import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useUniqueNameValidationCallbackForProject from 'ProjectManager/DataManagement/Questionnaire/components/SubsectionCreation/hooks/uniqueNameValidationCallback/useUniqueNameValidationCallbackForProject';
import useUniqueNameValidationCallbackForTemplate
    from 'ProjectManager/DataManagement/Questionnaire/components/SubsectionCreation/hooks/uniqueNameValidationCallback/useUniqueNameValidationCallbackForTemplate';

const useUniqueNameValidationCallback = (sectionId, isCustomSection) => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useUniqueNameValidationCallbackForProject(
        sectionId,
        isCustomSection,
    );

    const dataFromTemplate = useUniqueNameValidationCallbackForTemplate(
        sectionId,
        isCustomSection,
    );

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return () => {};
};

export default useUniqueNameValidationCallback;
