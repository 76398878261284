import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import updateProjectWorkingHours from 'ProjectManager/Project/Common/api/dataManagement/updateProjectWorkingHours';
import withWorkingHours from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/withWorkingHours';

const useProjectWorkingHoursData = () => {
    const [{ id: projectId, workingHours }, setProject] = useRecoilState(
        projectAtom,
    );

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const update = useCallback(
        async newWorkingHours => {
            await updateProjectWorkingHours(
                projectId,
                {
                    from: newWorkingHours.from.formatted,
                    to: newWorkingHours.to.formatted,
                },
                source.token,
            );

            setProject(withWorkingHours(newWorkingHours));
        },
        [projectId, setProject, source.token],
    );

    return projectId
        ? {
              from: workingHours.from,
              to: workingHours.to,
              updateWorkingHours: update,
          }
        : {};
};

export default useProjectWorkingHoursData;
