import api from 'Application/api/api';

const createTemplate = (templateGroupId, cancelToken = null) =>
    api.post(
        `project-manager/template-groups/${templateGroupId}/template`,
        {},
        { cancelToken },
    );

export default createTemplate;
