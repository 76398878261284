import { useMemo } from 'react';

const useDownloadButton = introduction => {
    const isVisible = useMemo(() => !introduction.isCanceled, [
        introduction.isCanceled,
    ]);

    const handleClick = () => {
        console.log('Downloading the certificate of the introduction...');
    };

    return {
        isVisible,
        handleClick,
    };
};

export default useDownloadButton;
