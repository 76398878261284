import setIn from 'Common/utils/setIn';
import findQuestionInQuestionnaireSections from 'ProjectManager/DataManagement/Questionnaire/helpers/findQuestionInQuestionnaireSections';

const withQuestionComment = (
    questionId,
    isCustomQuestion,
    comment,
) => prevProjectOrTemplate => {
    let questionnaireSections = [...prevProjectOrTemplate.questionnaireSections];

    const { question, questionPath } = findQuestionInQuestionnaireSections(
        questionnaireSections,
        questionId,
        isCustomQuestion,
    );

    if (!question) {
        return prevProjectOrTemplate;
    }

    const updatedQuestion = {
        ...question,
        comment,
    };

    questionnaireSections = setIn(
        questionnaireSections,
        questionPath,
        updatedQuestion,
    );

    return {
        ...prevProjectOrTemplate,
        questionnaireSections,
    };
};

export default withQuestionComment;
