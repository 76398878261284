import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import deleteTemplateQuestionComment from 'ProjectManager/Template/Common/api/dataManagement/questionnaire/question/comment/deleteTemplateQuestionComment';
import withQuestionComment
    from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/withQuestionComment';

const useDeleteTemplateQuestionCommentFunction = (
    questionId,
    isCustomQuestion,
) => {
    const [{ id: templateId }, setTemplate] = useRecoilState(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const deleteComment = useCallback(async () => {
        await deleteTemplateQuestionComment(
            templateId,
            questionId,
            isCustomQuestion,
            source.token,
        );

        setTemplate(withQuestionComment(questionId, isCustomQuestion, null));
    }, [templateId, questionId, isCustomQuestion, setTemplate, source.token]);

    return deleteComment;
};

export default useDeleteTemplateQuestionCommentFunction;
