import api from 'Application/api/api';

const getTemplateQuestionFiles = (templateId, questionId, isCustom, cancelToken = null) =>
    api.get(
        `project-manager/templates/${templateId}/questionnaire/questions/${questionId}/${
            isCustom ? 1 : 0
        }/files`,
        {
            cancelToken,
        },
    );

export default getTemplateQuestionFiles;
