import api from 'Application/api/api';

const requestProjectQuestionFilesDownload = (
    projectId,
    questionId,
    isCustomQuestion,
    cancelToken = null,
) =>
    api.post(
        `project-manager/projects/${projectId}/questionnaire/questions/${questionId}/${
            isCustomQuestion ? 1 : 0
        }/files/download-requests`,
        {
            cancelToken,
        },
    );

export default requestProjectQuestionFilesDownload;
