import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { CheckCircleRounded, WarningRounded } from '@material-ui/icons';
import StatisticsNumber from 'ProjectManager/Project/Preview/Statistics/StatisticsNumber';
import colors from 'Common/constants/colors';
import InnerBox from 'Common/components/Boxes/InnerBox';
import Button from 'Common/components/Button';

const Wrapper = styled(InnerBox)`
    display: flex;
    padding: 20px 0;

    @media screen and (min-width: 360px) {
        padding-left: 10px;
        padding-right: 10px;
    }
`;

const Main = styled.div`
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10px;

    svg {
        font-size: 45px;
    }

    a {
        font-size: 14px;
        line-height: 21px;
    }
`;

const StyledWarningRounded = styled(WarningRounded)`
    position: relative;
    top: -1px;
`;

const Title = styled.div`
    margin: 2px 0 10px;
    font-size: 15px;
    line-height: 22px;

    @media screen and (min-width: 360px) {
        font-size: 16px;
        line-height: 25px;
    }
`;

const Statistics = styled.div`
    flex: 0 0 127px;
    width: 127px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
    border-left: 1px solid ${colors.DARK_DIVIDER};

    @media screen and (min-width: 375px) {
        flex: 0 0 137px;
        width: 137px;
    }

    @media screen and (min-width: 425px) {
        flex: 0 0 147px;
        width: 147px;
    }

    > div {
        &:first-child {
            position: relative;
            padding-bottom: 15px;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: calc(100% - 20px);
                height: 1px;
                background: ${colors.DARK_DIVIDER};
            }
        }

        &:nth-child(2) {
            padding-top: 15px;
        }
    }
`;

const Task = ({ title, icon, to, pending, completed, buttonProps }) => (
    <Wrapper>
        <Main>
            {icon}
            <Title>{title}</Title>
            <Button small to={to} {...buttonProps}>
                Manage
            </Button>
        </Main>
        <Statistics>
            <StatisticsNumber
                small
                text="Ausstehend"
                number={pending}
                icon={pending > 0 ? <StyledWarningRounded /> : undefined}
                color={colors.WARNING}
            />
            <StatisticsNumber
                small
                text="Abgeschlossen"
                number={completed}
                icon={completed > 0 ? <CheckCircleRounded /> : undefined}
                color={colors.GREEN}
            />
        </Statistics>
    </Wrapper>
);

Task.defaultProps = {
    buttonProps: {},
};

Task.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.any.isRequired,
    to: PropTypes.string.isRequired,
    pending: PropTypes.number.isRequired,
    completed: PropTypes.number.isRequired,
    buttonProps: PropTypes.object,
};

export default Task;
