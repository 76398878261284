import React, { useState } from 'react';
import { CloseRounded } from '@material-ui/icons';
import Draggable from 'react-draggable';
import styled from 'styled-components/macro';
import InteractableOuterBox from 'Common/components/Boxes/InteractableOuterBox';
import colors from 'Common/constants/colors';

const DraggableImageWrapper = styled.div`
    position: fixed;
    z-index: 9999;
    cursor: move;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4), 0 0 0 1px rgba(0, 0, 0, 0.17);
    border-radius: 6px;
    overflow: hidden;

    img {
        display: block;
        width: 100%;
        max-width: 550px;
        min-width: 350px;
        pointer-events: none;
    }
`;

const DraggableBoxCloseButton = styled(InteractableOuterBox).attrs({
    as: 'button',
})`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    border: 0 none;
    outline: 0 none;
    cursor: pointer;

    svg {
        margin-right: 0;
    }
`;

const PreviewImage = styled.div`
    width: 60px;
    height: 28px;
    border: 1px solid ${colors.PAGE_BACKGROUND};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    border-radius: 6px;
`;

const OpenPreviewButton = styled.button`
    position: absolute;
    display: flex;
    align-items: center;
    top: 50%;
    right: 0;
    overflow: hidden;
    width: auto;
    height: 32px;
    padding: 0;
    background: ${colors.PAGE_BACKGROUND};
    border: 2px solid ${colors.PAGE_BACKGROUND};
    outline: 0 none;
    color: ${colors.DIRTY_WHITE};
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    cursor: pointer;
    transform: translateY(-50%);
    border-radius: 6px;

    &:hover {
        background: ${colors.PRIMARY};
        border-color: ${colors.PRIMARY};
    }

    > span {
        display: none;
    }

    @media screen and (min-width: 375px) {
        padding-left: 10px;

        > span {
            display: block;
            margin-right: 10px;
        }
    }
`;

const LicensePreview = ({ path }) => {
    const [isImageOpened, setIsImageOpened] = useState(false);

    const handleImageOpen = () => {
        setIsImageOpened(true);
    };

    const handleImageClose = () => {
        setIsImageOpened(false);
    };

    return (
        <>
            <OpenPreviewButton type="button" onClick={handleImageOpen}>
                <span>Vorschau</span>
                <PreviewImage
                    style={{
                        backgroundImage: `url(${path})`,
                    }}
                />
            </OpenPreviewButton>
            {isImageOpened && (
                <Draggable cancel=".draggable-cancel">
                    <DraggableImageWrapper>
                        <DraggableBoxCloseButton
                            className="draggable-cancel"
                            onClick={handleImageClose}
                        >
                            <CloseRounded />
                        </DraggableBoxCloseButton>
                        <img src={path} alt="" />
                    </DraggableImageWrapper>
                </Draggable>
            )}
        </>
    );
};

export default LicensePreview;
