import api from 'Application/api/api';

const changeIntroductionParticipants = (
    projectId,
    introductionId,
    projectParticipantIds,
    cancelToken = null,
) =>
    api.post(
        `project-manager/projects/${projectId}/introductions/${introductionId}/change-participants`,
        {
            projectParticipantIds,
        },
        { cancelToken },
    );

export default changeIntroductionParticipants;
