import api from 'Application/api/api';

const markDriverLicenseAsLost = (driverLicenseControlId, cancelToken = null) =>
    api.post(
        `/measures/vehicles/driver-licenses/controls/${driverLicenseControlId}/driver-license/mark-as-lost`,
        {},
        { cancelToken },
    );

export default markDriverLicenseAsLost;
