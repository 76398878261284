import { useEffect, useState } from 'react';
import isLastDayOfMonth from 'date-fns/isLastDayOfMonth';
import getMonth from 'date-fns/getMonth';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import getSeconds from 'date-fns/getSeconds';
import { useDispatch, useSelector } from 'react-redux';
import { clearInstructionsRestriction } from 'Education/reduxEducation';
import createNewDate from 'Common/utils/createNewDate';

// If you ever need to change these, please change them in the middleware in the back-end as well
const timeRestrictedFrom = '15:00';
const timeRestrictedTo = '23:59';

const isAccessRestricted = () => {
    const now = createNewDate();

    const month = getMonth(now) + 1;

    const isMidYearTraineeArchive = isLastDayOfMonth(now) && month === 7;
    const isAnnualEmployeeArchive = isLastDayOfMonth(now) && month === 12;

    if (!isMidYearTraineeArchive && !isAnnualEmployeeArchive) {
        return false;
    }

    const hour = getHours(now);
    const minute = getMinutes(now);

    const [restrictedFromHour, restrictedFromMinute] = timeRestrictedFrom
        .split(':')
        .map(part => parseInt(part));

    const isAfterStartingTime =
        hour > restrictedFromHour ||
        (hour === restrictedFromHour && minute >= restrictedFromMinute);

    const [restrictedToHour, restrictedToMinute] = timeRestrictedTo
        .split(':')
        .map(part => parseInt(part));

    const isBeforeEndingTime =
        hour < restrictedToHour ||
        (hour === restrictedToHour && minute <= restrictedToMinute);

    return isAfterStartingTime && isBeforeEndingTime;
};

const calculateTimeLeft = () => {
    if (!isAccessRestricted()) {
        return null;
    }

    const now = createNewDate();

    const hours = getHours(now);
    const minutes = getMinutes(now);
    const seconds = getSeconds(now);

    const [restrictedToHour, restrictedToMinute] = timeRestrictedTo
        .split(':')
        .map(part => parseInt(part));

    const remainingHours = restrictedToHour - hours;
    const remainingMinutes = restrictedToMinute - minutes;
    const remainingSeconds = 59 - seconds;

    return {
        hours: remainingHours,
        minutes: remainingMinutes,
        seconds: remainingSeconds,
    };
};

const useRestrictionCheckDuringArchivingProcess = () => {
    const [isRestricted, setIsRestricted] = useState(isAccessRestricted);
    const [timeLeftUntilAccessible, setTimeLeftUntilAccessible] = useState(
        calculateTimeLeft,
    );

    const dispatch = useDispatch();

    const isAccessRestrictedExternally = useSelector(
        state => state.education.isAccessRestricted,
    );

    useEffect(() => {
        let interval;

        if (isRestricted || (!isRestricted && isAccessRestrictedExternally)) {
            interval = setInterval(() => {
                let isRestrictedNow = isAccessRestricted();

                setIsRestricted(isRestrictedNow);
                setTimeLeftUntilAccessible(calculateTimeLeft());

                if (!isRestrictedNow) {
                    dispatch(clearInstructionsRestriction());
                }
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [dispatch, isRestricted, isAccessRestrictedExternally]);

    return {
        isRestricted,
        timeLeftUntilAccessible,
    };
};

export default useRestrictionCheckDuringArchivingProcess;
