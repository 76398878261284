import api from 'Application/api/api';

const signInstructionAssignmentWithSignature = (
    assignmentId,
    signature,
    cancelToken = null,
) => {
    const formData = new FormData();

    formData.append('signature', signature);

    return api.post(
        `/education/instruction-assignments/${assignmentId}/sign/with-signature`,
        formData,
        { cancelToken },
    );
};

export default signInstructionAssignmentWithSignature;
