import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useRecoilState } from 'recoil';
import participantsAtom from 'ProjectManager/Project/Introductions/Create/recoil/participants/participantsAtom';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import ModalOption from 'Common/components/Modals/ModalOption';
import { orderBy } from 'lodash';
import withParticipant from 'ProjectManager/Project/Introductions/Create/recoil/participants/modifiers/withParticipant';
import withoutParticipant from 'ProjectManager/Project/Introductions/Create/recoil/participants/modifiers/withoutParticipant';

const Options = styled.div`
    > * {
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const RefineModal = ({ participants, introducedParticipantIds, onClose }) => {
    const [selectedParticipants, setSelectedParticipants] = useRecoilState(
        participantsAtom,
    );

    const handleToggle = (participantId, isChecked) => {
        if (isChecked) {
            setSelectedParticipants(withParticipant(participantId));
        } else {
            setSelectedParticipants(withoutParticipant(participantId));
        }
    };

    return (
        <Modal
            isOpen
            onRequestClose={onClose}
            buttons={
                <Button type="button" onClick={onClose}>
                    OK
                </Button>
            }
        >
            <ModalTitle>Auswahl Mitarbeiter</ModalTitle>
            <Options>
                {orderBy(
                    participants,
                    ['employee.lastName', 'employee.firstName'],
                    ['asc', 'asc'],
                ).map(participant => {
                    const employee = participant.employee;
                    const isIntroduced = introducedParticipantIds.includes(
                        participant.id,
                    );

                    return (
                        <ModalOption
                            key={participant.id}
                            title={`${employee.lastName}, ${employee.firstName}`}
                            description={
                                isIntroduced
                                    ? 'Bereits mind. 1 x eingewiesen'
                                    : 'Einweisung offen'
                            }
                            isChecked={selectedParticipants.includes(
                                participant.id,
                            )}
                            onToggle={isChecked =>
                                handleToggle(participant.id, isChecked)
                            }
                            isMulti
                        />
                    );
                })}
            </Options>
        </Modal>
    );
};

RefineModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default RefineModal;
