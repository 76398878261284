import api from 'Application/api/api';

/**
 * @param librariesToImport Accepts array of objects with the following format:
 * {
 *   id: 25,
 *   name: '',
 *   keepReference: true,
 *   slideIds: [],
 *   areaIds: [],
 * }
 * @param cancelToken
 */
const importInstructionsFromLibrary = (librariesToImport, cancelToken = null) =>
    api.post('/education/library-instructions/import', librariesToImport, {
        cancelToken,
    });

export default importInstructionsFromLibrary;
