import mapUserToOption from 'User/utils/mapUserToOption';
import { orderBy } from 'lodash';

const mapUsersToOptions = users =>
    orderBy(
        users.map(user => mapUserToOption(user)),
        ['label'],
        ['asc'],
    );

export default mapUsersToOptions;
