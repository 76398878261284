import React from 'react';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import CreateButton from 'ProjectManager/Project/Introductions/Create/components/CreateButton';
import { AssignmentRounded } from '@material-ui/icons';
import InnerBox from 'Common/components/Boxes/InnerBox';

const Wrapper = styled(InnerBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: ${colors.GRAY};

    > svg {
        font-size: 48px;
    }
`;

const NoIntroductionsText = styled.div`
    margin: 5px 0 20px;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
`;

const NoIntroductions = () => (
    <Wrapper>
        <AssignmentRounded />
        <NoIntroductionsText>
            Bisher wurden noch keine Einweisungen erstellt
        </NoIntroductionsText>
        <CreateButton />
    </Wrapper>
);

export default NoIntroductions;
