import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';
import DriverLicenseControlOverview from 'Measure/components/vehicle/driver-license/overview/DriverLicenseControlOverview';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import getEmployeeDriverLicenseControl from 'Measure/api/driver-license/getEmployeeDriverLicenseControl';

const DriverLicenseEmployeeOverview = () => {
    const dispatch = useDispatch();

    const driverLicenseControlRequest = useCallback(
        cancelToken =>
            getEmployeeDriverLicenseControl(
                [
                    'examiners',
                    'employee',
                    'employee.user',
                    'driverLicense',
                    'driverLicense.checks',
                    'driverLicense.checks.sentEmails',
                    'driverLicense.nextCheck',
                    'driverLicense.nextCheck.directProcedure',
                    'driverLicense.nextCheck.distanceProcedure',
                    'driverLicense.nextCheck.previousDistanceProcedure',
                    'driverLicense.nextCheck.previousDistanceProcedure.examiner',
                ],
                cancelToken,
            ),
        [],
    );

    const {
        data: driverLicenseControl,
        loadData: loadDriverLicenseControl,
        isLoading,
        hasError,
        error,
    } = useAxiosRequest(driverLicenseControlRequest, null);

    useEffect(() => {
        const responseStatus = error?.response?.status;

        if (responseStatus === 404) {
            dispatch(replace('/404'));
        }
    }, [dispatch, error]);

    if (isLoading) {
        return <Loader />;
    }

    if (hasError) {
        return <RetryLoading onRetry={loadDriverLicenseControl} />;
    }

    return (
        <DriverLicenseControlOverview
            driverLicenseControl={driverLicenseControl}
        />
    );
};

export default DriverLicenseEmployeeOverview;
