import api from 'Application/api/api';

const goToExamQuestionNumber = (id, questionNumber, cancelToken = null) =>
    api.post(
        `/education/instruction-assignments/${id}/exam/go-to-question`,
        {
            questionNumber,
        },
        {
            cancelToken,
        },
    );

export default goToExamQuestionNumber;
