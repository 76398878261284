import { parse, stringify } from 'qs';
import { useCallback, useMemo, useState } from 'react';
import queryParamsDecoder from 'Common/utils/queryParamsDecoder';

const useQueryParameter = name => {
    const [query, setQuery] = useState(() =>
        parse(window.location.search, {
            ignoreQueryPrefix: true,
            arrayLimit: 50,
            decoder: queryParamsDecoder,
        }),
    );

    const newQueryParam = query[name];
    const newQueryParamAsString = JSON.stringify(newQueryParam);

    // noinspection UnnecessaryLocalVariableJS
    const queryParam = useMemo(() => {
        if (newQueryParamAsString) {
            return JSON.parse(newQueryParamAsString);
        }

        return undefined;
    }, [newQueryParamAsString]);

    const setQueryParam = useCallback(
        value => {
            let newValue = value;

            if (
                value === null ||
                (Array.isArray(value) && value.length === 0)
            ) {
                newValue = undefined;
            }

            setQuery(prevQuery => ({
                ...prevQuery,
                [name]: newValue,
            }));

            const newQueryString = stringify({
                ...query,
                [name]: newValue,
            });

            window.history.replaceState(
                {},
                '',
                (newQueryString
                    ? `?${newQueryString}`
                    : window.location.pathname) + window.location.hash,
            );
        },
        [name, query],
    );

    return [queryParam, setQueryParam];
};

export default useQueryParameter;
