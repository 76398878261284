import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useCreateProjectCustomSubsectionFunction from 'ProjectManager/DataManagement/Questionnaire/components/SubsectionCreation/hooks/createCustomSubsectionFunction/useCreateProjectCustomSubsectionFunction';
import useCreateProjectTemplateCustomSubsectionFunction from 'ProjectManager/DataManagement/Questionnaire/components/SubsectionCreation/hooks/createCustomSubsectionFunction/useCreateTemplateCustomTopicFunction';

const useCreateCustomSubsectionFunction = (sectionId, isCustomSection) => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useCreateProjectCustomSubsectionFunction(
        sectionId,
        isCustomSection,
    );

    const dataFromTemplate = useCreateProjectTemplateCustomSubsectionFunction(
        sectionId,
        isCustomSection,
    );

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return () => {};
};

export default useCreateCustomSubsectionFunction;
