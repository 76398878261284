import React, { useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import employeeStatusFilters from 'Employee/constants/employeeStatusFilters';
import EmployeeList from 'Employee/components/List/EmployeeList';
import EmployeeListOverviewSidebar from 'Employee/components/List/EmployeeListOverviewSidebar';
import employeeTypeFilters from 'Employee/constants/employeeTypeFilters';
import getEmployees from 'Employee/api/getEmployees';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';
import BackButton from 'Application/components/Header/BackButton/BackButton';

const Layout = styled.div`
    @media screen and (min-width: 940px) {
        display: flex;
    }
`;

const EmployeeListOverview = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const [selectedStatus, setSelectedStatus] = useState(
        employeeStatusFilters.ACTIVE,
    );

    const [selectedEmployeeType, setSelectedEmployeeType] = useState(
        employeeTypeFilters.ALL,
    );

    const handleSearchTermChange = value => {
        setSearchTerm(value);
    };

    const employeesRequest = useCallback(
        cancelToken =>
            getEmployees(
                ['tasksProgressPercentage', 'availableModules'],
                cancelToken,
            ),
        [],
    );

    const {
        data: employees,
        loadData: loadEmployees,
        setData: setEmployees,
        isLoading,
        hasError,
    } = useAxiosRequest(employeesRequest, []);

    const handleEmployeeActivate = useCallback(
        id => {
            setEmployees(prevEmployees => {
                const newEmployees = [...prevEmployees];
                const index = newEmployees.findIndex(
                    employee => employee.id === id,
                );

                if (index !== -1) {
                    newEmployees[index] = {
                        ...newEmployees[index],
                        isEnabled: true,
                    };
                }

                return newEmployees;
            });
        },
        [setEmployees],
    );

    const handleEmployeeDeactivate = useCallback(
        id => {
            setEmployees(prevEmployees => {
                const newEmployees = [...prevEmployees];
                const index = newEmployees.findIndex(
                    employee => employee.id === id,
                );

                if (index !== -1) {
                    newEmployees[index] = {
                        ...newEmployees[index],
                        isEnabled: false,
                    };
                }

                return newEmployees;
            });
        },
        [setEmployees],
    );

    const handleEmployeeArchive = useCallback(
        id => {
            setEmployees(prevEmployees => {
                const newEmployees = [...prevEmployees];
                const index = newEmployees.findIndex(
                    employee => employee.id === id,
                );

                if (index !== -1) {
                    newEmployees[index] = {
                        ...newEmployees[index],
                        isArchived: true,
                    };
                }

                return newEmployees;
            });
        },
        [setEmployees],
    );

    let filteredEmployees = employees.filter(employee => !employee.isArchived);

    if (searchTerm) {
        filteredEmployees = filteredEmployees.filter(employee => {
            const searchTerms = searchTerm
                .toLowerCase()
                .split(' ')
                .filter(term => !!term);

            const firstName = employee.firstName.toLowerCase();
            const isFirstNameMatched =
                searchTerms.findIndex(term => firstName.startsWith(term)) !==
                -1;

            const lastName = employee.lastName.toLowerCase();
            const isLastNameMatched =
                searchTerms.findIndex(term => lastName.startsWith(term)) !== -1;

            return searchTerms.length > 1
                ? isFirstNameMatched && isLastNameMatched
                : isFirstNameMatched || isLastNameMatched;
        });
    }

    if (selectedEmployeeType === employeeTypeFilters.TRAINEE) {
        filteredEmployees = filteredEmployees.filter(
            employee => employee.isTrainee,
        );
    }

    return (
        <>
            <Helmet>
                <title>Mitarbeiter</title>
            </Helmet>
            <BackButton to="/dashboard" />
            <Breadcrumb isActive>Mitarbeiter</Breadcrumb>
            <Layout>
                {isLoading ? (
                    <Loader />
                ) : hasError ? (
                    <RetryLoading onRetry={loadEmployees} />
                ) : (
                    <>
                        <EmployeeListOverviewSidebar
                            searchTerm={searchTerm}
                            onSearchTermChange={handleSearchTermChange}
                            selectedStatus={selectedStatus}
                            onStatusChange={setSelectedStatus}
                            selectedEmployeeType={selectedEmployeeType}
                            onEmployeeTypeChange={setSelectedEmployeeType}
                            employees={filteredEmployees}
                            allEmployees={employees}
                        />
                        <EmployeeList
                            selectedStatus={selectedStatus}
                            employees={filteredEmployees}
                            onEmployeeActivate={handleEmployeeActivate}
                            onEmployeeDeactivate={handleEmployeeDeactivate}
                            onEmployeeArchive={handleEmployeeArchive}
                        />
                    </>
                )}
            </Layout>
        </>
    );
};

export default EmployeeListOverview;
