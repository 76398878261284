import React from 'react';
import PropTypes from 'prop-types';
import projectStatusesElements from 'ProjectManager/Project/Common/constants/projectStatusesElements';
import styled from 'styled-components/macro';
import { prop } from 'styled-tools';
import colors from 'Common/constants/colors';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;

    @media screen and (min-width: 470px) {
        flex-direction: column;
        width: 75px;
        flex: 0 0 75px;
        margin: 0 20px 0 5px;
    }
`;

const IconCircle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    margin-right: 8px;
    background-color: ${prop('background')};
    border-radius: 50%;
    color: ${colors.DIRTY_WHITE};
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.3);

    @media screen and (min-width: 470px) {
        flex: initial;
        margin-right: 0;
        margin-bottom: 8px;
    }

    svg {
        font-size: 21px;
    }
`;

const Text = styled.div`
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
`;

const Status = ({ project }) => {
    const statusElements = projectStatusesElements[project.status];
    const text = statusElements.text;
    const color = statusElements.color;
    const Icon = statusElements.icon;

    return (
        <Wrapper>
            <IconCircle background={color}>{<Icon />}</IconCircle>
            <Text>{text}</Text>
        </Wrapper>
    );
};

Status.propTypes = {
    project: PropTypes.object.isRequired,
};

export default Status;
