import api from 'Application/api/api';

const updateDriverLicenseControlExaminers = (
    driverLicenseControlId,
    examinerIds,
    cancelToken = null,
) =>
    api.post(
        `/measures/vehicles/driver-licenses/controls/${driverLicenseControlId}/update-examiners`,
        {
            examinerIds,
        },
        {
            cancelToken,
        },
    );

export default updateDriverLicenseControlExaminers;
