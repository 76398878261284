import React, { useState } from 'react';
import styled from 'styled-components/macro';
import ContentSection from 'ProjectManager/DataManagement/Common/components/ContentSection';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import AvailableEmployees
    from 'ProjectManager/DataManagement/EmployeeSelection/components/AvailableEmployees';
import SelectedEmployees
    from 'ProjectManager/DataManagement/EmployeeSelection/components/SelectedEmployees';
import MobileAvailableEmployees
    from 'ProjectManager/DataManagement/EmployeeSelection/components/MobileAvailableEmployees';
import useEmployeeSelectionData
    from 'ProjectManager/DataManagement/EmployeeSelection/hooks/useEmployeeSelectionData';
import RetryLoading from 'Common/components/RetryLoading';
import Loader from 'Common/components/Loader';

const Wrapper = styled(ContentSection)`
  // Compensate for the scrollbar padding on the right
  @media screen and (max-width: 767px) {
    padding-right: 10px;
  }
`;

const EmployeesWrapper = styled.div`
  display: flex;
  min-height: 380px;
  max-height: calc(100vh - 330px);

  @media screen and (min-width: 768px) {
    max-height: calc(100vh - 350px);
  }
`;

const Employees = styled.div`
  // Left column (available employees)
  &:first-child {
    display: none;
  }

  // Right column (selected employees)
  &:nth-child(2) {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    flex: 1;

    // Left column (available employees)
    &:first-child {
      display: block;
      margin-right: 25px;
    }

    &:nth-child(2) {
      width: auto;
    }
  }
`;

const EmployeeSelection = () => {
    const {
        isLoading,
        hasError,
        retryLoading,
        assignableEmployees,
        participatingEmployees,
        assignEmployees,
        unassignEmployees,
        updateRecoilState,
    } = useEmployeeSelectionData();

    const [lastAddedEmployeeIds, setLastAddedEmployeeIds] = useState([]);

    const handleAfterAdd = (addedEmployeeIds, newValue) => {
        updateRecoilState(newValue);

        setLastAddedEmployeeIds(prevEmployeeIds => [
            ...prevEmployeeIds,
            ...addedEmployeeIds,
        ]);
    };

    const handleAfterRemove = (removedEmployeeIds, newValue) => {
        updateRecoilState(newValue);

        setLastAddedEmployeeIds(prevEmployeeIds => {
            const newEmployeeIds = [...prevEmployeeIds];

            removedEmployeeIds.forEach(removedEmployeeId => {
                const index = newEmployeeIds.indexOf(removedEmployeeId);

                if (index !== -1) {
                    newEmployeeIds.splice(index, 1);
                }
            });

            return newEmployeeIds;
        });
    };

    return (
        <Wrapper>
            <OuterBoxTitle>Personaleinsatz für das Projekt</OuterBoxTitle>
            {isLoading ? (
                <Loader />
            ) : hasError ? (
                <RetryLoading onRetry={retryLoading} />
            ) : (
                <>
                    <MobileAvailableEmployees
                        assignableEmployees={assignableEmployees}
                        participatingEmployees={participatingEmployees}
                        onAdd={assignEmployees}
                        onAfterAdd={handleAfterAdd}
                    />
                    <EmployeesWrapper>
                        <Employees>
                            <AvailableEmployees
                                assignableEmployees={assignableEmployees}
                                participatingEmployees={participatingEmployees}
                                onAdd={assignEmployees}
                                onAfterAdd={handleAfterAdd}
                            />
                        </Employees>
                        <Employees>
                            <SelectedEmployees
                                assignableEmployees={assignableEmployees}
                                participatingEmployees={participatingEmployees}
                                lastAddedEmployeeIds={lastAddedEmployeeIds}
                                onRemove={unassignEmployees}
                                onAfterRemove={handleAfterRemove}
                            />
                        </Employees>
                    </EmployeesWrapper>
                </>
            )}
        </Wrapper>
    );
};

export default EmployeeSelection;
