import api from 'Application/api/api';

const deleteTemplateQuestionFile = (
    templateId,
    questionId,
    isCustom,
    fileId,
    cancelToken = null,
) =>
    api.delete(
        `project-manager/templates/${templateId}/questionnaire/questions/${questionId}/${
            isCustom ? 1 : 0
        }/files/${fileId}`,
        {
            cancelToken,
        },
    );

export default deleteTemplateQuestionFile;
