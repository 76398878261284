import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import deleteTemplateQuestionFile from 'ProjectManager/Template/Common/api/dataManagement/questionnaire/question/files/deleteTemplateQuestionFile';
import getTemplateQuestionFiles from 'ProjectManager/Template/Common/api/dataManagement/questionnaire/question/files/getTemplateQuestionFiles';
import withQuestionFiles
    from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/withQuestionFiles';

const useTemplateQuestionFileFunctions = (questionId, isCustomQuestion) => {
    const [{ id: templateId }, setTemplate] = useRecoilState(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const generateDownloadLink = useCallback(
        fileId =>
            `/api/project-manager/templates/${templateId}/questionnaire/questions/${questionId}/${
                isCustomQuestion ? 1 : 0
            }/files/${fileId}`,
        [isCustomQuestion, templateId, questionId],
    );

    const deleteQuestionFile = useCallback(
        async fileId => {
            await deleteTemplateQuestionFile(
                templateId,
                questionId,
                isCustomQuestion,
                fileId,
                source.token,
            );

            const filesResponse = await getTemplateQuestionFiles(
                templateId,
                questionId,
                isCustomQuestion,
                source.token,
            );

            setTemplate(
                withQuestionFiles(
                    questionId,
                    isCustomQuestion,
                    filesResponse.data,
                ),
            );
        },
        [templateId, questionId, isCustomQuestion, setTemplate, source.token],
    );

    return { generateDownloadLink, deleteQuestionFile };
};

export default useTemplateQuestionFileFunctions;
