import api from 'Application/api/api';

const getCompany = (load, cancelToken = null) =>
    api.get('/companies/current', {
        params: {
            load,
        },
        cancelToken,
    });

export default getCompany;
