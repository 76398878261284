import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import React, { useCallback, useMemo, useState } from 'react';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import ReminderModal from 'ProjectManager/Project/Introductions/Grid/components/Introduction/ReminderModal';

const useReminderButton = introduction => {
    const { status: projectStatus } = useRecoilValue(projectAtom);

    const haveAllRelevantParticipantsConfirmed = introduction.participants
        .filter(participant => participant.isRelevant)
        .every(participant => participant.isConfirmed);

    const isVisible = useMemo(
        () =>
            projectStatus === projectStatuses.ONGOING &&
            introduction.isConducted &&
            !introduction.isCanceled &&
            !haveAllRelevantParticipantsConfirmed,
        [
            haveAllRelevantParticipantsConfirmed,
            introduction.isCanceled,
            introduction.isConducted,
            projectStatus,
        ],
    );

    const [isModalOpened, setIsModalOpened] = useState(false);

    const handleClick = () => {
        setIsModalOpened(true);
    };

    const closeModal = useCallback(() => {
        setIsModalOpened(false);
    }, []);

    const renderModal = useCallback(() => {
        if (!isModalOpened) {
            return null;
        }

        return (
            <ReminderModal introduction={introduction} onClose={closeModal} />
        );
    }, [closeModal, introduction, isModalOpened]);

    return {
        isVisible,
        handleClick,
        renderModal,
    };
};

export default useReminderButton;
