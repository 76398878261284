import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ReactSelectAsync from 'react-select/async';
import ErrorMessage from 'Common/components/Form/ErrorMessage';
import { debounce } from 'lodash';
import StyledInputLabel from 'Common/components/Form/StyledInputLabel';
import reactSelectCustomComponents from 'Common/components/Form/Fields/reactSelectCustomComponents';
import useReactSelectCustomStyles from 'Common/components/Form/Fields/useReactSelectCustomStyles';
import RequiredAsterisk from 'Common/components/Form/RequiredAsterisk';

const Wrapper = styled.div`
    position: relative;
    width: 100%;
`;

const getLoadingMessage = () => 'Wird geladen...';
const getNoOptionsMessage = () => 'Keine Übereinstimmung';

const AsyncSelect = ({
    label,
    id,
    error,
    touched,
    setFieldValue,
    loadOptions,
    onFocus,
    onBlur,
    additionalStyles,
    isRequired,
    className,
    ...props
}) => {
    // Use debounced loading of the options in order to prevent making a request
    // on every single keystroke.
    const debouncedLoadOptions = useCallback(
        debounce(async (inputValue, resolve) => {
            const loadedOptions = await loadOptions(inputValue);

            resolve(loadedOptions);
        }, 350),
        [],
    );

    // A promise wrapper around the debounced function for loading the options.
    // It is needed, since react-select needs the function in the form of a promise.
    const promiseOptions = inputValue =>
        new Promise(async resolve => {
            debouncedLoadOptions(inputValue, resolve);
        });

    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = (...args) => {
        setIsFocused(true);

        if (onFocus) {
            onFocus(...args);
        }
    };

    const handleBlur = event => {
        setIsFocused(false);

        const cachedEvent = { ...event };

        if (onBlur) {
            // Prevent the onBlur being called too early on mobile devices,
            // as it breaks the validation.
            setTimeout(() => {
                onBlur(cachedEvent);
            }, 1);
        }
    };

    const handleChange = option => {
        setFieldValue(props.name, option);
    };

    const hasLabel = label !== null;

    const hasValue =
        !!props.value &&
        (!Array.isArray(props.value) ||
            (Array.isArray(props.value) && props.value.length > 0));

    const hasError =
        !!error &&
        ((typeof touched === 'boolean' && touched) ||
            typeof touched === 'object');

    const customStyles = useReactSelectCustomStyles(hasError, additionalStyles);

    const renderedLabel =
        hasLabel && isRequired ? (
            <>
                {label} <RequiredAsterisk />
            </>
        ) : (
            label
        );

    return (
        <>
            <Wrapper className={className}>
                {hasLabel && (
                    <StyledInputLabel
                        id={id}
                        focused={isFocused}
                        shrink={isFocused || hasValue}
                        error={hasError}
                        disabled={props.isDisabled}
                    >
                        {renderedLabel}
                    </StyledInputLabel>
                )}

                <ReactSelectAsync
                    inputId={id}
                    styles={customStyles}
                    defaultOptions
                    loadOptions={promiseOptions}
                    loadingMessage={getLoadingMessage}
                    noOptionsMessage={getNoOptionsMessage}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder={null}
                    hideSelectedOptions={false}
                    closeMenuOnSelect={!props.isMulti}
                    blurInputOnSelect={false}
                    components={reactSelectCustomComponents}
                    {...props}
                />
            </Wrapper>

            {hasError && <ErrorMessage message={error} />}
        </>
    );
};

AsyncSelect.defaultProps = {
    label: null,
    error: null,
    touched: false,
    setFieldValue: undefined,
    onFocus: undefined,
    onBlur: undefined,
    additionalStyles: {},
    isRequired: false,
};

AsyncSelect.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.any,
    setFieldValue: PropTypes.func,
    loadOptions: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    additionalStyles: PropTypes.object,
    isRequired: PropTypes.bool,
};

export default AsyncSelect;
