import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import InnerBoxWithButtons from 'Common/components/Boxes/InnerBoxWithButtons';
import Button from 'Common/components/Button';
import { CloseRounded, DeleteSweepRounded } from '@material-ui/icons';
import colors from 'Common/constants/colors';
import ClearAllModal from 'Common/components/Filters/FiltersBox/ClearAllModal';
import CloseButton from 'Common/components/Filters/CloseButton/CloseButton';

const Wrapper = styled(InnerBoxWithButtons)`
    width: 100%;
    background: ${colors.DIRTY_WHITE};

    // Content wrapper
    > div:first-child {
        padding: 0;
    }
`;

const CloseButtonWrapper = styled.div`
    position: sticky;
    display: flex;
    justify-content: flex-end;
    top: 0;

    button {
        position: absolute;
        top: 0;
        right: 0;

        svg {
            font-size: 32px !important;
        }
    }
`;

const Content = styled.div`
    max-height: calc(100vh - 270px);
    padding: 20px 48px 20px 20px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    > div {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ::-webkit-scrollbar-track-piece {
        background-color: ${colors.DIRTY_WHITE};
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${colors.LIGHT_GRAY};
    }
`;

const ClearButton = styled(Button)`
    padding: 0 5px;
    margin-right: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
`;

const FiltersBox = ({
    haveFiltersChanged,
    onApply,
    onClearAll,
    onCloseButtonClick,
    children,
    ...props
}) => {
    const [isClearAllModalOpened, setIsClearAllModalOpened] = useState(false);

    const handleClearButtonClick = () => {
        setIsClearAllModalOpened(true);
    };

    const closeClearAllModal = () => {
        setIsClearAllModalOpened(false);
    };

    const handleClearAllModalConfirm = () => {
        onClearAll();
        closeClearAllModal();
    };

    return (
        <Wrapper
            light
            buttons={
                <>
                    <ClearButton
                        text
                        dark
                        small
                        icon={<DeleteSweepRounded />}
                        type="button"
                        onClick={handleClearButtonClick}
                    >
                        Zurücksetzen
                    </ClearButton>
                    <Button
                        type="button"
                        disabled={!haveFiltersChanged}
                        onClick={onApply}
                    >
                        Anwenden
                    </Button>
                </>
            }
            {...props}
        >
            <CloseButtonWrapper>
                <CloseButton
                    iconOnly
                    dark
                    icon={<CloseRounded />}
                    haveFiltersChanged={haveFiltersChanged}
                    onClick={onCloseButtonClick}
                />
            </CloseButtonWrapper>
            <Content>{children}</Content>
            {isClearAllModalOpened && (
                <ClearAllModal
                    onClose={closeClearAllModal}
                    onConfirm={handleClearAllModalConfirm}
                />
            )}
        </Wrapper>
    );
};

FiltersBox.propTypes = {
    haveFiltersChanged: PropTypes.bool.isRequired,
    onApply: PropTypes.func.isRequired,
    onClearAll: PropTypes.func.isRequired,
    onCloseButtonClick: PropTypes.func.isRequired,
};

export default FiltersBox;
