import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import createProjectCustomSubsection from 'ProjectManager/Project/Common/api/dataManagement/questionnaire/subsection/createProjectCustomSubsection';
import withAdditionalCustomSubsection from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/subsection/withAdditionalCustomSubsection';

const useCreateProjectCustomSubsectionFunction = (
    sectionId,
    isCustomSection,
) => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const createNewCustomSubsection = useCallback(
        async values => {
            const response = await createProjectCustomSubsection(
                projectId,
                sectionId,
                isCustomSection,
                values,
                source.token,
            );

            const newCustomSubsection = response.data;

            setProject(
                withAdditionalCustomSubsection(
                    newCustomSubsection,
                    sectionId,
                    isCustomSection,
                ),
            );

            return newCustomSubsection;
        },
        [isCustomSection, projectId, sectionId, setProject, source.token],
    );

    return createNewCustomSubsection;
};

export default useCreateProjectCustomSubsectionFunction;
