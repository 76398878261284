import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import giveTemplateQuestionMultipleChoiceAnswers from 'ProjectManager/Template/Common/api/dataManagement/questionnaire/question/answer/giveTemplateQuestionMultipleChoiceAnswers';
import withMultipleChoiceAnswersForQuestion
    from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/answer/withMultipleChoiceAnswersForQuestion';

const useTemplateGiveMultipleChoiceAnswersFunction = (
    questionId,
    isCustomQuestion,
) => {
    const [{ id: templateId }, setTemplate] = useRecoilState(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const giveMultipleChoiceAnswers = useCallback(
        async selectedAnswerIds => {
            await giveTemplateQuestionMultipleChoiceAnswers(
                templateId,
                questionId,
                isCustomQuestion,
                selectedAnswerIds,
                source.token,
            );

            setTemplate(
                withMultipleChoiceAnswersForQuestion(
                    questionId,
                    isCustomQuestion,
                    selectedAnswerIds,
                ),
            );
        },
        [templateId, questionId, isCustomQuestion, setTemplate, source.token],
    );

    return giveMultipleChoiceAnswers;
};

export default useTemplateGiveMultipleChoiceAnswersFunction;
