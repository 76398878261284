import api from 'Application/api/api';

const signInstructionAssignmentForEmployeeWithPassword = (
    assignmentId,
    employeeId,
    password,
    cancelToken = null,
) =>
    api.post(
        `/education/instruction-assignments/${assignmentId}/sign-for-employee/${employeeId}/with-password`,
        {
            password,
        },
        { cancelToken },
    );

export default signInstructionAssignmentForEmployeeWithPassword;
