import api from 'Application/api/api';

const goToNextPresentationSlide = (id, cancelToken = null) =>
    api.post(
        `/education/instruction-assignments/${id}/presentation/go-to-next-slide`,
        {},
        { cancelToken },
    );

export default goToNextPresentationSlide;
