import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useDeleteProjectCustomSubsectionFunction from 'ProjectManager/DataManagement/Questionnaire/components/Subsection/hooks/deleteCustomSubsectionFunction/useDeleteProjectCustomSubsectionFunction';
import useDeleteTemplateCustomSubsectionFunction from 'ProjectManager/DataManagement/Questionnaire/components/Subsection/hooks/deleteCustomSubsectionFunction/useDeleteTemplateCustomSubsectionFunction';

const useDeleteCustomSubsectionFunction = () => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useDeleteProjectCustomSubsectionFunction();
    const dataFromTemplate = useDeleteTemplateCustomSubsectionFunction();

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return () => {};
};

export default useDeleteCustomSubsectionFunction;
