const withUpdatedGroupData = (groupId, updatedValues) => prevGroups => {
    const index = prevGroups.findIndex(group => group.id === groupId);

    if (index !== -1) {
        const groups = [...prevGroups];

        groups[index] = {
            ...groups[index],
            ...updatedValues,
        };

        return groups;
    }

    return prevGroups;
};

export default withUpdatedGroupData;
