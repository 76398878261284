import withUpdatedCompletedStatusesInQuestionnaire from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/withUpdatedCompletedStatusesInQuestionnaire';

const withoutCustomSubsection = customSubsectionId => prevProjectOrTemplate => {
    const sectionIndex = prevProjectOrTemplate.questionnaireSections.findIndex(section =>
        section.subsections.some(
            subsection => subsection.id === customSubsectionId,
        ),
    );

    if (sectionIndex === -1) {
        return prevProjectOrTemplate;
    }

    const newQuestionnaireSections = [...prevProjectOrTemplate.questionnaireSections];
    const section = newQuestionnaireSections[sectionIndex];

    const subsectionIndex = section.subsections.findIndex(
        subsection =>
            subsection.id === customSubsectionId && subsection.isCustom,
    );

    if (subsectionIndex === -1) {
        return prevProjectOrTemplate;
    }

    const newSubsections = [...section.subsections];

    newSubsections.splice(subsectionIndex, 1);

    newQuestionnaireSections[sectionIndex] = {
        ...section,
        subsections: newSubsections,
    };

    return withUpdatedCompletedStatusesInQuestionnaire({
        ...prevProjectOrTemplate,
        questionnaireSections: newQuestionnaireSections,
    });
};

export default withoutCustomSubsection;
