import api from 'Application/api/api';

const getDriverLicenseControl = (id, load, cancelToken = null) =>
    api.get(`/measures/vehicles/driver-licenses/controls/${id}`, {
        params: {
            load,
        },
        cancelToken,
    });

export default getDriverLicenseControl;
