import React from 'react';
import styled from 'styled-components/macro';
import { useRecoilValue } from 'recoil';
import colors from 'Common/constants/colors';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import Tile from 'ProjectManager/Project/Preview/Tile';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import StatusTimeline from 'ProjectManager/Project/Preview/Timeline/StatusTimeline/StatusTimeline';
import PrimaryButtons from 'ProjectManager/Project/Preview/Timeline/PrimaryButtons/PrimaryButtons';
import EditButton from 'ProjectManager/Project/Preview/Timeline/EditButton';
import Info from 'ProjectManager/Project/Preview/Timeline/Info';
import { useWindowWidth } from '@react-hook/window-size';

const Content = styled.div`
    > div {
        &:first-child {
            border-bottom: 1px solid ${colors.DARK_DIVIDER};
            padding-bottom: 30px;
        }

        &:nth-child(2) {
            padding-top: 30px;
        }
    }

    @media screen and (min-width: 520px) {
        display: flex;

        > div {
            flex: 1;
            padding-top: 15px !important;
            padding-bottom: 15px !important;

            &:first-child {
                position: relative;
                padding-right: 30px;
                border-bottom: 0;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 1px;
                    height: 100%;
                    background: ${colors.DARK_DIVIDER};
                }
            }

            &:nth-child(2) {
                padding-left: 30px;
            }
        }
    }
`;

const Actions = styled.div`
    display: flex;
    margin-top: 15px;

    > div {
        flex: 1 1 50%;

        &:first-child {
            padding: 0 30px 0 20px;
        }

        &:nth-child(2) {
            padding-left: 30px;
        }
    }
`;

const Action = styled.div`
    max-width: 200px;
    margin-top: 20px;
`;

const Timeline = () => {
    const { status } = useRecoilValue(projectAtom);

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 520;

    const isEditable = status !== projectStatuses.ARCHIVED;

    return (
        <Tile>
            <OuterBoxTitle>Einsatzzeiten</OuterBoxTitle>
            <Content>
                <div>
                    <StatusTimeline />
                    {isMobile && isEditable && (
                        <Action>
                            <PrimaryButtons />
                        </Action>
                    )}
                </div>
                <div>
                    <Info />
                    {isMobile && isEditable && (
                        <Action>
                            <EditButton />
                        </Action>
                    )}
                </div>
            </Content>
            {!isMobile && isEditable && (
                <Actions>
                    <PrimaryButtons />
                    <EditButton />
                </Actions>
            )}
        </Tile>
    );
};

export default Timeline;
