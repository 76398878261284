import React from 'react';
import PropTypes from 'prop-types';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import Modal from 'Common/components/Modals/Modal';

const UnansweredQuestionsGuideModal = ({
    unansweredQuestions,
    onCancel,
    onConfirm,
}) => (
    <Modal
        isOpen
        onRequestClose={onCancel}
        buttons={
            <>
                <Button text dark type="button" onClick={onCancel}>
                    Nein, danke
                </Button>
                <Button type="button" onClick={onConfirm}>
                    Leite mich
                </Button>
            </>
        }
    >
        <ModalTitle>
            Du hast {unansweredQuestions.length} Fragen nicht beantwortet
        </ModalTitle>
        <p>
            Willst du, dass der Amedic-Manager die leitet um welche Fragen es
            sich dabei handelt?
        </p>
        <p>
            Hinweis: Falls du die Fragen für das Projekt irrelevant sind und du
            diese aus dem Projekt entfernen (deaktivieren) willst, kannst du auf
            den switch Knopf in der rechten oberen Ecke der entsprechenden Frage
            drücken. Bitte beachte das das Beantworten/Deaktivieren aller Fragen
            Voraussetzung ist um fortzufahren.
        </p>
    </Modal>
);

UnansweredQuestionsGuideModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    unansweredQuestions: PropTypes.array.isRequired,
};

export default UnansweredQuestionsGuideModal;
