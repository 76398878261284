import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';
import { HelpRounded } from '@material-ui/icons';
import Button from 'Common/components/Button';
import { Popover } from '@material-ui/core';
import colors from 'Common/constants/colors';

const Title = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 27px;
    overflow-wrap: anywhere;
    word-break: break-word;

    ${ifProp(
        'disabled',
        css`
            opacity: 0.35;
        `,
    )};

    @media screen and (min-width: 1180px) {
        font-size: 22px;
        line-height: 33px;
    }
`;

const HelpButton = styled(Button)`
    position: relative;
    top: -7px;
    margin-left: 1px;

    @media screen and (min-width: 1180px) {
        top: -4px;
        margin-left: 3px;
    }
`;

const HelpText = styled.div`
    max-width: 300px;
    padding: 20px;
    color: ${colors.DARK_GRAY};
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;

    @media screen and (min-width: 400px) {
        max-width: 380px;
    }
`;

const OuterBoxTitle = ({ helpText, children, ...props }) => {
    const [helpPopoverAnchorEl, setHelpPopoverAnchorEl] = useState(null);

    const handleHelpButtonClick = e => {
        setHelpPopoverAnchorEl(e.currentTarget);
    };

    const handleCloseHelpPopover = () => {
        setHelpPopoverAnchorEl(null);
    };

    const isHelpPopoverOpened = Boolean(helpPopoverAnchorEl);

    return (
        <Title {...props}>
            {children}
            {Boolean(helpText) && (
                <>
                    <HelpButton
                        iconOnly
                        icon={<HelpRounded />}
                        type="button"
                        onClick={handleHelpButtonClick}
                    />
                    <Popover
                        open={isHelpPopoverOpened}
                        anchorEl={helpPopoverAnchorEl}
                        onClose={handleCloseHelpPopover}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <HelpText>{helpText}</HelpText>
                    </Popover>
                </>
            )}
        </Title>
    );
};

OuterBoxTitle.defaultProps = {
    helpText: null,
};

OuterBoxTitle.propTypes = {
    helpText: PropTypes.any,
};

export default OuterBoxTitle;
