import { get } from 'lodash';

const findQuestionInQuestionnaireSections = (
    questionnaireSections,
    questionId,
    isCustomQuestion,
) => {
    let foundSectionIndex;
    let foundSubsectionIndex;
    let foundQuestionIndex;

    search: for (
        let sectionIndex = 0;
        sectionIndex < questionnaireSections.length;
        sectionIndex++
    ) {
        const section = questionnaireSections[sectionIndex];

        for (
            let subsectionIndex = 0;
            subsectionIndex < section.subsections.length;
            subsectionIndex++
        ) {
            const subsection = section.subsections[subsectionIndex];

            const questionIndex = subsection.questions.findIndex(
                question =>
                    question.id === questionId &&
                    question.isCustom === isCustomQuestion,
            );

            if (questionIndex !== -1) {
                foundSectionIndex = sectionIndex;
                foundSubsectionIndex = subsectionIndex;
                foundQuestionIndex = questionIndex;
                break search;
            }
        }
    }

    if (
        foundSectionIndex === -1 ||
        foundSubsectionIndex === -1 ||
        foundQuestionIndex === -1
    ) {
        return {
            question: null,
            questionPath: null,
        };
    }

    const questionPath = `[${foundSectionIndex}].subsections[${foundSubsectionIndex}].questions[${foundQuestionIndex}]`;
    const question = get(questionnaireSections, questionPath);

    return {
        question,
        questionPath,
        sectionIndex: foundSectionIndex,
        subsectionIndex: foundSubsectionIndex,
        questionIndex: foundQuestionIndex,
    };
};

export default findQuestionInQuestionnaireSections;
