import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Textarea from 'Common/components/Form/Fields/Textarea';
import { useRecoilState } from 'recoil';
import styled from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import hasUnsavedChangesState from 'Common/recoil/hasUnsavedChangesState';
import axios from 'axios';
import { debounce } from 'lodash';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import changeIntroductionComment from 'ProjectManager/Project/Introductions/Common/api/changeIntroductionComment';
import withIntroductionComment from 'ProjectManager/Project/Common/recoil/project/modifiers/introduction/withIntroductionComment';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';

const Wrapper = styled.div`
    width: calc(100% - 10px);
    margin-top: 20px;

    @media screen and (min-width: 820px) {
        width: calc(50% - 10px);
    }
`;

const StyledTextarea = styled(Textarea)`
    textarea {
        min-height: initial;
        height: ${ifProp('$compact', 16, 48)}px !important;
        overflow: ${ifProp('$compact', 'hidden', 'auto')} !important;
        resize: none;
        transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1),
            height 200ms cubic-bezier(0, 0, 0.2, 1) !important;
    }
`;

const Comment = ({ introduction }) => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);
    const [, setHasUnsavedChanges] = useRecoilState(hasUnsavedChangesState);

    const [comment, setComment] = useState(introduction.comment ?? '');

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const debouncedSave = useCallback(
        debounce(
            async value => {
                try {
                    await changeIntroductionComment(
                        projectId,
                        introduction.id,
                        value,
                        source.token,
                    );

                    setProject(withIntroductionComment(introduction.id, value));

                    setHasUnsavedChanges(false);
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        notify(
                            'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                            {
                                type: toast.TYPE.ERROR,
                            },
                        );
                    }
                }
            },
            350,
            {
                leading: true,
                trailing: true,
            },
        ),
        [
            projectId,
            setProject,
            introduction.id,
            setHasUnsavedChanges,
            source.token,
        ],
    );

    const handleChange = e => {
        setHasUnsavedChanges(true);
        setComment(e.target.value);
        debouncedSave(e.target.value);
    };

    const [isCompact, setIsCompact] = useState(true);

    const handleFocus = () => {
        setIsCompact(false);
    };

    const handleBlur = () => {
        setIsCompact(true);
    };

    return (
        <Wrapper>
            <StyledTextarea
                id="comment"
                name="comment"
                label="Kommentar"
                value={comment}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                $compact={isCompact}
            />
        </Wrapper>
    );
};

export default Comment;
