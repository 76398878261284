import React from 'react';
import PropTypes from 'prop-types';
import Drawer from 'Common/components/Drawer';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import getPoints from 'ProjectManager/Project/Introductions/Common/api/getPoints';
import getPPEs from 'ProjectManager/Project/Introductions/Common/api/getPPEs';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';
import PointsSelection from 'ProjectManager/Project/Introductions/Grid/components/Introduction/EditPointsDrawer/PointsSelection';

const EditPointsDrawer = ({
    introduction,
    isOpened,
    onOpen,
    onClose,
    ...props
}) => {
    const {
        data: points,
        loadData: loadPoints,
        isLoading: isLoadingPoints,
        hasError: hasPointsError,
    } = useAxiosRequest(getPoints, []);

    const {
        data: ppes,
        loadData: loadPPEs,
        isLoading: isLoadingPPEs,
        hasError: hasPPEsError,
    } = useAxiosRequest(getPPEs, []);

    return (
        <Drawer
            isOpened={isOpened}
            onOpen={onOpen}
            onClose={onClose}
            backButtonProps={{
                title: 'Zurück zum',
                subtitle: 'Einweisung',
            }}
            {...props}
        >
            {isLoadingPoints || isLoadingPPEs ? (
                <Loader />
            ) : hasPointsError ? (
                <RetryLoading onRetry={loadPoints} />
            ) : hasPPEsError ? (
                <RetryLoading onRetry={loadPPEs} />
            ) : (
                <PointsSelection
                    points={points}
                    ppes={ppes}
                    introduction={introduction}
                />
            )}
        </Drawer>
    );
};

EditPointsDrawer.propTypes = {
    introduction: PropTypes.object.isRequired,
    isOpened: PropTypes.bool.isRequired,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default EditPointsDrawer;
