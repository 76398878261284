import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import DataManagementBottomButtons from 'ProjectManager/DataManagement/Common/components/DataManagementBottomButtons';
import Button from 'Common/components/Button';
import WarningModal from 'ProjectManager/DataManagement/EmployeeSelection/components/WarningModal';
import useEmployeeSelectionData from 'ProjectManager/DataManagement/EmployeeSelection/hooks/useEmployeeSelectionData';
import { ArrowForwardRounded } from '@material-ui/icons';
import EmployeeSelectionContent from 'ProjectManager/DataManagement/EmployeeSelection/components/EmployeeSelectionContent';
import useHasOtherPrimaryButton from 'ProjectManager/DataManagement/Common/hooks/useHasOtherPrimaryButton/useHasOtherPrimaryButton';

const Wrapper = styled.div``;

const EmployeeSelection = ({ onNextButtonClick }) => {
    const hasOtherPrimaryButton = useHasOtherPrimaryButton();

    const {
        participatingEmployees,
        showWarningModalWhenNoEmployeesAreSelected,
    } = useEmployeeSelectionData();

    const [isWarningModalOpened, setIsWarningModalOpened] = useState(false);

    const handleModalClose = () => {
        setIsWarningModalOpened(false);
    };

    const handleNextButtonClick = () => {
        if (
            participatingEmployees.length === 0 &&
            showWarningModalWhenNoEmployeesAreSelected
        ) {
            setIsWarningModalOpened(true);
        } else {
            onNextButtonClick();
        }
    };

    return (
        <Wrapper>
            <EmployeeSelectionContent />
            <DataManagementBottomButtons>
                <Button
                    text={hasOtherPrimaryButton}
                    icon={
                        hasOtherPrimaryButton ? (
                            <ArrowForwardRounded />
                        ) : undefined
                    }
                    iconPosition="right"
                    type="button"
                    onClick={handleNextButtonClick}
                >
                    Weiter
                </Button>
            </DataManagementBottomButtons>
            {isWarningModalOpened && (
                <WarningModal
                    onClose={handleModalClose}
                    onNextButtonClick={onNextButtonClick}
                />
            )}
        </Wrapper>
    );
};

EmployeeSelection.propTypes = {
    onNextButtonClick: PropTypes.func.isRequired,
};

export default EmployeeSelection;
