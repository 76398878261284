import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import {
    AssignmentTurnedInRounded,
    CloudDownloadRounded,
    HistoryRounded,
} from '@material-ui/icons';
import { useOnClickOutside } from 'crooks';
import { ifProp } from 'styled-tools';
import DriverLicenseSentEmailsTable from 'Measure/components/vehicle/driver-license/overview/DriverLicenseSentEmailsTable';
import isAuthorized from 'User/utils/isAuthorized';
import roles from 'User/constants/roles';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';
import subDays from 'date-fns/subDays';
import parseDate from 'Common/utils/parseDate';
import formatDate from 'Common/utils/formatDate';

const Wrapper = styled.div`
    display: block;
    width: 100%;
`;

const Toolbar = styled.div`
    position: relative;

    > div,
    > button {
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    > div > button {
        width: 100%;
    }

    @media screen and (min-width: 620px) {
        display: flex;
        justify-content: flex-start;

        > div,
        > button {
            width: auto;
            margin-right: 10px;
            margin-bottom: 0;

            &:last-child {
                margin-right: 0;
            }
        }

        > div > button {
            width: auto;
        }
    }
`;

const ActionsMenu = styled.div`
    position: absolute;
    z-index: 6;
    top: 55px;
    left: 0;
    width: 100%;
    padding: 20px 10px;
    font-size: 15px;
    background-color: ${colors.DIRTY_WHITE};
    text-align: left;
    box-shadow: -2px 1px 5px 1px rgba(0, 0, 0, 0.35);
    border-radius: 6px;

    @media screen and (min-width: 620px) {
        width: calc(50% - 5px);
    }

    @media screen and (min-width: 940px) {
        width: 310px;
    }
`;

const PreviousCheck = styled.div`
    display: flex;
    margin-bottom: 15px;
    line-height: 1.5;
    min-height: 35px;
    padding-right: 5px;

    &:last-child {
        margin-bottom: 0;
    }
`;

const PreviousCheckName = styled.button`
    flex: 1;
    background: none;
    border: 0 none;
    outline: 0 none;
    cursor: pointer;
    padding: 10px;
    color: ${colors.DARK_GRAY};
    text-align: left;
    border-radius: 6px;
    margin-right: 10px;

    &:hover,
    &:focus {
        background: ${colors.LIGHT_DIVIDER};
    }

    ${ifProp(
        'isSelected',
        css`
            font-weight: 500;
            pointer-events: none;
        `,
    )};
`;

const EmptyWrapper = styled.div`
    padding: 55px 20px;
    color: ${colors.GRAY};
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    border-radius: 6px;

    @media screen and (min-width: 940px) {
        padding-top: 125px;
        padding-bottom: 125px;
    }
`;

const DriverLicenseSentEmails = ({
    isExaminer,
    driverLicenseControl,
    selectedCheckId,
    onChangeSelectedCheckId,
}) => {
    const [
        isPreviousChecksMenuOpened,
        setIsPreviousChecksMenuOpened,
    ] = useState(false);

    const handlePreviousChecksButtonClick = () => {
        setIsPreviousChecksMenuOpened(prevIsOpened => !prevIsOpened);
    };

    const handleCurrentCheckButtonClick = () => {
        onChangeSelectedCheckId(null);
    };

    const handleClickOutsideActions = () => {
        setIsPreviousChecksMenuOpened(false);
    };

    const handlePreviousCheckClick = checkId => {
        setIsPreviousChecksMenuOpened(false);
        onChangeSelectedCheckId(checkId);
    };

    const previousChecksMenuButtonWrapperRef = useOnClickOutside(
        handleClickOutsideActions,
        !isPreviousChecksMenuOpened,
    );

    const driverLicense = driverLicenseControl.driverLicense;
    const nextCheck = driverLicense.nextCheck;
    const checks = driverLicense.checks;

    const selectedCheck = selectedCheckId
        ? checks.find(check => check.id === selectedCheckId)
        : checks[checks.length - 1];

    const dueDate = parseDate(driverLicense.nextCheck.dueDate);
    const reminderDate = subDays(
        parseDate(driverLicense.nextCheck.dueDate),
        14,
    );

    const previousChecks = [...checks];
    previousChecks.pop();

    return (
        <Wrapper>
            <Toolbar>
                <div ref={previousChecksMenuButtonWrapperRef}>
                    {isExaminer && previousChecks.length > 0 && (
                        <Button
                            outline
                            type="button"
                            icon={<HistoryRounded />}
                            onClick={handlePreviousChecksButtonClick}
                        >
                            Vorherige Kontrollen
                        </Button>
                    )}
                    {isPreviousChecksMenuOpened && (
                        <ActionsMenu>
                            {previousChecks.map(check => {
                                const pdfDocumentLink = isAuthorized([
                                    roles.EMPLOYEE,
                                ]) && !isExaminer
                                    ? `/api/measures/vehicles/driver-licenses/controls/download-reminders-document/${check.id}`
                                    : `/api/measures/vehicles/driver-licenses/controls/${driverLicenseControl.id}/download-reminders-document/${check.id}`;

                                return (
                                    <PreviousCheck key={check.id}>
                                        <PreviousCheckName
                                            isSelected={
                                                check.id === selectedCheckId
                                            }
                                            onClick={() =>
                                                handlePreviousCheckClick(
                                                    check.id,
                                                )
                                            }
                                        >
                                            {`Prüfprotokoll vom ${formatDate(
                                                parseDate(check.completedAt),
                                            )}`}
                                        </PreviousCheckName>
                                        <Button
                                            dark
                                            iconOnly
                                            icon={<CloudDownloadRounded />}
                                            href={pdfDocumentLink}
                                            target="_blank"
                                        />
                                    </PreviousCheck>
                                );
                            })}
                        </ActionsMenu>
                    )}
                </div>
                {selectedCheckId !== null && (
                    <Button
                        outline
                        type="button"
                        icon={<AssignmentTurnedInRounded />}
                        onClick={handleCurrentCheckButtonClick}
                    >
                        Aktuelle Kontrolle
                    </Button>
                )}
            </Toolbar>
            {nextCheck.isInVerificationPeriod || selectedCheckId !== null ? (
                <DriverLicenseSentEmailsTable
                    driverLicenseControl={driverLicenseControl}
                    sentEmails={[...selectedCheck.sentEmails].reverse()}
                />
            ) : (
                <EmptyWrapper>
                    {`Die nächste Kontrolle ist am ${formatDate(
                        dueDate,
                    )} fällig.`}
                    <br />
                    {`Die Kontrolle wird mit der Benachrichtigung am ${formatDate(
                        reminderDate,
                    )} freigeschaltet.`}
                </EmptyWrapper>
            )}
        </Wrapper>
    );
};

DriverLicenseSentEmails.defaultProps = {
    selectedCheckId: null,
};

DriverLicenseSentEmails.propTypes = {
    isExaminer: PropTypes.bool.isRequired,
    driverLicenseControl: PropTypes.object.isRequired,
    selectedCheckId: PropTypes.number,
    onChangeSelectedCheckId: PropTypes.func.isRequired,
};

export default DriverLicenseSentEmails;
