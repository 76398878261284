import React from 'react';
import ContentSection from 'ProjectManager/DataManagement/Common/components/ContentSection';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import ProjectCoordinatorsManagement from 'ProjectManager/Project/DataManagement/GeneralInformation/ProjectCoordinators/ProjectCoordinatorsManagement';

const ProjectCoordinators = () => (
    <ContentSection>
        <OuterBoxTitle
            helpText={
                <>
                    "SiGe-Koordinator(in)": Es ist ein geeigneter
                    SiGe-Koordinator zu bestellen. Entsprechend § 3 BaustellV
                    oder § 6 DGUV Vorschrift 1 (mit Weisungsbefugnis). Ist ab
                    spezifischen Rahmenbedingungen vom Bauherren zu stellen.
                </>
            }
        >
            SiGeKo
        </OuterBoxTitle>
        <ProjectCoordinatorsManagement />
    </ContentSection>
);

export default ProjectCoordinators;
