import React from 'react';
import PropTypes from 'prop-types';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import Modal from 'Common/components/Modals/Modal';

const WarningModal = ({ onClose, onNextButtonClick }) => (
    <Modal
        isOpen
        onRequestClose={onClose}
        buttons={
            <>
                <Button text dark type="button" onClick={onClose}>
                    Abbrechen
                </Button>
                <Button type="button" onClick={onNextButtonClick}>
                    Weiter
                </Button>
            </>
        }
    >
        <ModalTitle>Mitarbeiterzuweisung</ModalTitle>
        <p>
            Du hast keine Mitarbeiter in das Projekt einbezogen. Bist du dir
            sicher das du fortfahren willst? Die Zuweisung zum Projekt kann
            jederzeit angepasst werden.
        </p>
    </Modal>
);

WarningModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onNextButtonClick: PropTypes.func.isRequired,
};

export default WarningModal;
