import { useRecoilValue } from 'recoil';
import useProjectAreasData from 'ProjectManager/DataManagement/Areas/hooks/useProjectAreasData';
import useTemplateAreasData from 'ProjectManager/DataManagement/Areas/hooks/useTemplateAreasData';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';

const useAreasData = () => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useProjectAreasData();
    const dataFromTemplate = useTemplateAreasData();

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return {};
};

export default useAreasData;
