import React, { useEffect, useMemo, useState } from 'react';
import ConfirmClientChangeModal from 'ProjectManager/Project/DataManagement/GeneralInformation/Client/components/ConfirmClientChangeModal';
import mapClientToOption from 'ProjectManager/Project/DataManagement/GeneralInformation/Client/helpers/mapClientToOption';
import createProject from 'ProjectManager/Project/Common/api/createProject';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import changeProjectClient from 'ProjectManager/Project/Common/api/dataManagement/changeProjectClient';
import getClient from 'ProjectManager/Client/api/getClient';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import changeProjectClientContactPeople from 'ProjectManager/Project/Common/api/dataManagement/changeProjectClientContactPeople';
import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import withClient from 'ProjectManager/Project/Common/recoil/project/modifiers/withClient';
import withClientContactPeople from 'ProjectManager/Project/Common/recoil/project/modifiers/contactPerson/withClientContactPeople';
import useClientField from 'ProjectManager/Project/DataManagement/GeneralInformation/Client/hooks/useClientField';

const ClientForProjectData = props => {
    const [isLoading, setIsLoading] = useState(false);

    const [{ id: projectId, client, isCreated }, setProject] = useRecoilState(
        projectAtom,
    );

    const dispatch = useDispatch();

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const [
        isConfirmChangeModalOpened,
        setIsConfirmChangeModalOpened,
    ] = useState(false);

    const handleCreateButtonClick = () => {
        if (projectId) {
            // When a project has already been created, it already has a client.
            // So, first we have to verify that the change is desired.
            // The local state will trigger a confirmation pop-up to show.
            setIsConfirmChangeModalOpened(true);
        } else {
            openCreateModal();
        }
    };

    const handleCreateClientSuccess = async newClient => {
        if (projectId) {
            await changeProjectClient(projectId, newClient.id, source.token);

            // Load the same client, but with its contact people
            const clientResponse = await getClient(
                newClient.id,
                ['contactPeople'],
                source.token,
            );

            const newClientWithContactPeople = clientResponse.data;

            // Assign all the client's contact people
            await changeProjectClientContactPeople(
                projectId,
                newClientWithContactPeople.contactPeople.map(
                    contactPerson => contactPerson.id,
                ),
                source.token,
            );

            // Update the client in Recoil
            setProject(withClient(newClientWithContactPeople));

            // This is the creation of a new client with its contact people.
            // Those contact people are automatically assigned to the project.
            setProject(
                withClientContactPeople(
                    newClientWithContactPeople.contactPeople,
                ),
            );
        } else {
            // The project has not been created, yet.
            // So, let's create the project, using the newly created client
            // and redirect the user to the post-creation page.

            setIsLoading(true);

            try {
                const projectResponse = await createProject(
                    newClient.id,
                    source.token,
                );

                const newProject = projectResponse.data;

                // Load the same client, but with its contact people
                const clientResponse = await getClient(
                    newClient.id,
                    ['contactPeople'],
                    source.token,
                );

                const newClientWithContactPeople = clientResponse.data;

                // When creating a client for the first time and at the same time
                // creating a new project, assign all the client's contact people
                // to the newly created project.
                await changeProjectClientContactPeople(
                    newProject.id,
                    newClientWithContactPeople.contactPeople.map(
                        contactPerson => contactPerson.id,
                    ),
                    source.token,
                );

                setIsLoading(false);

                notify('Das Projekt wurde erfolgreich erstellt', {
                    type: toast.TYPE.SUCCESS,
                });

                dispatch(
                    push(`/project-manager/project/${newProject.id}/create`),
                );
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setIsLoading(false);

                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }
        }
    };

    // State, holding the newly selected client option.
    // The value is set when the option is selected from the drop-down field,
    // but it's used only after the change has been confirmed via the pop-up.
    const [potentialNewClientOption, setPotentialNewClientOption] = useState(
        null,
    );

    const handleChange = async option => {
        if (projectId) {
            // In order to update the project's client, the user has to confirm
            // that the change has not been done by accident, because switching a client
            // will wipe out some of the data that has already been entered.
            setPotentialNewClientOption(option);
            setIsConfirmChangeModalOpened(true);
        } else {
            // When there is no project and this is the first time a client is set,
            // this actually means that the project has not been created, yet.
            // So, let's create the project and redirect the user to the post-creation page.

            setIsLoading(true);

            try {
                const response = await createProject(
                    option.value,
                    source.token,
                );
                const newProject = response.data;

                setIsLoading(false);

                notify('Das Projekt wurde erfolgreich erstellt', {
                    type: toast.TYPE.SUCCESS,
                });

                dispatch(
                    push(`/project-manager/project/${newProject.id}/create`),
                );
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setIsLoading(false);

                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }
        }
    };

    // Used only when the project has already been created,
    // to confirm the change from one client to another.
    const handleConfirmClientChange = async () => {
        if (potentialNewClientOption) {
            // The client for the change was selected from the drop-down field
            try {
                await changeProjectClient(
                    projectId,
                    potentialNewClientOption.value,
                    source.token,
                );

                const response = await getClient(
                    potentialNewClientOption.value,
                    ['contactPeople'],
                    source.token,
                );

                const newClient = response.data;

                setPotentialNewClientOption(null);

                setProject(withClient(newClient));

                // Changing to another already existing client will automatically
                // clear the contact people field.
                setProject(withClientContactPeople([]));

                setIsConfirmChangeModalOpened(false);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }
        } else {
            // The client for the change is going to be created through the modal.
            // All the responsible logic for this is inside the creation modal and its handlers.
            setIsConfirmChangeModalOpened(false);
            openCreateModal();
        }
    };

    const handleCancelClientChange = () => {
        setPotentialNewClientOption(null);
        setIsConfirmChangeModalOpened(false);
    };

    const { field, openCreateModal } = useClientField({
        onCreateButtonClick: handleCreateButtonClick,
        onClientCreate: handleCreateClientSuccess,
        selectProps: {
            value: client ? mapClientToOption(client) : null,
            onChange: handleChange,
            isDisabled: isCreated || isLoading,
            showSpinningLoader: isLoading,
            ...props,
        },
    });

    return (
        <>
            {field}
            {isConfirmChangeModalOpened && (
                <ConfirmClientChangeModal
                    isOpen
                    onConfirm={handleConfirmClientChange}
                    onCancel={handleCancelClientChange}
                    shouldReturnFocusAfterClose={false}
                />
            )}
        </>
    );
};

export default ClientForProjectData;
