import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import getInstructionArea from 'Education/api/instruction-area/getInstructionArea';
import updateInstructionArea from 'Education/api/instruction-area/updateInstructionArea';
import FormField from 'Common/components/Form/FormField';
import Input from 'Common/components/Form/Fields/Input';
import Textarea from 'Common/components/Form/Fields/Textarea';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import axios from 'axios';
import RetryLoading from 'Common/components/RetryLoading';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import colors from 'Common/constants/colors';
import { Link } from 'react-router-dom';
import { orderBy } from 'lodash';

const Form = styled.form``;

const Instructions = styled.div`
    margin-top: 40px;
`;

const InstructionList = styled.ul`
    padding-left: 20px;
`;

const Instruction = styled(Link)`
    color: ${colors.PRIMARY};
    outline: 0 none;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Pflichtangabe'),
    description: Yup.string(),
});

const InstructionAreaEditModal = ({ areaId, onCancel, onSuccess }) => {
    const [isSavingChanges, setIsSavingChanges] = useState(false);

    const instructionAreaRequest = useCallback(
        cancelToken =>
            getInstructionArea(
                areaId,
                ['instructionsCount', 'instructions'],
                cancelToken,
            ),
        [areaId],
    );

    const {
        data: instructionArea,
        loadData: loadInstructionArea,
        isLoading,
        hasError,
    } = useAxiosRequest(instructionAreaRequest, null);

    const initialValues = useMemo(
        () =>
            instructionArea
                ? {
                      name: instructionArea.name,
                      description: instructionArea.description,
                  }
                : {
                      name: '',
                      description: '',
                  },
        [instructionArea],
    );

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const {
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
    } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async values => {
            if (areaId) {
                setIsSavingChanges(true);

                try {
                    await updateInstructionArea(
                        areaId,
                        {
                            name: values.name,
                            description: values.description,
                        },
                        source.token,
                    );

                    await onSuccess();

                    setIsSavingChanges(false);

                    notify('Der Bereich wurde aktualisiert', {
                        type: toast.TYPE.SUCCESS,
                    });
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        setIsSavingChanges(false);

                        notify('Der Bereich wurde nicht aktualisiert', {
                            type: toast.TYPE.ERROR,
                        });
                    }
                }
            }
        },
    });

    return (
        <Modal
            isOpen={areaId !== null}
            isLoading={isLoading}
            onRequestClose={isSavingChanges ? undefined : onCancel}
            maxHeight={480}
            buttons={
                !hasError &&
                instructionArea !== null && (
                    <>
                        <Button
                            text
                            dark
                            type="button"
                            onClick={onCancel}
                            disabled={isSavingChanges}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            type="button"
                            isLoading={isSavingChanges}
                            onClick={handleSubmit}
                            disabled={isSavingChanges || !isValid}
                        >
                            Speichern
                        </Button>
                    </>
                )
            }
        >
            {!isLoading &&
                (hasError ? (
                    <RetryLoading onRetry={loadInstructionArea} />
                ) : instructionArea === null ? (
                    <ModalTitle>
                        Dieser Bereiche ist nicht mehr verfügbar
                    </ModalTitle>
                ) : (
                    <>
                        <ModalTitle>Bereich Bearbeiten</ModalTitle>
                        <Form onSubmit={handleSubmit}>
                            <FormField>
                                <Input
                                    label="Bereich"
                                    id="name"
                                    name="name"
                                    error={errors.name}
                                    touched={touched.name}
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormField>
                            <FormField>
                                <Textarea
                                    label="Beschreibung"
                                    id="description"
                                    name="description"
                                    error={errors.description}
                                    touched={touched.description}
                                    value={values.description}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormField>
                            <Instructions>
                                <ModalTitle>
                                    {`${instructionArea.instructionsCount} Unterweisungen`}
                                </ModalTitle>
                                <InstructionList>
                                    {orderBy(
                                        instructionArea.instructions,
                                        ['name'],
                                        ['asc'],
                                    ).map(instruction => (
                                        <li key={instruction.id}>
                                            <Instruction
                                                to={`/education/instruction/${instruction.id}/presentation`}
                                            >
                                                {instruction.name}
                                            </Instruction>
                                        </li>
                                    ))}
                                </InstructionList>
                            </Instructions>
                        </Form>
                    </>
                ))}
        </Modal>
    );
};

InstructionAreaEditModal.defaultProps = {
    areaId: null,
};

InstructionAreaEditModal.propTypes = {
    areaId: PropTypes.number,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default InstructionAreaEditModal;
