import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';
import { SwipeableDrawer } from '@material-ui/core';
import { CloseRounded, FolderRounded } from '@material-ui/icons';
import Navigation from 'ProjectManager/DataManagement/Navigation/components/Navigation';
import { navigationItemsData } from 'ProjectManager/DataManagement/Navigation/constants/navigation';
import getQuestionnaireSectionIdentifier from 'ProjectManager/DataManagement/Questionnaire/helpers/getQuestionnaireSectionIdentifier';
import getQuestionnaireSectionIconComponent from 'ProjectManager/DataManagement/Questionnaire/helpers/getQuestionnaireSectionIconComponent';
import useQuestionnaireSections from 'ProjectManager/DataManagement/Questionnaire/hooks/questionnaireSections/useQuestionnaireSections';

const Wrapper = styled.button`
    display: block;
    width: 100%;
    padding: 12px;
    background: ${colors.DIRTY_WHITE};
    border: 0 none;
    border-left: 6px solid ${colors.PRIMARY};
    border-radius: 6px;
    outline: 0 none;
    text-align: left;
`;

const NameIconWrapper = styled.div`
    display: flex;
    align-items: flex-start;

    svg {
        color: ${colors.DARK_GRAY};
        margin-right: 10px;
    }
`;

const Name = styled.div`
    padding-top: 2px;
    color: ${colors.DARK_GRAY};
    font-size: 14px;
    line-height: 21px;
`;

const MoreSectionsHint = styled.div`
    padding-left: 34px;
    color: ${colors.LIGHT_GRAY};
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-decoration: underline;
`;

const AllSectionsDrawer = styled(SwipeableDrawer)`
    &.MuiDrawer-root {
        z-index: 9999 !important;
    }

    .MuiPaper-root {
        display: flex;
        width: calc(100vw - 10px);
        max-width: 340px;
        border-radius: 0 6px 6px 0;
        box-shadow: 0 7px 10px rgba(0, 0, 0, 0.16);
        background: ${colors.OUTER_BOX_BACKGROUND};
    }
`;

const AllSectionsWrapper = styled.div`
    padding: 0 20px;
`;

const DrawerCloseButton = styled(Button)``;

const DrawerCloseButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    position: sticky;
    width: 100%;
    top: 0;
    margin-bottom: 20px;
    padding: 10px 10px 0;
    background: ${colors.OUTER_BOX_BACKGROUND};
`;

const getNameByIdentifier = (questionnaireSections, identifier) => {
    let name;

    const isFromQuestionnaire =
        identifier.startsWith('SECTION_') ||
        identifier.startsWith('CUSTOM_SECTION_');

    if (isFromQuestionnaire) {
        const questionnaireSection = questionnaireSections.find(
            section =>
                identifier === getQuestionnaireSectionIdentifier(section),
        );

        name = questionnaireSection?.name;
    } else {
        name = navigationItemsData[identifier]?.name;
    }

    return name ?? 'Unknown';
};

const getIconComponentByIdentifier = (questionnaireSections, identifier) => {
    let Icon;

    const isFromQuestionnaire =
        identifier.startsWith('SECTION_') ||
        identifier.startsWith('CUSTOM_SECTION_');

    if (isFromQuestionnaire) {
        const questionnaireSection = questionnaireSections.find(
            section =>
                identifier === getQuestionnaireSectionIdentifier(section),
        );

        if (questionnaireSection) {
            Icon = getQuestionnaireSectionIconComponent(questionnaireSection);
        }
    } else {
        Icon = navigationItemsData[identifier]?.icon;
    }

    return Icon ?? FolderRounded;
};

const ActiveNavigationItem = ({ title, identifier, onChange, className }) => {
    const [isOpened, setIsOpened] = useState(false);

    const openDrawer = () => {
        setIsOpened(true);
    };

    const closeDrawer = () => {
        setIsOpened(false);
    };

    const handleActiveItemChange = (...args) => {
        closeDrawer();

        onChange(...args);
    };

    const questionnaireSections = useQuestionnaireSections();

    const name = getNameByIdentifier(questionnaireSections, identifier);

    const Icon = getIconComponentByIdentifier(
        questionnaireSections,
        identifier,
    );

    return (
        <>
            <Wrapper
                type="button"
                aria-label="open drawer"
                onClick={openDrawer}
                edge="start"
                className={className}
            >
                <NameIconWrapper>
                    {<Icon />}
                    <Name>{name}</Name>
                </NameIconWrapper>
                <MoreSectionsHint>Abschnitte anzeigen</MoreSectionsHint>
            </Wrapper>
            <AllSectionsDrawer
                anchor="left"
                open={isOpened}
                onOpen={openDrawer}
                onClose={closeDrawer}
            >
                <DrawerCloseButtonWrapper>
                    <DrawerCloseButton
                        iconOnly
                        icon={<CloseRounded />}
                        type="button"
                        onClick={closeDrawer}
                    />
                </DrawerCloseButtonWrapper>

                <AllSectionsWrapper>
                    <Navigation
                        title={title}
                        activeItem={identifier}
                        onActiveItemChange={handleActiveItemChange}
                        onSubsectionClicked={closeDrawer}
                    />
                </AllSectionsWrapper>
            </AllSectionsDrawer>
        </>
    );
};

ActiveNavigationItem.propsTypes = {
    title: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default ActiveNavigationItem;
