const withoutTemplate = templateId => prevTemplates => {
    const index = prevTemplates.findIndex(template => template.id === templateId);

    if (index !== -1) {
        const deletedTemplate = prevTemplates[index];

        const templates = [...prevTemplates];

        templates.splice(index, 1);

        return templates.map(template => {
            if (template.position > deletedTemplate.position) {
                return {
                    ...template,
                    position: template.position - 1,
                };
            }

            return template;
        });
    }

    return prevTemplates;
};

export default withoutTemplate;
