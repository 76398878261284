import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { prop } from 'styled-tools';
import colors from 'Common/constants/colors';
import projectStatusesElements from 'ProjectManager/Project/Common/constants/projectStatusesElements';

const Wrapper = styled.div`
    display: flex;
    align-items: center;

    @media screen and (min-width: 425px) {
        flex-direction: column;
    }
`;

const IconCircle = styled.div`
    display: flex;
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${prop('background')};
    border-radius: 50%;
    color: ${colors.DIRTY_WHITE};
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.3);

    @media screen and (min-width: 425px) {
        width: 60px;
        height: 60px;
        flex: 0 0 60px;
    }
`;

const Text = styled.div`
    margin-left: 10px;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;

    @media screen and (min-width: 425px) {
        margin-top: 10px;
        margin-left: 0;
    }
`;

const Status = ({ status }) => {
    const statusElement = projectStatusesElements[status];
    const Icon = statusElement.icon;

    return (
        <Wrapper>
            <IconCircle background={statusElement.color}>
                <Icon />
            </IconCircle>
            <Text>{statusElement.text}</Text>
        </Wrapper>
    );
};

Status.propTypes = {
    status: PropTypes.string.isRequired,
};

export default Status;
