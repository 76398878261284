import api from 'Application/api/api';
import formatDateAsISO from 'Common/utils/formatDateAsISO';

const uploadDriverLicenseFile = (
    driverLicenseControlId,
    file,
    type,
    uploadedAt,
    cancelToken = null,
) => {
    const formData = new FormData();

    formData.append('file', file);

    formData.append(
        'data',
        JSON.stringify({
            type,
            uploadedAt: formatDateAsISO(uploadedAt),
        }),
    );

    return api.post(
        `/measures/vehicles/driver-licenses/controls/${driverLicenseControlId}/driver-license/upload-file`,
        formData,
        {
            cancelToken,
            timeout: 30 * 1000,
        },
    );
};

export default uploadDriverLicenseFile;
