import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import updateProjectWorkingDays from 'ProjectManager/Project/Common/api/dataManagement/updateProjectWorkingDays';
import withWorkingDays from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/withWorkingDays';

const useProjectWorkingDaysData = () => {
    const [{ id: projectId, workingDays }, setProject] = useRecoilState(
        projectAtom,
    );

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const update = useCallback(
        async newWorkingDays => {
            await updateProjectWorkingDays(
                projectId,
                newWorkingDays,
                source.token,
            );

            setProject(withWorkingDays(newWorkingDays));
        },
        [projectId, setProject, source.token],
    );

    return projectId
        ? {
              initialWorkingDays: workingDays,
              updateWorkingDays: update,
              isRequired: true,
          }
        : {};
};

export default useProjectWorkingDaysData;
