import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Input from 'Common/components/Form/Fields/Input';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import hasUnsavedChangesState from 'Common/recoil/hasUnsavedChangesState';
import { debounce } from 'lodash';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { InputAdornment } from '@material-ui/core';
import Button from 'Common/components/Button';
import { LocationOnRounded } from '@material-ui/icons';
import getReverseGeocodeLocation from 'Application/api/getReverseGeocodeLocation';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import changeProjectAddress from 'ProjectManager/Project/Common/api/dataManagement/changeProjectAddress';
import withAddress from 'ProjectManager/Project/Common/recoil/project/modifiers/withAddress';

const Address = () => {
    const [
        { id: projectId, address: initialAddress },
        setProject,
    ] = useRecoilState(projectAtom);

    const [, setHasUnsavedChanges] = useRecoilState(hasUnsavedChangesState);

    const [isTouched, setIsTouched] = useState(false);
    const [address, setAddress] = useState(initialAddress ?? '');

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const debouncedSave = useCallback(
        debounce(
            async value => {
                try {
                    await changeProjectAddress(projectId, value, source.token);

                    setProject(withAddress(value));

                    setHasUnsavedChanges(false);
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        notify(
                            'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                            {
                                type: toast.TYPE.ERROR,
                            },
                        );
                    }
                }
            },
            350,
            {
                leading: true,
                trailing: true,
            },
        ),
        [projectId, setProject, setHasUnsavedChanges, source.token],
    );

    const changeAddressState = value => {
        setIsTouched(true);
        setHasUnsavedChanges(true);
        setAddress(value);

        if (projectId) {
            debouncedSave(value);
        }
    };

    const handleChange = e => {
        changeAddressState(e.target.value);
    };

    const [isDetectingLocation, setIsDetectingLocation] = useState(false);

    const reverseGeocodeLocation = async position => {
        setIsDetectingLocation(true);

        try {
            const response = await getReverseGeocodeLocation(
                position.coords.latitude,
                position.coords.longitude,
                source.token,
            );

            changeAddressState(response.data.address ?? '');
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }

        setIsDetectingLocation(false);
    };

    const detectLocation = async () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                reverseGeocodeLocation,
                () => {
                    notify('Ihr Standort kann nicht bestimmt werden', {
                        type: toast.TYPE.WARNING,
                    });
                },
            );
        } else {
            notify('Ihr Standort kann nicht bestimmt werden', {
                type: toast.TYPE.WARNING,
            });
        }
    };

    return (
        <Input
            id="address"
            name="address"
            label="Projektadresse"
            value={address}
            onChange={handleChange}
            disabled={!projectId || isDetectingLocation}
            touched={isTouched}
            error={address === '' ? 'Pflichtangabe' : undefined}
            isRequired
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Button
                            dark
                            iconOnly
                            icon={<LocationOnRounded />}
                            isLoading={isDetectingLocation}
                            type="button"
                            onClick={detectLocation}
                            tooltip="Projektstandort"
                        />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default Address;
