import api from 'Application/api/api';

const addProjectCoordinator = (projectId, data, cancelToken = null) =>
    api.post(
        `project-manager/projects/${projectId}/project-coordinators`,
        data,
        { cancelToken },
    );

export default addProjectCoordinator;
