import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import InnerBox from 'Common/components/Boxes/InnerBox';
import ShowHideControl from 'ProjectManager/DataManagement/Questionnaire/components/Question/ShowHideControl/components/ShowHideControl';
import Answers from 'ProjectManager/DataManagement/Questionnaire/components/Question/Answers/components/Answers';
import AddCommentButton from 'ProjectManager/DataManagement/Questionnaire/components/Question/Comment/components/AddCommentButton';
import Comment from 'ProjectManager/DataManagement/Questionnaire/components/Question/Comment/components/Comment';
import MoreActionsButton from 'Common/components/MoreActionsButton/MoreActionsButton';
import AddCommentMenuItem from 'ProjectManager/DataManagement/Questionnaire/components/Question/Comment/components/AddCommentMenuItem';
import UploadButton from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/components/Upload/UploadButton';
import UploadMenuItem from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/components/Upload/UploadMenuItem';
import Files from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/components/Files';
import useWindowScroll from '@react-hook/window-scroll';
import useWindowSize from '@react-hook/window-size';
import { ifProp } from 'styled-tools';
import Loader from 'Common/components/Loader';
import { useRecoilValue } from 'recoil';
import questionWithOpenedImagePreviewAtom from 'ProjectManager/DataManagement/Common/recoil/questionWithOpenedImagePreview/questionWithOpenedImagePreviewAtom';
import ImagePreview from 'ProjectManager/DataManagement/Questionnaire/components/Question/ImagePreview';
import DeleteCustomQuestionButton from 'ProjectManager/DataManagement/Questionnaire/components/Question/DeleteCustomQuestion/components/DeleteCustomQuestionButton';
import DeleteCustomQuestionMenuItem from 'ProjectManager/DataManagement/Questionnaire/components/Question/DeleteCustomQuestion/components/DeleteCustomQuestionMenuItem';
import getQuestionnaireQuestionIdentifier from 'ProjectManager/DataManagement/Questionnaire/helpers/getQuestionnaireQuestionIdentifier';
import questionInGuidanceModeState from 'ProjectManager/DataManagement/Questionnaire/components/Questionnaire/recoil/questionInGuidanceModeState';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';

const Wrapper = styled(InnerBox)`
    position: relative;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    ${ifProp(
        'isDisabled',
        css`
            opacity: 0.25;
            pointer-events: none;
        `,
    )};

    ${ifProp(
        'isInGuidanceMode',
        css`
            box-shadow: 0 0 0 999999px rgba(0, 0, 0, 0.55);
            pointer-events: all;
            overflow: hidden;
            z-index: 2;
        `,
    )};
`;

const Inner = styled.div`
    padding: 5px 15px 15px;
`;

const PreviewImageWrapper = styled.div`
    margin-top: 7px;
`;

const AnswersWrapper = styled.div`
    margin-top: 15px;
`;

const HeadingRow = styled.div`
    @media screen and (min-width: 560px) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
`;

const Title = styled.div`
    padding-top: 10px;
    font-size: 14px;
    line-height: 21px;

    ${ifProp(
        'isInactive',
        css`
            opacity: 0.35;
        `,
    )};

    @media screen and (min-width: 560px) {
        order: 1;
    }
`;

const Controls = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (min-width: 560px) {
        order: 2;
        flex: 1 0 auto;
        justify-content: flex-end;
        margin-left: 20px;
    }
`;

const MobileButtons = styled.div`
    @media screen and (min-width: 860px) {
        display: none;
    }
`;

const DesktopButtons = styled.div`
    @media screen and (max-width: 859px) {
        display: none;
    }

    > button {
        margin-right: 5px;

        &:last-child {
            margin-right: 0;
        }
    }
`;

const StyledShowHideControl = styled(ShowHideControl)`
    @media screen and (max-width: 559px) {
        position: relative;
        left: -10px;
    }
`;

const StyledLoader = styled(Loader)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const FilesWrapper = styled.div`
    margin: 30px 0;

    &:last-child {
        margin-bottom: 0;
    }
`;

const Question = ({ question }) => {
    const questionId = getQuestionnaireQuestionIdentifier(question);

    const questionInGuidanceMode = useRecoilValue(questionInGuidanceModeState);

    const isInGuidanceMode =
        questionInGuidanceMode !== null &&
        questionId ===
            getQuestionnaireQuestionIdentifier(questionInGuidanceMode);

    const [isShowing, setIsShowing] = useState(false);

    const questionWithOpenedImagePreview = useRecoilValue(
        questionWithOpenedImagePreviewAtom,
    );

    const isDisabled =
        questionWithOpenedImagePreview !== null &&
        (questionWithOpenedImagePreview.id !== question.id ||
            questionWithOpenedImagePreview.isCustom !== question.isCustom);

    const handleBeforeShow = () => {
        setIsShowing(true);
    };

    const handleAfterShow = () => {
        setIsShowing(false);
    };

    const wrapperRef = useRef(null);
    const scrollY = useWindowScroll();
    const [, windowHeight] = useWindowSize();

    const scrollToComment = () => {
        const distanceToQuestion = wrapperRef.current.getBoundingClientRect()
            .top;

        const questionHeight = wrapperRef.current.getBoundingClientRect()
            .height;

        window.scroll({
            top: scrollY + distanceToQuestion + questionHeight - windowHeight,
            left: 0,
            behavior: 'smooth',
        });
    };

    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);
    const isProject = dataManagementEntity === dataManagementEntities.PROJECT;

    const { status: projectStatus } = useRecoilValue(projectAtom);
    const isDeletingPossible =
        !isProject || projectStatus !== projectStatuses.ENDED;

    return (
        <Wrapper
            id={`questionnaireQuestion-${questionId}`}
            ref={wrapperRef}
            isDisabled={isDisabled}
            isInGuidanceMode={isInGuidanceMode}
        >
            <Inner>
                <HeadingRow>
                    <Controls>
                        <DesktopButtons>
                            <UploadButton
                                question={question}
                                disabled={!question.isVisible}
                            />
                            <AddCommentButton
                                question={question}
                                disabled={!question.isVisible}
                            />
                            {question.isCustom && (
                                <DeleteCustomQuestionButton
                                    question={question}
                                    disabled={
                                        !question.isVisible ||
                                        !isDeletingPossible
                                    }
                                    tooltip={
                                        !isDeletingPossible
                                            ? 'Löschen nicht erlaubt. Projekt ist im Status Beendet.'
                                            : ''
                                    }
                                />
                            )}
                        </DesktopButtons>
                        <StyledShowHideControl
                            question={question}
                            disabled={isShowing}
                            onBeforeShow={handleBeforeShow}
                            onAfterShow={handleAfterShow}
                        />
                        <MobileButtons>
                            <MoreActionsButton disabled={!question.isVisible}>
                                <UploadMenuItem question={question} />
                                <AddCommentMenuItem
                                    question={question}
                                    onAdd={scrollToComment}
                                />
                                {question.isCustom && (
                                    <DeleteCustomQuestionMenuItem
                                        question={question}
                                        disabled={!isDeletingPossible}
                                        tooltip={
                                            !isDeletingPossible
                                                ? 'Löschen nicht erlaubt. Projekt ist im Status Beendet.'
                                                : ''
                                        }
                                    />
                                )}
                            </MoreActionsButton>
                        </MobileButtons>
                    </Controls>
                    <Title isInactive={!question.isVisible}>
                        {question.text}
                        {question.isVisible && question.imageUrl && (
                            <PreviewImageWrapper>
                                <ImagePreview question={question} />
                            </PreviewImageWrapper>
                        )}
                    </Title>
                    {isShowing && <StyledLoader size={24} />}
                </HeadingRow>
                {question.isVisible && (
                    <>
                        <AnswersWrapper>
                            <Answers question={question} />
                        </AnswersWrapper>
                        {question.comment !== null && (
                            <Comment question={question} />
                        )}
                        {question.files.length > 0 && (
                            <FilesWrapper>
                                <Files question={question} />
                            </FilesWrapper>
                        )}
                    </>
                )}
            </Inner>
        </Wrapper>
    );
};

Question.propTypes = {
    question: PropTypes.object.isRequired,
};

export default Question;
