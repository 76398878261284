import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from 'Application/components/Header/Header';
import getAvailableModules from 'Company/api/getAvailableModules';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';

const LaravelHeader = ({ isManager }) => {
    const { data: availableModules, hasError } = useAxiosRequest(
        getAvailableModules,
        [],
    );

    useEffect(() => {
        if (hasError) {
            notify(
                'Ein Fehler ist aufgetreten. Bitte versuchen Sie, die Seite zu aktualisieren.',
                {
                    type: toast.TYPE.ERROR,
                },
            );
        }
    }, [hasError]);

    return (
        <Header
            isManager={isManager}
            availableModules={availableModules}
            isReact={false}
        />
    );
};

LaravelHeader.propTypes = {
    isManager: PropTypes.bool.isRequired,
};

export default LaravelHeader;
