import React, { useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { useRecoilState } from 'recoil';
import hasUnsavedChangesState from 'Common/recoil/hasUnsavedChangesState';
import axios from 'axios';
import getInstructionAreas from 'Education/api/instruction-area/getInstructionAreas';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import { debounce, orderBy } from 'lodash';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { CheckBoxOutlineBlankRounded, CheckRounded } from '@material-ui/icons';
import colors from 'Common/constants/colors';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';
import ErrorMessage from 'Common/components/Form/ErrorMessage';
import EmployeeWarningModal from 'ProjectManager/DataManagement/Areas/components/EmployeeWarningModal';
import useAreasData from 'ProjectManager/DataManagement/Areas/hooks/useAreasData';
import filterEmployeesWithoutOtherAreas from 'ProjectManager/DataManagement/Areas/helpers/filterEmployeesWithoutOtherAreas';
import ContentSection from 'ProjectManager/DataManagement/Common/components/ContentSection';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import OuterBoxSubtitle from 'Common/components/Boxes/OuterBoxSubtitle';

const Wrapper = styled.div`
    max-width: 705px;
`;

const Area = styled.button`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
    margin: 0 10px 10px 0;
    text-align: left;
    outline: 0 none;
    border: 1px solid ${colors.SEARCH_FIELD_BORDER};
    border-radius: 6px;
    cursor: pointer;

    ${ifProp(
        'isSelected',
        css`
            background: ${colors.DIRTY_WHITE};
            color: ${colors.DARK_GRAY};
        `,
        css`
            background: transparent;
            color: ${colors.DIRTY_WHITE};
        `,
    )};

    @media only screen and (min-width: 580px) {
        display: inline-flex;
        width: 210px;
    }
`;

const Name = styled.div`
    margin-left: 7px;
    font-size: 14px;
    line-height: 21px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const CheckBox = styled(CheckBoxOutlineBlankRounded)`
    color: ${colors.SEARCH_FIELD_BORDER};
`;

const Check = styled(CheckRounded)`
    color: ${colors.DARK_GRAY};
`;

const Areas = () => {
    const {
        selectedAreas,
        participatingEmployees,
        updateAreas,
        isRequired,
    } = useAreasData();

    const [isTouched, setIsTouched] = useState(false);

    const [selectedAreaIds, setSelectedAreaIds] = useState(() =>
        selectedAreas.map(area => area.id),
    );

    const instructionAreasRequest = useCallback(
        cancelToken => getInstructionAreas([], cancelToken),
        [],
    );

    const { data: instructionAreas } = useAxiosRequest(
        instructionAreasRequest,
        [],
    );

    const [, setHasUnsavedChanges] = useRecoilState(hasUnsavedChangesState);

    const debouncedSave = useCallback(
        debounce(
            async areaIds => {
                try {
                    await updateAreas(areaIds);
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        notify(
                            'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                            {
                                type: toast.TYPE.ERROR,
                            },
                        );
                    }
                }

                setHasUnsavedChanges(false);
            },
            350,
            {
                leading: true,
                trailing: true,
            },
        ),
        [updateAreas, setHasUnsavedChanges],
    );

    const [
        areaIdForEmployeeWarningModal,
        setAreaIdForEmployeeWarningModal,
    ] = useState(null);

    const handleEmployeeWarningModalClose = () => {
        setAreaIdForEmployeeWarningModal(null);
    };

    const handleAreaClick = clickedId => {
        setIsTouched(true);
        setHasUnsavedChanges(true);

        const employeesWithoutOtherAreas = filterEmployeesWithoutOtherAreas(
            participatingEmployees,
            selectedAreaIds,
            clickedId,
        );

        if (employeesWithoutOtherAreas.length > 0) {
            setAreaIdForEmployeeWarningModal(clickedId);
        } else {
            setSelectedAreaIds(prevSelectedAreaIds => {
                const newAreaIds = [...prevSelectedAreaIds];

                const index = newAreaIds.indexOf(clickedId);

                if (index === -1) {
                    newAreaIds.push(clickedId);
                } else {
                    newAreaIds.splice(index, 1);
                }

                debouncedSave(newAreaIds);

                return newAreaIds;
            });
        }
    };

    const showEmployeeWarningModal = areaIdForEmployeeWarningModal !== null;

    const areaForWarningModal = showEmployeeWarningModal
        ? instructionAreas.find(
              area => area.id === areaIdForEmployeeWarningModal,
          )
        : null;

    const employeesForWarningModal = showEmployeeWarningModal
        ? orderBy(
              filterEmployeesWithoutOtherAreas(
                  participatingEmployees,
                  selectedAreaIds,
                  areaIdForEmployeeWarningModal,
              ),
              ['lastName', 'firstName'],
              ['asc', 'asc'],
          )
        : [];

    return (
        <>
            <ContentSection>
                <OuterBoxTitle>Unterweisungsbereiche</OuterBoxTitle>
                {isRequired && (
                    <OuterBoxSubtitle>
                        Bitte weise dem Projekt mindestens einen relevanten
                        Bereich zu
                    </OuterBoxSubtitle>
                )}
                <Wrapper>
                    {instructionAreas.map(area => {
                        const isSelected = selectedAreaIds.includes(area.id);

                        return (
                            <Area
                                key={area.id}
                                isSelected={isSelected}
                                onClick={() => handleAreaClick(area.id)}
                            >
                                {isSelected ? <Check /> : <CheckBox />}
                                <Name>{area.name}</Name>
                            </Area>
                        );
                    })}
                </Wrapper>
                {isRequired && selectedAreaIds.length === 0 && isTouched && (
                    <ErrorMessage message="Bitte wählen Sie mindestens einen Bereich aus." />
                )}
            </ContentSection>
            {showEmployeeWarningModal && (
                <EmployeeWarningModal
                    onClose={handleEmployeeWarningModalClose}
                    areaName={areaForWarningModal.name}
                    employees={employeesForWarningModal}
                />
            )}
        </>
    );
};

export default Areas;
