import React from 'react';
import styled from 'styled-components/macro';
import DownloadAll from 'ProjectManager/Project/ProjectMediaFiles/components/Sidebar/QuickActions/DownloadAll/DownloadAll';
import DownloadGeneralFiles from 'ProjectManager/Project/ProjectMediaFiles/components/Sidebar/QuickActions/DownloadGeneralFiles/DownloadGeneralFiles';
import DownloadQuestionFiles from 'ProjectManager/Project/ProjectMediaFiles/components/Sidebar/QuickActions/DownloadQuestionFiles/DownloadQuestionFiles';

const Wrapper = styled.div`
    > * {
        width: 100%;
    }
`;

const QuickActions = () => (
    <Wrapper>
        <DownloadAll />
        <DownloadGeneralFiles />
        <DownloadQuestionFiles />
    </Wrapper>
);

export default QuickActions;
