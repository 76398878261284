import React, { useState } from 'react';
import PropTypes from 'prop-types';
import steps from 'ProjectManager/Project/Preview/QuickActions/DownloadAction/constants/steps';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import dataTypes from 'ProjectManager/Project/Preview/QuickActions/DownloadAction/constants/dataTypes';
import useSimpleDataTypeSelectionModal from 'ProjectManager/Project/Preview/QuickActions/DownloadAction/hooks/useSimpleDataTypeSelectionModal';
import useDetailedDataTypeSelectionModal from 'ProjectManager/Project/Preview/QuickActions/DownloadAction/hooks/useDetailedDataTypeSelectionModal';
import Modal from 'Common/components/Modals/Modal';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import useSuccessModal from 'ProjectManager/Project/Preview/QuickActions/DownloadAction/hooks/useSuccessModal';

const DownloadModal = ({ onClose }) => {
    const {
        files,
        questionnaireSections,
        statistics: { participantsWithInstructionCertificates },
    } = useRecoilValue(projectAtom);

    const hasInstructionCertificates =
        participantsWithInstructionCertificates > 0;

    const hasGeneralFiles = files.length > 0;

    const hasQuestionFiles = questionnaireSections.some(section =>
        section.subsections.some(subsection =>
            subsection.questions.some(question => question.files.length > 0),
        ),
    );

    const [step, setStep] = useState(steps.SIMPLE_DATA_TYPE_SELECTION);

    const [simpleDataType, setSimpleDataType] = useState(null);

    const [requestedData, setRequestedData] = useState(() => {
        const initialContentTypes = [dataTypes.PDF_REPORT];

        if (hasInstructionCertificates) {
            initialContentTypes.push(dataTypes.INSTRUCTION_CERTIFICATES);
        }

        if (hasGeneralFiles) {
            initialContentTypes.push(dataTypes.GENERAL_FILES);
        }

        if (hasQuestionFiles) {
            initialContentTypes.push(dataTypes.QUESTION_FILES);
        }

        return initialContentTypes;
    });

    const {
        props: simpleDataTypeSelectionProps,
        content: simpleDataTypeSelectionContent,
    } = useSimpleDataTypeSelectionModal({
        value: simpleDataType,
        onChange: setSimpleDataType,
        onClose,
        onNextStepButtonClick: () =>
            setStep(steps.DETAILED_DATA_TYPE_SELECTION),
    });

    const {
        props: detailedDataTypeSelectionProps,
        content: detailedDataTypeSelectionContent,
    } = useDetailedDataTypeSelectionModal({
        value: requestedData,
        onChange: setRequestedData,
        onClose,
        onBackButtonClick: () => setStep(steps.SIMPLE_DATA_TYPE_SELECTION),
        onSuccess: () => setStep(steps.SUCCESS),
    });

    const { props: successProps, content: successContent } = useSuccessModal({
        requestedData,
        onClose,
    });

    let props = {};
    let content = null;

    // eslint-disable-next-line default-case
    switch (step) {
        case steps.SIMPLE_DATA_TYPE_SELECTION:
            props = simpleDataTypeSelectionProps;
            content = simpleDataTypeSelectionContent;
            break;

        case steps.DETAILED_DATA_TYPE_SELECTION:
            props = detailedDataTypeSelectionProps;
            content = detailedDataTypeSelectionContent;
            break;

        case steps.SUCCESS:
            props = successProps;
            content = successContent;
            break;
    }

    return (
        <Modal isOpen onRequestClose={onClose} {...props}>
            <ModalTitle>
                {step === steps.SUCCESS
                    ? 'Angeforderte Projektunterlagen'
                    : 'Report anfordern'}
            </ModalTitle>
            {content}
        </Modal>
    );
};

DownloadModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default DownloadModal;
