import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useIsNextButtonAvailableForProject
    from 'ProjectManager/DataManagement/TopicManagement/hooks/isNextButtonAvailable/useIsNextButtonAvailableForProject';
import useIsNextButtonAvailableForTemplate
    from 'ProjectManager/DataManagement/TopicManagement/hooks/isNextButtonAvailable/useIsNextButtonAvailableForTemplate';

const useIsNextButtonAvailable = () => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useIsNextButtonAvailableForProject();
    const dataFromTemplate = useIsNextButtonAvailableForTemplate();

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return [];
};

export default useIsNextButtonAvailable;
