import React from 'react';
import styled from 'styled-components/macro';
import { AssignmentRounded } from '@material-ui/icons';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import NoIntroductions from 'ProjectManager/Project/Introductions/Common/components/NoIntroductions';
import CreateButton from 'ProjectManager/Project/Introductions/Create/components/CreateButton';
import IntroductionsGrid from 'ProjectManager/Project/Introductions/Grid/components/IntroductionsGrid';

const Heading = styled.div`
    margin-bottom: 23px;

    @media screen and (min-width: 520px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

const Title = styled.div`
    display: flex;
    margin-bottom: 20px;

    @media screen and (min-width: 520px) {
        margin-bottom: 0;
    }
`;

const StyledAssignmentRounded = styled(AssignmentRounded)`
    font-size: 28px !important;
`;

const StyledOuterBoxTitle = styled(OuterBoxTitle)`
    margin-left: 10px;
    margin-bottom: 0;
`;

const Content = () => {
    const { introductions } = useRecoilValue(projectAtom);

    return (
        <>
            <Heading>
                <Title>
                    <StyledAssignmentRounded />
                    <StyledOuterBoxTitle>Einweisung</StyledOuterBoxTitle>
                </Title>
                {introductions.length > 0 && <CreateButton />}
            </Heading>
            {introductions.length === 0 ? (
                <NoIntroductions />
            ) : (
                <IntroductionsGrid />
            )}
        </>
    );
};

export default Content;
