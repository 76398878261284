import { useCallback, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import getProject from 'ProjectManager/Project/Common/api/getProject';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';

const useProjectForDataManagement = (id, load = null) => {
    const setDataManagementEntity = useSetRecoilState(
        dataManagementEntityAtom,
    );

    useEffect(() => {
        setDataManagementEntity(dataManagementEntities.PROJECT);
    }, [setDataManagementEntity]);

    const [project, setProject] = useRecoilState(projectAtom);

    const projectRequest = useCallback(
        cancelToken =>
            getProject(
                id,
                [
                    'client.contactPeople',
                    'clientContactPeople',
                    'supervisors',
                    'projectCoordinators',
                    'areas',
                    'files',
                    'participants',
                    'participants.employee.user',
                    'participants.employee.areas',
                    'topics',
                    'customTopics',
                    'questionnaireSections',
                    'questionnaireSections.subsections',
                    'questionnaireSections.subsections.questions',
                    'questionnaireSections.subsections.questions.files',
                    'introductions',
                    ...(load ?? []),
                ],
                cancelToken,
            ),
        [id, load],
    );

    const {
        loadData: loadProject,
        isLoading,
        hasError,
        error,
    } = useAxiosRequest(projectRequest, null, {
        customDataSetterFunction: setProject,
    });

    return {
        project,
        setProject,
        loadProject,
        isLoading,
        hasError,
        error,
    };
};

export default useProjectForDataManagement;
