import api from 'Application/api/api';

const changeProjectClientContactPeople = (
    projectId,
    clientContactPeopleIds,
    cancelToken = null,
) =>
    api.post(
        `project-manager/projects/${projectId}/change-client-contact-people`,
        {
            clientContactPeopleIds,
        },
        { cancelToken },
    );

export default changeProjectClientContactPeople;
