import React from 'react';
import styled from 'styled-components/macro';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';
import colors from 'Common/constants/colors';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Progress = styled.div`
    position: relative;
    flex: 1;
    height: 10px;
    background: ${colors.SEARCH_FIELD_BORDER};
    border-radius: 10px;
    margin-right: 8px;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: ${prop('percentage')}%;
        height: 10px;
        background: ${prop('color')};
        border-radius: 10px;
    }
`;

const ProgressNumber = styled.div`
    width: 48px;
    flex: 0 0 48px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
`;

const ProgressBar = ({ className, percentage, color }) => (
    <Wrapper className={className}>
        <Progress percentage={percentage} color={color} />
        <ProgressNumber>{Math.floor(percentage).toFixed(0)}%</ProgressNumber>
    </Wrapper>
);

ProgressBar.defaultProps = {
    color: colors.PRIMARY,
};

ProgressBar.propTypes = {
    percentage: PropTypes.number.isRequired,
    color: PropTypes.string,
};

export default ProgressBar;
