import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Loader from 'Common/components/Loader';
import getAllMeasureTasksByEmployee from 'Measure/api/getAllMeasureTasksByEmployee';
import getAllMeasureTasks from 'Measure/api/getAllMeasureTasks';
import MeasureTasksTable from 'Employee/components/View/Measure/MeasureTasksTable';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';
import OuterBox from 'Common/components/Boxes/OuterBox';

const Wrapper = styled(OuterBox)`
    display: flex;
    flex-direction: column;
    padding: 20px 0;
`;

const LoaderWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

const AllEmployeeMeasureTasks = ({ employeeId }) => {
    const measureTasksRequest = useCallback(
        cancelToken => {
            if (employeeId) {
                return getAllMeasureTasksByEmployee(employeeId, cancelToken);
            }

            return getAllMeasureTasks(cancelToken);
        },
        [employeeId],
    );

    const {
        data: measureTasks,
        loadData: loadMeasureTasks,
        isLoading,
        hasError,
    } = useAxiosRequest(measureTasksRequest, []);

    return (
        <Wrapper>
            {isLoading ? (
                <LoaderWrapper>
                    <Loader />
                </LoaderWrapper>
            ) : hasError ? (
                <RetryLoading onRetry={loadMeasureTasks} />
            ) : (
                <MeasureTasksTable
                    measureTasks={measureTasks}
                    employeeId={employeeId}
                />
            )}
        </Wrapper>
    );
};

AllEmployeeMeasureTasks.defaultProps = {
    employeeId: null,
};

AllEmployeeMeasureTasks.propTypes = {
    employeeId: PropTypes.number,
};

export default AllEmployeeMeasureTasks;
