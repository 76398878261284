import api from 'Application/api/api';

const deleteTemplateCustomSubsection = (templateId, customSubsectionId, cancelToken = null) =>
    api.delete(
        `project-manager/templates/${templateId}/questionnaire/custom-subsections/${customSubsectionId}`,
        {
            cancelToken,
        },
    );

export default deleteTemplateCustomSubsection;
