import React from 'react';
import styled from 'styled-components/macro';

import { prop } from 'styled-tools';
import Loader from 'Common/components/Loader';
import getDriverLicenseTasksProgress from 'Measure/api/driver-license/getDriverLicenseTasksProgress';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';
import colors from 'Common/constants/colors';
import InnerBox from 'Common/components/Boxes/InnerBox';

const Wrapper = styled.div`
    display: block;
    text-align: center;
    padding: 20px 0;
`;

const ProgressTitle = styled.div`
    display: flex;
    padding-bottom: 15px;
    color: ${colors.DIRTY_WHITE};
`;

const Bar = styled(InnerBox)`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 4px;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: ${prop('progress')}%;
        height: 100%;
        background: ${colors.PRIMARY_LIGHT};
    }
`;

const Number = styled.div`
    position: relative;
    z-index: 1;
    color: ${colors.DIRTY_WHITE};
    font-size: 12px;
`;

const DriverLicenseControlProgress = () => {
    const {
        data: stats,
        loadData: loadStats,
        isLoading,
        hasError,
    } = useAxiosRequest(getDriverLicenseTasksProgress, {
        pending: 0,
        completed: 0,
    });

    const progressPercentage =
        stats.all > 0 ? Math.floor((stats.completed / stats.all) * 100) : 0;

    return (
        <Wrapper>
            <ProgressTitle>Aktive Kontrollen</ProgressTitle>

            {isLoading ? (
                <Loader size={80} />
            ) : hasError ? (
                <RetryLoading onRetry={loadStats} />
            ) : (
                <Bar progress={progressPercentage.toFixed(4)}>
                    <Number>{`${stats.completed} absolviert`}</Number>
                    <Number>{`${stats.pending} ausstehend`}</Number>
                </Bar>
            )}
        </Wrapper>
    );
};

export default DriverLicenseControlProgress;
