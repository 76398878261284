import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import markProjectAsCreated from 'ProjectManager/Project/Common/api/markProjectAsCreated';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import areAllProjectStepsCompletedSelector from 'ProjectManager/Project/Common/recoil/project/selectors/areAllProjectStepsCompletedSelector';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import DataManagementBottomButtons from 'ProjectManager/DataManagement/Common/components/DataManagementBottomButtons';
import Button from 'Common/components/Button';

const useCompleteButton = () => {
    const { id } = useRecoilValue(projectAtom);
    const areAllStepsCompleted = useRecoilValue(areAllProjectStepsCompletedSelector);

    const [isLoading, setIsLoading] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const dispatch = useDispatch();

    const complete = useCallback(async () => {
        if (areAllStepsCompleted) {
            setIsLoading(true);

            await markProjectAsCreated(id, source.token);

            notify('Die Projekterstellung wurde erfolgreich abgeschlossen', {
                type: toast.TYPE.SUCCESS,
            });

            setIsLoading(false);

            dispatch(push(`/project-manager/project/${id}/preview`));
        }
    }, [areAllStepsCompleted, dispatch, id, source.token]);

    const renderButton = useCallback(() => {
        if (areAllStepsCompleted) {
            return (
                <DataManagementBottomButtons append>
                    <Button
                        type="button"
                        isLoading={isLoading}
                        disabled={isLoading}
                        onClick={complete}
                    >
                        Erstellung abschließen
                    </Button>
                </DataManagementBottomButtons>
            );
        }

        return null;
    }, [areAllStepsCompleted, complete, isLoading]);

    return {
        complete,
        renderButton,
    };
};

export default useCompleteButton;
