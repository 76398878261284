import api from 'Application/api/api';

const isDriverLicenseNumberUnique = (
    licenseNumber,
    driverLicenseControlId,
    cancelToken = null,
) =>
    api.get(
        `/measures/vehicles/driver-licenses/controls/${driverLicenseControlId}/driver-license/is-license-number-unique`,
        {
            params: {
                licenseNumber,
            },
            cancelToken,
        },
    );

export default isDriverLicenseNumberUnique;
