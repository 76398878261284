import withUpdatedCompletedStatusesInQuestionnaire from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/withUpdatedCompletedStatusesInQuestionnaire';

const withAdditionalCustomSubsection = (
    newCustomSubsection,
    sectionId,
    isCustomSection,
) => prevProjectOrTemplate => {
    const sectionIndex = prevProjectOrTemplate.questionnaireSections.findIndex(
        section =>
            section.id === sectionId && section.isCustom === isCustomSection,
    );

    if (sectionIndex === -1) {
        return prevProjectOrTemplate;
    }

    const newQuestionnaireSections = [...prevProjectOrTemplate.questionnaireSections];
    const section = newQuestionnaireSections[sectionIndex];

    newQuestionnaireSections[sectionIndex] = {
        ...section,
        subsections: [
            ...section.subsections,
            {
                questions: [],
                ...newCustomSubsection,
            },
        ],
    };

    return withUpdatedCompletedStatusesInQuestionnaire({
        ...prevProjectOrTemplate,
        questionnaireSections: newQuestionnaireSections,
    });
};

export default withAdditionalCustomSubsection;
