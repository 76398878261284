export const examStatuses = {
    NOT_STARTED: 'NOT_STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    IN_APPROVAL: 'IN_APPROVAL',
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
};

export const examStatusOptions = [
    {
        value: examStatuses.NOT_STARTED,
        label: 'Offen',
    },
    {
        value: examStatuses.IN_PROGRESS,
        label: 'In Bearbeitung',
    },
    {
        value: examStatuses.IN_APPROVAL,
        label: 'Mit Zustimmung',
    },
    {
        value: examStatuses.COMPLETED,
        label: 'Abgeschlossen',
    },
    {
        value: examStatuses.FAILED,
        label: 'Gescheitert',
    },
];
