import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import OuterBox from 'Common/components/Boxes/OuterBox';
import Button from 'Common/components/Button';

const Wrapper = styled(OuterBox)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 20px;
    text-align: center;

    @media screen and (min-width: 940px) {
        padding: 50px;
    }
`;

const CongratulationsMessage = styled.div`
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 27px;

    @media screen and (min-width: 940px) {
        margin-bottom: 30px;
        font-size: 22px;
        line-height: 33px;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    > a,
    > button {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const CompletedPresentationScreen = ({
    assignment,
    onViewPresentationAgain,
}) => (
    <Wrapper>
        <CongratulationsMessage>
            Jetzt können Sie mit dem Test beginnen.
        </CongratulationsMessage>
        <ButtonWrapper>
            <Button outline type="button" onClick={onViewPresentationAgain}>
                Präsentation erneut anzeigen
            </Button>
            <Button to={`/education/assignment/exam/${assignment.id}`}>
                Zum Test
            </Button>
        </ButtonWrapper>
    </Wrapper>
);

CompletedPresentationScreen.propTypes = {
    assignment: PropTypes.object.isRequired,
    onViewPresentationAgain: PropTypes.func.isRequired,
};

export default CompletedPresentationScreen;
