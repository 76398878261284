import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { ChevronLeftRounded } from '@material-ui/icons';
import colors from 'Common/constants/colors';
import { Link } from 'react-router-dom';

const StickyWrapper = styled.div`
    position: sticky;
    width: 100%;
    top: 0;
    margin-bottom: 10px;
    z-index: 2;

    @media screen and (min-width: 1180px) {
        margin-bottom: 20px;
    }
`;

const StyledButton = styled.button`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px 20px 15px 6px;
    background: ${colors.DARK_DIVIDER};
    border: 0 none;
    outline: 0 none;
    color: ${colors.DIRTY_WHITE};
    text-align: left;
    cursor: pointer;
    text-decoration: none;
    user-select: none;

    svg {
        font-size: 40px;
        margin-right: 3px;
    }
`;

const StyledButtonAsReactRouterLink = styled(StyledButton).attrs({
    as: Link,
})``;

const StyledButtonAsHtmlAnchor = styled(StyledButton).attrs({
    as: 'a',
})``;

const Title = styled.span`
    display: block;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.GRAY};
`;

const Subtitle = styled.span`
    display: block;
    font-size: 18px;
    line-height: 27px;
`;

const BackButton = ({ title, subtitle, isSticky, ...props }) => {
    let isReactRouterLink = !!props.to;
    let isHtmlAnchor = !!props.href;

    let ButtonComponent;

    if (isReactRouterLink) {
        ButtonComponent = StyledButtonAsReactRouterLink;
    } else if (isHtmlAnchor) {
        ButtonComponent = StyledButtonAsHtmlAnchor;
    } else {
        ButtonComponent = StyledButton;
    }

    const renderedButton = (
        <ButtonComponent {...props}>
            <ChevronLeftRounded />
            <span>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </span>
        </ButtonComponent>
    );

    if (isSticky) {
        return <StickyWrapper>{renderedButton}</StickyWrapper>;
    }

    return renderedButton;
};

BackButton.defaultProps = {
    isSticky: false,
};

BackButton.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    isSticky: PropTypes.bool,
};

export default BackButton;
