import api from 'Application/api/api';

const selectTemplateTopic = (templateId, topicId, cancelToken = null) =>
    api.post(
        `project-manager/templates/${templateId}/topics/${topicId}/select`,
        {},
        { cancelToken },
    );

export default selectTemplateTopic;
