import api from 'Application/api/api';

const deleteTemplateQuestionComment = (templateId, questionId, isCustom, cancelToken = null) =>
    api.delete(
        `project-manager/templates/${templateId}/questionnaire/questions/${questionId}/${
            isCustom ? 1 : 0
        }/comment`,
        {
            cancelToken,
        },
    );

export default deleteTemplateQuestionComment;
