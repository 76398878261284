import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useProjectCustomTopics from 'ProjectManager/DataManagement/TopicManagement/components/CustomTopicManagement/hooks/customTopics/useProjectCustomTopics';
import useTemplateCustomTopics from 'ProjectManager/DataManagement/TopicManagement/components/CustomTopicManagement/hooks/customTopics/useTemplateCustomTopics';

const useCustomTopics = () => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useProjectCustomTopics();
    const dataFromTemplate = useTemplateCustomTopics();

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return [];
};

export default useCustomTopics;
