import React from 'react';
import PropTypes from 'prop-types';
import Filter from 'Common/components/Filters/Filter/Filter';
import { compact } from 'lodash';
import DateRangePicker from 'Common/components/Form/Fields/DateRangePicker';

const DateRangeFilter = ({
    startingDate,
    endingDate,
    onStartingDateChange,
    onEndingDateChange,
    startingDateProps,
    endingDateProps,
    ...props
}) => (
    <Filter
        {...props}
        selectedCount={compact([startingDate, endingDate]).length}
    >
        <DateRangePicker
            startingDate={startingDate}
            endingDate={endingDate}
            onStartingDateChange={onStartingDateChange}
            onEndingDateChange={onEndingDateChange}
            startingDateProps={{
                portalId: 'root-portal',
                ...startingDateProps,
            }}
            endingDateProps={{
                portalId: 'root-portal',
                ...endingDateProps,
            }}
        />
    </Filter>
);

DateRangeFilter.defaultProps = {
    startingDate: null,
    endingDate: null,
    startingDateProps: {},
    endingDateProps: {},
};

DateRangeFilter.propTypes = {
    title: PropTypes.any.isRequired,
    startingDate: PropTypes.object,
    endingDate: PropTypes.object,
    onStartingDateChange: PropTypes.func.isRequired,
    onEndingDateChange: PropTypes.func.isRequired,
    startingDateProps: PropTypes.object,
    endingDateProps: PropTypes.object,
};

export default DateRangeFilter;
