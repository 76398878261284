import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { debounce } from 'lodash';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import hasUnsavedChangesState from 'Common/recoil/hasUnsavedChangesState';
import Answer from 'ProjectManager/DataManagement/Questionnaire/components/Question/Answers/components/Answer';
import { CheckBoxOutlineBlankRounded, CheckRounded } from '@material-ui/icons';
import colors from 'Common/constants/colors';
import useGiveMultipleChoiceAnswersFunction from 'ProjectManager/DataManagement/Questionnaire/components/Question/Answers/hooks/giveMultipleChoiceAnswers/useGiveMultipleChoiceAnswersFunction';

const Wrapper = styled.div`
    max-width: 520px;
`;

const Check = styled(CheckRounded)`
    color: ${colors.DARK_GRAY};
`;

const CheckBox = styled(CheckBoxOutlineBlankRounded)`
    color: ${colors.SEARCH_FIELD_BORDER};
`;

const MultipleChoice = ({ question }) => {
    const giveMultipleChoiceAnswers = useGiveMultipleChoiceAnswersFunction(
        question.id,
        question.isCustom,
    );

    const [, setHasUnsavedChanges] = useRecoilState(hasUnsavedChangesState);

    const [answerIds, setAnswerIds] = useState(() =>
        question.singleOrMultipleAnswers
            .filter(answer => answer.isSelected)
            .map(answer => answer.id),
    );

    const debouncedSave = useCallback(
        debounce(
            async selectedAnswerIds => {
                try {
                    await giveMultipleChoiceAnswers(selectedAnswerIds);
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        notify(
                            'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                            {
                                type: toast.TYPE.ERROR,
                            },
                        );
                    }
                }

                setHasUnsavedChanges(false);
            },
            350,
            {
                leading: true,
                trailing: true,
            },
        ),
        [giveMultipleChoiceAnswers, setHasUnsavedChanges],
    );

    const handleAnswerClick = clickedAnswerId => {
        setHasUnsavedChanges(true);

        setAnswerIds(prevAnswerIds => {
            const newAnswerIds = [...prevAnswerIds];

            if (newAnswerIds.includes(clickedAnswerId)) {
                const index = newAnswerIds.indexOf(clickedAnswerId);

                if (index !== -1) {
                    newAnswerIds.splice(index, 1);
                }
            } else {
                newAnswerIds.push(clickedAnswerId);
            }

            debouncedSave(newAnswerIds);

            return newAnswerIds;
        });
    };

    return (
        <Wrapper>
            {question.singleOrMultipleAnswers.map(answer => (
                <Answer
                    key={answer.id}
                    text={answer.text}
                    isSelected={answerIds.includes(answer.id)}
                    selectedIcon={<Check />}
                    notSelectedIcon={<CheckBox />}
                    onClick={() => handleAnswerClick(answer.id)}
                />
            ))}
        </Wrapper>
    );
};

MultipleChoice.propTypes = {
    question: PropTypes.object.isRequired,
};

export default MultipleChoice;
