import { store } from 'Application/store';

import { clearUserData } from 'User/reduxUser';
import logoutRequest from 'User/api/logout';

const logout = async (cancelToken = null) => {
    await logoutRequest(cancelToken);

    store.dispatch(clearUserData());
};

export default logout;
