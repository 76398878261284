import api from 'Application/api/api';

/**
 * @param data Accepts an object with the following format:
 * {
 *   name: '',
 *   description: '',
 * }
 * @param cancelToken
 */
const createInstructionArea = (data, cancelToken = null) =>
    api.post('education/instruction-areas', data, { cancelToken });

export default createInstructionArea;
