import api from 'Application/api/api';

const conductIntroduction = (
    projectId,
    introductionId,
    projectParticipantIds,
    password,
    signature,
    cancelToken = null,
) => {
    const formData = new FormData();

    formData.append('signature', signature);
    formData.append(
        'data',
        JSON.stringify({
            projectParticipantIds,
            password,
        }),
    );

    return api.post(
        `project-manager/projects/${projectId}/introductions/${introductionId}/conduct`,
        formData,
        { cancelToken },
    );
};

export default conductIntroduction;
