import React from 'react';
import styled from 'styled-components/macro';
import { useRecoilState } from 'recoil';
import Input from 'Common/components/Form/Fields/Input';
import projectMediaFilesSearchTermAtom from 'ProjectManager/Project/ProjectMediaFiles/recoil/projectMediaFilesSearchTerm/projectMediaFilesSearchTermAtom';
import { BackspaceRounded, Search as SearchIcon } from '@material-ui/icons';
import Button from 'Common/components/Button';

const ClearButton = styled(Button)`
    margin-top: 10px;
`;

const Search = () => {
    const [searchTerm, setSearchTerm] = useRecoilState(
        projectMediaFilesSearchTermAtom,
    );

    const handleSearchTermChange = e => {
        setSearchTerm(e.target.value);
    };

    const handleClearButtonClick = () => {
        setSearchTerm('');
    };

    return (
        <>
            <Input
                label="Suche"
                icon={SearchIcon}
                value={searchTerm}
                onChange={handleSearchTermChange}
            />
            {searchTerm && (
                <ClearButton
                    underline
                    icon={<BackspaceRounded />}
                    type="button"
                    onClick={handleClearButtonClick}
                >
                    Suche zurücksetzen
                </ClearButton>
            )}
        </>
    );
};

export default Search;
