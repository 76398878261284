import { selector } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';

const isProjectTopicManagementStepCompletedSelector = selector({
    key: 'projectManagement_isProjectTopicManagementStepCompleted',
    get: ({ get }) => {
        const { id, topics, customTopics } = get(projectAtom);

        return Boolean(id) && topics.length + customTopics.length > 0;
    },
});

export default isProjectTopicManagementStepCompletedSelector;
