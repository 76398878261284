const pluginName = 'useCustomCellStyles';

export const useCustomCellStyles = hooks => {
    hooks.getHeaderProps.push(getHeaderProps);
    hooks.getCellProps.push(getCellProps);
};

useCustomCellStyles.pluginName = pluginName;

const getHeaderProps = (props, { column }) => [
    props,
    {
        style: {
            ...column.customCellStyles,
            ...column.customHeaderCellStyles,
        },
    },
];

const getCellProps = (props, { cell }) => [
    props,
    {
        style: {
            ...cell.column.customCellStyles,
            ...cell.column.customBodyCellStyles,
        },
    },
];
