import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import LabeledValue from 'ProjectManager/Project/Preview/LabeledValue';
import Engineering from 'Common/components/Icons/Engineering';
import Button from 'Common/components/Button';
import useParticipantsManageButton from 'ProjectManager/Project/Introductions/Grid/hooks/useParticipantsManageButton';
import useParticipantsViewButton from 'ProjectManager/Project/Introductions/Grid/hooks/useParticipantsViewButton';

const StyledLabeledValue = styled(LabeledValue)`
    button {
        margin-top: 5px;
        padding-left: 0;
    }
`;

const Participants = ({ introduction }) => {
    const relevantParticipants = introduction.participants.filter(
        participant => participant.isRelevant,
    );

    const {
        isVisible: isManageButtonVisible,
        handleClick: handleManageButtonClick,
        renderModal: renderManageModal,
    } = useParticipantsManageButton(introduction);

    const {
        isVisible: isViewButtonVisible,
        handleClick: handleViewButtonClick,
        renderModal: renderParticipantsViewModal,
    } = useParticipantsViewButton(introduction);

    return (
        <StyledLabeledValue label="Einbezogene Mitarbeiter">
            <div>{relevantParticipants.length}</div>
            {isManageButtonVisible && (
                <Button
                    underline
                    icon={<Engineering />}
                    type="button"
                    onClick={handleManageButtonClick}
                >
                    Manage
                </Button>
            )}
            {isViewButtonVisible && (
                <Button
                    underline
                    icon={<Engineering />}
                    type="button"
                    onClick={handleViewButtonClick}
                >
                    Ansicht
                </Button>
            )}
            {renderManageModal()}
            {renderParticipantsViewModal()}
        </StyledLabeledValue>
    );
};

Participants.propTypes = {
    introduction: PropTypes.object.isRequired,
};

export default Participants;
