import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TasksProgress from 'Dashboard/components/manager/TasksProgress';
import styled from 'styled-components/macro';
import { CloudDownloadRounded, SearchRounded } from '@material-ui/icons';
import Input from 'Common/components/Form/Fields/Input';
import { ifProp } from 'styled-tools';
import EmployeeLicensesUsage from 'Employee/components/List/EmployeeLicensesUsage';
import employeeStatusFilters from 'Employee/constants/employeeStatusFilters';
import employeeTypeFilters from 'Employee/constants/employeeTypeFilters';
import EmployeeCreateButton from 'Employee/components/List/EmployeeCreateButton';
import NumberIndicator from 'Common/components/NumberIndicator';
import colors from 'Common/constants/colors';
import OuterBox from 'Common/components/Boxes/OuterBox';
import InnerBox from 'Common/components/Boxes/InnerBox';

const Wrapper = styled.div`
    margin-bottom: 10px;
    border-radius: 4px;

    @media screen and (min-width: 940px) {
        position: sticky;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        top: 80px;
        max-height: calc(100vh - 150px);
        width: 300px;
        flex: 0 0 300px;
        align-self: flex-start;
        margin-right: 10px;
        margin-bottom: 0;
    }
`;

const SidebarBox = styled(OuterBox)`
    margin-top: 10px;
    padding: 0 20px;

    > div {
        border-bottom: 1px solid ${colors.DARK_DIVIDER};

        &:last-child {
            border-bottom: 0 none;
        }
    }
`;

const SearchWrapper = styled.div`
    padding: 20px 0;
`;

const Documents = styled.div`
    padding: 20px 0;
`;

const IconWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 28px;
    height: 28px;
    color: ${colors.DIRTY_WHITE};

    &::after {
        content: '';
        display: block;
        width: 1px;
        height: 20px;
        background: ${colors.SEARCH_FIELD_BORDER};
        position: absolute;
        top: 27px;
    }
`;

const DocumentName = styled.div`
    color: ${colors.DIRTY_WHITE};
    font-size: 14px;
    line-height: 21px;
`;

const DocumentItem = styled.a`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    text-decoration: none;
    color: ${colors.DIRTY_WHITE};
    cursor: pointer;

    &:last-child {
        margin-bottom: 0;

        ${IconWrapper} {
            &::after {
                display: none;
            }
        }
    }

    &:hover {
        ${DocumentName} {
            color: ${colors.PRIMARY};
        }

        ${IconWrapper} {
            border-color: ${colors.PRIMARY};
        }
    }
`;

const FiltersWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
`;

const EmployeeFiltersWrapper = styled(InnerBox)`
    display: flex;
    width: 100%;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: 580px) and (max-width: 939px) {
        flex: 1;
        width: auto;
        margin-right: 10px;
        margin-bottom: 0;

        &:last-child {
            margin-right: 0;
        }
    }
`;

const StatusFilterButton = styled.button`
    position: relative;
    flex: 1;
    margin-right: 1px;
    padding: 14px 15px;
    font-size: 13px;
    color: ${ifProp('isActive', colors.DARK_GRAY, colors.DIRTY_WHITE)};
    background: ${ifProp(
        'isActive',
        colors.WHITE,
        colors.INNER_BOX_BACKGROUND,
    )};
    border: 0 none;
    outline: 0;
    cursor: pointer;

    &:hover:not([disabled]) {
        background: ${colors.DARK_DIVIDER};
    }

    &[disabled] {
        cursor: default;
    }

    &:first-child {
        border-radius: 4px 0 0 4px;
    }

    &:last-child {
        border-radius: 0 4px 4px 0;
    }
`;

const ItemsFound = styled(NumberIndicator)`
    position: absolute;
    top: -10px;
    right: 10px;
`;

const EmployeeListOverviewSidebar = ({
    searchTerm,
    onSearchTermChange,
    selectedStatus,
    onStatusChange,
    selectedEmployeeType,
    onEmployeeTypeChange,
    employees,
    allEmployees,
}) => {
    const [uniqueKey, setUniqueKey] = useState(1);

    const numberOfArchivedEmployees = allEmployees.filter(
        employee => employee.isArchived,
    ).length;

    useEffect(() => {
        setUniqueKey(prevUniqueKey => prevUniqueKey + 1);
    }, [numberOfArchivedEmployees]);

    const handleSearchInputChange = e => {
        onSearchTermChange(e.target.value);
    };

    const numberOfActiveEmployeesFound = employees.filter(
        employee => employee.isEnabled,
    ).length;

    const numberOfInactiveEmployeesFound = employees.filter(
        employee => !employee.isEnabled,
    ).length;

    return (
        <Wrapper>
            <EmployeeCreateButton key={`create_button_${uniqueKey}`} />
            <SidebarBox>
                <SearchWrapper>
                    <Input
                        name="search"
                        label="Suche"
                        icon={SearchRounded}
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                    />
                </SearchWrapper>
                <TasksProgress key={`tasks_progress_${uniqueKey}`} />
                <EmployeeLicensesUsage key={`licenses_usage_${uniqueKey}`} />
                <FiltersWrapper>
                    <EmployeeFiltersWrapper>
                        <StatusFilterButton
                            disabled={
                                selectedStatus === employeeStatusFilters.ACTIVE
                            }
                            isActive={
                                selectedStatus === employeeStatusFilters.ACTIVE
                            }
                            onClick={() =>
                                onStatusChange(employeeStatusFilters.ACTIVE)
                            }
                        >
                            Aktive Mitarbeiter
                            {!!searchTerm && (
                                <ItemsFound>
                                    {numberOfActiveEmployeesFound}
                                </ItemsFound>
                            )}
                        </StatusFilterButton>
                        <StatusFilterButton
                            disabled={
                                selectedStatus ===
                                employeeStatusFilters.INACTIVE
                            }
                            isActive={
                                selectedStatus ===
                                employeeStatusFilters.INACTIVE
                            }
                            onClick={() =>
                                onStatusChange(employeeStatusFilters.INACTIVE)
                            }
                        >
                            Inaktive Mitarbeiter
                            {!!searchTerm && (
                                <ItemsFound>
                                    {numberOfInactiveEmployeesFound}
                                </ItemsFound>
                            )}
                        </StatusFilterButton>
                    </EmployeeFiltersWrapper>
                    <EmployeeFiltersWrapper>
                        <StatusFilterButton
                            disabled={
                                selectedEmployeeType === employeeTypeFilters.ALL
                            }
                            isActive={
                                selectedEmployeeType === employeeTypeFilters.ALL
                            }
                            onClick={() =>
                                onEmployeeTypeChange(employeeTypeFilters.ALL)
                            }
                        >
                            Alle Mitarbeiter
                        </StatusFilterButton>
                        <StatusFilterButton
                            disabled={
                                selectedEmployeeType ===
                                employeeTypeFilters.TRAINEE
                            }
                            isActive={
                                selectedEmployeeType ===
                                employeeTypeFilters.TRAINEE
                            }
                            onClick={() =>
                                onEmployeeTypeChange(
                                    employeeTypeFilters.TRAINEE,
                                )
                            }
                        >
                            Auszubildende
                        </StatusFilterButton>
                    </EmployeeFiltersWrapper>
                </FiltersWrapper>
                <Documents>
                    <DocumentItem
                        href="/backend/employyes/export"
                        target="_blank"
                    >
                        <IconWrapper>
                            <CloudDownloadRounded />
                        </IconWrapper>
                        <DocumentName>CSV-Download</DocumentName>
                    </DocumentItem>
                </Documents>
            </SidebarBox>
        </Wrapper>
    );
};

EmployeeListOverviewSidebar.propTypes = {
    searchTerm: PropTypes.string.isRequired,
    onSearchTermChange: PropTypes.func.isRequired,
    selectedStatus: PropTypes.string.isRequired,
    onStatusChange: PropTypes.func.isRequired,
    selectedEmployeeType: PropTypes.string.isRequired,
    onEmployeeTypeChange: PropTypes.func.isRequired,
    employees: PropTypes.array.isRequired,
    allEmployees: PropTypes.array.isRequired,
};

export default EmployeeListOverviewSidebar;
