import { range } from 'lodash';
import convertAmountOfHoursToHumanReadableString
    from 'ProjectManager/DataManagement/WorkingHours/helpers/convertAmountOfHoursToHumanReadableString';

const hourOptions = range(0, 24, 0.25).map(amountOfHours => ({
    value: amountOfHours,
    label: convertAmountOfHoursToHumanReadableString(amountOfHours),
}));

export default hourOptions;
