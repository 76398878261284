import api from 'Application/api/api';
import qs from 'qs';
import { pickBy } from 'lodash';

// noinspection JSIncompatibleTypesComparison
const getProjects = (
    offset,
    limit,
    filter = {},
    sortBy = {},
    searchTerm = undefined,
    load = [],
    cancelToken = null,
) =>
    api.get('project-manager/projects', {
        params: {
            offset,
            limit,
            load,
            filter: pickBy(filter, value => value !== null),
            sortBy,
            searchTerm: searchTerm !== '' ? searchTerm : undefined,
        },
        paramsSerializer: params => qs.stringify(params),
        cancelToken,
    });

export default getProjects;
