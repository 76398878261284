import React, { useEffect, useMemo, useState } from 'react';
import deleteInstructionArea from 'Education/api/instruction-area/deleteInstructionArea';
import PropTypes from 'prop-types';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import axios from 'axios';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';

const InstructionAreaDeleteModal = ({ area, onCancel, onSuccess }) => {
    const [isDeleting, setIsDeleting] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirm = async () => {
        try {
            setIsDeleting(true);

            await deleteInstructionArea(area.id, source.token);

            onSuccess();

            setIsDeleting(false);

            notify('Der Bereich wurde erfolgreich gelöscht', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsDeleting(false);

                notify('Der Bereich konnte nicht gelöscht werden', {
                    type: toast.TYPE.ERROR,
                });
            }
        }
    };

    return (
        <Modal
            isOpen={area !== null}
            onRequestClose={isDeleting ? undefined : onCancel}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onCancel}
                        disabled={isDeleting}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isDeleting}
                        onClick={handleConfirm}
                        disabled={isDeleting}
                    >
                        Löschen
                    </Button>
                </>
            }
        >
            <ModalTitle>
                Wollen Sie den Bereich {area?.name} wirklich Löschen?
            </ModalTitle>
        </Modal>
    );
};

InstructionAreaDeleteModal.defaultProps = {
    area: null,
};

InstructionAreaDeleteModal.propTypes = {
    area: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default InstructionAreaDeleteModal;
