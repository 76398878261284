import api from 'Application/api/api';

const giveTemplateQuestionTextAnswer = (
    templateId,
    questionId,
    isCustom,
    answer,
    cancelToken = null,
) =>
    api.post(
        `project-manager/templates/${templateId}/questionnaire/questions/${questionId}/${
            isCustom ? 1 : 0
        }/answers/text`,
        {
            answer,
        },
        { cancelToken },
    );

export default giveTemplateQuestionTextAnswer;
