import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import getCurrentUser from 'User/api/getCurrentUser';
import { authenticateUser } from 'User/reduxUser';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';

const PostLogin = () => {
    const dispatch = useDispatch();

    const { data: currentUser } = useAxiosRequest(getCurrentUser, null);

    useEffect(() => {
        if (currentUser) {
            dispatch(authenticateUser(currentUser));

            window.location.href = '/backend/evaluation';
        }
    }, [currentUser, dispatch]);

    return null;
};

export default PostLogin;
