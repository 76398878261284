import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { prop } from 'styled-tools';
import { CloseRounded } from '@material-ui/icons';
import Zoom from 'react-medium-image-zoom';
import LoadingProgressBar from 'Common/components/LoadingProgressBar';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';
import formatDate from 'Common/utils/formatDate';
import defaultDateTimeFormat from 'Common/constants/defaultDateTimeFormat';

const Wrapper = styled.div`
    width: 100%;
    text-align: center;

    @media screen and (min-width: 620px) {
        max-width: 520px;
    }
`;

const Title = styled.div`
    margin-bottom: 20px;
    color: ${colors.DIRTY_WHITE};
    font-size: 18px;

    @media screen and (min-width: 940px) {
        margin-bottom: 30px;
    }
`;

const Hint = styled.div`
    margin-top: 20px;
    color: ${colors.LIGHTER_GRAY};
    font-size: 14px;
    line-height: 21px;
`;

const ImageWrapper = styled.div`
    position: relative;
    width: 100%;

    button {
        &:focus {
            outline: 0 none;
        }
    }
`;

const Image = styled.div`
  display: block;
  width: 100%;
  height: 200px;
  background-image: url('${prop('$src')}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

const WatermarkDate = styled.div`
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
    color: ${colors.WHITE};
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    text-shadow: 1px 1px 2px ${colors.PAGE_BACKGROUND};
`;

const ClearButton = styled(Button)`
    margin-top: 20px;
`;

const ProgressBarWrapper = styled.div`
    @media screen and (min-width: 940px) {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 200px;
    }
`;

const LicenseImage = ({
    title,
    hint,
    src,
    watermarkDate,
    onClear,
    isLoading,
}) => (
    <Wrapper>
        <Title>{title}</Title>
        <ImageWrapper>
            {isLoading ? (
                <ProgressBarWrapper>
                    <LoadingProgressBar
                        label="Ihre Bildaufnahme wird optimiert, bitte haben Sie etwas Geduld."
                        isLoading={isLoading}
                        estimatedLoadingTime={20000}
                        autoLoading
                    />
                </ProgressBarWrapper>
            ) : (
                <Zoom
                    overlayBgColorStart="rgba(0, 0, 0, 0)"
                    overlayBgColorEnd="rgba(0, 0, 0, 0.55)"
                    zoomMargin={20}
                    wrapStyle={{ width: '100%' }}
                >
                    <Image $src={src} />
                    {!!watermarkDate && (
                        <WatermarkDate>
                            {`${formatDate(
                                watermarkDate,
                                defaultDateTimeFormat,
                            )} Uhr`}
                        </WatermarkDate>
                    )}
                </Zoom>
            )}
        </ImageWrapper>
        {!!onClear && !isLoading && (
            <ClearButton outline icon={<CloseRounded />} onClick={onClear}>
                Foto entfernen
            </ClearButton>
        )}
        {!!hint && <Hint>{hint}</Hint>}
    </Wrapper>
);

LicenseImage.defaultProps = {
    hint: null,
    watermarkDate: null,
    onClear: null,
    isLoading: false,
};

LicenseImage.propTypes = {
    title: PropTypes.any.isRequired,
    hint: PropTypes.any,
    src: PropTypes.string.isRequired,
    watermarkDate: PropTypes.object,
    onClear: PropTypes.func,
    isLoading: PropTypes.bool,
};

export default LicenseImage;
