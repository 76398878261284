import React from 'react';
import PropTypes from 'prop-types';
import SingleCheckboxFilter from 'Common/components/Filters/Filter/SingleCheckboxFilter';

const ProjectsThatRequireAction = ({ value, setFilters }) => {
    const handleToggle = () => {
        setFilters(prevFilters => ({
            ...prevFilters,
            onlyProjectsThatRequireAction: !prevFilters.onlyProjectsThatRequireAction,
        }));
    };

    return (
        <SingleCheckboxFilter
            title="Projekte mit Handlungsbedarf"
            isChecked={value}
            onToggle={handleToggle}
        />
    );
};

ProjectsThatRequireAction.propTypes = {
    value: PropTypes.bool.isRequired,
    setFilters: PropTypes.func.isRequired,
};

export default ProjectsThatRequireAction;
