import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import axios from 'axios';
import giveProjectQuestionSingleChoiceAnswer from 'ProjectManager/Project/Common/api/dataManagement/questionnaire/question/answer/giveProjectQuestionSingleChoiceAnswer';
import withSingleChoiceAnswerForQuestion from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/answer/withSingleChoiceAnswerForQuestion';

const useProjectGiveSingleChoiceAnswerFunction = (
    questionId,
    isCustomQuestion,
) => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const giveSingleChoiceAnswer = useCallback(
        async selectedAnswerId => {
            await giveProjectQuestionSingleChoiceAnswer(
                projectId,
                questionId,
                isCustomQuestion,
                selectedAnswerId,
                source.token,
            );

            setProject(
                withSingleChoiceAnswerForQuestion(
                    questionId,
                    isCustomQuestion,
                    selectedAnswerId,
                ),
            );
        },
        [projectId, questionId, isCustomQuestion, setProject, source.token],
    );

    return giveSingleChoiceAnswer;
};

export default useProjectGiveSingleChoiceAnswerFunction;
