import React, { useState } from 'react';
import Button from 'Common/components/Button';
import { DeleteRounded } from '@material-ui/icons';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import CommentDeleteModal from 'ProjectManager/DataManagement/Questionnaire/components/Question/Comment/components/CommentDeleteModal';

const Wrapper = styled.div`
    margin-top: 15px;
`;

const DeleteCommentButton = ({ question, className }) => {
    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

    const handleOpenDeleteModal = () => {
        setIsDeleteModalOpened(true);
    };

    const closeModal = () => {
        setIsDeleteModalOpened(false);
    };

    return (
        <Wrapper>
            <Button
                underline
                icon={<DeleteRounded />}
                type="button"
                onClick={handleOpenDeleteModal}
                className={className}
            >
                Kommentar löschen
            </Button>
            {isDeleteModalOpened && (
                <CommentDeleteModal
                    question={question}
                    onCancel={closeModal}
                    onSuccess={closeModal}
                />
            )}
        </Wrapper>
    );
};

DeleteCommentButton.propTypes = {
    question: PropTypes.object.isRequired,
};

export default DeleteCommentButton;
