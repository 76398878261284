import React, { useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import {
    AddRounded,
    ExpandMoreRounded,
    SearchRounded,
} from '@material-ui/icons';
import Input from 'Common/components/Form/Fields/Input';
import Checkbox from 'Common/components/Form/Fields/Checkbox';
import getInstructionAreas from 'Education/api/instruction-area/getInstructionAreas';
import PropTypes from 'prop-types';
import LinkToLibraryInstructions from 'Education/components/LinkToLibraryInstructions';
import {
    SidebarAccordionContent,
    SidebarAccordionPanel,
    SidebarAccordionTitle,
} from 'Common/components/sidebarAccordion';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';
import InstructionCreateModal from 'Education/components/instruction/InstructionCreateModal';
import OuterBox from 'Common/components/Boxes/OuterBox';

const Wrapper = styled.div`
    margin-bottom: 10px;

    @media screen and (min-width: 940px) {
        align-self: flex-start;
        position: sticky;
        top: 134px;
        width: 300px;
        flex: 0 0 300px;
        max-height: calc(100vh - 206px);
        margin-right: 10px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
`;

const CreateButton = styled(OuterBox).attrs({
    as: 'button',
})`
    display: flex;
    width: 100%;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    border: 0 none;
    outline: 0;
    cursor: pointer;
`;

const SidebarContent = styled(OuterBox)`
    margin-top: 10px;
    padding: 20px;
`;

const SearchWrapper = styled.div`
    margin-top: 35px;
    margin-bottom: 30px;
`;

const InstructionSidebar = ({
    searchTerm,
    onSearchTermChange,
    selectedAreaIds,
    onCheckboxChange,
    onInstructionCreate,
}) => {
    const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);

    const handleCreateButtonClick = () => {
        setIsCreateModalOpened(true);
    };

    const handleCreateCancel = () => {
        setIsCreateModalOpened(false);
    };

    const handleCreateSuccess = async newInstruction => {
        await onInstructionCreate(newInstruction);
        setIsCreateModalOpened(false);
    };

    const instructionAreasRequest = useCallback(
        cancelToken => getInstructionAreas([], cancelToken),
        [],
    );

    const {
        data: instructionAreas,
        loadData: loadInstructionAreas,
        isLoading,
        hasError,
    } = useAxiosRequest(instructionAreasRequest, []);

    const handleSearchInputChange = event => {
        onSearchTermChange(event.target.value);
    };

    const [expandedPanel, setExpandedPanel] = useState(null);

    const handleChange = (panel, isExpanded) => {
        setExpandedPanel(isExpanded ? panel : null);
    };

    return (
        <Wrapper>
            <CreateButton onClick={handleCreateButtonClick}>
                Unterweisung
                <AddRounded />
            </CreateButton>
            <SidebarContent>
                <LinkToLibraryInstructions />
                <SearchWrapper>
                    <Input
                        name="search"
                        label="Suche"
                        icon={SearchRounded}
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                    />
                </SearchWrapper>

                <SidebarAccordionPanel
                    expanded={expandedPanel === 'areas'}
                    onChange={(e, isExpanded) =>
                        handleChange('areas', isExpanded)
                    }
                >
                    <SidebarAccordionTitle
                        aria-controls="panel-areas-content"
                        id="panel-areas-header"
                        expandIcon={<ExpandMoreRounded />}
                    >
                        Filter: Bereiche
                    </SidebarAccordionTitle>
                    <SidebarAccordionContent>
                        {isLoading ? (
                            <Loader size={80} />
                        ) : hasError ? (
                            <RetryLoading onRetry={loadInstructionAreas} />
                        ) : (
                            instructionAreas.map(instructionArea => (
                                <Checkbox
                                    key={instructionArea.id}
                                    label={instructionArea.name}
                                    id={`instructionArea_${instructionArea.id}`}
                                    name="instructionArea"
                                    checked={selectedAreaIds.includes(
                                        instructionArea.id,
                                    )}
                                    onChange={e =>
                                        onCheckboxChange(
                                            instructionArea.id,
                                            e.target.checked,
                                        )
                                    }
                                />
                            ))
                        )}
                    </SidebarAccordionContent>
                </SidebarAccordionPanel>
            </SidebarContent>
            {isCreateModalOpened && (
                <InstructionCreateModal
                    isOpen={isCreateModalOpened}
                    onCancel={handleCreateCancel}
                    onSuccess={handleCreateSuccess}
                />
            )}
        </Wrapper>
    );
};

InstructionSidebar.propTypes = {
    searchTerm: PropTypes.string.isRequired,
    selectedAreaIds: PropTypes.array.isRequired,
    onCheckboxChange: PropTypes.func.isRequired,
    onSearchTermChange: PropTypes.func.isRequired,
    onInstructionCreate: PropTypes.func.isRequired,
};

export default InstructionSidebar;
