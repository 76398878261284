import * as Yup from 'yup';

const uniqueTest = function (testedValue, message, compareFunction = null) {
    const testedIndex = parseInt(this.path.match(/\[(\d)]$/)[1]);

    if (
        this.parent
            // Exclude the same value
            .filter((value, index) => index !== testedIndex)

            // Check for value match among some of the other values
            .some(value =>
                compareFunction
                    ? compareFunction(value, testedValue)
                    : value === testedValue,
            )
    ) {
        throw this.createError({
            path: this.path,
            message,
        });
    }

    return true;
};

Yup.addMethod(Yup.mixed, 'unique', function (message, compareFunction = null) {
    return this.test('unique', message, function (value) {
        return uniqueTest.call(this, value, message, compareFunction);
    });
});
