import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { Redirect, useParams } from 'react-router-dom';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import { WarningRounded } from '@material-ui/icons';
import OuterBox from 'Common/components/Boxes/OuterBox';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import colors from 'Common/constants/colors';
import WarningMessage from 'Common/components/Modals/WarningMessage';
import getPublicProjectQuestionFilesDownloadRequest from 'ProjectManager/Project/Common/api/downloadRequest/getPublicProjectQuestionFilesDownloadRequest';

const Wrapper = styled(OuterBox)`
    padding: 20px;
`;

const Text = styled.div`
    color: ${colors.GRAY};

    p {
        margin: 30px 0;

        strong {
            line-height: 24px;
        }
    }
`;

const ProjectQuestionFilesExpiredDownloadRequest = () => {
    const {
        projectId,
        questionId,
        isCustomQuestion,
        questionFilesDownloadRequestId,
    } = useParams();

    const [checksum] = useQueryParameter('checksum');

    const request = useCallback(
        cancelToken =>
            getPublicProjectQuestionFilesDownloadRequest(
                projectId,
                questionId,
                Boolean(parseInt(isCustomQuestion)),
                questionFilesDownloadRequestId,
                checksum,
                [],
                cancelToken,
            ),
        [
            projectId,
            questionId,
            isCustomQuestion,
            questionFilesDownloadRequestId,
            checksum,
        ],
    );

    const {
        data: downloadRequest,
        loadData: loadDownloadRequest,
        isLoading,
        hasError,
        error,
    } = useAxiosRequest(request, null);

    if (hasError) {
        const responseStatusCode = error?.response?.status;

        if (responseStatusCode === 404 || responseStatusCode === 400) {
            return <Redirect to="/" />;
        }

        return <RetryLoading onRetry={loadDownloadRequest} />;
    }

    if (isLoading) {
        return <Loader />;
    }

    return (
        <Wrapper>
            <OuterBoxTitle>
                {`Projektdateien - ${downloadRequest.clientName}, ${downloadRequest.projectName}`}
            </OuterBoxTitle>
            <Text>
                <p>
                    <strong>Frage:</strong>
                    <br />
                    {downloadRequest.questionText}
                </p>
                <p>
                    <strong>Projektfeld:</strong>
                    <br />
                    {downloadRequest.topicName}
                </p>
                <WarningMessage>
                    <WarningRounded />
                    Der Link ist abgelaufen und nicht mehr gültig.
                </WarningMessage>
            </Text>
        </Wrapper>
    );
};

export default ProjectQuestionFilesExpiredDownloadRequest;
