import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import OuterBox from 'Common/components/Boxes/OuterBox';
import colors from 'Common/constants/colors';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';

const Wrapper = styled(OuterBox)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 80px;
    padding: 5px 10px 15px 20px;
    margin-bottom: 10px;
    z-index: 2;

    @media screen and (min-width: 940px) {
        position: sticky;
        top: 80px;
    }

    ${ifProp(
        'withButton',
        css`
            padding-left: 12px;
        `,
    )};
`;

const Title = styled.div`
    margin: 10px 20px 0 0;
    color: ${colors.DIRTY_WHITE};
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;

    @media screen and (min-width: 940px) {
        flex: 1;
    }
`;

const RightElementWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    > * {
        margin: 10px 10px 0 0;
    }
`;

const ActionBar = ({ title, rightElement, ...props }) => (
    <Wrapper {...props}>
        <Title>{title}</Title>
        {!!rightElement && (
            <RightElementWrapper>{rightElement}</RightElementWrapper>
        )}
    </Wrapper>
);

ActionBar.defaultProps = {
    rightElement: undefined,
    withButton: false,
};

ActionBar.propsTypes = {
    title: PropTypes.string.isRequired,
    rightElement: PropTypes.any,
    withButton: PropTypes.bool,
};

export default ActionBar;
