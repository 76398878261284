import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import OuterBox from 'Common/components/Boxes/OuterBox';

const InteractableOuterBox = styled(OuterBox)`
    transition: background 150ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
        background: ${colors.INNER_BOX_BACKGROUND};
    }
`;

export default InteractableOuterBox;
