const pluginName = 'useClickableTableRow';

export const useClickableTableRow = hooks => {
    hooks.getRowProps.push(getRowProps);
};

useClickableTableRow.pluginName = pluginName;

const getRowProps = (props, { instance, row }) => {
    const {
        state: { hiddenColumns },
    } = instance;

    const isClickable = row.original.isClickable ?? true;

    if (!isClickable || (hiddenColumns && hiddenColumns.length > 0)) {
        return [props];
    }

    return [
        props,
        {
            onClick: () => instance.onRowClick(row),
            style: {
                cursor: 'pointer',
            },
        },
    ];
};
