import React from 'react';
import styled from 'styled-components/macro';

import {
    DriverLicenseControlIcon,
    UvvTuvIcon,
    VehicleContractIcon,
} from 'Common/components/icons';

import Tile from 'Common/components/Tile';
import { Link } from 'react-router-dom';

const ContentModule = styled.div`
    position: relative;
    display: flex;
    margin-top: 10px;

    &:first-child {
        margin-top: 0;
    }

    @media screen and (min-width: 1180px) {
        width: calc(50% - 5px);
        margin-right: 10px;

        &:first-child {
            width: 100%;
            margin-right: 0;
        }

        &:nth-child(3) {
            margin-right: 0;
        }
    }
`;

const ModuleTile = styled(Tile)`
    flex: 1 1 auto;
    width: 100%;
    padding-right: 62px;

    @media screen and (min-width: 1180px) {
        padding-right: 0;
    }
`;

const ContentModuleLink = styled(ModuleTile).attrs({
    as: Link,
})``;

const ContentModuleExternalLink = styled(ModuleTile).attrs({
    as: 'a',
})``;

const ModuleName = styled.span`
    @media screen and (min-width: 1180px) {
        flex: 1;
        padding-right: 87px;
        text-align: center;
    }
`;

const VehicleMeasures = () => (
    <>
        <ContentModule>
            <ContentModuleLink to="/measures/vehicles/driver-licenses">
                <DriverLicenseControlIcon />
                <ModuleName>Führerscheinkontrolle</ModuleName>
            </ContentModuleLink>
        </ContentModule>
        <ContentModule>
            <ContentModuleExternalLink href="/backend/m/reminder/content?t=car">
                <VehicleContractIcon />
                <ModuleName>Auftrag Fahrzeugführung</ModuleName>
            </ContentModuleExternalLink>
        </ContentModule>
        <ContentModule>
            <ContentModuleExternalLink href="/backend/m/reminder/content?t=car">
                <UvvTuvIcon />
                <ModuleName>UVV/TÜV Prüfung</ModuleName>
            </ContentModuleExternalLink>
        </ContentModule>
    </>
);

export default VehicleMeasures;
