import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import GroupHeading from 'ProjectManager/Template/List/components/Overview/Group/GroupHeading';
import Template from 'ProjectManager/Template/List/components/Overview/Template/Template';

const Wrapper = styled.div``;

const Templates = styled.div`
    margin-top: 5px;
  
    > div {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const Group = ({ group }) => (
    <Wrapper>
        <GroupHeading group={group} />
        <Templates>
            {group.templates.map(template => (
                <Template key={template.id} template={template} group={group}/>
            ))}
        </Templates>
    </Wrapper>
);

Group.propTypes = {
    group: PropTypes.object.isRequired,
};

export default Group;
