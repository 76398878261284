import api from 'Application/api/api';

const goToPreviousExamQuestion = (id, cancelToken = null) =>
    api.post(
        `/education/instruction-assignments/${id}/exam/go-to-previous-question`,
        {},
        { cancelToken },
    );

export default goToPreviousExamQuestion;
