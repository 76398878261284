import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useUploadProjectQuestionFilesFunction from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/hooks/uploadQuestionFilesFunction/useUploadProjectQuestionFilesFunction';
import useUploadTemplateQuestionFilesFunction from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/hooks/uploadQuestionFilesFunction/useUploadTemplateQuestionFilesFunction';

const useUploadQuestionFilesFunction = (
    questionId,
    isCustomQuestion,
    onUpload,
) => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useUploadProjectQuestionFilesFunction(
        questionId,
        isCustomQuestion,
        onUpload,
    );

    const dataFromTemplate = useUploadTemplateQuestionFilesFunction(
        questionId,
        isCustomQuestion,
        onUpload,
    );

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return {};
};

export default useUploadQuestionFilesFunction;
