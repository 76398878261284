import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import NavigationItem from 'ProjectManager/DataManagement/Navigation/components/NavigationItem';
import {
    navigationItems,
    navigationItemsData,
} from 'ProjectManager/DataManagement/Navigation/constants/navigation';
import QuestionnaireNavigationItems from 'ProjectManager/DataManagement/Navigation/components/QuestionnaireNavigationItems';
import { useRecoilValue } from 'recoil';
import useQuestionnaireSections from 'ProjectManager/DataManagement/Questionnaire/hooks/questionnaireSections/useQuestionnaireSections';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import useIsGeneralInformationStepCompleted from 'ProjectManager/DataManagement/Navigation/hooks/useIsGeneralInformationStepCompleted/useIsGeneralInformationStepCompleted';
import useIsEmployeeSelectionStepCompleted from 'ProjectManager/DataManagement/Navigation/hooks/useIsEmployeeSelectionStepCompleted/useIsEmployeeSelectionStepCompleted';
import useIsTopicManagementStepCompleted from 'ProjectManager/DataManagement/Navigation/hooks/useIsTopicManagementStepCompleted/useIsTopicManagementStepCompleted';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';

const Wrapper = styled.div`
    @media screen and (min-width: 1180px) {
        position: sticky;
        top: 80px;
        max-height: calc(100vh - 110px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: 20px 20px 60px 20px;
    }
`;

const Title = styled(OuterBoxTitle)`
    margin-top: 60px;

    &:first-child {
        margin-top: 0;
    }
`;

const Navigation = ({
    title,
    activeItem,
    onActiveItemChange,
    onSubsectionClicked,
}) => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const isProject = dataManagementEntity === dataManagementEntities.PROJECT;
    const { isCreated: isProjectCreated } = useRecoilValue(projectAtom);

    const isTemplate = dataManagementEntity === dataManagementEntities.TEMPLATE;
    const { id: templateId } = useRecoilValue(templateAtom);
    const isTemplateCreated = Boolean(templateId);

    const GeneralInformationIcon =
        navigationItemsData[navigationItems.GENERAL_INFORMATION].icon;

    const EmployeeSelectionIcon =
        navigationItemsData[navigationItems.EMPLOYEE_SELECTION].icon;

    const TopicSelectionIcon =
        navigationItemsData[navigationItems.TOPIC_MANAGEMENT].icon;

    const questionnaireSections = useQuestionnaireSections();

    const isGeneralInformationStepCompleted = useIsGeneralInformationStepCompleted();
    const isEmployeeSelectionStepCompleted = useIsEmployeeSelectionStepCompleted();
    const isTopicManagementStepCompleted = useIsTopicManagementStepCompleted();

    const isTopicManagementDisabled =
        (isTemplate && !isTemplateCreated) ||
        (isProject &&
            !isProjectCreated &&
            activeItem !== navigationItems.TOPIC_MANAGEMENT &&
            !isGeneralInformationStepCompleted &&
            !isEmployeeSelectionStepCompleted &&
            !isTopicManagementStepCompleted);

    const isEmployeeSelectionDisabled =
        (isTemplate && !isTemplateCreated) ||
        (isProject &&
            !isProjectCreated &&
            activeItem === navigationItems.GENERAL_INFORMATION &&
            !isGeneralInformationStepCompleted &&
            !isEmployeeSelectionStepCompleted &&
            !isTopicManagementStepCompleted);

    const showOnlyQuestionnaire = isProject && isProjectCreated;

    return (
        <Wrapper>
            <Title>{title}</Title>
            {!showOnlyQuestionnaire && (
                <>
                    <NavigationItem
                        icon={<GeneralInformationIcon />}
                        name={
                            navigationItemsData[
                                navigationItems.GENERAL_INFORMATION
                            ].name
                        }
                        active={
                            activeItem === navigationItems.GENERAL_INFORMATION
                        }
                        completed={isGeneralInformationStepCompleted}
                        notFullyCompleted={
                            isProject && !isGeneralInformationStepCompleted
                        }
                        onClick={() =>
                            onActiveItemChange(
                                navigationItems.GENERAL_INFORMATION,
                            )
                        }
                    />
                    <NavigationItem
                        icon={<EmployeeSelectionIcon />}
                        name={
                            navigationItemsData[
                                navigationItems.EMPLOYEE_SELECTION
                            ].name
                        }
                        active={
                            activeItem === navigationItems.EMPLOYEE_SELECTION
                        }
                        completed={isEmployeeSelectionStepCompleted}
                        notFullyCompleted={
                            isProject &&
                            !isEmployeeSelectionStepCompleted &&
                            ((activeItem ===
                                navigationItems.GENERAL_INFORMATION &&
                                isTopicManagementStepCompleted) ||
                                activeItem !==
                                    navigationItems.GENERAL_INFORMATION)
                        }
                        disabled={isEmployeeSelectionDisabled}
                        onClick={() =>
                            onActiveItemChange(
                                navigationItems.EMPLOYEE_SELECTION,
                            )
                        }
                    />
                    <Title disabled={isTopicManagementDisabled}>
                        Projektfelder
                    </Title>
                </>
            )}

            <NavigationItem
                icon={<TopicSelectionIcon />}
                name={
                    navigationItemsData[navigationItems.TOPIC_MANAGEMENT].name
                }
                active={activeItem === navigationItems.TOPIC_MANAGEMENT}
                completed={isTopicManagementStepCompleted}
                disabled={isTopicManagementDisabled}
                onClick={() =>
                    onActiveItemChange(navigationItems.TOPIC_MANAGEMENT)
                }
            />
            {questionnaireSections && questionnaireSections.length > 0 && (
                <QuestionnaireNavigationItems
                    activeItem={activeItem}
                    onActiveItemChange={onActiveItemChange}
                    onSubsectionClicked={onSubsectionClicked}
                />
            )}
        </Wrapper>
    );
};

Navigation.defaultProps = {
    onSubsectionClicked: undefined,
};

Navigation.propsTypes = {
    title: PropTypes.string.isRequired,
    activeItem: PropTypes.string.isRequired,
    onActiveItemChange: PropTypes.func.isRequired,
    onSubsectionClicked: PropTypes.func,
};

export default Navigation;
