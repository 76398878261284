import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DeleteRounded } from '@material-ui/icons';
import MenuItem from 'Common/components/MoreActionsButton/MenuItem';
import DeleteCustomQuestionModal from 'ProjectManager/DataManagement/Questionnaire/components/Question/DeleteCustomQuestion/components/DeleteCustomQuestionModal';

const DeleteCustomQuestionMenuItem = ({
    question,
    onClick: closeKebabMenu,
    ...props
}) => {
    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

    const handleClick = () => {
        setIsDeleteModalOpened(true);
    };

    const closeModal = () => {
        setIsDeleteModalOpened(false);
    };

    const handleDelete = () => {
        closeModal();
        closeKebabMenu();
    };

    return (
        <>
            <MenuItem
                icon={<DeleteRounded />}
                type="button"
                onClick={handleClick}
                {...props}
            >
                Löschen
            </MenuItem>
            {isDeleteModalOpened && (
                <DeleteCustomQuestionModal
                    question={question}
                    onCancel={closeModal}
                    onDelete={handleDelete}
                />
            )}
        </>
    );
};

DeleteCustomQuestionMenuItem.propTypes = {
    question: PropTypes.object.isRequired,
};

export default DeleteCustomQuestionMenuItem;
