import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
    DefaultTableBody,
    DefaultTableHead,
    Table,
} from 'Common/components/table';
import { useSortBy, useTable } from 'react-table';
import {
    examStatuses,
    examStatusOptions,
} from 'Education/constants/examStatuses';
import { useCustomCellStyles } from 'Common/hooks/useCustomCellStyles';
import {
    presentationStatuses,
    presentationStatusOptions,
} from 'Education/constants/presentationStatuses';
import { useSelector } from 'react-redux';
import { CheckRounded, CloseRounded, WarningRounded } from '@material-ui/icons';
import { useResponsiveTable } from 'Common/hooks/useResponsiveTable';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';
import WarningMessage from 'Common/components/ActionBar/WarningMessage';
import { Tooltip } from '@material-ui/core';
import stableTableSort from 'Common/utils/stableTableSort';
import { orderBy } from 'lodash';
import GroupedPeopleAvatars from 'Common/components/GroupedPeopleAvatars/GroupedPeopleAvatars';
import GroupAssignmentLabel from 'Employee/components/GroupAssignmentLabel';

const CheckTableIcon = styled(CheckRounded)`
    color: ${colors.GREEN};
`;

const CrossTableIcon = styled(CloseRounded)`
    color: ${colors.DARK_GRAY};
`;

const ExclamationTableIcon = styled(WarningRounded)`
    color: ${colors.WARNING};
`;

const StyledTableHead = styled(DefaultTableHead)`
    th {
        top: 80px;

        @media screen and (min-width: 940px) {
            top: 134px;
        }
    }
`;

const StyledWarningMessage = styled(WarningMessage)`
    justify-content: center;
`;

const OpenInstructionAssignmentsTable = ({
    instructionAssignments,
    onSelectAssignmentIdForConfirmation,
}) => {
    const userId = useSelector(state => state.user.id);

    const columns = useMemo(
        () => [
            {
                Header: 'Unterweisungen',
                accessor: 'name',
            },
            {
                Header: '',
                accessor: 'group',
                disableSortBy: true,
            },
            {
                Header: 'Dauer (Min.)',
                accessor: 'timeSpent',
                disableSortBy: true,
                customCellStyles: {
                    minWidth: 150,
                },
            },
            {
                Header: 'Gelesen',
                accessor: 'presentationStatusIcon',
                disableSortBy: true,
            },
            {
                Header: 'Test',
                accessor: 'examStatusIcon',
                disableSortBy: true,
            },
            {
                Header: 'Aktion',
                accessor: 'actions',
                disableSortBy: true,
                customCellStyles: {
                    textAlign: 'center',
                },
            },
        ],
        [],
    );

    const data = useMemo(
        () =>
            orderBy(
                instructionAssignments.map(assignment => {
                    const timeSpent = Math.ceil(
                        (assignment.timeSpentOnPresentationInSeconds +
                            assignment.timeSpentOnExamInSeconds) /
                            60,
                    );

                    const recommendedTime =
                        assignment.recommendedPresentationDurationInMinutes +
                        assignment.recommendedTestDurationInMinutes;

                    const presentationIconTitle = presentationStatusOptions.find(
                        option =>
                            option.value === assignment.presentationStatus,
                    )?.label;

                    let PresentationStatusIcon = CrossTableIcon;
                    if (
                        assignment.presentationStatus ===
                        presentationStatuses.IN_PROGRESS
                    ) {
                        PresentationStatusIcon = ExclamationTableIcon;
                    } else if (
                        assignment.presentationStatus ===
                        presentationStatuses.COMPLETED
                    ) {
                        PresentationStatusIcon = CheckTableIcon;
                    }

                    const testIconTitle = examStatusOptions.find(
                        option => option.value === assignment.examStatus,
                    )?.label;

                    let TestStatusIcon = CrossTableIcon;
                    if (
                        [
                            examStatuses.IN_PROGRESS,
                            examStatuses.IN_APPROVAL,
                            examStatuses.FAILED,
                        ].includes(assignment.examStatus)
                    ) {
                        TestStatusIcon = ExclamationTableIcon;
                    } else if (
                        assignment.examStatus === examStatuses.COMPLETED
                    ) {
                        TestStatusIcon = CheckTableIcon;
                    }

                    let actionText = 'Starten';

                    if (
                        assignment.presentationStatus ===
                        presentationStatuses.IN_PROGRESS
                    ) {
                        actionText = 'Fortsetzen';
                    } else if (
                        assignment.presentationStatus ===
                            presentationStatuses.COMPLETED &&
                        [
                            examStatuses.NOT_STARTED,
                            examStatuses.IN_PROGRESS,
                            examStatuses.IN_APPROVAL,
                        ].includes(assignment.examStatus)
                    ) {
                        actionText = 'Zum Test';
                    } else if (assignment.examStatus === examStatuses.FAILED) {
                        actionText = 'Neuer Versuch';
                    } else if (
                        assignment.examStatus === examStatuses.COMPLETED
                    ) {
                        actionText = 'Bestätigen';
                    }

                    let buttonLink = `/education/assignment/presentation/${assignment.instructionId}`;

                    if (assignment.id) {
                        buttonLink = `${buttonLink}/${assignment.id}`;
                    }

                    if (
                        assignment.presentationStatus ===
                        presentationStatuses.COMPLETED
                    ) {
                        buttonLink = `/education/assignment/exam/${assignment.id}`;
                    }

                    const isEmployeeResponsible =
                        assignment.responsibleEmployee.user.id === userId;

                    if (
                        !isEmployeeResponsible &&
                        assignment.examStatus !== examStatuses.COMPLETED
                    ) {
                        actionText = 'Im Besitz des Gruppenleiters';
                        buttonLink = null;
                    }

                    // When the employee is a group leader, but is not an assignee,
                    // allow the processing of the group assignment only if the
                    // employee has completed another assignment for the same instruction.
                    if (isEmployeeResponsible && assignment.isGroupAssignment) {
                        const isAssigneeInGroupAssignment =
                            assignment.groupAssignmentDetails?.assignees.findIndex(
                                assignee =>
                                    assignee.employee.user.id === userId,
                            ) !== -1;

                        if (!isAssigneeInGroupAssignment) {
                            const isInstructionCompleted =
                                assignment.responsibleEmployee.completedInstructions.findIndex(
                                    instruction =>
                                        instruction.id ===
                                        assignment.instructionId,
                                ) !== -1;

                            if (!isInstructionCompleted) {
                                actionText =
                                    'Machen Sie zuerst die andere Aufgabe';
                                buttonLink = null;
                            }
                        }
                    }

                    return {
                        id: assignment.id,
                        name: assignment.isGroupAssignment ? (
                            <>
                                <GroupAssignmentLabel />
                                <div>{assignment.name}</div>
                            </>
                        ) : (
                            assignment.name
                        ),
                        group: assignment.isGroupAssignment ? (
                            <GroupedPeopleAvatars
                                people={
                                    assignment.groupAssignmentDetails?.assignees?.map(
                                        assignee => assignee.employee,
                                    ) ?? []
                                }
                            />
                        ) : (
                            ''
                        ),
                        timeSpent: `${timeSpent} / ${recommendedTime}`,
                        presentationStatusIcon: (
                            <Tooltip title={presentationIconTitle}>
                                <PresentationStatusIcon />
                            </Tooltip>
                        ),
                        examStatusIcon: (
                            <Tooltip title={testIconTitle}>
                                <TestStatusIcon />
                            </Tooltip>
                        ),
                        examStatus: assignment.examStatus,
                        actions:
                            assignment.examStatus === examStatuses.COMPLETED ? (
                                <Button
                                    small
                                    onClick={() => {
                                        onSelectAssignmentIdForConfirmation(
                                            assignment.id,
                                        );
                                    }}
                                >
                                    {actionText}
                                </Button>
                            ) : buttonLink ? (
                                <Button small to={buttonLink}>
                                    {actionText}
                                </Button>
                            ) : (
                                <StyledWarningMessage>
                                    <WarningRounded />
                                    {actionText}
                                </StyledWarningMessage>
                            ),
                    };
                }),
                ['name'],
                ['asc'],
            ),
        [instructionAssignments, onSelectAssignmentIdForConfirmation, userId],
    );

    const initialSortBy = useMemo(
        () => [
            {
                id: 'name',
                desc: false,
            },
        ],
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { hiddenColumns },
    } = useTable(
        {
            columns,
            data,
            hideColumnsPriority: [
                'timeSpent',
                'presentationStatusIcon',
                'group',
                'examStatusIcon',
                'actions',
            ],
            disableSortRemove: true,
            disableMultiSort: true,
            initialState: {
                sortBy: initialSortBy,
            },
            orderByFn: stableTableSort,
        },
        useCustomCellStyles,
        useResponsiveTable,
        useSortBy,
    );

    return (
        <Table {...getTableProps()}>
            <StyledTableHead headerGroups={headerGroups} />
            <DefaultTableBody
                rows={rows}
                getTableBodyProps={getTableBodyProps}
                prepareRow={prepareRow}
                hiddenColumns={hiddenColumns}
                visibleColumns={visibleColumns}
            />
        </Table>
    );
};

OpenInstructionAssignmentsTable.propTypes = {
    instructionAssignments: PropTypes.array.isRequired,
    onSelectAssignmentIdForConfirmation: PropTypes.func.isRequired,
};

export default OpenInstructionAssignmentsTable;
