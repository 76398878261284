const withGroupMovedUp = groupId => prevGroups => {
    const movedGroup = prevGroups.find(group => group.id === groupId);

    if (movedGroup) {
        return prevGroups.map(group => {
            // The changed group - it has moved up, so let's decrease the position
            if (group.id === movedGroup.id) {
                return {
                    ...group,
                    position: group.position - 1,
                };
            }

            // The previous group - it will be pushed down, so let's increase the position
            const isPreviousGroup = group.position === movedGroup.position - 1;

            if (isPreviousGroup) {
                return {
                    ...group,
                    position: group.position + 1,
                };
            }

            // All other groups are left unchanged
            return group;
        });
    }

    return prevGroups;
};

export default withGroupMovedUp;
