const gridItems = {
    PROJECT_DETAILS: 'PROJECT_DETAILS',
    TASKS: 'TASKS',
    WORKFORCE_PLANNING: 'WORKFORCE_PLANNING',
    TIMELINE: 'TIMELINE',
    MEDIA_FILES: 'MEDIA_FILES',
    PEOPLE: 'PEOPLE',
    TOPICS: 'TOPICS',
    QUICK_ACTIONS: 'QUICK_ACTIONS',
};

export default gridItems;
