import { atom } from 'recoil';

const key = 'projectManagement_projectsListSortBy';

const getDefaultValue = () => {
    try {
        if (window.localStorage && window.localStorage.getItem(key)) {
            return JSON.parse(window.localStorage.getItem(key));
        }
    } catch (error) {
        // Fail silently
    }

    return {
        field: 'startingDate',
        direction: 'ASC',
    };
};

const projectsSortByAtom = atom({
    key,
    default: getDefaultValue(),
});

export default projectsSortByAtom;
