import { useRecoilValue } from 'recoil';
import isProjectTopicManagementStepCompletedSelector from 'ProjectManager/Project/Common/recoil/project/selectors/isProjectTopicManagementStepCompletedSelector';

const useIsNextButtonAvailableForProject = () => {
    // noinspection UnnecessaryLocalVariableJS
    const isStepCompleted = useRecoilValue(
        isProjectTopicManagementStepCompletedSelector,
    );

    return isStepCompleted;
};

export default useIsNextButtonAvailableForProject;
