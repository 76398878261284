import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { DefaultTable } from 'Common/components/table';
import {
    examStatuses,
    examStatusOptions,
} from 'Education/constants/examStatuses';
import {
    presentationStatuses,
    presentationStatusOptions,
} from 'Education/constants/presentationStatuses';
import {
    CheckRounded,
    CloseRounded,
    CloudDownloadRounded,
    LibraryAddCheckRounded,
    WarningRounded,
} from '@material-ui/icons';
import { useSortBy, useTable } from 'react-table';
import { useCustomCellStyles } from 'Common/hooks/useCustomCellStyles';
import { useResponsiveTable } from 'Common/hooks/useResponsiveTable';
import colors from 'Common/constants/colors';
import MoreActionsButton from 'Common/components/MoreActionsButton/MoreActionsButton';
import MenuItem from 'Common/components/MoreActionsButton/MenuItem';
import { Tooltip } from '@material-ui/core';
import stableTableSort from 'Common/utils/stableTableSort';
import { orderBy } from 'lodash';
import GroupedPeopleAvatars from 'Common/components/GroupedPeopleAvatars/GroupedPeopleAvatars';
import GroupAssignmentLabel from 'Employee/components/GroupAssignmentLabel';

const CheckTableIcon = styled(CheckRounded)`
    color: ${colors.GREEN};
`;

const CrossTableIcon = styled(CloseRounded)`
    color: ${colors.DARK_GRAY};
`;

const ExclamationTableIcon = styled(WarningRounded)`
    color: ${colors.WARNING};
`;

const StyledTable = styled(DefaultTable)`
    th {
        top: 80px;

        @media screen and (min-width: 940px) {
            top: 134px;
        }
    }
`;

const InstructionAssignmentsTableManagerView = ({
    instructionAssignments,
    employeeId,
    showOnlyOpened,
    tabNumber,
}) => {
    const fromTabQueryParam = tabNumber ? `&fromTab=${tabNumber}` : '';

    const columns = useMemo(
        () => [
            {
                Header: 'Unterweisungen',
                accessor: 'name',
            },
            {
                Header: '',
                accessor: 'group',
                disableSortBy: true,
            },
            {
                Header: 'Dauer (Min.)',
                accessor: 'timeSpent',
                disableSortBy: true,
                customCellStyles: {
                    minWidth: 150,
                },
            },
            {
                Header: 'Gelesen',
                accessor: 'presentationStatus',
                disableSortBy: true,
            },
            {
                Header: 'Test',
                accessor: 'examStatus',
                disableSortBy: true,
            },
            {
                Header: 'Aktion',
                accessor: 'actions',
                disableSortBy: true,
                customCellStyles: {
                    textAlign: 'center',
                },
            },
        ],
        [],
    );

    const data = useMemo(() => {
        let filteredInstructionAssignments = [...instructionAssignments];

        if (showOnlyOpened) {
            filteredInstructionAssignments = filteredInstructionAssignments.filter(
                assignment => assignment.examStatus !== examStatuses.COMPLETED,
            );
        }

        return orderBy(
            filteredInstructionAssignments.map(assignment => {
                const timeSpent = Math.ceil(
                    (assignment.timeSpentOnPresentationInSeconds +
                        assignment.timeSpentOnExamInSeconds) /
                        60,
                );

                const recommendedTime =
                    assignment.recommendedPresentationDurationInMinutes +
                    assignment.recommendedTestDurationInMinutes;

                const presentationIconTitle = presentationStatusOptions.find(
                    option => option.value === assignment.presentationStatus,
                )?.label;

                let PresentationStatusIcon = CrossTableIcon;
                if (
                    assignment.presentationStatus ===
                    presentationStatuses.IN_PROGRESS
                ) {
                    PresentationStatusIcon = ExclamationTableIcon;
                } else if (
                    assignment.presentationStatus ===
                    presentationStatuses.COMPLETED
                ) {
                    PresentationStatusIcon = CheckTableIcon;
                }

                const testIconTitle = examStatusOptions.find(
                    option => option.value === assignment.examStatus,
                )?.label;

                let TestStatusIcon = CrossTableIcon;
                if (
                    [
                        examStatuses.IN_PROGRESS,
                        examStatuses.IN_APPROVAL,
                        examStatuses.FAILED,
                    ].includes(assignment.examStatus)
                ) {
                    TestStatusIcon = ExclamationTableIcon;
                } else if (assignment.examStatus === examStatuses.COMPLETED) {
                    TestStatusIcon = CheckTableIcon;
                }

                const isAssignee =
                    assignment.isSingleAssignment ||
                    (assignment.isGroupAssignment &&
                        assignment.groupAssignmentDetails?.assignees.findIndex(
                            assignee => assignee.employee.id === employeeId,
                        ) !== -1);

                const isActionsButtonEnabled = [
                    examStatuses.COMPLETED,
                    examStatuses.IN_APPROVAL,
                    examStatuses.FAILED,
                ].includes(assignment.examStatus);

                return {
                    name: assignment.isGroupAssignment ? (
                        <>
                            <GroupAssignmentLabel />
                            <div>{assignment.name}</div>
                        </>
                    ) : (
                        assignment.name
                    ),
                    group: assignment.isGroupAssignment ? (
                        <GroupedPeopleAvatars
                            people={
                                assignment.groupAssignmentDetails?.assignees?.map(
                                    assignee => assignee.employee,
                                ) ?? []
                            }
                        />
                    ) : (
                        ''
                    ),
                    timeSpent: `${timeSpent} / ${recommendedTime}`,
                    presentationStatus: (
                        <Tooltip title={presentationIconTitle}>
                            <PresentationStatusIcon />
                        </Tooltip>
                    ),
                    examStatus: (
                        <Tooltip title={testIconTitle}>
                            <TestStatusIcon />
                        </Tooltip>
                    ),
                    actions: (
                        <MoreActionsButton disabled={!isActionsButtonEnabled}>
                            {assignment.examStatus ===
                                examStatuses.COMPLETED && (
                                <>
                                    {isAssignee && (
                                        <MenuItem
                                            icon={<CloudDownloadRounded />}
                                            href={`/api/education/instruction-assignments/${assignment.id}/certificates/single/download/${employeeId}`}
                                            target="_blank"
                                        >
                                            Zertifikat
                                        </MenuItem>
                                    )}
                                    {assignment.isGroupAssignment && (
                                        <MenuItem
                                            icon={<CloudDownloadRounded />}
                                            href={`/api/education/instruction-assignments/${assignment.id}/certificates/group/download`}
                                            target="_blank"
                                        >
                                            Gruppenzertifikat
                                        </MenuItem>
                                    )}
                                </>
                            )}
                            <MenuItem
                                icon={<LibraryAddCheckRounded />}
                                to={`/education/assignment/exam/preview/${assignment.id}?comingFromEmployeeId=${employeeId}${fromTabQueryParam}`}
                            >
                                Test anzeigen
                            </MenuItem>
                        </MoreActionsButton>
                    ),
                };
            }),
            ['name'],
            ['asc'],
        );
    }, [employeeId, fromTabQueryParam, instructionAssignments, showOnlyOpened]);

    const initialSortBy = useMemo(
        () => [
            {
                id: 'name',
                desc: false,
            },
        ],
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { hiddenColumns },
    } = useTable(
        {
            columns,
            data,
            hideColumnsPriority: [
                'timeSpent',
                'presentationStatus',
                'group',
                'examStatus',
                'actions',
            ],
            disableSortRemove: true,
            disableMultiSort: true,
            initialState: {
                sortBy: initialSortBy,
            },
            orderByFn: stableTableSort,
        },
        useCustomCellStyles,
        useResponsiveTable,
        useSortBy,
    );

    return (
        <StyledTable
            headerGroups={headerGroups}
            rows={rows}
            hiddenColumns={hiddenColumns}
            visibleColumns={visibleColumns}
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
        />
    );
};

InstructionAssignmentsTableManagerView.defaultProps = {
    tabNumber: null,
};

InstructionAssignmentsTableManagerView.propTypes = {
    instructionAssignments: PropTypes.array.isRequired,
    employeeId: PropTypes.number.isRequired,
    showOnlyOpened: PropTypes.bool.isRequired,
    tabNumber: PropTypes.number,
};

export default InstructionAssignmentsTableManagerView;
