import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import { useDropzone } from 'react-dropzone';
import { CropOriginalRounded, DeleteRounded } from '@material-ui/icons';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px;
    background: ${colors.DIRTY_WHITE};
    color: ${colors.LIGHTER_GRAY};
    word-break: break-word;
    border-radius: 6px;
    overflow: hidden;

    ${ifProp(
        'isClickable',
        css`
            cursor: pointer;
        `,
    )}
`;

const Image = styled.div`
    width: 100%;
    height: 100%;
    background-image: url('${prop('src')}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
`;

const ImageIcon = styled(CropOriginalRounded)`
    font-size: 55px !important;
`;

const ActionsPanel = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    background: rgba(0, 0, 0, 0.65);

    > button {
        margin-bottom: 5px;

        &:hover {
            color: ${colors.PRIMARY};
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const QuestionImage = ({ defaultImage, onImageChange }) => {
    const [image, setImage] = useState(defaultImage ?? null);
    const [isActionsPanelVisible, setIsActionsPanelVisible] = useState(false);

    const { open, getRootProps, getInputProps } = useDropzone({
        multiple: false,
        noClick: isActionsPanelVisible,
        accept: 'image/jpg, image/jpeg, image/png',
        onDrop: acceptedFiles => {
            if (acceptedFiles && acceptedFiles[0]) {
                const file = acceptedFiles[0];

                setImage(URL.createObjectURL(file));

                onImageChange(file);
            }
        },
    });

    // Make sure to revoke the data URI to avoid memory leaks
    useEffect(
        () => () => {
            if (image !== null) {
                URL.revokeObjectURL(image);
            }
        },
        [image],
    );

    const handleMouseEnter = () => {
        if (hasImage) {
            setIsActionsPanelVisible(true);
        }
    };

    const handleMouseLeave = () => {
        if (hasImage) {
            setIsActionsPanelVisible(false);
        }
    };

    const handleImageDelete = e => {
        e.stopPropagation();
        setImage(null);
        setIsActionsPanelVisible(false);

        onImageChange(null);
    };

    const hasImage = !!image;

    return (
        <Wrapper
            isClickable={!hasImage}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            {...getRootProps()}
        >
            <input {...getInputProps()} />
            {hasImage ? <Image src={image} /> : <ImageIcon />}
            {isActionsPanelVisible && (
                <ActionsPanel>
                    <Button
                        text
                        icon={<CropOriginalRounded />}
                        type="button"
                        onClick={open}
                    >
                        Bild ändern
                    </Button>
                    <Button
                        text
                        icon={<DeleteRounded />}
                        type="button"
                        onClick={handleImageDelete}
                    >
                        Bild löschen
                    </Button>
                </ActionsPanel>
            )}
        </Wrapper>
    );
};

QuestionImage.defaultProps = {
    defaultImage: null,
};

QuestionImage.propTypes = {
    defaultImage: PropTypes.any,
    onImageChange: PropTypes.func.isRequired,
};

export default QuestionImage;
