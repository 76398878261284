const withStatusFilter = (status, isChecked) => prevFilters => {
    const statuses = [...prevFilters.statuses];

    if (isChecked && !statuses.includes(status)) {
        statuses.push(status);
    } else if (!isChecked && statuses.includes(status)) {
        statuses.splice(statuses.indexOf(status), 1);
    }

    return {
        ...prevFilters,
        statuses,
    };
};

export default withStatusFilter;
