import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useDeleteTemplateCustomQuestionFunction from 'ProjectManager/DataManagement/Questionnaire/components/Question/DeleteCustomQuestion/hooks/deleteCustomQuestionFunction/useDeleteTemplateCustomQuestionFunction';
import useDeleteProjectCustomQuestionFunction from 'ProjectManager/DataManagement/Questionnaire/components/Question/DeleteCustomQuestion/hooks/deleteCustomQuestionFunction/useDeleteProjectCustomQuestionFunction';

const useDeleteCustomQuestionFunction = questionId => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useDeleteProjectCustomQuestionFunction(questionId);

    const dataFromTemplate = useDeleteTemplateCustomQuestionFunction(
        questionId,
    );

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return () => {};
};

export default useDeleteCustomQuestionFunction;
