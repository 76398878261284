import React from 'react';
import {
    EditRounded,
    EmailRounded,
    NotificationsRounded,
} from '@material-ui/icons';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import QuickAction from 'ProjectManager/Project/Preview/QuickActions/QuickAction';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import Tile from 'ProjectManager/Project/Preview/Tile';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import DownloadAction from 'ProjectManager/Project/Preview/QuickActions/DownloadAction/components/DownloadAction';

const QuickActions = () => {
    const { id, status } = useRecoilValue(projectAtom);

    return (
        <Tile>
            <OuterBoxTitle>Schnellzugriff</OuterBoxTitle>
            <div>
                <DownloadAction />
                <QuickAction
                    icon={<EmailRounded />}
                    title="Report senden"
                    description="Versende den PDF Report an Dritte, die im Vorfeld eingebunden wurden (Einbezogene Instanzen)."
                    type="button"
                />
                <QuickAction
                    icon={<NotificationsRounded />}
                    title="Erinnerung versenden"
                    description="Erinnere alle in das Projekt eingebundene Personen über ausstehende Aufgaben."
                    type="button"
                    disabled={status === projectStatuses.ARCHIVED}
                />
                <QuickAction
                    icon={<EditRounded />}
                    title="Projekt bearbeiten"
                    description="Nutze die Möglichkeit Projektanhänge und Fragen zu aktualisieren."
                    to={`/project-manager/project/${id}/edit`}
                    disabled={status === projectStatuses.ARCHIVED}
                />
            </div>
        </Tile>
    );
};

export default QuickActions;
