import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';

const CommonBox = styled.div`
    color: ${colors.DIRTY_WHITE};
    box-shadow: 0 7px 10px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
`;

export default CommonBox;
