import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useIsProjectGeneralInformationStepCompleted from 'ProjectManager/DataManagement/Navigation/hooks/useIsGeneralInformationStepCompleted/useIsProjectGeneralInformationStepCompleted';
import useIsTemplateGeneralInformationStepCompleted from 'ProjectManager/DataManagement/Navigation/hooks/useIsGeneralInformationStepCompleted/useIsTemplateGeneralInformationStepCompleted';

const useIsGeneralInformationStepCompleted = () => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const projectData = useIsProjectGeneralInformationStepCompleted();
    const templateData = useIsTemplateGeneralInformationStepCompleted();

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return projectData;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return templateData;
    }

    return false;
};

export default useIsGeneralInformationStepCompleted;
