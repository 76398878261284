import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import sendRemindersForIntroduction from 'ProjectManager/Project/Introductions/Common/api/sendRemindersForIntroduction';

const ReminderModal = ({ introduction, onClose }) => {
    const [isConfirming, setIsConfirming] = useState(false);

    const { id: projectId } = useRecoilValue(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirmButtonClick = async () => {
        setIsConfirming(true);

        try {
            await sendRemindersForIntroduction(
                projectId,
                introduction.id,
                source.token,
            );

            setIsConfirming(false);

            onClose();

            notify('Benachrichtigung erfolgreich ausgeführt', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsConfirming(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const participantsEligibleForReminder = introduction.participants.filter(
        participant => participant.isRelevant && !participant.isConfirmed,
    ).length;

    return (
        <Modal
            isOpen
            onRequestClose={isConfirming ? undefined : onClose}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onClose}
                        disabled={isConfirming}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        onClick={handleConfirmButtonClick}
                        isLoading={isConfirming}
                        disabled={isConfirming}
                    >
                        Benachrichtigen
                    </Button>
                </>
            }
        >
            <ModalTitle>Mitarbeiter benachrichtigen</ModalTitle>
            <p>
                Nach deiner Bestätigung werden jene Mitarbeiter benachrichtigt
                bei denn die Bestätigung aussteht. Bist du dir sicher das du
                {` (${participantsEligibleForReminder} Mitarbeiter) `}benachrichtigen
                willst?
            </p>
        </Modal>
    );
};

ReminderModal.propTypes = {
    introduction: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ReminderModal;
