import api from 'Application/api/api';

const isClientNameUnique = (name, clientId = null, cancelToken = null) =>
    api.get(`project-manager/clients/is-name-unique/${name}`, {
        params: {
            clientId,
        },
        cancelToken,
    });

export default isClientNameUnique;
