import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import InnerBox from 'Common/components/Boxes/InnerBox';
import MoreActionsButton from 'Common/components/MoreActionsButton/MoreActionsButton';
import MenuItem from 'Common/components/MoreActionsButton/MenuItem';
import { DeleteRounded, EditRounded } from '@material-ui/icons';
import CustomTopicDeleteModal from 'ProjectManager/DataManagement/TopicManagement/components/CustomTopicManagement/components/CustomTopicTile/CustomTopicDeleteModal';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';

const Wrapper = styled(InnerBox)`
    display: flex;
    align-items: center;
    padding: 5px 10px 5px 15px;
    font-size: 14px;
    line-height: 21px;
`;

const Info = styled.div`
    flex: 1;
    margin-right: 10px;
    word-break: break-word;
`;

const Name = styled.div``;

const CustomTopicPreview = ({ customTopic, onEditButtonClick }) => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);
    const isProject = dataManagementEntity === dataManagementEntities.PROJECT;

    const { status: projectStatus } = useRecoilValue(projectAtom);
    const isDeletingPossible =
        !isProject || projectStatus !== projectStatuses.ENDED;

    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

    const handleDeleteButtonClick = () => {
        setIsDeleteModalOpened(true);
    };

    const closeModal = () => {
        setIsDeleteModalOpened(false);
    };

    return (
        <>
            <Wrapper>
                <Info>
                    <Name>{customTopic.name}</Name>
                </Info>
                <MoreActionsButton>
                    <MenuItem
                        icon={<EditRounded />}
                        type="button"
                        onClick={onEditButtonClick}
                    >
                        Bearbeiten
                    </MenuItem>
                    <MenuItem
                        icon={<DeleteRounded />}
                        type="button"
                        onClick={handleDeleteButtonClick}
                        disabled={!isDeletingPossible}
                        tooltip={
                            !isDeletingPossible
                                ? 'Löschen nicht erlaubt. Projekt ist im Status Beendet.'
                                : ''
                        }
                    >
                        Löschen
                    </MenuItem>
                </MoreActionsButton>
            </Wrapper>
            {isDeleteModalOpened && (
                <CustomTopicDeleteModal
                    customTopic={customTopic}
                    onCancel={closeModal}
                    onDelete={closeModal}
                />
            )}
        </>
    );
};

CustomTopicPreview.propTypes = {
    customTopic: PropTypes.object.isRequired,
    onEditButtonClick: PropTypes.func.isRequired,
};

export default CustomTopicPreview;
