import styled from 'styled-components/macro';
import ModalTitle from 'Common/components/Modals/ModalTitle';

const ModalSectionTitle = styled(ModalTitle)`
    margin-bottom: 15px;
    margin-top: 40px;

    &:first-child {
        margin-top: 0;
    }
`;

export default ModalSectionTitle;
