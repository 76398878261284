import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import Button from 'Common/components/Button';
import ModalOption from 'Common/components/Modals/ModalOption';
import dataTypes, {
    dataTypesInfo,
} from 'ProjectManager/Project/Preview/QuickActions/DownloadAction/constants/dataTypes';
import requestDataDownload from 'ProjectManager/Project/Common/api/downloadRequest/requestDataDownload';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';

const Options = styled.div`
    > * {
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const useDetailedDataTypeSelectionModal = ({
    value,
    onChange,
    onClose,
    onBackButtonClick,
    onSuccess,
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const {
        id: projectId,
        files,
        questionnaireSections,
        statistics: { participantsWithInstructionCertificates },
    } = useRecoilValue(projectAtom);

    const hasInstructionCertificates =
        participantsWithInstructionCertificates > 0;

    const hasGeneralFiles = files.length > 0;

    const hasQuestionFiles = questionnaireSections.some(section =>
        section.subsections.some(subsection =>
            subsection.questions.some(question => question.files.length > 0),
        ),
    );

    const handleToggle = (contentType, isChecked) => {
        onChange(prevContentTypes => {
            if (isChecked && !prevContentTypes.includes(contentType)) {
                return [...prevContentTypes, contentType];
            }

            if (!isChecked && prevContentTypes.includes(contentType)) {
                const newContentTypes = [...prevContentTypes];

                newContentTypes.splice(newContentTypes.indexOf(contentType), 1);

                return newContentTypes;
            }

            return prevContentTypes;
        });
    };

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleDownloadButtonClick = async () => {
        setIsLoading(true);

        try {
            await requestDataDownload(projectId, value, {}, true, source.token);

            setIsLoading(false);

            onSuccess();
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsLoading(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    return {
        props: {
            onRequestClose: isLoading ? undefined : onClose,
            buttons: (
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onBackButtonClick}
                        disabled={isLoading}
                    >
                        Zurück
                    </Button>
                    <Button
                        type="button"
                        disabled={value.length === 0 || isLoading}
                        onClick={handleDownloadButtonClick}
                        isLoading={isLoading}
                    >
                        Download
                    </Button>
                </>
            ),
        },
        content: (
            <>
                <p>Welche Inhalte möchtest du einbeziehen</p>
                <Options>
                    <ModalOption
                        title={dataTypesInfo[dataTypes.PDF_REPORT].title}
                        description={
                            dataTypesInfo[dataTypes.PDF_REPORT].description
                        }
                        isChecked={value.includes(dataTypes.PDF_REPORT)}
                        onToggle={isChecked =>
                            handleToggle(dataTypes.PDF_REPORT, isChecked)
                        }
                        isSwitch
                    />
                    <ModalOption
                        title={
                            dataTypesInfo[dataTypes.INSTRUCTION_CERTIFICATES]
                                .title
                        }
                        description={
                            dataTypesInfo[dataTypes.INSTRUCTION_CERTIFICATES]
                                .description
                        }
                        isChecked={value.includes(
                            dataTypes.INSTRUCTION_CERTIFICATES,
                        )}
                        onToggle={isChecked =>
                            handleToggle(
                                dataTypes.INSTRUCTION_CERTIFICATES,
                                isChecked,
                            )
                        }
                        isSwitch
                        disabled={!hasInstructionCertificates}
                        tooltip={
                            !hasInstructionCertificates
                                ? 'Es sind keine Daten vorhanden, welche du downloaden kannst'
                                : ''
                        }
                    />
                    <ModalOption
                        title={dataTypesInfo[dataTypes.GENERAL_FILES].title}
                        description={
                            dataTypesInfo[dataTypes.GENERAL_FILES].description
                        }
                        isChecked={value.includes(dataTypes.GENERAL_FILES)}
                        onToggle={isChecked =>
                            handleToggle(dataTypes.GENERAL_FILES, isChecked)
                        }
                        isSwitch
                        disabled={!hasGeneralFiles}
                        tooltip={
                            !hasGeneralFiles
                                ? 'Es sind keine Daten vorhanden, welche du downloaden kannst'
                                : ''
                        }
                    />
                    <ModalOption
                        title={dataTypesInfo[dataTypes.QUESTION_FILES].title}
                        description={
                            dataTypesInfo[dataTypes.QUESTION_FILES].description
                        }
                        isChecked={value.includes(dataTypes.QUESTION_FILES)}
                        onToggle={isChecked =>
                            handleToggle(dataTypes.QUESTION_FILES, isChecked)
                        }
                        isSwitch
                        disabled={!hasQuestionFiles}
                        tooltip={
                            !hasQuestionFiles
                                ? 'Es sind keine Daten vorhanden, welche du downloaden kannst'
                                : ''
                        }
                    />
                </Options>
            </>
        ),
    };
};

export default useDetailedDataTypeSelectionModal;
