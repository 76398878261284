import React from 'react';
import PropTypes from 'prop-types';
import InteractableInnerBox from 'Common/components/Boxes/InteractableInnerBox';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import colors from 'Common/constants/colors';
import { ifProp } from 'styled-tools';
import { CheckRounded, WarningRounded } from '@material-ui/icons';
import { useRecoilState, useSetRecoilState } from 'recoil';
import hasUnsavedChangesState from 'Common/recoil/hasUnsavedChangesState';
import questionWithOpenedImagePreviewAtom from 'ProjectManager/DataManagement/Common/recoil/questionWithOpenedImagePreview/questionWithOpenedImagePreviewAtom';

const Wrapper = styled.div`
    margin-bottom: 15px;
`;

const NameIconWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    margin-right: 10px;

    svg {
        margin-right: 10px;
    }
`;

const Name = styled.div`
    padding-top: 2px;
    font-size: 14px;
    line-height: 21px;
`;

const CompletedIcon = styled(CheckRounded)`
    color: ${colors.GREEN};
`;

const NotFullyCompletedIcon = styled(WarningRounded)`
    color: ${colors.WARNING};
`;

const Subsections = styled.div`
    background: ${colors.PAGE_BACKGROUND};
    padding: 20px 20px 20px 46px;
    border-radius: 0 0 6px 6px;

    > button {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const Tile = styled(InteractableInnerBox).attrs({
    as: 'button',
})`
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 12px;
    outline: 0 none;
    border: 0 none;
    border-radius: 6px;
    cursor: pointer;
    text-align: left;

    ${ifProp(
        'hasStatus',
        css`
            justify-content: space-between;
        `,
    )};

    &[disabled] {
        opacity: 0.35;
        cursor: default;

        &:hover {
            background: ${colors.INNER_BOX_BACKGROUND};
        }
    }

    ${ifProp(
        'active',
        css`
            border-left: 6px solid ${colors.PRIMARY};
            background: ${colors.DIRTY_WHITE};
            cursor: default;

            &:hover {
                background: ${colors.DIRTY_WHITE};
            }

            ${NameIconWrapper} {
                svg {
                    color: ${colors.DARK_GRAY};
                }
            }

            ${Name} {
                color: ${colors.DARK_GRAY};
            }
        `,
    )};
`;

const NavigationItem = ({
    icon,
    name,
    completed,
    notFullyCompleted,
    onClick,
    children,
    ...props
}) => {
    const canShowStatus = !props.active && !props.disabled;
    const hasStatus = completed || notFullyCompleted;

    const [hasUnsavedChanges, setHasUnsavedChanges] = useRecoilState(
        hasUnsavedChangesState,
    );

    const setQuestionWithOpenedImagePreview = useSetRecoilState(
        questionWithOpenedImagePreviewAtom,
    );

    const executeClickLogic = () => {
        setQuestionWithOpenedImagePreview(null);
        onClick();
    };

    const handleClick = () => {
        if (hasUnsavedChanges) {
            if (
                window.confirm(
                    'Möchtest du die Seite wirklich verlassen? Nicht gespeicherte Änderungen gehen verloren.',
                )
            ) {
                setHasUnsavedChanges(false);
                executeClickLogic();
            }
        } else {
            executeClickLogic();
        }
    };

    return (
        <Wrapper>
            <Tile
                type="button"
                hasStatus={canShowStatus && hasStatus}
                onClick={handleClick}
                {...props}
            >
                <NameIconWrapper>
                    {icon}
                    <Name>{name}</Name>
                </NameIconWrapper>
                {completed && canShowStatus && <CompletedIcon />}
                {notFullyCompleted && canShowStatus && (
                    <NotFullyCompletedIcon />
                )}
            </Tile>
            {props.active && children && children.length > 0 && (
                <Subsections>{children}</Subsections>
            )}
        </Wrapper>
    );
};

NavigationItem.defaultProps = {
    active: false,
    disabled: false,
    completed: false,
    notFullyCompleted: false,
};

NavigationItem.propsTypes = {
    icon: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    completed: PropTypes.bool,
    notFullyCompleted: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

export default NavigationItem;
