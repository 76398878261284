import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getEmployeeDriverLicenseControl from 'Measure/api/driver-license/getEmployeeDriverLicenseControl';
import { push, replace } from 'connected-react-router';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import passwordConfirmSuccessfulCheckForDirectProcedure from 'Measure/api/driver-license/direct-check/passwordConfirmSuccessfulCheckForDirectProcedure';
import axios from 'axios';
import signatureConfirmSuccessfulCheckForDirectProcedure from 'Measure/api/driver-license/direct-check/signatureConfirmSuccessfulCheckForDirectProcedure';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import modules from 'Common/constants/modules';
import { Redirect } from 'react-router';
import DriverLicensePasswordVerification from 'Measure/components/vehicle/driver-license/check/DriverLicensePasswordVerification';
import DriverLicenseSignatureVerification from 'Measure/components/vehicle/driver-license/check/DriverLicenseSignatureVerification';
import Title from 'Measure/components/vehicle/driver-license/Common/Title';
import BackButton from 'Application/components/Header/BackButton/BackButton';
import createNewDate from 'Common/utils/createNewDate';
import formatDate from 'Common/utils/formatDate';

const DriverLicenseEmployeeDirectCheck = () => {
    const dispatch = useDispatch();

    const userId = useSelector(state => state.user.id);

    const isAutomaticSigningAllowed = useSelector(
        state => state.user.isAutomaticSigningAllowed,
    );

    const driverLicenseControlRequest = useCallback(
        cancelToken =>
            getEmployeeDriverLicenseControl(
                [
                    'examiners',
                    'employee',
                    'driverLicense',
                    'driverLicense.files',
                    'driverLicense.nextCheck',
                    'driverLicense.nextCheck.directProcedure',
                ],
                cancelToken,
            ),
        [],
    );

    const {
        data: driverLicenseControl,
        loadData: loadDriverLicenseControl,
        isLoading,
        hasError,
        error,
    } = useAxiosRequest(driverLicenseControlRequest, null);

    useEffect(() => {
        const responseStatus = error?.response?.status;

        if (responseStatus === 404) {
            dispatch(replace('/404'));
        }
    }, [dispatch, error]);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    if (isLoading) {
        return <Loader />;
    }

    if (hasError) {
        return <RetryLoading onRetry={loadDriverLicenseControl} />;
    }

    const handleConfirmPassword = async password => {
        await passwordConfirmSuccessfulCheckForDirectProcedure(
            driverLicenseControl.id,
            password,
            source.token,
        );

        dispatch(push(`/dashboard?module=${modules.MEASURES}`));
    };

    const handleConfirmSignature = async blob => {
        const date = createNewDate();
        const file = new File(
            [blob],
            `DriverLicenseSignature_Employee_${userId}_${formatDate(
                date,
                'yyyy-MM-dd_H-mm-ss',
            )}.png`,
            {
                type: 'image/png',
                lastModified: date,
            },
        );

        await signatureConfirmSuccessfulCheckForDirectProcedure(
            driverLicenseControl.id,
            file,
            source.token,
        );

        dispatch(push(`/dashboard?module=${modules.MEASURES}`));
    };

    const driverLicense = driverLicenseControl?.driverLicense;

    if (
        driverLicenseControl &&
        driverLicenseControl.isActive &&
        driverLicense &&
        driverLicense.isUsingDirectControlMethod &&
        !driverLicense.isInCreationProcess &&
        !driverLicense.isExpired &&
        !driverLicense.isLost &&
        driverLicense.nextCheck.isInVerificationPeriod &&
        driverLicense.nextCheck.directProcedure.isPendingEmployeeConfirmation
    ) {
        const hint = (
            <>
                Hinweis: Hiermit bestätigen Sie, dass Sie Ihren Führerschein
                einem der zugewiesenen Prüfer im Original vorgelegt haben.
            </>
        );

        const confirmButtonText = 'Kontrolle abschließen';

        return (
            <>
                <Helmet>
                    <title>Prüfung - Führerscheinkontrolle - Maßnahmen</title>
                </Helmet>
                <BackButton to="/measures/vehicles/driver-license/overview" />
                <Breadcrumb to={`/dashboard?module=${modules.MEASURES}`}>
                    Maßnahmen
                </Breadcrumb>
                <Breadcrumb to="/measures/vehicles/driver-license/overview">
                    Führerscheinkontrolle
                </Breadcrumb>
                <Breadcrumb isActive>Prüfung</Breadcrumb>
                <Title>Führerscheinkontrolle</Title>
                {isAutomaticSigningAllowed ? (
                    <DriverLicensePasswordVerification
                        hint={hint}
                        confirmButtonText={confirmButtonText}
                        onConfirm={handleConfirmPassword}
                        showBackButton={false}
                    />
                ) : (
                    <DriverLicenseSignatureVerification
                        hint={hint}
                        confirmButtonText={confirmButtonText}
                        onConfirm={handleConfirmSignature}
                        showBackButton={false}
                    />
                )}
            </>
        );
    }

    return <Redirect to={`/dashboard?module=${modules.MEASURES}`} />;
};

export default DriverLicenseEmployeeDirectCheck;
