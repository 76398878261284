import { selector } from 'recoil';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';

const isTemplateTopicManagementStepCompletedSelector = selector({
    key: 'projectManagement_isTemplateTopicManagementStepCompleted',
    get: ({ get }) => {
        const { id, topics, customTopics } = get(templateAtom);

        return Boolean(id) && topics.length + customTopics.length > 0;
    },
});

export default isTemplateTopicManagementStepCompletedSelector;
