import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useProjectFiles from 'ProjectManager/DataManagement/Files/hooks/files/useProjectFiles';
import useTemplateFiles from 'ProjectManager/DataManagement/Files/hooks/files/useTemplateFiles';

const useFiles = () => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useProjectFiles();
    const dataFromTemplate = useTemplateFiles();

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return [];
};

export default useFiles;
