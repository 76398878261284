import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { get } from 'lodash';
import FormField from 'Common/components/Form/FormField';
import Input from 'Common/components/Form/Fields/Input';
import Select from 'Common/components/Form/Fields/Select';
import colors from 'Common/constants/colors';
import { DeleteRounded } from '@material-ui/icons';

const FormRow = styled.div`
    display: flex;
    align-items: flex-end;

    > div {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }

    @media screen and (max-width: 679px) {
        flex-wrap: wrap;
        margin-bottom: 25px;
        border-bottom: 1px solid ${colors.GRAY};

        > div {
            &:first-child {
                flex: 1 0 auto;
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }

    @media screen and (min-width: 680px) {
        > div {
            align-self: flex-start;
        }
    }
`;

const DeleteButton = styled.button`
    flex: 0 0 15px;
    height: 38px;
    margin-left: 7px;
    padding: 0 3px;
    background: none;
    border: 0 none;
    color: ${colors.GRAY};
    cursor: pointer;

    &:hover,
    &:focus {
        outline: 0 none;
        color: ${colors.DIRTY_WHITE};
    }
`;

const Answers = ({
    answers,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    fieldPrefix,
    onAnswerDelete,
}) => (
    <div>
        {answers.map((answer, index) => (
            <FormRow key={answer.id}>
                <FormField>
                    <Input
                        label={`Antwort ${index + 1}`}
                        id={`${fieldPrefix}[${index}].text`}
                        name={`${fieldPrefix}[${index}].text`}
                        error={get(errors, `${fieldPrefix}[${index}].text`)}
                        touched={get(
                            touched,
                            `${fieldPrefix}[${index}].text`,
                            false,
                        )}
                        value={answer.text}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>
                <FormField size={150}>
                    <Select
                        id={`${fieldPrefix}[${index}].isCorrect`}
                        name={`${fieldPrefix}[${index}].isCorrect`}
                        label="Antwort"
                        error={get(
                            errors,
                            `${fieldPrefix}[${index}].isCorrect`,
                        )}
                        touched={get(
                            touched,
                            `${fieldPrefix}[${index}].isCorrect`,
                            false,
                        )}
                        value={answer.isCorrect}
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        options={[
                            {
                                value: true,
                                label: 'Richtig',
                            },
                            {
                                value: false,
                                label: 'Falsch',
                            },
                        ]}
                        isSearchable={false}
                    />
                </FormField>
                <FormField size={40}>
                    {answers.length > 2 && (
                        <DeleteButton onClick={() => onAnswerDelete(answer.id)}>
                            <DeleteRounded size="sm" />
                        </DeleteButton>
                    )}
                </FormField>
            </FormRow>
        ))}
    </div>
);

Answers.propTypes = {
    answers: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    fieldPrefix: PropTypes.string.isRequired,
    onAnswerDelete: PropTypes.func.isRequired,
};

export default Answers;
