import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';

const Wrapper = styled.div`
    margin-bottom: 20px;

    @media screen and (min-width: 425px) {
        margin-bottom: 30px;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

const Label = styled.div`
    font-size: 12px;
    line-height: 18px;
    color: ${colors.GRAY};
    text-transform: uppercase;
`;

const Value = styled.div`
    font-size: 14px;
    line-height: 21px;
`;

const LabeledValue = ({ label, children, ...props }) => (
    <Wrapper {...props}>
        <Label>{label}</Label>
        <Value>{children}</Value>
    </Wrapper>
);

LabeledValue.propTypes = {
    label: PropTypes.string.isRequired,
};

export default LabeledValue;
