import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import { StickyTabList, Tab, TabPanel, Tabs } from 'Common/components/tabs';
import AllDriverLicenseControlsTab from 'Measure/components/vehicle/driver-license/AllDriverLicenseControlsTab';
import DriverLicenseExaminerDashboardSidebar from 'Measure/components/vehicle/driver-license/DriverLicenseExaminerDashboardSidebar';
import driverLicenseStatusFilters from 'Measure/constants/driverLicenseStatusFilters';
import PendingDriverLicenseControlsTab from 'Measure/components/vehicle/driver-license/PendingDriverLicenseControlsTab';
import isAuthorized from 'User/utils/isAuthorized';
import roles from 'User/constants/roles';
import modules from 'Common/constants/modules';
import useHash from 'Common/hooks/useHash';
import driverLicensePendingFilters from 'Measure/constants/driverLicensePendingFilters';
import BackButton from 'Application/components/Header/BackButton/BackButton';
import { useSelector } from 'react-redux';

const Layout = styled.div`
    @media screen and (min-width: 940px) {
        display: flex;
    }
`;

const Content = styled.div`
    @media screen and (min-width: 940px) {
        flex: 1;
    }
`;

const DriverLicenseExaminerDashboard = () => {
    const isUsingOnlyNewModules = useSelector(
        state => state.user.isUsingOnlyNewModules,
    );

    const [searchTerm, setSearchTerm] = useState('');

    const [selectedPendingFilter, setSelectedPendingFilter] = useState(
        driverLicensePendingFilters.MY_PENDING,
    );

    const [selectedStatus, setSelectedStatus] = useState(
        driverLicenseStatusFilters.ACTIVE,
    );

    const [
        allDriverLicenseControlsFound,
        setAllDriverLicenseControlsFound,
    ] = useState([]);

    const [tabIndex, setTabIndex] = useHash();

    // Clear the search term when the user goes back to the first tab
    useEffect(() => {
        if (tabIndex === 0) {
            setSearchTerm('');
        }
    }, [tabIndex]);

    const handleSearchTermChange = value => {
        if (value) {
            setTabIndex(1);
        }

        setSearchTerm(value);
    };

    const handleAllDriverLicenseControlsFound = useCallback(
        allDriverLicenseControls => {
            setAllDriverLicenseControlsFound(allDriverLicenseControls);
        },
        [],
    );

    return (
        <>
            {isAuthorized([roles.MANAGER]) ? (
                isUsingOnlyNewModules ? (
                    <>
                        <Helmet>
                            <title>Führerscheinkontrolle</title>
                        </Helmet>
                        <BackButton to="/dashboard" />
                        <Breadcrumb isActive>Führerscheinkontrolle</Breadcrumb>
                    </>
                ) : (
                    <>
                        <Helmet>
                            <title>
                                Führerscheinkontrolle - Fuhrpark - Maßnahmen
                            </title>
                        </Helmet>
                        <BackButton to="/measures/vehicles" />
                        <Breadcrumb to="/measures">Maßnahmen</Breadcrumb>
                        <Breadcrumb to="/measures/vehicles">
                            Fuhrpark
                        </Breadcrumb>
                        <Breadcrumb isActive>Führerscheinkontrolle</Breadcrumb>
                    </>
                )
            ) : (
                <>
                    <Helmet>
                        <title>Führerscheinkontrolle - Maßnahmen</title>
                    </Helmet>
                    <BackButton to={`/dashboard?module=${modules.MEASURES}`} />
                    <Breadcrumb to={`/dashboard?module=${modules.MEASURES}`}>
                        Maßnahmen
                    </Breadcrumb>
                    <Breadcrumb isActive>Führerscheinkontrolle</Breadcrumb>
                </>
            )}
            <Layout>
                <DriverLicenseExaminerDashboardSidebar
                    searchTerm={searchTerm}
                    onSearchTermChange={handleSearchTermChange}
                    selectedTabIndex={tabIndex}
                    selectedStatus={selectedStatus}
                    onStatusChange={setSelectedStatus}
                    selectedPendingFilter={selectedPendingFilter}
                    onPendingFilterChange={setSelectedPendingFilter}
                    driverLicenseControlsFound={allDriverLicenseControlsFound}
                />
                <Content>
                    <Tabs selectedIndex={tabIndex} onSelect={setTabIndex}>
                        <StickyTabList>
                            <Tab>Ausstehende Aufgaben</Tab>
                            <Tab>Alle Aufgaben</Tab>
                        </StickyTabList>

                        <TabPanel>
                            <PendingDriverLicenseControlsTab
                                selectedPendingFilter={selectedPendingFilter}
                            />
                        </TabPanel>
                        <TabPanel>
                            <AllDriverLicenseControlsTab
                                searchTerm={searchTerm}
                                selectedStatus={selectedStatus}
                                onDriverLicenseControlsFound={
                                    handleAllDriverLicenseControlsFound
                                }
                            />
                        </TabPanel>
                    </Tabs>
                </Content>
            </Layout>
        </>
    );
};

export default DriverLicenseExaminerDashboard;
