import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import styled from 'styled-components/macro';
import Status from 'ProjectManager/Project/Preview/ProjectDetails/Status';
import colors from 'Common/constants/colors';
import { EditRounded } from '@material-ui/icons';
import Button from 'Common/components/Button';
import ImagePreview from 'ProjectManager/Project/Preview/ProjectDetails/ImagePreview';
import ProjectDrawer from 'ProjectManager/Project/Preview/ProjectDrawer';
import ProjectData from 'ProjectManager/Project/DataManagement/GeneralInformation/ProjectData';
import Progress from 'ProjectManager/Project/Preview/ProjectDetails/Progress';
import WarningMessage from 'Common/components/WarningMessage';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import Tile from 'ProjectManager/Project/Preview/Tile';

const Info = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @media screen and (min-width: 425px) {
        flex-direction: row;
    }
`;

const StatusWrapper = styled.div`
    margin-bottom: 20px;

    @media screen and (min-width: 425px) {
        order: 2;
        margin-bottom: 0;
    }
`;

const Details = styled.div`
    flex: 1;

    @media screen and (min-width: 425px) {
        order: 1;
        margin-right: 20px;
    }
`;

const ClientName = styled.div`
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    color: ${colors.GRAY};
`;

const ProjectName = styled(OuterBoxTitle)`
    margin-top: 3px;
    margin-bottom: 0;
`;

const ProjectAddress = styled.div`
    margin-top: 5px;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;

    > button {
        margin-top: 5px;

        &:first-child {
            margin-top: 0;
        }
    }
`;

const StyledButton = styled(Button)`
    justify-content: initial;
    padding-left: 0;
`;

const ProjectDetails = () => {
    const {
        name,
        address,
        client,
        photo,
        clientContactPeople,
        status,
    } = useRecoilValue(projectAtom);

    const [isDrawerOpened, setIsDrawerOpened] = useState(false);

    const openDrawer = () => {
        setIsDrawerOpened(true);
    };

    const closeDrawer = () => {
        setIsDrawerOpened(false);
    };

    const hasMissingRequiredData =
        !name || !address || clientContactPeople.length === 0;

    const isEditingPossible = status !== projectStatuses.ARCHIVED;

    return (
        <>
            <Tile>
                <Info>
                    <StatusWrapper>
                        <Status status={status} />
                    </StatusWrapper>
                    <Details>
                        <ClientName>{client.name}</ClientName>
                        {name !== '' && <ProjectName>{name}</ProjectName>}
                        {address !== '' && (
                            <ProjectAddress>{address}</ProjectAddress>
                        )}
                        <ButtonsWrapper>
                            {photo && <ImagePreview src={photo} />}
                            {isEditingPossible && (
                                <StyledButton
                                    underline
                                    icon={<EditRounded />}
                                    onClick={openDrawer}
                                >
                                    Projektdaten bearbeiten
                                </StyledButton>
                            )}
                        </ButtonsWrapper>
                        {hasMissingRequiredData && isEditingPossible && (
                            <WarningMessage>
                                Fehlende erforderliche Daten
                            </WarningMessage>
                        )}
                    </Details>
                </Info>
                <Progress />
            </Tile>
            <ProjectDrawer
                isOpened={isDrawerOpened}
                onOpen={openDrawer}
                onClose={closeDrawer}
            >
                <ProjectData isInsideDrawer />
            </ProjectDrawer>
        </>
    );
};

export default ProjectDetails;
