import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import useActionSelectionModal
    from 'ProjectManager/Template/List/components/Overview/Group/DeleteModal/hooks/useActionSelectionModal';
import useNewTemplateGroupSelectionModal
    from 'ProjectManager/Template/List/components/Overview/Group/DeleteModal/hooks/useNewTemplateGroupSelectionModal';

const steps = {
    ACTION_SELECTION: 'ACTION_SELECTION',
    NEW_TEMPLATE_GROUP_SELECTION: 'NEW_TEMPLATE_GROUP_SELECTION',
};

const StandardDeleteModal = ({ group, isLoading, onConfirm, onCancel }) => {
    const [step, setStep] = useState(steps.ACTION_SELECTION);
    const [actionSelection, setActionSelection] = useState(null);
    const [
        templateGroupToMoveTheTemplatesTo,
        setTemplateGroupToMoveTheTemplatesTo,
    ] = useState(null);

    const {
        props: actionSelectionProps,
        content: actionSelectionContent,
    } = useActionSelectionModal({
        value: actionSelection,
        onChange: setActionSelection,
        onCancel,
        onConfirm,
        onNextStepButtonClick: () =>
            setStep(steps.NEW_TEMPLATE_GROUP_SELECTION),
        isLoading,
    });

    const {
        props: newTemplateGroupSelectionProps,
        content: newTemplateGroupSelectionContent,
    } = useNewTemplateGroupSelectionModal({
        group,
        value: templateGroupToMoveTheTemplatesTo,
        onChange: setTemplateGroupToMoveTheTemplatesTo,
        onBackButtonClick: () => setStep(steps.ACTION_SELECTION),
        onConfirm,
        isLoading,
    });

    let props = {};
    let content = null;

    // eslint-disable-next-line default-case
    switch (step) {
        case steps.ACTION_SELECTION:
            props = actionSelectionProps;
            content = actionSelectionContent;
            break;

        case steps.NEW_TEMPLATE_GROUP_SELECTION:
            props = newTemplateGroupSelectionProps;
            content = newTemplateGroupSelectionContent;
            break;
    }

    return (
        <Modal isOpen onRequestClose={onCancel} {...props}>
            <ModalTitle>Projektkategorie löschen</ModalTitle>
            {content}
        </Modal>
    );
};

StandardDeleteModal.propTypes = {
    group: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default StandardDeleteModal;
