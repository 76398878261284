import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import DatePicker from 'Common/components/Form/Fields/DatePicker';
import updateProjectTimeline from 'ProjectManager/Project/Common/api/dataManagement/updateProjectTimeline';
import { debounce, last } from 'lodash';
import { useRecoilState } from 'recoil';
import hasUnsavedChangesState from 'Common/recoil/hasUnsavedChangesState';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import colors from 'Common/constants/colors';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import ErrorMessage from 'Common/components/Form/ErrorMessage';
import parseDate from 'Common/utils/parseDate';
import createNewDate from 'Common/utils/createNewDate';
import formatDateAsISO from 'Common/utils/formatDateAsISO';
import max from 'date-fns/max';
import min from 'date-fns/min';
import isAfter from 'date-fns/isAfter';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import PropTypes from 'prop-types';
import { usePrevious } from 'react-use';
import isSameDay from 'date-fns/isSameDay';
import getProject from 'ProjectManager/Project/Common/api/getProject';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import addDays from 'date-fns/addDays';
import { useWindowWidth } from '@react-hook/window-size';
import withTimeline from 'ProjectManager/Project/Common/recoil/project/modifiers/withTimeline';

const Wrapper = styled.div`
    display: flex;
    border-radius: 6px;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.16);

    > div {
        flex: 1;

        .MuiTextField-root {
            .MuiFilledInput-root {
                box-shadow: none;
            }
        }

        &:first-child {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                width: 1px;
                height: 60%;
                background: ${colors.LIGHT_GRAY};
                transform: translateY(-50%);
            }

            .MuiTextField-root {
                .MuiFilledInput-root {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }

        &:last-child {
            .MuiTextField-root {
                .MuiFilledInput-root {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }
`;

const Timeline = ({ isInsideDrawer }) => {
    const [
        {
            id: projectId,
            startingDate: rawStartingDate,
            endingDate: rawEndingDate,
            status,
            introductions,
        },
        setProject,
    ] = useRecoilState(projectAtom);

    const startingDate = parseDate(rawStartingDate);
    const endingDate = rawEndingDate ? parseDate(rawEndingDate) : null;

    const [timeline, setTimeline] = useState({
        startingDate,
        endingDate,
    });

    const [, setHasUnsavedChanges] = useRecoilState(hasUnsavedChangesState);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const prevStartingDate = usePrevious(startingDate);
    const prevEndingDate = usePrevious(endingDate);

    // Reload the status when the starting/ending dates change
    useEffect(() => {
        const isFirstRender = prevStartingDate === undefined;

        const hasStartingDateChanged =
            startingDate &&
            prevStartingDate &&
            !isSameDay(startOfDay(startingDate), startOfDay(prevStartingDate));

        const hasEndingDateChanged =
            (prevEndingDate === null && endingDate !== null) ||
            (prevEndingDate !== null && endingDate === null) ||
            (prevEndingDate &&
                endingDate &&
                !isSameDay(startOfDay(endingDate), startOfDay(prevEndingDate)));

        const areChangesDetected =
            hasStartingDateChanged || hasEndingDateChanged;

        if (!isFirstRender && areChangesDetected) {
            (async () => {
                const response = await getProject(projectId, [], source.token);

                setProject(prevProject => ({
                    ...prevProject,
                    status: response.data.status,
                }));
            })();
        }
    }, [
        startingDate,
        prevStartingDate,
        endingDate,
        prevEndingDate,
        projectId,
        setProject,
        source.token,
    ]);

    const debouncedSave = useCallback(
        debounce(
            async values => {
                try {
                    const updatedValues = {
                        startingDate: formatDateAsISO(values.startingDate),
                        endingDate: values.endingDate
                            ? formatDateAsISO(values.endingDate)
                            : null,
                    };

                    await updateProjectTimeline(
                        projectId,
                        updatedValues,
                        source.token,
                    );

                    setProject(withTimeline(updatedValues));
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        notify(
                            'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                            {
                                type: toast.TYPE.ERROR,
                            },
                        );
                    }
                }

                setHasUnsavedChanges(false);
            },
            350,
            {
                leading: true,
                trailing: true,
            },
        ),
        [projectId, setProject, setHasUnsavedChanges, source.token],
    );

    const handleStartingDateChange = date => {
        // Do not allow empty dates and always fall back to the previous value
        if (date !== null) {
            setHasUnsavedChanges(true);

            setTimeline(prevTimeline => {
                const newTimeline = {
                    ...prevTimeline,
                    startingDate: date,
                };

                debouncedSave(newTimeline);

                return newTimeline;
            });
        }
    };

    const handleEndingDateChange = date => {
        setHasUnsavedChanges(true);

        setTimeline(prevTimeline => {
            const newTimeline = {
                ...prevTimeline,
                endingDate: date,
            };

            debouncedSave(newTimeline);

            return newTimeline;
        });
    };

    const isEndingDateValid =
        !Boolean(endingDate) ||
        isAfter(
            endOfDay(parseDate(rawEndingDate)),
            startOfDay(createNewDate()),
        ) ||
        // Skip validation for already ended projects
        status === projectStatuses.ENDED;

    const conductedIntroductions = introductions.filter(
        introduction => introduction.isConducted,
    );

    const firstConductedIntroduction = conductedIntroductions[0] ?? null;
    const lastConductedIntroduction = last(conductedIntroductions) ?? null;

    let maxStartingDate = timeline.endingDate;

    if (firstConductedIntroduction !== null) {
        if (timeline.endingDate) {
            maxStartingDate = min([
                startOfDay(parseDate(firstConductedIntroduction.conductedAt)),
                timeline.endingDate,
            ]);
        } else {
            maxStartingDate = startOfDay(parseDate(firstConductedIntroduction.conductedAt));
        }
    }

    let minEndingDate = max([
        timeline.startingDate,
        status === projectStatuses.ENDED
            ? startOfDay(createNewDate())
            : startOfDay(addDays(createNewDate(), 1)),
    ]);

    if (lastConductedIntroduction !== null) {
        minEndingDate = max([
            startOfDay(parseDate(lastConductedIntroduction.conductedAt)),
            minEndingDate,
        ]);
    }

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth <= 540;

    const fixedDatePickerProps = {};

    if (isInsideDrawer && !isMobile) {
        fixedDatePickerProps.portalId = 'root-portal';
    }

    return (
        <>
            <Wrapper>
                <DatePicker
                    id="startingDate"
                    name="startingDate"
                    label="Startdatum"
                    value={timeline.startingDate}
                    onChange={handleStartingDateChange}
                    isClearable={false}
                    startDate={timeline.startingDate}
                    endDate={timeline.endingDate}
                    maxDate={maxStartingDate}
                    selectsStart
                    {...fixedDatePickerProps}
                />
                <DatePicker
                    id="endingDate"
                    name="endingDate"
                    label="Enddatum"
                    value={timeline.endingDate}
                    onChange={handleEndingDateChange}
                    startDate={timeline.startingDate}
                    endDate={timeline.endingDate}
                    minDate={minEndingDate}
                    disabled={status === projectStatuses.ENDED}
                    selectsEnd
                    {...fixedDatePickerProps}
                />
            </Wrapper>
            {!isEndingDateValid && (
                <ErrorMessage message="Das Enddatum darf kein vergangenes Datum sein." />
            )}
        </>
    );
};

Timeline.defaultProps = {
    isInsideDrawer: false,
};

Timeline.propTypes = {
    isInsideDrawer: PropTypes.bool,
};

export default Timeline;
