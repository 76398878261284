import api from 'Application/api/api';

const getReverseGeocodeLocation = (latitude, longitude, cancelToken = null) =>
    api.get('/geocode/reverse', {
        params: {
            latitude,
            longitude,
        },
        cancelToken,
    });

export default getReverseGeocodeLocation;
