import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import SelectedLibrariesForImportList from 'Education/components/library/SelectedLibrariesForImportList';
import SelectedLibraryForImportSlidesModal from 'Education/components/library/SelectedLibraryForImportSlidesModal';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const formFieldPrefix = 'libraryInstructions';

const preventDefault = e => {
    e.preventDefault();
};

const SelectedLibrariesForImport = ({
    libraryInstructions,
    libraryInstructionValues,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
}) => {
    const [
        openedSlidesModalLibraryInstructionId,
        setOpenedSlidesModalLibraryInstructionId,
    ] = useState(null);

    const handleSlidesLoaded = useCallback(
        slides => {
            const newLibraryInstructions = [...libraryInstructionValues];

            const index = newLibraryInstructions.findIndex(
                libraryInstruction =>
                    libraryInstruction.id ===
                    openedSlidesModalLibraryInstructionId,
            );

            if (index !== -1) {
                const libraryInstruction = newLibraryInstructions[index];

                if (libraryInstruction.slideIds === null) {
                    newLibraryInstructions[index] = {
                        ...libraryInstruction,
                        slideIds: slides.map(slide => slide.id),
                    };

                    setFieldValue(
                        'libraryInstructions',
                        newLibraryInstructions,
                        false,
                    );
                }
            }
        },
        [
            openedSlidesModalLibraryInstructionId,
            setFieldValue,
            libraryInstructionValues,
        ],
    );

    // Update the selected slides when the checkboxes are changed.
    const handleSlideChanged = useCallback(
        (slideId, isChecked) => {
            const newLibraryInstructions = [...libraryInstructionValues];

            const libraryInstructionIndex = newLibraryInstructions.findIndex(
                libraryInstruction =>
                    libraryInstruction.id ===
                    openedSlidesModalLibraryInstructionId,
            );

            if (libraryInstructionIndex !== -1) {
                const libraryInstruction =
                    newLibraryInstructions[libraryInstructionIndex];

                const newSlideIds = [...libraryInstruction.slideIds];

                const slideIdIndex = newSlideIds.indexOf(slideId);

                if (isChecked && slideIdIndex === -1) {
                    newSlideIds.push(slideId);
                } else if (!isChecked && slideIdIndex !== -1) {
                    newSlideIds.splice(slideIdIndex, 1);
                }

                newLibraryInstructions[libraryInstructionIndex] = {
                    ...libraryInstruction,
                    slideIds: newSlideIds,
                };

                setFieldValue(
                    'libraryInstructions',
                    newLibraryInstructions,
                    false,
                );
            }
        },
        [
            openedSlidesModalLibraryInstructionId,
            setFieldValue,
            libraryInstructionValues,
        ],
    );

    const handleOpenSlidesButtonClick = libraryInstructionId => {
        setOpenedSlidesModalLibraryInstructionId(libraryInstructionId);
    };

    const handleSlidesModalClose = () => {
        setOpenedSlidesModalLibraryInstructionId(null);
    };

    let openedLibraryInstructionSelectedSlideIds = [];

    if (openedSlidesModalLibraryInstructionId) {
        openedLibraryInstructionSelectedSlideIds =
            libraryInstructionValues.find(
                libraryInstructionValue =>
                    libraryInstructionValue.id ===
                    openedSlidesModalLibraryInstructionId,
            )?.slideIds ?? [];
    }

    return (
        <Wrapper>
            <form onSubmit={preventDefault}>
                <SelectedLibrariesForImportList
                    libraryInstructions={libraryInstructions}
                    libraryInstructionValues={libraryInstructionValues}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    formFieldPrefix={formFieldPrefix}
                    onOpenSlidesButtonClick={handleOpenSlidesButtonClick}
                />
            </form>
            <SelectedLibraryForImportSlidesModal
                libraryInstructionId={openedSlidesModalLibraryInstructionId}
                selectedSlideIds={openedLibraryInstructionSelectedSlideIds}
                onSlidesLoaded={handleSlidesLoaded}
                onSlideChange={handleSlideChanged}
                onClose={handleSlidesModalClose}
            />
        </Wrapper>
    );
};

SelectedLibrariesForImport.propTypes = {
    libraryInstructions: PropTypes.array.isRequired,
    libraryInstructionValues: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
};

export default SelectedLibrariesForImport;
