import React, { useState } from 'react';
import Button from 'Common/components/Button';
import UnansweredQuestionsGuideModal
    from 'ProjectManager/DataManagement/Questionnaire/components/Questionnaire/components/UnansweredQuestionsGuideModal';
import { flatten, last } from 'lodash';
import isQuestionAnswered from 'ProjectManager/DataManagement/Questionnaire/helpers/isQuestionAnswered';
import useUnansweredQuestionsGuidance
    from 'ProjectManager/DataManagement/Questionnaire/components/Questionnaire/hooks/useUnansweredQuestionsGuidance';
import { ArrowForwardRounded } from '@material-ui/icons';
import useAreAllStepsCompleted
    from 'ProjectManager/DataManagement/Common/hooks/useAreAllStepsCompleted/useAreAllStepsCompleted';
import useQuestionnaireSections
    from 'ProjectManager/DataManagement/Questionnaire/hooks/questionnaireSections/useQuestionnaireSections';
import getQuestionnaireSectionIdentifier
    from 'ProjectManager/DataManagement/Questionnaire/helpers/getQuestionnaireSectionIdentifier';
import useHasOtherPrimaryButton
    from 'ProjectManager/DataManagement/Common/hooks/useHasOtherPrimaryButton/useHasOtherPrimaryButton';
import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom
    from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';

const NextButton = ({ section, onClick }) => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);
    const isProject = dataManagementEntity === dataManagementEntities.PROJECT;
    const isTemplate = dataManagementEntity === dataManagementEntities.TEMPLATE;

    const [
        isUnansweredQuestionsGuideModalOpened,
        setIsUnansweredQuestionsGuideModalOpened,
    ] = useState(false);

    const closeModal = () => {
        setIsUnansweredQuestionsGuideModalOpened(false);
    };

    const allQuestions = flatten(
        section.subsections.map(subsection => subsection.questions),
    );

    const unansweredQuestions = allQuestions.filter(
        question => !isQuestionAnswered(question),
    );

    const { startGuidance, renderGuidance } = useUnansweredQuestionsGuidance({
        onComplete: () =>
            onClick({
                completeCreationIfPossible: true,
            }),
        allQuestions,
    });

    const handleUnansweredQuestionsModalConfirm = async () => {
        startGuidance(unansweredQuestions);
        closeModal();
    };

    const handleUnansweredQuestionsModalCancel = () => {
        closeModal();

        // Go to the next section after the user refuses to use the guidance,
        // but only when we are in a template.
        if (isTemplate) {
            onClick();
        }
    };

    const handleClick = () => {
        if (unansweredQuestions.length > 0) {
            setIsUnansweredQuestionsGuideModalOpened(true);
        } else {
            onClick();
        }
    };

    const hasOtherPrimaryButton = useHasOtherPrimaryButton();
    const areAllStepsCompleted = useAreAllStepsCompleted();
    const questionnaireSections = useQuestionnaireSections();

    const isLastSection =
        getQuestionnaireSectionIdentifier(last(questionnaireSections)) ===
        getQuestionnaireSectionIdentifier(section);

    const isHidden = isProject && isLastSection && areAllStepsCompleted;

    return (
        <>
            {!isHidden && (
                <Button
                    text={hasOtherPrimaryButton}
                    icon={
                        hasOtherPrimaryButton ? (
                            <ArrowForwardRounded />
                        ) : undefined
                    }
                    disabled={isLastSection && unansweredQuestions.length === 0}
                    iconPosition="right"
                    type="button"
                    onClick={handleClick}
                >
                    Weiter
                </Button>
            )}

            {isUnansweredQuestionsGuideModalOpened && (
                <UnansweredQuestionsGuideModal
                    unansweredQuestions={unansweredQuestions}
                    onCancel={handleUnansweredQuestionsModalCancel}
                    onConfirm={handleUnansweredQuestionsModalConfirm}
                />
            )}

            {renderGuidance(isLastSection)}
        </>
    );
};

export default NextButton;
