import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { DriverLicenseControlGridItemWrapper } from 'Measure/components/vehicle/driver-license/DriverLicenseControlGridItemWrapper';
import {
    AssignmentIndRounded,
    CancelRounded,
    CheckCircleRounded,
    CheckRounded,
    CreditCardRounded,
    DescriptionRounded,
    NotificationsNoneRounded,
    RotateRightRounded,
    UpdateRounded,
    WarningRounded,
} from '@material-ui/icons';
import activateDriverLicenseControl from 'Measure/api/driver-license/activateDriverLicenseControl';
import deactivateDriverLicenseControl from 'Measure/api/driver-license/deactivateDriverLicenseControl';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import sendManualReminder from 'Measure/api/driver-license/sendManualReminder';
import colors from 'Common/constants/colors';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import WarningMessage from 'Common/components/Modals/WarningMessage';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import { Tooltip } from '@material-ui/core';
import MoreActionsButton from 'Common/components/MoreActionsButton/MoreActionsButton';
import MenuItem from 'Common/components/MoreActionsButton/MenuItem';
import parseDate from 'Common/utils/parseDate';
import formatDate from 'Common/utils/formatDate';
import createNewDate from 'Common/utils/createNewDate';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

const GridItemWrapper = styled(DriverLicenseControlGridItemWrapper)`
    padding: 0;
`;

const GridItemLink = styled(RouterLink)`
    display: block;
    width: 100%;
    text-decoration: none;
    outline: 0 none;
    padding: 10px 15px;
    color: ${colors.WHITE};
`;

const GridItemDiv = styled(GridItemLink).attrs({
    as: 'div',
})``;

const StatusBar = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const Information = styled.div`
    font-size: 14px;
`;

const EmployeeName = styled.div`
    display: flex;
    align-items: center;

    svg {
        margin-left: 8px;
    }
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    height: 38px;
    padding-right: 38px;
`;

const InfoItem = styled.div`
    display: flex;
    margin-bottom: 5px;
`;

const Label = styled.div`
    margin-right: 10px;
    color: ${colors.GRAY};
    min-width: 125px;
`;

const Value = styled.div`
    color: ${colors.DIRTY_WHITE};
`;

const CreationProcessIcon = styled(RotateRightRounded)`
    color: ${colors.WARNING};
`;

const PendingNotInitiatedIcon = styled(UpdateRounded)`
    color: ${colors.LIGHTER_GRAY};
`;

const PendingInProgressIcon = styled(UpdateRounded)`
    color: ${colors.WARNING};
`;

const OverdueIcon = styled(WarningRounded)`
    color: ${colors.WARNING};
`;

const CompletedIcon = styled(CheckRounded)`
    color: ${colors.GREEN};
`;

const LostIcon = styled(CreditCardRounded)`
    color: ${colors.ERROR};
`;

const ExpiredIcon = styled(CreditCardRounded)`
    color: ${colors.ERROR};
`;

const Status = styled.div`
    span {
        display: block;
    }

    svg {
        display: block;
    }
`;

const MenuIcon = styled.div`
    position: absolute;
    top: 5px;
    right: 0;
`;

const DriverLicenseControlGridItem = ({
    driverLicenseControl,
    onActivate,
    onDeactivate,
}) => {
    const dispatch = useDispatch();

    const [isActivating, setIsActivating] = useState(false);
    const [isDeactivating, setIsDeactivating] = useState(false);
    const [isSendingManualReminder, setIsSendingManualReminder] = useState(
        false,
    );

    const [isReCreateModalOpened, setIsReCreateModalOpened] = useState(false);

    const openReCreateModal = () => {
        setIsReCreateModalOpened(true);
    };

    const closeReCreateModal = () => {
        setIsReCreateModalOpened(false);
    };

    const handleConfirmReCreateButtonClick = async () => {
        dispatch(
            push(
                `/measures/vehicles/driver-license/create/${driverLicenseControl.id}?recreate=1`,
            ),
        );
    };

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleActivate = async () => {
        setIsActivating(true);

        try {
            await activateDriverLicenseControl(
                driverLicenseControl.id,
                source.token,
            );
            await onActivate();

            setIsActivating(false);
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsActivating(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const handleDeactivate = async () => {
        setIsDeactivating(true);

        try {
            await deactivateDriverLicenseControl(
                driverLicenseControl.id,
                source.token,
            );
            await onDeactivate();

            setIsDeactivating(false);
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsDeactivating(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const handleSendManualReminder = async () => {
        setIsSendingManualReminder(true);

        try {
            await sendManualReminder(driverLicenseControl.id, source.token);

            setIsSendingManualReminder(false);

            notify('Die Erinnerung wird in Kürze gesendet.', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsSendingManualReminder(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    let nextCheckDate = '-';
    let controlMethodName = '-';

    let isInCreationProcess = true;
    let isPending = false;
    let isOverdue = false;
    let isCompleted = false;
    let isPendingInitiation = false;
    let isLost = false;
    let isExpired = false;
    let isInVerificationPeriod = false;
    let canSendManualReminder = false;

    const driverLicense = driverLicenseControl.driverLicense;

    const nextCheck = driverLicense?.nextCheck;
    const previousCheck = driverLicense?.previousCheck;

    if (driverLicense) {
        isInCreationProcess = driverLicense.isInCreationProcess;

        controlMethodName = driverLicense.isUsingDirectControlMethod
            ? 'Direct'
            : 'Distance';

        if (!isInCreationProcess) {
            if (nextCheck.dueDate) {
                nextCheckDate = formatDate(parseDate(nextCheck.dueDate));
            }

            isPending =
                nextCheck.isInitiated &&
                nextCheck.isInVerificationPeriod &&
                !nextCheck.isOverdue;

            isPendingInitiation =
                !nextCheck.isInitiated &&
                nextCheck.isInVerificationPeriod &&
                !nextCheck.isOverdue;

            isOverdue = nextCheck.isInVerificationPeriod && nextCheck.isOverdue;

            isCompleted =
                !nextCheck.isInVerificationPeriod &&
                previousCheck &&
                previousCheck.isCompleted;

            isLost = driverLicense.isLost;
            isExpired = driverLicense.isExpired;
            isInVerificationPeriod = nextCheck.isInVerificationPeriod;

            if (nextCheck.dueDate) {
                const minimumNumberOfDaysAfterDueDateToAllowManualReminder = 15;

                const daysAfterDueDate = differenceInCalendarDays(
                    createNewDate(),
                    parseDate(nextCheck.dueDate),
                );

                canSendManualReminder =
                    driverLicenseControl.isActive &&
                    isInVerificationPeriod &&
                    isOverdue &&
                    daysAfterDueDate >=
                        minimumNumberOfDaysAfterDueDateToAllowManualReminder;
            }
        }
    }

    const isClickable = driverLicenseControl.isActive;
    const GridItem = isClickable ? GridItemLink : GridItemDiv;

    let link;

    if (isClickable) {
        link = isInCreationProcess
            ? `/measures/vehicles/driver-license/create/${driverLicenseControl.id}`
            : `/measures/vehicles/driver-license/overview/${driverLicenseControl.id}`;
    }

    const isEmployeeEnabled = driverLicenseControl.employee.isEnabled;

    return (
        <GridItemWrapper>
            <GridItem to={link}>
                <StatusBar>
                    <EmployeeName>
                        {`${driverLicenseControl.employee.lastName}, ${driverLicenseControl.employee.firstName}`}
                        {isLost && (
                            <Tooltip title="Führerschein als verloren gemeldet">
                                <LostIcon />
                            </Tooltip>
                        )}
                        {isExpired && (
                            <Tooltip title="Der Führerschein ist abgelaufen">
                                <ExpiredIcon />
                            </Tooltip>
                        )}
                    </EmployeeName>
                    <Actions>
                        <Status>
                            {isPendingInitiation && (
                                <Tooltip title="Ausstehend (nicht initiiert)">
                                    <PendingNotInitiatedIcon />
                                </Tooltip>
                            )}
                            {isPending && (
                                <Tooltip title="Ausstehend (in Bearbeitung)">
                                    <PendingInProgressIcon />
                                </Tooltip>
                            )}
                            {isOverdue && (
                                <Tooltip title="Überfällig">
                                    <OverdueIcon />
                                </Tooltip>
                            )}
                            {isCompleted && (
                                <Tooltip title="Abgeschlossen">
                                    <CompletedIcon />
                                </Tooltip>
                            )}
                            {isInCreationProcess && (
                                <Tooltip title="Noch nicht vollständig erstellt">
                                    <CreationProcessIcon />
                                </Tooltip>
                            )}
                        </Status>
                    </Actions>
                </StatusBar>
                <Information>
                    <InfoItem>
                        <Label>Prüfmethode:</Label>
                        <Value>{controlMethodName}</Value>
                    </InfoItem>
                    <InfoItem>
                        <Label>Nächste Kontrolle:</Label>
                        <Value>{nextCheckDate}</Value>
                    </InfoItem>
                </Information>
            </GridItem>
            <MenuIcon>
                <MoreActionsButton>
                    {!isInCreationProcess && (
                        <MenuItem
                            icon={<DescriptionRounded />}
                            href={`/api/measures/vehicles/driver-licenses/controls/${driverLicenseControl.id}/download-proof-document`}
                            target="_blank"
                        >
                            Dokument anzeigen
                        </MenuItem>
                    )}
                    {driverLicenseControl.isActive && isInCreationProcess && (
                        <MenuItem
                            icon={<RotateRightRounded />}
                            to={`/measures/vehicles/driver-license/create/${driverLicenseControl.id}`}
                        >
                            Erstellung fortsetzen
                        </MenuItem>
                    )}
                    {driverLicenseControl.isActive && !isInCreationProcess && (
                        <MenuItem
                            icon={<AssignmentIndRounded />}
                            to={`/measures/vehicles/driver-license/update/${driverLicenseControl.id}`}
                        >
                            Fahrerdaten
                        </MenuItem>
                    )}
                    {canSendManualReminder && (
                        <MenuItem
                            type="button"
                            icon={<NotificationsNoneRounded />}
                            isLoading={isSendingManualReminder}
                            disabled={isSendingManualReminder}
                            onClick={handleSendManualReminder}
                        >
                            Erinnerung versenden
                        </MenuItem>
                    )}
                    {driverLicenseControl.isActive && (
                        <MenuItem
                            type="button"
                            icon={<CancelRounded />}
                            isLoading={isDeactivating}
                            disabled={isDeactivating}
                            onClick={handleDeactivate}
                        >
                            Kontrolle deaktivieren
                        </MenuItem>
                    )}
                    {!driverLicenseControl.isActive && (
                        <MenuItem
                            type="button"
                            icon={<CheckCircleRounded />}
                            isLoading={isActivating}
                            disabled={!isEmployeeEnabled || isActivating}
                            onClick={handleActivate}
                            tooltip={
                                !isEmployeeEnabled
                                    ? 'Der Mitarbeiter ist inaktiv'
                                    : ''
                            }
                        >
                            Kontrolle aktivieren
                        </MenuItem>
                    )}
                    {!isInCreationProcess && driverLicenseControl.isActive && (
                        <MenuItem
                            type="button"
                            icon={<RotateRightRounded />}
                            disabled={!isEmployeeEnabled}
                            onClick={openReCreateModal}
                            tooltip={
                                !isEmployeeEnabled
                                    ? 'Der Mitarbeiter ist inaktiv'
                                    : ''
                            }
                        >
                            Erstelle neu
                        </MenuItem>
                    )}
                </MoreActionsButton>
            </MenuIcon>
            {isReCreateModalOpened && (
                <Modal
                    isOpen={isReCreateModalOpened}
                    onRequestClose={closeReCreateModal}
                    buttons={
                        <>
                            <Button
                                text
                                dark
                                type="button"
                                onClick={closeReCreateModal}
                            >
                                Abbrechen
                            </Button>
                            <Button
                                type="button"
                                onClick={handleConfirmReCreateButtonClick}
                            >
                                Neu erstellen
                            </Button>
                        </>
                    }
                >
                    <ModalTitle>
                        Möchten Sie wirklich einen neuen Führerschein erstellen?
                    </ModalTitle>
                    <WarningMessage>
                        <WarningRounded />
                        Die aktuelle Kontrolle wird automatisch archiviert!
                    </WarningMessage>
                </Modal>
            )}
        </GridItemWrapper>
    );
};

DriverLicenseControlGridItem.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
    onActivate: PropTypes.func.isRequired,
    onDeactivate: PropTypes.func.isRequired,
};

export default DriverLicenseControlGridItem;
