import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import WarningMessage from 'Common/components/Modals/WarningMessage';
import { WarningRounded } from '@material-ui/icons';

const QuestionDeleteModal = ({ questionId, onConfirm, onCancel, stats }) => (
    <Modal
        isOpen={questionId !== null}
        onRequestClose={onCancel}
        buttons={
            <>
                <Button text dark type="button" onClick={onCancel}>
                    Abbrechen
                </Button>
                <Button type="button" onClick={onConfirm}>
                    Löschen
                </Button>
            </>
        }
    >
        <ModalTitle>Möchten Sie diese Frage wirklich löschen?</ModalTitle>
        <p>
            Die Änderungen werden nicht automatisch gespeichert, Sie müssen den
            gesamten Test speichern.
        </p>
        {!!stats && stats.examsInProgressCount > 0 && (
            <WarningMessage>
                <WarningRounded />
                {`Hinweis: Es gibt ${stats.examsInProgressCount} Mitarbeiter, die eine Testprüfung machen`}
            </WarningMessage>
        )}
    </Modal>
);

QuestionDeleteModal.defaultProps = {
    questionId: null,
    stats: null,
};

QuestionDeleteModal.propTypes = {
    questionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    stats: PropTypes.object,
};

export default QuestionDeleteModal;
