import React, { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import EditPointsDrawer from 'ProjectManager/Project/Introductions/Grid/components/Introduction/EditPointsDrawer/EditPointsDrawer';

const useEditPointsButton = introduction => {
    const { status: projectStatus } = useRecoilValue(projectAtom);

    const isVisible = useMemo(
        () =>
            ![projectStatuses.ENDED, projectStatuses.ARCHIVED].includes(
                projectStatus,
            ) &&
            !introduction.isConducted &&
            !introduction.isCanceled,
        [introduction.isCanceled, introduction.isConducted, projectStatus],
    );

    const [isDrawerOpened, setIsDrawerOpened] = useState(false);

    const handleClick = () => {
        setIsDrawerOpened(true);
    };

    const openDrawer = useCallback(() => {
        setIsDrawerOpened(true);
    }, []);

    const closeDrawer = useCallback(() => {
        setIsDrawerOpened(false);
    }, []);

    const renderDrawer = useCallback(
        () => (
            <EditPointsDrawer
                isOpened={isDrawerOpened}
                introduction={introduction}
                onOpen={openDrawer}
                onClose={closeDrawer}
            />
        ),
        [closeDrawer, introduction, isDrawerOpened, openDrawer],
    );

    return {
        isVisible,
        handleClick,
        renderDrawer,
    };
};

export default useEditPointsButton;
