import React from 'react';
import styled from 'styled-components/macro';
import VehicleMeasures from 'Measure/components/vehicle/VehicleMeasures';
import VehicleMeasuresProgress from 'Measure/components/vehicle/VehicleMeasuresProgress';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import { Helmet } from 'react-helmet-async';
import BackButton from 'Application/components/Header/BackButton/BackButton';

const Layout = styled.div`
    @media screen and (min-width: 860px) {
        display: flex;
    }
`;

const Sidebar = styled.div`
    margin-bottom: 10px;

    > * {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 860px) {
        width: 300px;
        margin-right: 10px;
        margin-bottom: 0;
    }
`;

const Content = styled.div`
    @media screen and (min-width: 860px) {
        flex: 1;
    }

    @media screen and (min-width: 1180px) {
        display: flex;
        flex-wrap: wrap;
    }
`;

const VehiclesDashboard = () => (
    <Layout>
        <Helmet>
            <title>Fuhrpark - Maßnahmen</title>
        </Helmet>
        <BackButton to="/measures" />
        <Breadcrumb to="/measures">Maßnahmen</Breadcrumb>
        <Breadcrumb isActive>Fuhrpark</Breadcrumb>
        <Sidebar>
            <VehicleMeasuresProgress />
        </Sidebar>
        <Content>
            <VehicleMeasures />
        </Content>
    </Layout>
);

export default VehiclesDashboard;
