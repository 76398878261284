import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import withWorkingDays from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/withWorkingDays';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import updateTemplateWorkingDays from 'ProjectManager/Template/Common/api/dataManagement/updateTemplateWorkingDays';

const useTemplateWorkingDaysData = () => {
    const [{ id: templateId, workingDays }, setTemplate] = useRecoilState(
        templateAtom,
    );

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const update = useCallback(
        async newWorkingDays => {
            await updateTemplateWorkingDays(
                templateId,
                newWorkingDays,
                source.token,
            );

            setTemplate(withWorkingDays(newWorkingDays));
        },
        [templateId, setTemplate, source.token],
    );

    return templateId
        ? {
              initialWorkingDays: workingDays,
              updateWorkingDays: update,
              isRequired: false,
          }
        : {};
};

export default useTemplateWorkingDaysData;
