import React from 'react';
import PropTypes from 'prop-types';
import getQuestionnaireSectionIdentifier from 'ProjectManager/DataManagement/Questionnaire/helpers/getQuestionnaireSectionIdentifier';
import getQuestionnaireSectionIconComponent from 'ProjectManager/DataManagement/Questionnaire/helpers/getQuestionnaireSectionIconComponent';
import NavigationItem from 'ProjectManager/DataManagement/Navigation/components/NavigationItem';
import NavigationSubItem from 'ProjectManager/DataManagement/Navigation/components/NavigationSubItem';
import getQuestionnaireSubsectionIdentifier from 'ProjectManager/DataManagement/Questionnaire/helpers/getQuestionnaireSubsectionIdentifier';
import useQuestionnaireSections from 'ProjectManager/DataManagement/Questionnaire/hooks/questionnaireSections/useQuestionnaireSections';
import useWindowScroll from '@react-hook/window-scroll';
import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';

const approximateStickyElementsHeight = 170;

const QuestionnaireNavigationItems = ({
    activeItem,
    onActiveItemChange,
    onSubsectionClicked,
}) => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);
    const isProject = dataManagementEntity === dataManagementEntities.PROJECT;

    const questionnaireSections = useQuestionnaireSections();

    const scrollY = useWindowScroll();

    const handleSubsectionClick = (section, subsection) => {
        const subsectionId = getQuestionnaireSubsectionIdentifier(subsection);
        const domElementId = `questionnaireSubsection-${subsectionId}`;

        const distanceToDomElement = document
            .getElementById(domElementId)
            .getBoundingClientRect().top;

        window.scroll({
            top:
                scrollY +
                distanceToDomElement -
                approximateStickyElementsHeight,
            left: 0,
            behavior: 'smooth',
        });

        if (onSubsectionClicked) {
            onSubsectionClicked();
        }
    };

    return questionnaireSections.map((section, index) => {
        let identifier = getQuestionnaireSectionIdentifier(section);
        let Icon = getQuestionnaireSectionIconComponent(section);

        const areSomeNextSectionsCompleted = questionnaireSections
            .slice(index + 1)
            .some(nextSection => nextSection.isCompleted);

        return (
            <NavigationItem
                key={identifier}
                icon={<Icon />}
                name={section.name}
                active={activeItem === identifier}
                completed={section.isCompleted}
                notFullyCompleted={
                    isProject &&
                    !section.isCompleted &&
                    areSomeNextSectionsCompleted
                }
                onClick={() => onActiveItemChange(identifier)}
            >
                {section.subsections.map(subsection => (
                    <NavigationSubItem
                        key={getQuestionnaireSubsectionIdentifier(subsection)}
                        name={subsection.name}
                        completed={subsection.isCompleted}
                        onClick={() =>
                            handleSubsectionClick(section, subsection)
                        }
                    />
                ))}
            </NavigationItem>
        );
    });
};

QuestionnaireNavigationItems.defaultProps = {
    onSubsectionClicked: undefined,
};

QuestionnaireNavigationItems.propTypes = {
    activeItem: PropTypes.string.isRequired,
    onActiveItemChange: PropTypes.func.isRequired,
    onSubsectionClicked: PropTypes.func,
};

export default QuestionnaireNavigationItems;
