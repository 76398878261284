import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import useUpdateCustomSubsectionFunction from 'ProjectManager/DataManagement/Questionnaire/components/Subsection/hooks/updateCustomSubsectionFunction/useUpdateCustomSubsectionFunction';
import useUniqueNameValidationCallback from 'ProjectManager/DataManagement/Questionnaire/components/SubsectionCreation/hooks/uniqueNameValidationCallback/useUniqueNameValidationCallback';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import axios from 'axios';
import InnerBoxWithButtons from 'Common/components/Boxes/InnerBoxWithButtons';
import Button from 'Common/components/Button';
import InnerBoxTitle from 'Common/components/Boxes/InnerBoxTitle';
import FormField from 'Common/components/Form/FormField';
import Input from 'Common/components/Form/Fields/Input';

const UpdateCustomSubsection = ({ subsection, section, onCancel, onSave }) => {
    const [isSaving, setIsSaving] = useState(false);

    const initialValues = useMemo(() => pick(subsection, ['name']), [
        subsection,
    ]);

    const updateCustomSubsection = useUpdateCustomSubsectionFunction();

    const uniqueNameValidationCallback = useUniqueNameValidationCallback(
        section.id,
        section.isCustom,
    );

    const customizedUniqueNameValidationCallback = useCallback(
        value => uniqueNameValidationCallback(value, subsection.id),
        [subsection.id, uniqueNameValidationCallback],
    );

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                name: Yup.string()
                    .debouncedCallback(
                        'Diese Bezeichnung wird bereits verwendet',
                        customizedUniqueNameValidationCallback,
                    )
                    .required('Pflichtangabe'),
            }),
        [customizedUniqueNameValidationCallback],
    );

    const {
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        isSubmitting,
        isValidating,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async values => {
            setIsSaving(true);

            try {
                await updateCustomSubsection(subsection.id, values);
                await onSave();

                notify('Anpassung war erfolgreich', {
                    type: toast.TYPE.SUCCESS,
                });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }

            setIsSaving(false);
        },
    });

    const isSubmittingAndValidating = isSubmitting && isValidating;
    const isLoading = isSaving || isSubmittingAndValidating;

    return (
        <InnerBoxWithButtons
            buttons={
                <>
                    <Button
                        text
                        type="button"
                        disabled={isLoading}
                        onClick={onCancel}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        disabled={isLoading || !isValid}
                        isLoading={isLoading}
                        onClick={handleSubmit}
                    >
                        Speichern
                    </Button>
                </>
            }
        >
            <InnerBoxTitle>Untersektion bearbeiten</InnerBoxTitle>
            <form onSubmit={handleSubmit}>
                <FormField>
                    <Input
                        id="name"
                        name="name"
                        label="Bezeichnung"
                        value={values.name}
                        error={errors.name}
                        touched={touched.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isRequired
                    />
                </FormField>
            </form>
        </InnerBoxWithButtons>
    );
};

UpdateCustomSubsection.propTypes = {
    subsection: PropTypes.object.isRequired,
    section: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default UpdateCustomSubsection;
