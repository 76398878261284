import React from 'react';
import styled from 'styled-components/macro';
import Button from 'Common/components/Button';
import colors from 'Common/constants/colors';

const StyledButton = styled(Button)`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 10px 30px 10px 20px;
    background: ${colors.DIRTY_WHITE};
    transition: background 150ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
        background: ${colors.LIGHT_DIVIDER};
    }
`;

const MenuItem = ({ innerRef, ...props }) => <StyledButton text dark innerRef={innerRef} {...props} />;

export default MenuItem;
