import api from 'Application/api/api';

const signInstructionAssignmentForEmployeeWithSignature = (
    assignmentId,
    employeeId,
    signature,
    cancelToken,
) => {
    const formData = new FormData();

    formData.append('signature', signature);

    return api.post(
        `/education/instruction-assignments/${assignmentId}/sign-for-employee/${employeeId}/with-signature`,
        formData,
        {
            cancelToken,
        },
    );
};

export default signInstructionAssignmentForEmployeeWithSignature;
