import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { prop } from 'styled-tools';
import Modal from 'Common/components/Modals/Modal';
import Checkbox from 'Common/components/Form/Fields/Checkbox';
import getLibraryInstruction from 'Education/api/library/getLibraryInstruction';
import ReactPlayer from 'react-player';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import InnerBox from 'Common/components/Boxes/InnerBox';
import { VisibilityRounded } from '@material-ui/icons';

const Wrapper = styled.div`
    @media screen and (min-width: 768px) {
        display: flex;
    }
`;

const CountInfo = styled.div`
    margin-bottom: 10px;
`;

const Sidebar = styled.div`
    @media screen and (min-width: 768px) {
        flex: 0 0 300px;
    }
`;

const SlidesList = styled(InnerBox)`
    padding: 5px 15px;

    @media screen and (min-width: 768px) {
        margin-right: 10px;
    }
`;

const LibraryInstructionSlideItem = styled.div`
    display: flex;
    justify-content: space-between;
    text-align: left;
    overflow-wrap: anywhere;
    word-break: break-word;
`;

const Content = styled.div`
    display: none;

    @media screen and (min-width: 768px) {
        position: sticky;
        display: block;
        top: 50px;
        flex: 1;
        max-height: 460px;
    }
`;

const PreviewWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const IconWrapper = styled.div`
    text-align: center;

    svg {
        font-size: 64px !important;
    }
`;

const IconText = styled.div`
    font-size: 24px;
    line-height: 32px;
`;

const ImagePreview = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('${prop('src')}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
`;

const VideoPlayerWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 0;
    padding-top: 100%;
    transform: translate(-50%, -50%);
`;

const VideoPlayer = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
`;

const SelectedLibraryForImportSlidesModal = ({
    libraryInstructionId,
    selectedSlideIds,
    onSlidesLoaded,
    onSlideChange,
    onClose,
}) => {
    const [slideIdOnPreview, setSlideIdOnPreview] = useState(null);

    const libraryInstructionRequest = useCallback(
        cancelToken => {
            if (!libraryInstructionId) {
                return null;
            }

            return getLibraryInstruction(
                libraryInstructionId,
                ['slides'],
                cancelToken,
            );
        },
        [libraryInstructionId],
    );

    const {
        data: libraryInstruction,
        loadData: loadLibraryInstruction,
        isLoading,
        hasError,
    } = useAxiosRequest(libraryInstructionRequest, null);

    useEffect(() => {
        if (libraryInstruction) {
            onSlidesLoaded(libraryInstruction.slides);
        }
    }, [libraryInstruction, onSlidesLoaded]);

    const handleSlideCheckboxChange = (slideId, isChecked) => {
        onSlideChange(slideId, isChecked);
    };

    const handleSlideItemMouseOver = slideId => {
        setSlideIdOnPreview(slideId);
    };

    const handleSlideItemMouseOut = () => {
        setSlideIdOnPreview(null);
    };

    const slideOnPreview = libraryInstruction?.slides.find(
        slide => slide.id === slideIdOnPreview,
    );

    return (
        <Modal
            isLoading={isLoading}
            isOpen={libraryInstructionId !== null}
            onRequestClose={onClose}
            maxWidth={880}
            maxHeight={600}
            buttons={
                <Button onClick={onClose}>Bestätigen und schließen</Button>
            }
        >
            {!isLoading &&
                (hasError ? (
                    <RetryLoading onRetry={loadLibraryInstruction} />
                ) : libraryInstruction === null ? (
                    <ModalTitle>
                        Diese Unterweisung ist nicht mehr verfügbar
                    </ModalTitle>
                ) : (
                    <Wrapper>
                        <Sidebar>
                            <CountInfo>{`Folienliste (${selectedSlideIds.length} / ${libraryInstruction.slides.length})`}</CountInfo>
                            <SlidesList light>
                                {libraryInstruction.slides.map(slide => (
                                    <LibraryInstructionSlideItem
                                        key={slide.id}
                                        onMouseOver={() =>
                                            handleSlideItemMouseOver(slide.id)
                                        }
                                        onMouseOut={handleSlideItemMouseOut}
                                    >
                                        <Checkbox
                                            dark
                                            id={`slide_${slide.id}`}
                                            label={slide.title}
                                            checked={selectedSlideIds.includes(
                                                slide.id,
                                            )}
                                            onChange={e =>
                                                handleSlideCheckboxChange(
                                                    slide.id,
                                                    e.target.checked,
                                                )
                                            }
                                        />
                                    </LibraryInstructionSlideItem>
                                ))}
                            </SlidesList>
                        </Sidebar>
                        <Content>
                            <PreviewWrapper>
                                {!slideOnPreview ? (
                                    <IconWrapper>
                                        <VisibilityRounded />
                                        <IconText>Vorschau</IconText>
                                    </IconWrapper>
                                ) : (
                                    <>
                                        {slideOnPreview.fileType ===
                                            'IMAGE' && (
                                            <ImagePreview
                                                src={slideOnPreview.file}
                                            />
                                        )}
                                        {slideOnPreview.fileType ===
                                            'VIDEO' && (
                                            <VideoPlayerWrapper>
                                                <VideoPlayer
                                                    url={slideOnPreview.file}
                                                    playing
                                                    width="100%"
                                                    height="100%"
                                                />
                                            </VideoPlayerWrapper>
                                        )}
                                    </>
                                )}
                            </PreviewWrapper>
                        </Content>
                    </Wrapper>
                ))}
        </Modal>
    );
};

export default SelectedLibraryForImportSlidesModal;
