import getTemplate from 'ProjectManager/Template/Common/api/getTemplate';

const getTemplateQuestionnaireSections = async (templateId, cancelToken) => {
    try {
        const response = await getTemplate(
            templateId,
            [
                'questionnaireSections',
                'questionnaireSections.subsections',
                'questionnaireSections.subsections.questions',
                'questionnaireSections.subsections.questions.files',
            ],
            cancelToken,
        );

        return response.data.questionnaireSections;
    } catch (error) {
        return [];
    }
};

export default getTemplateQuestionnaireSections;
