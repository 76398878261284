import React, { useCallback, useEffect } from 'react';
import { sumBy } from 'lodash';
import { actions } from 'react-table';
import { useWindowWidth } from '@react-hook/window-size';

const pluginName = 'useResponsiveFlexTable';

export const useResponsiveFlexTable = hooks => {
    hooks.stateReducers.push(reducer);
    hooks.useInstance.push(useInstance);
    hooks.getTableProps.push(getTableProps);
};

useResponsiveFlexTable.pluginName = pluginName;

const reducer = (state, action) => {
    if (action.type === actions.init) {
        return {
            ...state,
            tableRef: React.createRef(),
        };
    }
};

const useInstance = instance => {
    const {
        hideColumnsPriority,
        flatHeaders,
        setHiddenColumns,
        state: { tableRef },
    } = instance;

    const windowWidth = useWindowWidth();

    const toggleHiddenColumns = useCallback(() => {
        if (tableRef.current) {
            const tableWidth = tableRef.current.getBoundingClientRect().width;

            if (tableWidth > 0) {
                const columnsToHide = [];

                let columnsWidth = sumBy(flatHeaders, 'width');

                hideColumnsPriority.forEach(columnId => {
                    if (tableWidth < columnsWidth) {
                        const columnWidth = flatHeaders.find(
                            column => column.id === columnId,
                        ).width;

                        columnsWidth -= columnWidth;

                        columnsToHide.push(columnId);
                    }
                });

                setHiddenColumns(columnsToHide);
            }
        }
        // eslint-disable-next-line
    }, [tableRef, hideColumnsPriority, setHiddenColumns]);

    useEffect(() => {
        if (windowWidth > 0) {
            toggleHiddenColumns();
        }
    }, [toggleHiddenColumns, windowWidth]);
};

const getTableProps = (props, { instance }) => [
    props,
    {
        ref: instance.state.tableRef,
    },
];
