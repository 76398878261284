import React from 'react';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import colors from 'Common/constants/colors';
import { CheckCircleRounded, WarningRounded } from '@material-ui/icons';
import SideBySideStatistics from 'ProjectManager/Project/Preview/Statistics/SideBySideStatistics';
import StatisticsNumber from 'ProjectManager/Project/Preview/Statistics/StatisticsNumber';

const Statistics = () => {
    const { statistics } = useRecoilValue(projectAtom);

    return (
        <SideBySideStatistics>
            <StatisticsNumber
                number={statistics.participantsWithPendingTasks}
                text="Mitarbeiter mit anstehenden Aufgaben"
                icon={
                    statistics.participantsWithPendingTasks !== 0 ? (
                        <WarningRounded />
                    ) : undefined
                }
                color={colors.WARNING}
            />
            <StatisticsNumber
                number={statistics.participantsWithCompletedTasks}
                text="Mitarbeiter mit erledigten Aufgaben"
                icon={
                    statistics.participantsWithCompletedTasks !== 0 ? (
                        <CheckCircleRounded />
                    ) : undefined
                }
                color={colors.GREEN}
            />
        </SideBySideStatistics>
    );
};

export default Statistics;
