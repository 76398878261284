import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import ProjectGeneralInformation from 'ProjectManager/Project/DataManagement/GeneralInformation/ProjectGeneralInformation';
import TemplateGeneralInformation from 'ProjectManager/Template/DataManagement/TemplateGeneralInformation';

const GeneralInformation = ({ onNextButtonClick }) => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return (
            <ProjectGeneralInformation onNextButtonClick={onNextButtonClick} />
        );
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return (
            <TemplateGeneralInformation onNextButtonClick={onNextButtonClick} />
        );
    }

    return null;
};

GeneralInformation.propTypes = {
    onNextButtonClick: PropTypes.func.isRequired,
};

export default GeneralInformation;
