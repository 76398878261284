import React from 'react';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import InnerBox from 'Common/components/Boxes/InnerBox';
import formatDate from 'Common/utils/formatDate';
import parseDate from 'Common/utils/parseDate';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';

const Supervisors = styled.div``;

const Supervisor = styled(InnerBox)`
    margin-bottom: 10px;
    padding: 12px;

    &:last-child {
        margin-bottom: 0;
    }

    ${ifProp(
        'disabled',
        css`
            opacity: 0.3;
        `,
    )};
`;

const Name = styled.div`
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 21px;
`;

const Email = styled.div`
    font-size: 12px;
    line-height: 18px;
    color: ${colors.LIGHTER_GRAY};
`;

const Date = styled.div`
    font-size: 12px;
    line-height: 18px;
    color: ${colors.LIGHTER_GRAY};
`;

const SupervisorsList = () => {
    const { supervisors } = useRecoilValue(projectAtom);

    return (
        <Supervisors>
            {supervisors.map(supervisor => (
                <Supervisor
                    key={supervisor.id}
                    disabled={supervisor.isUnAssigned}
                >
                    <Name>{`${supervisor.user.lastName}, ${supervisor.user.firstName}`}</Name>
                    <Email>{supervisor.user.email}</Email>
                    <Date>
                        {supervisor.isUnAssigned
                            ? `Entfernt am ${formatDate(
                                  parseDate(supervisor.unAssignedAt),
                              )}`
                            : `Zugewiesen am ${formatDate(
                                  parseDate(supervisor.assignedAt),
                              )}`}
                    </Date>
                </Supervisor>
            ))}
        </Supervisors>
    );
};

export default SupervisorsList;
