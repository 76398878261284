import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { switchProp } from 'styled-tools';
import { css } from 'styled-components';

const SideBySideStatistics = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${switchProp(
        'placement',
        {
            top: css`
                margin-bottom: 30px;
                padding-bottom: 20px;
                border-bottom: 1px solid ${colors.DARK_DIVIDER};
            `,
            middle: css`
                margin: 30px 0;
                padding: 20px 0;
                border-top: 1px solid ${colors.DARK_DIVIDER};
                border-bottom: 1px solid ${colors.DARK_DIVIDER};
            `,
        },
        css`
            margin-top: 30px;
            padding-top: 20px;
            border-top: 1px solid ${colors.DARK_DIVIDER};
        `,
    )};

    > div {
        max-width: 160px;

        &:first-child {
            margin-bottom: 30px;
        }
    }

    @media screen and (min-width: 375px) {
        flex-direction: row;

        > div {
            flex: 1;
            max-width: initial;

            &:first-child {
                position: relative;
                padding-right: 10px;
                margin-bottom: 0;

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    width: 1px;
                    height: calc(100% - 10px);
                    background: ${colors.DARK_DIVIDER};
                }
            }

            &:nth-child(2) {
                padding-left: 10px;
            }
        }
    }

    @media screen and (min-width: 400px) {
        > div {
            &:first-child {
                padding-right: 20px;
            }

            &:nth-child(2) {
                padding-left: 20px;
            }
        }
    }
`;

export default SideBySideStatistics;
