import api from 'Application/api/api';

const deleteProjectCustomSubsection = (projectId, customSubsectionId, cancelToken = null) =>
    api.delete(
        `project-manager/projects/${projectId}/questionnaire/custom-subsections/${customSubsectionId}`,
        {
            cancelToken,
        },
    );

export default deleteProjectCustomSubsection;
