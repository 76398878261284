import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useAreAllProjectStepsCompleted from 'ProjectManager/DataManagement/Common/hooks/useAreAllStepsCompleted/useAreAllProjectStepsCompleted';
import useAreAllTemplateStepsCompleted from 'ProjectManager/DataManagement/Common/hooks/useAreAllStepsCompleted/useAreAllTemplateStepsCompleted';

const useAreAllStepsCompleted = () => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useAreAllProjectStepsCompleted();
    const dataFromTemplate = useAreAllTemplateStepsCompleted();

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return false;
};

export default useAreAllStepsCompleted;
