import api from 'Application/api/api';

const getEmployeeInstructionAssignments = (
    employeeId,
    load,
    cancelToken = null,
) =>
    api.get('/education/instruction-assignments', {
        params: {
            employeeId,
            load,
        },
        cancelToken,
    });

export default getEmployeeInstructionAssignments;
