import React from 'react';
import SideBySideStatistics from 'ProjectManager/Project/Preview/Statistics/SideBySideStatistics';
import StatisticsNumber from 'ProjectManager/Project/Preview/Statistics/StatisticsNumber';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { CheckCircleRounded, WarningRounded } from '@material-ui/icons';
import colors from 'Common/constants/colors';
import styled from 'styled-components/macro';

const StyledWarningRounded = styled(WarningRounded)`
    position: relative;
    top: -1px;
`;

const Statistics = props => {
    const { statistics } = useRecoilValue(projectAtom);
    const pending = statistics.participantsWithoutConfirmedIntroductions;
    const completed = statistics.participantsWithConfirmedIntroductions;

    return (
        <SideBySideStatistics {...props}>
            <StatisticsNumber
                text="Ausstehend"
                number={pending}
                icon={pending > 0 ? <StyledWarningRounded /> : undefined}
                color={colors.WARNING}
            />
            <StatisticsNumber
                text="Abgeschlossen"
                number={completed}
                icon={completed > 0 ? <CheckCircleRounded /> : undefined}
                color={colors.GREEN}
            />
        </SideBySideStatistics>
    );
};

export default Statistics;
