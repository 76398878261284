import React from 'react';
import { AssignmentRounded } from '@material-ui/icons';
import NoItemsText from 'ProjectManager/Overview/NoItemsText';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import OuterBox from 'Common/components/Boxes/OuterBox';

const Wrapper = styled(OuterBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding: 40px 20px;
    color: ${colors.GRAY};

    ${NoItemsText} {
        max-width: 340px;
    }

    > svg {
        font-size: 55px;
    }

    @media screen and (min-width: 1180px) {
        padding-top: 90px;
        padding-bottom: 90px;

        > svg {
            font-size: 140px;
        }
    }
`;

const NoProjectsFound = () => (
    <Wrapper>
        <AssignmentRounded />
        <NoItemsText>
            Zu den ausgewählten Status existieren keine Projekt
        </NoItemsText>
    </Wrapper>
);

export default NoProjectsFound;
