import React from 'react';
import PropTypes from 'prop-types';
import questionTypesAnswerComponentsMap from 'ProjectManager/DataManagement/Questionnaire/components/Question/Answers/constants/questionTypesAnswerComponentsMap';

const Answers = ({ question }) => {
    if (questionTypesAnswerComponentsMap.hasOwnProperty(question.type)) {
        const AnswerComponent = questionTypesAnswerComponentsMap[question.type];

        return <AnswerComponent question={question} />;
    }

    return null;
};

Answers.propTypes = {
    question: PropTypes.object.isRequired,
};

export default Answers;
