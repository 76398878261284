import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import styled from 'styled-components/macro';
import ProjectDrawer from 'ProjectManager/Project/Preview/ProjectDrawer';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import { EditRounded } from '@material-ui/icons';
import Button from 'Common/components/Button';
import EmployeeSelectionContent
    from 'ProjectManager/DataManagement/EmployeeSelection/components/EmployeeSelectionContent';
import Statistics from 'ProjectManager/Project/Preview/WorkforcePlanning/Statistics';
import Areas from 'ProjectManager/DataManagement/Areas/components/Areas';
import WarningMessage from 'Common/components/WarningMessage';
import LabeledValue from 'ProjectManager/Project/Preview/LabeledValue';
import { orderBy } from 'lodash';
import Tile from 'ProjectManager/Project/Preview/Tile';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';

const Details = styled.div`
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  padding-left: 0;
`;

const WorkforcePlanning = () => {
    const { status, areas, participants } = useRecoilValue(projectAtom);

    const [isDrawerOpened, setIsDrawerOpened] = useState(false);

    const openDrawer = () => {
        setIsDrawerOpened(true);
    };

    const closeDrawer = () => {
        setIsDrawerOpened(false);
    };

    const statusesInWhichEditingIsNotPossible = [
        projectStatuses.ARCHIVED,
        projectStatuses.ENDED,
    ];

    const isEditingPossible = !statusesInWhichEditingIsNotPossible.includes(
        status,
    );

    const assignedParticipants = participants.filter(
        participant => participant.isAssigned,
    );

    return (
        <>
            <Tile>
                <OuterBoxTitle>Personaleinsatzplanung</OuterBoxTitle>
                <Details>
                    <LabeledValue label="Unterweisungsbereiche">
                        {areas.length > 0
                            ? orderBy(areas, ['name'], ['asc'])
                                .map(area => area.name)
                                .join(', ')
                            : '-'}
                    </LabeledValue>
                    <LabeledValue label="Einbezogene Mitarbeiter">
                        {assignedParticipants.length}
                    </LabeledValue>
                </Details>
                {isEditingPossible && (
                    <>
                        <StyledButton
                            underline
                            icon={<EditRounded />}
                            onClick={openDrawer}
                        >
                            Personaleinsatzplanung
                        </StyledButton>
                        {assignedParticipants.length === 0 && (
                            <WarningMessage>
                                Keine Mitarbeiter ausgewählt
                            </WarningMessage>
                        )}
                    </>
                )}
                <Statistics />
            </Tile>
            <ProjectDrawer
                isOpened={isDrawerOpened}
                onOpen={openDrawer}
                onClose={closeDrawer}
            >
                <Areas />
                <EmployeeSelectionContent />
            </ProjectDrawer>
        </>
    );
};

export default WorkforcePlanning;
