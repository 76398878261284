import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import TopicSelection from 'ProjectManager/DataManagement/TopicManagement/components/TopicSelection/components/TopicSelection';
import DataManagementBottomButtons from 'ProjectManager/DataManagement/Common/components/DataManagementBottomButtons';
import Button from 'Common/components/Button';
import CustomTopicManagement from 'ProjectManager/DataManagement/TopicManagement/components/CustomTopicManagement/components/CustomTopicManagement';
import useIsNextButtonAvailable from 'ProjectManager/DataManagement/TopicManagement/hooks/isNextButtonAvailable/useIsNextButtonAvailable';
import { ArrowForwardRounded } from '@material-ui/icons';
import useHasOtherPrimaryButton from 'ProjectManager/DataManagement/Common/hooks/useHasOtherPrimaryButton/useHasOtherPrimaryButton';

const Wrapper = styled.div``;

const TopicManagement = ({ onNextButtonClick }) => {
    const hasOtherPrimaryButton = useHasOtherPrimaryButton();
    const isNextButtonAvailable = useIsNextButtonAvailable();

    return (
        <Wrapper>
            <TopicSelection />
            <CustomTopicManagement />
            <DataManagementBottomButtons>
                <Button
                    text={hasOtherPrimaryButton}
                    icon={
                        hasOtherPrimaryButton ? (
                            <ArrowForwardRounded />
                        ) : undefined
                    }
                    iconPosition="right"
                    type="button"
                    disabled={!isNextButtonAvailable}
                    onClick={onNextButtonClick}
                >
                    Weiter
                </Button>
            </DataManagementBottomButtons>
        </Wrapper>
    );
};

TopicManagement.propTypes = {
    onNextButtonClick: PropTypes.func.isRequired,
};

export default TopicManagement;
