import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import useProjectForDataManagement from 'ProjectManager/Project/Common/hooks/useProjectForDataManagement';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';
import Project from 'ProjectManager/Project/Edit/Project';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';

const EditProject = () => {
    const { id } = useParams();

    const {
        project,
        loadProject,
        isLoading,
        hasError,
        error,
    } = useProjectForDataManagement(id);

    if (
        hasError &&
        (error?.response?.status === 401 || error?.response?.status === 404)
    ) {
        return <Redirect to="/project-manager" />;
    }

    if (project.id) {
        if (!project.isCreated) {
            return <Redirect to={`/project-manager/project/${id}/create`} />;
        }

        if (project.status === projectStatuses.ARCHIVED) {
            return <Redirect to={`/project-manager/project/${id}/preview`} />;
        }
    }

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : hasError ? (
                <RetryLoading onRetry={loadProject} />
            ) : (
                <Project />
            )}
        </>
    );
};

export default EditProject;
