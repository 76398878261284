import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'Common/components/Button';
import ConfirmModal from 'Common/components/Filters/CloseButton/ConfirmModal';

const CloseButton = ({ haveFiltersChanged, onClick, ...props }) => {
    const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);

    const handleClick = () => {
        if (haveFiltersChanged) {
            setIsConfirmModalOpened(true);
        } else {
            onClick();
        }
    };

    const closeConfirmModal = () => {
        setIsConfirmModalOpened(false);
    };

    return (
        <>
            <Button type="button" onClick={handleClick} {...props} />
            {isConfirmModalOpened && (
                <ConfirmModal onClose={closeConfirmModal} onConfirm={onClick} />
            )}
        </>
    );
};

CloseButton.propTypes = {
    haveFiltersChanged: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default CloseButton;
