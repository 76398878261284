import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import AsyncSelect from 'Common/components/Form/Fields/AsyncSelect';
import reactSelectCustomComponents from 'Common/components/Form/Fields/reactSelectCustomComponents';
import { components as reactSelectComponents } from 'react-select';
import Button from 'Common/components/Button';
import { AddRounded } from '@material-ui/icons';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import moveTemplateToGroup from 'ProjectManager/Template/Common/api/dataManagement/moveTemplateToGroup';
import withGroup from 'ProjectManager/Template/Common/recoil/modifiers/withGroup';
import createTemplate from 'ProjectManager/Template/Common/api/createTemplate';
import getTemplateGroup from 'ProjectManager/TemplateGroup/api/getTemplateGroup';
import mapTemplateGroupToOption from 'ProjectManager/Template/DataManagement/Group/helpers/mapTemplateGroupToOption';
import loadTemplateGroupOptions from 'ProjectManager/Template/DataManagement/Group/helpers/loadTemplateGroupOptions';
import ConfirmGroupChangeModal from 'ProjectManager/Template/DataManagement/Group/components/ConfirmGroupChangeModal';
import CreateGroupModal from 'ProjectManager/Template/DataManagement/Group/components/CreateGroupModal';

const CreateButton = styled(Button)`
    width: 100%;
    justify-content: flex-start;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;

    svg {
        font-size: 18px !important;
    }
`;

const MenuWithCreateButton = ({ children, ...props }) => (
    <reactSelectComponents.Menu {...props}>
        <CreateButton
            text
            dark
            icon={<AddRounded />}
            type="button"
            onClick={props.selectProps.onCreateButtonClick}
            onTouchStart={() => {
                document.isInsideOfMenuTouched = true;
            }}
        >
            Erstelle eine neue Projektkategorie
        </CreateButton>
        {children}
    </reactSelectComponents.Menu>
);

const Group = props => {
    const [isLoading, setIsLoading] = useState(false);

    const [{ id: templateId, group }, setTemplate] = useRecoilState(
        templateAtom,
    );

    // Keep track of how many groups were created in order to update the options
    // in the drop-down field for the groups.
    const [newGroupsCreatedCount, setNewGroupsCreatedCount] = useState(0);

    const dispatch = useDispatch();

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);

    const [
        isConfirmChangeModalOpened,
        setIsConfirmChangeModalOpened,
    ] = useState(false);

    const handleCreateButtonClick = () => {
        if (templateId) {
            // When a template has already been created, it already has a group.
            // So, first we have to verify that the change is desired.
            // The local state will trigger a confirmation pop-up to show.
            setIsConfirmChangeModalOpened(true);
        } else {
            setIsCreateModalOpened(true);
        }
    };

    const handleCreateGroupSuccess = async newGroup => {
        if (templateId) {
            await moveTemplateToGroup(templateId, newGroup.id, source.token);

            // Update the group in Recoil
            setTemplate(withGroup(newGroup));

            setNewGroupsCreatedCount(prevCount => prevCount + 1);
            setIsCreateModalOpened(false);
        } else {
            // The template has not been created, yet.
            // So, let's create the template, using the newly created group
            // and redirect the user to the edit page.

            setIsLoading(true);

            try {
                const templateResponse = await createTemplate(
                    newGroup.id,
                    source.token,
                );

                setIsLoading(false);

                notify('Das Projekt wurde erfolgreich erstellt', {
                    type: toast.TYPE.SUCCESS,
                });

                const newTemplate = templateResponse.data;

                dispatch(push(`/project-manager/template/${newTemplate.id}`));
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setIsLoading(false);

                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }
        }
    };

    const handleCancelGroupCreation = () => {
        setIsCreateModalOpened(false);
    };

    // State, holding the newly selected group option.
    // The value is set when the option is selected from the drop-down field,
    // but it's used only after the change has been confirmed via the pop-up.
    const [potentialNewGroupOption, setPotentialNewGroupOption] = useState(
        null,
    );

    const handleChange = async option => {
        if (templateId) {
            // In order to update the template's group, the user has to confirm
            // that the change has not been done by accident.
            setPotentialNewGroupOption(option);
            setIsConfirmChangeModalOpened(true);
        } else {
            // When there is no template and this is the first time a group is set,
            // this actually means that the template has not been created, yet.
            // So, let's create the template and redirect the user to the edit page.

            setIsLoading(true);

            try {
                const templateResponse = await createTemplate(
                    option.value,
                    source.token,
                );

                setIsLoading(false);

                notify('Das Projekt wurde erfolgreich erstellt', {
                    type: toast.TYPE.SUCCESS,
                });

                const newTemplate = templateResponse.data;

                dispatch(push(`/project-manager/template/${newTemplate.id}`));
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setIsLoading(false);

                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }
        }
    };

    // Used only when the template has already been created,
    // to confirm the change from one group to another.
    const handleConfirmGroupChange = async () => {
        if (potentialNewGroupOption) {
            // The group for the change was selected from the drop-down field
            try {
                await moveTemplateToGroup(
                    templateId,
                    potentialNewGroupOption.value,
                    source.token,
                );

                const groupResponse = await getTemplateGroup(
                    potentialNewGroupOption.value,
                    source.token,
                );

                const newGroup = groupResponse.data;

                setPotentialNewGroupOption(null);

                setTemplate(withGroup(newGroup));

                setIsConfirmChangeModalOpened(false);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }
        } else {
            // The group for the change is going to be created through the modal.
            // All the responsible logic for this is inside the creation modal and its handlers.
            setIsConfirmChangeModalOpened(false);
            setIsCreateModalOpened(true);
        }
    };

    const handleCancelGroupChange = () => {
        setPotentialNewGroupOption(null);
        setIsConfirmChangeModalOpened(false);
    };

    return (
        <>
            <AsyncSelect
                key={newGroupsCreatedCount}
                id="group"
                name="group"
                label="Projektkategorie"
                value={group ? mapTemplateGroupToOption(group) : null}
                onChange={handleChange}
                loadOptions={loadTemplateGroupOptions}
                isDisabled={isLoading}
                isSearchable
                components={{
                    ...reactSelectCustomComponents,
                    Menu: MenuWithCreateButton,
                }}
                onCreateButtonClick={handleCreateButtonClick}
                showSpinningLoader={isLoading}
                {...props}
            />
            {isCreateModalOpened && (
                <CreateGroupModal
                    isOpen
                    onSuccess={handleCreateGroupSuccess}
                    onCancel={handleCancelGroupCreation}
                    shouldReturnFocusAfterClose={false}
                />
            )}
            {isConfirmChangeModalOpened && (
                <ConfirmGroupChangeModal
                    isOpen
                    onConfirm={handleConfirmGroupChange}
                    onCancel={handleCancelGroupChange}
                    shouldReturnFocusAfterClose={false}
                />
            )}
        </>
    );
};

export default Group;
