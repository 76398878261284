import React, { useEffect, useState } from 'react';
import { navigationItems } from 'ProjectManager/DataManagement/Navigation/constants/navigation';
import getQuestionnaireSectionIdentifier from 'ProjectManager/DataManagement/Questionnaire/helpers/getQuestionnaireSectionIdentifier';
import scrollToTop from 'Common/utils/scrollToTop';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import isProjectTopicManagementStepCompletedState from 'ProjectManager/Project/Common/recoil/project/selectors/isGeneralProjectInformationStepCompletedSelector';
import isGeneralProjectInformationStepCompletedSelector from 'ProjectManager/Project/Common/recoil/project/selectors/isGeneralProjectInformationStepCompletedSelector';
import isProjectEmployeeSelectionStepCompletedSelector from 'ProjectManager/Project/Common/recoil/project/selectors/isProjectEmployeeSelectionStepCompletedSelector';
import useCompleteButton from 'ProjectManager/Project/CreationProcess/hooks/useCompleteButton';
import BackButton from 'Application/components/Header/BackButton/BackButton';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import DataManagement from 'ProjectManager/DataManagement/Common/components/DataManagement';
import { Helmet } from 'react-helmet-async';

const Project = () => {
    const isGeneralProjectInformationStepCompleted = useRecoilValue(
        isGeneralProjectInformationStepCompletedSelector,
    );

    const isEmployeeSelectionStepCompleted = useRecoilValue(
        isProjectEmployeeSelectionStepCompletedSelector,
    );

    const isTopicManagementStepCompleted = useRecoilValue(
        isProjectTopicManagementStepCompletedState,
    );

    const { name, questionnaireSections } = useRecoilValue(projectAtom);

    const [activeNavigationItem, setActiveNavigationItem] = useState(() => {
        if (
            isTopicManagementStepCompleted &&
            questionnaireSections.length > 0
        ) {
            let activeQuestionnaireSection =
                questionnaireSections[questionnaireSections.length - 1];

            for (let i = 0; i < questionnaireSections.length; i++) {
                const section = questionnaireSections[i];

                if (!section.isCompleted) {
                    activeQuestionnaireSection = section;
                    break;
                }
            }

            return getQuestionnaireSectionIdentifier(
                activeQuestionnaireSection,
            );
        }

        if (isEmployeeSelectionStepCompleted) {
            return navigationItems.TOPIC_MANAGEMENT;
        }

        if (isGeneralProjectInformationStepCompleted) {
            return navigationItems.EMPLOYEE_SELECTION;
        }

        return navigationItems.GENERAL_INFORMATION;
    });

    // Each time the step is changed, scroll to the top of the page,
    // as if the page has changed.
    useEffect(() => {
        scrollToTop();
    }, [activeNavigationItem]);

    const { complete, renderButton } = useCompleteButton();

    const title = name ? name : 'Kein Name';

    return (
        <>
            <Helmet>
                <title>{title} - Projektübersicht</title>
            </Helmet>
            <BackButton to="/project-manager" />
            <Breadcrumb to="/project-manager">Projektübersicht</Breadcrumb>
            <Breadcrumb isActive>{title}</Breadcrumb>
            <DataManagement
                title={title}
                activeNavigationItem={activeNavigationItem}
                onActiveNavigationItemChange={setActiveNavigationItem}
                onComplete={complete}
            />
            {renderButton()}
        </>
    );
};

export default Project;
