import getDriverLicenseClasses from 'Measure/api/driver-license/getDriverLicenseClasses';

const loadDriverLicenseClassesOptions = async cancelToken => {
    const response = await getDriverLicenseClasses(cancelToken);
    const licenseClasses = response.data;

    return licenseClasses.map(licenseClass => ({
        value: licenseClass,
        label: licenseClass,
    }));
};

export default loadDriverLicenseClassesOptions;
