import api from 'Application/api/api';

const updateTemplateQuestionComment = (
    templateId,
    questionId,
    isCustom,
    comment,
    cancelToken = null,
) =>
    api.post(
        `project-manager/templates/${templateId}/questionnaire/questions/${questionId}/${
            isCustom ? 1 : 0
        }/comment/update`,
        { comment },
        { cancelToken },
    );

export default updateTemplateQuestionComment;
