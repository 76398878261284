import api from 'Application/api/api';

const isTemplateCustomTopicNameUnique = (
    name,
    templateId,
    editedCustomTopicId = null,
    cancelToken = null,
) =>
    api.get(
        `project-manager/templates/${templateId}/custom-topics/is-name-unique/${name}`,
        {
            params: {
                editedCustomTopicId,
            },
            cancelToken,
        },
    );

export default isTemplateCustomTopicNameUnique;
