import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import axios from 'axios';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import deleteProjectPhoto from 'ProjectManager/Project/Common/api/dataManagement/photo/deleteProjectPhoto';
import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';

const PhotoDeleteModal = ({ onCancel, onSuccess }) => {
    const [{ id: projectId }] = useRecoilState(projectAtom);

    const [isDeleting, setIsDeleting] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirm = async () => {
        setIsDeleting(true);

        try {
            await deleteProjectPhoto(projectId, source.token);

            setIsDeleting(false);

            onSuccess();

            notify('Das Bild wurde erfolgreich gelöscht', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsDeleting(false);

                notify('Das Bild konnte nicht gelöscht werden', {
                    type: toast.TYPE.ERROR,
                });
            }
        }
    };

    return (
        <Modal
            isOpen
            onRequestClose={isDeleting ? undefined : onCancel}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onCancel}
                        disabled={isDeleting}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isDeleting}
                        onClick={handleConfirm}
                        disabled={isDeleting}
                    >
                        Löschen
                    </Button>
                </>
            }
        >
            <ModalTitle>
                Sie sind dabei das Bild zu löschen. Sind Sie sich sicher?
            </ModalTitle>
        </Modal>
    );
};

PhotoDeleteModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default PhotoDeleteModal;
