import api from 'Application/api/api';

const getCurrentEmployee = (load, cancelToken = null) =>
    api.get('/employees/current', {
        params: {
            load,
        },
        cancelToken,
    });

export default getCurrentEmployee;
