import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import archiveEmployee from 'Employee/api/archiveEmployee';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useSelector } from 'react-redux';
import modules from 'Common/constants/modules';

const ArchiveModal = ({ employee, onClose, onArchive }) => {
    const availableModules = useSelector(state => state.user.availableModules);

    const isModuleAvailable = useCallback(
        module => availableModules.includes(module),
        [availableModules],
    );

    const [isConfirming, setIsConfirming] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const archive = async () => {
        setIsConfirming(true);

        try {
            await archiveEmployee(employee.id, source.token);
            await onArchive();

            setIsConfirming(false);

            notify('Mitarbeiter erfolgreich gelöscht', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsConfirming(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    return (
        <Modal
            isOpen
            onRequestClose={isConfirming ? undefined : onClose}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onClose}
                        disabled={isConfirming}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isConfirming}
                        onClick={archive}
                        disabled={isConfirming}
                    >
                        Zugang löschen
                    </Button>
                </>
            }
        >
            <ModalTitle>Löschung des Mitarbeiterzugangs</ModalTitle>
            <p>
                Bitte beachte, dass der Mitarbeiter
                {` "${employee.lastName}, ${employee.firstName}" `}nach der
                Löschung unwiderruflich entfernt wird. Der Mitarbeiter bei der
                Löschung des Zugangs sich nicht mehr im System anmelden kann.
                Die Unterweisungsnachweise finden Sie zukünftig im Archive.
            </p>
            {isModuleAvailable(modules.NEW_RISK_ASSESSMENT) && (
                <p>
                    Falls der Mitarbeiter als Arbeitsverantwortlicher vor Ort
                    benannt wurde, muss der Administrator gegebenenfalls
                    intervenieren.
                </p>
            )}
        </Modal>
    );
};

ArchiveModal.propTypes = {
    employee: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onArchive: PropTypes.func.isRequired,
};

export default ArchiveModal;
