import { useRecoilState } from 'recoil';
import hasUnsavedChangesState from 'Common/recoil/hasUnsavedChangesState';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import deleteTemplateCustomQuestion from 'ProjectManager/Template/Common/api/dataManagement/questionnaire/question/deleteTemplateCustomQuestion';
import withoutCustomQuestion
    from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/withoutCustomQuestion';

const useDeleteTemplateCustomQuestionFunction = questionId => {
    const [{ id: templateId }, setTemplate] = useRecoilState(templateAtom);

    const [, setHasUnsavedChanges] = useRecoilState(hasUnsavedChangesState);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const deleteQuestion = useCallback(async () => {
        setHasUnsavedChanges(true);

        try {
            await deleteTemplateCustomQuestion(
                templateId,
                questionId,
                source.token,
            );

            setTemplate(withoutCustomQuestion(questionId));
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }

        setHasUnsavedChanges(false);
    }, [
        templateId,
        questionId,
        setHasUnsavedChanges,
        setTemplate,
        source.token,
    ]);

    return deleteQuestion;
};

export default useDeleteTemplateCustomQuestionFunction;
