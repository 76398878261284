import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Checkbox from 'Common/components/Form/Fields/Checkbox';
import startSingleInstructionAssignmentPresentation from 'Education/api/instruction-assignment/presentation/startSingleInstructionAssignmentPresentation';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import OuterBox from 'Common/components/Boxes/OuterBox';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';

const Wrapper = styled(OuterBox)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 20px;
    text-align: center;

    @media screen and (min-width: 940px) {
        padding: 50px;
    }
`;

const Title = styled.div`
    font-size: 18px;
    line-height: 27px;

    @media screen and (min-width: 940px) {
        font-size: 22px;
        line-height: 33px;
    }
`;

const SubTitle = styled.div`
    margin-bottom: 40px;
    color: ${colors.GRAY};
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;

    @media screen and (min-width: 940px) {
        font-size: 18px;
        line-height: 27px;
    }
`;

const InformationText = styled.div`
    margin-bottom: 30px;
    color: ${colors.LIGHTER_GRAY};
    font-size: 14px;
`;

const CheckboxWrapper = styled.div`
    margin-bottom: 20px;
`;

const ButtonWrapper = styled.div``;

const SingleAssignmentStartScreen = ({ assignment, onStart }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [isStarting, setIsStarting] = useState(false);

    const handleCheckboxChange = e => {
        setIsChecked(e.target.checked);
    };

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleButtonClick = async () => {
        setIsStarting(true);

        try {
            await startSingleInstructionAssignmentPresentation(
                assignment.id,
                source.token,
            );
            await onStart();

            setIsStarting(false);
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsStarting(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    return (
        <Wrapper>
            <Title>Sie starten die Unterweisung</Title>
            <SubTitle>{assignment.name}</SubTitle>
            <InformationText>
                Wenn Sie Fragen zu der Unterweisung oder dem Test haben, so
                wenden Sie sich an Ihren Vorgesetzten.
            </InformationText>
            <CheckboxWrapper>
                <Checkbox
                    name="assignment"
                    id="assignment"
                    label="Ich habe die Anweisung gelesen und verstanden."
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
            </CheckboxWrapper>
            <ButtonWrapper>
                <Button
                    type="button"
                    isLoading={isStarting}
                    disabled={isStarting || !isChecked}
                    onClick={handleButtonClick}
                >
                    Unterweisung starten
                </Button>
            </ButtonWrapper>
        </Wrapper>
    );
};

SingleAssignmentStartScreen.propTypes = {
    assignment: PropTypes.object.isRequired,
    onStart: PropTypes.func.isRequired,
};

export default SingleAssignmentStartScreen;
