import React, { useCallback } from 'react';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import getEmployee from 'Employee/api/getEmployee';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import BackButton from 'Application/components/Header/BackButton/BackButton';

const LaravelBreadcrumbsAndBackButton = () => {
    const [employeeId] = useQueryParameter('emp');
    const [alternativeEmployeeId] = useQueryParameter('e');
    const [measureType] = useQueryParameter('t');

    const id = employeeId || alternativeEmployeeId;

    const employeeRequest = useCallback(
        cancelToken =>
            id ? getEmployee(id, [], cancelToken) : async () => null,
        [id],
    );

    const { data: employee, isLoading: isLoadingEmployee } = useAxiosRequest(
        employeeRequest,
        null,
    );

    if (isLoadingEmployee) {
        return null;
    }

    const path = window.location.pathname;

    if (path === '/backend/employees') {
        return (
            <>
                <BackButton href="/employees" />
                <Breadcrumb href="/employees">Mitarbeiter</Breadcrumb>
                <Breadcrumb isActive>Erstellen</Breadcrumb>
            </>
        );
    }

    if (path === '/backend/m/exec/call') {
        if (employeeId && employee) {
            return (
                <>
                    <BackButton href={`/employee/${employee.id}`} />
                    <Breadcrumb href="/employees">Mitarbeiter</Breadcrumb>
                    <Breadcrumb
                        href={`/employee/${employee.id}`}
                    >{`${employee.lastName}, ${employee.firstName}`}</Breadcrumb>
                    <Breadcrumb isActive>Ergänzende GF</Breadcrumb>
                </>
            );
        }

        return (
            <>
                <BackButton href="/dashboard" />
                <Breadcrumb isActive>Ergänzende GF</Breadcrumb>
            </>
        );
    }

    if (path === '/backend/m/reminder/call') {
        return (
            <>
                <BackButton href="/dashboard" />
                <Breadcrumb isActive>Maßnahmen</Breadcrumb>
            </>
        );
    }

    if (path === '/backend/m/reminder/content') {
        const components = [
            <BackButton href="/measures" />,
            <Breadcrumb href="/measures">Maßnahmen</Breadcrumb>,
        ];

        // eslint-disable-next-line default-case
        switch (measureType) {
            case 'fire':
                components.push(<Breadcrumb isActive>Brandschutz</Breadcrumb>);
                break;

            case 'aid':
                components.push(<Breadcrumb isActive>Erste Hilfe</Breadcrumb>);
                break;

            case 'psa':
                components.push(
                    <Breadcrumb isActive>
                        Persönliche Schutzausrüstung
                    </Breadcrumb>,
                );
                break;

            case 'car':
                components.push(<Breadcrumb isActive>Fuhrpark</Breadcrumb>);
                break;

            case 'ladder':
                components.push(
                    <Breadcrumb isActive>
                        Prüfbedürftige Einrichtungen
                    </Breadcrumb>,
                );
                break;

            case 'eg':
                components.push(
                    <Breadcrumb isActive>Gefahrenstoffmanagement</Breadcrumb>,
                );
                break;

            case 'other':
                components.push(
                    <Breadcrumb isActive>Rechtsbelehrungen</Breadcrumb>,
                );
                break;
        }

        return components;
    }

    if (path === '/backend/m/docs/call') {
        return (
            <>
                <BackButton href="/dashboard" />
                <Breadcrumb isActive>Dokumente</Breadcrumb>
            </>
        );
    }

    if (path === '/backend/m/csite/call') {
        return (
            <>
                <BackButton href="/dashboard" />
                <Breadcrumb isActive>Ergänzende GF</Breadcrumb>
            </>
        );
    }

    if (path === '/backend/m/csite/report') {
        return (
            <>
                <BackButton href="/backend/m/csite/call" />
                <Breadcrumb href="/backend/m/csite/call">
                    Ergänzende GF
                </Breadcrumb>
                <Breadcrumb isActive>Bericht</Breadcrumb>
            </>
        );
    }

    if (path === '/backend/m/csite/detail') {
        return (
            <>
                <BackButton href="/backend/m/csite/call" />
                <Breadcrumb href="/backend/m/csite/call">
                    Ergänzende GF
                </Breadcrumb>
                <Breadcrumb isActive>Baustelle</Breadcrumb>
            </>
        );
    }

    if (path === '/backend/m/csite/create') {
        return (
            <>
                <BackButton href="/backend/m/csite/call" />
                <Breadcrumb href="/backend/m/csite/call">
                    Ergänzende GF
                </Breadcrumb>
                <Breadcrumb isActive>Baustelle anlegen</Breadcrumb>
            </>
        );
    }

    if (path === '/backend/m/csite/edit_template') {
        return (
            <>
                <BackButton href="/backend/m/csite/call" />
                <Breadcrumb href="/backend/m/csite/call">
                    Ergänzende GF
                </Breadcrumb>
                <Breadcrumb isActive>Vorlage anlegen</Breadcrumb>
            </>
        );
    }

    if (path === '/backend/dsgvo') {
        return (
            <>
                <BackButton href="/dashboard" />
                <Breadcrumb isActive>EU-DSGVO</Breadcrumb>
            </>
        );
    }

    if (path === '/backend/settings') {
        return (
            <>
                <BackButton href="/dashboard" />
                <Breadcrumb isActive>Einstellungen</Breadcrumb>
            </>
        );
    }

    if (path === '/backend/m/archive/call') {
        return (
            <>
                <BackButton href="/dashboard" />
                <Breadcrumb isActive>Archiv</Breadcrumb>
            </>
        );
    }

    if (path === '/backend/m/archive/employees') {
        return (
            <>
                <BackButton href="/archive" />
                <Breadcrumb href="/archive">Archiv</Breadcrumb>
                <Breadcrumb isActive>Mitarbeiter</Breadcrumb>
            </>
        );
    }

    if (
        path === '/backend/m/archive/certificates' &&
        alternativeEmployeeId &&
        employee
    ) {
        return (
            <>
                <BackButton href="/backend/m/archive/employees" />
                <Breadcrumb href="/archive">Archiv</Breadcrumb>
                <Breadcrumb href="/backend/m/archive/employees">
                    Mitarbeiter
                </Breadcrumb>
                <Breadcrumb
                    isActive
                >{`${employee.lastName}, ${employee.firstName}`}</Breadcrumb>
            </>
        );
    }

    return null;
};

export default LaravelBreadcrumbsAndBackButton;
