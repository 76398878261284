import React from 'react';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { useCallback, useMemo, useState } from 'react';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import ManageParticipantsModal from 'ProjectManager/Project/Introductions/Grid/components/Introduction/ManageParticipantsModal';

const useParticipantsManageButton = introduction => {
    const { status: projectStatus } = useRecoilValue(projectAtom);

    const isVisible = useMemo(
        () =>
            ![projectStatuses.ENDED, projectStatuses.ARCHIVED].includes(
                projectStatus,
            ) &&
            !introduction.isConducted &&
            !introduction.isCanceled,
        [introduction.isCanceled, introduction.isConducted, projectStatus],
    );

    const [isModalOpened, setIsModalOpened] = useState(false);

    const handleClick = () => {
        setIsModalOpened(true);
    };

    const closeModal = useCallback(() => {
        setIsModalOpened(false);
    }, []);

    const renderModal = useCallback(() => {
        if (!isModalOpened) {
            return null;
        }

        return (
            <ManageParticipantsModal
                introduction={introduction}
                onClose={closeModal}
            />
        );
    }, [closeModal, introduction, isModalOpened]);

    return {
        isVisible,
        handleClick,
        renderModal,
    };
};

export default useParticipantsManageButton;
