import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { useCallback, useEffect, useMemo } from 'react';
import updateProjectAreas from 'ProjectManager/Project/Common/api/dataManagement/updateProjectAreas';
import axios from 'axios';
import withAreas from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/withAreas';

const useProjectAreasData = () => {
    const [{ id: projectId, areas, participants }, setProject] = useRecoilState(
        projectAtom,
    );

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const update = useCallback(
        async areaIds => {
            const response = await updateProjectAreas(
                projectId,
                areaIds,
                source.token,
            );

            const newAreas = response.data;

            setProject(withAreas(newAreas));
        },
        [projectId, setProject, source.token],
    );

    return projectId
        ? {
              selectedAreas: areas,
              participatingEmployees: participants
                  .filter(participant => participant.isAssigned)
                  .map(participant => participant.employee),
              updateAreas: update,
              isRequired: true,
          }
        : {};
};

export default useProjectAreasData;
