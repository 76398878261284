import api from 'Application/api/api';

const deleteTemplateGroup = (
    id,
    templateGroupIdToMoveTheTemplatesTo = null,
    cancelToken = null,
) =>
    api.delete(`project-manager/template-groups/${id}`, {
        params: {
            templateGroupIdToMoveTheTemplatesTo,
        },
        cancelToken,
    });

export default deleteTemplateGroup;
