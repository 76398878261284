import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import InnerBoxWithButtons from 'Common/components/Boxes/InnerBoxWithButtons';
import Button from 'Common/components/Button';
import InnerBoxTitle from 'Common/components/Boxes/InnerBoxTitle';
import FormField from 'Common/components/Form/FormField';
import Input from 'Common/components/Form/Fields/Input';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import axios from 'axios';
import useCreateCustomSubsectionFunction from 'ProjectManager/DataManagement/Questionnaire/components/SubsectionCreation/hooks/createCustomSubsectionFunction/useCreateCustomSubsectionFunction';
import useUniqueNameValidationCallback from 'ProjectManager/DataManagement/Questionnaire/components/SubsectionCreation/hooks/uniqueNameValidationCallback/useUniqueNameValidationCallback';
import getQuestionnaireSubsectionIdentifier from 'ProjectManager/DataManagement/Questionnaire/helpers/getQuestionnaireSubsectionIdentifier';
import useWindowScroll from '@react-hook/window-scroll';

const initialValues = {
    name: '',
};

const approximateStickyElementsHeight = 170;

const CreateSubsectionForm = ({ section, onCancel, onSave }) => {
    const scrollY = useWindowScroll();

    const [isCreating, setIsCreating] = useState(false);

    const uniqueNameValidationCallback = useUniqueNameValidationCallback(
        section.id,
        section.isCustom,
    );

    const createCustomSubsection = useCreateCustomSubsectionFunction(
        section.id,
        section.isCustom,
    );

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                name: Yup.string()
                    .debouncedCallback(
                        'Diese Bezeichnung wird bereits verwendet',
                        uniqueNameValidationCallback,
                    )
                    .required('Pflichtangabe'),
            }),
        [uniqueNameValidationCallback],
    );

    const {
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        isSubmitting,
        isValidating,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async values => {
            setIsCreating(true);

            try {
                const customSubsection = await createCustomSubsection(values);

                await onSave();

                const customSubsectionId = getQuestionnaireSubsectionIdentifier(
                    customSubsection,
                );

                const customSubsectionDomElementId = `questionnaireSubsection-${customSubsectionId}`;

                const distanceToSubsection = document
                    .getElementById(customSubsectionDomElementId)
                    .getBoundingClientRect().top;

                window.scroll({
                    top:
                        scrollY +
                        distanceToSubsection -
                        approximateStickyElementsHeight -
                        15, // Ensure to have some spacing at the top
                    left: 0,
                    behavior: 'smooth',
                });

                notify('Erstellung war erfolgreich', {
                    type: toast.TYPE.SUCCESS,
                });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }

            setIsCreating(false);
        },
    });

    const isSubmittingAndValidating = isSubmitting && isValidating;
    const isLoading = isCreating || isSubmittingAndValidating;

    return (
        <InnerBoxWithButtons
            buttons={
                <>
                    <Button
                        text
                        type="button"
                        disabled={isLoading}
                        onClick={onCancel}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        disabled={isLoading || !isValid}
                        isLoading={isLoading}
                        onClick={handleSubmit}
                    >
                        Erstellen
                    </Button>
                </>
            }
        >
            <InnerBoxTitle>Neue Untersektion</InnerBoxTitle>
            <form onSubmit={handleSubmit}>
                <FormField>
                    <Input
                        id="name"
                        name="name"
                        label="Bezeichnung"
                        value={values.name}
                        error={errors.name}
                        touched={touched.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isRequired
                    />
                </FormField>
            </form>
        </InnerBoxWithButtons>
    );
};

CreateSubsectionForm.propTypes = {
    section: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default CreateSubsectionForm;
