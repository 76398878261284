import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useUniqueNameValidationCallbackForTemplate from 'ProjectManager/DataManagement/TopicManagement/components/CustomTopicManagement/hooks/uniqueNameValidationCallback/useUniqueNameValidationCallbackForTemplate';
import useUniqueNameValidationCallbackForProject from 'ProjectManager/DataManagement/TopicManagement/components/CustomTopicManagement/hooks/uniqueNameValidationCallback/useUniqueNameValidationCallbackForProject';

const useUniqueNameValidationCallback = () => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useUniqueNameValidationCallbackForProject();
    const dataFromTemplate = useUniqueNameValidationCallbackForTemplate();

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return () => {};
};

export default useUniqueNameValidationCallback;
