import React from 'react';
import styled from 'styled-components/macro';
import TopOverview from 'ProjectManager/Project/List/components/Overview/TopOverview/TopOverview';
import ProjectsTable from 'ProjectManager/Project/List/components/Overview/ProjectsTable/ProjectsTable';
import MobileTemplates from 'ProjectManager/Template/List/components/MobileTemplates';

const MobileTemplatesWrapper = styled.div`
    @media screen and (min-width: 1180px) {
        display: none;
    }
`;

const Overview = () => (
    <>
        <TopOverview />
        <MobileTemplatesWrapper>
            <MobileTemplates />
        </MobileTemplatesWrapper>
        <ProjectsTable />
    </>
);

export default Overview;
