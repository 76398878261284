import api from 'Application/api/api';

const login = (domain, userIdentifier, password, cancelToken = null) =>
    api.post(
        '/login-web',
        {
            domain,
            userIdentifier,
            password,
        },
        {
            cancelToken,
        },
    );

export default login;
