import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import { WarningRounded } from '@material-ui/icons';
import WarningMessage from 'Common/components/Modals/WarningMessage';

const InstructionPresentationSlideDeleteModal = ({
    slideId,
    onConfirm,
    onCancel,
    stats,
}) => (
    <Modal
        isOpen={slideId !== null}
        onRequestClose={onCancel}
        buttons={
            <>
                <Button text dark type="button" onClick={onCancel}>
                    Abbrechen
                </Button>
                <Button type="button" onClick={onConfirm}>
                    Löschen
                </Button>
            </>
        }
    >
        <ModalTitle>Möchten Sie diese Folie wirklich löschen?</ModalTitle>
        <p>
            Die Änderungen werden nicht automatisch gespeichert, Sie müssen die
            gesamte Präsentation speichern.
        </p>
        {!!stats && stats.presentationsInProgressCount > 0 && (
            <WarningMessage>
                <WarningRounded />
                {`Hinweis: Es gibt ${stats.presentationsInProgressCount} Mitarbeiter, die eine Präsentation halten`}
            </WarningMessage>
        )}
    </Modal>
);

InstructionPresentationSlideDeleteModal.defaultProps = {
    slideId: null,
    stats: null,
};

InstructionPresentationSlideDeleteModal.propTypes = {
    slideId: PropTypes.number,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    stats: PropTypes.object,
};

export default InstructionPresentationSlideDeleteModal;
