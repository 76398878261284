import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import colors from 'Common/constants/colors';
import InnerBox from 'Common/components/Boxes/InnerBox';
import OuterBox from 'Common/components/Boxes/OuterBox';
import InteractableOuterBox from 'Common/components/Boxes/InteractableOuterBox';
import {
    AutoSizer,
    CellMeasurer,
    CellMeasurerCache,
    InfiniteLoader,
    List,
    WindowScroller,
} from 'react-virtualized';
import { fill, isEmpty } from 'lodash';
import ContentLoader from 'react-content-loader';

const TableWrapper = styled.div`
    width: 100%;
`;

const StyledTable = styled.div``;

export const Table = ({ getTableProps, children, ...props }) => (
    <TableWrapper>
        <StyledTable {...getTableProps()} {...props}>
            {children}
        </StyledTable>
    </TableWrapper>
);

export const TableHead = styled(OuterBox)`
    position: sticky;
    top: 80px;
    padding: 15px;
    z-index: 5;
`;

export const TableHeadRow = styled.div``;

export const TableHeadCell = styled.div`
    display: inline-flex;
    max-width: 100%;
    padding-right: 20px;
    color: ${colors.GRAY};
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    text-align: left;

    &:last-child {
        padding-right: 0;
    }
`;

export const TableBody = styled.div``;

export const TableBodyCell = styled.div`
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    padding-right: 20px;
    color: ${colors.LIGHTER_GRAY};
    font-size: 16px;
    line-height: 25px;
    overflow-wrap: break-word;

    &:last-child {
        padding-right: 0;
    }

    > * {
        min-width: 0;
    }
`;

export const TableBodyRowWrapper = styled(InteractableOuterBox)`
    padding: 15px;
    margin-top: 5px;
`;

export const TableBodyRow = styled.div``;

export const HiddenColumns = styled.div`
    margin-top: 20px;
`;

export const HiddenColumnProperties = styled(InnerBox)`
    display: flex;
    flex-wrap: wrap;
    padding: 1px 21px;
`;

export const HiddenColumnProperty = styled.div`
    flex: 1 0 auto;
    min-width: 150px;
    max-width: 100%;
    margin-left: -1px;
    margin-top: -1px;
    padding: 10px 0;
    border-bottom: 1px solid ${colors.DARK_DIVIDER};

    &:last-child {
        border-bottom: 0;
    }
`;

export const HiddenColumnLabel = styled.div`
    margin-bottom: 5px;
    color: ${colors.GRAY};
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
`;

export const HiddenColumnValue = styled.div`
    color: ${colors.LIGHTER_GRAY};
    font-size: 16px;
    line-height: 25px;
`;

export const TableRowActions = styled.div``;

const SortArrowWrapper = styled.span`
    display: inline-block;
    vertical-align: middle;
`;

const SortArrow = ({ column }) => (
    <SortArrowWrapper>
        {column.isSortedDesc ? (
            <ArrowDropDown fontSize="small" />
        ) : (
            <ArrowDropUp fontSize="small" />
        )}
    </SortArrowWrapper>
);

SortArrow.propTypes = {
    column: PropTypes.object.isRequired,
};

const DefaultTableHeadCell = ({ column, before, after, ...props }) => (
    <TableHeadCell
        {...(column.getSortByToggleProps
            ? column.getHeaderProps(column.getSortByToggleProps())
            : column.getHeaderProps())}
        {...props}
    >
        {before}
        {column.render('Header')}
        {column.isSorted && <SortArrow column={column} />}
        {after}
    </TableHeadCell>
);

DefaultTableHeadCell.propTypes = {
    column: PropTypes.object.isRequired,
};

export { DefaultTableHeadCell };

const DefaultTableHeadRow = ({ headerGroup, before, after, ...props }) => (
    <TableHeadRow {...headerGroup.getHeaderGroupProps()} {...props}>
        {before}
        {headerGroup.headers.map(column => (
            <DefaultTableHeadCell
                key={column.getHeaderProps().key}
                column={column}
            />
        ))}
        {after}
    </TableHeadRow>
);

DefaultTableHeadRow.propTypes = {
    headerGroup: PropTypes.object.isRequired,
};

export { DefaultTableHeadRow };

const DefaultTableHead = ({ headerGroups, before, after, ...props }) => (
    <TableHead {...props}>
        {before}
        {headerGroups.map(headerGroup => (
            <DefaultTableHeadRow
                key={headerGroup.getHeaderGroupProps().key}
                headerGroup={headerGroup}
            />
        ))}
        {after}
    </TableHead>
);

DefaultTableHead.propTypes = {
    headerGroups: PropTypes.array.isRequired,
};

export { DefaultTableHead };

const DefaultHiddenColumnProperties = ({
    row,
    hiddenColumns,
    before,
    after,
    ...props
}) => (
    <HiddenColumnProperties {...props}>
        {before}
        {row.allCells
            .filter(cell => hiddenColumns.indexOf(cell.column.id) !== -1)
            .map(cell => {
                if (!cell.column.Header && !cell.value) {
                    return null;
                }

                return (
                    <HiddenColumnProperty key={cell.column.id}>
                        <HiddenColumnLabel>
                            {cell.column.render('Header')}
                        </HiddenColumnLabel>
                        <HiddenColumnValue>
                            {cell.render('Cell')}
                        </HiddenColumnValue>
                    </HiddenColumnProperty>
                );
            })}
        {after}
    </HiddenColumnProperties>
);

DefaultHiddenColumnProperties.propTypes = {
    row: PropTypes.object.isRequired,
    hiddenColumns: PropTypes.array.isRequired,
};

export { DefaultHiddenColumnProperties };

const DefaultHiddenColumns = ({
    row,
    hiddenColumns,
    before,
    after,
    ...props
}) => (
    <HiddenColumns {...props}>
        {before}
        <DefaultHiddenColumnProperties
            row={row}
            hiddenColumns={hiddenColumns}
        />
        {after}
    </HiddenColumns>
);

DefaultHiddenColumns.propTypes = {
    row: PropTypes.object.isRequired,
    hiddenColumns: PropTypes.array.isRequired,
};

export { DefaultHiddenColumns };

const DefaultTableBodyCell = ({ cell, before, after, ...props }) => (
    <TableBodyCell {...cell.getCellProps()} {...props}>
        {before}
        {cell.render('Cell')}
        {after}
    </TableBodyCell>
);

DefaultTableBodyCell.propTypes = {
    cell: PropTypes.object.isRequired,
};

export { DefaultTableBodyCell };

const DefaultTableBodyRow = ({
    row,
    hiddenColumns,
    visibleColumns,
    before,
    after,
    onReload,
    ...props
}) => {
    const hasHiddenColumns = Boolean(hiddenColumns) && hiddenColumns.length > 0;

    useEffect(() => {
        if (onReload) {
            onReload();
        }
    }, [hiddenColumns, onReload]);

    return (
        <TableBodyRowWrapper>
            {before}
            <TableBodyRow {...row.getRowProps()} {...props}>
                {row.cells.map(cell => (
                    <DefaultTableBodyCell
                        key={cell.getCellProps().key}
                        cell={cell}
                    />
                ))}
            </TableBodyRow>
            {hasHiddenColumns && (
                <DefaultHiddenColumns
                    row={row}
                    hiddenColumns={hiddenColumns}
                    visibleColumns={visibleColumns}
                />
            )}
            {after}
        </TableBodyRowWrapper>
    );
};

DefaultTableBodyRow.propTypes = {
    row: PropTypes.object.isRequired,
    hiddenColumns: PropTypes.array,
    visibleColumns: PropTypes.array,
    onReload: PropTypes.func,
};

export { DefaultTableBodyRow };

const DataLoader = () => (
    <ContentLoader
        height={54}
        width={320}
        viewBox="0 0 320 54"
        backgroundColor={colors.INNER_BOX_BACKGROUND}
        foregroundColor={colors.DARK_DIVIDER}
    >
        <circle cx="27" cy="27" r="18" />
        <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
        <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
        <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
    </ContentLoader>
);

const LoadingTableBodyRow = ({ row }) => (
    <TableBodyRowWrapper style={{ height: 'calc(100% - 5px)' }}>
        <TableBodyRow {...row.getRowProps()}>
            <DataLoader />
        </TableBodyRow>
    </TableBodyRowWrapper>
);

LoadingTableBodyRow.propTypes = {
    row: PropTypes.object.isRequired,
};

export { LoadingTableBodyRow };

const DefaultTableBody = ({
    rows,
    hiddenColumns,
    visibleColumns,
    getTableBodyProps,
    prepareRow,
    before,
    after,
    ...props
}) => (
    <TableBody {...props} {...getTableBodyProps()}>
        {before}
        {rows.map(row => {
            prepareRow(row);

            return (
                <DefaultTableBodyRow
                    key={row.getRowProps().key}
                    row={row}
                    getRowProps={row.getRowProps}
                    hiddenColumns={hiddenColumns}
                    visibleColumns={visibleColumns}
                />
            );
        })}
        {after}
    </TableBody>
);

DefaultTableBody.defaultProps = {
    hiddenColumns: null,
    visibleColumns: null,
};

DefaultTableBody.propTypes = {
    rows: PropTypes.array.isRequired,
    hiddenColumns: PropTypes.array,
    visibleColumns: PropTypes.array,
    getTableBodyProps: PropTypes.func.isRequired,
    prepareRow: PropTypes.func.isRequired,
};

export { DefaultTableBody };

const VirtualizedTableBody = ({
    rows,
    headerGroups,
    hiddenColumns,
    visibleColumns,
    getTableBodyProps,
    prepareRow,
    totalItemsCount,
    loadItems,
    itemsHash,
    rowDefaultHeight,
    rowMinHeight,
    isLoading,
    before,
    after,
    ...props
}) => {
    const infiniteLoaderRef = useRef(null);
    const hasMountedRef = useRef(false);

    const cache = useMemo(
        () =>
            new CellMeasurerCache({
                defaultHeight: rowDefaultHeight,
                minHeight: rowMinHeight,
                fixedWidth: true,
            }),
        [rowDefaultHeight, rowMinHeight],
    );

    // Each time the total items count has changed, reset the cache
    useEffect(() => {
        if (hasMountedRef.current) {
            if (infiniteLoaderRef.current) {
                cache.clearAll();
                infiniteLoaderRef.current.resetLoadMoreRowsCache();
            }
        }

        hasMountedRef.current = true;
    }, [cache, totalItemsCount, itemsHash]);

    const isRowLoaded = useCallback(
        ({ index }) => !isEmpty(rows[index].original),
        [rows],
    );

    const rowRenderer = useCallback(
        ({ index, key, parent, isVisible, style }) => {
            const row = rows[index];

            if (!row) {
                return null;
            }

            prepareRow(row);

            const isLoading = !isRowLoaded({ index }) || !isVisible;

            return (
                <CellMeasurer
                    cache={cache}
                    columnIndex={0}
                    key={key}
                    parent={parent}
                    rowIndex={index}
                >
                    {({ measure, registerChild }) => (
                        <div ref={registerChild} style={style}>
                            {isLoading ? (
                                <LoadingTableBodyRow row={row} />
                            ) : (
                                <DefaultTableBodyRow
                                    row={row}
                                    hiddenColumns={hiddenColumns}
                                    visibleColumns={visibleColumns}
                                    onReload={measure}
                                />
                            )}
                        </div>
                    )}
                </CellMeasurer>
            );
        },
        [cache, hiddenColumns, isRowLoaded, prepareRow, rows, visibleColumns],
    );

    const renderList = useCallback(
        props => (
            <List
                // CellMeasurer HoC props
                deferredMeasurementCache={cache}
                rowHeight={({ index }) =>
                    Math.max(
                        rowDefaultHeight,
                        rowMinHeight,
                        cache.rowHeight({ index }),
                    )
                }
                {...props}
            />
        ),
        [cache, rowDefaultHeight, rowMinHeight],
    );

    return (
        <TableBody {...getTableBodyProps()} {...props}>
            {before}
            {isLoading ? (
                fill(Array(25), {}).map((entry, index) => (
                    <TableBodyRowWrapper
                        key={index}
                        style={{ height: 'calc(100% - 5px)' }}
                    >
                        <TableBodyRow
                            {...headerGroups[0].getHeaderGroupProps({
                                style: {
                                    height: rowMinHeight,
                                },
                            })}
                        >
                            <DataLoader />
                        </TableBodyRow>
                    </TableBodyRowWrapper>
                ))
            ) : (
                <WindowScroller updateScrollTopOnUpdatePosition>
                    {({
                        height,
                        isScrolling,
                        onChildScroll,
                        scrollTop,
                        registerChild: windowScrollerRegisterChild,
                    }) => (
                        <InfiniteLoader
                            ref={infiniteLoaderRef}
                            isRowLoaded={isRowLoaded}
                            loadMoreRows={loadItems}
                            rowCount={totalItemsCount}
                            minimumBatchSize={10}
                            threshold={3}
                        >
                            {({ onRowsRendered, registerChild }) => (
                                <AutoSizer disableHeight>
                                    {({ width }) => (
                                        <div ref={windowScrollerRegisterChild}>
                                            {renderList({
                                                // Standard props
                                                rowCount: totalItemsCount,
                                                overscanRowCount: 20,
                                                rowRenderer,

                                                // AutoSizer HoC props
                                                width,

                                                // InfiniteLoader HoC props
                                                ref: registerChild,
                                                onRowsRendered,

                                                // WindowScroller HoC props
                                                autoHeight: true,
                                                height,
                                                scrollTop,
                                                isScrolling,
                                                onChildScroll,
                                            })}
                                        </div>
                                    )}
                                </AutoSizer>
                            )}
                        </InfiniteLoader>
                    )}
                </WindowScroller>
            )}
            {after}
        </TableBody>
    );
};

VirtualizedTableBody.defaultProps = {
    hiddenColumns: null,
    visibleColumns: null,
    defaultHeight: 60,
    minHeight: 0,
    isLoading: false,
};

VirtualizedTableBody.propTypes = {
    rows: PropTypes.array.isRequired,
    hiddenColumns: PropTypes.array,
    visibleColumns: PropTypes.array,
    getTableBodyProps: PropTypes.func.isRequired,
    prepareRow: PropTypes.func.isRequired,
    totalItemsCount: PropTypes.number.isRequired,
    loadItems: PropTypes.func.isRequired,
    itemsHash: PropTypes.string.isRequired,
    rowDefaultHeight: PropTypes.number,
    rowMinHeight: PropTypes.number,
    isLoading: PropTypes.bool,
};

export { VirtualizedTableBody };

const DefaultTable = ({
    headerGroups,
    rows,
    hiddenColumns,
    visibleColumns,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    ...props
}) => (
    <Table getTableProps={getTableProps} {...props}>
        <DefaultTableHead headerGroups={headerGroups} />
        <DefaultTableBody
            rows={rows}
            hiddenColumns={hiddenColumns}
            visibleColumns={visibleColumns}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
        />
    </Table>
);

DefaultTable.defaultProps = {
    hiddenColumns: null,
    visibleColumns: null,
};

DefaultTable.propTypes = {
    headerGroups: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    hiddenColumns: PropTypes.array,
    visibleColumns: PropTypes.array,
    getTableProps: PropTypes.func.isRequired,
    getTableBodyProps: PropTypes.func.isRequired,
    prepareRow: PropTypes.func.isRequired,
};

export { DefaultTable };
