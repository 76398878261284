import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import Buttons from 'ProjectManager/Project/List/components/Overview/ProjectsTable/Buttons';

const Wrapper = styled.div`
    width: 100%;

    @media screen and (min-width: 470px) {
        flex: 1;
    }
`;

const InfoWrapper = styled.div`
    color: ${colors.LIGHT_GRAY};
`;

const ClientAndProjectName = styled.div`
    font-size: 16px;
    line-height: 25px;
`;

const Address = styled.div`
    font-size: 13px;
    line-height: 20px;
`;

const DesktopButtonsWrapper = styled.div`
    display: none;

    @media screen and (min-width: 375px) {
        display: block;
    }
`;

const GeneralData = ({ project }) => (
    <Wrapper>
        <InfoWrapper>
            <ClientAndProjectName>
                {`${project.client.name}, `}
                {project.name ? project.name : <em>Kein Name</em>}
            </ClientAndProjectName>
            <Address>
                {project.address ? project.address : <em>Keine Adresse</em>}
            </Address>
        </InfoWrapper>
        <DesktopButtonsWrapper>
            <Buttons project={project} />
        </DesktopButtonsWrapper>
    </Wrapper>
);

GeneralData.propTypes = {
    project: PropTypes.object.isRequired,
};

export default GeneralData;
