import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import OuterBox from 'Common/components/Boxes/OuterBox';

const Box = styled(OuterBox)`
    margin-top: 10px;
    padding: 20px;
    font-size: 14px;
    line-height: 1.5;

    a {
        color: ${colors.DIRTY_WHITE};

        &:hover,
        &:focus {
            color: ${colors.PRIMARY};
        }
    }

    @media screen and (min-width: 500px) {
        margin-top: 20px;
        padding-left: 40px;
        padding-right: 40px;
    }
`;

export default Box;
