import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FileDeleteModal from 'ProjectManager/DataManagement/Files/components/File/FileDeleteModal';
import FileTile from 'ProjectManager/DataManagement/Common/components/FileTile';
import useFileFunctions from 'ProjectManager/DataManagement/Files/hooks/fileFunctions/useFileFunctions';

const File = ({ file, className, searchTerm }) => {
    const { generateDownloadLink, deleteFile } = useFileFunctions();

    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

    const handleDeleteButtonClick = () => {
        setIsDeleteModalOpened(true);
    };

    const handleCancelDelete = () => {
        setIsDeleteModalOpened(false);
    };

    const handleConfirmDelete = async () => {
        await deleteFile(file.id);

        setIsDeleteModalOpened(false);
    };

    return (
        <>
            <FileTile
                file={file}
                downloadLink={generateDownloadLink(file.id)}
                onDeleteButtonClick={handleDeleteButtonClick}
                searchTerm={searchTerm}
                className={className}
            />

            {isDeleteModalOpened && (
                <FileDeleteModal
                    onCancel={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />
            )}
        </>
    );
};

File.defaultProps = {
    searchTerm: undefined,
};

File.propTypes = {
    file: PropTypes.object.isRequired,
    searchTerm: PropTypes.string,
};

export default File;
