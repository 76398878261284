import React from 'react';
import styled from 'styled-components/macro';
import InnerBox from 'Common/components/Boxes/InnerBox';
import Files from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/components/Files';
import { ChevronRightRounded } from '@material-ui/icons';
import colors from 'Common/constants/colors';
import { useRecoilValue } from 'recoil';
import projectMediaFilesSearchTermAtom from 'ProjectManager/Project/ProjectMediaFiles/recoil/projectMediaFilesSearchTerm/projectMediaFilesSearchTermAtom';
import QuestionFilesSearchResults from 'ProjectManager/Project/ProjectMediaFiles/components/QuestionFilesSearchResults';
import PropTypes from 'prop-types';
import MatchableText from 'ProjectManager/Project/ProjectMediaFiles/components/MatchableText';

const Info = styled.div`
    padding: 15px 15px 0;
`;

const Path = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    flex-wrap: wrap;
`;

const PathItem = styled.div`
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
    color: ${colors.LIGHT_GRAY};
    margin-right: 3px;

    &:last-child {
        margin-right: 0;
    }

    > svg {
        margin-left: 3px;
        font-size: 19px;
    }
`;

const Title = styled.div`
    font-size: 14px;
    line-height: 21px;
`;

const FilesWrapper = styled.div`
    margin-top: 20px;
`;

const FilesSearchResultsWrapper = styled.div`
    max-height: 238px;
    overflow-y: auto;
    padding: 15px;
`;

const AllFilesWrapper = styled.div`
    padding: 15px;
`;

const Question = ({ section, subsection, question }) => {
    const searchTerm = useRecoilValue(projectMediaFilesSearchTermAtom);

    const foundFiles = question.files.filter(file =>
        file.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    return (
        <InnerBox>
            <Info>
                <Path>
                    {!section.isCustom && (
                        <PathItem>
                            <MatchableText
                                text={section.predefinedTopic.name}
                                searchTerm={searchTerm}
                            />
                            <ChevronRightRounded />
                        </PathItem>
                    )}
                    <PathItem>
                        <MatchableText
                            text={section.name}
                            searchTerm={searchTerm}
                        />
                        <ChevronRightRounded />
                    </PathItem>
                    <PathItem>
                        <MatchableText
                            text={subsection.name}
                            searchTerm={searchTerm}
                        />
                    </PathItem>
                </Path>
                <Title>
                    <MatchableText
                        text={question.text}
                        searchTerm={searchTerm}
                    />
                </Title>
            </Info>
            <FilesWrapper>
                {searchTerm === '' || foundFiles.length === 0 ? (
                    <AllFilesWrapper>
                        <Files question={question} />
                    </AllFilesWrapper>
                ) : (
                    <FilesSearchResultsWrapper>
                        <QuestionFilesSearchResults
                            question={question}
                            files={foundFiles}
                        />
                    </FilesSearchResultsWrapper>
                )}
            </FilesWrapper>
        </InnerBox>
    );
};

Question.propTypes = {
    section: PropTypes.object.isRequired,
    subsection: PropTypes.object.isRequired,
    question: PropTypes.object.isRequired,
};

export default Question;
