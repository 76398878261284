import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import deleteTemplateCustomSubsection from 'ProjectManager/Template/Common/api/dataManagement/questionnaire/subsection/deleteTemplateCustomSubsection';
import withoutCustomSubsection
    from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/subsection/withoutCustomSubsection';

const useDeleteTemplateCustomSubsectionFunction = () => {
    const [{ id: templateId }, setTemplate] = useRecoilState(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const deleteCustomSubsection = useCallback(
        async customSubsectionId => {
            await deleteTemplateCustomSubsection(
                templateId,
                customSubsectionId,
                source.token,
            );

            setTemplate(withoutCustomSubsection(customSubsectionId));
        },
        [templateId, setTemplate, source.token],
    );

    return deleteCustomSubsection;
};

export default useDeleteTemplateCustomSubsectionFunction;
