import api from 'Application/api/api';

const getGroupInstructionAssignments = (load, cancelToken = null) =>
    api.get('/education/instruction-assignments/group', {
        params: {
            load,
        },
        cancelToken,
    });

export default getGroupInstructionAssignments;
