import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import Loader from 'Common/components/Loader';
import ImportSlides from 'Education/components/instruction/presentation-edit/ImportSlides';
import SlidesList from 'Education/components/instruction/presentation-edit/SlidesList';
import InstructionPresentationForm from 'Education/components/instruction/presentation-edit/InstructionPresentationForm';
import InstructionPresentationSlideDeleteModal from 'Education/components/instruction/presentation-edit/InstructionPresentationSlideDeleteModal';
import InstructionSaveModal from 'Education/components/instruction/presentation-edit/InstructionSaveModal';
import getInstruction from 'Education/api/instruction/getInstruction';
import getInstructionStats from 'Education/api/instruction/getInstructionStats';
import updateInstructionPresentation from 'Education/api/instruction/updateInstructionPresentation';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import axios from 'axios';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';
import withRestrictionCheckDuringArchivingProcess from 'Education/components/common/withRestrictionCheckDuringArchivingProcess';
import colors from 'Common/constants/colors';
import OuterBox from 'Common/components/Boxes/OuterBox';
import BackButton from 'Application/components/Header/BackButton/BackButton';
import { WarningRounded } from '@material-ui/icons';
import Button from 'Common/components/Button';
import ActionBar from 'Common/components/ActionBar/ActionBar';
import WarningMessage from 'Common/components/ActionBar/WarningMessage';

const Wrapper = styled.div``;

const Content = styled(OuterBox)`
    padding: 30px 20px 0;
`;

const Fields = styled.div`
    display: flex;
    padding-bottom: 30px;

    @media screen and (max-width: 920px) {
        flex-direction: column;
    }
`;

const GeneralInformation = styled.div`
    flex: 1;
    margin-right: 10px;

    @media screen and (max-width: 920px) {
        margin-right: 0;
    }
`;

const ImportFiles = styled.div`
    flex: 0 1 350px;
    min-width: 280px;
    margin-left: 10px;

    @media screen and (max-width: 920px) {
        margin-left: 0;
        min-width: initial;
        flex: initial;
    }
`;

const SubTitle = styled.h2`
    margin-top: 0;
    margin-bottom: 20px;
    color: ${colors.DIRTY_WHITE};
    font-size: 16px;
    line-height: 18px;
`;

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Pflichtangabe'),
    recommendedPresentationDuration: Yup.number()
        .integer('Ungültige Nummer')
        .positive('Ungültige Nummer')
        .required('Pflichtangabe'),
    keepReference: Yup.object().nullable(),
    areas: Yup.array().of(Yup.object()).required('Pflichtangabe').nullable(),
    slides: Yup.array().of(
        Yup.object().shape({
            id: Yup.number(),
            title: Yup.string().required('Pflichtangabe'),
        }),
    ),
});

const EditInstructionPresentation = () => {
    const [isSavingChanges, setIsSavingChanges] = useState(false);
    const [isSaveChangesModalOpened, setIsSaveChangesModalOpened] = useState(
        false,
    );

    const instructionRequest = useCallback(
        cancelToken => id =>
            getInstruction(
                id,
                [
                    'presentationAttachment',
                    'recommendedPresentationDuration',
                    'areas',
                    'slides',
                ],
                cancelToken,
            ),
        [],
    );

    const {
        data: instruction,
        isLoading: isLoadingInstruction,
        loadData: loadInstruction,
        loadDataWithoutLoader: loadInstructionWithoutLoader,
        hasError: hasInstructionError,
    } = useAxiosRequest(instructionRequest, null, {
        isInitiallyLoading: true,
        isManual: true,
    });

    const instructionStatsRequest = useCallback(
        cancelToken => id =>
            getInstructionStats(
                id,
                ['presentationsInProgressCount'],
                cancelToken,
            ),
        [],
    );

    const {
        data: stats,
        isLoading: isLoadingStats,
        loadData: loadInstructionStats,
        loadDataWithoutLoader: loadInstructionStatsWithoutLoader,
        hasError: hasInstructionStatsError,
    } = useAxiosRequest(instructionStatsRequest, null, {
        isInitiallyLoading: true,
        isManual: true,
    });

    const isLoading = isLoadingInstruction || isLoadingStats;
    const hasError = hasInstructionError || hasInstructionStatsError;

    const handleSaveButtonClick = () => {
        setIsSaveChangesModalOpened(true);
    };
    const handleSaveChangesCancel = () => {
        setIsSaveChangesModalOpened(false);
    };

    const { id: instructionId } = useParams();

    const handleSlidesImport = useCallback(
        async message => {
            await Promise.all([
                loadInstructionWithoutLoader(instructionId),
                loadInstructionStatsWithoutLoader(instructionId),
            ]);

            notify(message, {
                type: toast.TYPE.SUCCESS,
            });
        },
        [
            instructionId,
            loadInstructionWithoutLoader,
            loadInstructionStatsWithoutLoader,
        ],
    );

    // Initial load of the instruction
    useEffect(() => {
        loadInstruction(instructionId);
    }, [instructionId, loadInstruction]);

    // Initial load of the instruction stats
    useEffect(() => {
        loadInstructionStats(instructionId);
    }, [instructionId, loadInstructionStats]);

    const initialValues = useMemo(
        () =>
            instruction
                ? {
                      name: instruction.name,
                      recommendedPresentationDuration: instruction.recommendedPresentationDuration.toString(),
                      keepReference:
                          instruction.keepReference !== null
                              ? {
                                    value: instruction.keepReference,
                                    label: instruction.keepReference
                                        ? 'Mit Referenz'
                                        : 'Ohne Referenz',
                                }
                              : null,
                      areas: instruction.areas.map(area => ({
                          value: area.id,
                          label: area.name,
                      })),
                      slides: instruction.slides.map(slide => ({
                          id: slide.id,
                          title: slide.title,
                      })),
                  }
                : {
                      name: '',
                      recommendedPresentationDuration: 1,
                      keepReference: null,
                      areas: [],
                      slides: [],
                  },
        [instruction],
    );

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const {
        errors,
        touched,
        values,
        setFieldValue,
        setTouched,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
    } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async values => {
            setIsSavingChanges(true);

            try {
                await updateInstructionPresentation(
                    instructionId,
                    {
                        instructionName: values.name,
                        keepReference:
                            values.keepReference !== null
                                ? values.keepReference.value
                                : null,
                        recommendedPresentationDuration: Number(
                            values.recommendedPresentationDuration,
                        ),
                        areaIds: values.areas.map(option => option.value),
                        slides: values.slides,
                    },
                    source.token,
                );

                await Promise.all([
                    loadInstructionWithoutLoader(instructionId),
                    loadInstructionStatsWithoutLoader(instructionId),
                ]);

                setIsSavingChanges(false);
                setIsSaveChangesModalOpened(false);

                notify('Die Präsentation wurde erfolgreich gespeichert!', {
                    type: toast.TYPE.SUCCESS,
                });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setIsSavingChanges(false);
                    setIsSaveChangesModalOpened(false);

                    notify('Präsentation konnte nicht gespeichert werden', {
                        type: toast.TYPE.ERROR,
                    });
                }
            }
        },
    });

    const handleSlideMoveBackwards = slideId => {
        const newSlides = [...values.slides];

        const index = newSlides.findIndex(slide => slide.id === slideId);

        if (index > 0) {
            const newSlidesTouched = touched.slides ? [...touched.slides] : [];

            const movedSlideTouched = newSlidesTouched[index];
            newSlidesTouched[index] = newSlidesTouched[index - 1];
            newSlidesTouched[index - 1] = movedSlideTouched;

            setTouched({
                ...touched,
                slides: newSlidesTouched,
            });

            const movedSlide = newSlides[index];
            newSlides[index] = newSlides[index - 1];
            newSlides[index - 1] = movedSlide;

            setFieldValue('slides', newSlides);
        }
    };

    const handleSlideMoveForwards = slideId => {
        const newSlides = [...values.slides];

        const index = newSlides.findIndex(slide => slide.id === slideId);

        if (index < newSlides.length - 1) {
            const newSlidesTouched = touched.slides ? [...touched.slides] : [];

            const movedSlideTouched = newSlidesTouched[index];
            newSlidesTouched[index] = newSlidesTouched[index + 1];
            newSlidesTouched[index + 1] = movedSlideTouched;

            setTouched({
                ...touched,
                slides: newSlidesTouched,
            });

            const movedSlide = newSlides[index];
            newSlides[index] = newSlides[index + 1];
            newSlides[index + 1] = movedSlide;

            setFieldValue('slides', newSlides);
        }
    };

    const [slideIdForDeletion, setSlideIdForDeletion] = useState(null);

    const handleSlideDeleteButtonClick = slideId => {
        setSlideIdForDeletion(slideId);
    };

    const handleCancelSlideDelete = () => {
        setSlideIdForDeletion(null);
    };

    const handleSlideDelete = () => {
        if (slideIdForDeletion) {
            const newSlides = [...values.slides];

            const index = newSlides.findIndex(
                slide => slide.id === slideIdForDeletion,
            );

            if (index !== -1) {
                const newSlidesTouched = touched.slides
                    ? [...touched.slides]
                    : [];

                newSlidesTouched.splice(index, 1);

                setTouched({
                    ...touched,
                    slides: newSlidesTouched,
                });

                newSlides.splice(index, 1);

                setFieldValue('slides', newSlides);
            }

            setSlideIdForDeletion(null);
        }
    };

    const handleRetryLoading = () => {
        loadInstruction(instructionId);
        loadInstructionStats(instructionId);
    };

    return (
        <Wrapper>
            <BackButton to="/education" />
            <Breadcrumb to="/education">Unterweisungen</Breadcrumb>
            {isLoading ? (
                <Loader />
            ) : hasError ? (
                <RetryLoading onRetry={handleRetryLoading} />
            ) : (
                <>
                    <Helmet>
                        <title>{`${instruction.name} - Unterweisungen`}</title>
                    </Helmet>
                    <Breadcrumb isActive>
                        Folien bearbeiten - {instruction.name}
                    </Breadcrumb>
                    <ActionBar
                        title={
                            <>
                                <div>{`Unterweisung: ${instruction.name}`}</div>
                                {!!stats &&
                                    stats.presentationsInProgressCount > 0 && (
                                        <WarningMessage>
                                            <WarningRounded />
                                            {`Es gibt ${stats.presentationsInProgressCount} Mitarbeiter, die eine Präsentation halten`}
                                        </WarningMessage>
                                    )}
                            </>
                        }
                        rightElement={
                            <Button
                                type="button"
                                onClick={handleSaveButtonClick}
                                disabled={!isValid}
                            >
                                Speichern
                            </Button>
                        }
                    />
                    <Content>
                        <Fields>
                            <GeneralInformation>
                                <SubTitle>Allgemein</SubTitle>
                                <InstructionPresentationForm
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                />
                            </GeneralInformation>
                            <ImportFiles>
                                <SubTitle>Folien erzeugen</SubTitle>
                                <ImportSlides
                                    instructionId={instruction.id}
                                    onImport={handleSlidesImport}
                                />
                            </ImportFiles>
                        </Fields>
                        <SlidesList
                            slides={instruction?.slides ?? []}
                            slideValues={values.slides}
                            errors={errors}
                            touched={touched}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            onSlideMoveBackwards={handleSlideMoveBackwards}
                            onSlideMoveForwards={handleSlideMoveForwards}
                            onSlideDelete={handleSlideDeleteButtonClick}
                        />
                    </Content>
                </>
            )}
            <InstructionSaveModal
                isOpen={isSaveChangesModalOpened}
                isConfirmLoading={isSavingChanges}
                onConfirm={handleSubmit}
                onCancel={handleSaveChangesCancel}
                stats={stats}
                keepReference={
                    values.keepReference !== null
                        ? values.keepReference.value
                        : null
                }
            />
            <InstructionPresentationSlideDeleteModal
                slideId={slideIdForDeletion}
                onConfirm={handleSlideDelete}
                onCancel={handleCancelSlideDelete}
                stats={stats}
            />
        </Wrapper>
    );
};

export default withRestrictionCheckDuringArchivingProcess(
    EditInstructionPresentation,
);
