import React from 'react';
import PropTypes from 'prop-types';
import StatisticsNumber from 'ProjectManager/Project/Preview/Statistics/StatisticsNumber';
import colors from 'Common/constants/colors';
import { CheckCircleRounded, WarningRounded } from '@material-ui/icons';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 20px;
    border-bottom: 1px solid ${colors.DARK_DIVIDER};
    margin-bottom: 20px;

    @media screen and (min-width: 520px) {
        flex: 0 0 147px;
        width: 147px;
        margin-bottom: 0;
        padding-bottom: 0;
        padding-right: 20px;
        border-bottom: 0 none;
        border-right: 1px solid ${colors.DARK_DIVIDER};
    }

    > div {
        &:first-child {
            position: relative;
            padding-bottom: 15px;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: calc(100% - 20px);
                max-width: 110px;
                height: 1px;
                background: ${colors.DARK_DIVIDER};
            }
        }

        &:nth-child(2) {
            padding-top: 15px;
        }
    }
`;

const StyledWarningRounded = styled(WarningRounded)`
    position: relative;
    top: -1px;
`;

const Statistics = ({ introduction }) => {
    const relevantParticipants = introduction.participants.filter(
        participant => participant.isRelevant,
    );

    const participantsWithPendingConfirmation = relevantParticipants.filter(
        participant => !participant.isConfirmed,
    );

    const participantsWithCompletedConfirmation = relevantParticipants.filter(
        participant => participant.isConfirmed,
    );

    const pendingConfirmations = participantsWithPendingConfirmation.length;
    const completedConfirmations = participantsWithCompletedConfirmation.length;

    return (
        <Wrapper>
            <StatisticsNumber
                small
                text="Ausstehend"
                number={pendingConfirmations}
                icon={
                    pendingConfirmations > 0 ? (
                        <StyledWarningRounded />
                    ) : undefined
                }
                color={colors.WARNING}
            />
            <StatisticsNumber
                small
                text="Abgeschlossen"
                number={completedConfirmations}
                icon={
                    completedConfirmations > 0 ? (
                        <CheckCircleRounded />
                    ) : undefined
                }
                color={colors.GREEN}
            />
        </Wrapper>
    );
};

Statistics.propTypes = {
    introduction: PropTypes.object.isRequired,
};

export default Statistics;
