import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import ContentSection from 'ProjectManager/DataManagement/Common/components/ContentSection';
import Question from 'ProjectManager/DataManagement/Questionnaire/components/Question/Question';
import MoreActionsButton from 'Common/components/MoreActionsButton/MoreActionsButton';
import MenuItem from 'Common/components/MoreActionsButton/MenuItem';
import { DeleteRounded, EditRounded } from '@material-ui/icons';
import CustomSubsectionDeleteModal from 'ProjectManager/DataManagement/Questionnaire/components/Subsection/components/CustomSubsectionDeleteModal';
import UpdateCustomSubsection from 'ProjectManager/DataManagement/Questionnaire/components/Subsection/components/UpdateCustomSubsection';
import QuestionCreationButton from 'ProjectManager/DataManagement/Questionnaire/components/QuestionCreation/components/QuestionCreationButton';
import { useWindowWidth } from '@react-hook/window-size';
import useWindowScroll from '@react-hook/window-scroll';
import getQuestionnaireSubsectionIdentifier from 'ProjectManager/DataManagement/Questionnaire/helpers/getQuestionnaireSubsectionIdentifier';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';

const Questions = styled.div``;

const StyledOuterBoxTitle = styled(OuterBoxTitle)`
    align-items: center;
`;

const Title = styled.div``;

const MoreActionsButtonWrapper = styled.div`
    margin-left: 7px;
`;

const FormWrapper = styled.div`
    width: 100%;
    max-width: 420px;
`;

const CreationButtonWrapper = styled.div`
    margin-top: 20px;
`;

const approximateStickyElementsHeight = 170;

const Subsection = ({ subsection, section }) => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);
    const isProject = dataManagementEntity === dataManagementEntities.PROJECT;

    const { status: projectStatus } = useRecoilValue(projectAtom);
    const isDeletingPossible = !isProject || projectStatus !== projectStatuses.ENDED;

    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

    const handleDeleteButtonClick = () => {
        setIsDeleteModalOpened(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpened(false);
    };

    const [isInEditMode, setIsInEditMode] = useState(false);

    const handleEditButtonClick = () => {
        setIsInEditMode(true);
    };

    const closeEditForm = () => {
        setIsInEditMode(false);
    };

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 940;

    const scrollY = useWindowScroll();

    const handleWizardOpen = () => {
        if (isMobile) {
            const subsectionId = getQuestionnaireSubsectionIdentifier(
                subsection,
            );
            const addButtonElementId = `addCustomQuestionButtonWrapper-${subsectionId}`;

            const distanceToAddButton = document
                .getElementById(addButtonElementId)
                .getBoundingClientRect().top;

            // The timeout is to ensure that the state will be set
            // and the distance to the button will be calculated properly.
            setTimeout(() => {
                window.scroll({
                    top:
                        scrollY +
                        distanceToAddButton -
                        approximateStickyElementsHeight -
                        15, // Ensure to have some spacing at the top
                    left: 0,
                    behavior: 'smooth',
                });
            }, 5);
        }
    };

    const subsectionId = getQuestionnaireSubsectionIdentifier(subsection);

    return (
        <>
            <ContentSection id={`questionnaireSubsection-${subsectionId}`}>
                {isInEditMode ? (
                    <FormWrapper>
                        <UpdateCustomSubsection
                            section={section}
                            subsection={subsection}
                            onCancel={closeEditForm}
                            onSave={closeEditForm}
                        />
                    </FormWrapper>
                ) : (
                    <StyledOuterBoxTitle>
                        <Title>{subsection.name}</Title>
                        {subsection.isCustom && (
                            <MoreActionsButtonWrapper>
                                <MoreActionsButton>
                                    <MenuItem
                                        icon={<EditRounded />}
                                        type="button"
                                        onClick={handleEditButtonClick}
                                    >
                                        Bearbeiten
                                    </MenuItem>
                                    <MenuItem
                                        icon={<DeleteRounded />}
                                        type="button"
                                        onClick={handleDeleteButtonClick}
                                        disabled={!isDeletingPossible}
                                        tooltip={
                                            !isDeletingPossible
                                                ? 'Löschen nicht erlaubt. Projekt ist im Status Beendet.'
                                                : ''
                                        }
                                    >
                                        Löschen
                                    </MenuItem>
                                </MoreActionsButton>
                            </MoreActionsButtonWrapper>
                        )}
                    </StyledOuterBoxTitle>
                )}
                <Questions>
                    {subsection.questions.map(question => (
                        <Question key={question.id} question={question} />
                    ))}
                </Questions>
                {subsection.isCustom && (
                    <CreationButtonWrapper
                        id={`addCustomQuestionButtonWrapper-${subsectionId}`}
                    >
                        <QuestionCreationButton
                            subsection={subsection}
                            onWizardOpen={handleWizardOpen}
                        />
                    </CreationButtonWrapper>
                )}
            </ContentSection>

            {isDeleteModalOpened && (
                <CustomSubsectionDeleteModal
                    subsection={subsection}
                    onCancel={closeDeleteModal}
                    onDelete={closeDeleteModal}
                />
            )}
        </>
    );
};

Subsection.propTypes = {
    subsection: PropTypes.object.isRequired,
    section: PropTypes.object.isRequired,
};

export default Subsection;
