import React, { useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';
import { useRecoilState } from 'recoil';
import { SearchRounded } from '@material-ui/icons';
import Input from 'Common/components/Form/Fields/Input';
import projectsSearchTermAtom from 'ProjectManager/Project/List/recoil/projectsSearchTermAtom';
import colors from 'Common/constants/colors';
import { debounce } from 'lodash';

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-left: 10px;
`;

const transition = '200ms cubic-bezier(0, 0, 0.2, 1)';

const SearchField = styled(Input)`
    &.MuiTextField-root {
        transition: width ${transition};

        .MuiFilledInput-root {
            height: 50px;
            border: 2px solid transparent;
            transition: background-color ${transition};
            transition: border-color ${transition};
        }

        .MuiFilledInput-input {
            font-size: 14px;
            line-height: 14px;
        }

        .MuiFilledInput-adornedEnd {
            padding-right: 11px;
            transition: padding ${transition};
        }

        .MuiInputAdornment-root {
            transform: translateX(0);
            transition: transform ${transition};
        }

        ${ifProp(
            '$isCollapsed',
            css`
                width: 50px;

                .MuiFilledInput-root {
                    background-color: transparent;
                    border-color: ${colors.SEARCH_FIELD_BORDER};
                    cursor: pointer;

                    &:hover {
                        background-color: transparent;
                    }
                }

                .MuiFormLabel-root {
                    display: none;
                }

                .MuiFilledInput-input {
                    cursor: pointer;
                }

                .MuiFilledInput-adornedEnd {
                    padding-right: 6px;
                }

                .MuiInputAdornment-root {
                    pointer-events: none;
                    transform: translateX(-6px);
                }
            `,
        )};
    }
`;

const Search = () => {
    const [recoilSearchTerm, setRecoilSearchTerm] = useRecoilState(
        projectsSearchTermAtom,
    );
    const [searchTerm, setSearchTerm] = useState(recoilSearchTerm);

    const [isFocused, setIsFocused] = useState(false);

    // noinspection JSCheckFunctionSignatures
    const debouncedSetRecoilSearchTerm = useCallback(
        debounce(setRecoilSearchTerm, 350, {
            leading: true,
            trailing: true,
        }),
        [setRecoilSearchTerm],
    );

    const handleSearchTermChange = e => {
        setSearchTerm(e.target.value);

        // noinspection JSValidateTypes
        debouncedSetRecoilSearchTerm(e.target.value);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const isCollapsed = !isFocused && searchTerm === '';

    return (
        <Wrapper>
            <SearchField
                id="projectsListTableSearchTerm"
                name="projectsListTableSearchTerm"
                label={isCollapsed ? '' : 'Suche'}
                value={searchTerm}
                onChange={handleSearchTermChange}
                icon={SearchRounded}
                onFocus={handleFocus}
                onBlur={handleBlur}
                $isCollapsed={isCollapsed}
            />
        </Wrapper>
    );
};

export default Search;
