import { selector } from 'recoil';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';

const isTemplateEmployeeSelectionStepCompletedSelector = selector({
    key: 'projectManagement_isTemplateEmployeeSelectionStepCompleted',
    get: ({ get }) => {
        const { id, participatingEmployees } = get(templateAtom);

        return Boolean(id) && participatingEmployees.length > 0;
    },
});

export default isTemplateEmployeeSelectionStepCompletedSelector;
