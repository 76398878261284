import React from 'react';
import Button from 'Common/components/Button';
import { useRecoilValue } from 'recoil';
import FormField from 'Common/components/Form/FormField';
import Select from 'Common/components/Form/Fields/Select';
import templateGroupsSelector from 'ProjectManager/Template/List/recoil/templateGroupsSelector';

const useNewTemplateGroupSelectionModal = ({
    group,
    value,
    onChange,
    onConfirm,
    onBackButtonClick,
    isLoading,
}) => {
    const groups = useRecoilValue(templateGroupsSelector);

    const groupOptions = groups
        .filter(iteratedGroup => iteratedGroup.id !== group.id)
        .map(iteratedGroup => ({
            value: iteratedGroup.id,
            label: iteratedGroup.name,
        }));

    return {
        props: {
            buttons: (
                <>
                    <Button text dark type="button" onClick={onBackButtonClick}>
                        Zurück
                    </Button>
                    <Button
                        type="button"
                        disabled={value === null || isLoading}
                        onClick={() => onConfirm(value.value)}
                        isLoading={isLoading}
                    >
                        Löschen und verschieben
                    </Button>
                </>
            ),
        },
        content: (
            <>
                <p>
                    Diese Projektkategorie beinhaltet Vorlagen. Bitte
                    Entscheide, in welche Projektkategorie diese Vorlagen
                    übertragen werden sollen
                </p>
                <FormField>
                    <Select
                        label="Projektkategorie"
                        id="templateGroup"
                        name="templateGroup"
                        value={value}
                        onChange={onChange}
                        options={groupOptions}
                        menuPosition="fixed"
                        menuPortalTarget={document.body}
                    />
                </FormField>
            </>
        ),
    };
};

export default useNewTemplateGroupSelectionModal;
