import React from 'react';
import styled from 'styled-components/macro';
import BackButton from 'Application/components/Header/BackButton/BackButton';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import OuterBox from 'Common/components/Boxes/OuterBox';
import { Helmet } from 'react-helmet-async';
import Projects from 'ProjectManager/Project/List/components/Projects';
import Templates from 'ProjectManager/Template/List/components/Templates';

const Wrapper = styled.div`
    @media screen and (min-width: 1180px) {
        display: flex;
    }
`;

const ProjectsWrapper = styled.div`
    margin-bottom: 10px;

    @media screen and (min-width: 1180px) {
        flex: 1;
        min-width: 0;
        margin-right: 10px;
        margin-bottom: 0;
    }
`;

const TemplatesWrapper = styled(OuterBox)`
    display: none;
    padding: 20px;

    @media screen and (min-width: 1180px) {
        display: block;
        position: sticky;
        top: 80px;
        max-height: calc(100vh - 110px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        width: 350px;
        flex: 0 0 350px;
    }
`;

const ProjectManagerOverview = () => (
    <>
        <Helmet>
            <title>Projektübersicht</title>
        </Helmet>
        <BackButton to="/dashboard" />
        <Breadcrumb isActive>Projektübersicht</Breadcrumb>
        <Wrapper>
            <ProjectsWrapper>
                <Projects />
            </ProjectsWrapper>
            <TemplatesWrapper>
                <Templates />
            </TemplatesWrapper>
        </Wrapper>
    </>
);

export default ProjectManagerOverview;
