import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useProjectFileFunctions from 'ProjectManager/DataManagement/Files/hooks/fileFunctions/useProjectFileFunctions';
import useTemplateFileFunctions from 'ProjectManager/DataManagement/Files/hooks/fileFunctions/useTemplateFileFunctions';

const useFileFunctions = () => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useProjectFileFunctions();
    const dataFromTemplate = useTemplateFileFunctions();

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return {};
};

export default useFileFunctions;
