import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'Common/components/Button';
import { CloseRounded, ImageRounded } from '@material-ui/icons';
import Draggable from 'react-draggable';
import InteractableOuterBox from 'Common/components/Boxes/InteractableOuterBox';

const PreviewButton = styled(Button)`
    padding-left: 0;
`;

const DraggableImageWrapper = styled.div`
    position: fixed;
    z-index: 9999;
    cursor: move;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4), 0 0 0 1px rgba(0, 0, 0, 0.17);
    border-radius: 6px;
    overflow: hidden;

    img {
        display: block;
        width: 100%;
        max-width: 550px;
        min-width: 350px;
        pointer-events: none;
    }
`;

const DraggableBoxCloseButton = styled(InteractableOuterBox).attrs({
    as: 'button',
})`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    border: 0 none;
    outline: 0 none;
    cursor: pointer;

    svg {
        margin-right: 0;
    }
`;

const ImagePreview = ({ src }) => {
    const [isInPreviewMode, setIsInPreviewMode] = useState(false);

    const handleButtonClick = () => {
        setIsInPreviewMode(true);
    };

    const closePreviewMode = () => {
        setIsInPreviewMode(false);
    };

    return (
        <>
            <PreviewButton
                underline
                icon={<ImageRounded />}
                type="button"
                onClick={handleButtonClick}
                disabled={isInPreviewMode}
            >
                Bild ansehen
            </PreviewButton>
            {isInPreviewMode && (
                <Draggable cancel=".draggable-cancel">
                    <DraggableImageWrapper>
                        <DraggableBoxCloseButton
                            className="draggable-cancel"
                            onClick={closePreviewMode}
                        >
                            <CloseRounded />
                        </DraggableBoxCloseButton>
                        <img src={src} alt="" />
                    </DraggableImageWrapper>
                </Draggable>
            )}
        </>
    );
};

ImagePreview.propTypes = {
    src: PropTypes.string.isRequired,
};

export default ImagePreview;
