import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import giveTemplateQuestionSingleChoiceAnswer from 'ProjectManager/Template/Common/api/dataManagement/questionnaire/question/answer/giveTemplateQuestionSingleChoiceAnswer';
import withSingleChoiceAnswerForQuestion
    from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/answer/withSingleChoiceAnswerForQuestion';

const useTemplateGiveSingleChoiceAnswerFunction = (
    questionId,
    isCustomQuestion,
) => {
    const [{ id: templateId }, setTemplate] = useRecoilState(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const giveSingleChoiceAnswer = useCallback(
        async selectedAnswerId => {
            await giveTemplateQuestionSingleChoiceAnswer(
                templateId,
                questionId,
                isCustomQuestion,
                selectedAnswerId,
                source.token,
            );

            setTemplate(
                withSingleChoiceAnswerForQuestion(
                    questionId,
                    isCustomQuestion,
                    selectedAnswerId,
                ),
            );
        },
        [templateId, questionId, isCustomQuestion, setTemplate, source.token],
    );

    return giveSingleChoiceAnswer;
};

export default useTemplateGiveSingleChoiceAnswerFunction;
