import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useProjectEmployeeSelectionData from 'ProjectManager/DataManagement/EmployeeSelection/hooks/useProjectEmployeeSelectionData';
import useTemplateEmployeeSelectionData from 'ProjectManager/DataManagement/EmployeeSelection/hooks/useTemplateEmployeeSelectionData';

const useEmployeeSelectionData = () => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useProjectEmployeeSelectionData();
    const dataFromTemplate = useTemplateEmployeeSelectionData();

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return {};
};

export default useEmployeeSelectionData;
