const withoutPoint = point => prevPoints => {
    const index = prevPoints.indexOf(point);

    if (index === -1) {
        return prevPoints;
    }

    const newPoints = [...prevPoints];

    newPoints.splice(index, 1);

    return newPoints;
};

export default withoutPoint;
