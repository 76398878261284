import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import getCurrentEmployee from 'Employee/api/getCurrentEmployee';
import Input from 'Common/components/Form/Fields/Input';
import signInstructionAssignmentWithPassword from 'Education/api/instruction-assignment/signInstructionAssignmentWithPassword';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import dataUriToBlob from 'Common/utils/dataUriToBlob';
import signInstructionAssignmentWithSignature from 'Education/api/instruction-assignment/signInstructionAssignmentWithSignature';
import SignaturePad from 'Common/components/SignaturePad';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import axios from 'axios';
import RetryLoading from 'Common/components/RetryLoading';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';

const Content = styled.div``;

const AssignmentSignatureModal = ({
    assignmentId,
    isOpen,
    onCancel,
    onSuccess,
}) => {
    const [isConfirming, setIsConfirming] = useState(false);
    const [employeePassword, setEmployeePassword] = useState('');

    const signaturePad = useRef();

    const currentEmployeeRequest = useCallback(
        cancelToken => getCurrentEmployee([], cancelToken),
        [],
    );

    const {
        data: currentEmployee,
        loadData: loadCurrentEmployee,
        isLoading,
        hasError,
    } = useAxiosRequest(currentEmployeeRequest, null);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirm = async () => {
        setIsConfirming(true);

        if (currentEmployee.isAutomaticSigningOfInstructionsAllowed) {
            try {
                await signInstructionAssignmentWithPassword(
                    assignmentId,
                    employeePassword,
                    source.token,
                );

                await onSuccess();
            } catch (error) {
                if (!axios.isCancel(error)) {
                    notify('Dein Passwort ist falsch', {
                        type: toast.TYPE.ERROR,
                    });
                }
            }
        } else {
            if (signaturePad.current.isEmpty()) {
                notify('Das Unterschriftsfeld darf nicht leer sein', {
                    type: toast.TYPE.ERROR,
                });
            } else {
                try {
                    await signInstructionAssignmentWithSignature(
                        assignmentId,
                        dataUriToBlob(signaturePad.current.toDataURL()),
                        source.token,
                    );

                    await onSuccess();
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        notify(
                            'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                            {
                                type: toast.TYPE.ERROR,
                            },
                        );
                    }
                }
            }
        }

        setIsConfirming(false);
    };

    const handleEmployeePasswordChange = e => {
        setEmployeePassword(e.target.value);
    };

    return (
        <Modal
            isOpen={isOpen}
            isLoading={isLoading}
            onRequestClose={isConfirming ? undefined : onCancel}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onCancel}
                        disabled={isConfirming}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isConfirming}
                        onClick={handleConfirm}
                        disabled={isConfirming}
                    >
                        Bestätigen
                    </Button>
                </>
            }
        >
            {!isLoading &&
                (hasError ? (
                    <RetryLoading onRetry={loadCurrentEmployee} />
                ) : (
                    <Content>
                        {currentEmployee.isAutomaticSigningOfInstructionsAllowed ? (
                            <>
                                <ModalTitle>Passwort</ModalTitle>
                                <Input
                                    type="password"
                                    label="Geben Sie Ihr Passwort ein"
                                    value={employeePassword}
                                    onChange={handleEmployeePasswordChange}
                                    autoComplete="off"
                                />
                            </>
                        ) : (
                            <>
                                <ModalTitle>Unterschrift</ModalTitle>
                                <SignaturePad ref={signaturePad} />
                            </>
                        )}
                    </Content>
                ))}
        </Modal>
    );
};

AssignmentSignatureModal.propTypes = {
    assignmentId: PropTypes.number.isRequired,
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default AssignmentSignatureModal;
