import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import colors from 'Common/constants/colors';
import InnerBox from 'Common/components/Boxes/InnerBox';

const TableWrapper = styled.div``;

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

export const Table = ({ wrapperRef, children, ...props }) => (
    <TableWrapper ref={wrapperRef}>
        <StyledTable {...props}>{children}</StyledTable>
    </TableWrapper>
);

export const TableHead = styled.thead``;

export const TableHeadRow = styled.tr``;

export const TableHeadCell = styled.th`
    position: sticky;
    padding: 10px 15px;
    background: ${colors.OUTER_BOX_BACKGROUND};
    color: ${colors.GRAY};
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    text-align: left;
    z-index: 5;
`;

export const TableBody = styled.tbody``;

export const TableBodyCell = styled.td`
    padding: 10px 15px;
    color: ${colors.LIGHTER_GRAY};
    font-size: 16px;
    line-height: 25px;
`;

export const TableBodyRow = styled.tr`
    ${ifProp(
        'hasHiddenColumns',
        css`
            ${TableBodyCell} {
                padding-top: 20px;
            }
        `,
        css`
            border-bottom: 1px solid ${colors.DARK_DIVIDER};
        `,
    )};

    &:hover {
        background: ${colors.INNER_BOX_BACKGROUND};
    }

    &:last-child {
        border-bottom: 0 none;
    }
`;

export const HiddenColumnsTableBodyRow = styled(TableBodyRow)`
    &:hover {
        background: none;
    }
`;

export const HiddenColumnsTableBodyCell = styled(TableBodyCell)`
    padding-bottom: 20px;
`;

export const HiddenColumnProperties = styled(InnerBox)`
    display: flex;
    flex-wrap: wrap;
    padding: 1px 21px;
`;

export const HiddenColumnProperty = styled.div`
    flex: 1 0 auto;
    min-width: 150px;
    max-width: 100%;
    margin-left: -1px;
    margin-top: -1px;
    padding: 10px 0;
    border-bottom: 1px solid ${colors.DARK_DIVIDER};

    &:last-child {
        border-bottom: 0;
    }
`;

export const HiddenColumnLabel = styled.div`
    margin-bottom: 5px;
    color: ${colors.GRAY};
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
`;

export const HiddenColumnValue = styled.div`
    color: ${colors.LIGHTER_GRAY};
    font-size: 16px;
    line-height: 25px;
`;

export const TableRowActions = styled.div``;

const SortArrowWrapper = styled.span`
    display: inline-block;
    vertical-align: middle;
`;

const SortArrow = ({ column }) => (
    <SortArrowWrapper>
        {column.isSortedDesc ? (
            <ArrowDropDown fontSize="small" />
        ) : (
            <ArrowDropUp fontSize="small" />
        )}
    </SortArrowWrapper>
);

SortArrow.propTypes = {
    column: PropTypes.object.isRequired,
};

const DefaultTableHeadCell = ({ column, before, after, ...props }) => (
    <TableHeadCell
        {...props}
        {...(column.getSortByToggleProps
            ? column.getHeaderProps(column.getSortByToggleProps())
            : column.getHeaderProps())}
    >
        {before}
        {column.render('Header')}
        {column.isSorted && <SortArrow column={column} />}
        {after}
    </TableHeadCell>
);

DefaultTableHeadCell.propTypes = {
    column: PropTypes.object.isRequired,
};

export { DefaultTableHeadCell };

const DefaultTableHeadRow = ({ headerGroup, before, after, ...props }) => (
    <TableHeadRow {...props} {...headerGroup.getHeaderGroupProps()}>
        {before}
        {headerGroup.headers.map(column => (
            <DefaultTableHeadCell
                column={column}
                {...(column.getSortByToggleProps
                    ? column.getHeaderProps(column.getSortByToggleProps())
                    : column.getHeaderProps())}
            />
        ))}
        {after}
    </TableHeadRow>
);

DefaultTableHeadRow.propTypes = {
    headerGroup: PropTypes.object.isRequired,
};

export { DefaultTableHeadRow };

const DefaultTableHead = ({ headerGroups, before, after, ...props }) => (
    <TableHead {...props}>
        {before}
        {headerGroups.map(headerGroup => (
            <DefaultTableHeadRow
                headerGroup={headerGroup}
                {...headerGroup.getHeaderGroupProps()}
            />
        ))}
        {after}
    </TableHead>
);

DefaultTableHead.propTypes = {
    headerGroups: PropTypes.array.isRequired,
};

export { DefaultTableHead };

const DefaultHiddenColumnProperties = ({
    row,
    hiddenColumns,
    before,
    after,
    ...props
}) => (
    <HiddenColumnProperties {...props}>
        {before}
        {row.allCells
            .filter(cell => hiddenColumns.indexOf(cell.column.id) !== -1)
            .map(cell => {
                if (!cell.column.Header && !cell.value) {
                    return null;
                }

                return (
                    <HiddenColumnProperty key={cell.column.id}>
                        <HiddenColumnLabel>
                            {cell.column.render('Header')}
                        </HiddenColumnLabel>
                        <HiddenColumnValue>
                            {cell.render('Cell')}
                        </HiddenColumnValue>
                    </HiddenColumnProperty>
                );
            })}
        {after}
    </HiddenColumnProperties>
);

DefaultHiddenColumnProperties.propTypes = {
    row: PropTypes.object.isRequired,
    hiddenColumns: PropTypes.array.isRequired,
};

export { DefaultHiddenColumnProperties };

const DefaultHiddenColumnsTableBodyRow = ({
    row,
    hiddenColumns,
    visibleColumns,
    before,
    after,
    ...props
}) => (
    <HiddenColumnsTableBodyRow {...props}>
        <HiddenColumnsTableBodyCell
            colSpan={visibleColumns.length > 1 ? visibleColumns.length : 2}
        >
            {before}
            <DefaultHiddenColumnProperties
                row={row}
                hiddenColumns={hiddenColumns}
            />
            {after}
        </HiddenColumnsTableBodyCell>
    </HiddenColumnsTableBodyRow>
);

DefaultHiddenColumnsTableBodyRow.propTypes = {
    row: PropTypes.object.isRequired,
    hiddenColumns: PropTypes.array.isRequired,
    visibleColumns: PropTypes.array.isRequired,
};

export { DefaultHiddenColumnsTableBodyRow };

const DefaultTableBodyCell = ({ cell, before, after, ...props }) => (
    <TableBodyCell {...props} {...cell.getCellProps()}>
        {before}
        {cell.render('Cell')}
        {after}
    </TableBodyCell>
);

DefaultTableBodyCell.propTypes = {
    cell: PropTypes.object.isRequired,
};

export { DefaultTableBodyCell };

const DefaultTableBodyRow = ({ row, before, after, ...props }) => (
    <TableBodyRow {...props} {...row.getRowProps()}>
        {before}
        {row.cells.map(cell => (
            <DefaultTableBodyCell cell={cell} {...cell.getCellProps()} />
        ))}
        {after}
    </TableBodyRow>
);

DefaultTableBodyRow.propTypes = {
    row: PropTypes.object.isRequired,
};

export { DefaultTableBodyRow };

const DefaultTableBody = ({
    rows,
    hiddenColumns,
    visibleColumns,
    getTableBodyProps,
    prepareRow,
    before,
    after,
    ...props
}) => (
    <TableBody {...props} {...getTableBodyProps()}>
        {before}
        {rows.map(row => {
            prepareRow(row);

            const hasHiddenColumns =
                !!hiddenColumns && hiddenColumns.length > 0;

            return (
                <Fragment key={`${row.getRowProps().key}_fragment`}>
                    <DefaultTableBodyRow
                        row={row}
                        hasHiddenColumns={hasHiddenColumns}
                    />
                    {hasHiddenColumns && (
                        <DefaultHiddenColumnsTableBodyRow
                            row={row}
                            hiddenColumns={hiddenColumns}
                            visibleColumns={visibleColumns}
                        />
                    )}
                </Fragment>
            );
        })}
        {after}
    </TableBody>
);

DefaultTableBody.defaultProps = {
    hiddenColumns: null,
    visibleColumns: null,
};

DefaultTableBody.propTypes = {
    rows: PropTypes.array.isRequired,
    hiddenColumns: PropTypes.array,
    visibleColumns: PropTypes.array,
    getTableBodyProps: PropTypes.func.isRequired,
    prepareRow: PropTypes.func.isRequired,
};

export { DefaultTableBody };

const DefaultTable = ({
    headerGroups,
    rows,
    hiddenColumns,
    visibleColumns,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    ...props
}) => (
    <Table {...props} {...getTableProps()}>
        <DefaultTableHead headerGroups={headerGroups} />
        <DefaultTableBody
            rows={rows}
            hiddenColumns={hiddenColumns}
            visibleColumns={visibleColumns}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
        />
    </Table>
);

DefaultTable.defaultProps = {
    hiddenColumns: null,
    visibleColumns: null,
};

DefaultTable.propTypes = {
    headerGroups: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    hiddenColumns: PropTypes.array,
    visibleColumns: PropTypes.array,
    getTableProps: PropTypes.func.isRequired,
    getTableBodyProps: PropTypes.func.isRequired,
    prepareRow: PropTypes.func.isRequired,
};

export { DefaultTable };
