import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import LinkToLibraryInstructions from 'Education/components/LinkToLibraryInstructions';
import Input from 'Common/components/Form/Fields/Input';
import { AddRounded, SearchRounded } from '@material-ui/icons';
import OuterBox from 'Common/components/Boxes/OuterBox';
import InstructionAreaCreateModal from 'Education/components/instruction-area/InstructionAreaCreateModal';

const Wrapper = styled.div`
    margin-bottom: 10px;

    @media screen and (min-width: 940px) {
        align-self: flex-start;
        position: sticky;
        top: 134px;
        width: 300px;
        flex: 0 0 300px;
        max-height: calc(100vh - 206px);
        margin-right: 10px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
`;

const CreateButton = styled(OuterBox).attrs({
    as: 'button',
})`
    display: flex;
    width: 100%;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    border: 0 none;
    outline: 0;
    cursor: pointer;
`;

const SidebarContent = styled(OuterBox)`
    margin-top: 10px;
    padding: 20px;
`;

const SearchWrapper = styled.div`
    margin-top: 35px;
`;

const InstructionAreaSidebar = ({
    searchTerm,
    onSearchTermChange,
    onAreaCreate,
}) => {
    const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);

    const handleCreateButtonClick = () => {
        setIsCreateModalOpened(true);
    };

    const handleCreateCancel = () => {
        setIsCreateModalOpened(false);
    };

    const handleCreateSuccess = async newInstructionArea => {
        await onAreaCreate(newInstructionArea);
        setIsCreateModalOpened(false);
    };

    const handleSearchInputChange = e => {
        onSearchTermChange(e.target.value);
    };

    return (
        <Wrapper>
            <CreateButton onClick={handleCreateButtonClick}>
                Bereich
                <AddRounded />
            </CreateButton>
            <SidebarContent>
                <LinkToLibraryInstructions />
                <SearchWrapper>
                    <Input
                        name="search"
                        label="Suche"
                        icon={SearchRounded}
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                    />
                </SearchWrapper>
            </SidebarContent>
            {isCreateModalOpened && (
                <InstructionAreaCreateModal
                    isOpen
                    onCancel={handleCreateCancel}
                    onSuccess={handleCreateSuccess}
                />
            )}
        </Wrapper>
    );
};

InstructionAreaSidebar.propTypes = {
    searchTerm: PropTypes.string.isRequired,
    onSearchTermChange: PropTypes.func.isRequired,
    onAreaCreate: PropTypes.func.isRequired,
};

export default InstructionAreaSidebar;
