import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import {
    AddPhotoAlternateRounded,
    PhotoCameraRounded,
} from '@material-ui/icons';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';
import { ifProp } from 'styled-tools';
import { useDropzone } from 'react-dropzone';
import Loader from 'Common/components/Loader';
import PhotoDeleteModal from 'ProjectManager/Project/DataManagement/GeneralInformation/Photo/PhotoDeleteModal';
import uploadProjectPhoto from 'ProjectManager/Project/Common/api/dataManagement/photo/uploadProjectPhoto';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import getProject from 'ProjectManager/Project/Common/api/getProject';
import PhotoUploadedImage from 'ProjectManager/Project/DataManagement/GeneralInformation/Photo/PhotoUploadedImage';
import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import withPhoto from 'ProjectManager/Project/Common/recoil/project/modifiers/withPhoto';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;

    ${ifProp(
        'disabled',
        css`
            opacity: 0.76;
            pointer-events: none;
        `,
    )};
`;

const Box = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
    outline: 0 none;
    border-radius: 6px;
    background: ${colors.DIRTY_WHITE};
    color: ${colors.LIGHTER_GRAY};
    cursor: pointer;
    overflow: hidden;

    ${ifProp(
        'hasImage',
        css`
            background: none;
        `,
    )};

    ${ifProp(
        'disabled',
        css`
            cursor: default;
        `,
    )};
`;

const BoxText = styled.div`
    font-weight: 600;
    font-size: 15px;
    line-height: 23px;
    text-transform: uppercase;
`;

const NoPhotoIcon = styled(PhotoCameraRounded)`
    font-size: 56px !important;
`;

const UploadingOverlay = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
`;

const UploadButton = styled(Button)`
    padding-left: 0;
    padding-right: 0;
`;

const Photo = () => {
    const [{ id: projectId, photo }, setProject] = useRecoilState(projectAtom);

    const [isUploading, setIsUploading] = useState(false);
    const [image, setImage] = useState(photo ?? null);

    const hasImage = Boolean(image);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const { open, getRootProps, getInputProps } = useDropzone({
        multiple: false,
        accept: 'image/jpg, image/jpeg, image/png',
        noClick: hasImage || isUploading,
        noDrag: hasImage || isUploading,
        onDrop: async acceptedFiles => {
            setIsUploading(true);

            if (acceptedFiles && acceptedFiles[0]) {
                const file = acceptedFiles[0];

                try {
                    await uploadProjectPhoto(projectId, file, source.token);

                    const projectResponse = await getProject(
                        projectId,
                        [],
                        source.token,
                    );

                    const photoUrl = projectResponse.data.photo;

                    setImage(photoUrl);
                    setProject(withPhoto(photoUrl));

                    notify('Das Bild wurde erfolgreich hochgeladen', {
                        type: toast.TYPE.SUCCESS,
                    });
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        notify('Das Bild konnte nicht hochgeladen werden', {
                            type: toast.TYPE.ERROR,
                        });
                    }
                }
            } else {
                notify('Dieser Dateityp wird nicht unterstützt', {
                    type: toast.TYPE.ERROR,
                });
            }

            setIsUploading(false);
        },
    });

    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

    const handleDeleteButtonClick = () => {
        setIsDeleteModalOpened(true);
    };

    const handleCancelDelete = () => {
        setIsDeleteModalOpened(false);
    };

    const handleDelete = async () => {
        setImage(null);
        setIsDeleteModalOpened(false);
        setProject(withPhoto(null));
    };

    const isClickUploadAllowed = projectId && !hasImage && !isUploading;

    return (
        <>
            <Wrapper disabled={!projectId}>
                <Box
                    {...getRootProps()}
                    disabled={!isClickUploadAllowed}
                    hasImage={hasImage}
                >
                    <input {...getInputProps()} />
                    {hasImage ? (
                        <PhotoUploadedImage
                            image={image}
                            isLoading={isUploading}
                            onUploadNewButtonClick={open}
                            onDeleteButtonClick={handleDeleteButtonClick}
                        />
                    ) : (
                        <>
                            <NoPhotoIcon />
                            <BoxText>Kein Photo</BoxText>
                        </>
                    )}
                    {isUploading && (
                        <UploadingOverlay>
                            <Loader size={48} />
                        </UploadingOverlay>
                    )}
                </Box>
                {!hasImage && (
                    <UploadButton
                        underline
                        icon={<AddPhotoAlternateRounded />}
                        type="button"
                        onClick={open}
                        disabled={!isClickUploadAllowed}
                    >
                        Bild hochladen
                    </UploadButton>
                )}
            </Wrapper>
            {isDeleteModalOpened && (
                <PhotoDeleteModal
                    onCancel={handleCancelDelete}
                    onSuccess={handleDelete}
                />
            )}
        </>
    );
};

export default Photo;
