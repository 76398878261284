import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import Input from 'Common/components/Form/Fields/Input';
import {
    ChevronLeftRounded,
    ChevronRightRounded,
    CloseRounded,
} from '@material-ui/icons';
import Button from 'Common/components/Button';
import format from 'date-fns/format';
import getYear from 'date-fns/getYear';
import eachMonthOfInterval from 'date-fns/eachMonthOfInterval';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';
import de from 'date-fns/locale/de';
import { range } from 'lodash';
import createNewDate from 'Common/utils/createNewDate';
import Select from 'Common/components/Form/Fields/Select';
import reactSelectCustomComponents from 'Common/components/Form/Fields/reactSelectCustomComponents';
import { components as reactSelectComponents } from 'react-select';
import parseDate from 'Common/utils/parseDate';
import formatDateAsISO from 'Common/utils/formatDateAsISO';

registerLocale('de', de);

const Wrapper = styled.div`
    position: relative;
    width: 100%;
`;

const ClearButton = styled(Button)`
    position: absolute;
    top: 10px;
    right: 5px;

    svg {
        font-size: 18px !important;
    }
`;

const ChevronButton = styled(Button)`
    padding: 0;
`;

const CustomInput = forwardRef(({ isClearable, onClear, ...props }, ref) => (
    <>
        <Input inputRef={ref} {...props} />
        {isClearable && Boolean(props.value) && (
            <ClearButton
                dark
                iconOnly
                icon={<CloseRounded />}
                type="button"
                onClick={onClear}
                tooltip="entfernen"
            />
        )}
    </>
));

const CustomHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const CustomHeaderControl = styled.div`
    display: flex;
    align-items: center;

    &:first-child {
        margin-right: 10px;
    }

    &:last-child {
        margin-left: 10px;
    }
`;

const CustomHeaderLabel = styled.div``;

// Hide the IndicatorsContainer from the control
// and only render the ValueContainer.
const CustomReactSelectControl = ({ children, ...props }) => (
    <reactSelectComponents.Control {...props}>
        {children[0]}
    </reactSelectComponents.Control>
);

// Instead of rendering the standard ValueContainer's children,
// including the input, render only the selected value's label.
// eslint-disable-next-line
const CustomReactSelectSingleValue = ({ children, ...props }) => {
    const values = props.getValue();
    const selectedOption = values.length > 0 ? values[0] : null;

    return (
        <CustomHeaderLabel>{selectedOption?.label ?? '-'}</CustomHeaderLabel>
    );
};

const CustomReactSelect = props => (
    <Select
        isClearable={false}
        isSearchable={false}
        components={{
            ...reactSelectCustomComponents,
            Control: CustomReactSelectControl,
            SingleValue: CustomReactSelectSingleValue,
        }}
        additionalStyles={{
            control: provided => ({
                ...provided,
                minHeight: 'initial',
                paddingLeft: 0,
            }),
            valueContainer: provided => ({
                ...provided,
                width: 80,
                justifyContent: 'center',
                marginBottom: 0,
                paddingLeft: undefined,
                padding: '5px 15px',
            }),
        }}
        {...props}
    />
);

const renderCustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    decreaseYear,
    increaseYear,
    prevYearButtonDisabled,
    nextYearButtonDisabled,
    changeMonth,
    changeYear,
}) => {
    const selectedMonth = parseInt(format(date, 'M'));
    const selectedYear = parseInt(format(date, 'yyyy'));

    const now = createNewDate();

    const minYears = 1900;
    const maxYears = getYear(now) + 51;

    const yearOptions = range(minYears, maxYears, 1).map(year => ({
        value: year,
        label: year.toString(),
    }));

    const monthOptions = eachMonthOfInterval({
        start: startOfYear(now),
        end: endOfYear(now),
    }).map(monthDate => ({
        value: parseInt(format(monthDate, 'M')),
        label: format(monthDate, 'MMM', { locale: de }),
    }));

    return (
        <CustomHeader key={format(date, 'dd.MM.yyyy')}>
            <CustomHeaderControl>
                <ChevronButton
                    dark
                    iconOnly
                    icon={<ChevronLeftRounded />}
                    type="button"
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                />
                <CustomReactSelect
                    name="month"
                    value={
                        monthOptions.find(
                            option => option.value === selectedMonth,
                        ) ?? null
                    }
                    onChange={option => {
                        // The expected value is the index of the month, instead of the
                        // month number, e.g. 3 for April, instead of 4.
                        // Hence the subtraction by 1.
                        changeMonth(option.value - 1);
                    }}
                    options={monthOptions}
                />
                <ChevronButton
                    dark
                    iconOnly
                    icon={<ChevronRightRounded />}
                    type="button"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                />
            </CustomHeaderControl>
            <CustomHeaderControl>
                <ChevronButton
                    dark
                    iconOnly
                    icon={<ChevronLeftRounded />}
                    type="button"
                    onClick={decreaseYear}
                    disabled={prevYearButtonDisabled}
                />
                <CustomReactSelect
                    name="year"
                    value={
                        yearOptions.find(year => year.value === selectedYear) ??
                        null
                    }
                    onChange={option => {
                        changeYear(option.value);
                    }}
                    options={yearOptions}
                />
                <ChevronButton
                    dark
                    iconOnly
                    icon={<ChevronRightRounded />}
                    type="button"
                    onClick={increaseYear}
                    disabled={nextYearButtonDisabled}
                />
            </CustomHeaderControl>
        </CustomHeader>
    );
};

const DatePicker = ({
    label,
    value,
    error,
    touched,
    setFieldValue,
    setFieldTouched,
    onChange,
    onBlur,
    isClearable,
    isRequired,
    ...props
}) => {
    const handleBlur = event => {
        const cachedEvent = { ...event };

        if (onBlur) {
            // Prevent the onBlur being called too early on mobile devices,
            // as it breaks the validation.
            setTimeout(() => {
                onBlur(cachedEvent);
            }, 1);
        }
    };

    const handleChange = date => {
        let newValue = null;

        if (date) {
            // Convert the date to the default timezone by first converting it
            // to an ISO string in the UTC timezone and then using the parse function
            // to convert it from UTC to the default timezone.
            newValue = parseDate(formatDateAsISO(date));
        }

        if (onChange) {
            onChange(newValue);
        }

        if (setFieldValue) {
            setFieldValue(props.name, newValue);
        }

        // @TODO: Remove when Formik gets updated and the following GitHub issue gets resolved:
        //        https://github.com/formium/formik/issues/2457
        if (setFieldTouched) {
            setTimeout(() => {
                setFieldTouched(props.name, true);
            }, 50);
        }
    };

    const handleClear = () => {
        handleChange(null);
    };

    return (
        <>
            <Wrapper>
                <ReactDatePicker
                    selected={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    dateFormat="dd.MM.yyyy"
                    popperModifiers={{
                        preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport',
                        },
                    }}
                    customInput={
                        <CustomInput
                            label={label}
                            error={error}
                            touched={Boolean(touched)}
                            isRequired={isRequired}
                            isClearable={isClearable && !props.disabled}
                            onClear={handleClear}
                        />
                    }
                    renderCustomHeader={renderCustomHeader}
                    disabledKeyboardNavigation
                    isClearable={false}
                    locale="de"
                    {...props}
                />
            </Wrapper>
        </>
    );
};

DatePicker.defaultProps = {
    label: null,
    value: null,
    error: null,
    touched: false,
    setFieldValue: undefined,
    setFieldTouched: undefined,
    onChange: undefined,
    onBlur: undefined,
    isClearable: true,
    isRequired: false,
};

DatePicker.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    error: PropTypes.string,
    touched: PropTypes.any,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    isClearable: PropTypes.bool,
    isRequired: PropTypes.bool,
};

export default DatePicker;
