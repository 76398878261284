import api from 'Application/api/api';

const getAllDriverLicenseControls = (load, cancelToken = null) =>
    api.get('/measures/vehicles/driver-licenses/controls', {
        params: {
            load,
        },
        cancelToken,
    });

export default getAllDriverLicenseControls;
