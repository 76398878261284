import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import axios from 'axios';
import FormField from 'Common/components/Form/FormField';
import Input from 'Common/components/Form/Fields/Input';
import login from 'User/services/login';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import getPlatformDomainName from 'Application/api/getPlatformDomainName';
import Loader from 'Common/components/Loader';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import Button from 'Common/components/Button';

const validationSchema = Yup.object().shape({
    domain: Yup.string().required('Pflichtangabe'),
    userIdentifier: Yup.string().required('Pflichtangabe'),
    password: Yup.string().required('Pflichtangabe'),
});

const LoginButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    button {
        min-width: 180px;
    }
`;

const LoginForm = () => {
    const { data: platformDomainNameData, isLoading } = useAxiosRequest(
        getPlatformDomainName,
        [],
    );

    const [domainQueryParameter] = useQueryParameter('domain');
    const domainFromLastLogin = window.localStorage.getItem('domain') ?? null;

    const platformDomainName =
        platformDomainNameData.domain ??
        domainQueryParameter ??
        domainFromLastLogin;

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const initialValues = useMemo(
        () => ({
            domain: platformDomainName ?? '',
            userIdentifier: '',
            password: '',
        }),
        [platformDomainName],
    );

    const {
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: async values => {
            try {
                await login(
                    values.domain,
                    values.userIdentifier,
                    values.password,
                    true,
                    source.token,
                );
                window.localStorage.setItem('domain', values.domain);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    notify('Zugangsdaten ungültig', {
                        type: toast.TYPE.ERROR,
                    });
                }
            }
        },
    });

    if (isLoading) {
        return <Loader />;
    }

    return (
        <form onSubmit={handleSubmit}>
            <FormField>
                <Input
                    label="Domain"
                    id="domain"
                    name="domain"
                    error={errors.domain}
                    touched={touched.domain}
                    value={values.domain}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    spellCheck={false}
                    disabled={!!platformDomainName}
                />
            </FormField>
            <FormField>
                <Input
                    label="Benutzername"
                    id="userIdentifier"
                    name="userIdentifier"
                    error={errors.userIdentifier}
                    touched={touched.userIdentifier}
                    value={values.userIdentifier}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    spellCheck={false}
                />
            </FormField>
            <FormField>
                <Input
                    type="password"
                    label="Passwort"
                    id="password"
                    name="password"
                    error={errors.password}
                    touched={touched.password}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </FormField>

            <LoginButtonWrapper>
                <Button type="submit">Anmelden</Button>
            </LoginButtonWrapper>
        </form>
    );
};

export default LoginForm;
