import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import InnerBox from 'Common/components/Boxes/InnerBox';
import { prop } from 'styled-tools';
import colors from 'Common/constants/colors';
import Switch from 'Common/components/Form/Switch';
import { useRecoilValue } from 'recoil';
import ContentLoader from 'react-content-loader';
import projectsLoadingState from 'ProjectManager/Project/List/recoil/loading/projectsLoadingState';

const StyledInnerBox = styled(InnerBox)`
    position: relative;
    display: flex;
    align-items: center;
    padding: 26px 16px;
`;

const IconCircle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    flex: 0 0 60px;
    margin-right: 15px;
    background-color: ${prop('background')};
    border-radius: 50%;
    color: ${colors.DIRTY_WHITE};
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.3);
`;

const Info = styled.div`
    flex: 1;
`;

const Number = styled.div`
    display: flex;
    font-weight: 600;
    font-size: 32px;
    line-height: 34px;
`;

const Text = styled.div`
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
`;

const SwitchWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

const StatisticsTile = ({
    icon,
    number,
    text,
    color,
    isSelected,
    isDisabled,
    onToggle,
}) => {
    const isLoading = useRecoilValue(projectsLoadingState);

    return (
        <StyledInnerBox light>
            <IconCircle background={color}>{icon}</IconCircle>
            <Info>
                <Number>
                    {isLoading ? (
                        <ContentLoader
                            width={45}
                            height={34}
                            viewBox="0 0 45 34"
                            backgroundColor={colors.DIRTY_WHITE}
                            foregroundColor={colors.LIGHTER_GRAY}
                        >
                            <rect
                                x="0"
                                y="0"
                                rx="6"
                                ry="6"
                                width="45"
                                height="34"
                            />
                        </ContentLoader>
                    ) : (
                        number
                    )}
                </Number>
                <Text>{text}</Text>
            </Info>
            <SwitchWrapper>
                <Switch
                    checked={isSelected}
                    onChange={onToggle}
                    disabled={isDisabled}
                    name="checked"
                    color={color}
                />
            </SwitchWrapper>
        </StyledInnerBox>
    );
};

StatisticsTile.defaultProps = {
    isSelected: false,
    isDisabled: false,
};

StatisticsTile.propTypes = {
    icon: PropTypes.any.isRequired,
    number: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onToggle: PropTypes.func.isRequired,
};

export default StatisticsTile;
