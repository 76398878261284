import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useProjectTopicFunctions
    from 'ProjectManager/DataManagement/TopicManagement/components/TopicSelection/hooks/topicFunctions/useProjectTopicFunctions';
import useTemplateTopicFunctions
    from 'ProjectManager/DataManagement/TopicManagement/components/TopicSelection/hooks/topicFunctions/useTemplateTopicFunctions';

const useTopicFunctions = () => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useProjectTopicFunctions();
    const dataFromTemplate = useTemplateTopicFunctions();

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return {};
};

export default useTopicFunctions;
