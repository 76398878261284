import { selector } from 'recoil';
import templatesAtom from 'ProjectManager/Template/List/recoil/templates/templatesAtom';
import { orderBy } from 'lodash';
import groupsAtom from 'ProjectManager/Template/List/recoil/groups/groupsAtom';

const templateGroupsSelector = selector({
    key: 'projectManagement_templateGroups',
    get: ({ get }) => {
        const groups = get(groupsAtom);
        const templates = get(templatesAtom);

        return orderBy(
            groups.map(group => ({
                ...group,
                templates: orderBy(
                    templates.filter(
                        template => template.group.id === group.id,
                    ),
                    ['position'],
                    ['ASC'],
                ),
            })),
            ['position'],
            ['ASC'],
        );
    },
});

export default templateGroupsSelector;
