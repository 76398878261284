import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import addProjectQuestionComment from 'ProjectManager/Project/Common/api/dataManagement/questionnaire/question/comment/addProjectQuestionComment';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import hasUnsavedChangesState from 'Common/recoil/hasUnsavedChangesState';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import withQuestionComment from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/withQuestionComment';

const useAddProjectQuestionCommentData = (
    questionId,
    isCustomQuestion,
    onAdd,
) => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const [isLoading, setIsLoading] = useState(false);
    const [, setHasUnsavedChanges] = useRecoilState(hasUnsavedChangesState);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const addComment = useCallback(async () => {
        setHasUnsavedChanges(true);
        setIsLoading(true);

        try {
            await addProjectQuestionComment(
                projectId,
                questionId,
                isCustomQuestion,
                source.token,
            );

            setProject(withQuestionComment(questionId, isCustomQuestion, ''));

            if (onAdd) {
                await onAdd();
            }
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }

        setIsLoading(false);
        setHasUnsavedChanges(false);
    }, [
        onAdd,
        projectId,
        questionId,
        isCustomQuestion,
        setHasUnsavedChanges,
        setProject,
        source.token,
    ]);

    return {
        isLoading,
        addComment,
    };
};

export default useAddProjectQuestionCommentData;
