import setIn from 'Common/utils/setIn';
import withUpdatedCompletedStatusesInQuestionnaire from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/withUpdatedCompletedStatusesInQuestionnaire';

const withAdditionalCustomQuestion = (
    newCustomQuestion,
    subsectionId,
) => prevProjectOrTemplate => {
    const questionnaireSections = prevProjectOrTemplate.questionnaireSections;

    const sectionIndex = questionnaireSections.findIndex(section =>
        section.subsections.some(
            subsection => subsection.id === subsectionId && subsection.isCustom,
        ),
    );

    if (sectionIndex === -1) {
        return prevProjectOrTemplate;
    }

    const subsectionIndex = questionnaireSections[
        sectionIndex
    ].subsections.findIndex(
        subsection => subsection.id === subsectionId && subsection.isCustom,
    );

    if (subsectionIndex === -1) {
        return prevProjectOrTemplate;
    }

    const newQuestionnaireSections = setIn(
        questionnaireSections,
        `${sectionIndex}.subsections.${subsectionIndex}.questions`,
        [
            ...questionnaireSections[sectionIndex].subsections[subsectionIndex]
                .questions,
            {
                files: [],
                ...newCustomQuestion,
            },
        ],
    );

    return withUpdatedCompletedStatusesInQuestionnaire({
        ...prevProjectOrTemplate,
        questionnaireSections: newQuestionnaireSections,
    });
};

export default withAdditionalCustomQuestion;
