import React, { useCallback, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import getProject from 'ProjectManager/Project/Common/api/getProject';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';
import BackButton from 'Common/components/BackButton';
import HeaderBackButton from 'Application/components/Header/BackButton/BackButton';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components/macro';
import OuterBox from 'Common/components/Boxes/OuterBox';
import Content from 'ProjectManager/Project/Introductions/Common/components/Content';
import Sidebar from 'ProjectManager/Project/Introductions/Common/components/Sidebar';
import getPoints from 'ProjectManager/Project/Introductions/Common/api/getPoints';
import availablePointsAtom from 'ProjectManager/Project/Introductions/Common/recoil/availablePoints/availablePointsAtom';

const SidebarLayout = styled.div`
    @media screen and (min-width: 1180px) {
        display: flex;
    }
`;

const MobileBackButtonWrapper = styled.div`
    position: sticky;
    top: 80px;
    width: 100%;
    z-index: 2;

    > a {
        border-radius: 6px 6px 0 0;
    }

    @media screen and (min-width: 1180px) {
        display: none;
    }
`;

const DesktopBackButtonWrapper = styled.div`
    display: none;

    @media screen and (min-width: 1180px) {
        display: block;
        position: sticky;
        top: 0;
        width: 100%;
        margin-bottom: 20px;
        z-index: 2;
    }
`;

const SidebarWrapper = styled(OuterBox)`
    margin-bottom: 10px;

    @media screen and (min-width: 1180px) {
        position: sticky;
        top: 80px;
        width: 340px;
        max-height: calc(100vh - 110px);
        flex: 0 0 340px;
        margin-right: 10px;
        margin-bottom: 0;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
`;

const SidebarContent = styled.div`
    padding: 20px;

    @media screen and (min-width: 1180px) {
        padding-top: 0;
        padding-bottom: 60px;
    }
`;

const ContentWrapper = styled(OuterBox)`
    padding: 20px;

    @media screen and (min-width: 1180px) {
        flex: 1;
    }
`;

const ProjectIntroductions = () => {
    const { id } = useParams();

    const setDataManagementEntity = useSetRecoilState(dataManagementEntityAtom);

    useEffect(() => {
        setDataManagementEntity(dataManagementEntities.PROJECT);
    }, [setDataManagementEntity]);

    const [project, setProject] = useRecoilState(projectAtom);

    const projectRequest = useCallback(
        cancelToken =>
            getProject(
                id,
                [
                    'statistics',
                    'status',
                    'supervisors',
                    'participants',
                    'introductions',
                    'introductions.supervisor',
                    'introductions.participants',
                    'introductions.participants.projectParticipant',
                    'introductions.participants.projectParticipant.employee',
                    'introductions.participants.projectParticipant.employee.user',
                ],
                cancelToken,
            ),
        [id],
    );

    const {
        loadData: loadProject,
        isLoading,
        hasError,
        error,
    } = useAxiosRequest(projectRequest, null, {
        customDataSetterFunction: setProject,
    });

    const setAvailablePoints = useSetRecoilState(availablePointsAtom);

    const {
        loadData: loadPoints,
        isLoading: isLoadingPoints,
        hasError: hasPointsError,
    } = useAxiosRequest(getPoints, [], {
        customDataSetterFunction: setAvailablePoints,
    });

    if (
        hasError &&
        (error?.response?.status === 401 || error?.response?.status === 404)
    ) {
        return <Redirect to="/project-manager" />;
    }

    if (project.id && !project.isCreated) {
        return <Redirect to={`/project-manager/project/${id}/create`} />;
    }

    if (isLoading || isLoadingPoints) {
        return <Loader />;
    }

    if (hasError) {
        return <RetryLoading onRetry={loadProject} />;
    }

    if (hasPointsError) {
        return <RetryLoading onRetry={loadPoints} />;
    }

    const title = project.name ? project.name : 'Kein Name';

    return (
        <>
            <Helmet>
                <title>{title} - Einweisung</title>
            </Helmet>
            <HeaderBackButton to={`/project-manager/project/${id}/preview`} />
            <Breadcrumb to="/project-manager">Projektübersicht</Breadcrumb>
            <Breadcrumb to={`/project-manager/project/${id}/preview`}>
                {title}
            </Breadcrumb>
            <Breadcrumb isActive>Einweisung</Breadcrumb>
            <SidebarLayout>
                <MobileBackButtonWrapper>
                    <BackButton
                        to={`/project-manager/project/${project.id}/preview`}
                        title="Zurück zum Projekt"
                        subtitle={title}
                    />
                </MobileBackButtonWrapper>
                <SidebarWrapper>
                    <DesktopBackButtonWrapper>
                        <BackButton
                            to={`/project-manager/project/${project.id}/preview`}
                            title="Zurück zum Projekt"
                            subtitle={title}
                        />
                    </DesktopBackButtonWrapper>
                    <SidebarContent>
                        <Sidebar />
                    </SidebarContent>
                </SidebarWrapper>
                <ContentWrapper>
                    <Content />
                </ContentWrapper>
            </SidebarLayout>
        </>
    );
};

export default ProjectIntroductions;
