import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import deleteProjectCustomSubsection from 'ProjectManager/Project/Common/api/dataManagement/questionnaire/subsection/deleteProjectCustomSubsection';
import withoutCustomSubsection from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/subsection/withoutCustomSubsection';

const useDeleteProjectCustomSubsectionFunction = () => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const deleteCustomSubsection = useCallback(
        async customSubsectionId => {
            await deleteProjectCustomSubsection(
                projectId,
                customSubsectionId,
                source.token,
            );

            setProject(withoutCustomSubsection(customSubsectionId));
        },
        [projectId, setProject, source.token],
    );

    return deleteCustomSubsection;
};

export default useDeleteProjectCustomSubsectionFunction;
