import { atom } from 'recoil';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import { mapValues } from 'lodash';

const key = 'projectManagement_projectsListFilters';

const defaultValue = {
    statuses: [projectStatuses.ON_STANDBY, projectStatuses.ONGOING],
    onlyProjectsThatRequireAction: false,
    endingDateFrom: null,
    endingDateTo: null,
    topicIds: [],
};

const getInitialValue = () => {
    try {
        if (window.localStorage && window.localStorage.getItem(key)) {
            return {
                ...defaultValue,
                ...mapValues(
                    JSON.parse(window.localStorage.getItem(key)),
                    (value, key) => {
                        if (
                            ['endingDateFrom', 'endingDateTo'].includes(key) &&
                            Boolean(value)
                        ) {
                            return new Date(value);
                        }

                        return value;
                    },
                ),
            };
        }
    } catch (error) {
        // Fail silently
    }

    return defaultValue;
};

const projectsFiltersAtom = atom({
    key,
    default: getInitialValue(),
});

export { defaultValue as defaultProjectsFiltersState };

export default projectsFiltersAtom;
