import mapClientContactPeopleToOptions from 'ProjectManager/Project/DataManagement/GeneralInformation/ContactPeople/helpers/mapClientContactPeopleToOptions';
import getClientContactPeople from 'ProjectManager/Client/api/contactPeople/getClientContactPeople';

const loadClientContactPeopleOptions = async (clientId, query) => {
    const response = await getClientContactPeople(clientId);
    const clientContactPeople = response.data;

    if (!query) {
        return mapClientContactPeopleToOptions(clientContactPeople);
    }

    const searchTerm = query.toLowerCase();

    return mapClientContactPeopleToOptions(
        clientContactPeople.filter(clientContactPerson =>
            clientContactPerson.name.toLowerCase().includes(searchTerm),
        ),
    );
};

export default loadClientContactPeopleOptions;
