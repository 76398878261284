import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import deleteProjectCustomTopic from 'ProjectManager/Project/Common/api/dataManagement/customTopic/deleteProjectCustomTopic';
import updateProjectCustomTopic from 'ProjectManager/Project/Common/api/dataManagement/customTopic/updateProjectCustomTopic';
import getProjectQuestionnaireSections from 'ProjectManager/Project/Common/api/dataManagement/questionnaire/getProjectQuestionnaireSections';
import withoutCustomTopic from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/customTopic/withoutCustomTopic';
import withUpdatedCustomTopicData from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/customTopic/withUpdatedCustomTopicData';
import withQuestionnaireSections from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/withQuestionnaireSections';

/**
 * @TODO: Move into 2 separate hook groups - one for each function
 */
const useProjectCustomTopicFunctions = () => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const updateCustomTopic = useCallback(
        async (customTopicId, updatedValues) => {
            await updateProjectCustomTopic(
                projectId,
                customTopicId,
                updatedValues,
                source.token,
            );

            const questionnaireSections = await getProjectQuestionnaireSections(
                projectId,
                source.token,
            );

            setProject(
                withUpdatedCustomTopicData(customTopicId, updatedValues),
            );
            setProject(withQuestionnaireSections(questionnaireSections));
        },
        [projectId, setProject, source.token],
    );

    const deleteCustomTopic = useCallback(
        async deletedCustomTopicId => {
            await deleteProjectCustomTopic(
                projectId,
                deletedCustomTopicId,
                source.token,
            );

            const questionnaireSections = await getProjectQuestionnaireSections(
                projectId,
                source.token,
            );

            setProject(withoutCustomTopic(deletedCustomTopicId));
            setProject(withQuestionnaireSections(questionnaireSections));
        },
        [projectId, setProject, source.token],
    );

    return { updateCustomTopic, deleteCustomTopic };
};

export default useProjectCustomTopicFunctions;
