import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useSelector } from 'react-redux';
import modules from 'Common/constants/modules';
import deactivateEmployee from 'Employee/api/deactivateEmployee';

const DeactivateModal = ({ employee, onClose, onDeactivate }) => {
    const availableModules = useSelector(state => state.user.availableModules);

    const isModuleAvailable = useCallback(
        module => availableModules.includes(module),
        [availableModules],
    );

    const [isConfirming, setIsConfirming] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const deactivate = async () => {
        setIsConfirming(true);

        try {
            await deactivateEmployee(employee.id, source.token);
            await onDeactivate();

            setIsConfirming(false);

            notify('Mitarbeiter erfolgreich deaktiviert', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsConfirming(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    return (
        <Modal
            isOpen
            onRequestClose={isConfirming ? undefined : onClose}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onClose}
                        disabled={isConfirming}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isConfirming}
                        onClick={deactivate}
                        disabled={isConfirming}
                    >
                        Zugang inaktivieren
                    </Button>
                </>
            }
        >
            <ModalTitle>Inaktivierung des Mitarbeiterzugangs</ModalTitle>
            <p>
                Bitte beachte, dass der Mitarbeiter
                {` "${employee.lastName}, ${employee.firstName}" `}bei der
                Inaktivierung des Zugangs sich nicht mehr im System anmelden
                kann.
            </p>
            {isModuleAvailable(modules.NEW_RISK_ASSESSMENT) && (
                <p>
                    Falls der Mitarbeiter als Arbeitsverantwortlicher vor Ort
                    benannt wurde, muss der Administrator gegebenenfalls
                    intervenieren.
                </p>
            )}
        </Modal>
    );
};

DeactivateModal.propTypes = {
    employee: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onDeactivate: PropTypes.func.isRequired,
};

export default DeactivateModal;
