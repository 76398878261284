import React from 'react';
import styled from 'styled-components/macro';
import OuterBox from 'Common/components/Boxes/OuterBox';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import Button from 'Common/components/Button';
import Statistics from 'ProjectManager/Project/List/components/Overview/TopOverview/Statistics';

const StyledOuterBox = styled(OuterBox)`
    padding: 20px;
    margin-bottom: 10px;
`;

const StyledOuterBoxTitle = styled(OuterBoxTitle)`
    display: block;

    @media screen and (min-width: 520px) {
        display: flex;
        justify-content: space-between;
    }
`;

const TitleText = styled.div`
    margin-bottom: 15px;

    @media screen and (min-width: 520px) {
        margin-bottom: 0;
    }
`;

const TopOverview = () => (
    <StyledOuterBox>
        <StyledOuterBoxTitle>
            <TitleText>Projektübersicht</TitleText>
            <Button to="/project-manager/project/create">
                Projekt erstellen
            </Button>
        </StyledOuterBoxTitle>
        <Statistics />
    </StyledOuterBox>
);

export default TopOverview;
