import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from 'Common/components/Form/ErrorMessage';
import { InputAdornment } from '@material-ui/core';
import StyledTextField from 'Common/components/Form/Fields/StyledTextField';
import NumberFormat from 'react-number-format';
import RequiredAsterisk from 'Common/components/Form/RequiredAsterisk';

const CustomNumberInput = ({ inputRef, onChange, ...props }) => (
    <NumberFormat
        getInputRef={inputRef}
        onValueChange={values => {
            onChange({
                target: {
                    name: props.name,
                    value: values.value,
                },
            });
        }}
        isNumericString
        allowNegative={false}
        {...props}
    />
);

CustomNumberInput.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const Input = memo(
    ({
        label,
        icon: Icon,
        error,
        touched,
        value: rawValue,
        readOnly,
        isRequired,
        type,
        numberProps,
        InputProps,
        ...props
    }) => {
        const hasError = !!error && touched;
        const hasLabel = label !== null;
        const hasIcon = !!Icon;

        let value = rawValue;

        if (type === 'number') {
            value = value !== '' ? Number(value).toString() : '';
        }

        const endAdornment = hasIcon ? (
            <InputAdornment position="end">
                <Icon fontSize="small" />
            </InputAdornment>
        ) : undefined;

        const inputProps = {
            endAdornment,
            readOnly,
            ...InputProps,
        };

        let fieldType = type;

        if (type === 'number') {
            fieldType = 'text';
            inputProps.inputComponent = CustomNumberInput;
            inputProps.inputProps = numberProps;
        }

        const renderedLabel =
            hasLabel && isRequired ? (
                <>
                    {label} <RequiredAsterisk />
                </>
            ) : (
                label
            );

        return (
            <>
                <StyledTextField
                    variant="filled"
                    error={hasError}
                    label={hasLabel ? renderedLabel : undefined}
                    value={value}
                    InputProps={inputProps}
                    type={fieldType}
                    {...props}
                />

                {hasError && <ErrorMessage message={error} />}
            </>
        );
    },
);

Input.defaultProps = {
    label: null,
    icon: null,
    error: null,
    touched: false,
    readOnly: false,
    isRequired: false,
};

Input.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.elementType,
    error: PropTypes.string,
    touched: PropTypes.bool,
    readOnly: PropTypes.bool,
    isRequired: PropTypes.bool,
    value: PropTypes.any.isRequired,
};

export default Input;
