import ProtectiveGloves from 'Common/components/Icons/ProtectiveGloves';
import FootProtection from 'Common/components/Icons/FootProtection';
import ProtectiveClothing from 'Common/components/Icons/ProtectiveClothing';
import ProtectiveMask from 'Common/components/Icons/ProtectiveMask';
import SafetyHarness from 'Common/components/Icons/SafetyHarness';
import HeadProtection from 'Common/components/Icons/HeadProtection';
import EyeProtection from 'Common/components/Icons/EyeProtection';
import EarProtection from 'Common/components/Icons/EarProtection';
import SafetyVest from 'Common/components/Icons/SafetyVest';

const ppeIcons = {
    EYE_PROTECTION: EyeProtection,
    EAR_PROTECTION: EarProtection,
    PROTECTIVE_GLOVES: ProtectiveGloves,
    FOOT_PROTECTION: FootProtection,
    PROTECTIVE_CLOTHING: ProtectiveClothing,
    PROTECTIVE_MASK: ProtectiveMask,
    SAFETY_HARNESS: SafetyHarness,
    HEAD_PROTECTION: HeadProtection,
    SAFETY_VEST: SafetyVest,
};

export default ppeIcons;
