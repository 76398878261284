import api from 'Application/api/api';

const createDriverLicense = (
    driverLicenseControlId,
    controlMethod,
    cancelToken = null,
) =>
    api.post(
        `/measures/vehicles/driver-licenses/controls/${driverLicenseControlId}/driver-license`,
        { controlMethod },
        {
            cancelToken,
        },
    );

export default createDriverLicense;
