import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';

const useSelectedProjectTopics = () => {
    const { topics } = useRecoilValue(projectAtom);

    return topics;
};

export default useSelectedProjectTopics;
