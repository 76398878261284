import api from 'Application/api/api';

const preProcessDriverLicenseFile = (file, cancelToken = null) => {
    const formData = new FormData();

    formData.append('file', file);

    return api.post(
        '/measures/vehicles/driver-licenses/pre-process-file',
        formData,
        {
            cancelToken,
            timeout: 25000,
        },
    );
};

export default preProcessDriverLicenseFile;
