import { useDispatch } from 'react-redux';
import { clearUserData } from 'User/reduxUser';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

const Logout = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearUserData());

        notify('Deine Sitzung ist abgelaufen', {
            type: toast.TYPE.WARNING,
            toastId: 'session-timeout',
        });
    }, [dispatch]);

    return null;
};

export default Logout;
