import api from 'Application/api/api';

/**
 * @param data Accepts an object with the following format:
 * {
 *   name: '',
 *   contactPeople: [
 *       {
 *           name: '',
 *           email: '',
 *       },
 *   ],
 * }
 * @param cancelToken
 */
const createClient = (data, cancelToken = null) =>
    api.post('project-manager/clients', data, { cancelToken });

export default createClient;
