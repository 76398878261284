import areAllTemplateStepsCompletedSelector from 'ProjectManager/Template/Common/recoil/selectors/areAllTemplateStepsCompletedSelector';
import { useRecoilValue } from 'recoil';

const useAreAllTemplateStepsCompleted = () => {
    // noinspection UnnecessaryLocalVariableJS
    const areAllStepsCompleted = useRecoilValue(
        areAllTemplateStepsCompletedSelector,
    );

    return areAllStepsCompleted;
};

export default useAreAllTemplateStepsCompleted;
