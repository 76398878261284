const measures = {
    DRIVER_LICENSE: 'DRIVER_LICENSE',
    VEHICLE_INSPECTION: 'VEHICLE_INSPECTION',
    VEHICLE_MANAGEMENT: 'VEHICLE_MANAGEMENT',
    UVV_TUV: 'UVV_TUV',
    LADDER: 'LADDER',
    DGUV_REGULATION_3: 'DGUV_REGULATION_3',
    ELECTRIC_GATE: 'ELECTRIC_GATE',
    PERSONAL_PROTECTIVE_EQUIPMENT: 'PERSONAL_PROTECTIVE_EQUIPMENT',
    PPE_FALL_EQUIPMENT: 'PPE_FALL_EQUIPMENT',
    FIRST_AID: 'FIRST_AID',
    FIRE_EXTINGUISHER: 'FIRE_EXTINGUISHER',
    FIRE_PROTECTION_ASSISTANT: 'FIRE_PROTECTION_ASSISTANT',
    EC_SAFETY_DATA_SHEETS: 'EC_SAFETY_DATA_SHEETS',
    TRANSMISSION_OF_ENTREPRENEURIAL_RESPONSIBILITIES:
        'TRANSMISSION_OF_ENTREPRENEURIAL_RESPONSIBILITIES',
    REQUIREMENT_TO_CARRY_PERSONAL_ID: 'REQUIREMENT_TO_CARRY_PERSONAL_ID',
};

export default measures;
