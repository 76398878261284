import api from 'Application/api/api';

const sendManualReminder = (id, cancelToken = null) =>
    api.post(
        `/measures/vehicles/driver-licenses/controls/${id}/send-manual-reminder`,
        {},
        { cancelToken },
    );

export default sendManualReminder;
