import styled from 'styled-components/macro';
import { css } from 'styled-components';
import colors from 'Common/constants/colors';
import { ifProp } from 'styled-tools';

const InnerBoxButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: 15px;
    box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.16);

    ${ifProp(
        'light',
        css`
            background: ${colors.WHITE};
        `,
        css`
            background: ${colors.DARK_DIVIDER};
        `,
    )};
`;

export default InnerBoxButtons;
