import React from 'react';
import styled from 'styled-components/macro';
import AllowedFileTypes from 'ProjectManager/DataManagement/Files/components/AllowedFileTypes';
import UploadButton from 'ProjectManager/DataManagement/Files/components/UploadButton';

const Wrapper = styled.div`
    @media screen and (min-width: 520px) {
        display: flex;
        align-items: center;
    }
`;

const InfoIconWrapper = styled.div`
    margin-top: 20px;

    @media screen and (min-width: 520px) {
        margin-top: 0;
        margin-left: 10px;
    }
`;

const Upload = () => (
    <Wrapper>
        <UploadButton outline />
        <InfoIconWrapper>
            <AllowedFileTypes />
        </InfoIconWrapper>
    </Wrapper>
);

export default Upload;
