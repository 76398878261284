import { selector } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';

const isProjectEmployeeSelectionStepCompletedSelector = selector({
    key: 'projectManagement_isProjectEmployeeSelectionStepCompleted',
    get: ({ get }) => {
        const { id, participants } = get(projectAtom);

        return (
            Boolean(id) &&
            participants.filter(participant => participant.isAssigned).length >
                0
        );
    },
});

export default isProjectEmployeeSelectionStepCompletedSelector;
