import api from 'Application/api/api';

const reactivateProject = (id, endingDate = null, cancelToken = null) =>
    api.post(
        `project-manager/projects/${id}/reactivate`,
        { endingDate },
        { cancelToken },
    );

export default reactivateProject;
