import React from 'react';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components/macro';
import Question from 'ProjectManager/Project/ProjectMediaFiles/components/Content/Questionnaire/Question';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import visibleQuestionFilesCountSelector from 'ProjectManager/Project/Common/recoil/project/selectors/visibleQuestionFilesCountSelector';
import DownloadAllFilesButton from 'ProjectManager/Project/ProjectMediaFiles/components/Content/Questionnaire/DownloadAllFilesButton/DownloadAllFilesButton';
import { flatten } from 'lodash';
import InnerBoxTitle from 'Common/components/Boxes/InnerBoxTitle';
import InnerBox from 'Common/components/Boxes/InnerBox';
import colors from 'Common/constants/colors';
import projectMediaFilesSearchTermAtom from 'ProjectManager/Project/ProjectMediaFiles/recoil/projectMediaFilesSearchTerm/projectMediaFilesSearchTermAtom';

const Wrapper = styled.div``;

const Questions = styled.div`
    > div {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const NoResultsFound = styled(InnerBox)`
    padding: 20px;

    ${InnerBoxTitle} {
        margin-bottom: 0;
        color: ${colors.GRAY};
    }
`;

const FilesTitle = styled(OuterBoxTitle)`
    display: block;

    @media screen and (min-width: 480px) {
        display: flex;
        align-items: center;
    }
`;

const FilesButtonWrapper = styled.div`
    margin-top: 20px;

    @media screen and (min-width: 480px) {
        display: flex;
        align-items: center;
        margin-top: 0;
        margin-left: 15px;
    }
`;

const doesQuestionMatchSearchTerm = (
    section,
    subsection,
    question,
    searchTerm,
) =>
    question.text.toLowerCase().includes(searchTerm.toLowerCase()) ||
    subsection.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    section.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (!section.isCustom &&
        section.predefinedTopic.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
    question.files.some(file =>
        file.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );

const isQuestionVisible = (section, subsection, question, searchTerm) =>
    question.isVisible &&
    question.files.length > 0 &&
    (!searchTerm ||
        (Boolean(searchTerm) &&
            doesQuestionMatchSearchTerm(
                section,
                subsection,
                question,
                searchTerm,
            )));

const Questionnaire = () => {
    const { questionnaireSections } = useRecoilValue(projectAtom);
    const searchTerm = useRecoilValue(projectMediaFilesSearchTermAtom);
    const questionFilesCount = useRecoilValue(visibleQuestionFilesCountSelector);

    const canDownloadQuestionFiles = questionFilesCount > 1 && !searchTerm;

    const visibleQuestions = flatten(
        questionnaireSections.map(section =>
            flatten(
                section.subsections.map(subsection =>
                    subsection.questions
                        .filter(question =>
                            isQuestionVisible(
                                section,
                                subsection,
                                question,
                                searchTerm,
                            ),
                        )
                        .map(question => ({
                            ...question,
                            section,
                            subsection,
                        })),
                ),
            ),
        ),
    );

    const hasVisibleQuestions = visibleQuestions.length > 0;

    return (
        <Wrapper>
            <FilesTitle>
                Fragendateien
                {Boolean(searchTerm) &&
                    ` (${visibleQuestions.length} Frage${
                        visibleQuestions.length !== 1 ? 'n' : ''
                    })`}
                {canDownloadQuestionFiles && (
                    <FilesButtonWrapper>
                        <DownloadAllFilesButton />
                    </FilesButtonWrapper>
                )}
            </FilesTitle>
            {hasVisibleQuestions ? (
                <Questions>
                    {visibleQuestions.map(question => (
                        <Question
                            key={question.id}
                            section={question.section}
                            subsection={question.subsection}
                            question={question}
                        />
                    ))}
                </Questions>
            ) : (
                <NoResultsFound>
                    <InnerBoxTitle>Keine Fragedateien gefunden</InnerBoxTitle>
                </NoResultsFound>
            )}
        </Wrapper>
    );
};

export default Questionnaire;
