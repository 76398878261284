import React from 'react';
import styled from 'styled-components/macro';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import Introduction from 'ProjectManager/Project/Introductions/Grid/components/Introduction/Introduction';
import { orderBy } from 'lodash';

const Wrapper = styled.div`
    > div {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const IntroductionsGrid = () => {
    const { introductions } = useRecoilValue(projectAtom);

    return (
        <Wrapper>
            {orderBy(introductions, ['createdAt'], ['desc']).map(
                introduction => (
                    <Introduction
                        key={introduction.id}
                        introduction={introduction}
                    />
                ),
            )}
        </Wrapper>
    );
};

export default IntroductionsGrid;
