import React from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { prop, switchProp } from 'styled-tools';
import { ReactComponent as DriverLicenseControl } from 'Common/components/assets/driver-license-control.svg';
import { ReactComponent as UvvTuvCheck } from 'Common/components/assets/uvv-tuv-check.svg';
import { ReactComponent as VehicleContract } from 'Common/components/assets/vehicle-contract.svg';
import { ReactComponent as PortraitToLandscape } from 'Common/components/assets/portrait-to-landscape.svg';
import { ReactComponent as SpaCloud } from 'Common/components/assets/spa-cloud.svg';

// noinspection CssNoGenericFontName
const StyledIcon = styled.i`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  // Use !important to prevent issues with browser extensions that change fonts
  font-family: 'Font-Amedic-01' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: ${prop('color')};

  // Optimize Rendering
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    content: '\\e${prop('code')}';
  }

  ${switchProp('size', {
      xs: css`
          width: 14px;
          height: 14px;
          font-size: 14px;
      `,
      s: css`
          width: 20px;
          height: 20px;
          font-size: 20px;
      `,
      sm: css`
          width: 30px;
          height: 30px;
          font-size: 28px;
      `,
      md: css`
          width: 48px;
          height: 48px;
          font-size: 48px;
      `,
      lg: css`
          width: 70px;
          height: 70px;
          font-size: 50px;
      `,
      xl: css`
          width: 100px;
          height: 100px;
          font-size: 84px;
      `,
  })}
`;

const Icon = ({ code, size, color }) => (
    <StyledIcon code={code} size={size} color={color} />
);

Icon.defaultProps = {
    size: 'md',
    color: 'inherit',
};

const SvgIcon = ({ svg: Svg, size, color, className }) => {
    const style = {};

    // eslint-disable-next-line default-case
    switch (size) {
        case 'xs':
            style.width = 14;
            style.height = 14;
            break;

        case 's':
            style.width = 20;
            style.height = 20;
            break;

        case 'sm':
            style.width = 30;
            style.height = 30;
            break;

        case 'md':
            style.width = 48;
            style.height = 48;
            break;

        case 'lg':
            style.width = 70;
            style.height = 70;
            break;

        case 'xl':
            style.width = 100;
            style.height = 100;
            break;

        case null:
            break;

        default:
            style.width = size;
            style.height = size;
            break;
    }

    return <Svg className={className} style={style} fill={color} />;
};

SvgIcon.defaultProps = {
    size: 'md',
    color: 'currentColor',
};

export const EmployeesIcon = props => <Icon code="90c" {...props} />;
export const GroupIcon = props => <Icon code="944" {...props} />;
export const LibraryIcon = props => <Icon code="934" {...props} />;
export const EyeIcon = props => <Icon code="90e" {...props} />;
export const SignatureIcon = props => <Icon code="93c" {...props} />;
export const InstructionIcon = props => <Icon code="932" {...props} />;
export const MeasuresIcon = props => <Icon code="92e" {...props} />;
export const ConstructionSiteIcon = props => <Icon code="933" {...props} />;
export const PdfDocumentIcon = props => <Icon code="937" {...props} />;
export const VehiclesIcon = props => <Icon code="941" {...props} />;
export const ToolsIcon = props => <Icon code="935" {...props} />;
export const VestIcon = props => <Icon code="942" {...props} />;
export const FirstAidIcon = props => <Icon code="929" {...props} />;
export const FireExtinguisherIcon = props => <Icon code="930" {...props} />;
export const ToxicIcon = props => <Icon code="93e" {...props} />;
export const EmptyDocumentIcon = props => <Icon code="92c" {...props} />;
export const DriverLicenseControlIcon = props => (
    <SvgIcon svg={DriverLicenseControl} {...props} />
);
export const UvvTuvIcon = props => <SvgIcon svg={UvvTuvCheck} {...props} />;
export const VehicleContractIcon = props => (
    <SvgIcon svg={VehicleContract} {...props} />
);
export const PortraitToLandscapeIcon = props => (
    <SvgIcon svg={PortraitToLandscape} {...props} />
);
export const SpaCloudIcon = props => (
    <SvgIcon svg={SpaCloud} {...props} />
);
