import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Button from 'Common/components/Button';
import { ShortTextRounded } from '@material-ui/icons';
import { Popover } from '@material-ui/core';
import colors from 'Common/constants/colors';

const Wrapper = styled.div``;

const SectionsWrapper = styled.div`
    min-width: 280px;
    max-width: 300px;
    padding: 20px;
    color: ${colors.DARK_GRAY};
    font-size: 14px;
    line-height: 21px;

    @media screen and (min-width: 400px) {
        max-width: 380px;
    }
`;

const Section = styled.div`
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
`;

const SectionTitle = styled.div`
    margin-bottom: 2px;
`;

const SectionContent = styled.div`
    font-weight: 300;

    ul {
        margin: 10px 0 0;
        padding-left: 20px;
    }
`;

const Info = ({ project }) => {
    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

    const handleButtonClick = e => {
        setPopoverAnchorEl(e.currentTarget);
    };

    const handleClosePopover = () => {
        setPopoverAnchorEl(null);
    };

    const isPopoverOpened = Boolean(popoverAnchorEl);

    return (
        <Wrapper>
            <Button
                iconOnly
                icon={<ShortTextRounded />}
                type="button"
                onClick={handleButtonClick}
                tooltip="Mehr Info"
            />
            <Popover
                open={isPopoverOpened}
                anchorEl={popoverAnchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <SectionsWrapper>
                    <Section>
                        <SectionTitle>Projektfelder</SectionTitle>
                        <SectionContent>
                            {project.topics.length > 0 ? (
                                <ul>
                                    {project.topics.map(topic => (
                                        <li key={topic.id}>{topic.name}</li>
                                    ))}
                                </ul>
                            ) : (
                                <em>Keine</em>
                            )}
                        </SectionContent>
                    </Section>
                    {project.customTopics.length > 0 && (
                        <Section>
                            <SectionTitle>Eigene Projektfelder</SectionTitle>
                            <SectionContent>
                                <ul>
                                    {project.customTopics.map(customTopic => (
                                        <li key={customTopic.id}>
                                            {customTopic.name}
                                        </li>
                                    ))}
                                </ul>
                            </SectionContent>
                        </Section>
                    )}
                </SectionsWrapper>
            </Popover>
        </Wrapper>
    );
};

Info.propTypes = {
    project: PropTypes.object.isRequired,
};

export default Info;
