import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { push, replace } from 'connected-react-router';
import getDriverLicenseControl from 'Measure/api/driver-license/getDriverLicenseControl';
import Loader from 'Common/components/Loader';
import steps from 'Measure/constants/driverLicenseCreateSteps';
import driverLicenseFileTypes from 'Measure/constants/driverLicenseFileTypes';
import DriverLicenseControlMethodSelection
    from 'Measure/components/vehicle/driver-license/create/DriverLicenseControlMethodSelection';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import roles from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';
import modules from 'Common/constants/modules';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';
import DriverLicenseImageUpload from 'Measure/components/vehicle/driver-license/create/DriverLicenseImageUpload';
import DriverLicenseFrontDataForm from 'Measure/components/vehicle/driver-license/create/DriverLicenseFrontDataForm';
import DriverLicenseBackDataForm from 'Measure/components/vehicle/driver-license/create/DriverLicenseBackDataForm';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import BackButton from 'Application/components/Header/BackButton/BackButton';

const CreateDriverLicense = () => {
    const dispatch = useDispatch();
    const { id: driverLicenseControlId } = useParams();

    const isUsingOnlyNewModules = useSelector(
        state => state.user.isUsingOnlyNewModules,
    );

    const isUsingPremiumDriverLicenseModule = useSelector(
        state => state.user.isUsingPremiumDriverLicenseModule,
    );

    const [recreate, setRecreate] = useQueryParameter('recreate');
    const isInReCreateMode = !!recreate;

    const [step, setStep] = useState(null);

    const driverLicenseControlRequest = useCallback(
        cancelToken =>
            getDriverLicenseControl(
                driverLicenseControlId,
                [
                    'employee',
                    'driverLicense',
                    'driverLicense.files',
                    'driverLicense.licenseData',
                    'driverLicense.licenseData.licenseClasses',
                    'driverLicense.employeeData',
                ],
                cancelToken,
            ),
        [driverLicenseControlId],
    );

    const {
        data: driverLicenseControl,
        loadData: loadDriverLicenseControl,
        isLoading,
        hasError,
        error,
    } = useAxiosRequest(driverLicenseControlRequest, null);

    useEffect(() => {
        if (error?.response?.status === 404) {
            dispatch(replace('/404'));
        }
    }, [dispatch, error]);

    const goToStep = async step => {
        await loadDriverLicenseControl();
        setStep(step);
    };

    const handleConfirmControlMethodSelection = async controlMethod => {
        if (isInReCreateMode) {
            await loadDriverLicenseControl();
            setRecreate(null);
        }

        if (controlMethod === 'DIRECT') {
            return goToStep(steps.DIRECT_FRONT_IMAGE);
        }

        if (controlMethod === 'DISTANCE') {
            return goToStep(steps.DISTANCE_FRONT_IMAGE);
        }
    };

    const handleConfirmBackData = async () => {
        await loadDriverLicenseControl();

        notify('Die Führerscheinkontrolle wurde erfolgreich erstellt', {
            type: toast.TYPE.SUCCESS,
        });

        dispatch(push('/measures/vehicles/driver-licenses'));
    };

    // Set the initial step when the driver license control loads
    useEffect(() => {
        if (driverLicenseControl && step === null) {
            const driverLicense = driverLicenseControl.driverLicense;

            if (driverLicense && !isInReCreateMode) {
                const uploadedFileTypes = driverLicense.files.map(
                    file => file.type,
                );

                if (driverLicense.isUsingDirectControlMethod) {
                    const hasFrontImage = uploadedFileTypes.includes(
                        driverLicenseFileTypes.FRONT_WITH_HOLOGRAM,
                    );

                    const hasBackImage = uploadedFileTypes.includes(
                        driverLicenseFileTypes.BACK_WITH_HOLOGRAM,
                    );

                    if (!hasFrontImage) {
                        setStep(steps.DIRECT_FRONT_IMAGE);
                    } else if (!hasBackImage) {
                        setStep(steps.DIRECT_BACK_IMAGE);
                    } else if (
                        !driverLicense.isRequiredFrontDataComplete ||
                        driverLicense.hasUnconfirmedOcrData
                    ) {
                        setStep(steps.DIRECT_FRONT_DATA);
                    } else if (
                        !driverLicense.isRequiredBackDataComplete ||
                        driverLicense.hasUnconfirmedOcrData
                    ) {
                        setStep(steps.DIRECT_BACK_DATA);
                    }
                } else if (driverLicense.isUsingDistanceControlMethod) {
                    const hasFrontImage = uploadedFileTypes.includes(
                        driverLicenseFileTypes.FRONT_WITH_HOLOGRAM,
                    );

                    const hasBackImageWithLetters = uploadedFileTypes.includes(
                        driverLicenseFileTypes.BACK_WITH_HOLOGRAM_LETTERS,
                    );

                    const hasBackImageWithSigns = uploadedFileTypes.includes(
                        driverLicenseFileTypes.BACK_WITH_HOLOGRAM_SIGNS,
                    );

                    if (!hasFrontImage) {
                        setStep(steps.DISTANCE_FRONT_IMAGE);
                    } else if (!hasBackImageWithLetters) {
                        setStep(steps.DISTANCE_BACK_IMAGE_WITH_LETTERS);
                    } else if (!hasBackImageWithSigns) {
                        setStep(steps.DISTANCE_BACK_IMAGE_WITH_SIGNS);
                    } else if (
                        !driverLicense.isRequiredFrontDataComplete ||
                        driverLicense.hasUnconfirmedOcrData
                    ) {
                        setStep(steps.DISTANCE_FRONT_DATA);
                    } else if (
                        !driverLicense.isRequiredBackDataComplete ||
                        driverLicense.hasUnconfirmedOcrData
                    ) {
                        setStep(steps.DISTANCE_BACK_DATA);
                    }
                }
            } else {
                setStep(steps.CONTROL_METHOD_SELECTION);
            }
        }
    }, [driverLicenseControl, isInReCreateMode, step]);

    if (isLoading) {
        return <Loader />;
    }

    if (hasError) {
        return <RetryLoading onRetry={loadDriverLicenseControl} />;
    }

    const driverLicense = driverLicenseControl.driverLicense;
    const isInCreationProcess =
        driverLicenseControl &&
        (!driverLicense || driverLicense.isInCreationProcess);

    if (isInCreationProcess || isInReCreateMode) {
        const breadcrumbs = isAuthorized([roles.MANAGER]) ? (
            isUsingOnlyNewModules ? (
                <>
                    <Helmet>
                        <title>{`${driverLicenseControl.employee.lastName}, ${driverLicenseControl.employee.firstName} - Führerscheinkontrolle`}</title>
                    </Helmet>
                    <BackButton to="/measures/vehicles/driver-licenses" />
                    <Breadcrumb to="/measures/vehicles/driver-licenses">
                        Führerscheinkontrolle
                    </Breadcrumb>
                    <Breadcrumb
                        isActive
                    >{`${driverLicenseControl.employee.lastName}, ${driverLicenseControl.employee.firstName}`}</Breadcrumb>
                </>
            ) : (
                <>
                    <Helmet>
                        <title>{`${driverLicenseControl.employee.lastName}, ${driverLicenseControl.employee.firstName} - Führerscheinkontrolle - Fuhrpark - Maßnahmen`}</title>
                    </Helmet>
                    <BackButton to="/measures/vehicles/driver-licenses" />
                    <Breadcrumb to="/measures">Maßnahmen</Breadcrumb>
                    <Breadcrumb to="/measures/vehicles">Fuhrpark</Breadcrumb>
                    <Breadcrumb to="/measures/vehicles/driver-licenses">
                        Führerscheinkontrolle
                    </Breadcrumb>
                    <Breadcrumb
                        isActive
                    >{`${driverLicenseControl.employee.lastName}, ${driverLicenseControl.employee.firstName}`}</Breadcrumb>
                </>
            )
        ) : (
            <>
                <Helmet>
                    <title>{`${driverLicenseControl.employee.lastName}, ${driverLicenseControl.employee.firstName} - Führerscheinkontrolle - Maßnahmen`}</title>
                </Helmet>
                <BackButton to="/measures/vehicles/driver-licenses" />
                <Breadcrumb to={`/dashboard?module=${modules.MEASURES}`}>
                    Maßnahmen
                </Breadcrumb>
                <Breadcrumb to="/measures/vehicles/driver-licenses">
                    Führerscheinkontrolle
                </Breadcrumb>
                <Breadcrumb
                    isActive
                >{`${driverLicenseControl.employee.lastName}, ${driverLicenseControl.employee.firstName}`}</Breadcrumb>
            </>
        );

        // eslint-disable-next-line default-case
        switch (step) {
            case steps.CONTROL_METHOD_SELECTION:
                return (
                    <>
                        {breadcrumbs}
                        <DriverLicenseControlMethodSelection
                            driverLicenseControl={driverLicenseControl}
                            onConfirm={handleConfirmControlMethodSelection}
                            shouldReCreate={isInReCreateMode}
                        />
                    </>
                );

            case steps.DIRECT_FRONT_IMAGE:
                return (
                    <>
                        {breadcrumbs}
                        <DriverLicenseImageUpload
                            key={driverLicenseFileTypes.FRONT_WITH_HOLOGRAM}
                            type={driverLicenseFileTypes.FRONT_WITH_HOLOGRAM}
                            driverLicenseControl={driverLicenseControl}
                            title="1/4 Abbildung Führerscheinvorderseite"
                            onConfirm={() => goToStep(steps.DIRECT_BACK_IMAGE)}
                            onBackButtonClick={() =>
                                setStep(steps.CONTROL_METHOD_SELECTION)
                            }
                        />
                    </>
                );

            case steps.DIRECT_BACK_IMAGE:
                return (
                    <>
                        {breadcrumbs}
                        <DriverLicenseImageUpload
                            key={driverLicenseFileTypes.BACK_WITH_HOLOGRAM}
                            type={driverLicenseFileTypes.BACK_WITH_HOLOGRAM}
                            driverLicenseControl={driverLicenseControl}
                            title="2/4 Abbildung Führerscheinrückseite"
                            onConfirm={() => goToStep(steps.DIRECT_FRONT_DATA)}
                            onBackButtonClick={() =>
                                setStep(steps.DIRECT_FRONT_IMAGE)
                            }
                        />
                    </>
                );

            case steps.DIRECT_FRONT_DATA:
                return (
                    <>
                        {breadcrumbs}
                        <DriverLicenseFrontDataForm
                            key={steps.DIRECT_FRONT_DATA}
                            driverLicenseControl={driverLicenseControl}
                            title="3/4 Fahrerdatenabgleich"
                            confirmButtonText="Abgleich übernehmen"
                            onConfirm={() => goToStep(steps.DIRECT_BACK_DATA)}
                            onBackButtonClick={() =>
                                setStep(steps.DIRECT_BACK_IMAGE)
                            }
                        />
                    </>
                );

            case steps.DIRECT_BACK_DATA:
                return (
                    <>
                        {breadcrumbs}
                        <DriverLicenseBackDataForm
                            key={steps.DIRECT_BACK_DATA}
                            driverLicenseControl={driverLicenseControl}
                            title="4/4 Fahrerdatenabgleich"
                            onConfirm={handleConfirmBackData}
                            onBackButtonClick={() =>
                                setStep(steps.DIRECT_FRONT_DATA)
                            }
                        />
                    </>
                );

            case steps.DISTANCE_FRONT_IMAGE:
                return (
                    <>
                        {breadcrumbs}
                        <DriverLicenseImageUpload
                            key={driverLicenseFileTypes.FRONT_WITH_HOLOGRAM}
                            type={driverLicenseFileTypes.FRONT_WITH_HOLOGRAM}
                            driverLicenseControl={driverLicenseControl}
                            title="1/5 Abbildung Führerscheinvorderseite"
                            onConfirm={() =>
                                goToStep(steps.DISTANCE_BACK_IMAGE_WITH_LETTERS)
                            }
                            onBackButtonClick={() =>
                                setStep(steps.CONTROL_METHOD_SELECTION)
                            }
                            shouldPreProcessImage={
                                isUsingPremiumDriverLicenseModule
                            }
                        />
                    </>
                );

            case steps.DISTANCE_BACK_IMAGE_WITH_LETTERS:
                return (
                    <>
                        {breadcrumbs}
                        <DriverLicenseImageUpload
                            key={
                                driverLicenseFileTypes.BACK_WITH_HOLOGRAM_LETTERS
                            }
                            type={
                                driverLicenseFileTypes.BACK_WITH_HOLOGRAM_LETTERS
                            }
                            driverLicenseControl={driverLicenseControl}
                            title="2/5 Abbildung Führerscheinrückseite"
                            onConfirm={() =>
                                goToStep(steps.DISTANCE_BACK_IMAGE_WITH_SIGNS)
                            }
                            onBackButtonClick={() =>
                                setStep(steps.DISTANCE_FRONT_IMAGE)
                            }
                            shouldPreProcessImage={
                                isUsingPremiumDriverLicenseModule
                            }
                        />
                    </>
                );

            case steps.DISTANCE_BACK_IMAGE_WITH_SIGNS:
                return (
                    <>
                        {breadcrumbs}
                        <DriverLicenseImageUpload
                            key={
                                driverLicenseFileTypes.BACK_WITH_HOLOGRAM_SIGNS
                            }
                            type={
                                driverLicenseFileTypes.BACK_WITH_HOLOGRAM_SIGNS
                            }
                            driverLicenseControl={driverLicenseControl}
                            title="3/5 Abbildung Führerscheinrückseite"
                            onConfirm={() =>
                                goToStep(steps.DISTANCE_FRONT_DATA)
                            }
                            onBackButtonClick={() =>
                                setStep(steps.DISTANCE_BACK_IMAGE_WITH_LETTERS)
                            }
                            shouldPreProcessImage={
                                isUsingPremiumDriverLicenseModule
                            }
                        />
                    </>
                );

            case steps.DISTANCE_FRONT_DATA:
                return (
                    <>
                        {breadcrumbs}
                        <DriverLicenseFrontDataForm
                            key={steps.DISTANCE_FRONT_DATA}
                            driverLicenseControl={driverLicenseControl}
                            title="4/5 Fahrerdatenabgleich"
                            confirmButtonText="Abgleich übernehmen"
                            onConfirm={() => goToStep(steps.DISTANCE_BACK_DATA)}
                            onBackButtonClick={() =>
                                setStep(steps.DISTANCE_BACK_IMAGE_WITH_SIGNS)
                            }
                        />
                    </>
                );

            case steps.DISTANCE_BACK_DATA:
                return (
                    <>
                        {breadcrumbs}
                        <DriverLicenseBackDataForm
                            key={steps.DISTANCE_BACK_DATA}
                            driverLicenseControl={driverLicenseControl}
                            title="5/5 Fahrerdatenabgleich"
                            onConfirm={handleConfirmBackData}
                            onBackButtonClick={() =>
                                setStep(steps.DISTANCE_FRONT_DATA)
                            }
                        />
                    </>
                );
        }
    }

    return <Redirect to="/measures/vehicles/driver-licenses" />;
};

export default CreateDriverLicense;
