const withoutTopic = topicId => prevProjectOrTemplate => {
    const topics = [...prevProjectOrTemplate.topics];

    const index = topics.findIndex(topic => topic.id === topicId);

    if (index !== -1) {
        topics.splice(index, 1);
    }

    return {
        ...prevProjectOrTemplate,
        topics,
    };
};

export default withoutTopic;
