import React from 'react';
import styled from 'styled-components/macro';
import NoProjects from 'ProjectManager/Project/List/components/NoProjects';
import Overview from 'ProjectManager/Project/List/components/Overview/Overview';
import { useRecoilValue } from 'recoil';
import projectsTotalCountWithoutFiltersAtom from 'ProjectManager/Project/List/recoil/totalCount/projectsTotalCountWithoutFiltersAtom';
import Templates from 'ProjectManager/Template/List/components/Templates';
import OuterBox from 'Common/components/Boxes/OuterBox';

const MobileTemplatesWrapper = styled(OuterBox)`
    margin-top: 10px;
    padding: 20px;

    @media screen and (min-width: 1180px) {
        display: none;
    }
`;

const Projects = () => {
    const totalCountWithoutFilters = useRecoilValue(
        projectsTotalCountWithoutFiltersAtom,
    );

    const hasAnyProjects = totalCountWithoutFilters > 0;

    if (hasAnyProjects) {
        return <Overview />;
    }

    return (
        <>
            <NoProjects />
            <MobileTemplatesWrapper>
                <Templates />
            </MobileTemplatesWrapper>
        </>
    );
};

export default Projects;
