import api from 'Application/api/api';

const createProjectFromTemplate = (clientId, templateId, cancelToken = null) =>
    api.post(
        `project-manager/clients/${clientId}/project/from-template/${templateId}`,
        {},
        { cancelToken },
    );

export default createProjectFromTemplate;
