import api from 'Application/api/api';

const updateProjectCoordinator = (
    projectId,
    projectCoordinatorId,
    data,
    cancelToken = null,
) =>
    api.post(
        `project-manager/projects/${projectId}/project-coordinators/${projectCoordinatorId}`,
        data,
        { cancelToken },
    );

export default updateProjectCoordinator;
