import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import deleteProjectQuestionFile from 'ProjectManager/Project/Common/api/dataManagement/questionnaire/question/files/deleteProjectQuestionFile';
import getProjectQuestionFiles from 'ProjectManager/Project/Common/api/dataManagement/questionnaire/question/files/getProjectQuestionFiles';
import withQuestionFiles from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/withQuestionFiles';

const useProjectQuestionFileFunctions = (questionId, isCustomQuestion) => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const generateDownloadLink = useCallback(
        fileId =>
            `/api/project-manager/projects/${projectId}/questionnaire/questions/${questionId}/${
                isCustomQuestion ? 1 : 0
            }/files/${fileId}`,
        [isCustomQuestion, projectId, questionId],
    );

    const deleteQuestionFile = useCallback(
        async fileId => {
            await deleteProjectQuestionFile(
                projectId,
                questionId,
                isCustomQuestion,
                fileId,
                source.token,
            );

            const filesResponse = await getProjectQuestionFiles(
                projectId,
                questionId,
                isCustomQuestion,
                source.token,
            );

            setProject(
                withQuestionFiles(
                    questionId,
                    isCustomQuestion,
                    filesResponse.data,
                ),
            );
        },
        [projectId, questionId, isCustomQuestion, setProject, source.token],
    );

    return { generateDownloadLink, deleteQuestionFile };
};

export default useProjectQuestionFileFunctions;
