import api from 'Application/api/api';

const changeProjectAddress = (projectId, address, cancelToken = null) =>
    api.post(
        `project-manager/projects/${projectId}/change-address`,
        {
            address,
        },
        { cancelToken },
    );

export default changeProjectAddress;
