import styled from 'styled-components/macro';
import {
    Tab as ReactTab,
    TabList as ReactTabList,
    TabPanel as ReactTabPanel,
    Tabs as ReactTabs,
} from 'react-tabs';
import colors from 'Common/constants/colors';

export const Tabs = styled(ReactTabs)``;

export const TabList = styled(ReactTabList)`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 -10px;
    padding: 0;
    background: ${colors.PAGE_BACKGROUND};
    list-style-type: none;
    text-align: center;
    z-index: 5;
`;

export const StickyTabList = styled(TabList)`
    @media screen and (min-width: 940px) {
        position: sticky;
        top: 80px;
    }
`;

export const Tab = styled(ReactTab)`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-left: 10px;
    margin-bottom: 10px;
    min-width: 250px;
    padding: 13px 15px;
    background: ${colors.OUTER_BOX_BACKGROUND};
    color: ${colors.LIGHTER_GRAY};
    font-size: 14px;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: 0 7px 10px rgba(0, 0, 0, 0.16);

    &:focus {
        outline: 0 none;
    }

    &.react-tabs__tab--selected {
        background: ${colors.DARK_DIVIDER};
        color: ${colors.LIGHTER_GRAY};

        &:focus {
            outline: 0 none;
        }
    }

    @media screen and (min-width: 940px) {
        font-size: 16px;
    }
`;

export const TabPanel = styled(ReactTabPanel)``;
