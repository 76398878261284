import React from 'react';
import PropTypes from 'prop-types';
import LabeledValue from 'ProjectManager/Project/Preview/LabeledValue';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import formatDate from 'Common/utils/formatDate';
import parseDate from 'Common/utils/parseDate';

const RelevantDate = ({ introduction }) => {
    const {
        status: projectStatus,
        startingDate: projectStartingDate,
    } = useRecoilValue(projectAtom);

    let label = 'Durchgeführt am';
    let value = '-';

    if (introduction.isCanceled) {
        label = 'Abgesagt am';
        value = formatDate(parseDate(introduction.canceledAt));
    } else if (introduction.isConducted) {
        label = 'Durchgeführt am';
        value = formatDate(parseDate(introduction.conductedAt));
    } else if (projectStatus === projectStatuses.ON_STANDBY) {
        label = 'Durchführbar ab';
        value = formatDate(parseDate(projectStartingDate));
    }

    return <LabeledValue label={label}>{value}</LabeledValue>;
};

RelevantDate.propTypes = {
    introduction: PropTypes.object.isRequired,
};

export default RelevantDate;
