import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import GroupAssignmentEmployeeSignatures from 'Education/components/instruction-assignment/exam/GroupAssignmentEmployeeSignatures';
import OuterBox from 'Common/components/Boxes/OuterBox';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';
import { ArrowBackRounded } from '@material-ui/icons';
import OuterBoxButtons from 'Common/components/Boxes/OuterBoxButtons';

const Wrapper = styled(OuterBox)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 20px;
    text-align: center;

    @media screen and (min-width: 940px) {
        padding: 50px;
    }
`;

const Title = styled.div`
    font-size: 18px;
    line-height: 27px;

    @media screen and (min-width: 940px) {
        font-size: 22px;
        line-height: 33px;
    }
`;

const SubTitle = styled.div`
    margin-bottom: 40px;
    color: ${colors.GRAY};
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;

    @media screen and (min-width: 940px) {
        font-size: 18px;
        line-height: 27px;
    }
`;

const SignatureQuestion = styled.div`
    margin-bottom: 40px;
`;

const SignatureQuestionText = styled.div`
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 21px;

    @media screen and (min-width: 940px) {
        font-size: 18px;
        line-height: 27px;
    }
`;

const VerticalButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;

    > a,
    > button {
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 520px) {
        > a,
        > button {
            width: 100%;
        }
    }
`;

const CompletedExamScreen = ({
    assignment,
    onShowExamMistakes,
    onSignaturesUpdates,
}) => {
    const [isSignatureScreenVisible, setIsSignatureScreenVisible] = useState(
        false,
    );

    const handleShowSignatureScreen = () => {
        setIsSignatureScreenVisible(true);
    };

    const handleHideSignatureScreen = () => {
        setIsSignatureScreenVisible(false);
    };

    let areSignaturesForGroupAssignmentPlaced = false;

    if (assignment.isGroupAssignment) {
        const groupAssignmentDetails = assignment.groupAssignmentDetails;

        const groupLeader = groupAssignmentDetails.groupLeader;
        const assignees = groupAssignmentDetails.assignees;
        const assigneesWhoSigned = assignees.filter(
            assignee => assignee.isSignaturePlaced,
        );

        areSignaturesForGroupAssignmentPlaced =
            groupLeader.isSignaturePlaced &&
            assigneesWhoSigned.length === assignees.length;
    }

    if (isSignatureScreenVisible) {
        return (
            <>
                <Wrapper>
                    <GroupAssignmentEmployeeSignatures
                        assignment={assignment}
                        onSignatureConfirmed={onSignaturesUpdates}
                    />
                </Wrapper>
                <OuterBoxButtons>
                    <Button
                        text
                        type="button"
                        icon={<ArrowBackRounded />}
                        onClick={handleHideSignatureScreen}
                    >
                        Zurück
                    </Button>
                    <Button to="/dashboard">Abschließen</Button>
                </OuterBoxButtons>
            </>
        );
    }

    return (
        <Wrapper>
            <Title>Herzlichen Glückwunsch</Title>
            <SubTitle>
                {assignment.isGroupAssignment
                    ? 'Die Gruppe hat den Test erfolgreich bestanden'
                    : 'Sie haben den Test erfolgreich bestanden'}
            </SubTitle>
            {assignment.isGroupAssignment &&
                !areSignaturesForGroupAssignmentPlaced && (
                    <SignatureQuestion>
                        <SignatureQuestionText>
                            Möchten Sie jetzt als Gruppenleiter den Teilnehmer
                            die Möglichkeit geben, die Teilnahmen zu bestätigen?
                        </SignatureQuestionText>
                        <VerticalButtonsWrapper>
                            <Button
                                outline
                                type="button"
                                onClick={handleShowSignatureScreen}
                            >
                                Zur Bestätigung der Teilnehmer
                            </Button>
                        </VerticalButtonsWrapper>
                    </SignatureQuestion>
                )}
            <VerticalButtonsWrapper>
                <Button outline type="button" onClick={onShowExamMistakes}>
                    Prüfungsergebnisse anzeigen
                </Button>
                <Button to="/dashboard">Abschließen</Button>
            </VerticalButtonsWrapper>
        </Wrapper>
    );
};

CompletedExamScreen.propTypes = {
    assignment: PropTypes.object.isRequired,
    onShowExamMistakes: PropTypes.func.isRequired,
    onSignaturesUpdates: PropTypes.func.isRequired,
};

export default CompletedExamScreen;
