import React, { useState } from 'react';
import QuickAction from 'ProjectManager/Project/Preview/QuickActions/QuickAction';
import { CloudDownloadRounded } from '@material-ui/icons';
import DownloadModal from 'ProjectManager/Project/Preview/QuickActions/DownloadAction/components/Modal/DownloadModal';

const DownloadAction = () => {
    const [isModalOpened, setIsModalOpened] = useState(false);

    const handleClick = () => {
        setIsModalOpened(true);
    };

    const handleModalClose = () => {
        setIsModalOpened(false);
    };

    return (
        <>
            <QuickAction
                icon={<CloudDownloadRounded />}
                title="Report anfordern"
                description="Das PDF Dokument wird auf Basis der aktuellsten Einträge erstellt."
                type="button"
                onClick={handleClick}
            />
            {isModalOpened && <DownloadModal onClose={handleModalClose} />}
        </>
    );
};

export default DownloadAction;
