import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import axios from 'axios';
import showProjectQuestion from 'ProjectManager/Project/Common/api/dataManagement/questionnaire/question/showProjectQuestion';
import withQuestionVisibility from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/withQuestionVisibility';

const useShowProjectQuestionFunction = (questionId, isCustomQuestion) => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const showQuestion = useCallback(async () => {
        await showProjectQuestion(
            projectId,
            questionId,
            isCustomQuestion,
            source.token,
        );

        setProject(withQuestionVisibility(questionId, isCustomQuestion, true));
    }, [projectId, questionId, isCustomQuestion, setProject, source.token]);

    return showQuestion;
};

export default useShowProjectQuestionFunction;
