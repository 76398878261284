import styled from 'styled-components/macro';

const NoItemsText = styled.div`
    margin: 5px 0 20px;
    font-size: 15px;
    line-height: 22px;
    text-align: center;

    @media screen and (min-width: 1180px) {
        font-size: 22px;
        line-height: 33px;
    }
`;

export default NoItemsText;
