import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
    DefaultTableBody,
    DefaultTableHead,
    Table,
} from 'Common/components/table';
import InstructionAreaEditModal from 'Education/components/instruction-area/InstructionAreaEditModal';
import InstructionAreaDeleteModal from 'Education/components/instruction-area/InstructionAreaDeleteModal';
import { useSortBy, useTable } from 'react-table';
import { useCustomCellStyles } from 'Common/hooks/useCustomCellStyles';
import { useResponsiveTable } from 'Common/hooks/useResponsiveTable';
import styled from 'styled-components/macro';
import { DeleteRounded, EditRounded } from '@material-ui/icons';
import MoreActionsButton from 'Common/components/MoreActionsButton/MoreActionsButton';
import MenuItem from 'Common/components/MoreActionsButton/MenuItem';
import { orderBy } from 'lodash';
import stableTableSort from 'Common/utils/stableTableSort';

const StyledTableHead = styled(DefaultTableHead)`
    th {
        top: 80px;

        @media screen and (min-width: 940px) {
            top: 134px;
        }
    }
`;

const InstructionAreasTable = ({
    instructionAreas,
    onAreaUpdate,
    onAreaDelete,
}) => {
    const [areaIdForEdit, setAreaIdForEdit] = useState(null);
    const [areaIdForDeletion, setAreaIdForDeletion] = useState(null);

    const handleEditButtonClick = areaId => {
        setAreaIdForEdit(areaId);
    };

    const handleEditCancel = () => {
        setAreaIdForEdit(null);
    };

    const handleEditSuccess = async () => {
        await onAreaUpdate(areaIdForEdit);
        setAreaIdForEdit(null);
    };

    const handleDeleteButtonClick = areaId => {
        setAreaIdForDeletion(areaId);
    };

    const handleDeleteCancel = () => {
        setAreaIdForDeletion(null);
    };

    const handleDeleteSuccess = () => {
        if (areaIdForDeletion) {
            onAreaDelete(areaIdForDeletion);
            setAreaIdForDeletion(null);
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Beschreibung',
                accessor: 'description',
            },
            {
                Header: 'Anzahl der Unterweisungen',
                accessor: 'instructionsCount',
            },
            {
                Header: 'Aktion',
                accessor: 'actions',
                disableSortBy: true,
                customCellStyles: {
                    textAlign: 'center',
                },
            },
        ],
        [],
    );

    const data = useMemo(
        () =>
            orderBy(
                instructionAreas.map(area => {
                    const isDeleteDisabled =
                        area.instructionsCount > 0 || area.employeesCount > 0;

                    return {
                        name: area.name,
                        description: area.description,
                        instructionsCount: area.instructionsCount,
                        actions: (
                            <MoreActionsButton>
                                <MenuItem
                                    icon={<EditRounded />}
                                    onClick={() =>
                                        handleEditButtonClick(area.id)
                                    }
                                >
                                    Bearbeiten
                                </MenuItem>
                                <MenuItem
                                    icon={<DeleteRounded />}
                                    onClick={() =>
                                        handleDeleteButtonClick(area.id)
                                    }
                                    disabled={isDeleteDisabled}
                                    tooltip={
                                        isDeleteDisabled
                                            ? 'Das Löschen von Bereichen ist nicht erlaubt!'
                                            : ''
                                    }
                                >
                                    Löschen
                                </MenuItem>
                            </MoreActionsButton>
                        ),
                    };
                }),
                ['name'],
                ['asc'],
            ),
        [instructionAreas],
    );

    const initialSortBy = useMemo(
        () => [
            {
                id: 'name',
                desc: false,
            },
        ],
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { hiddenColumns },
    } = useTable(
        {
            columns,
            data,
            hideColumnsPriority: [
                'description',
                'instructionsCount',
                'actions',
            ],
            disableSortRemove: true,
            disableMultiSort: true,
            initialState: {
                sortBy: initialSortBy,
            },
            orderByFn: stableTableSort,
        },
        useCustomCellStyles,
        useResponsiveTable,
        useSortBy,
    );
    return (
        <>
            <Table {...getTableProps()}>
                <StyledTableHead headerGroups={headerGroups} />
                <DefaultTableBody
                    rows={rows}
                    hiddenColumns={hiddenColumns}
                    visibleColumns={visibleColumns}
                    getTableBodyProps={getTableBodyProps}
                    prepareRow={prepareRow}
                />
            </Table>
            {areaIdForEdit !== null && (
                <InstructionAreaEditModal
                    areaId={areaIdForEdit}
                    onCancel={handleEditCancel}
                    onSuccess={handleEditSuccess}
                />
            )}
            {areaIdForDeletion !== null && (
                <InstructionAreaDeleteModal
                    area={
                        areaIdForDeletion
                            ? instructionAreas.find(
                                  area => area.id === areaIdForDeletion,
                              )
                            : null
                    }
                    onCancel={handleDeleteCancel}
                    onSuccess={handleDeleteSuccess}
                />
            )}
        </>
    );
};

InstructionAreasTable.propTypes = {
    instructionAreas: PropTypes.array.isRequired,
    onAreaUpdate: PropTypes.func.isRequired,
    onAreaDelete: PropTypes.func.isRequired,
};

export default InstructionAreasTable;
