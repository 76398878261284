import api from 'Application/api/api';

const createProjectCustomSubsection = (
    projectId,
    sectionOrCustomTopicId,
    isCustomTopic,
    data,
    cancelToken = null,
) =>
    api.post(
        `project-manager/projects/${projectId}/questionnaire/sections/${sectionOrCustomTopicId}/${
            isCustomTopic ? 1 : 0
        }/custom-subsections`,
        data,
        { cancelToken },
    );

export default createProjectCustomSubsection;
