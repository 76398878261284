import React, { useEffect, useState } from 'react';
import scrollToTop from 'Common/utils/scrollToTop';
import { navigationItems } from 'ProjectManager/DataManagement/Navigation/constants/navigation';
import DataManagementBottomButtons from 'ProjectManager/DataManagement/Common/components/DataManagementBottomButtons';
import Button from 'Common/components/Button';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { Helmet } from 'react-helmet-async';
import BackButton from 'Application/components/Header/BackButton/BackButton';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import DataManagement from 'ProjectManager/DataManagement/Common/components/DataManagement';

const Project = () => {
    const { id, name } = useRecoilValue(projectAtom);

    const [activeNavigationItem, setActiveNavigationItem] = useState(
        navigationItems.TOPIC_MANAGEMENT,
    );

    // Each time the step is changed, scroll to the top of the page,
    // as if the page has changed.
    useEffect(() => {
        scrollToTop();
    }, [activeNavigationItem]);

    const dispatch = useDispatch();

    const handleBackButtonClick = () => {
        dispatch(push(`/project-manager/project/${id}/preview`));
    };

    const title = name ? name : 'Kein Name';

    return (
        <>
            <Helmet>
                <title>{title} - Projektübersicht</title>
            </Helmet>
            <BackButton to={`/project-manager/project/${id}/preview`} />
            <Breadcrumb to="/project-manager">Projektübersicht</Breadcrumb>
            <Breadcrumb to={`/project-manager/project/${id}/preview`}>
                {title}
            </Breadcrumb>
            <Breadcrumb isActive>Bearbeiten</Breadcrumb>
            <DataManagement
                title={title}
                activeNavigationItem={activeNavigationItem}
                onActiveNavigationItemChange={setActiveNavigationItem}
            />
            <DataManagementBottomButtons append>
                <Button type="button" onClick={handleBackButtonClick}>
                    Zurück zum Projekt
                </Button>
            </DataManagementBottomButtons>
        </>
    );
};

export default Project;
