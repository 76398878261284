import getClients from 'ProjectManager/Client/api/getClients';
import mapClientsToOptions from 'ProjectManager/Project/DataManagement/GeneralInformation/Client/helpers/mapClientsToOptions';

const loadClientOptions = async query => {
    const response = await getClients();
    const clients = response.data;

    if (!query) {
        return mapClientsToOptions(clients);
    }

    const searchTerm = query.toLowerCase();

    return mapClientsToOptions(
        clients.filter(client =>
            client.name.toLowerCase().includes(searchTerm),
        ),
    );
};

export default loadClientOptions;
