import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Upload from 'ProjectManager/DataManagement/Files/components/Upload';
import FilesList from 'ProjectManager/DataManagement/Files/components/FilesList';
import DownloadAllFilesButton from 'ProjectManager/DataManagement/Files/components/DownloadAllFilesButton';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import useFiles from 'ProjectManager/DataManagement/Files/hooks/files/useFiles';

const FilesTitle = styled(OuterBoxTitle)`
    display: block;

    @media screen and (min-width: 480px) {
        display: flex;
        align-items: center;
    }
`;

const FilesButtonWrapper = styled.div`
    margin-top: 20px;

    @media screen and (min-width: 480px) {
        display: flex;
        align-items: center;
        margin-top: 0;
        margin-left: 15px;
    }
`;

const ListWrapper = styled.div`
    margin-top: 20px;
`;

const Files = ({ title }) => {
    const files = useFiles();

    return (
        <>
            <FilesTitle>
                {title}
                {files.length > 1 && (
                    <FilesButtonWrapper>
                        <DownloadAllFilesButton />
                    </FilesButtonWrapper>
                )}
            </FilesTitle>
            <Upload />
            <ListWrapper>
                <FilesList files={files} />
            </ListWrapper>
        </>
    );
};

Files.propTypes = {
    title: PropTypes.string.isRequired,
};

export default Files;
