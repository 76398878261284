import React from 'react';
import PropTypes from 'prop-types';
import Input from 'Common/components/Form/Fields/Input';
import { get } from 'lodash';
import { DeleteRounded, PersonAddRounded } from '@material-ui/icons';
import styled from 'styled-components/macro';
import Button from 'Common/components/Button';
import { useWindowWidth } from '@react-hook/window-size';
import { v4 as uuidv4 } from 'uuid';
import ModalSectionTitle from 'Common/components/Modals/ModalSectionTitle';

const ContactPeople = styled.div`
    margin-bottom: 15px;
`;

const ContactPerson = styled.div`
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: 580px) {
        display: flex;
        align-items: flex-start;
    }
`;

const InlineField = styled.div`
    margin-bottom: 10px;

    &:last-of-type {
        margin-bottom: 0;
    }

    @media screen and (min-width: 580px) {
        margin-right: 10px;
        margin-bottom: 0;

        &:last-of-type {
            margin-right: 0;
        }
    }
`;

const MobileDeleteButton = styled(Button)`
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 0;
`;

const DesktopDeleteButton = styled(Button)`
    position: relative;
    top: 7px;
`;

const ContactPeopleFields = ({
    contactPeople,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    onContactPersonAdd,
}) => {
    const addContactPerson = () => {
        const newContactPeople = [...contactPeople];

        newContactPeople.push({
            id: uuidv4(),
            name: '',
            email: '',
        });

        setFieldValue('contactPeople', newContactPeople);

        onContactPersonAdd();
    };

    const removeContactPerson = id => {
        const newContactPeople = [...contactPeople];

        const index = newContactPeople.findIndex(
            contactPerson => contactPerson.id === id,
        );

        if (index !== -1) {
            newContactPeople.splice(index, 1);

            setFieldError('contactPeople', []);
            setFieldTouched('contactPeople', []);
            setFieldValue('contactPeople', newContactPeople, true);
        }
    };

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 580;

    const handleEmailKeyDown = e => {
        if (e.key === ' ') {
            e.preventDefault();
        }
    };

    const handleEmailInput = e => {
        e.target.value = e.target.value.replace(/\s/g, '');
    };

    return (
        <>
            <ModalSectionTitle>Kontaktperson</ModalSectionTitle>
            <ContactPeople>
                {contactPeople.map((contactPerson, index) => {
                    const fieldsPrefix = `contactPeople[${index}]`;

                    return (
                        <ContactPerson key={contactPerson.id}>
                            <InlineField>
                                <Input
                                    id={`${fieldsPrefix}.name`}
                                    name={`${fieldsPrefix}.name`}
                                    label="Name"
                                    value={contactPerson.name}
                                    error={get(errors, `[${index}].name`)}
                                    touched={get(touched, `[${index}].name`)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isRequired
                                />
                            </InlineField>
                            <InlineField>
                                <Input
                                    id={`${fieldsPrefix}.email`}
                                    name={`${fieldsPrefix}.email`}
                                    label="E-Mail"
                                    value={contactPerson.email}
                                    error={get(errors, `[${index}].email`)}
                                    touched={get(touched, `[${index}].email`)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyDown={handleEmailKeyDown}
                                    onInput={handleEmailInput}
                                    isRequired
                                />
                            </InlineField>
                            {contactPeople.length > 1 && (
                                <>
                                    {isMobile ? (
                                        <MobileDeleteButton
                                            dark
                                            underline
                                            icon={<DeleteRounded />}
                                            type="button"
                                            onClick={() =>
                                                removeContactPerson(
                                                    contactPerson.id,
                                                )
                                            }
                                        >
                                            Löschen
                                        </MobileDeleteButton>
                                    ) : (
                                        <DesktopDeleteButton
                                            dark
                                            iconOnly
                                            icon={<DeleteRounded />}
                                            type="button"
                                            onClick={() =>
                                                removeContactPerson(
                                                    contactPerson.id,
                                                )
                                            }
                                            tooltip="Löschen"
                                        />
                                    )}
                                </>
                            )}
                        </ContactPerson>
                    );
                })}
            </ContactPeople>
            <Button
                dark
                underline
                icon={<PersonAddRounded />}
                type="button"
                onClick={addContactPerson}
            >
                Weitere hinzufügen
            </Button>
        </>
    );
};

ContactPeopleFields.propTypes = {
    contactPeople: PropTypes.array.isRequired,
    errors: PropTypes.array.isRequired,
    touched: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldError: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    onContactPersonAdd: PropTypes.func.isRequired,
};

export default ContactPeopleFields;
