const withAdditionalTopic = topic => prevProjectOrTemplate => {
    const topics = [...prevProjectOrTemplate.topics];

    topics.push(topic);

    return {
        ...prevProjectOrTemplate,
        topics,
    };
};

export default withAdditionalTopic;
