import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'Common/components/Drawer';
import { useSelector } from 'react-redux';
import roles from 'User/constants/roles';
import SupervisorSelection from 'ProjectManager/Project/Introductions/Create/components/Steps/SupervisorSelection';
import PointsSelection
    from 'ProjectManager/Project/Introductions/Create/components/Steps/PointsSelection/PointsSelection';
import EmployeesSelection
    from 'ProjectManager/Project/Introductions/Create/components/Steps/EmployeeSelection/EmployeesSelection';
import AbortModal from 'ProjectManager/Project/Introductions/Create/components/AbortModal';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import supervisorUserOptionAtom
    from 'ProjectManager/Project/Introductions/Create/recoil/supervisorUserOption/supervisorUserOptionAtom';
import pointsAtom from 'ProjectManager/Project/Introductions/Create/recoil/points/pointsAtom';
import ppesAtom from 'ProjectManager/Project/Introductions/Create/recoil/ppes/ppesAtom';
import defaultComment from 'ProjectManager/Project/Introductions/Create/recoil/comment/defaultComment';
import commentAtom from 'ProjectManager/Project/Introductions/Create/recoil/comment/commentAtom';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import getPoints from 'ProjectManager/Project/Introductions/Common/api/getPoints';
import getPPEs from 'ProjectManager/Project/Introductions/Common/api/getPPEs';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import mapUserToOption from 'User/utils/mapUserToOption';
import participantsAtom from 'ProjectManager/Project/Introductions/Create/recoil/participants/participantsAtom';
import introducedProjectParticipantIdsSelector
    from 'ProjectManager/Project/Common/recoil/project/selectors/introducedProjectParticipantIdsSelector';
import getProject from 'ProjectManager/Project/Common/api/getProject';
import axios from 'axios';
import { pick } from 'lodash';

const steps = {
    SUPERVISOR_SELECTION: 'SUPERVISOR_SELECTION',
    POINTS_SELECTION: 'POINTS_SELECTION',
    EMPLOYEES_SELECTION: 'EMPLOYEES_SELECTION',
};

const CreateDrawer = ({ isOpened, onOpen, onClose, ...props }) => {
    const setSelectedPoints = useSetRecoilState(pointsAtom);

    const {
        data: points,
        loadData: loadPoints,
        isLoading: isLoadingPoints,
        hasError: hasPointsError,
    } = useAxiosRequest(getPoints, []);

    const defaultSelectedPoints = useMemo(
        () => points.map(point => point.value),
        [points],
    );

    // Initially, when all points load (for the first time), set them in Recoil
    useEffect(() => {
        setSelectedPoints(defaultSelectedPoints);
    }, [defaultSelectedPoints, setSelectedPoints]);

    const setSelectedPPEs = useSetRecoilState(ppesAtom);

    const {
        data: ppes,
        loadData: loadPPEs,
        isLoading: isLoadingPPEs,
        hasError: hasPPEsError,
    } = useAxiosRequest(getPPEs, []);

    const defaultSelectedPPEs = useMemo(() => ppes.map(ppe => ppe.value), [
        ppes,
    ]);

    useEffect(() => {
        setSelectedPPEs(defaultSelectedPPEs);
    }, [defaultSelectedPPEs, setSelectedPPEs]);

    const currentUserId = useSelector(state => state.user.id);

    const setSupervisorUserOption = useSetRecoilState(supervisorUserOptionAtom);

    const { supervisors } = useRecoilValue(projectAtom);

    // Set the default value of the drop-down, but only if it currently
    // doesn't have an option selected.
    const defaultSupervisorUserOption = useMemo(() => {
        const user =
            supervisors
                .filter(supervisor => !supervisor.isUnAssigned)
                .find(supervisor => supervisor.user.id === currentUserId)
                ?.user ?? null;

        if (user) {
            return mapUserToOption(user);
        }

        return null;
    }, [supervisors, currentUserId]);

    useEffect(() => {
        setSupervisorUserOption(defaultSupervisorUserOption);
    }, [defaultSupervisorUserOption, setSupervisorUserOption]);

    const { participants } = useRecoilValue(projectAtom);
    const setSelectedParticipants = useSetRecoilState(participantsAtom);

    const assignedParticipants = useMemo(
        () => participants.filter(participant => participant.isAssigned),
        [participants],
    );

    const introducedProjectParticipantIds = useRecoilValue(
        introducedProjectParticipantIdsSelector,
    );

    const nonIntroducedProjectParticipants = useMemo(
        () =>
            assignedParticipants.filter(
                participant =>
                    !introducedProjectParticipantIds.includes(participant.id),
            ),
        [introducedProjectParticipantIds, assignedParticipants],
    );

    const defaultSelectedParticipants = useMemo(
        () =>
            (nonIntroducedProjectParticipants.length > 0
                    ? nonIntroducedProjectParticipants
                    : assignedParticipants
            ).map(participant => participant.id),
        [nonIntroducedProjectParticipants, assignedParticipants],
    );

    useEffect(() => {
        setSelectedParticipants(defaultSelectedParticipants);
    }, [setSelectedParticipants, defaultSelectedParticipants]);

    const role = useSelector(state => state.user.role);

    const calculateFirstStep = () => {
        if (role === roles.MANAGER) {
            return steps.SUPERVISOR_SELECTION;
        }

        return steps.POINTS_SELECTION;
    };

    const [step, setStep] = useState(calculateFirstStep);

    const [isAbortModalOpened, setIsAbortModalOpened] = useState(false);

    const openAbortModal = () => {
        setIsAbortModalOpened(true);
    };

    const closeAbortModal = () => {
        setIsAbortModalOpened(false);
    };

    const setPoints = useSetRecoilState(pointsAtom);
    const setPPEs = useSetRecoilState(ppesAtom);
    const setComment = useSetRecoilState(commentAtom);

    const abort = () => {
        closeAbortModal();
        onClose();
        setStep(calculateFirstStep());

        // Reset all the fields to their default values
        setSupervisorUserOption(defaultSupervisorUserOption);
        setPoints(defaultSelectedPoints);
        setPPEs(defaultSelectedPPEs);
        setComment(defaultComment);
        setSelectedParticipants(defaultSelectedParticipants);
    };

    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleCreate = async () => {
        const projectResponse = await getProject(
            projectId,
            [
                'statistics',
                'introductions',
                'introductions.supervisor',
                'introductions.participants',
                'introductions.participants.projectParticipant',
                'introductions.participants.projectParticipant.employee',
                'introductions.participants.projectParticipant.employee.user',
            ],
            source.token,
        );

        setProject(prevProject => ({
            ...prevProject,
            ...pick(projectResponse.data, ['statistics', 'introductions']),
        }));
    };

    return (
        <>
            <Drawer
                isOpened={isOpened}
                onOpen={onOpen}
                onClose={openAbortModal}
                backButtonProps={{
                    title: 'Zurück zum',
                    subtitle: 'Einweisung',
                }}
                {...props}
            >
                {isLoadingPoints || isLoadingPPEs ? (
                    <Loader />
                ) : hasPointsError ? (
                    <RetryLoading onRetry={loadPoints} />
                ) : hasPPEsError ? (
                    <RetryLoading onRetry={loadPPEs} />
                ) : (
                    <>
                        {step === steps.SUPERVISOR_SELECTION && (
                            <SupervisorSelection
                                onCancelButtonClick={openAbortModal}
                                onNextButtonClick={() => {
                                    setStep(steps.POINTS_SELECTION);
                                }}
                            />
                        )}
                        {step === steps.POINTS_SELECTION && (
                            <PointsSelection
                                points={points}
                                ppes={ppes}
                                onCancelButtonClick={openAbortModal}
                                onBackButtonClick={() => {
                                    setStep(steps.SUPERVISOR_SELECTION);
                                }}
                                onNextButtonClick={() => {
                                    setStep(steps.EMPLOYEES_SELECTION);
                                }}
                            />
                        )}
                        {step === steps.EMPLOYEES_SELECTION && (
                            <EmployeesSelection
                                onBackButtonClick={() => {
                                    setStep(steps.POINTS_SELECTION);
                                }}
                                onCreate={handleCreate}
                                onClose={abort}
                            />
                        )}
                    </>
                )}
            </Drawer>
            {isAbortModalOpened && (
                <AbortModal onCancel={closeAbortModal} onAbort={abort} />
            )}
        </>
    );
};

CreateDrawer.propTypes = {
    isOpened: PropTypes.bool.isRequired,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default CreateDrawer;
