import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';
import styled from 'styled-components/macro';
import SwitchableTile from 'Common/components/SwitchableTile';
import { useRecoilState, useRecoilValue } from 'recoil';
import pointsAtom from 'ProjectManager/Project/Introductions/Create/recoil/points/pointsAtom';
import withPoint from 'ProjectManager/Project/Introductions/Create/recoil/points/modifiers/withPoint';
import withoutPoint from 'ProjectManager/Project/Introductions/Create/recoil/points/modifiers/withoutPoint';
import Button from 'Common/components/Button';
import PPERefineModal from 'ProjectManager/Project/Introductions/Create/components/Steps/PointsSelection/PPERefineModal';
import ppesAtom from 'ProjectManager/Project/Introductions/Create/recoil/ppes/ppesAtom';

const RefineWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`;

const RefineButton = styled(Button)`
    padding-left: 0;
`;

const RefineCounter = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    ${ifProp(
        'isDisabled',
        css`
            opacity: 0.35;
        `,
    )};
`;

const PointTile = ({ point, isSelected, ppes }) => {
    const [selectedPoints, setSelectedPoints] = useRecoilState(pointsAtom);
    const selectedPPEs = useRecoilValue(ppesAtom);

    const handleSelect = async () => {
        setSelectedPoints(withPoint(point.value));
    };

    const handleDeselect = async () => {
        setSelectedPoints(withoutPoint(point.value));
    };

    const [isRefineModalOpened, setIsRefineModalOpened] = useState(false);

    const handleRefineButtonClick = () => {
        setIsRefineModalOpened(true);
    };

    const closeRefineModal = () => {
        setIsRefineModalOpened(false);
    };

    return (
        <>
            <SwitchableTile
                title={point.label}
                description={point.description}
                isSelected={isSelected}
                isDisabled={isSelected && selectedPoints.length === 1}
                disabledTooltipText="Mind. eine Einweisungspunkt muss ausgewählt werden"
                onSelect={handleSelect}
                onDeselect={handleDeselect}
            >
                {point.value === 'PERSONAL_PROTECTIVE_EQUIPMENT' && (
                    <RefineWrapper>
                        <RefineButton
                            type="button"
                            underline
                            onClick={handleRefineButtonClick}
                            disabled={!isSelected}
                        >
                            Verfeinern
                        </RefineButton>
                        <RefineCounter isDisabled={!isSelected}>
                            {`${selectedPPEs.length}/${ppes.length}`}
                        </RefineCounter>
                    </RefineWrapper>
                )}
            </SwitchableTile>
            {isRefineModalOpened && (
                <PPERefineModal ppes={ppes} onClose={closeRefineModal} />
            )}
        </>
    );
};

PointTile.propTypes = {
    point: PropTypes.object.isRequired,
    isSelected: PropTypes.bool.isRequired,
    ppes: PropTypes.array.isRequired,
};

export default PointTile;
