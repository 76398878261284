import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddTopic from 'Common/components/Icons/AddTopic';
import Button from 'Common/components/Button';
import styled from 'styled-components/macro';
import CreationWizard from 'ProjectManager/DataManagement/Questionnaire/components/QuestionCreation/components/CreationWizard/CreationWizard';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';

const WizardWrapper = styled.div`
    width: 100%;
    max-width: 450px;
`;

const QuestionCreationButton = ({ subsection, onWizardOpen }) => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);
    const isProject = dataManagementEntity === dataManagementEntities.PROJECT;

    const { status: projectStatus } = useRecoilValue(projectAtom);
    const isCreatingPossible = !isProject || projectStatus !== projectStatuses.ENDED;

    const [isWizardOpened, setIsWizardOpened] = useState(false);

    const handleQuestionCreationButtonClick = () => {
        setIsWizardOpened(true);
        onWizardOpen();
    };

    const handleWizardClose = () => {
        setIsWizardOpened(false);
    };

    if (isWizardOpened) {
        return (
            <WizardWrapper>
                <CreationWizard
                    subsection={subsection}
                    onCreate={handleWizardClose}
                    onCancel={handleWizardClose}
                />
            </WizardWrapper>
        );
    }

    return (
        <Button
            underline
            icon={<AddTopic />}
            type="button"
            onClick={handleQuestionCreationButtonClick}
            disabled={!isCreatingPossible}
            tooltip={
                !isCreatingPossible
                    ? 'Erstellung nicht erlaubt. Projekt ist im Status Beendet.'
                    : ''
            }
        >
            Eigene Frage erstellen
        </Button>
    );
};

QuestionCreationButton.propTypes = {
    subsection: PropTypes.object.isRequired,
    onWizardOpen: PropTypes.func.isRequired,
};

export default QuestionCreationButton;
