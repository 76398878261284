import React from 'react';
import styled from 'styled-components/macro';
import StatisticsTile from 'ProjectManager/Project/List/components/Overview/TopOverview/StatisticsTile';
import projectStatusesElements from 'ProjectManager/Project/Common/constants/projectStatusesElements';
import { useRecoilState, useRecoilValue } from 'recoil';
import projectsStatisticsAtom from 'ProjectManager/Project/List/recoil/statistics/projectsStatisticsAtom';
import projectsFiltersAtom from 'ProjectManager/Project/List/recoil/filters/projectsFiltersAtom';
import withStatusFilter from 'ProjectManager/Project/List/recoil/filters/modifiers/withStatusFilter';

const Wrapper = styled.div`
    @media screen and (max-width: 469px) {
        > div {
            width: 100%;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media screen and (min-width: 470px) and (max-width: 909px),
        screen and (min-width: 1180px) and (max-width: 1309px) {
        display: flex;
        flex-wrap: wrap;

        > div {
            flex: 0 0 calc(50% - 5px);
            width: calc(50% - 5px);
            margin-right: 10px;

            &:nth-child(2n) {
                margin-right: 0;
            }

            &:nth-child(n + 3) {
                margin-top: 10px;
            }
        }
    }

    @media screen and (min-width: 910px) and (max-width: 1179px),
        screen and (min-width: 1310px) {
        display: flex;

        > div {
            flex: 1;
            width: 100%;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
`;

const Statistics = () => {
    const statistics = useRecoilValue(projectsStatisticsAtom);

    const [{ statuses: statusFilters = [] }, setFilters] = useRecoilState(
        projectsFiltersAtom,
    );

    const handleToggleStatus = (status, isChecked) => {
        setFilters(withStatusFilter(status, isChecked));
    };

    return (
        <Wrapper>
            {Object.keys(statistics).map(projectStatus => {
                const statusElements = projectStatusesElements[projectStatus];
                const Icon = statusElements.icon;

                const isSelected = statusFilters.includes(projectStatus);

                return (
                    <StatisticsTile
                        key={projectStatus}
                        icon={<Icon />}
                        number={statistics[projectStatus]}
                        text={statusElements.text}
                        color={statusElements.color}
                        isSelected={isSelected}
                        isDisabled={isSelected && statusFilters.length === 1}
                        onToggle={e =>
                            handleToggleStatus(projectStatus, e.target.checked)
                        }
                    />
                );
            })}
        </Wrapper>
    );
};

export default Statistics;
