import React, { Fragment } from 'react';
import styled from 'styled-components/macro';
import ProjectCoordinatorTile from 'ProjectManager/Project/DataManagement/GeneralInformation/ProjectCoordinators/ProjectCoordinatorTile/ProjectCoordinatorTile';
import { useWindowWidth } from '@react-hook/window-size';
import useWindowScroll from '@react-hook/window-scroll';
import AddProjectCoordinatorButton from 'ProjectManager/Project/DataManagement/GeneralInformation/ProjectCoordinators/AddProjectCoordinatorButton';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';

const List = styled.div`
    margin-bottom: 20px;

    > div {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 940px) {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-bottom: 0;

        > div {
            flex: 0 0 420px;
            width: 420px;
            margin-right: 10px;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 10px;
            }
        }
    }
`;

const DesktopColumn = styled.div`
    > div {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const AddButtonWrapper = styled.div`
    margin-top: 20px;
`;

const approximateTileHeight = 280;
const approximateStickyElementsHeight = 170;

const ProjectCoordinatorsManagement = () => {
    const { projectCoordinators } = useRecoilValue(projectAtom);

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 940;

    const scrollY = useWindowScroll();

    const handleAdd = () => {
        if (isMobile) {
            const distanceToAddButton = document
                .getElementById('addProjectCoordinatorButtonWrapper')
                .getBoundingClientRect().top;

            window.scroll({
                top:
                    scrollY +
                    distanceToAddButton -
                    approximateStickyElementsHeight -
                    approximateTileHeight,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    const handleFormOpen = () => {
        if (isMobile) {
            const distanceToAddButton = document
                .getElementById('addProjectCoordinatorButtonWrapper')
                .getBoundingClientRect().top;

            // The timeout is to ensure that the state will be set
            // and the distance to the button will be calculated properly.
            setTimeout(() => {
                window.scroll({
                    top:
                        scrollY +
                        distanceToAddButton -
                        approximateStickyElementsHeight -
                        15, // Ensure to have some spacing at the top
                    left: 0,
                    behavior: 'smooth',
                });
            }, 5);
        }
    };

    const oddProjectCoordinators = projectCoordinators.filter(
        (projectCoordinator, index) => (index + 1) % 2 !== 0,
    );

    const evenProjectCoordinators = projectCoordinators.filter(
        (projectCoordinator, index) => (index + 1) % 2 === 0,
    );

    const projectCoordinatorColumns = isMobile
        ? [projectCoordinators]
        : [oddProjectCoordinators, evenProjectCoordinators];

    const ColumnComponent = isMobile ? Fragment : DesktopColumn;

    return (
        <>
            <List>
                {projectCoordinatorColumns.map((projectCoordinators, index) => (
                    <ColumnComponent key={index}>
                        {projectCoordinators.map(projectCoordinator => (
                            <ProjectCoordinatorTile
                                key={projectCoordinator.id}
                                projectCoordinator={projectCoordinator}
                            />
                        ))}
                        {index === 0 && (
                            <AddButtonWrapper id="addProjectCoordinatorButtonWrapper">
                                <AddProjectCoordinatorButton
                                    onAdd={handleAdd}
                                    onFormOpen={handleFormOpen}
                                />
                            </AddButtonWrapper>
                        )}
                    </ColumnComponent>
                ))}
            </List>
        </>
    );
};

export default ProjectCoordinatorsManagement;
