import { createAction, createReducer } from 'redux-act';

export const authenticateUser = createAction(
    'Authenticate the user and set information about him/her, including e-mail, role and other personal information',
    userData => userData,
);

export const updateUser = createAction(
    'Update the information about the user, including e-mail, role and other personal information',
    userData => userData,
);

export const clearUserData = createAction('Clear all existing user data');

const initialUserData = {
    isAuthenticated: false,
    id: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    role: null,
    isUsingOnlyNewModules: false,
    isUsingPremiumDriverLicenseModule: false,
    isAutomaticSigningAllowed: false,
    availableModules: [],
    measuresForExamination: [],
};

export const userReducer = createReducer(
    {
        [authenticateUser]: (
            state,
            {
                id,
                email,
                firstName,
                lastName,
                title,
                role,
                isUsingOnlyNewModules,
                isUsingPremiumDriverLicenseModule,
                isAutomaticSigningAllowed,
                availableModules,
                measuresForExamination,
            },
        ) => ({
            ...state,
            isAuthenticated: true,
            id,
            email,
            firstName,
            lastName,
            title,
            role,
            isUsingOnlyNewModules,
            isUsingPremiumDriverLicenseModule,
            isAutomaticSigningAllowed,
            availableModules,
            measuresForExamination,
        }),
        [updateUser]: (
            state,
            {
                id,
                email,
                firstName,
                lastName,
                title,
                role,
                isUsingOnlyNewModules,
                isUsingPremiumDriverLicenseModule,
                isAutomaticSigningAllowed,
                availableModules,
                measuresForExamination,
            },
        ) => ({
            ...state,
            id,
            email,
            firstName,
            lastName,
            title,
            role,
            isUsingOnlyNewModules,
            isUsingPremiumDriverLicenseModule,
            isAutomaticSigningAllowed,
            availableModules,
            measuresForExamination,
        }),
        [clearUserData]: state => ({
            ...state,
            ...initialUserData,
        }),
    },
    {
        ...initialUserData,
    },
);
