import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import axios from 'axios';
import activateEmployee from 'Employee/api/activateEmployee';

const ActivateModal = ({ employee, onClose, onActivate }) => {
    const [isConfirming, setIsConfirming] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const activate = async () => {
        setIsConfirming(true);

        try {
            await activateEmployee(employee.id, source.token);
            await onActivate();

            setIsConfirming(false);

            notify('Mitarbeiter erfolgreich aktiviert', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsConfirming(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    return (
        <Modal
            isOpen
            onRequestClose={isConfirming ? undefined : onClose}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onClose}
                        disabled={isConfirming}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isConfirming}
                        onClick={activate}
                        disabled={isConfirming}
                    >
                        Zugang aktivieren
                    </Button>
                </>
            }
        >
            <ModalTitle>Aktivierung des Mitarbeiterzugangs</ModalTitle>
            <p>
                Bitte beachte, dass der Mitarbeiter
                {` "${employee.lastName}, ${employee.firstName}" `}bei der
                Aktivierung des Zugangs sich wieder mit dem bestehenden
                Zugangsdaten im System anmelden kann.
            </p>
        </Modal>
    );
};

ActivateModal.propTypes = {
    employee: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onActivate: PropTypes.func.isRequired,
};

export default ActivateModal;
