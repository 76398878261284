import React, { useEffect, useState } from 'react';
import Tile from 'ProjectManager/Project/Preview/Tile';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import File from 'ProjectManager/DataManagement/Files/components/File/File';
import { FileCopyRounded } from '@material-ui/icons';
import Button from 'Common/components/Button';
import { sumBy, take } from 'lodash';
import { useWindowWidth } from '@react-hook/window-size';
import UploadButton from 'ProjectManager/DataManagement/Files/components/UploadButton';
import Statistics from 'ProjectManager/Project/Preview/MediaFiles/Statistics';

const Subtitle = styled.div`
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.GRAY};
`;

const FilesWrapper = styled.div`
    margin-bottom: 20px;

    @media screen and (min-width: 580px) {
        margin-top: -30px;
        margin-left: -25px;
    }
`;

const StyledFile = styled(File)`
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: 580px) {
        display: inline-block;
        width: calc(50% - 25px);
        margin-top: 30px;
        margin-left: 25px;
        margin-bottom: 0;
    }
`;

const StyledButton = styled(Button)`
    padding-left: 0;
`;

const NoResults = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    color: ${colors.GRAY};

    svg {
        font-size: 48px;
    }
`;

const NoResultsText = styled.div`
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
`;

const calculateVisibleFilesCount = isMobile => {
    if (isMobile) {
        return 2;
    }

    return 4;
};

const MediaFiles = () => {
    const project = useRecoilValue(projectAtom);

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 580;

    const [visibleFilesCount, setVisibleFilesCount] = useState(() =>
        calculateVisibleFilesCount(isMobile),
    );

    // Change the visible files count depending on the device's resolution
    useEffect(() => {
        setVisibleFilesCount(calculateVisibleFilesCount(isMobile));
    }, [isMobile]);

    const questionFilesCount = sumBy(project.questionnaireSections, section =>
        sumBy(section.subsections, subsection =>
            sumBy(subsection.questions, question => question.files.length),
        ),
    );

    const generalProjectFilesCount = project.files.length;

    return (
        <Tile>
            <OuterBoxTitle>Media-Dateien</OuterBoxTitle>
            {generalProjectFilesCount > 0 && (
                <>
                    <Subtitle>Zuletzt hochgeladene Projektdateien</Subtitle>
                    <FilesWrapper>
                        {take(project.files, visibleFilesCount).map(file => (
                            <StyledFile key={file.id} file={file} />
                        ))}
                    </FilesWrapper>
                </>
            )}
            {generalProjectFilesCount + questionFilesCount > 0 ? (
                <StyledButton
                    underline
                    icon={<FileCopyRounded />}
                    to={`/project-manager/project/${project.id}/media-files`}
                >
                    Alle Dateien anzeigen
                </StyledButton>
            ) : (
                <NoResults>
                    <FileCopyRounded />
                    <NoResultsText>Keine Dateien</NoResultsText>
                    <div>
                        <UploadButton underline>
                            Projektdateien uploaden
                        </UploadButton>
                    </div>
                </NoResults>
            )}
            <Statistics />
        </Tile>
    );
};

export default MediaFiles;
