import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import InnerBoxWithButtons from 'Common/components/Boxes/InnerBoxWithButtons';
import Button from 'Common/components/Button';
import * as Yup from 'yup';
import axios from 'axios';
import { useFormik } from 'formik';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import Input from 'Common/components/Form/Fields/Input';
import FormField from 'Common/components/Form/FormField';
import InnerBoxTitle from 'Common/components/Boxes/InnerBoxTitle';
import Textarea from 'Common/components/Form/Fields/Textarea';
import useUniqueNameValidationCallback
    from 'ProjectManager/DataManagement/TopicManagement/components/CustomTopicManagement/hooks/uniqueNameValidationCallback/useUniqueNameValidationCallback';
import useCreateCustomTopicFunction
    from 'ProjectManager/DataManagement/TopicManagement/components/CustomTopicManagement/hooks/createCustomTopicFunction/useCreateCustomTopicFunction';

const initialValues = {
    name: '',
    explanationText: '',
};

const AddCustomTopic = ({ onCancel, onAdd }) => {
    const uniqueNameValidationCallback = useUniqueNameValidationCallback();
    const createCustomTopic = useCreateCustomTopicFunction();

    const [isAdding, setIsAdding] = useState(false);

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                name: Yup.string()
                    .debouncedCallback(
                        'Der Name wird bereits verwendet',
                        uniqueNameValidationCallback,
                    )
                    .required('Pflichtangabe'),
            }),
        [uniqueNameValidationCallback],
    );

    const {
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        isSubmitting,
        isValidating,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async values => {
            setIsAdding(true);

            try {
                await createCustomTopic(values);
                await onAdd();

                notify('Erstellung war erfolgreich', {
                    type: toast.TYPE.SUCCESS,
                });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }

            setIsAdding(false);
        },
    });

    const isSubmittingAndValidating = isSubmitting && isValidating;
    const isLoading = isAdding || isSubmittingAndValidating;

    return (
        <InnerBoxWithButtons
            buttons={
                <>
                    <Button
                        text
                        type="button"
                        disabled={isLoading}
                        onClick={onCancel}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        disabled={isLoading || !isValid}
                        isLoading={isLoading}
                        onClick={handleSubmit}
                    >
                        Erstellen
                    </Button>
                </>
            }
        >
            <InnerBoxTitle>Eigenes Projektfeld erstellen</InnerBoxTitle>
            <form onSubmit={handleSubmit}>
                <FormField>
                    <Input
                        id="name"
                        name="name"
                        label="Projektfeld Bezeichnung"
                        value={values.name}
                        error={errors.name}
                        touched={touched.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isRequired
                    />
                </FormField>
                <FormField>
                    <Textarea
                        id="explanationText"
                        name="explanationText"
                        label="Beschreibung des Projektfeldes"
                        value={values.explanationText}
                        error={errors.explanationText}
                        touched={touched.explanationText}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>
            </form>
        </InnerBoxWithButtons>
    );
};

AddCustomTopic.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
};

export default AddCustomTopic;
