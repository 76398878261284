import React, { useEffect, useMemo, useState } from 'react';
import {
    DefaultTableBody,
    DefaultTableHead,
    Table,
} from 'Common/components/table';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useSortBy, useTable } from 'react-table';
import { useCustomCellStyles } from 'Common/hooks/useCustomCellStyles';
import { useResponsiveTable } from 'Common/hooks/useResponsiveTable';
import {
    CancelRounded,
    CheckCircleRounded,
    DeleteRounded,
    PersonRounded,
} from '@material-ui/icons';
import axios from 'axios';
import {
    DriverLicenseControlIcon,
    InstructionIcon,
} from 'Common/components/icons';
import colors from 'Common/constants/colors';
import InnerBox from 'Common/components/Boxes/InnerBox';
import MoreActionsButton from 'Common/components/MoreActionsButton/MoreActionsButton';
import MenuItem from 'Common/components/MoreActionsButton/MenuItem';
import Button from 'Common/components/Button';
import { Tooltip } from '@material-ui/core';
import stableTableSort from 'Common/utils/stableTableSort';
import { orderBy } from 'lodash';
import ArchiveModal from 'Employee/components/List/ArchiveModal';
import DeactivateModal from 'Employee/components/List/DeactivateModal';
import ActivateModal from 'Employee/components/List/ActivateModal';

const ModulesWrapper = styled.div`
    display: flex;

    i,
    svg {
        font-size: 28px !important;
        width: 28px !important;
        height: 28px !important;
    }
`;

const ModuleIconWrapper = styled(InnerBox)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    padding: 10px;
    border-radius: 50%;
    background: ${colors.PAGE_BACKGROUND};
    color: ${colors.LIGHTER_GRAY};

    &:last-child {
        margin-right: 0;
    }
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    > * {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
`;

const StyledTableHead = styled(DefaultTableHead)`
    th {
        top: 80px;
    }
`;

const EmployeeListTable = ({
    employees,
    onActivate,
    onDeactivate,
    onArchive,
}) => {
    const [employeeIdForActivating, setEmployeeIdForActivating] = useState(
        null,
    );
    const [employeeIdForDeactivating, setEmployeeIdForDeactivating] = useState(
        null,
    );
    const [employeeIdForArchiving, setEmployeeIdForArchiving] = useState(null);

    const columns = useMemo(
        () => [
            {
                Header: 'Mitarbeiter',
                accessor: 'name',
                customCellStyles: {
                    minWidth: 220,
                },
            },
            {
                Header: 'Fortschritt',
                accessor: 'progress',
            },
            {
                Header: 'Aufgaben',
                accessor: 'modules',
                disableSortBy: true,
            },
            {
                Header: 'Aktion',
                accessor: 'actions',
                disableSortBy: true,
                customCellStyles: {
                    textAlign: 'center',
                },
            },
        ],
        [],
    );

    const initialSortBy = useMemo(
        () => [
            {
                id: 'name',
                desc: false,
            },
        ],
        [],
    );

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const closeActivateModal = () => {
        setEmployeeIdForActivating(null);
    };

    const handleActivate = async () => {
        await onActivate(employeeIdForActivating);

        closeActivateModal();
    };

    const closeDeactivateModal = () => {
        setEmployeeIdForDeactivating(null);
    };

    const handleDeactivate = async () => {
        await onDeactivate(employeeIdForDeactivating);

        closeDeactivateModal();
    };

    const closeArchiveModal = () => {
        setEmployeeIdForArchiving(null);
    };

    const handleArchive = async () => {
        await onArchive(employeeIdForArchiving);

        closeArchiveModal();
    };

    const data = useMemo(
        () =>
            orderBy(
                employees.map(employee => ({
                    name: `${employee.lastName}, ${employee.firstName}`,
                    progress: `${employee.tasksProgressPercentage} %`,
                    modules: (
                        <ModulesWrapper>
                            {employee.availableModules.map(module => {
                                let Icon;
                                let iconText;

                                // eslint-disable-next-line default-case
                                switch (module.value) {
                                    case 'INSTRUCTION':
                                        Icon = InstructionIcon;
                                        iconText = 'Unterweisungen';
                                        break;

                                    case 'DRIVER_LICENSE':
                                        Icon = DriverLicenseControlIcon;
                                        iconText = 'Führerscheinkontrolle';
                                        break;
                                }

                                return (
                                    <Tooltip
                                        title={iconText}
                                        key={module.value}
                                    >
                                        <ModuleIconWrapper>
                                            <Icon />
                                        </ModuleIconWrapper>
                                    </Tooltip>
                                );
                            })}
                        </ModulesWrapper>
                    ),
                    actions: (
                        <Actions>
                            <Button small to={`/employee/${employee.id}`}>
                                Ansicht
                            </Button>
                            <MoreActionsButton>
                                {employee.isEnabled && (
                                    <MenuItem
                                        type="button"
                                        icon={<CancelRounded />}
                                        onClick={() => {
                                            setEmployeeIdForDeactivating(
                                                employee.id,
                                            );
                                        }}
                                    >
                                        Inaktivieren
                                    </MenuItem>
                                )}
                                {!employee.isEnabled && (
                                    <MenuItem
                                        type="button"
                                        icon={<CheckCircleRounded />}
                                        onClick={() => {
                                            setEmployeeIdForActivating(
                                                employee.id,
                                            );
                                        }}
                                    >
                                        Aktivieren
                                    </MenuItem>
                                )}
                                {!employee.isEnabled && (
                                    <MenuItem
                                        type="button"
                                        icon={<DeleteRounded />}
                                        onClick={() => {
                                            setEmployeeIdForArchiving(
                                                employee.id,
                                            );
                                        }}
                                    >
                                        Löschen
                                    </MenuItem>
                                )}
                                {employee.isEnabled && (
                                    <MenuItem
                                        icon={<PersonRounded />}
                                        href={`/backend/m/exec/call?emp=${employee.id}&openEdit=1&fromList=1`}
                                    >
                                        Mitarbeiterprofil
                                    </MenuItem>
                                )}
                            </MoreActionsButton>
                        </Actions>
                    ),
                })),
                ['name'],
                ['asc'],
            ),
        [employees],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { hiddenColumns },
    } = useTable(
        {
            columns,
            data,
            hideColumnsPriority: ['modules', 'progress', 'actions'],
            disableSortRemove: true,
            disableMultiSort: true,
            initialState: {
                sortBy: initialSortBy,
            },
            orderByFn: stableTableSort,
        },
        useCustomCellStyles,
        useResponsiveTable,
        useSortBy,
    );

    const employeeForActivating = employees.find(
        employee => employee.id === employeeIdForActivating,
    );

    const employeeForDeactivating = employees.find(
        employee => employee.id === employeeIdForDeactivating,
    );

    const employeeForArchiving = employees.find(
        employee => employee.id === employeeIdForArchiving,
    );

    return (
        <>
            <Table {...getTableProps()}>
                <StyledTableHead headerGroups={headerGroups} />
                <DefaultTableBody
                    rows={rows}
                    getTableBodyProps={getTableBodyProps}
                    prepareRow={prepareRow}
                    visibleColumns={visibleColumns}
                    hiddenColumns={hiddenColumns}
                />
            </Table>
            {Boolean(employeeForActivating) && (
                <ActivateModal
                    employee={employeeForActivating}
                    onClose={closeActivateModal}
                    onActivate={handleActivate}
                />
            )}
            {Boolean(employeeForDeactivating) && (
                <DeactivateModal
                    employee={employeeForDeactivating}
                    onClose={closeDeactivateModal}
                    onDeactivate={handleDeactivate}
                />
            )}
            {Boolean(employeeForArchiving) && (
                <ArchiveModal
                    employee={employeeForArchiving}
                    onClose={closeArchiveModal}
                    onArchive={handleArchive}
                />
            )}
        </>
    );
};

EmployeeListTable.propTypes = {
    employees: PropTypes.array.isRequired,
    onActivate: PropTypes.func.isRequired,
    onDeactivate: PropTypes.func.isRequired,
    onArchive: PropTypes.func.isRequired,
};

export default EmployeeListTable;
