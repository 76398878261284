import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import updateTemplateCustomSubsection from 'ProjectManager/Template/Common/api/dataManagement/questionnaire/subsection/updateTemplateCustomSubsection';
import withUpdatedCustomSubsectionData from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/subsection/withUpdatedCustomSubsectionData';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';

const useUpdateTemplateCustomSubsectionFunction = () => {
    const [{ id: templateId }, setTemplate] = useRecoilState(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const updateCustomSubsection = useCallback(
        async (customSubsectionId, updatedValues) => {
            await updateTemplateCustomSubsection(
                templateId,
                customSubsectionId,
                updatedValues,
                source.token,
            );

            setTemplate(
                withUpdatedCustomSubsectionData(
                    customSubsectionId,
                    updatedValues,
                ),
            );
        },
        [templateId, setTemplate, source.token],
    );
    return updateCustomSubsection;
};

export default useUpdateTemplateCustomSubsectionFunction;
