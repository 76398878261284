import React from 'react';
import { AssignmentRounded } from '@material-ui/icons';
import NoItemsText from 'ProjectManager/Overview/NoItemsText';
import Button from 'Common/components/Button';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import OuterBox from 'Common/components/Boxes/OuterBox';

const Wrapper = styled(OuterBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: ${colors.GRAY};

    ${NoItemsText} {
        max-width: 340px;
    }

    > svg {
        font-size: 55px;
    }

    @media screen and (min-width: 1180px) {
        height: calc(100vh - 190px);
        min-height: 520px;

        > svg {
            font-size: 140px;
        }
    }
`;

const NoProjects = () => (
    <Wrapper>
        <AssignmentRounded />
        <NoItemsText>
            Derzeit gibt es keine Projekte, die angezeigt werden können
        </NoItemsText>
        <Button to="/project-manager/project/create">Projekt erstellen</Button>
    </Wrapper>
);

export default NoProjects;
