import { selector } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import isQuestionAnswered from 'ProjectManager/DataManagement/Questionnaire/helpers/isQuestionAnswered';

const isProjectQuestionnaireCompletedSelector = selector({
    key: 'projectManagement_isProjectQuestionnaireCompleted',
    get: ({ get }) => {
        const { id, questionnaireSections } = get(projectAtom);

        return (
            Boolean(id) &&
            questionnaireSections.length > 0 &&
            questionnaireSections.every(section =>
                section.subsections.every(subsection =>
                    subsection.questions.every(question =>
                        isQuestionAnswered(question),
                    ),
                ),
            )
        );
    },
});

export default isProjectQuestionnaireCompletedSelector;
