import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { FilterListRounded } from '@material-ui/icons';
import Button from 'Common/components/Button';
import FiltersBox from 'Common/components/Filters/FiltersBox/FiltersBox';
import ActiveFiltersCounter from 'Common/components/Filters/ActiveFiltersCounter';
import { pickBy } from 'lodash';
import { usePrevious } from 'react-use';
import CloseButton from 'Common/components/Filters/CloseButton/CloseButton';

const Wrapper = styled.div``;

const ButtonWrapper = styled.div`
    position: relative;
`;

const FiltersBoxWrapper = styled.div`
    position: absolute;
    top: 77px;
    left: 15px;
    width: calc(100% - 30px);
    max-width: 480px;
`;

const Filters = ({
    initialState,
    setInitialState,
    clearedState,
    excludedFilterKeysFromCounter,
    children,
    ...props
}) => {
    const [filters, setFilters] = useState(initialState);
    const [isOpened, setIsOpened] = useState(false);

    const prevInitialState = usePrevious(initialState);

    // Change the local filters state whenever the initial state changes,
    // so that they can reflect the new changes in the properly.
    useEffect(() => {
        if (
            prevInitialState &&
            JSON.stringify(initialState) !== JSON.stringify(prevInitialState)
        ) {
            setFilters(prevFilters => ({
                // Use the previous filters as a starting ground
                ...prevFilters,

                // Overwrite those filters, which have been changed in the initial state
                ...pickBy(
                    initialState,
                    (value, key) =>
                        JSON.stringify(value) !==
                        JSON.stringify(prevInitialState[key]),
                ),
            }));
        }
    }, [initialState, prevInitialState]);

    const open = () => {
        setIsOpened(true);
    };

    const close = () => {
        setIsOpened(false);
    };

    const handleCloseButtonClick = () => {
        setFilters(initialState);

        close();
    };

    const applyFilters = () => {
        setInitialState(filters);

        close();
    };

    const clearAllFilters = () => {
        setFilters(clearedState);
        setInitialState(clearedState);

        close();
    };

    const haveFiltersChanged = useMemo(
        () => JSON.stringify(filters) !== JSON.stringify(initialState),
        [filters, initialState],
    );

    return (
        <Wrapper {...props}>
            <ButtonWrapper>
                {isOpened ? (
                    <CloseButton
                        outline
                        icon={<FilterListRounded />}
                        isActive
                        type="button"
                        onClick={handleCloseButtonClick}
                        haveFiltersChanged={haveFiltersChanged}
                    >
                        Filter
                    </CloseButton>
                ) : (
                    <Button
                        outline
                        icon={<FilterListRounded />}
                        type="button"
                        onClick={open}
                    >
                        Filter
                    </Button>
                )}
                <ActiveFiltersCounter
                    filters={filters}
                    clearedState={clearedState}
                    excludedFilterKeys={excludedFilterKeysFromCounter}
                />
            </ButtonWrapper>
            {isOpened && (
                <FiltersBoxWrapper>
                    <FiltersBox
                        haveFiltersChanged={haveFiltersChanged}
                        onApply={applyFilters}
                        onClearAll={clearAllFilters}
                        onCloseButtonClick={handleCloseButtonClick}
                    >
                        {children(filters, setFilters)}
                    </FiltersBox>
                </FiltersBoxWrapper>
            )}
        </Wrapper>
    );
};

Filters.defaultProps = {
    excludedFilterKeysFromCounter: [],
    clearedState: {},
};

Filters.propTypes = {
    initialState: PropTypes.object.isRequired,
    setInitialState: PropTypes.func.isRequired,
    clearedState: PropTypes.object,
    excludedFilterKeysFromCounter: PropTypes.array,
    children: PropTypes.func.isRequired,
};

export default Filters;
