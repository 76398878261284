import React, { useEffect, useMemo, useState } from 'react';
import InnerBox from 'Common/components/Boxes/InnerBox';
import styled from 'styled-components/macro';
import parseDate from 'Common/utils/parseDate';
import formatDate from 'Common/utils/formatDate';
import {
    ArrowBackRounded,
    ArrowDownwardRounded,
    ArrowForwardRounded,
    ArrowUpwardRounded,
    DeleteRounded,
    EditRounded,
    QueueRounded,
} from '@material-ui/icons';
import Button from 'Common/components/Button';
import PropTypes from 'prop-types';
import { useSetRecoilState } from 'recoil';
import templatesAtom from 'ProjectManager/Template/List/recoil/templates/templatesAtom';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import moveTemplateUp from 'ProjectManager/Template/Common/api/moveTemplateUp';
import withTemplateMovedUp from 'ProjectManager/Template/List/recoil/templates/modifiers/withTemplateMovedUp';
import moveTemplateDown from 'ProjectManager/Template/Common/api/moveTemplateDown';
import withTemplateMovedDown from 'ProjectManager/Template/List/recoil/templates/modifiers/withTemplateMovedDown';
import DeleteModal from 'ProjectManager/Template/List/components/Overview/Template/DeleteModal';
import ProjectCreateModal from 'ProjectManager/Template/List/components/Overview/Template/ProjectCreateModal';
import colors from 'Common/constants/colors';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';

const Wrapper = styled(InnerBox)`
    overflow: hidden;
`;

const SlidingWrapper = styled.div`
    display: flex;
    width: 200%;
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);

    ${ifProp(
        'isActionButtonsVisible',
        css`
            transform: translateX(-50%);
        `,
        css`
            transform: translateX(0);
        `,
    )};
`;

const MainButton = styled.button`
    flex: 0 0 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    padding: 15px 18px;
    color: ${colors.DIRTY_WHITE};
    background: none;
    border: 0 none;
    outline: 0 none;
    cursor: pointer;

    &:hover {
        background: ${colors.DARK_DIVIDER};
    }

    > div {
        text-align: left;
        font-size: 14px;
        line-height: 21px;
    }
`;

const ActionButtons = styled.div`
    display: flex;
    flex: 0 0 50%;
    width: 50%;
`;

const BackButton = styled(Button)`
    width: 46px;
`;

const ActionButtonsWrapper = styled.div`
    display: flex;
    flex: 1;
    background: ${colors.DIRTY_WHITE};
    border-radius: 6px;
    overflow: hidden;

    > * {
        position: relative;
        flex: 1 1 auto;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: 40%;
            background: ${colors.LIGHT_DIVIDER};
            transform: translateY(-50%);
        }

        &:first-child,
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
`;

const ActionButton = styled(Button)`
    ${ifProp(
        'isCreationButton',
        css`
            background: ${colors.PRIMARY};
        `,
    )};
`;

const UpdatingDate = styled.div`
    margin-top: 5px;
    color: ${colors.GRAY};
`;

const TemplateInfo = styled.div`
    margin-right: 20px;
    min-width: 0;
`;

const Name = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Template = ({ group, template }) => {
    const setTemplates = useSetRecoilState(templatesAtom);

    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
    const [isMovingUp, setIsMovingUp] = useState(false);
    const [isMovingDown, setIsMovingDown] = useState(false);
    const [isActionButtonsVisible, setIsActionButtonsVisible] = useState(false);

    const handleShowActionButtons = () => {
        setIsActionButtonsVisible(true);
    };

    const handleHideActionButtons = () => {
        setIsActionButtonsVisible(false);
    };

    const [
        isProjectCreateModalOpened,
        setIsProjectCreateModalOpened,
    ] = useState(false);

    const handleDeleteButtonClick = () => {
        setIsDeleteModalOpened(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpened(false);
    };

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleCreateProjectButtonClick = () => {
        setIsProjectCreateModalOpened(true);
    };

    const closeProjectCreateModal = () => {
        setIsProjectCreateModalOpened(false);
    };

    const handleMoveUpButtonClick = async () => {
        setIsMovingUp(true);
        try {
            await moveTemplateUp(template.id, source.token);
            setTemplates(withTemplateMovedUp(template.id));
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
        setIsMovingUp(false);
    };

    const handleMoveDownButtonClick = async () => {
        setIsMovingDown(true);

        try {
            await moveTemplateDown(template.id, source.token);
            setTemplates(withTemplateMovedDown(template.id));
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
        setIsMovingDown(false);
    };

    const isLoading = isMovingUp || isMovingDown;
    const name = template.name !== '' ? template.name : 'Kein Name';

    return (
        <Wrapper>
            <SlidingWrapper isActionButtonsVisible={isActionButtonsVisible}>
                <MainButton type="button" onClick={handleShowActionButtons}>
                    <TemplateInfo>
                        <Name>{name}</Name>
                        <UpdatingDate>{`Zuletzt bearbeitet am ${formatDate(
                            parseDate(template.updatedAt),
                        )}`}</UpdatingDate>
                    </TemplateInfo>
                    <ArrowForwardRounded />
                </MainButton>
                <ActionButtons>
                    <BackButton
                        type="button"
                        iconOnly
                        icon={<ArrowBackRounded />}
                        tooltip="Zurück"
                        onClick={handleHideActionButtons}
                        disabled={isLoading}
                    />
                    <ActionButtonsWrapper>
                        <ActionButton
                            type="button"
                            iconOnly
                            icon={<QueueRounded />}
                            tooltip="Projekt erstellen"
                            onClick={handleCreateProjectButtonClick}
                            disabled={isLoading}
                            isCreationButton
                        />
                        <ActionButton
                            dark
                            iconOnly
                            icon={<EditRounded />}
                            tooltip="Bearbeiten"
                            to={`/project-manager/template/${template.id}`}
                            disabled={isLoading}
                        />
                        <ActionButton
                            type="button"
                            dark
                            iconOnly
                            icon={<ArrowUpwardRounded />}
                            tooltip="Aufwärts verschieben"
                            onClick={handleMoveUpButtonClick}
                            disabled={template.position === 1 || isLoading}
                            isLoading={isMovingUp}
                        />
                        <ActionButton
                            type="button"
                            dark
                            iconOnly
                            icon={<ArrowDownwardRounded />}
                            tooltip="Abwärts verschieben"
                            onClick={handleMoveDownButtonClick}
                            disabled={
                                template.position === group.templates.length ||
                                isLoading
                            }
                            isLoading={isMovingDown}
                        />
                        <ActionButton
                            type="button"
                            dark
                            iconOnly
                            icon={<DeleteRounded />}
                            tooltip="Löschen"
                            onClick={handleDeleteButtonClick}
                            disabled={isLoading}
                        />
                    </ActionButtonsWrapper>
                </ActionButtons>
            </SlidingWrapper>
            {isDeleteModalOpened && (
                <DeleteModal template={template} onClose={closeDeleteModal} />
            )}
            {isProjectCreateModalOpened && (
                <ProjectCreateModal
                    template={template}
                    onClose={closeProjectCreateModal}
                />
            )}
        </Wrapper>
    );
};

Template.propTypes = {
    template: PropTypes.object.isRequired,
    group: PropTypes.object.isRequired,
};

export default Template;
