import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
    DefaultTableBody,
    DefaultTableHead,
    Table,
} from 'Common/components/table';
import GroupInstructionAssignmentEditModal from 'Education/components/instruction-assignment/group/GroupInstructionAssignmentEditModal';
import GroupInstructionAssignmentDeleteModal from 'Education/components/instruction-assignment/group/GroupInstructionAssignmentDeleteModal';
import { presentationStatuses } from 'Education/constants/presentationStatuses';
import { useSortBy, useTable } from 'react-table';
import { useCustomCellStyles } from 'Common/hooks/useCustomCellStyles';
import { useResponsiveTable } from 'Common/hooks/useResponsiveTable';
import styled from 'styled-components/macro';
import { DeleteRounded, EditRounded } from '@material-ui/icons';
import MoreActionsButton from 'Common/components/MoreActionsButton/MoreActionsButton';
import MenuItem from 'Common/components/MoreActionsButton/MenuItem';
import stableTableSort from 'Common/utils/stableTableSort';
import { orderBy } from 'lodash';
import GroupedPeopleAvatars from 'Common/components/GroupedPeopleAvatars/GroupedPeopleAvatars';

const StyledTableHead = styled(DefaultTableHead)`
    th {
        top: 80px;

        @media screen and (min-width: 940px) {
            top: 134px;
        }
    }
`;

const GroupInstructionAssignmentsTable = ({
    instructionAssignments,
    onAssignmentUpdate,
    onAssignmentDelete,
}) => {
    const [assignmentIdForEdit, setAssignmentIdForEdit] = useState(null);
    const [assignmentIdForDeletion, setAssignmentIdForDeletion] = useState(
        null,
    );

    const handleEditButtonClick = assignmentId => {
        setAssignmentIdForEdit(assignmentId);
    };

    const handleEditCancel = () => {
        setAssignmentIdForEdit(null);
    };

    const handleEditSuccess = async () => {
        await onAssignmentUpdate(assignmentIdForEdit);
        setAssignmentIdForEdit(null);
    };

    const handleDeleteButtonClick = assignmentId => {
        setAssignmentIdForDeletion(assignmentId);
    };

    const handleDeleteCancel = () => {
        setAssignmentIdForDeletion(null);
    };

    const handleDeleteSuccess = () => {
        if (assignmentIdForDeletion) {
            onAssignmentDelete(assignmentIdForDeletion);
            setAssignmentIdForDeletion(null);
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Gruppenleiter',
                accessor: 'leader',
            },
            {
                Header: 'Mitarbeiter',
                accessor: 'employees',
                disableSortBy: true,
            },
            {
                Header: 'Aktion',
                accessor: 'actions',
                disableSortBy: true,
                customCellStyles: {
                    textAlign: 'center',
                },
            },
        ],
        [],
    );

    const data = useMemo(
        () =>
            orderBy(
                instructionAssignments.map(assignment => {
                    const hasStarted =
                        assignment.presentationStatus !==
                        presentationStatuses.NOT_STARTED;

                    const assignmentDetails = assignment.groupAssignmentDetails;
                    const groupLeader = assignmentDetails.groupLeader;

                    return {
                        name: assignment.name,
                        leader: `${groupLeader.employee.lastName}, ${groupLeader.employee.firstName}`,
                        employees: (
                            <GroupedPeopleAvatars
                                people={assignmentDetails.assignees.map(
                                    assignee => assignee.employee,
                                )}
                            />
                        ),
                        actions: (
                            <MoreActionsButton>
                                <MenuItem
                                    icon={<EditRounded />}
                                    onClick={
                                        hasStarted
                                            ? undefined
                                            : () =>
                                                  handleEditButtonClick(
                                                      assignment.id,
                                                  )
                                    }
                                    tooltip={
                                        hasStarted
                                            ? 'Kann nicht bearbeitet werden, da es bereits gestartet wurde'
                                            : ''
                                    }
                                    disabled={hasStarted}
                                >
                                    Bearbeiten
                                </MenuItem>
                                <MenuItem
                                    icon={<DeleteRounded />}
                                    onClick={() =>
                                        handleDeleteButtonClick(assignment.id)
                                    }
                                >
                                    Löschen
                                </MenuItem>
                            </MoreActionsButton>
                        ),
                    };
                }),
                ['name'],
                ['asc'],
            ),
        [instructionAssignments],
    );

    const initialSortBy = useMemo(
        () => [
            {
                id: 'name',
                desc: false,
            },
        ],
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { hiddenColumns },
    } = useTable(
        {
            columns,
            data,
            hideColumnsPriority: ['employees', 'leader', 'actions'],
            disableSortRemove: true,
            disableMultiSort: true,
            initialState: {
                sortBy: initialSortBy,
            },
            orderByFn: stableTableSort,
        },
        useCustomCellStyles,
        useResponsiveTable,
        useSortBy,
    );

    return (
        <>
            {assignmentIdForEdit !== null && (
                <GroupInstructionAssignmentEditModal
                    assignmentId={assignmentIdForEdit}
                    onCancel={handleEditCancel}
                    onSuccess={handleEditSuccess}
                />
            )}
            {assignmentIdForDeletion !== null && (
                <GroupInstructionAssignmentDeleteModal
                    instructionAssignment={
                        assignmentIdForDeletion
                            ? instructionAssignments.find(
                                  assignment =>
                                      assignment.id === assignmentIdForDeletion,
                              )
                            : null
                    }
                    onCancel={handleDeleteCancel}
                    onSuccess={handleDeleteSuccess}
                />
            )}
            <Table {...getTableProps()}>
                <StyledTableHead headerGroups={headerGroups} />
                <DefaultTableBody
                    rows={rows}
                    hiddenColumns={hiddenColumns}
                    visibleColumns={visibleColumns}
                    getTableBodyProps={getTableBodyProps}
                    prepareRow={prepareRow}
                />
            </Table>
        </>
    );
};

GroupInstructionAssignmentsTable.propTypes = {
    instructionAssignments: PropTypes.array.isRequired,
    onAssignmentUpdate: PropTypes.func.isRequired,
    onAssignmentDelete: PropTypes.func.isRequired,
};

export default GroupInstructionAssignmentsTable;
