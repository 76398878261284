import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import InnerBox from 'Common/components/Boxes/InnerBox';
import Qualification from 'ProjectManager/DataManagement/EmployeeSelection/components/Qualification';
import Button from 'Common/components/Button';
import colors from 'Common/constants/colors';
import { CheckRounded } from '@material-ui/icons';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';
import useSupervisorsData from 'ProjectManager/DataManagement/Supervisors/hooks/useSupervisorsData';

const Wrapper = styled(InnerBox)`
    position: relative;
    padding: 12px 12px 5px;
`;

const Heading = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;

const NameWrapper = styled.div`
    margin-right: 10px;
`;

const Name = styled.div`
    font-size: 14px;
    line-height: 21px;
`;

const Supervisor = styled.div`
    margin-bottom: 2px;
    color: ${colors.WARNING};
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
`;

const ActionButton = styled(Button)`
    position: absolute;
    top: 4px;
    right: 4px;
`;

const Qualifications = styled.div`
    margin-top: 7px;

    > div {
        margin-right: 7px;
        margin-bottom: 7px;
    }
`;

const AnimationOverlay = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    animation: fadeIn 0.15s ease-in;

    ${ifProp(
        'light',
        css`
            background: rgba(255, 255, 255, 0.8);
            color: ${colors.DARK_GRAY};
        `,
        css`
            background: rgba(85, 85, 85, 0.8);
            color: ${colors.DIRTY_WHITE};
        `,
    )};
`;

const AnimationText = styled.span`
    margin-left: 5px;
    font-size: 18px;
    line-height: 27px;
`;

const EmployeeTile = ({
    employee,
    qualifications,
    actionLabel,
    actionIcon,
    onActionButtonClick,
    isActionButtonDisabled,
    invertedColors,
    showAnimation,
    animationText,
}) => {
    const { selectedSupervisorUsers } = useSupervisorsData();

    const isAssignedAsSupervisor = Boolean(
        selectedSupervisorUsers.find(
            user => user.id === employee.user.id,
        ),
    );

    const [isActionLoading, setIsActionLoading] = useState(false);

    const handleActionButtonClick = async () => {
        setIsActionLoading(true);

        await onActionButtonClick();

        setIsActionLoading(false);
    };

    return (
        <Wrapper light={invertedColors}>
            <Heading>
                <NameWrapper>
                    {isAssignedAsSupervisor && (
                        <Supervisor>Auftragsverantwortlicher</Supervisor>
                    )}
                    <Name>{`${employee.lastName}, ${employee.firstName}`}</Name>
                </NameWrapper>

                <ActionButton
                    dark={invertedColors}
                    iconOnly
                    icon={actionIcon}
                    type="button"
                    onClick={handleActionButtonClick}
                    tooltip={actionLabel}
                    isLoading={isActionLoading}
                    disabled={isActionLoading || isActionButtonDisabled}
                />
            </Heading>
            <Qualifications>
                {qualifications.map(qualification => (
                    <Qualification
                        key={qualification.type}
                        qualification={qualification}
                        dark={invertedColors}
                    />
                ))}
            </Qualifications>
            {showAnimation && (
                <AnimationOverlay light={invertedColors}>
                    <CheckRounded />
                    <AnimationText>{animationText}</AnimationText>
                </AnimationOverlay>
            )}
        </Wrapper>
    );
};

EmployeeTile.defaultProps = {
    isActionButtonDisabled: false,
    invertedColors: false,
    showAnimation: false,
    animationText: '',
};

EmployeeTile.propTypes = {
    employee: PropTypes.object.isRequired,
    qualifications: PropTypes.array.isRequired,
    actionLabel: PropTypes.string.isRequired,
    actionIcon: PropTypes.any.isRequired,
    onActionButtonClick: PropTypes.func.isRequired,
    isActionButtonDisabled: PropTypes.bool,
    invertedColors: PropTypes.bool,
    showAnimation: PropTypes.bool,
    animationText: PropTypes.string,
};

export default EmployeeTile;
