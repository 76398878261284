import { useSetRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import questionWithOpenedImagePreviewAtom
    from 'ProjectManager/DataManagement/Common/recoil/questionWithOpenedImagePreview/questionWithOpenedImagePreviewAtom';
import projectsLoadingState from 'ProjectManager/Project/List/recoil/loading/projectsLoadingState';
import projectsAtom from 'ProjectManager/Project/List/recoil/projects/projectsAtom';
import projectsTotalCountAtom from 'ProjectManager/Project/List/recoil/totalCount/projectsTotalCountAtom';
import projectsTotalCountWithoutFiltersAtom
    from 'ProjectManager/Project/List/recoil/totalCount/projectsTotalCountWithoutFiltersAtom';
import projectsStatisticsAtom from 'ProjectManager/Project/List/recoil/statistics/projectsStatisticsAtom';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import defaultProject from 'ProjectManager/Project/Common/recoil/project/defaultProject';
import defaultProjectsTotal from 'ProjectManager/Project/List/recoil/totalCount/defaultProjectsTotal';
import defaultStatistics from 'ProjectManager/Project/List/recoil/statistics/defaultStatistics';
import defaultProjects from 'ProjectManager/Project/List/recoil/projects/defaultProjects';
import defaultLoading from 'ProjectManager/Project/List/recoil/loading/defaultLoading';
import defaultTemplate from 'ProjectManager/Template/Common/recoil/defaultTemplate';
import templatesAtom from 'ProjectManager/Template/List/recoil/templates/templatesAtom';
import defaultTemplates from 'ProjectManager/Template/List/recoil/templates/defaultTemplates';
import groupsAtom from 'ProjectManager/Template/List/recoil/groups/groupsAtom';
import defaultGroups from 'ProjectManager/Template/List/recoil/groups/defaultGroups';
import projectMediaFilesSearchTermAtom
    from 'ProjectManager/Project/ProjectMediaFiles/recoil/projectMediaFilesSearchTerm/projectMediaFilesSearchTermAtom';
import defaultProjectMediaFilesSearchTerm
    from 'ProjectManager/Project/ProjectMediaFiles/recoil/projectMediaFilesSearchTerm/defaultProjectMediaFilesSearchTerm';
import pointsAtom from 'ProjectManager/Project/Introductions/Create/recoil/points/pointsAtom';
import ppesAtom from 'ProjectManager/Project/Introductions/Create/recoil/ppes/ppesAtom';
import defaultPoints from 'ProjectManager/Project/Introductions/Create/recoil/points/defaultPoints';
import defaultPPEs from 'ProjectManager/Project/Introductions/Create/recoil/ppes/defaultPPEs';
import commentAtom from 'ProjectManager/Project/Introductions/Create/recoil/comment/commentAtom';
import defaultComment from 'ProjectManager/Project/Introductions/Create/recoil/comment/defaultComment';
import defaultSupervisorUserOption
    from 'ProjectManager/Project/Introductions/Create/recoil/supervisorUserOption/defaultSupervisorUserOption';
import supervisorUserOptionAtom
    from 'ProjectManager/Project/Introductions/Create/recoil/supervisorUserOption/supervisorUserOptionAtom';
import availablePointsAtom from 'ProjectManager/Project/Introductions/Common/recoil/availablePoints/availablePointsAtom';
import defaultAvailablePoints from 'ProjectManager/Project/Introductions/Common/recoil/availablePoints/defaultAvailablePoints';

const useResetProjectManagerRecoilStateOnLocationChange = () => {
    // Data management
    const setProject = useSetRecoilState(projectAtom);
    const setTemplate = useSetRecoilState(templateAtom);
    const setQuestionWithOpenedImagePreview = useSetRecoilState(
        questionWithOpenedImagePreviewAtom,
    );

    // Projects overview (list)
    const setProjectsLoading = useSetRecoilState(projectsLoadingState);
    const setProjects = useSetRecoilState(projectsAtom);
    const setProjectsStatistics = useSetRecoilState(projectsStatisticsAtom);
    const setProjectsTotalCount = useSetRecoilState(projectsTotalCountAtom);
    const setProjectsTotalCountWithoutFilters = useSetRecoilState(
        projectsTotalCountWithoutFiltersAtom,
    );

    // Project preview
    const setProjectMediaFilesSearchTerm = useSetRecoilState(
        projectMediaFilesSearchTermAtom,
    );

    // Project templates (list)
    const setTemplates = useSetRecoilState(templatesAtom);
    const setGroups = useSetRecoilState(groupsAtom);

    // Project introductions (create)
    const setSupervisorUserOption = useSetRecoilState(supervisorUserOptionAtom);
    const setPoints = useSetRecoilState(pointsAtom);
    const setPPEs = useSetRecoilState(ppesAtom);
    const setComment = useSetRecoilState(commentAtom);

    // Project introductions (create)
    const setAvailablePoints = useSetRecoilState(availablePointsAtom);

    const location = useLocation();

    // When navigating to another page, make sure that all transient states are cleared
    useEffect(() => {
        setProject(defaultProject);
        setTemplate(defaultTemplate);
        setQuestionWithOpenedImagePreview(null);

        setProjectsLoading(defaultLoading);
        setProjects(defaultProjects);
        setProjectsStatistics(defaultStatistics);
        setProjectsTotalCount(defaultProjectsTotal);
        setProjectsTotalCountWithoutFilters(defaultProjectsTotal);

        setProjectMediaFilesSearchTerm(defaultProjectMediaFilesSearchTerm);

        setTemplates(defaultTemplates);
        setGroups(defaultGroups);

        setSupervisorUserOption(defaultSupervisorUserOption);
        setPoints(defaultPoints);
        setPPEs(defaultPPEs);
        setComment(defaultComment);

        setAvailablePoints(defaultAvailablePoints);
    }, [
        location,
        setProject,
        setTemplate,
        setProjects,
        setProjectsLoading,
        setProjectsStatistics,
        setProjectsTotalCount,
        setProjectsTotalCountWithoutFilters,
        setProjectMediaFilesSearchTerm,
        setQuestionWithOpenedImagePreview,
        setTemplates,
        setGroups,
        setSupervisorUserOption,
        setPoints,
        setPPEs,
        setComment,
        setAvailablePoints,
    ]);
};

export default useResetProjectManagerRecoilStateOnLocationChange;
