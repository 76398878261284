import React from 'react';
import colors from 'Common/constants/colors';
import { Switch as MaterialSwitch } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { pick } from 'lodash';

const useStyles = makeStyles(() => ({
    root: {
        height: 40,
    },
    switchBase: {
        padding: 11,
        color: colors.LIGHT_DIVIDER,
        '&$checked': {
            transform: 'translateX(18px)',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.4,
        },
    },
    thumb: {
        width: 17,
        height: 17,
    },
    checked: {
        color: props => `${props.color} !important`,
        opacity: props => (props.disabled ? 0.5 : 1),
    },
    track: {
        backgroundColor: props =>
            props.checked ? props.color : colors.SEARCH_FIELD_BORDER,
    },
}));

const Switch = ({ color, backgroundColor, ...props }) => {
    const classes = useStyles({
        color,
        backgroundColor,
        ...pick(props, ['checked', 'disabled']),
    });

    return <MaterialSwitch classes={classes} color="default" {...props} />;
};

Switch.defaultProps = {
    color: colors.GREEN,
    backgroundColor: colors.SEARCH_FIELD_BORDER,
};

export default Switch;
