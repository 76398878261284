import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
    DefaultTableBody,
    DefaultTableHead,
    Table,
} from 'Common/components/table';
import { useSortBy, useTable } from 'react-table';
import { useCustomCellStyles } from 'Common/hooks/useCustomCellStyles';
import {
    CloudDownloadRounded,
    DeleteRounded,
    DescriptionRounded,
    EditRounded,
    WarningRounded,
} from '@material-ui/icons';
import InstructionArchiveModal from 'Education/components/instruction/InstructionArchiveModal';
import { useResponsiveTable } from 'Common/hooks/useResponsiveTable';
import MoreActionsButton from 'Common/components/MoreActionsButton/MoreActionsButton';
import MenuItem from 'Common/components/MoreActionsButton/MenuItem';
import WarningMessage from 'Common/components/ActionBar/WarningMessage';
import stableTableSort from 'Common/utils/stableTableSort';
import { orderBy } from 'lodash';
import differentColumnSortType from 'Common/utils/differentColumnSortType';

const StyledTableHead = styled(DefaultTableHead)`
    th {
        top: 80px;

        @media screen and (min-width: 940px) {
            top: 134px;
        }
    }
`;

const InstructionsTable = ({ instructions, onInstructionArchive }) => {
    const [instructionIdForArchiving, setInstructionIdForArchiving] = useState(
        null,
    );

    const handleArchiveButtonClick = instructionId => {
        setInstructionIdForArchiving(instructionId);
    };

    const handleArchiveCancel = () => {
        setInstructionIdForArchiving(null);
    };

    const handleArchiveSuccess = () => {
        if (instructionIdForArchiving) {
            onInstructionArchive(instructionIdForArchiving);
            setInstructionIdForArchiving(null);
        }
    };

    const nameSortingFunction = useCallback(
        differentColumnSortType('rawName'),
        [],
    );

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                sortType: nameSortingFunction,
                Cell: ({ value: name, row }) => (
                    <div>
                        {name}
                        {row.original.slidesCount === 0 && (
                            <WarningMessage>
                                <WarningRounded />
                                Keine Folien in der Präsentation
                            </WarningMessage>
                        )}
                        {row.original.questionsCount === 0 && (
                            <WarningMessage>
                                <WarningRounded />
                                Keine Fragen im Test
                            </WarningMessage>
                        )}
                    </div>
                ),
            },
            {
                Header: 'Bereich',
                accessor: 'areas',
                disableSortBy: true,
                Cell: ({ value: areas }) =>
                    areas.map(area => area.name).join(', '),
            },
            {
                Header: 'Aktion',
                accessor: 'actions',
                disableSortBy: true,
                customCellStyles: {
                    textAlign: 'center',
                },
                Cell: ({ row }) => (
                    <MoreActionsButton>
                        {row.original.slidesCount > 0 && (
                            <MenuItem
                                icon={<DescriptionRounded />}
                                href={`/api/education/instructions/${row.original.id}/presentation/download-slides`}
                                target="_blank"
                            >
                                Als PDF öffnen
                            </MenuItem>
                        )}
                        {Boolean(row.original.presentationAttachment) && (
                            <MenuItem
                                icon={<CloudDownloadRounded />}
                                href={`/api/education/instructions/${row.original.id}/presentation/download-attachment`}
                                target="_blank"
                            >
                                Download PowerPoint
                            </MenuItem>
                        )}
                        <MenuItem
                            icon={<EditRounded />}
                            to={`/education/instruction/${row.original.id}/presentation`}
                        >
                            Folien bearbeiten
                        </MenuItem>
                        <MenuItem
                            icon={<EditRounded />}
                            to={`/education/instruction/${row.original.id}/test`}
                        >
                            Test bearbeiten
                        </MenuItem>
                        <MenuItem
                            icon={<DeleteRounded />}
                            onClick={() =>
                                handleArchiveButtonClick(row.original.id)
                            }
                        >
                            Löschen
                        </MenuItem>
                    </MoreActionsButton>
                ),
            },
        ],
        [nameSortingFunction],
    );

    const data = useMemo(
        () =>
            orderBy(
                instructions.map(instruction => ({
                    ...instruction,
                    rawName: instruction.name,
                })),
                ['name'],
                ['asc'],
            ),
        [instructions],
    );

    const initialSortBy = useMemo(
        () => [
            {
                id: 'name',
                desc: false,
            },
        ],
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { hiddenColumns },
    } = useTable(
        {
            columns,
            data,
            hideColumnsPriority: ['areas', 'actions'],
            disableSortRemove: true,
            disableMultiSort: true,
            initialState: {
                sortBy: initialSortBy,
            },
            orderByFn: stableTableSort,
        },
        useCustomCellStyles,
        useResponsiveTable,
        useSortBy,
    );

    return (
        <>
            {instructionIdForArchiving !== null && (
                <InstructionArchiveModal
                    instruction={
                        instructionIdForArchiving
                            ? instructions.find(
                                  instruction =>
                                      instruction.id ===
                                      instructionIdForArchiving,
                              )
                            : null
                    }
                    onCancel={handleArchiveCancel}
                    onSuccess={handleArchiveSuccess}
                />
            )}

            <Table {...getTableProps()}>
                <StyledTableHead headerGroups={headerGroups} />
                <DefaultTableBody
                    rows={rows}
                    hiddenColumns={hiddenColumns}
                    visibleColumns={visibleColumns}
                    prepareRow={prepareRow}
                    getTableBodyProps={getTableBodyProps}
                />
            </Table>
        </>
    );
};

InstructionsTable.propTypes = {
    instructions: PropTypes.array.isRequired,
    onInstructionArchive: PropTypes.func.isRequired,
};

export default InstructionsTable;
