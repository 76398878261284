import api from 'Application/api/api';

const goToNextExamQuestion = (id, cancelToken = null) =>
    api.post(
        `/education/instruction-assignments/${id}/exam/go-to-next-question`,
        {},
        { cancelToken },
    );

export default goToNextExamQuestion;
