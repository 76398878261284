import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import mapClientContactPeopleToOptions from 'ProjectManager/Project/DataManagement/GeneralInformation/ContactPeople/helpers/mapClientContactPeopleToOptions';
import AsyncSelect from 'Common/components/Form/Fields/AsyncSelect';
import loadClientContactPeopleOptions from 'ProjectManager/Project/DataManagement/GeneralInformation/ContactPeople/helpers/loadClientContactPeopleOptions';
import { debounce } from '@material-ui/core';
import changeProjectClientContactPeople from 'ProjectManager/Project/Common/api/dataManagement/changeProjectClientContactPeople';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import hasUnsavedChangesState from 'Common/recoil/hasUnsavedChangesState';
import reactSelectCustomComponents from 'Common/components/Form/Fields/reactSelectCustomComponents';
import { AddRounded } from '@material-ui/icons';
import Button from 'Common/components/Button';
import AddMoreContactPeopleModal from 'ProjectManager/Project/DataManagement/GeneralInformation/ContactPeople/components/AddMoreContactPeopleModal';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import withAdditionalClientContactPeople from 'ProjectManager/Project/Common/recoil/project/modifiers/contactPerson/withAdditionalClientContactPeople';
import withClientContactPeople from 'ProjectManager/Project/Common/recoil/project/modifiers/contactPerson/withClientContactPeople';

const AddMoreButton = styled(Button)`
    width: 100%;
    justify-content: flex-start;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;

    svg {
        font-size: 18px !important;
    }
`;

const Menu = ({ children, ...props }) => (
    <reactSelectCustomComponents.Menu {...props}>
        <AddMoreButton
            text
            dark
            icon={<AddRounded />}
            type="button"
            onClick={props.selectProps.onAddMoreButtonClick}
            onTouchStart={() => {
                document.isInsideOfMenuTouched = true;
            }}
        >
            Weitere hinzufügen
        </AddMoreButton>
        {children}
    </reactSelectCustomComponents.Menu>
);

const ContactPeople = props => {
    const [
        { id: projectId, client, clientContactPeople = [] },
        setProject,
    ] = useRecoilState(projectAtom);

    const [isTouched, setIsTouched] = useState(false);

    // State for tracking whether the available options
    // in the async select have to be re-loaded.
    const [optionsUpdatedCount, setOptionsUpdateCount] = useState(0);

    const clientId = client?.id;

    // Re-load the available options when the client has been changed
    useEffect(() => {
        if (clientId) {
            setOptionsUpdateCount(prevCount => prevCount + 1);
        }
    }, [clientId]);

    const [, setHasUnsavedChanges] = useRecoilState(hasUnsavedChangesState);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const debouncedSave = useCallback(
        debounce(
            async contactPeople => {
                await changeProjectClientContactPeople(
                    projectId,
                    contactPeople.map(contactPerson => contactPerson.id),
                    source.token,
                );

                setHasUnsavedChanges(false);
            },
            350,
            {
                leading: true,
                trailing: true,
            },
        ),
        [projectId, source.token],
    );

    const handleChange = options => {
        setIsTouched(true);
        setHasUnsavedChanges(true);

        const newOptions = options ?? [];
        const newContactPeople = newOptions.map(option =>
            client.contactPeople.find(
                contactPerson => contactPerson.id === option.value,
            ),
        );

        // We're directly using Recoil's state for this field
        setProject(withClientContactPeople(newContactPeople));

        debouncedSave(newContactPeople);
    };

    const [isAddMoreModalOpened, setIsAddMoreModalOpened] = useState(false);

    const handleAddMoreButtonClick = () => {
        setIsAddMoreModalOpened(true);
    };

    const handleCancelAddMorePeople = () => {
        setIsAddMoreModalOpened(false);
    };

    const handleAddMoreContactPeople = async newContactPeople => {
        const mergedContactPeople = [
            ...clientContactPeople,
            ...newContactPeople,
        ];

        await changeProjectClientContactPeople(
            projectId,
            mergedContactPeople.map(contactPerson => contactPerson.id),
            source.token,
        );

        setProject(withAdditionalClientContactPeople(newContactPeople));

        setIsAddMoreModalOpened(false);

        // Force a re-load of the available options,
        // so that the newly added ones can be visible when the drop-down is opened.
        setOptionsUpdateCount(prevCount => prevCount + 1);
    };

    // Dynamic options to "feed" the Select component.
    // When the client gets changed, the options get changed as well.
    const loadOptions = useCallback(
        query =>
            client ? loadClientContactPeopleOptions(client.id, query) : [],
        [client],
    );

    const selectedOptions = mapClientContactPeopleToOptions(
        clientContactPeople,
    );

    return (
        <>
            <AsyncSelect
                key={optionsUpdatedCount}
                id="clientContactPeople"
                name="clientContactPeople"
                label="Kontaktperson"
                value={selectedOptions}
                onChange={handleChange}
                loadOptions={loadOptions}
                isDisabled={!client}
                isMulti
                isSearchable
                isRequired
                components={{
                    ...reactSelectCustomComponents,
                    Menu,
                }}
                onAddMoreButtonClick={handleAddMoreButtonClick}
                touched={isTouched}
                error={
                    selectedOptions.length === 0 ? 'Pflichtangabe' : undefined
                }
                {...props}
            />
            {isAddMoreModalOpened && (
                <AddMoreContactPeopleModal
                    isOpen
                    client={client}
                    onAddMore={handleAddMoreContactPeople}
                    onCancel={handleCancelAddMorePeople}
                    shouldReturnFocusAfterClose={false}
                />
            )}
        </>
    );
};

export default ContactPeople;
