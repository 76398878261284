import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import { useRecoilValue } from 'recoil';
import availablePointsAtom from 'ProjectManager/Project/Introductions/Common/recoil/availablePoints/availablePointsAtom';

const PointsConfirmationModal = ({
    introduction,
    onNextButtonClick,
    onClose,
}) => {
    const relevantParticipants = introduction.participants.filter(
        participant => participant.isRelevant,
    );

    const availablePoints = useRecoilValue(availablePointsAtom);

    return (
        <Modal
            isOpen
            onRequestClose={onClose}
            noTransition
            buttons={
                <>
                    <Button text dark type="button" onClick={onClose}>
                        Abbrechen
                    </Button>
                    <Button type="button" onClick={onNextButtonClick}>
                        Weiter
                    </Button>
                </>
            }
        >
            <ModalTitle>Durchführen</ModalTitle>
            <p>
                Sie sind dabei die Einweisung für
                {` ${relevantParticipants.length} `}zu starten. Bitte beachte,
                dass nur die unten angezeigten Einweisungspunkte dokumentiert
                werden. Bist du dir sicher das du die Einweisung starten willst?
            </p>
            <ul>
                {availablePoints
                    .filter(point => introduction.points.includes(point.value))
                    .map(point => (
                        <li key={point.value}>{point.label}</li>
                    ))}
            </ul>
        </Modal>
    );
};

PointsConfirmationModal.propTypes = {
    introduction: PropTypes.object.isRequired,
    onNextButtonClick: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default PointsConfirmationModal;
