import { useRecoilValue } from 'recoil';
import isProjectEmployeeSelectionStepCompletedSelector from 'ProjectManager/Project/Common/recoil/project/selectors/isProjectEmployeeSelectionStepCompletedSelector';

const useIsProjectEmployeeSelectionStepCompleted = () => {
    // noinspection UnnecessaryLocalVariableJS
    const isProjectEmployeeSelectionStepCompleted = useRecoilValue(
        isProjectEmployeeSelectionStepCompletedSelector,
    );

    return isProjectEmployeeSelectionStepCompleted;
};

export default useIsProjectEmployeeSelectionStepCompleted;
