import { store } from 'Application/store';

const isAuthorized = authorizedRoles => {
    const userRole = store.getState().user.role;

    if (!userRole) {
        return false;
    }

    return authorizedRoles.includes(userRole);
};

export default isAuthorized;
