import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { prop } from 'styled-tools';
import PhotoActionsMenu from 'ProjectManager/Project/DataManagement/GeneralInformation/Photo/PhotoActionsMenu';

const Image = styled.div`
    width: 100%;
    height: 100%;
    background-image: url('${prop('src')}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
`;

const ImageActionsButtonWrapper = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
`;

const PhotoUploadedImage = ({
    image,
    isLoading,
    onUploadNewButtonClick,
    onDeleteButtonClick,
}) => (
    <>
        <Image src={image} />
        {!isLoading && (
            <ImageActionsButtonWrapper>
                <PhotoActionsMenu
                    onUploadNewButtonClick={onUploadNewButtonClick}
                    onDeleteButtonClick={onDeleteButtonClick}
                />
            </ImageActionsButtonWrapper>
        )}
    </>
);

PhotoUploadedImage.propTypes = {
    image: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onUploadNewButtonClick: PropTypes.func.isRequired,
    onDeleteButtonClick: PropTypes.func.isRequired,
};

export default PhotoUploadedImage;
