import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TopicDeselectModal from 'ProjectManager/DataManagement/TopicManagement/components/TopicSelection/components/TopicDeselectModal';
import useTopicFunctions from 'ProjectManager/DataManagement/TopicManagement/components/TopicSelection/hooks/topicFunctions/useTopicFunctions';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import SwitchableTile from 'Common/components/SwitchableTile';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';

const TopicTile = ({ topic, defaultIsSelected }) => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);
    const isProject = dataManagementEntity === dataManagementEntities.PROJECT;

    const { status: projectStatus } = useRecoilValue(projectAtom);

    const { selectTopic, deselectTopic } = useTopicFunctions();

    const [isSelected, setIsSelected] = useState(defaultIsSelected);
    const [showDeselectModal, setShowDeselectModal] = useState(false);

    const handleSelect = async () => {
        setIsSelected(true);

        try {
            await selectTopic(topic);
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsSelected(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const handleDeselect = () => {
        setShowDeselectModal(true);
    };

    const handleDeselectConfirm = async () => {
        await deselectTopic(topic.id);
        setIsSelected(false);
        setShowDeselectModal(false);
    };

    const closeModal = () => {
        setShowDeselectModal(false);
    };

    return (
        <>
            <SwitchableTile
                title={topic.name}
                description={topic.description}
                isSelected={isSelected}
                isDisabled={isProject && projectStatus === projectStatuses.ENDED}
                disabledTooltipText="Auswahl nicht erlaubt. Projekt ist im Status Beendet."
                onSelect={handleSelect}
                onDeselect={handleDeselect}
            />
            {showDeselectModal && (
                <TopicDeselectModal
                    onConfirm={handleDeselectConfirm}
                    onCancel={closeModal}
                />
            )}
        </>
    );
};

TopicTile.propTypes = {
    topic: PropTypes.object.isRequired,
    defaultIsSelected: PropTypes.bool.isRequired,
};

export default TopicTile;
