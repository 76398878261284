import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import requestFilesDownload from 'ProjectManager/Template/Common/api/downloadRequest/requestFilesDownload';

const useRequestTemplateFilesDownloadFunction = () => {
    const { id: templateId } = useRecoilValue(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const requestDownload = useCallback(async () => {
        await requestFilesDownload(templateId, source.token);
    }, [templateId, source.token]);

    return requestDownload;
};

export default useRequestTemplateFilesDownloadFunction;
