import api from 'Application/api/api';

const getProjectQuestionFiles = (projectId, questionId, isCustom, cancelToken = null) =>
    api.get(
        `project-manager/projects/${projectId}/questionnaire/questions/${questionId}/${
            isCustom ? 1 : 0
        }/files`,
        {
            cancelToken,
        },
    );

export default getProjectQuestionFiles;
