import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import Button from 'Common/components/Button';
import axios from 'axios';
import startProject from 'ProjectManager/Project/Common/api/status/startProject';
import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import getProject from 'ProjectManager/Project/Common/api/getProject';
import { pick } from 'lodash';

const ConfirmModal = ({ onClose }) => {
    const [{ id }, setProject] = useRecoilState(projectAtom);

    const [isConfirming, setIsConfirming] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirm = async () => {
        setIsConfirming(true);

        try {
            await startProject(id, source.token);

            const response = await getProject(id, [], source.token);

            setProject(prevProject => ({
                ...prevProject,
                ...pick(response.data, ['status', 'startingDate']),
            }));

            setIsConfirming(false);

            onClose();
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsConfirming(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    return (
        <Modal
            isOpen
            onRequestClose={isConfirming ? undefined : onClose}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        disabled={isConfirming}
                        onClick={onClose}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isConfirming}
                        disabled={isConfirming}
                        onClick={handleConfirm}
                    >
                        Projekt starten
                    </Button>
                </>
            }
        >
            <ModalTitle>Projektstart vorverlegen</ModalTitle>
            <p>
                Mit der Bestätigung dieses Prozess startest du das Projekt
                heute.
            </p>
        </Modal>
    );
};

ConfirmModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default ConfirmModal;
