import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';

const AbortModal = ({ onCancel, onAbort }) => (
    <Modal
        isOpen
        onRequestClose={onCancel}
        buttons={
            <>
                <Button text dark type="button" onClick={onCancel}>
                    Zurück
                </Button>
                <Button type="button" onClick={onAbort}>
                    Verwerfen
                </Button>
            </>
        }
    >
        <ModalTitle>Erstellung der Einweisung Abbrechen</ModalTitle>
        <p>
            Du bist dabei die Erstellung abzubrechen. Bitte beachte, dass die
            Einweisung nicht gespeichert wird. Bist du dir sicher, dass du die
            Erstellung abbrechen willst?
        </p>
    </Modal>
);

AbortModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onAbort: PropTypes.func.isRequired,
};

export default AbortModal;
