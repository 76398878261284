import React, { useCallback, useState } from 'react';
import employeeStatusFilters from 'Employee/constants/employeeStatusFilters';
import employeeTypeFilters from 'Employee/constants/employeeTypeFilters';
import getEmployees from 'Employee/api/getEmployees';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';
import Sidebar from 'Archive/components/employee-list/Sidebar';
import List from 'Archive/components/employee-list/List';
import styled from 'styled-components/macro';
import BackButton from 'Application/components/Header/BackButton/BackButton';
import { useSelector } from 'react-redux';
import getYear from 'date-fns/getYear';
import createNewDate from 'Common/utils/createNewDate';

const Layout = styled.div`
    @media screen and (min-width: 940px) {
        display: flex;
    }
`;

const EmployeesListArchive = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const [selectedStatus, setSelectedStatus] = useState(
        employeeStatusFilters.ACTIVE,
    );

    const [selectedEmployeeType, setSelectedEmployeeType] = useState(
        employeeTypeFilters.ALL,
    );

    const isUsingOnlyNewModules = useSelector(
        state => state.user.isUsingOnlyNewModules,
    );

    const handleSearchTermChange = value => {
        setSearchTerm(value);
    };

    const employeesRequest = useCallback(
        cancelToken => getEmployees([], cancelToken),
        [],
    );

    const {
        data: employees,
        loadData: loadEmployees,
        isLoading,
        hasError,
    } = useAxiosRequest(employeesRequest, []);

    let filteredEmployees = [...employees];

    if (searchTerm) {
        filteredEmployees = filteredEmployees.filter(employee => {
            const searchTerms = searchTerm
                .toLowerCase()
                .split(' ')
                .filter(term => !!term);

            const firstName = employee.firstName.toLowerCase();
            const isFirstNameMatched =
                searchTerms.findIndex(term => firstName.startsWith(term)) !==
                -1;

            const lastName = employee.lastName.toLowerCase();
            const isLastNameMatched =
                searchTerms.findIndex(term => lastName.startsWith(term)) !== -1;

            return searchTerms.length > 1
                ? isFirstNameMatched && isLastNameMatched
                : isFirstNameMatched || isLastNameMatched;
        });
    }

    if (selectedEmployeeType === employeeTypeFilters.TRAINEE) {
        filteredEmployees = filteredEmployees.filter(
            employee => employee.isTrainee,
        );
    }

    const year = getYear(createNewDate());

    return (
        <>
            {isUsingOnlyNewModules ? (
                <>
                    <Helmet>
                        <title>Archiv</title>
                    </Helmet>
                    <BackButton to="/dashboard" />
                    <Breadcrumb isActive>Archiv</Breadcrumb>
                </>
            ) : (
                <>
                    <Helmet>
                        <title>{`Mitarbeiter (2020 - ${year}) - Archiv`}</title>
                    </Helmet>
                    <BackButton to="/archive" />
                    <Breadcrumb to="/archive">Archiv</Breadcrumb>
                    <Breadcrumb isActive>{`Mitarbeiter (2020 - ${year})`}</Breadcrumb>
                </>
            )}
            <Layout>
                {isLoading ? (
                    <Loader />
                ) : hasError ? (
                    <RetryLoading onRetry={loadEmployees} />
                ) : (
                    <>
                        <Sidebar
                            searchTerm={searchTerm}
                            onSearchTermChange={handleSearchTermChange}
                            selectedStatus={selectedStatus}
                            onStatusChange={setSelectedStatus}
                            selectedEmployeeType={selectedEmployeeType}
                            onEmployeeTypeChange={setSelectedEmployeeType}
                            employees={filteredEmployees}
                        />
                        <List
                            selectedStatus={selectedStatus}
                            employees={filteredEmployees}
                        />
                    </>
                )}
            </Layout>
        </>
    );
};

export default EmployeesListArchive;
