import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { EditRounded } from '@material-ui/icons';
import Button from 'Common/components/Button';
import ProjectDrawer from 'ProjectManager/Project/Preview/ProjectDrawer';
import TimelineAndWorkingHours from 'ProjectManager/Project/DataManagement/GeneralInformation/TimelineAndWorkingHours';
import WarningMessage from 'Common/components/WarningMessage';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';

const Wrapper = styled.div`
    > button {
        padding-left: 0;
    }
`;

const EditButton = () => {
    const [isDrawerOpened, setIsDrawerOpened] = useState(false);

    const openDrawer = () => {
        setIsDrawerOpened(true);
    };

    const closeDrawer = () => {
        setIsDrawerOpened(false);
    };

    const { workingDays } = useRecoilValue(projectAtom);

    const hasMissingRequiredData = workingDays.length === 0;

    return (
        <Wrapper>
            <Button
                underline
                icon={<EditRounded />}
                type="button"
                onClick={openDrawer}
            >
                Bearbeiten
            </Button>
            {hasMissingRequiredData && (
                <WarningMessage>Fehlende erforderliche Daten</WarningMessage>
            )}
            <ProjectDrawer
                isOpened={isDrawerOpened}
                onOpen={openDrawer}
                onClose={closeDrawer}
            >
                <TimelineAndWorkingHours isInsideDrawer />
            </ProjectDrawer>
        </Wrapper>
    );
};

export default EditButton;
