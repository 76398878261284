const withCustomTopic = customTopic => prevProjectOrTemplate => {
    const customTopics = [...prevProjectOrTemplate.customTopics];

    customTopics.push(customTopic);

    return {
        ...prevProjectOrTemplate,
        customTopics,
    };
};

export default withCustomTopic;
