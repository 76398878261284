import { selector } from 'recoil';
import { uniq } from 'lodash';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';

const introducedProjectParticipantIdsSelector = selector({
    key: 'projectManagement_introducedProjectParticipantIds',
    get: ({ get }) => {
        const { introductions } = get(projectAtom);

        return uniq(
            introductions
                .filter(introduction => introduction.isConducted)
                .reduce(
                    (projectParticipantIds, introduction) => [
                        ...projectParticipantIds,
                        ...introduction.participants
                            .filter(participant => participant.isRelevant)
                            .map(
                                participant =>
                                    participant.projectParticipant.id,
                            ),
                    ],
                    [],
                ),
        );
    },
});

export default introducedProjectParticipantIdsSelector;
