import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import createProjectFromTemplate from 'ProjectManager/Project/Common/api/createProjectFromTemplate';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import getClient from 'ProjectManager/Client/api/getClient';
import changeProjectClientContactPeople from 'ProjectManager/Project/Common/api/dataManagement/changeProjectClientContactPeople';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import useClientField from 'ProjectManager/Project/DataManagement/GeneralInformation/Client/hooks/useClientField';
import mapClientToOption from 'ProjectManager/Project/DataManagement/GeneralInformation/Client/helpers/mapClientToOption';

const ProjectCreateModal = ({ template, onClose, ...props }) => {
    const [isCreating, setIsCreating] = useState(false);

    const [clientOption, setClientOption] = useState(null);
    const [isNewClient, setIsNewClient] = useState(false);

    const dispatch = useDispatch();

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleClientChange = newClientOption => {
        setIsNewClient(false);
        setClientOption(newClientOption);
    };

    const handleCreateClientSuccess = async newClient => {
        setIsNewClient(true);
        setClientOption(mapClientToOption(newClient));
    };

    const handleConfirm = async () => {
        setIsCreating(true);

        try {
            const projectResponse = await createProjectFromTemplate(
                clientOption.value,
                template.id,
                source.token,
            );

            const newProject = projectResponse.data;

            if (isNewClient) {
                // Load the same client, but with its contact people
                const clientResponse = await getClient(
                    clientOption.value,
                    ['contactPeople'],
                    source.token,
                );

                const newClientWithContactPeople = clientResponse.data;

                // When creating a client for the first time and at the same time
                // creating a new project, assign all the client's contact people
                // to the newly created project.
                await changeProjectClientContactPeople(
                    newProject.id,
                    newClientWithContactPeople.contactPeople.map(
                        contactPerson => contactPerson.id,
                    ),
                    source.token,
                );
            }

            setIsCreating(false);

            onClose();

            notify('Das Projekt wurde erfolgreich erstellt', {
                type: toast.TYPE.SUCCESS,
            });

            dispatch(push(`/project-manager/project/${newProject.id}/create`));
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsCreating(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const { field } = useClientField({
        onClientCreate: handleCreateClientSuccess,
        selectProps: {
            value: clientOption,
            onChange: handleClientChange,
            isDisabled: isCreating,
            menuPosition: 'fixed',
            menuPortalTarget: document.body,
        },
    });

    return (
        <Modal
            isOpen
            onRequestClose={isCreating ? undefined : onClose}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onClose}
                        disabled={isCreating}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isCreating}
                        onClick={handleConfirm}
                        disabled={isCreating || !clientOption}
                    >
                        Erstellen
                    </Button>
                </>
            }
            {...props}
        >
            <ModalTitle>
                Erstellung eines Projektes aus einer Vorlage
            </ModalTitle>
            <p>
                Um die Erstellung zu initiieren musst du den Kunden wählen bzw.
                eintragen.
            </p>
            {field}
        </Modal>
    );
};

ProjectCreateModal.propTypes = {
    template: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ProjectCreateModal;
