import colors from 'Common/constants/colors';
import roles from 'ProjectManager/Project/Preview/People/roles';

const rolesElements = {
    [roles.CLIENT_CONTACT_PERSON]: {
        color: colors.GREEN,
        text: 'Kontaktperson',
    },
    [roles.PROJECT_COORDINATOR]: {
        color: colors.BLUE,
        text: 'SiGeko',
    },
    [roles.SUPERVISOR]: {
        color: colors.WARNING,
        text: 'Auftragsverantwortlicher',
    },
};

export default rolesElements;
