import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import { pick } from 'lodash';
import getQuestionnaireQuestionIdentifier from 'ProjectManager/DataManagement/Questionnaire/helpers/getQuestionnaireQuestionIdentifier';
import Button from 'Common/components/Button';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import questionInGuidanceModeState from 'ProjectManager/DataManagement/Questionnaire/components/Questionnaire/recoil/questionInGuidanceModeState';
import InnerBoxButtons from 'Common/components/Boxes/InnerBoxButtons';
import useWindowScroll from '@react-hook/window-scroll';
import { usePrevious } from 'react-use';
import isQuestionAnswered from 'ProjectManager/DataManagement/Questionnaire/helpers/isQuestionAnswered';
import useAreAllStepsCompleted from 'ProjectManager/DataManagement/Common/hooks/useAreAllStepsCompleted/useAreAllStepsCompleted';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';

const HeaderOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.55);
`;

const approximateStickyElementsHeight = 80;

const useUnansweredQuestionsGuidance = ({ onComplete, allQuestions }) => {
    const areAllStepsCompleted = useAreAllStepsCompleted();

    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);
    const isProject = dataManagementEntity === dataManagementEntities.PROJECT;
    const { isCreated: isProjectCreated } = useRecoilValue(projectAtom);

    const [isLoading, setIsLoading] = useState(false);

    const setQuestionInGuidanceMode = useSetRecoilState(
        questionInGuidanceModeState,
    );

    const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);
    const [questions, setQuestions] = useState([]);

    const rootElementRef = useRef(null);

    useEffect(() => {
        rootElementRef.current = document.getElementById('root');
    }, []);

    const isLastQuestion = activeQuestionIndex === questions.length - 1;

    // Tracks whether the current question is in the recoil state
    const hasTheQuestionInState = useMemo(() => {
        const activeQuestion = questions[activeQuestionIndex];

        if (!activeQuestion) {
            return false;
        }

        const activeQuestionId = getQuestionnaireQuestionIdentifier(
            activeQuestion,
        );

        return Boolean(
            allQuestions.find(
                question =>
                    getQuestionnaireQuestionIdentifier(question) ===
                    activeQuestionId,
            ),
        );
    }, [activeQuestionIndex, allQuestions, questions]);

    const hadTheQuestionInState = usePrevious(hasTheQuestionInState);

    // Change the question guidance mode depending on the active index (step)
    useEffect(() => {
        if (questions.length === 0) {
            setQuestionInGuidanceMode(null);
        } else {
            setQuestionInGuidanceMode(questions[activeQuestionIndex]);
        }
    }, [activeQuestionIndex, questions, setQuestionInGuidanceMode]);

    const scrollY = useWindowScroll();
    const prevActiveQuestionIndex = usePrevious(activeQuestionIndex);

    // Scroll to the active question index when it is being changed
    useEffect(() => {
        if (
            questions.length > 0 &&
            activeQuestionIndex !== prevActiveQuestionIndex
        ) {
            const activeQuestion = questions[activeQuestionIndex];
            const activeQuestionId = getQuestionnaireQuestionIdentifier(
                activeQuestion,
            );

            const activeQuestionDomElementId = `questionnaireQuestion-${activeQuestionId}`;

            const distanceToQuestion = document
                .getElementById(activeQuestionDomElementId)
                .getBoundingClientRect().top;

            window.scroll({
                top:
                    scrollY +
                    distanceToQuestion -
                    approximateStickyElementsHeight -
                    15, // Ensure to have some spacing at the top
                left: 0,
                behavior: 'smooth',
            });
        }
    }, [activeQuestionIndex, prevActiveQuestionIndex, questions, scrollY]);

    const startGuidance = unansweredQuestions => {
        const questionsWithOmittedProperties = unansweredQuestions.map(
            question => pick(question, ['id', 'isCustom']),
        );

        setActiveQuestionIndex(0);
        setQuestions(questionsWithOmittedProperties);

        rootElementRef.current.style.pointerEvents = 'none';
    };

    const cancelGuidance = () => {
        setQuestions([]);
        setActiveQuestionIndex(null);

        rootElementRef.current.style.pointerEvents = null;
    };

    const completeGuidance = useCallback(async () => {
        setIsLoading(true);

        await onComplete();
        cancelGuidance();

        setIsLoading(false);
    }, [onComplete]);

    const goToNextQuestion = useCallback(() => {
        setActiveQuestionIndex(prevIndex => prevIndex + 1);
    }, []);

    const renderGuidance = () => {
        if (questions.length === 0) {
            return null;
        }

        const activeQuestion = questions[activeQuestionIndex];
        const activeQuestionId = getQuestionnaireQuestionIdentifier(
            activeQuestion,
        );

        const activeQuestionDomElementId = `questionnaireQuestion-${activeQuestionId}`;

        const questionInState = allQuestions.find(
            question =>
                getQuestionnaireQuestionIdentifier(question) ===
                activeQuestionId,
        );

        if (Boolean(questionInState)) {
            return (
                <>
                    {ReactDOM.createPortal(
                        <InnerBoxButtons>
                            <Button text type="button" onClick={cancelGuidance}>
                                Abbrechen
                            </Button>
                            <Button
                                type="button"
                                disabled={
                                    (isProject &&
                                        !isQuestionAnswered(questionInState)) ||
                                    isLoading
                                }
                                isLoading={isLoading}
                                onClick={
                                    isLastQuestion
                                        ? completeGuidance
                                        : goToNextQuestion
                                }
                            >
                                {isProject &&
                                !isProjectCreated &&
                                areAllStepsCompleted
                                    ? 'Erstellung abschließen'
                                    : 'Weiter'}
                            </Button>
                        </InnerBoxButtons>,
                        document.getElementById(activeQuestionDomElementId),
                    )}
                    {ReactDOM.createPortal(
                        <HeaderOverlay />,
                        document.getElementsByTagName('header')[0],
                    )}
                </>
            );
        }

        return null;
    };

    // If the current question disappears from the recoil state,
    // decide whether to go automatically to the next question,
    // or to complete the guidance process altogether if this is the last question of the guidance.
    useEffect(() => {
        if (hadTheQuestionInState && !hasTheQuestionInState) {
            if (isLastQuestion) {
                // noinspection JSIgnoredPromiseFromCall
                completeGuidance();
            } else {
                goToNextQuestion();
            }
        }
    }, [
        hasTheQuestionInState,
        hadTheQuestionInState,
        isLastQuestion,
        completeGuidance,
        goToNextQuestion,
    ]);

    return {
        startGuidance,
        renderGuidance,
    };
};

export default useUnansweredQuestionsGuidance;
