import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import NumberIndicator from 'Common/components/NumberIndicator';
import { isArray, isEmpty, omit, pickBy } from 'lodash';

const Wrapper = styled(NumberIndicator)`
    position: absolute;
    top: -10px;
    right: -10px;
    pointer-events: none;
`;

const ActiveFiltersCounter = ({ filters, clearedState, excludedFilterKeys }) => {
    const countableFilters = omit(filters, excludedFilterKeys);

    const activeFilters = pickBy(countableFilters, (value, key) => {
        // Compare the current filter value with the cleared state.
        // If it is different, then there's an active filter.
        if (clearedState.hasOwnProperty(key)) {
            return JSON.stringify(clearedState[key]) !== JSON.stringify(value);
        }

        // Otherwise, fall back to a default check for primitive type values
        if (isArray(value)) {
            return !isEmpty(value);
        }

        return Boolean(value);
    });

    const activeFiltersCount = Object.keys(activeFilters).length;

    if (activeFiltersCount > 0) {
        return <Wrapper>{activeFiltersCount}</Wrapper>;
    }

    return null;
};

ActiveFiltersCounter.defaultProps = {
    clearedState: [],
    excludedFilterKeys: [],
};

ActiveFiltersCounter.defaultProps = {
    filters: PropTypes.object.isRequired,
    clearedState: PropTypes.object,
    excludedFilterKeys: PropTypes.array,
};

export default ActiveFiltersCounter;
