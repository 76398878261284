import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ContentSection from 'ProjectManager/DataManagement/Common/components/ContentSection';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import Timeline from 'ProjectManager/Project/DataManagement/GeneralInformation/Timeline';
import FormField from 'Common/components/Form/FormField';
import WorkingHoursAndDays from 'ProjectManager/DataManagement/WorkingHoursAndDays';

const TimelineWrapper = styled(FormField)`
    max-width: 342px;
`;

const TimelineAndWorkingHours = ({ isInsideDrawer }) => (
    <ContentSection>
        <OuterBoxTitle>Einsatzzeiten</OuterBoxTitle>
        <TimelineWrapper>
            <Timeline isInsideDrawer={isInsideDrawer} />
        </TimelineWrapper>
        <WorkingHoursAndDays isInsideDrawer={isInsideDrawer} />
    </ContentSection>
);

TimelineAndWorkingHours.defaultProps = {
    isInsideDrawer: false,
};

TimelineAndWorkingHours.propTypes = {
    isInsideDrawer: PropTypes.bool,
};

export default TimelineAndWorkingHours;
