import React, { useState } from 'react';
import Input from 'Common/components/Form/Fields/Input';
import { AddRounded, SearchRounded } from '@material-ui/icons';
import styled from 'styled-components/macro';
import DriverLicenseControlCreateForm from 'Measure/components/vehicle/driver-license/DriverLicenseControlCreateForm';
import PropTypes from 'prop-types';
import driverLicenseStatusFilters from 'Measure/constants/driverLicenseStatusFilters';
import { ifProp } from 'styled-tools';
import DriverLicenseControlProgress from 'Measure/components/vehicle/driver-license/DriverLicenseControlProgress';
import DriverLicenseControlDocuments from 'Measure/components/vehicle/driver-license/DriverLicenseControlDocuments';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import driverLicensePendingFilters from 'Measure/constants/driverLicensePendingFilters';
import colors from 'Common/constants/colors';
import OuterBox from 'Common/components/Boxes/OuterBox';
import InnerBox from 'Common/components/Boxes/InnerBox';
import NumberIndicator from 'Common/components/NumberIndicator';

const Wrapper = styled.div`
    margin-bottom: 10px;
    border-radius: 4px;

    @media screen and (min-width: 940px) {
        position: sticky;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        top: 80px;
        max-height: calc(100vh - 150px);
        width: 300px;
        flex: 0 0 300px;
        align-self: flex-start;
        margin-right: 10px;
        margin-bottom: 0;
    }
`;

const CreateButton = styled.button`
    display: flex;
    width: 100%;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    color: ${colors.DIRTY_WHITE};
    background: none;
    border: 0 none;
    outline: 0;
    cursor: pointer;

    svg {
        margin-left: 10px;
    }
`;

const SidebarBox = styled(OuterBox)`
    margin-top: 10px;
    padding: 0 20px;

    > div {
        border-bottom: 1px solid ${colors.DARK_DIVIDER};

        &:last-child {
            border-bottom: 0 none;
        }
    }
`;

const SearchWrapper = styled.div`
    padding: 20px 0;
`;

const DriverLicenseFiltersWrapper = styled.div`
    padding: 20px 0;
`;

const DriverLicenseFiltersInner = styled(InnerBox)`
    display: flex;
`;

const StatusFilterButton = styled.button`
    position: relative;
    flex: 1;
    margin-right: 1px;
    padding: 14px 20px;
    font-size: 13px;
    color: ${ifProp('isActive', colors.DARK_GRAY, colors.DIRTY_WHITE)};
    background: ${ifProp(
        'isActive',
        colors.WHITE,
        colors.INNER_BOX_BACKGROUND,
    )};
    border: 0 none;
    outline: 0;
    cursor: pointer;

    &:hover:not([disabled]) {
        background: ${colors.DARK_DIVIDER};
    }

    &[disabled] {
        cursor: default;
    }

    &:first-child {
        border-radius: 4px 0 0 4px;
    }

    &:last-child {
        border-radius: 0 4px 4px 0;
    }
`;

const ItemsFound = styled(NumberIndicator)`
    position: absolute;
    top: -10px;
    right: 10px;
`;

const DriverLicenseExaminerDashboardSidebar = ({
    searchTerm,
    onSearchTermChange,
    selectedTabIndex,
    selectedStatus,
    onStatusChange,
    selectedPendingFilter,
    onPendingFilterChange,
    driverLicenseControlsFound,
}) => {
    const dispatch = useDispatch();

    const [isCreateBoxOpened, setIsCreateBoxOpened] = useState(false);

    const handleCreateBoxButtonClick = () => {
        setIsCreateBoxOpened(prevIsCreateBoxOpened => !prevIsCreateBoxOpened);
    };

    const handleSearchInputChange = e => {
        onSearchTermChange(e.target.value);
    };

    const handleCreateCancel = () => {
        setIsCreateBoxOpened(false);
    };

    const handleCreateSuccess = driverLicenseControl => {
        setIsCreateBoxOpened(false);

        dispatch(
            push(
                `/measures/vehicles/driver-license/create/${driverLicenseControl.id}`,
            ),
        );
    };

    const numberOfActiveDriverLicenseControlsFound = driverLicenseControlsFound.filter(
        driverLicenseControlFound => driverLicenseControlFound.isActive,
    ).length;

    const numberOfInactiveDriverLicenseControlsFound = driverLicenseControlsFound.filter(
        driverLicenseControlFound => !driverLicenseControlFound.isActive,
    ).length;

    return (
        <Wrapper>
            <OuterBox>
                <CreateButton
                    isActive={isCreateBoxOpened}
                    onClick={handleCreateBoxButtonClick}
                >
                    Führerscheinkontrolle
                    <AddRounded />
                </CreateButton>
                {isCreateBoxOpened && (
                    <DriverLicenseControlCreateForm
                        onCancel={handleCreateCancel}
                        onSuccess={handleCreateSuccess}
                    />
                )}
            </OuterBox>
            <SidebarBox>
                <SearchWrapper>
                    <Input
                        name="search"
                        label="Suche"
                        icon={SearchRounded}
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                    />
                </SearchWrapper>
                {selectedTabIndex === 0 && (
                    <DriverLicenseFiltersWrapper>
                        <DriverLicenseFiltersInner>
                            <StatusFilterButton
                                disabled={
                                    selectedPendingFilter ===
                                    driverLicensePendingFilters.MY_PENDING
                                }
                                isActive={
                                    selectedPendingFilter ===
                                    driverLicensePendingFilters.MY_PENDING
                                }
                                onClick={() =>
                                    onPendingFilterChange(
                                        driverLicensePendingFilters.MY_PENDING,
                                    )
                                }
                            >
                                Meine Aufgaben
                            </StatusFilterButton>
                            <StatusFilterButton
                                disabled={
                                    selectedPendingFilter ===
                                    driverLicensePendingFilters.EMPLOYEE_PENDING
                                }
                                isActive={
                                    selectedPendingFilter ===
                                    driverLicensePendingFilters.EMPLOYEE_PENDING
                                }
                                onClick={() =>
                                    onPendingFilterChange(
                                        driverLicensePendingFilters.EMPLOYEE_PENDING,
                                    )
                                }
                            >
                                Mitarbeiter Aufgaben
                            </StatusFilterButton>
                        </DriverLicenseFiltersInner>
                    </DriverLicenseFiltersWrapper>
                )}
                {selectedTabIndex === 1 && (
                    <DriverLicenseFiltersWrapper>
                        <DriverLicenseFiltersInner>
                            <StatusFilterButton
                                disabled={
                                    selectedStatus ===
                                    driverLicenseStatusFilters.ACTIVE
                                }
                                isActive={
                                    selectedStatus ===
                                    driverLicenseStatusFilters.ACTIVE
                                }
                                onClick={() =>
                                    onStatusChange(
                                        driverLicenseStatusFilters.ACTIVE,
                                    )
                                }
                            >
                                Aktive Kontrollen
                                {!!searchTerm && (
                                    <ItemsFound>
                                        {
                                            numberOfActiveDriverLicenseControlsFound
                                        }
                                    </ItemsFound>
                                )}
                            </StatusFilterButton>
                            <StatusFilterButton
                                disabled={
                                    selectedStatus ===
                                    driverLicenseStatusFilters.INACTIVE
                                }
                                isActive={
                                    selectedStatus ===
                                    driverLicenseStatusFilters.INACTIVE
                                }
                                onClick={() =>
                                    onStatusChange(
                                        driverLicenseStatusFilters.INACTIVE,
                                    )
                                }
                            >
                                Deaktiviert Kontrollen
                                {!!searchTerm && (
                                    <ItemsFound>
                                        {
                                            numberOfInactiveDriverLicenseControlsFound
                                        }
                                    </ItemsFound>
                                )}
                            </StatusFilterButton>
                        </DriverLicenseFiltersInner>
                    </DriverLicenseFiltersWrapper>
                )}
                <DriverLicenseControlProgress />
                <DriverLicenseControlDocuments />
            </SidebarBox>
        </Wrapper>
    );
};

DriverLicenseExaminerDashboardSidebar.propTypes = {
    searchTerm: PropTypes.string.isRequired,
    onSearchTermChange: PropTypes.func.isRequired,
    selectedTabIndex: PropTypes.number.isRequired,
    selectedStatus: PropTypes.string.isRequired,
    onStatusChange: PropTypes.func.isRequired,
    selectedPendingFilter: PropTypes.string.isRequired,
    onPendingFilterChange: PropTypes.func.isRequired,
    driverLicenseControlsFound: PropTypes.array.isRequired,
};

export default DriverLicenseExaminerDashboardSidebar;
