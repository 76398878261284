import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useRecoilValue } from 'recoil';
import LabeledValue from 'ProjectManager/Project/Preview/LabeledValue';
import availablePointsAtom from 'ProjectManager/Project/Introductions/Common/recoil/availablePoints/availablePointsAtom';
import { EditRounded } from '@material-ui/icons';
import Button from 'Common/components/Button';
import useEditPointsButton from 'ProjectManager/Project/Introductions/Grid/hooks/useEditPointsButton';

const List = styled.ul`
    padding-left: 18px;
`;

const Points = ({ introduction }) => {
    const availablePoints = useRecoilValue(availablePointsAtom);

    const {
        isVisible: isEditButtonVisible,
        handleClick: handleEditButtonClick,
        renderDrawer: renderEditDrawer,
    } = useEditPointsButton(introduction);

    return (
        <LabeledValue label="Einweisungspunkte">
            <List>
                {availablePoints
                    .filter(point => introduction.points.includes(point.value))
                    .map(point => (
                        <li key={point.value}>{point.label}</li>
                    ))}
            </List>
            {isEditButtonVisible && (
                <Button
                    underline
                    icon={<EditRounded />}
                    type="button"
                    onClick={handleEditButtonClick}
                >
                    Bearbeiten
                </Button>
            )}
            {renderEditDrawer()}
        </LabeledValue>
    );
};

Points.propTypes = {
    introduction: PropTypes.object.isRequired,
};

export default Points;
