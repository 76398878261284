import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import axios from 'axios';
import Input from 'Common/components/Form/Fields/Input';
import { SearchRounded } from '@material-ui/icons';
import EmployeeList from 'ProjectManager/DataManagement/EmployeeSelection/components/EmployeeList';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import PersonRemove from 'Common/components/Icons/PersonRemove';
import { orderBy } from 'lodash';
import isEmployeeMatched from 'ProjectManager/DataManagement/EmployeeSelection/helpers/isEmployeeMatched';
import Button from 'Common/components/Button';
import { useWindowWidth } from '@react-hook/window-size';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Inner = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media screen and (min-width: 768px) {
        padding: 20px 10px 20px 20px;
        background: #313131;
        border-radius: 10px;
    }
`;

const SearchBar = styled.div`
    width: calc(100% - 10px);
    margin-bottom: 10px;

    @media screen and (min-width: 430px) {
        max-width: 220px;
    }
`;

const BottomButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    padding-right: 10px;

    @media screen and (min-width: 768px) {
        padding-right: 0;
    }
`;

const SelectedEmployees = ({
    assignableEmployees,
    participatingEmployees,
    lastAddedEmployeeIds,
    onRemove,
    onAfterRemove,
}) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchTermChange = e => {
        setSearchTerm(e.target.value);
    };

    const selectedEmployeeIds = participatingEmployees.map(
        employee => employee.id,
    );

    // Show only the selected ones
    const selectedEmployees = orderBy(
        assignableEmployees
            .filter(
                assignableEmployee =>
                    selectedEmployeeIds.includes(
                        assignableEmployee.employee.id,
                    ) &&
                    isEmployeeMatched(assignableEmployee.employee, searchTerm),
            )
            .map(assignableEmployee => ({
                ...assignableEmployee,
                lastAddedPosition: lastAddedEmployeeIds.indexOf(
                    assignableEmployee.employee.id,
                ),
            })),
        ['lastAddedPosition', 'employee.lastName', 'employee.firstName'],
        ['desc', 'asc', 'asc'],
    );

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 768;

    const [animatedRemovedIds, setAnimatedRemovedIds] = useState([]);

    const handleActionButtonClick = async employeeId => {
        try {
            const newValue = await onRemove([employeeId]);

            if (isMobile) {
                setAnimatedRemovedIds([employeeId]);
            }

            setTimeout(
                () => {
                    // Clear the filter if this employee was the last one from the search
                    if (selectedEmployees.length === 1 && searchTerm !== '') {
                        setSearchTerm('');
                    }

                    setAnimatedRemovedIds([]);

                    onAfterRemove([employeeId], newValue);
                },
                isMobile ? 700 : 0,
            );
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const [isRemovingAll, setIsRemovingAll] = useState(false);

    const handleRemoveAllButtonClick = async () => {
        setIsRemovingAll(true);

        try {
            const employeeIds = selectedEmployees.map(
                assignableEmployee => assignableEmployee.employee.id,
            );

            const newValue = await onRemove(employeeIds);

            if (isMobile) {
                setAnimatedRemovedIds(employeeIds);
            }

            setTimeout(
                () => {
                    // Clear the filter if all employees were removed and no one is left from the search
                    if (searchTerm !== '') {
                        setSearchTerm('');
                    }

                    setAnimatedRemovedIds([]);

                    onAfterRemove(employeeIds, newValue);
                },
                isMobile ? 700 : 0,
            );
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }

        setIsRemovingAll(false);
    };

    return (
        <Wrapper>
            <Inner>
                <SearchBar>
                    <Input
                        id="selectedEmployeesSearchTerm"
                        name="selectedEmployeesSearchTerm"
                        label="Suche"
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                        icon={SearchRounded}
                        disabled={isRemovingAll}
                    />
                </SearchBar>
                <EmployeeList
                    title="Einbezogene Mitarbeiter"
                    actionLabel="Entfernen"
                    actionIcon={<PersonRemove />}
                    onActionButtonClick={handleActionButtonClick}
                    isActionButtonDisabled={isRemovingAll}
                    noResultsText="Keine einbezogene Mitarbeiter"
                    assignableEmployees={selectedEmployees}
                    animatedIds={animatedRemovedIds}
                    animationText="Entfernt"
                />
            </Inner>
            {selectedEmployees.length > 1 && (
                <BottomButtonWrapper>
                    <Button
                        underline
                        icon={<PersonRemove />}
                        type="button"
                        onClick={handleRemoveAllButtonClick}
                        isLoading={isRemovingAll}
                        disabled={isRemovingAll}
                    >
                        Alle entfernen
                    </Button>
                </BottomButtonWrapper>
            )}
        </Wrapper>
    );
};

SelectedEmployees.propTypes = {
    assignableEmployees: PropTypes.array.isRequired,
    participatingEmployees: PropTypes.array.isRequired,
    lastAddedEmployeeIds: PropTypes.array.isRequired,
    onRemove: PropTypes.func.isRequired,
    onAfterRemove: PropTypes.func.isRequired,
};

export default SelectedEmployees;
