import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import colors from 'Common/constants/colors';
import { ChevronRightRounded } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const ItemLink = styled(Link)`
    display: flex;
    align-items: center;
    color: ${ifProp('$isActive', colors.LIGHT_GRAY, colors.DIRTY_WHITE)};
    font-weight: ${ifProp('$isActive', 400, 500)};
    text-decoration: none;
    overflow-wrap: anywhere;
    word-break: break-word;

    &:hover {
        color: ${colors.PRIMARY};
    }
`;

const ItemExternalLink = styled(ItemLink).attrs({
    as: 'a',
})``;

const ItemText = styled.span`
    display: flex;
    align-items: center;
    color: ${ifProp('isActive', colors.LIGHT_GRAY, colors.DIRTY_WHITE)};
    font-weight: ${ifProp('isActive', 400, 500)};
    cursor: default;
    overflow-wrap: anywhere;
    word-break: break-word;
`;

const Item = styled.div`
    font-size: 12px;
    line-height: 18px;

    &:last-of-type {
        ${ItemLink},
        ${ItemExternalLink} {
            color: ${colors.LIGHT_GRAY};
            font-weight: 400;
            pointer-events: none;
        }
    }
`;

const Chevron = styled(ChevronRightRounded)`
    margin: 0 3px;
    font-size: 17px !important;

    &:last-child {
        display: none;
    }
`;

const Breadcrumb = ({ to, href, isActive, ...props }) => {
    const [portalNode, setPortalNode] = useState();

    useEffect(() => {
        setPortalNode(document.getElementById('breadcrumbs'));
    }, []);

    const hasLink = !!to || !!href;
    const isRenderedAsLink = !isActive && hasLink;

    const LinkComponent = !!to ? ItemLink : ItemExternalLink;
    const linkProp = !!to ? 'to' : 'href';

    return portalNode
        ? ReactDOM.createPortal(
              <>
                  <Item>
                      {isRenderedAsLink ? (
                          <LinkComponent
                              {...{
                                  [linkProp]: to || href,
                              }}
                              $isActive={isActive}
                          >
                              {props.children}
                          </LinkComponent>
                      ) : (
                          <ItemText isActive={isActive}>
                              {props.children}
                          </ItemText>
                      )}
                  </Item>
                  {!isActive && <Chevron />}
              </>,
              portalNode,
          )
        : null;
};

Breadcrumb.defaultProps = {
    to: null,
    href: null,
    isActive: false,
};

Breadcrumb.propTypes = {
    to: PropTypes.string,
    href: PropTypes.string,
    isActive: PropTypes.bool,
};

export default Breadcrumb;
