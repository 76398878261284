import { useRecoilValue } from 'recoil';
import areAllProjectStepsCompletedSelector from 'ProjectManager/Project/Common/recoil/project/selectors/areAllProjectStepsCompletedSelector';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';

const useHasOtherPrimaryButtonForProject = () => {
    const areAllStepsCompleted = useRecoilValue(areAllProjectStepsCompletedSelector);
    const { isCreated } = useRecoilValue(projectAtom);

    return areAllStepsCompleted || isCreated;
};

export default useHasOtherPrimaryButtonForProject;
