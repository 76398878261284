import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { CloudUploadRounded } from '@material-ui/icons';
import MenuItem from 'Common/components/MoreActionsButton/MenuItem';
import useUploadQuestionFilesFunction from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/hooks/uploadQuestionFilesFunction/useUploadQuestionFilesFunction';

const UploadMenuItem = ({ question, onClick: closeKebabMenu }) => {
    const menuItemRef = useRef(null);

    const handleUpload = async () => {
        await closeKebabMenu({
            target: menuItemRef.current,
        });
    };

    const { isUploading, open, getInputProps } = useUploadQuestionFilesFunction(
        question.id,
        question.isCustom,
        handleUpload,
    );

    return (
        <>
            <input {...getInputProps()} />
            <MenuItem
                innerRef={menuItemRef}
                icon={<CloudUploadRounded />}
                isLoading={isUploading}
                disabled={isUploading}
                type="button"
                onClick={open}
            >
                Upload
            </MenuItem>
        </>
    );
};

UploadMenuItem.propTypes = {
    question: PropTypes.object.isRequired,
};

export default UploadMenuItem;
