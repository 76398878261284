import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';
import Checkbox from 'Common/components/Form/Fields/Checkbox';
import { CheckRounded, CloseRounded } from '@material-ui/icons';
import InteractableInnerBox from 'Common/components/Boxes/InteractableInnerBox';
import colors from 'Common/constants/colors';

const Wrapper = styled.div``;

const Answer = styled(InteractableInnerBox)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border: 2px solid ${colors.INNER_BOX_BACKGROUND};
  border-radius: 4px;

  ${ifProp(
      'isShowingMistakes',
      css`
          &:hover {
              background: ${colors.INNER_BOX_BACKGROUND};
          }
      `,
      css`
          &:hover {
              border-color: ${colors.DARK_DIVIDER};
          }
      `,
  )}

  ${ifProp(
      'isCorrect',
      css`
          border-color: ${colors.GREEN};

          &:hover {
              border-color: ${colors.GREEN};
          }
      `,
  )}

  ${ifProp(
      'isWrong',
      css`
          border-color: ${colors.ERROR};

          &:hover {
              border-color: ${colors.ERROR};
          }
      `,
  )}
`;

const AnswerCheckbox = styled(Checkbox)`
    width: 100%;

    label {
        padding: 20px;
    }

    .MuiCheckbox-root {
        &.Mui-disabled.Mui-checked {
            color: ${colors.DIRTY_WHITE};
        }
    }

    .MuiFormControlLabel-label {
        color: ${colors.LIGHT_DIVIDER};
    }

    .Mui-checked + .MuiFormControlLabel-label {
        color: ${colors.DIRTY_WHITE};
    }
`;

const TestResultIcon = styled.div`
    flex: 0 0 24px;
    margin-left: 10px;
    margin-right: 20px;
`;

const CorrectIcon = styled(TestResultIcon).attrs({
    as: CheckRounded,
})`
    color: ${colors.GREEN};
`;

const WrongIcon = styled(TestResultIcon).attrs({
    as: CloseRounded,
})`
    color: ${colors.ERROR};
`;

const AnswerChoices = ({
    answers,
    selectedIds,
    onAnswerCheckboxChange,
    isShowingMistakes,
    isQuestionCorrectlyAnswered,
}) => (
    <Wrapper>
        {answers.map(answer => {
            const isChecked = selectedIds.includes(answer.id);

            const isCorrect =
                isShowingMistakes && isChecked && isQuestionCorrectlyAnswered;

            const isWrong =
                isShowingMistakes && isChecked && !isQuestionCorrectlyAnswered;

            return (
                <Answer
                    key={answer.id}
                    isShowingMistakes={isShowingMistakes}
                    isCorrect={isCorrect}
                    isWrong={isWrong}
                >
                    <AnswerCheckbox
                        id={`answer_${answer.id}`}
                        name="answer[]"
                        label={answer.text}
                        checked={isChecked}
                        disabled={isShowingMistakes}
                        onChange={e =>
                            onAnswerCheckboxChange(answer.id, e.target.checked)
                        }
                    />
                    {isCorrect && <CorrectIcon />}
                    {isWrong && <WrongIcon />}
                </Answer>
            );
        })}
    </Wrapper>
);

AnswerChoices.propTypes = {
    answers: PropTypes.array.isRequired,
    selectedIds: PropTypes.array.isRequired,
    onAnswerCheckboxChange: PropTypes.func.isRequired,
    isShowingMistakes: PropTypes.bool.isRequired,
    isQuestionCorrectlyAnswered: PropTypes.bool.isRequired,
};

export default AnswerChoices;
