import api from 'Application/api/api';

const reCreateDriverLicense = (
    driverLicenseControlId,
    controlMethod,
    cancelToken = null,
) =>
    api.post(
        `/measures/vehicles/driver-licenses/controls/${driverLicenseControlId}/driver-license/re-create`,
        { controlMethod },
        {
            cancelToken,
        },
    );

export default reCreateDriverLicense;
