import api from 'Application/api/api';

const getPublicProjectQuestionFilesDownloadRequest = (
    projectId,
    questionId,
    isCustomQuestion,
    downloadRequestId,
    checksum,
    load,
    cancelToken = null,
) =>
    api.get(
        `public/project-manager/projects/${projectId}/questionnaire/questions/${questionId}/${
            isCustomQuestion ? 1 : 0
        }/files/download-requests/${downloadRequestId}`,
        {
            params: {
                load,
                checksum,
            },
            cancelToken,
        },
    );

export default getPublicProjectQuestionFilesDownloadRequest;
