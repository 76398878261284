import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import ContentSection from 'ProjectManager/DataManagement/Common/components/ContentSection';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import getTopics from 'ProjectManager/Common/api/getTopics';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';
import TopicTile from 'ProjectManager/DataManagement/TopicManagement/components/TopicSelection/components/TopicTile';
import useSelectedTopics from 'ProjectManager/DataManagement/TopicManagement/components/TopicSelection/hooks/selectedTopics/useSelectedTopics';

const List = styled.div`
    > div {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;

        > div {
            width: calc(50% - 10px);
            flex: 0 0 calc(50% - 10px);
            margin-right: 10px;
            margin-bottom: 0;

            &:nth-child(n + 3) {
                margin-top: 10px;
            }

            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }
`;

const TopicSelection = () => {
    const selectedTopics = useSelectedTopics();

    const topicsRequest = useCallback(
        cancelToken => getTopics([], cancelToken),
        [],
    );

    const {
        data: topics,
        loadData: loadTopics,
        isLoading,
        hasError,
    } = useAxiosRequest(topicsRequest, []);

    const selectedTopicIds = selectedTopics.map(topic => topic.id);
    const availableTopics = topics.filter(
        topic => topic.isEnabled || selectedTopicIds.includes(topic.id),
    );

    return (
        <ContentSection>
            <OuterBoxTitle>Auswahl relevanter Projektfelder</OuterBoxTitle>
            {isLoading ? (
                <Loader />
            ) : hasError ? (
                <RetryLoading onRetry={loadTopics} />
            ) : (
                <List>
                    {availableTopics.map(topic => (
                        <TopicTile
                            key={topic.id}
                            topic={topic}
                            defaultIsSelected={selectedTopicIds.includes(
                                topic.id,
                            )}
                        />
                    ))}
                </List>
            )}
        </ContentSection>
    );
};

export default TopicSelection;
