import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useProjectQuestionnaireSections from 'ProjectManager/DataManagement/Questionnaire/hooks/questionnaireSections/useProjectQuestionnaireSections';
import useTemplateQuestionnaireSections from 'ProjectManager/DataManagement/Questionnaire/hooks/questionnaireSections/useTemplateQuestionnaireSections';

const useQuestionnaireSections = () => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useProjectQuestionnaireSections();
    const dataFromTemplate = useTemplateQuestionnaireSections();

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return [];
};

export default useQuestionnaireSections;
