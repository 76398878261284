import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from 'Common/constants/colors';
import { prop } from 'styled-tools';
import { orderBy, take } from 'lodash';
import PersonAvatar from 'Common/components/GroupedPeopleAvatars/PersonAvatar';
import { Avatar, Tooltip } from '@material-ui/core';
import AllPeopleModal from 'Common/components/GroupedPeopleAvatars/AllPeopleModal';

const Wrapper = styled.div`
    display: flex;

    .MuiAvatar-root {
        border: 2px solid ${prop('backgroundColor')};
    }

    > div,
    > button {
        margin-left: -8px;

        &:first-child {
            margin-left: 0;
        }
    }
`;

const ShowMore = styled.button`
    background: none;
    margin: 0;
    padding: 0;
    border: 0 none;
    outline: 0 none;
    cursor: pointer;

    .MuiAvatar-root {
        background-color: ${colors.PRIMARY};
        color: ${colors.DIRTY_WHITE};
        width: 45px;
        height: 45px;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
    }

    &:hover {
        .MuiAvatar-root {
            background-color: ${colors.PRIMARY_LIGHT};
        }
    }
`;

const avatarColors = [colors.BLUE, colors.DARK_GREEN];

const GroupedPeopleAvatars = ({ people, max, modalTitle, backgroundColor }) => {
    const [isModalOpened, setIsModalOpened] = useState(false);

    const handleShowMore = () => {
        setIsModalOpened(true);
    };

    const handleCloseModal = () => {
        setIsModalOpened(false);
    };

    const sortedPeople = orderBy(
        people,
        ['lastName', 'firstName'],
        ['asc', 'asc'],
    );

    const visiblePeople =
        people.length <= max ? sortedPeople : take(sortedPeople, max - 1);

    const hiddenPeopleCount = people.length - visiblePeople.length;

    return (
        <>
            <Wrapper backgroundColor={backgroundColor}>
                {visiblePeople.map((person, index) => (
                    <PersonAvatar
                        key={index}
                        firstName={person.firstName}
                        lastName={person.lastName}
                        style={{
                            zIndex: visiblePeople.length - index,
                            backgroundColor:
                                avatarColors[index % avatarColors.length],
                        }}
                    />
                ))}
                {hiddenPeopleCount > 0 && (
                    <Tooltip title="Alle anzeigen">
                        <ShowMore type="button" onClick={handleShowMore}>
                            <Avatar>{`+${hiddenPeopleCount}`}</Avatar>
                        </ShowMore>
                    </Tooltip>
                )}
            </Wrapper>
            {isModalOpened && (
                <AllPeopleModal
                    people={sortedPeople}
                    title={modalTitle}
                    onClose={handleCloseModal}
                />
            )}
        </>
    );
};

GroupedPeopleAvatars.defaultProps = {
    max: 3,
    backgroundColor: colors.OUTER_BOX_BACKGROUND,
    modalTitle: 'Alle Personen',
};

GroupedPeopleAvatars.propTypes = {
    people: PropTypes.array.isRequired,
    max: PropTypes.number,
    backgroundColor: PropTypes.string,
    modalTitle: PropTypes.string,
};

export default GroupedPeopleAvatars;
