import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import EmployeeListTable from 'Employee/components/List/EmployeeListTable';
import employeeStatusFilters from 'Employee/constants/employeeStatusFilters';
import OuterBox from 'Common/components/Boxes/OuterBox';

const Content = styled(OuterBox)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    padding: 8px 0;
`;

const EmployeeList = ({
    selectedStatus,
    employees,
    onEmployeeActivate,
    onEmployeeDeactivate,
    onEmployeeArchive,
}) => {
    let filteredEmployees = [...employees];

    if (selectedStatus === employeeStatusFilters.ACTIVE) {
        filteredEmployees = filteredEmployees.filter(
            employee => employee.isEnabled,
        );
    } else {
        filteredEmployees = filteredEmployees.filter(
            employee => !employee.isEnabled,
        );
    }

    return (
        <Content>
            <EmployeeListTable
                employees={filteredEmployees}
                onActivate={onEmployeeActivate}
                onDeactivate={onEmployeeDeactivate}
                onArchive={onEmployeeArchive}
            />
        </Content>
    );
};

EmployeeList.propTypes = {
    selectedStatus: PropTypes.string.isRequired,
    employees: PropTypes.array.isRequired,
    onEmployeeActivate: PropTypes.func.isRequired,
    onEmployeeDeactivate: PropTypes.func.isRequired,
    onEmployeeArchive: PropTypes.func.isRequired,
};

export default EmployeeList;
