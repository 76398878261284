import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import axios from 'axios';
import giveProjectQuestionMultipleChoiceAnswers from 'ProjectManager/Project/Common/api/dataManagement/questionnaire/question/answer/giveProjectQuestionMultipleChoiceAnswers';
import withMultipleChoiceAnswersForQuestion from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/answer/withMultipleChoiceAnswersForQuestion';

const useProjectGiveMultipleChoiceAnswersFunction = (
    questionId,
    isCustomQuestion,
) => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const giveMultipleChoiceAnswers = useCallback(
        async selectedAnswerIds => {
            await giveProjectQuestionMultipleChoiceAnswers(
                projectId,
                questionId,
                isCustomQuestion,
                selectedAnswerIds,
                source.token,
            );

            setProject(
                withMultipleChoiceAnswersForQuestion(
                    questionId,
                    isCustomQuestion,
                    selectedAnswerIds,
                ),
            );
        },
        [projectId, questionId, isCustomQuestion, setProject, source.token],
    );

    return giveMultipleChoiceAnswers;
};

export default useProjectGiveMultipleChoiceAnswersFunction;
