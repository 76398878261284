import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import InnerBox from 'Common/components/Boxes/InnerBox';
import InnerBoxButtons from 'Common/components/Boxes/InnerBoxButtons';

const StyledInnerBox = styled(InnerBox)`
    overflow: hidden;
`;

const ContentWrapper = styled.div`
    padding: 20px;
`;

const InnerBoxWithButtons = ({ buttons, children, ...props }) => (
    <StyledInnerBox {...props}>
        <ContentWrapper>{children}</ContentWrapper>
        <InnerBoxButtons light={props.light}>{buttons}</InnerBoxButtons>
    </StyledInnerBox>
);

InnerBoxWithButtons.propTypes = {
    buttons: PropTypes.any.isRequired,
};

export default InnerBoxWithButtons;
