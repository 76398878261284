import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import ModalOption from 'Common/components/Modals/ModalOption';
import { orderBy, pick } from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import introducedProjectParticipantIdsSelector from 'ProjectManager/Project/Common/recoil/project/selectors/introducedProjectParticipantIdsSelector';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import changeIntroductionParticipants from 'ProjectManager/Project/Introductions/Common/api/changeIntroductionParticipants';
import getProject from 'ProjectManager/Project/Common/api/getProject';

const Options = styled.div`
    > * {
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const ManageParticipantsModal = ({ introduction, onClose }) => {
    const [isSaving, setIsSaving] = useState(false);

    const [
        { id: projectId, participants: projectParticipants },
        setProject,
    ] = useRecoilState(projectAtom);

    const assignedProjectParticipants = useMemo(
        () => projectParticipants.filter(participant => participant.isAssigned),
        [projectParticipants],
    );

    const introducedProjectParticipantIds = useRecoilValue(
        introducedProjectParticipantIdsSelector,
    );

    const [
        selectedProjectParticipantIds,
        setSelectedProjectParticipantIds,
    ] = useState(() =>
        introduction.participants
            .filter(participant => participant.isRelevant)
            .map(participant => participant.projectParticipant.id),
    );

    const handleToggle = (participantId, isChecked) => {
        if (isChecked) {
            setSelectedProjectParticipantIds(prevParticipantIds => [
                ...prevParticipantIds,
                participantId,
            ]);
        } else {
            setSelectedProjectParticipantIds(prevParticipantIds => {
                const index = prevParticipantIds.indexOf(participantId);

                if (index === -1) {
                    return prevParticipantIds;
                }

                const newParticipantIds = [...prevParticipantIds];

                newParticipantIds.splice(index, 1);

                return newParticipantIds;
            });
        }
    };

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleSaveButtonClick = async () => {
        setIsSaving(true);

        try {
            await changeIntroductionParticipants(
                projectId,
                introduction.id,
                selectedProjectParticipantIds,
                source.token,
            );

            const projectResponse = await getProject(
                projectId,
                [
                    'statistics',
                    'introductions',
                    'introductions.supervisor',
                    'introductions.participants',
                    'introductions.participants.projectParticipant',
                    'introductions.participants.projectParticipant.employee',
                    'introductions.participants.projectParticipant.employee.user',
                ],
                source.token,
            );

            setProject(prevProject => ({
                ...prevProject,
                ...pick(projectResponse.data, ['statistics', 'introductions']),
            }));

            setIsSaving(false);

            onClose();

            notify('Anpassung war erfolgreich', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsSaving(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const initiallySelectedProjectParticipantIds = introduction.participants
        .filter(participant => participant.isRelevant)
        .map(participant => participant.projectParticipant.id);

    const isSaveButtonDisabled =
        initiallySelectedProjectParticipantIds.every(projectParticipantId =>
            selectedProjectParticipantIds.includes(projectParticipantId),
        ) &&
        selectedProjectParticipantIds.length ===
            initiallySelectedProjectParticipantIds.length;

    return (
        <Modal
            isOpen
            onRequestClose={isSaving ? undefined : onClose}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onClose}
                        disabled={isSaving}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        onClick={handleSaveButtonClick}
                        isLoading={isSaving}
                        disabled={
                            isSaveButtonDisabled ||
                            isSaving ||
                            selectedProjectParticipantIds.length === 0
                        }
                        tooltip={
                            selectedProjectParticipantIds.length === 0
                                ? 'Bitte wählen Sie mindestens einen Mitarbeiter aus'
                                : ''
                        }
                    >
                        Speichern
                    </Button>
                </>
            }
        >
            <ModalTitle>Auswahl Mitarbeiter</ModalTitle>
            <Options>
                {orderBy(
                    assignedProjectParticipants,
                    ['employee.lastName', 'employee.firstName'],
                    ['asc', 'asc'],
                ).map(participant => {
                    const employee = participant.employee;
                    const isIntroduced = introducedProjectParticipantIds.includes(
                        participant.id,
                    );

                    return (
                        <ModalOption
                            key={participant.id}
                            title={`${employee.lastName}, ${employee.firstName}`}
                            description={
                                isIntroduced
                                    ? 'Bereits mind. 1 x eingewiesen'
                                    : 'Einweisung offen'
                            }
                            isChecked={selectedProjectParticipantIds.includes(
                                participant.id,
                            )}
                            onToggle={isChecked =>
                                handleToggle(participant.id, isChecked)
                            }
                            isMulti
                        />
                    );
                })}
            </Options>
        </Modal>
    );
};

ManageParticipantsModal.propTypes = {
    introduction: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ManageParticipantsModal;
