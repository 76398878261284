import React, { useCallback, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';
import { useSetRecoilState } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import getTemplate from 'ProjectManager/Template/Common/api/getTemplate';
import Template from 'ProjectManager/Template/Edit/Template';

const EditTemplate = () => {
    const { id } = useParams();

    const setDataManagementEntity = useSetRecoilState(dataManagementEntityAtom);

    useEffect(() => {
        setDataManagementEntity(dataManagementEntities.TEMPLATE);
    }, [setDataManagementEntity]);

    const setTemplate = useSetRecoilState(templateAtom);

    const templateRequest = useCallback(
        cancelToken =>
            getTemplate(
                id,
                [
                    'supervisorUsers',
                    'areas',
                    'files',
                    'participatingEmployees',
                    'participatingEmployees.user',
                    'participatingEmployees.areas',
                    'topics',
                    'customTopics',
                    'questionnaireSections',
                    'questionnaireSections.subsections',
                    'questionnaireSections.subsections.questions',
                    'questionnaireSections.subsections.questions.files',
                ],
                cancelToken,
            ),
        [id],
    );

    const {
        loadData: loadTemplate,
        isLoading,
        hasError,
        error,
    } = useAxiosRequest(templateRequest, null, {
        customDataSetterFunction: setTemplate,
    });

    if (
        hasError &&
        (error?.response?.status === 401 || error?.response?.status === 404)
    ) {
        return <Redirect to="/project-manager" />;
    }

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : hasError ? (
                <RetryLoading onRetry={loadTemplate} />
            ) : (
                <Template />
            )}
        </>
    );
};

export default EditTemplate;
