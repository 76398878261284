const withTemplateMovedDown = templateId => prevTemplates => {
    const template = prevTemplates.find(template => template.id === templateId);

    return prevTemplates.map(iteratedTemplate => {
        // The changed template - it has moved down, so let's increase the position
        const isMovedUp = iteratedTemplate.id === template.id;

        if (isMovedUp) {
            return {
                ...iteratedTemplate,
                position: iteratedTemplate.position + 1,
            };
        }

        // The previous template - it will be pushed up, so let's decrease the position
        const isNextTemplate =
            iteratedTemplate.position === template.position + 1;

        if (isNextTemplate) {
            return {
                ...iteratedTemplate,
                position: iteratedTemplate.position - 1,
            };
        }

        // All other templates are left unchanged
        return iteratedTemplate;
    });
};

export default withTemplateMovedDown;
