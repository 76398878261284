import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import goToNextExamQuestion from 'Education/api/instruction-assignment/exam/goToNextExamQuestion';
import completeSingleInstructionAssignmentExam from 'Education/api/instruction-assignment/exam/completeSingleInstructionAssignmentExam';
import QuestionTitle from 'Education/components/instruction-assignment/exam/QuestionTitle';
import AnswerChoices from 'Education/components/instruction-assignment/exam/AnswerChoices';
import goToPreviousExamQuestion from 'Education/api/instruction-assignment/exam/goToPreviousExamQuestion';
import updateInstructionAssignmentExamGivenAnswers from 'Education/api/instruction-assignment/exam/updateInstructionAssignmentExamGivenAnswers';
import QuestionsSidebarMenu from 'Education/components/instruction-assignment/exam/QuestionsSidebarMenu';
import goToExamQuestionNumber from 'Education/api/instruction-assignment/exam/goToExamQuestionNumber';
import { useOnClickOutside } from 'crooks';
import markInstructionAssignmentExamForApproval from 'Education/api/instruction-assignment/exam/markInstructionAssignmentExamForApproval';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import OuterBox from 'Common/components/Boxes/OuterBox';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';
import {
    ArrowBackRounded,
    ArrowForwardRounded,
    MenuOpenRounded,
    MenuRounded,
} from '@material-ui/icons';
import OuterBoxButtons from 'Common/components/Boxes/OuterBoxButtons';
import ActionBar from 'Common/components/ActionBar/ActionBar';

const Title = styled.div`
    display: flex;
    align-items: center;

    button {
        margin-right: 10px;
    }
`;

const QuestionsCounter = styled.div`
    flex: 1 0 auto;
    margin-bottom: 10px;
    color: ${colors.GRAY};
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;

    @media screen and (min-width: 420px) {
        margin-right: 20px;
        margin-bottom: 0;
    }
`;

const QuestionContent = styled(OuterBox)`
    position: relative;
    padding: 20px;
    overflow: hidden;
`;

const AssignmentExamInProgress = ({
    assignment,
    isShowingMistakes,
    isInPreviewMode,
    initialQuestionNumber,
    initialSelectedAnswerIds,
    onProgress,
    onComplete,
    additionalButtons,
}) => {
    const [isLoadingCustomQuestion, setIsLoadingCustomQuestion] = useState(
        false,
    );
    const [isLoadingPreviousQuestion, setIsLoadingPreviousQuestion] = useState(
        false,
    );
    const [isLoadingNextQuestion, setIsLoadingNextQuestion] = useState(false);

    const [questionNumber, setQuestionNumber] = useState(initialQuestionNumber);
    const [selectedAnswerIds, setSelectedAnswerIds] = useState(
        initialSelectedAnswerIds,
    );

    const [isSidebarMenuOpened, setIsSidebarMenuOpened] = useState(false);

    const questions = assignment.examQuestions;
    const questionsCount = questions.length;
    const question = questions.find(
        (question, index) => index + 1 === questionNumber,
    );

    const answeredQuestions = questions.filter(question => {
        const answerIds = question.answers.map(answer => answer.id);

        const intersectingAnswerIds = answerIds.filter(answerId =>
            selectedAnswerIds.includes(answerId),
        );

        return intersectingAnswerIds.length > 0;
    });

    const answeredQuestionNumbers = answeredQuestions.map(
        answeredQuestion =>
            questions.findIndex(
                question => question.id === answeredQuestion.id,
            ) + 1,
    );

    const areAllQuestionsAnswered =
        questions.length === answeredQuestions.length;

    const handleAnswerCheckboxChange = (answerId, isChecked) => {
        setSelectedAnswerIds(prevSelectedAnswerIds => {
            const newSelectedAnswerIds = [...prevSelectedAnswerIds];

            if (isChecked && !newSelectedAnswerIds.includes(answerId)) {
                newSelectedAnswerIds.push(answerId);
            } else if (!isChecked && prevSelectedAnswerIds.includes(answerId)) {
                const index = newSelectedAnswerIds.indexOf(answerId);

                newSelectedAnswerIds.splice(index, 1);
            }

            return newSelectedAnswerIds;
        });
    };

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handlePreviousButtonClick = async () => {
        if (
            !isLoadingPreviousQuestion &&
            !isLoadingNextQuestion &&
            questionNumber > 1
        ) {
            if (!isShowingMistakes) {
                setIsLoadingPreviousQuestion(true);

                try {
                    await updateInstructionAssignmentExamGivenAnswers(
                        assignment.id,
                        selectedAnswerIds,
                        source.token,
                    );
                    await goToPreviousExamQuestion(assignment.id, source.token);
                    await onProgress();

                    setIsLoadingPreviousQuestion(false);
                    setQuestionNumber(
                        prevQuestionNumber => prevQuestionNumber - 1,
                    );
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        setIsLoadingPreviousQuestion(false);

                        notify(
                            'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                            {
                                type: toast.TYPE.ERROR,
                            },
                        );
                    }
                }
            } else {
                setQuestionNumber(prevQuestionNumber => prevQuestionNumber - 1);
            }
        }
    };

    const handleNextButtonClick = async () => {
        if (!isLoadingPreviousQuestion && !isLoadingNextQuestion) {
            if (questionNumber < questionsCount) {
                if (!isShowingMistakes) {
                    setIsLoadingNextQuestion(true);

                    try {
                        await updateInstructionAssignmentExamGivenAnswers(
                            assignment.id,
                            selectedAnswerIds,
                            source.token,
                        );
                        await goToNextExamQuestion(assignment.id, source.token);
                        await onProgress();

                        setIsLoadingNextQuestion(false);
                        setQuestionNumber(
                            prevQuestionNumber => prevQuestionNumber + 1,
                        );
                    } catch (error) {
                        if (!axios.isCancel(error)) {
                            setIsLoadingNextQuestion(false);

                            notify(
                                'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                                {
                                    type: toast.TYPE.ERROR,
                                },
                            );
                        }
                    }
                } else {
                    setQuestionNumber(
                        prevQuestionNumber => prevQuestionNumber + 1,
                    );
                }
            } else if (
                questionNumber === questionsCount &&
                questionNumber === assignment.currentExamQuestion &&
                (areAllQuestionsAnswered || isShowingMistakes)
            ) {
                // The exam is over
                setIsLoadingNextQuestion(true);

                if (!isShowingMistakes) {
                    try {
                        await updateInstructionAssignmentExamGivenAnswers(
                            assignment.id,
                            selectedAnswerIds,
                            source.token,
                        );

                        if (assignment.isGroupAssignment) {
                            await markInstructionAssignmentExamForApproval(
                                assignment.id,
                                source.token,
                            );
                        } else {
                            await completeSingleInstructionAssignmentExam(
                                assignment.id,
                                source.token,
                            );
                        }

                        await onComplete();
                        setIsLoadingNextQuestion(false);
                    } catch (error) {
                        if (!axios.isCancel(error)) {
                            setIsLoadingNextQuestion(false);

                            notify(
                                'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                                {
                                    type: toast.TYPE.ERROR,
                                },
                            );
                        }
                    }
                } else {
                    await onComplete();
                    setIsLoadingNextQuestion(false);
                }
            }
        }
    };

    const handleClickOutsideSidebar = () => {
        setIsSidebarMenuOpened(false);
    };

    const sidebarRef = useOnClickOutside(
        handleClickOutsideSidebar,
        !isSidebarMenuOpened,
    );

    const handleSidebarMenuButtonClick = () => {
        setIsSidebarMenuOpened(
            prevIsSidebarMenuOpened => !prevIsSidebarMenuOpened,
        );
    };

    const handleQuestionNumberChange = async number => {
        if (!isShowingMistakes) {
            setIsLoadingCustomQuestion(true);

            try {
                await updateInstructionAssignmentExamGivenAnswers(
                    assignment.id,
                    selectedAnswerIds,
                    source.token,
                );
                await goToExamQuestionNumber(
                    assignment.id,
                    number,
                    source.token,
                );
                await onProgress();

                setIsLoadingCustomQuestion(false);
                setQuestionNumber(number);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setIsLoadingCustomQuestion(false);

                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }
        } else {
            setIsLoadingCustomQuestion(false);
            setQuestionNumber(number);
        }
    };

    const isFirstQuestion = questionNumber === 1;
    const isLastQuestion = questionNumber === questionsCount;

    const correctlyAnsweredQuestionNumbers = [];

    questions.forEach((question, index) => {
        const questionAnswerIds = question.answers.map(answer => answer.id);

        const correctAnswerIds = question.answers
            .filter(answer => answer.isCorrect)
            .map(answer => answer.id);

        const givenAnswerIds = selectedAnswerIds.filter(answerId =>
            questionAnswerIds.includes(answerId),
        );

        const intersectingAnswerIds = correctAnswerIds.filter(answerId =>
            givenAnswerIds.includes(answerId),
        );

        if (
            intersectingAnswerIds.length === correctAnswerIds.length &&
            intersectingAnswerIds.length === givenAnswerIds.length
        ) {
            const number = index + 1;

            correctlyAnsweredQuestionNumbers.push(number);
        }
    });

    return (
        <>
            <ActionBar
                withButton
                title={
                    <Title>
                        <Button
                            type="button"
                            iconOnly
                            icon={
                                isSidebarMenuOpened ? (
                                    <MenuOpenRounded />
                                ) : (
                                    <MenuRounded />
                                )
                            }
                            onClick={handleSidebarMenuButtonClick}
                        />
                        {`Test: ${assignment.name}`}
                    </Title>
                }
                rightElement={
                    <QuestionsCounter>
                        {`Frage: ${questionNumber} / ${questionsCount}`}
                    </QuestionsCounter>
                }
            />
            <QuestionContent>
                <QuestionTitle question={question} />
                <AnswerChoices
                    answers={question.answers}
                    selectedIds={selectedAnswerIds}
                    onAnswerCheckboxChange={handleAnswerCheckboxChange}
                    isShowingMistakes={isShowingMistakes}
                    isQuestionCorrectlyAnswered={correctlyAnsweredQuestionNumbers.includes(
                        questionNumber,
                    )}
                />
                {isSidebarMenuOpened && (
                    <QuestionsSidebarMenu
                        elementRef={sidebarRef}
                        questions={questions}
                        questionNumber={questionNumber}
                        isShowingMistakes={isShowingMistakes}
                        correctlyAnsweredQuestionNumbers={
                            correctlyAnsweredQuestionNumbers
                        }
                        isLoading={
                            isLoadingCustomQuestion ||
                            isLoadingPreviousQuestion ||
                            isLoadingNextQuestion
                        }
                        answeredQuestionNumbers={answeredQuestionNumbers}
                        onQuestionNumberChange={handleQuestionNumberChange}
                    />
                )}
            </QuestionContent>
            <OuterBoxButtons>
                <Button
                    text
                    icon={<ArrowBackRounded />}
                    isLoading={isLoadingPreviousQuestion}
                    type="button"
                    disabled={
                        isLoadingCustomQuestion ||
                        isLoadingPreviousQuestion ||
                        isLoadingNextQuestion ||
                        isFirstQuestion
                    }
                    onClick={handlePreviousButtonClick}
                >
                    Zurück
                </Button>
                <Button
                    text
                    icon={<ArrowForwardRounded />}
                    iconPosition="right"
                    isLoading={isLoadingNextQuestion}
                    type="button"
                    disabled={
                        isLoadingCustomQuestion ||
                        isLoadingPreviousQuestion ||
                        isLoadingNextQuestion ||
                        (isLastQuestion && isInPreviewMode) ||
                        (isLastQuestion &&
                            !areAllQuestionsAnswered &&
                            !isShowingMistakes)
                    }
                    tooltip={
                        isLastQuestion &&
                        !areAllQuestionsAnswered &&
                        !isShowingMistakes
                            ? 'Es gibt unbeantwortete Fragen'
                            : ''
                    }
                    onClick={handleNextButtonClick}
                >
                    Weiter
                </Button>
                {additionalButtons}
            </OuterBoxButtons>
        </>
    );
};

AssignmentExamInProgress.defaultProps = {
    isInPreviewMode: false,
    closeButtonLink: '/dashboard',
    additionalButtons: null,
};

AssignmentExamInProgress.propTypes = {
    assignment: PropTypes.object.isRequired,
    isShowingMistakes: PropTypes.bool.isRequired,
    isInPreviewMode: PropTypes.bool,
    initialQuestionNumber: PropTypes.number.isRequired,
    initialSelectedAnswerIds: PropTypes.array.isRequired,
    closeButtonLink: PropTypes.string,
    onProgress: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    additionalButtons: PropTypes.any,
};

export default AssignmentExamInProgress;
