import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import withWorkingHours from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/withWorkingHours';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import updateTemplateWorkingHours from 'ProjectManager/Template/Common/api/dataManagement/updateTemplateWorkingHours';

const useTemplateWorkingHoursData = () => {
    const [{ id: templateId, workingHours }, setTemplate] = useRecoilState(
        templateAtom,
    );

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const update = useCallback(
        async newWorkingHours => {
            await updateTemplateWorkingHours(
                templateId,
                {
                    from: newWorkingHours.from.formatted,
                    to: newWorkingHours.to.formatted,
                },
                source.token,
            );

            setTemplate(withWorkingHours(newWorkingHours));
        },
        [templateId, setTemplate, source.token],
    );

    return templateId
        ? {
              from: workingHours.from,
              to: workingHours.to,
              updateWorkingHours: update,
          }
        : {};
};

export default useTemplateWorkingHoursData;
