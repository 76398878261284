import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useDropzone } from 'react-dropzone';
import {
    AddPhotoAlternateRounded,
    PhotoCameraRounded,
} from '@material-ui/icons';
import InnerBox from 'Common/components/Boxes/InnerBox';
import colors from 'Common/constants/colors';
import { Tooltip } from '@material-ui/core';

const SimpleWrapper = styled(InnerBox)`
    width: 100%;
    padding: 20px;
    text-align: center;

    @media screen and (min-width: 620px) {
        max-width: 400px;
    }

    @media screen and (min-width: 940px) {
        padding: 40px;
    }
`;

const ClickableWrapper = styled(SimpleWrapper).attrs({
    as: 'button',
})`
    border: 0 none;
    outline: 0 none;
    cursor: pointer;

    &:hover {
        background: ${colors.DARK_DIVIDER};
    }
`;

const Title = styled.div`
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 18px;
    color: ${colors.DIRTY_WHITE};

    @media screen and (min-width: 375px) {
        font-size: 21px;
    }
`;

const SubTitle = styled.div`
    margin-bottom: 5px;
    font-weight: 300;
    font-size: 16px;
    color: ${colors.LIGHTER_GRAY};
`;

const Hint = styled.div`
    color: ${colors.LIGHT_GRAY};
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 20px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
`;

const SimpleIcon = styled.div`
    svg {
        color: ${colors.DIRTY_WHITE};
        font-size: 50px !important;
    }
`;

const IconButton = styled(SimpleIcon).attrs({
    as: 'button',
})`
    background: none;
    border: 0 none;
    outline: 0 none;
    cursor: pointer;

    &:hover {
        svg {
            color: ${colors.PRIMARY};
        }
    }
`;

const UploadImageIcon = styled(AddPhotoAlternateRounded)`
    position: relative;
    top: -2px;
`;

const ImageUpload = ({
    title,
    subTitle,
    hint,
    onUpload,
    onImageCaptureButtonClick,
    allowFileUpload,
    allowCameraUpload,
}) => {
    const { open, getInputProps } = useDropzone({
        multiple: false,
        accept: 'image/jpg, image/jpeg, image/png',
        onDrop: acceptedFiles => {
            if (acceptedFiles && acceptedFiles[0]) {
                const file = acceptedFiles[0];

                onUpload(file);
            }
        },
    });

    const isSingleButtonUpload = !allowFileUpload || !allowCameraUpload;

    const handleWrapperClick = () => {
        if (allowFileUpload && !allowCameraUpload) {
            open();
        } else if (allowCameraUpload && !allowFileUpload) {
            onImageCaptureButtonClick();
        }
    };

    const Wrapper = isSingleButtonUpload ? ClickableWrapper : SimpleWrapper;
    const Icon = isSingleButtonUpload ? SimpleIcon : IconButton;

    return (
        <Wrapper
            onClick={isSingleButtonUpload ? handleWrapperClick : undefined}
        >
            {allowFileUpload && <input {...getInputProps()} />}
            <Title>{title}</Title>
            {!!subTitle && <SubTitle>{subTitle}</SubTitle>}
            {!!hint && <Hint>{hint}</Hint>}
            <ButtonsWrapper>
                {allowFileUpload && (
                    <Tooltip title="Foto als Datei hochladen">
                        <Icon onClick={isSingleButtonUpload ? undefined : open}>
                            <UploadImageIcon />
                        </Icon>
                    </Tooltip>
                )}
                {allowCameraUpload && (
                    <Tooltip title="Foto mit Kamera aufnehmen">
                        <Icon
                            onClick={
                                isSingleButtonUpload
                                    ? undefined
                                    : onImageCaptureButtonClick
                            }
                        >
                            <PhotoCameraRounded />
                        </Icon>
                    </Tooltip>
                )}
            </ButtonsWrapper>
        </Wrapper>
    );
};

ImageUpload.defaultProps = {
    hint: null,
    onUpload: null,
    allowFileUpload: true,
    allowCameraUpload: true,
};

ImageUpload.propTypes = {
    title: PropTypes.any.isRequired,
    hint: PropTypes.any,
    onUpload: PropTypes.func,
    onImageCaptureButtonClick: PropTypes.func.isRequired,
    allowFileUpload: PropTypes.bool,
    allowCameraUpload: PropTypes.bool,
};

export default ImageUpload;
