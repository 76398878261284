import api from 'Application/api/api';

const completeGroupInstructionAssignmentExam = (
    id,
    employeeIds,
    cancelToken = null,
) =>
    api.post(
        `/education/instruction-assignments/${id}/exam/complete/group`,
        {
            employeeIds,
        },
        {
            cancelToken,
        },
    );

export default completeGroupInstructionAssignmentExam;
