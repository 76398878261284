import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import colors from 'Common/constants/colors';
import ProgressBar from 'Common/components/ProgressBar';
import {
    averagePercentage,
    percentages,
} from 'Dashboard/constants/newRiskAssessment';
import { useWindowWidth } from '@react-hook/window-size';
import CircleProgress from 'Common/components/CircleProgress';
import Flickity from 'react-flickity-component';
import 'flickity/dist/flickity.min.css';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { CloseRounded } from '@material-ui/icons';
import { Helmet } from 'react-helmet-async';
import BackButton from 'Application/components/Header/BackButton/BackButton';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import InteractableOuterBox from 'Common/components/Boxes/InteractableOuterBox';

const Wrapper = styled.div`
    padding-top: 20px;
`;

const HeadlineWrapper = styled.div`
    margin-bottom: 65px;
    padding: 0 10px;
    text-align: center;
`;

const Title = styled.div`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;

    @media screen and (min-width: 480px) {
        font-size: 32px;
        line-height: 42px;
    }
`;

const SubTitle = styled.div`
    font-size: 18px;
    line-height: 24px;
    color: ${colors.LIGHT_GRAY};
    margin-bottom: 15px;

    @media screen and (min-width: 480px) {
        font-size: 24px;
        line-height: 32px;
    }
`;

const StyledProgressBar = styled(ProgressBar)`
    max-width: 315px;
    margin: 0 auto;
`;

const ContentWrapper = styled.div`
    @media screen and (min-width: 1280px) {
        display: flex;
    }
`;

const ModulePercentages = styled.div`
    max-width: 450px;
    margin: 0 auto;

    @media screen and (min-width: 880px) {
        display: flex;
        flex-wrap: wrap;
        max-width: initial;
        margin: 0;
    }

    @media screen and (min-width: 1280px) {
        display: block;
        width: 470px;
        flex: 0 0 470px;
        align-self: flex-start;
        flex-direction: column;
        margin-right: 70px;
    }
`;

const StyledCircleProgress = styled(CircleProgress)`
    margin-bottom: 60px;

    @media screen and (min-width: 375px) {
        margin-bottom: 45px;
    }

    @media screen and (min-width: 880px) {
        width: 50%;
        flex: 0 0 50%;
        padding: 0 20px;
    }

    @media screen and (min-width: 1280px) {
        width: 100%;
        padding: 0;
    }
`;

const ModuleScreenshots = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background: rgba(61, 61, 61, 0.81);
    border-radius: 10px;
    box-shadow: 0 7px 10px rgba(0, 0, 0, 0.16);

    .flickity-viewport {
        border-radius: 10px 10px 0 0;
    }

    @media screen and (min-width: 1280px) {
        .flickity-enabled {
            height: 100%;
        }

        .flickity-viewport {
            height: 100% !important;
        }
    }

    .flickity-button {
        background: ${colors.PRIMARY};
        color: ${colors.DIRTY_WHITE};
        z-index: 2;
    }

    .flickity-prev-next-button {
        width: 40px;
        height: 40px;
        border-radius: 6px;

        &.previous {
            left: 20px;
        }

        &.next {
            right: 20px;
        }

        .flickity-button-icon {
            left: 25%;
            top: 25%;
            width: 50%;
            height: 50%;
        }
    }
`;

const ScreenshotsTitle = styled.div`
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    margin: 30px 0;

    @media screen and (min-width: 1280px) {
        margin-bottom: 0;
    }
`;

const Slide = styled.div`
    position: relative;
    width: calc(100% - 80px);
    margin: 0 30px;
    border-radius: 10px;
    overflow: hidden;

    @media screen and (min-width: 1280px) {
        height: 100%;
        max-width: initial;
        background: none;
        margin: 0;
    }
`;

const SlideContent = styled.div`
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
    padding: 0 0 15px;
    text-align: center;

    ${ifProp(
        'isVideo',
        css`
            display: flex;
            align-items: center;
            justify-content: center;
        `,
    )};

    img,
    video {
        display: block;
        box-shadow: 0 10px 8px rgba(0, 0, 0, 0.07),
            0 4.17776px 3.34221px rgba(0, 0, 0, 0.0596643),
            0 2.23363px 1.7869px rgba(0, 0, 0, 0.0612968),
            0 1.25216px 1.00172px rgba(0, 0, 0, 0.0629457),
            0 0.66501px 0.532008px rgba(0, 0, 0, 0.0612426),
            0 0.276726px 0.221381px rgba(0, 0, 0, 0.0510612);
    }

    img {
        width: 100%;
    }

    video {
        max-width: 100%;
    }

    @media screen and (min-width: 1280px) {
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(100% - 160px);
        height: calc(100% - 100px);
        padding: 0;
        transform: translate(-50%, -50%);
        text-align: left;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: auto;
            max-width: 10%;
            max-height: 10%;
            transform: translate(-50%, -50%) scale(10);
            overflow: hidden;
            border-radius: 0.5px;
        }
    }
`;

const ClickableImage = styled.button`
    display: block;
    border: 0 none;
    outline: 0 none;
    background: none;
    cursor: pointer;
`;

const ZoomPopup = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.PAGE_BACKGROUND};
    z-index: 999999999;
`;

const CloseButton = styled(InteractableOuterBox).attrs({
    as: 'button',
})`
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 0 none;
    outline: 0 none;
    cursor: pointer;
    z-index: 1;

    svg {
        margin-right: 0;
    }
`;

const sliderMediaFiles = [
    '/images/risk-assessment/reporting-page.png',
    '/images/risk-assessment/employee-selection.mp4',
    '/images/risk-assessment/project-create.mp4',
    '/images/risk-assessment/project-list-new.png',
];

const RiskAssessmentPreview = () => {
    const flickityRef = useRef();

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 1280;

    const [adaptiveHeight, setAdaptiveHeight] = useState(false);

    useEffect(() => {
        setAdaptiveHeight(isMobile);
    }, [isMobile]);

    useEffect(() => {
        if (flickityRef && flickityRef.current) {
            flickityRef.current.options.adaptiveHeight = adaptiveHeight;
            flickityRef.current.resize();
        }
    }, [adaptiveHeight]);

    const [zoomedImage, setZoomedImage] = useState(null);

    useEffect(() => {
        const html = document.getElementsByTagName('html')[0];

        html.style.overflow = zoomedImage ? 'hidden' : null;
    }, [zoomedImage]);

    const zoomImage = image => {
        if (flickityRef.current && !flickityRef.current.isAnimating) {
            setZoomedImage(image);
        }
    };

    const handleCloseButtonClick = () => {
        setZoomedImage(null);
    };

    return (
        <Wrapper>
            <Helmet>
                <title>Neue Ergänzende Gefährdungsbeurteilung</title>
            </Helmet>
            <BackButton to="/dashboard" />
            <Breadcrumb isActive>
                Neue Ergänzende Gefährdungsbeurteilung
            </Breadcrumb>
            <HeadlineWrapper>
                <Title>Neue Ergänzende Gefährdungsbeurteilung</Title>
                <SubTitle>Bauarbeiten im Gange</SubTitle>
                <StyledProgressBar percentage={averagePercentage} />
            </HeadlineWrapper>
            <ContentWrapper>
                <ModulePercentages>
                    {Object.keys(percentages).map(key => (
                        <StyledCircleProgress
                            key={key}
                            percentage={percentages[key].percentage}
                            text={percentages[key].label}
                            size={95}
                            strokeWidth={5}
                        />
                    ))}
                </ModulePercentages>
                <ModuleScreenshots>
                    <ScreenshotsTitle>
                        Visualisierung des neuen Moduls
                    </ScreenshotsTitle>
                    <Flickity
                        flickityRef={node => {
                            flickityRef.current = node;
                        }}
                        options={{
                            adaptiveHeight,
                            wrapAround: true,
                            pageDots: false,
                            arrowShape:
                                'M46.39,3.77l-46,46L46.39,95.86,53,89.28,18.14,54.46l80.87,0V45.14l-80.87,0L53,10.34Z',
                        }}
                    >
                        {sliderMediaFiles.map(sliderMediaFile => (
                            <Slide key={sliderMediaFile}>
                                <SlideContent
                                    isVideo={sliderMediaFile.endsWith('.mp4')}
                                >
                                    {sliderMediaFile.endsWith('.mp4') ? (
                                        <video controls autoPlay loop muted playsInline width="320">
                                            <source
                                                src={sliderMediaFile}
                                                type="video/mp4"
                                            />
                                        </video>
                                    ) : (
                                        <ClickableImage
                                            onClick={() =>
                                                zoomImage(sliderMediaFile)
                                            }
                                        >
                                            <img src={sliderMediaFile} alt="" />
                                        </ClickableImage>
                                    )}
                                </SlideContent>
                            </Slide>
                        ))}
                    </Flickity>
                </ModuleScreenshots>
            </ContentWrapper>
            {!!zoomedImage && (
                <ZoomPopup>
                    <CloseButton onClick={handleCloseButtonClick}>
                        <CloseRounded />
                    </CloseButton>
                    <TransformWrapper
                        defaultScale={0.5}
                        options={{ minScale: 0.5 }}
                    >
                        <TransformComponent>
                            <img src={zoomedImage} alt="" />
                        </TransformComponent>
                    </TransformWrapper>
                </ZoomPopup>
            )}
        </Wrapper>
    );
};
export default RiskAssessmentPreview;
