import React from 'react';
import styled from 'styled-components/macro';
import Tile from 'ProjectManager/Project/Preview/Tile';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { AssignmentRounded, QuestionAnswerRounded } from '@material-ui/icons';
import Task from 'ProjectManager/Project/Preview/Tasks/Task';
import Instructions from 'Common/components/Icons/Instructions';

const List = styled.div`
    > div {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const Tasks = () => {
    const {
        id,
        statistics,
        participants,
        introductions,
        topics,
        customTopics,
    } = useRecoilValue(projectAtom);

    const isDisabled =
        (participants.filter(participant => participant.isAssigned).length ===
            0 &&
            introductions.length === 0) ||
        (topics.length + customTopics.length === 0 && introductions.length === 0);

    return (
        <Tile>
            <OuterBoxTitle>Projektaufgaben</OuterBoxTitle>
            <List>
                <Task
                    title="Projektfragen"
                    icon={<QuestionAnswerRounded />}
                    to={`/project-manager/project/${id}/edit`}
                    pending={statistics.incompleteSubsections}
                    completed={statistics.completedSubsections}
                />
                <Task
                    title="Einweisung"
                    icon={<AssignmentRounded />}
                    to={`/project-manager/project/${id}/introductions`}
                    pending={
                        statistics.participantsWithoutConfirmedIntroductions
                    }
                    completed={
                        statistics.participantsWithConfirmedIntroductions
                    }
                    buttonProps={{
                        disabled: isDisabled,
                        tooltip: isDisabled
                            ? 'Es sind keine Einweisungen realisierbar, da keine Mitarbeiter in das Projekt einbezogen wurden oder keine Projektfelder ausgewählt sind'
                            : '',
                    }}
                />
                <Task
                    title="Unterweisung"
                    icon={<Instructions />}
                    to={`/project-manager/project/${id}/instructions`}
                    pending={statistics.participantsWithPendingInstructions}
                    completed={statistics.participantsWithCompletedInstructions}
                />
            </List>
        </Tile>
    );
};

export default Tasks;
