import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import MarkAsFoundButton from 'Measure/components/vehicle/driver-license/overview/MarkAsFoundButton';
import MarkAsLostButton from 'Measure/components/vehicle/driver-license/overview/MarkAsLostButton';
import { WarningRounded } from '@material-ui/icons';
import WarningMessage from 'Common/components/ActionBar/WarningMessage';

const Wrapper = styled.div`
    margin-top: 20px;

    ${WarningMessage} {
        justify-content: center;
        margin-top: 10px;
    }
`;

const MarkAsLost = ({ driverLicenseControl }) => {
    const isLost = driverLicenseControl.driverLicense.isLost;

    return (
        <Wrapper>
            {isLost ? (
                <>
                    <MarkAsFoundButton
                        driverLicenseControl={driverLicenseControl}
                    />
                    <WarningMessage>
                        <WarningRounded />
                        Führerscheinverlust gemeldet
                    </WarningMessage>
                </>
            ) : (
                <MarkAsLostButton driverLicenseControl={driverLicenseControl} />
            )}
        </Wrapper>
    );
};

MarkAsLost.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
};

export default MarkAsLost;
