import api from 'Application/api/api';

const sendRemindersForIntroduction = (projectId, introductionId, cancelToken = null) =>
    api.post(
        `project-manager/projects/${projectId}/introductions/${introductionId}/send-reminders`,
        {},
        { cancelToken },
    );

export default sendRemindersForIntroduction;
