import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { CheckCircleRounded } from '@material-ui/icons';
import colors from 'Common/constants/colors';
import { ifProp } from 'styled-tools';

const Wrapper = styled.button`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 3px 0;
    background: none;
    color: ${ifProp('completed', colors.GREEN, colors.DIRTY_WHITE)};
    border: 0 none;
    outline: 0 none;
    cursor: pointer;
    text-align: left;
`;

const Name = styled.div`
    font-size: 14px;
    line-height: 21px;
`;

const CompletedIcon = styled(CheckCircleRounded)`
    position: relative;
    top: 1px;
    color: ${colors.GREEN};
    font-size: 19px !important;
`;

const NavigationSubItem = ({ name, ...props }) => (
    <Wrapper type="button" {...props}>
        <Name>{name}</Name>
        {props.completed && <CompletedIcon />}
    </Wrapper>
);

NavigationSubItem.defaultProps = {
    completed: false,
};

NavigationSubItem.propsTypes = {
    name: PropTypes.string.isRequired,
    completed: PropTypes.bool,
};

export default NavigationSubItem;
