import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import withGeneralFiles from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/generalFile/withGeneralFiles';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import uploadGeneralTemplateFiles from 'ProjectManager/Template/Common/api/dataManagement/generalFile/uploadGeneralTemplateFiles';
import getGeneralTemplateFiles from 'ProjectManager/Template/Common/api/dataManagement/generalFile/getGeneralTemplateFiles';

const useUploadTemplateFilesFunction = () => {
    const [{ id: templateId }, setTemplate] = useRecoilState(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const uploadFiles = useCallback(
        async acceptedFiles => {
            await uploadGeneralTemplateFiles(
                templateId,
                acceptedFiles,
                source.token,
            );

            const response = await getGeneralTemplateFiles(
                templateId,
                source.token,
            );

            setTemplate(withGeneralFiles(response.data));
        },
        [templateId, setTemplate, source.token],
    );

    return uploadFiles;
};

export default useUploadTemplateFilesFunction;
