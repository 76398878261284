import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import createClient from 'ProjectManager/Client/api/createClient';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import Input from 'Common/components/Form/Fields/Input';
import { omit } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import isClientNameUnique from 'ProjectManager/Client/api/isClientNameUnique';
import ContactPeopleFields from 'ProjectManager/Project/DataManagement/GeneralInformation/ContactPeople/components/ContactPeopleFields';
import { useWindowWidth } from '@react-hook/window-size';

const initialValues = {
    name: '',
    contactPeople: [
        {
            id: uuidv4(),
            name: '',
            email: '',
        },
    ],
};

const CreateClientModal = ({ onSuccess, onCancel, ...props }) => {
    const [isCreating, setIsCreating] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const uniqueNameValidationCallback = useCallback(
        async value => {
            if (!value) {
                return true;
            }

            try {
                const response = await isClientNameUnique(
                    value,
                    null,
                    source.token,
                );

                return response.data.isUnique;
            } catch (error) {
                return axios.isCancel(error);
            }
        },
        [source.token],
    );

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                name: Yup.string()
                    .debouncedCallback(
                        'Der Name wird bereits verwendet',
                        uniqueNameValidationCallback,
                    )
                    .required('Pflichtangabe'),
                contactPeople: Yup.array()
                    .of(
                        Yup.object()
                            .shape({
                                name: Yup.string().required('Pflichtangabe'),
                                email: Yup.string()
                                    .email('Ungültige E-Mail')
                                    .required('Pflichtangabe'),
                            })
                            .uniqueProperty(
                                'email',
                                'E-Mail bereits in Verwendung',
                            ),
                    )
                    .required('Pflichtangabe'),
            }),
        [uniqueNameValidationCallback],
    );

    const {
        errors,
        touched,
        values,
        setFieldValue,
        setFieldTouched,
        setFieldError,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async values => {
            setIsCreating(true);

            try {
                const response = await createClient(
                    {
                        ...omit(values, ['contactPeople']),
                        contactPeople: values.contactPeople.map(contactPerson =>
                            omit(contactPerson, ['id']),
                        ),
                    },
                    source.token,
                );

                const newClient = response.data;

                // Let the parent component know about the change that was made
                // and who exactly is the new client.
                await onSuccess(newClient);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }

            setIsCreating(false);
        },
    });

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 940;

    const modalRef = useRef(null);

    const setModalRef = node => {
        modalRef.current = node;
    };

    const handleContactPersonAdd = () => {
        if (isMobile && modalRef.current) {
            // The timeout is to ensure that the state will be set
            // and the distance to the button will be calculated properly.
            setTimeout(() => {
                modalRef.current.scroll({
                    top: modalRef.current.scrollHeight,
                    left: 0,
                    behavior: 'smooth',
                });
            }, 5);
        }
    };

    return (
        <Modal
            contentRef={setModalRef}
            maxHeight={500}
            onRequestClose={isCreating ? undefined : onCancel}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onCancel}
                        disabled={isCreating}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isCreating}
                        onClick={handleSubmit}
                        disabled={isCreating || !isValid}
                    >
                        Erstellen
                    </Button>
                </>
            }
            {...props}
        >
            <form onSubmit={handleSubmit}>
                <ModalTitle>Neuen Kunden erstellen</ModalTitle>
                <Input
                    id="name"
                    name="name"
                    label="Kundenname"
                    value={values.name}
                    error={errors.name}
                    touched={touched.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isRequired
                />

                <ContactPeopleFields
                    contactPeople={values.contactPeople}
                    errors={errors.contactPeople}
                    touched={touched.contactPeople}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    setFieldTouched={setFieldTouched}
                    onContactPersonAdd={handleContactPersonAdd}
                />
            </form>
        </Modal>
    );
};

CreateClientModal.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default CreateClientModal;
