import api from 'Application/api/api';

const uploadProjectQuestionFiles = (
    projectId,
    questionId,
    isCustom,
    files,
    cancelToken = null,
) => {
    const formData = new FormData();

    files.forEach((file, index) => {
        formData.append(`file${index}`, file);
    });

    return api.post(
        `project-manager/projects/${projectId}/questionnaire/questions/${questionId}/${
            isCustom ? 1 : 0
        }/files`,
        formData,
        {
            cancelToken,
            timeout: 30 * 1000,
        },
    );
};

export default uploadProjectQuestionFiles;
