import api from 'Application/api/api';

const updateTemplateCustomTopic = (
    templateId,
    customTopicId,
    data,
    cancelToken = null,
) =>
    api.post(
        `project-manager/templates/${templateId}/custom-topics/${customTopicId}`,
        data,
        { cancelToken },
    );

export default updateTemplateCustomTopic;
