import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import Modal from 'Common/components/Modals/Modal';

const TopicDeselectModal = ({ onConfirm, onCancel }) => {
    const [isDeselecting, setIsDeselecting] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleDeselect = async () => {
        setIsDeselecting(true);

        try {
            await onConfirm();

            notify('Das Projektfeld wurde erfolgreich entfernt', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsDeselecting(false);

                notify('Das Projektfeld konnte nicht gelöscht werden', {
                    type: toast.TYPE.ERROR,
                });
            }
        }

        setIsDeselecting(false);
    };
    return (
        <Modal
            isOpen
            onRequestClose={isDeselecting ? undefined : onCancel}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onCancel}
                        disabled={isDeselecting}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isDeselecting}
                        onClick={handleDeselect}
                        disabled={isDeselecting}
                    >
                        Entfernen
                    </Button>
                </>
            }
        >
            <ModalTitle>Abwählen eines Projektfeldes</ModalTitle>
            <p>
                Solltest du dich entscheiden das ausgewählt Projektfeld
                abzuwählen, werden alle Projektfeld bezogenen Daten
                unwiderruflich gelöscht.
            </p>
        </Modal>
    );
};

TopicDeselectModal.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default TopicDeselectModal;
