import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import axios from 'axios';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import { ArrowBackRounded } from '@material-ui/icons';
import Title from 'Measure/components/vehicle/driver-license/Common/Title';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import updateDriverLicenseFrontData from 'Measure/api/driver-license/update/updateDriverLicenseFrontData';
import FormField from 'Common/components/Form/FormField';
import Select from 'Common/components/Form/Fields/Select';
import Input from 'Common/components/Form/Fields/Input';
import LicensePreview from 'Measure/components/vehicle/driver-license/create/LicensePreview';
import { Prompt } from 'react-router';
import isDriverLicenseNumberUnique from 'Measure/api/driver-license/update/isDriverLicenseNumberUnique';
import { debounce } from 'lodash';
import OuterBox from 'Common/components/Boxes/OuterBox';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';
import OuterBoxButtons from 'Common/components/Boxes/OuterBoxButtons';
import DatePicker from 'Common/components/Form/Fields/DatePicker';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import addDays from 'date-fns/addDays';
import subYears from 'date-fns/subYears';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import createNewDate from 'Common/utils/createNewDate';
import formatDateAsISO from 'Common/utils/formatDateAsISO';
import parseDate from 'Common/utils/parseDate';

const Content = styled(OuterBox)`
    margin-bottom: 10px;
    padding: 20px;

    @media screen and (min-width: 940px) {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 0;
        padding: 40px;
    }
`;

const Form = styled.form`
    display: block;
    width: 100%;

    @media screen and (min-width: 940px) {
        display: flex;
    }
`;

const LeftColumn = styled.div`
    margin-bottom: 20px;

    @media screen and (min-width: 940px) {
        flex: 2;
        margin-bottom: 0;
        margin-right: 40px;
    }
`;

const RightColumn = styled.div`
    @media screen and (min-width: 940px) {
        flex: 1;
    }
`;

const FieldSet = styled.div`
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
`;

const FieldSetTitle = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: ${colors.DIRTY_WHITE};
    font-size: 18px;
    line-height: 21px;
`;

const FieldSetRow = styled.div`
    @media screen and (min-width: 620px) {
        display: flex;
    }
`;

const FieldSetColumn = styled.div`
    display: flex;

    > div {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }

    @media screen and (min-width: 620px) {
        flex: 1;
    }
`;

const FieldSetLeftColumn = styled(FieldSetColumn)`
    @media screen and (min-width: 620px) {
        margin-right: 20px;
    }
`;

const FieldSetRightColumn = styled(FieldSetColumn)``;

const checkIntervals = [
    {
        value: 1,
        label: 'monatlich',
    },
    {
        value: 3,
        label: 'vierteljährlich',
    },
    {
        value: 6,
        label: 'halbjährlich',
    },
];

const isDueDateOutsideRange = (date, initialDueDate) => {
    const minDueDate = startOfDay(addDays(createNewDate(), 14));

    return (
        isBefore(date, minDueDate) &&
        (!initialDueDate || !isSameDay(date, initialDueDate))
    );
};

const isExpiryDateOutsideRange = (date, initialExpiryDate) => {
    const today = endOfDay(createNewDate());

    return (
        isBefore(date, today) &&
        (!initialExpiryDate || !isSameDay(date, initialExpiryDate))
    );
};

const DriverLicenseFrontDataForm = ({
    driverLicenseControl,
    title,
    confirmButtonText,
    onConfirm,
    onBackButtonClick,
    onDirty,
}) => {
    const [isSaving, setIsSaving] = useState(false);

    const driverLicense = driverLicenseControl.driverLicense;

    const frontImage = driverLicense.files.find(
        file => file.type === 'FRONT_WITH_HOLOGRAM',
    );
    const path = frontImage.file;

    // @TODO: Refactor the validation to use the new debouncedCallback Yup method
    const debouncedUniqueLicenseNumberValidation = useMemo(
        () =>
            debounce(
                async (licenseNumber, validatorContext) =>
                    licenseNumber
                        ? new Promise(async resolve => {
                              try {
                                  const response = await isDriverLicenseNumberUnique(
                                      licenseNumber,
                                      driverLicenseControl.id,
                                  );

                                  const { isUnique, employee } = response.data;

                                  if (isUnique) {
                                      resolve(true);
                                  } else {
                                      resolve(
                                          validatorContext.createError({
                                              message: `Die Führerscheinnummer wird bereits verwendet. In der Kontrolle des Nutzers: ${employee.lastName}, ${employee.firstName}.`,
                                              path: validatorContext.path,
                                          }),
                                      );
                                  }
                              } catch (error) {
                                  if (axios.isCancel(error)) {
                                      resolve(true);
                                  } else {
                                      resolve(false);
                                  }
                              }
                          })
                        : true,
                350,
                {
                    leading: true,
                    trailing: true,
                },
            ),
        [driverLicenseControl.id],
    );

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                checkIntervalInMonths: Yup.object().required('Pflichtangabe'),
                dueDate: Yup.date()
                    .required('Pflichtangabe')
                    .nullable(),
                fullName: Yup.string().required('Pflichtangabe'),
                address: Yup.string().required('Pflichtangabe'),
                addressNumber: Yup.string().required('Pflichtangabe'),
                residence: Yup.string().required('Pflichtangabe'),
                postCode: Yup.string().required('Pflichtangabe'),
                placeOfBirth: Yup.string().required('Pflichtangabe'),
                dateOfBirth: Yup.date()
                    .required('Pflichtangabe')
                    .nullable(),
                phone: Yup.string().nullable(),
                email: Yup.string().nullable(),
                dateOfIssue: Yup.date()
                    .required('Pflichtangabe')
                    .test(
                        'isBeforeExpiryDate',
                        'Sollte vor dem Ablaufdatum liegen',
                        function(dateOfIssue) {
                            const expiryDateRef = Yup.ref('expiryDate');
                            const expiryDate = this.resolve(expiryDateRef);

                            // No way to compare them, so the validation passes the test
                            if (!dateOfIssue || !expiryDate) {
                                return true;
                            }

                            return isBefore(dateOfIssue, expiryDate);
                        },
                    )
                    .nullable(),
                expiryDate: Yup.date()
                    .test(
                        'isAfterDateOfIssue',
                        'Sollte nach dem Ausstellungsdatum liegen',
                        function(expiryDate) {
                            const dateOfIssueRef = Yup.ref('dateOfIssue');
                            const dateOfIssue = this.resolve(dateOfIssueRef);

                            // No way to compare them, so the validation passes the test
                            if (!expiryDate || !dateOfIssue) {
                                return true;
                            }

                            return isAfter(expiryDate, dateOfIssue);
                        },
                    )
                    .nullable(),
                issuedBy: Yup.string().required('Pflichtangabe'),
                licenseNumber: Yup.string()
                    .test(
                        'isUnique',
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        function(licenseNumber) {
                            return debouncedUniqueLicenseNumberValidation(
                                licenseNumber,
                                this,
                            );
                        },
                    )
                    .required('Pflichtangabe'),
            }),
        [debouncedUniqueLicenseNumberValidation],
    );

    const initialValues = useMemo(
        () =>
            driverLicense
                ? {
                      checkIntervalInMonths: driverLicense.checkIntervalInMonths
                          ? checkIntervals.find(
                                option =>
                                    option.value ===
                                    driverLicense.checkIntervalInMonths,
                            )
                          : checkIntervals[0],
                      dueDate: driverLicense.nextCheck?.dueDate
                          ? parseDate(driverLicense.nextCheck.dueDate)
                          : addDays(createNewDate(), 14),
                      fullName: driverLicense.employeeData?.fullName ?? '',
                      address: driverLicense.employeeData?.address ?? '',
                      addressNumber:
                          driverLicense.employeeData?.addressNumber ?? '',
                      residence: driverLicense.employeeData?.residence ?? '',
                      postCode: driverLicense.employeeData?.postCode ?? '',
                      placeOfBirth:
                          driverLicense.employeeData?.placeOfBirth ?? '',
                      dateOfBirth: driverLicense.employeeData?.dateOfBirth
                          ? parseDate(driverLicense.employeeData.dateOfBirth)
                          : null,
                      phone: driverLicense.employeeData?.phone ?? '',
                      email: driverLicense.employeeData?.email ?? '',
                      dateOfIssue: driverLicense.licenseData?.dateOfIssue
                          ? parseDate(driverLicense.licenseData.dateOfIssue)
                          : null,
                      expiryDate: driverLicense.licenseData?.expiryDate
                          ? parseDate(driverLicense.licenseData.expiryDate)
                          : null,
                      issuedBy: driverLicense.licenseData?.issuedBy ?? '',
                      licenseNumber:
                          driverLicense.licenseData?.licenseNumber ?? '',
                  }
                : {
                      checkIntervalInMonths: checkIntervals[0],
                      dueDate: addDays(createNewDate(), 14),
                      fullName: '',
                      address: '',
                      addressNumber: '',
                      residence: '',
                      postCode: '',
                      placeOfBirth: '',
                      dateOfBirth: null,
                      phone: '',
                      email: '',
                      dateOfIssue: null,
                      expiryDate: null,
                      issuedBy: '',
                      licenseNumber: '',
                  },
        [driverLicense],
    );

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const {
        errors,
        touched,
        values,
        handleChange,
        setFieldValue,
        setFieldTouched,
        handleBlur,
        handleSubmit,
        isValid,
        dirty,
    } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            setIsSaving(true);

            try {
                await updateDriverLicenseFrontData(
                    driverLicenseControl.id,
                    {
                        ...values,
                        checkIntervalInMonths:
                            values.checkIntervalInMonths.value,
                        dueDate: formatDateAsISO(values.dueDate),
                        dateOfBirth: formatDateAsISO(values.dateOfBirth),
                        dateOfIssue: formatDateAsISO(values.dateOfIssue),
                        expiryDate: values.expiryDate
                            ? formatDateAsISO(values.expiryDate)
                            : null,
                    },
                    source.token,
                );

                await onConfirm();

                resetForm({
                    values,
                });

                setIsSaving(false);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setIsSaving(false);

                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }
        },
    });

    useEffect(() => {
        if (onDirty) {
            onDirty(dirty);
        }
    }, [onDirty, dirty]);

    const minDueDate = startOfDay(addDays(createNewDate(), 14));

    const maxDateOfBirth = endOfDay(subYears(createNewDate(), 16));

    const startOfToday = startOfDay(createNewDate());
    const endOfToday = endOfDay(createNewDate());

    const initialDueDate = driverLicense.nextCheck?.dueDate
        ? parseDate(driverLicense.nextCheck.dueDate)
        : null;

    const initialExpiryDate = driverLicense.licenseData?.expiryDate
        ? parseDate(driverLicense.licenseData.expiryDate)
        : null;

    const handleEmailKeyDown = e => {
        if (e.key === ' ') {
            e.preventDefault();
        }
    };

    const handleEmailInput = e => {
        e.target.value = e.target.value.replace(/\s/g, '');
    };

    return (
        <>
            {!!title && <Title>{title}</Title>}
            <Content>
                <Form onSubmit={handleSubmit}>
                    <LeftColumn>
                        <FieldSet>
                            <FieldSetTitle>
                                Kontrolldaten
                                <LicensePreview path={path} />
                            </FieldSetTitle>
                            <FieldSetRow>
                                <FieldSetLeftColumn>
                                    <FormField>
                                        <Select
                                            label="Intervall"
                                            id="checkIntervalInMonths"
                                            name="checkIntervalInMonths"
                                            error={errors.checkIntervalInMonths}
                                            touched={
                                                touched.checkIntervalInMonths
                                            }
                                            value={values.checkIntervalInMonths}
                                            setFieldValue={setFieldValue}
                                            onBlur={handleBlur}
                                            options={checkIntervals}
                                            isSearchable={false}
                                        />
                                    </FormField>
                                </FieldSetLeftColumn>
                                <FieldSetRightColumn>
                                    <FormField>
                                        <DatePicker
                                            id="dueDate"
                                            name="dueDate"
                                            label="Startdatum"
                                            error={errors.dueDate}
                                            touched={touched.dueDate}
                                            value={values.dueDate}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                            filterDate={date =>
                                                !isDueDateOutsideRange(
                                                    date,
                                                    initialDueDate,
                                                )
                                            }
                                            openToDate={
                                                values.dueDate ?? minDueDate
                                            }
                                            disabled={
                                                driverLicense?.nextCheck
                                                    ?.isInVerificationPeriod
                                            }
                                        />
                                    </FormField>
                                </FieldSetRightColumn>
                            </FieldSetRow>
                        </FieldSet>
                        <FieldSet>
                            <FieldSetTitle>
                                Angaben zur fahrenden Person
                            </FieldSetTitle>
                            <FieldSetRow>
                                <FieldSetLeftColumn>
                                    <FormField>
                                        <Input
                                            label="Vollständiger Name"
                                            id="fullName"
                                            name="fullName"
                                            error={errors.fullName}
                                            touched={touched.fullName}
                                            value={values.fullName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FormField>
                                </FieldSetLeftColumn>
                                <FieldSetRightColumn>
                                    <FormField>
                                        <Input
                                            label="Wohnort"
                                            id="residence"
                                            name="residence"
                                            error={errors.residence}
                                            touched={touched.residence}
                                            value={values.residence}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FormField>
                                    <FormField size={95}>
                                        <Input
                                            label="PLZ"
                                            id="postCode"
                                            name="postCode"
                                            error={errors.postCode}
                                            touched={touched.postCode}
                                            value={values.postCode}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FormField>
                                </FieldSetRightColumn>
                            </FieldSetRow>
                            <FieldSetRow>
                                <FieldSetLeftColumn>
                                    <FormField>
                                        <Input
                                            label="Straße"
                                            id="address"
                                            name="address"
                                            error={errors.address}
                                            touched={touched.address}
                                            value={values.address}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FormField>
                                    <FormField size={95}>
                                        <Input
                                            label="Nr."
                                            id="addressNumber"
                                            name="addressNumber"
                                            error={errors.addressNumber}
                                            touched={touched.addressNumber}
                                            value={values.addressNumber}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FormField>
                                </FieldSetLeftColumn>
                                <FieldSetRightColumn>
                                    <FormField>
                                        <Input
                                            label="Geburtsort"
                                            id="placeOfBirth"
                                            name="placeOfBirth"
                                            error={errors.placeOfBirth}
                                            touched={touched.placeOfBirth}
                                            value={values.placeOfBirth}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FormField>
                                    <FormField>
                                        <DatePicker
                                            id="dateOfBirth"
                                            name="dateOfBirth"
                                            label="Geburtsdatum"
                                            error={errors.dateOfBirth}
                                            touched={touched.dateOfBirth}
                                            value={values.dateOfBirth}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                            maxDate={maxDateOfBirth}
                                            openToDate={
                                                values.dateOfBirth ??
                                                maxDateOfBirth
                                            }
                                        />
                                    </FormField>
                                </FieldSetRightColumn>
                            </FieldSetRow>
                            <FieldSetRow>
                                <FieldSetLeftColumn>
                                    <FormField>
                                        <Input
                                            label="E-Mail-Addresse"
                                            id="email"
                                            name="email"
                                            error={errors.email}
                                            touched={touched.email}
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onKeyDown={handleEmailKeyDown}
                                            onInput={handleEmailInput}
                                            disabled={
                                                !!driverLicense.employeeData
                                                    ?.email
                                            }
                                        />
                                    </FormField>
                                </FieldSetLeftColumn>
                                <FieldSetRightColumn>
                                    <FormField>
                                        <Input
                                            label="Telefonnummer"
                                            id="phone"
                                            name="phone"
                                            error={errors.phone}
                                            touched={touched.phone}
                                            value={values.phone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={
                                                !!driverLicense.employeeData
                                                    ?.phone
                                            }
                                        />
                                    </FormField>
                                </FieldSetRightColumn>
                            </FieldSetRow>
                        </FieldSet>
                    </LeftColumn>
                    <RightColumn>
                        <FieldSetTitle>Angaben zur Fahrerlaubnis</FieldSetTitle>
                        <div>
                            <FormField>
                                <DatePicker
                                    id="dateOfIssue"
                                    name="dateOfIssue"
                                    label="Ausstellungsdatum (Ziff. 4a)"
                                    error={errors.dateOfIssue}
                                    touched={touched.dateOfIssue}
                                    value={values.dateOfIssue}
                                    setFieldValue={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                    maxDate={endOfToday}
                                    openToDate={
                                        values.dateOfIssue ?? endOfToday
                                    }
                                />
                            </FormField>
                            <FormField>
                                <DatePicker
                                    id="expiryDate"
                                    name="expiryDate"
                                    label="Ablaufdatum (Ziff. 4b)"
                                    error={errors.expiryDate}
                                    touched={touched.expiryDate}
                                    value={values.expiryDate}
                                    setFieldValue={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                    filterDate={date =>
                                        !isExpiryDateOutsideRange(
                                            date,
                                            initialExpiryDate,
                                        )
                                    }
                                    openToDate={
                                        values.expiryDate ?? startOfToday
                                    }
                                />
                            </FormField>
                            <FormField>
                                <Input
                                    label="Ausstellungsbehörde (Ziff. 4c)"
                                    id="issuedBy"
                                    name="issuedBy"
                                    error={errors.issuedBy}
                                    touched={touched.issuedBy}
                                    value={values.issuedBy}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormField>
                            <FormField>
                                <Input
                                    label="Führerscheinnummer (Ziff. 5)"
                                    id="licenseNumber"
                                    name="licenseNumber"
                                    error={errors.licenseNumber}
                                    touched={touched.licenseNumber}
                                    value={values.licenseNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </FormField>
                        </div>
                    </RightColumn>
                </Form>
            </Content>
            <OuterBoxButtons>
                {!!onBackButtonClick && (
                    <Button
                        text
                        icon={<ArrowBackRounded />}
                        type="button"
                        onClick={onBackButtonClick}
                    >
                        Zurück
                    </Button>
                )}
                <Button
                    isLoading={isSaving}
                    type="button"
                    disabled={isSaving || !isValid}
                    onClick={handleSubmit}
                >
                    {confirmButtonText}
                </Button>
            </OuterBoxButtons>
            <Prompt
                when={dirty}
                message="Möchtest du die Seite wirklich verlassen? Nicht gespeicherte Änderungen gehen verloren."
            />
        </>
    );
};

DriverLicenseFrontDataForm.defaultProps = {
    title: null,
    confirmButtonText: 'Speichern',
    onBackButtonClick: null,
    onDirty: null,
};

DriverLicenseFrontDataForm.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
    title: PropTypes.string,
    confirmButtonText: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onBackButtonClick: PropTypes.func,
    onDirty: PropTypes.func,
};

export default DriverLicenseFrontDataForm;
