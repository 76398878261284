import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { Link } from 'react-router-dom';
import { ArrowForwardRounded } from '@material-ui/icons';
import InteractableInnerBox from 'Common/components/Boxes/InteractableInnerBox';
import Loader from 'Common/components/Loader';
import { Tooltip } from '@material-ui/core';

const Button = styled(InteractableInnerBox).attrs({
    as: 'button',
})`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    padding: 15px;
    border: 0 none;
    outline: 0 none;
    text-decoration: none;
    text-align: left;
    cursor: pointer;

    &[disabled] {
        opacity: 0.35;
        pointer-events: none;
    }
`;

const ButtonAsReactRouterLink = styled(Button).attrs({
    as: Link,
})``;

const ButtonAsHtmlAnchor = styled(Button).attrs({
    as: 'a',
})``;

const IconCircle = styled.div`
    display: flex;
    flex: 0 0 40px;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 20px;
    background-color: ${colors.PRIMARY};
    color: ${colors.DIRTY_WHITE};
    border-radius: 50%;
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.3);

    svg {
        font-size: 21px;
    }
`;

const Texts = styled.div`
    flex: 1;
`;

const Title = styled.div`
    font-size: 14px;
    line-height: 21px;
`;

const Description = styled.div`
    display: none;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.GRAY};

    @media screen and (min-width: 375px) {
        display: block;
    }
`;

const ArrowIconWrapper = styled.div`
    flex: 0 0 24px;
    width: 24px;
    margin-left: 20px;
`;

const DisabledButtonWrapper = styled.div`
    display: inline-flex;
`;

const QuickAction = ({
    icon,
    title,
    description,
    isLoading,
    tooltip,
    ...props
}) => {
    let isReactRouterLink = Boolean(props.to);
    let isHtmlAnchor = Boolean(props.href);

    let ButtonComponent;

    if (isReactRouterLink) {
        ButtonComponent = ButtonAsReactRouterLink;
    } else if (isHtmlAnchor) {
        ButtonComponent = ButtonAsHtmlAnchor;
    } else {
        ButtonComponent = Button;
    }

    const renderedButton = (
        <ButtonComponent {...props}>
            <IconCircle>
                {isLoading ? (
                    <Loader size={24} color={colors.DIRTY_WHITE} />
                ) : (
                    icon
                )}
            </IconCircle>
            <Texts>
                <Title>{title}</Title>
                {Boolean(description) && (
                    <Description>{description}</Description>
                )}
            </Texts>
            <ArrowIconWrapper>
                <ArrowForwardRounded />
            </ArrowIconWrapper>
        </ButtonComponent>
    );

    if (tooltip) {
        const tooltipProps =
            typeof tooltip === 'string'
                ? {
                      title: tooltip,
                  }
                : tooltip;

        if (props.disabled) {
            // By default a disabled <button> will not trigger user interactions,
            // so a Tooltip will not activate on normal events like hover.
            // To accommodate the disabled button, it is wrapped in a <div>.
            return (
                <Tooltip {...tooltipProps}>
                    <DisabledButtonWrapper>
                        {renderedButton}
                    </DisabledButtonWrapper>
                </Tooltip>
            );
        }

        return <Tooltip {...tooltipProps}>{renderedButton}</Tooltip>;
    }

    return renderedButton;
};

QuickAction.defaultProps = {
    description: undefined,
    isLoading: false,
    tooltip: undefined,
};

QuickAction.propTypes = {
    icon: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    isLoading: PropTypes.bool,
    tooltip: PropTypes.any,
};

export default QuickAction;
