import api from 'Application/api/api';

const startGroupInstructionAssignmentPresentation = (
    id,
    employeeIds,
    cancelToken = null,
) =>
    api.post(
        `/education/instruction-assignments/${id}/presentation/start/group`,
        {
            employeeIds,
        },
        { cancelToken },
    );

export default startGroupInstructionAssignmentPresentation;
