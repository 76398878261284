import React from 'react';
import PropTypes from 'prop-types';
import ProjectDetails from 'ProjectManager/Project/Preview/ProjectDetails/ProjectDetails';
import QuickActions from 'ProjectManager/Project/Preview/QuickActions/QuickActions';
import WorkforcePlanning from 'ProjectManager/Project/Preview/WorkforcePlanning/WorkforcePlanning';
import MediaFiles from 'ProjectManager/Project/Preview/MediaFiles/MediaFiles';
import Tasks from 'ProjectManager/Project/Preview/Tasks/Tasks';
import Timeline from 'ProjectManager/Project/Preview/Timeline/Timeline';
import gridItems from 'ProjectManager/Project/Preview/Grid/constants/gridItems';
import Topics from 'ProjectManager/Project/Preview/Topics/Topics';
import People from 'ProjectManager/Project/Preview/People/People';

const GridItem = ({ id }) => {
    switch (id) {
        case gridItems.PROJECT_DETAILS:
            return <ProjectDetails />;

        case gridItems.TASKS:
            return <Tasks />;

        case gridItems.WORKFORCE_PLANNING:
            return <WorkforcePlanning />;

        case gridItems.TIMELINE:
            return <Timeline />;

        case gridItems.MEDIA_FILES:
            return <MediaFiles />;

        case gridItems.PEOPLE:
            return <People />;

        case gridItems.TOPICS:
            return <Topics />;

        case gridItems.QUICK_ACTIONS:
            return <QuickActions />;

        default:
            return null;
    }
};

GridItem.propTypes = {
    id: PropTypes.string.isRequired,
};

export default GridItem;
