import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import CircularProgress from '@material-ui/core/CircularProgress';

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;

const Loader = ({ size, className, color }) => (
    <Wrapper
        className={className}
        style={{
            color,
        }}
    >
        <CircularProgress size={size} thickness={4} color="inherit" />
    </Wrapper>
);

Loader.defaultProps = {
    size: 150,
    color: colors.PRIMARY,
};

Loader.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
};

export default Loader;
