import api from 'Application/api/api';

const uploadTemplateQuestionFiles = (
    templateId,
    questionId,
    isCustom,
    files,
    cancelToken = null,
) => {
    const formData = new FormData();

    files.forEach((file, index) => {
        formData.append(`file${index}`, file);
    });

    return api.post(
        `project-manager/templates/${templateId}/questionnaire/questions/${questionId}/${
            isCustom ? 1 : 0
        }/files`,
        formData,
        {
            cancelToken,
            timeout: 30 * 1000,
        },
    );
};

export default uploadTemplateQuestionFiles;
