const withUpdatedCustomTopicData = (
    customTopicId,
    updatedValues,
) => prevProjectOrTemplate => {
    const customTopics = [...prevProjectOrTemplate.customTopics];

    const index = customTopics.findIndex(
        customTopic => customTopic.id === customTopicId,
    );

    if (index !== -1) {
        customTopics[index] = {
            ...customTopics[index],
            ...updatedValues,
        };
    }

    return {
        ...prevProjectOrTemplate,
        customTopics,
    };
};

export default withUpdatedCustomTopicData;
