import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TypeSelection
    from 'ProjectManager/DataManagement/Questionnaire/components/QuestionCreation/components/CreationWizard/Steps/TypeSelection';
import InformationForm
    from 'ProjectManager/DataManagement/Questionnaire/components/QuestionCreation/components/CreationWizard/Steps/InformationForm';

const steps = {
    TYPE_SELECTION: 'TYPE_SELECTION',
    DATA_INSERTION: 'DATA_INSERTION',
};

const CreationWizard = ({ subsection, onCreate, onCancel }) => {
    const [selectedType, setSelectedType] = useState(null);
    const [currentStep, setCurrentStep] = useState(steps.TYPE_SELECTION);

    const handleContinueButton = () => {
        setCurrentStep(steps.DATA_INSERTION);
    };

    const handleBackButtonClick = () => {
        setCurrentStep(steps.TYPE_SELECTION);
    };

    if (currentStep === steps.TYPE_SELECTION) {
        return (
            <TypeSelection
                selectedType={selectedType}
                onSelect={setSelectedType}
                onCancel={onCancel}
                onContinue={handleContinueButton}
            />
        );
    }

    if (currentStep === steps.DATA_INSERTION) {
        return <InformationForm
            subsection={subsection}
            selectedType={selectedType}
            onBackButtonClick={handleBackButtonClick}
            onCreate={onCreate}
        />;
    }

    throw new Error(`Unrecognized step: "${currentStep}"`);
};

CreationWizard.propTypes = {
    subsection: PropTypes.object.isRequired,
    onCreate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default CreationWizard;
