import { useMemo } from 'react';

const useHash = (defaultValue = 0) => {
    const setValue = newValue => {
        if (newValue !== defaultValue) {
            window.location.hash = `#${newValue}`;
        } else {
            window.location.hash = '';
            window.history.replaceState({}, '', ' ');
        }
    };

    const hash = window.location.hash;

    const value = useMemo(() => {
        if (!hash) {
            return defaultValue;
        }

        const parsedHash = hash.substr(1);
        const isNumber = !Number.isNaN(Number(parsedHash));

        if (isNumber) {
            return Number(parsedHash);
        }

        return parsedHash;
    }, [defaultValue, hash]);

    return [value, setValue];
};

export default useHash;
