import React, { useState } from 'react';
import Button from 'Common/components/Button';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { useRecoilValue } from 'recoil';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import CreateDrawer from 'ProjectManager/Project/Introductions/Create/components/CreateDrawer';

const CreateButton = () => {
    const { participants, status } = useRecoilValue(projectAtom);

    const isDisabled =
        participants.length === 0 ||
        [projectStatuses.ENDED, projectStatuses.ARCHIVED].includes(status);

    const [isDrawerOpened, setIsDrawerOpened] = useState(false);

    const openDrawer = () => {
        setIsDrawerOpened(true);
    };

    const closeDrawer = () => {
        setIsDrawerOpened(false);
    };

    return (
        <>
            <Button
                type="button"
                onClick={openDrawer}
                disabled={isDisabled}
                tooltip={
                    isDisabled
                        ? 'In dem Projekt können momentan keine Einweisungen erstellt werden'
                        : ''
                }
            >
                Einweisung erstellen
            </Button>
            <CreateDrawer
                isOpened={isDrawerOpened}
                onOpen={openDrawer}
                onClose={closeDrawer}
            />
        </>
    );
};

export default CreateButton;
