import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import Modal from 'Common/components/Modals/Modal';
import useDeleteQuestionCommentFunction
    from 'ProjectManager/DataManagement/Questionnaire/components/Question/Comment/hooks/deleteQuestionCommentFunction/useDeleteQuestionCommentFunction';

const CommentDeleteModal = ({ question, onCancel, onSuccess }) => {
    const deleteComment = useDeleteQuestionCommentFunction(
        question.id,
        question.isCustom,
    );

    const [isDeleting, setIsDeleting] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirm = async () => {
        setIsDeleting(true);

        try {
            await deleteComment();
            await onSuccess();

            notify('Der Kommentar wurde erfolgreich gelöscht', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify('Der Kommentar konnte nicht gelöscht werden', {
                    type: toast.TYPE.ERROR,
                });
            }
        }

        setIsDeleting(false);
    };

    return (
        <Modal
            isOpen
            onRequestClose={isDeleting ? undefined : onCancel}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onCancel}
                        disabled={isDeleting}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isDeleting}
                        disabled={isDeleting}
                        onClick={handleConfirm}
                    >
                        Löschen
                    </Button>
                </>
            }
        >
            <ModalTitle>Wollen Sie den Kommentar wirklich löschen?</ModalTitle>
        </Modal>
    );
};

CommentDeleteModal.propTypes = {
    question: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default CommentDeleteModal;
