import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';

const useAxiosRequest = (
    request,
    initialData,
    {
        isManual = false,
        isInitiallyLoading = undefined,
        customDataSetterFunction = undefined,
    } = {},
) => {
    const defaultData = useRef(initialData);

    const [data, setData] = useState(initialData);
    const [isLoading, setIsLoading] = useState(isInitiallyLoading ?? !isManual);
    const [error, setError] = useState(null);

    const loadData = useCallback(
        async (cancelToken, withLoader, rest) => {
            if (withLoader) {
                setIsLoading(true);
            }

            setError(null);

            try {
                const result = request(cancelToken);

                const response =
                    typeof result === 'function'
                        ? await result(...rest)
                        : await result;

                if (customDataSetterFunction) {
                    customDataSetterFunction(response?.data ?? response);
                } else {
                    setData(response?.data ?? response);
                }

                if (withLoader) {
                    setIsLoading(false);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    if (customDataSetterFunction) {
                        customDataSetterFunction(defaultData.current);
                    } else {
                        setData(defaultData.current);
                    }

                    setError(error);
                    setIsLoading(false);
                }
            }
        },
        [request, customDataSetterFunction],
    );

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const manuallyLoadData = useCallback(
        (...rest) => {
            (async () => {
                await loadData(source.token, true, rest);
            })();
        },
        [loadData, source.token],
    );

    const manuallyLoadDataWithoutLoader = useCallback(
        (...rest) => {
            (async () => {
                await loadData(source.token, false, rest);
            })();
        },
        [loadData, source.token],
    );

    useEffect(() => {
        const source = axios.CancelToken.source();

        if (!isManual) {
            loadData(source.token, true);
        }

        return () => {
            source.cancel();
        };
    }, [isManual, loadData]);

    return {
        data,
        setData,
        loadData: manuallyLoadData,
        loadDataWithoutLoader: manuallyLoadDataWithoutLoader,
        isLoading,
        error,
        hasError: !!error,
    };
};

export default useAxiosRequest;
