import styled from 'styled-components/macro';
import MoreActionsButton from 'Common/components/MoreActionsButton/MoreActionsButton';
import MenuItem from 'Common/components/MoreActionsButton/MenuItem';
import { DeleteRounded } from '@material-ui/icons';
import React, { useState } from 'react';
import DeleteModal from 'ProjectManager/Project/CreationProcess/components/ActionsMenu/DeleteModal';

const StyledMoreActionsButton = styled(MoreActionsButton)`
    @media screen and (min-width: 1180px) {
        position: relative;
        pointer-events: all;
    }
`;

const ActionsMenu = () => {
    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

    const handleDeleteButtonClick = () => {
        setIsDeleteModalOpened(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpened(false);
    };

    return (
        <>
            <StyledMoreActionsButton>
                <MenuItem
                    type="button"
                    icon={<DeleteRounded />}
                    onClick={handleDeleteButtonClick}
                >
                    Projekt Löschen
                </MenuItem>
            </StyledMoreActionsButton>
            {isDeleteModalOpened && <DeleteModal onClose={closeDeleteModal} />}
        </>
    );
};

export default ActionsMenu;
