import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';

const MatchedText = styled.span`
    background: ${colors.BLUE};
    color: ${colors.DIRTY_WHITE};
`;

const MatchableText = ({ text, searchTerm }) => {
    if (!searchTerm || !text.toLowerCase().includes(searchTerm.toLowerCase())) {
        return text;
    }

    const searchTermRegEx = new RegExp(searchTerm, 'gi');
    const matches = text.match(searchTermRegEx);

    return text.split(searchTermRegEx).map((textPart, index) => {
        if (index === 0) {
            return <Fragment key={index}>{textPart}</Fragment>;
        }

        return (
            <Fragment key={index}>
                <MatchedText>{matches[index - 1]}</MatchedText>
                {textPart}
            </Fragment>
        );
    });
};

MatchableText.defaultProps = {
    searchTerm: undefined,
};

MatchableText.propTypes = {
    text: PropTypes.string.isRequired,
    searchTerm: PropTypes.string,
};

export default MatchableText;
