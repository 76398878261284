const weekDays = [
    {
        value: 'MONDAY',
        label: 'Mo',
    },
    {
        value: 'TUESDAY',
        label: 'Di',
    },
    {
        value: 'WEDNESDAY',
        label: 'Mi',
    },
    {
        value: 'THURSDAY',
        label: 'Do',
    },
    {
        value: 'FRIDAY',
        label: 'Fr',
    },
    {
        value: 'SATURDAY',
        label: 'Sa',
    },
    {
        value: 'SUNDAY',
        label: 'So',
    },
];

export default weekDays;
