import React from 'react';
import { useRecoilValue } from 'recoil';
import projectMediaFilesSearchTermAtom from 'ProjectManager/Project/ProjectMediaFiles/recoil/projectMediaFilesSearchTerm/projectMediaFilesSearchTermAtom';
import FilesList from 'ProjectManager/DataManagement/Files/components/FilesList';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import styled from 'styled-components/macro';

const ListWrapper = styled.div`
    margin-top: 20px;
`;

const GeneralProjectFilesSearchResults = () => {
    const { files } = useRecoilValue(projectAtom);
    const searchTerm = useRecoilValue(projectMediaFilesSearchTermAtom);

    const foundFiles = files.filter(file =>
        file.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    return (
        <>
            <OuterBoxTitle>
                {`Allgemeine Projektdateien (${foundFiles.length} Datei${
                    foundFiles.length !== 1 ? 'en' : ''
                })`}
            </OuterBoxTitle>
            <ListWrapper>
                <FilesList
                    files={foundFiles}
                    noFilesText="Keine Projektdateien gefunden"
                    searchTerm={searchTerm}
                />
            </ListWrapper>
        </>
    );
};

export default GeneralProjectFilesSearchResults;
