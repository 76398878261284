import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import InnerBox from 'Common/components/Boxes/InnerBox';
import {
    CheckBoxOutlineBlankRounded,
    CheckBoxRounded,
    RadioButtonCheckedRounded,
    RadioButtonUncheckedRounded,
} from '@material-ui/icons';
import colors from 'Common/constants/colors';
import { Tooltip } from '@material-ui/core';
import Switch from 'Common/components/Form/Switch';
import Loader from 'Common/components/Loader';

const NonInteractableWrapper = styled(InnerBox)`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 12px;

    &[disabled] {
        opacity: 0.3;
        cursor: default;
        pointer-events: none;
    }
`;

const InteractableWrapper = styled(NonInteractableWrapper).attrs({
    as: 'button',
})`
    outline: 0 none;
    border: 0 none;
    text-align: left;
    cursor: pointer;
`;

const DisabledButtonWrapper = styled.span`
    display: inline-flex;
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 30px;
    font-size: 14px;
    line-height: 21px;

    > svg {
        margin-right: 10px;
        font-size: 48px;
    }
`;

const Description = styled.div`
    margin-top: 7px;
    color: ${colors.GRAY};
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
`;

const Icon = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
`;

const SwitchWrapper = styled.div`
    position: absolute;
    top: 2px;
    right: 2px;
`;

const ModalOption = ({
    icon,
    title,
    description,
    isChecked,
    isMulti,
    isSwitch,
    isLoading,
    onToggle,
    tooltip,
    ...props
}) => {
    const UncheckedIcon = isMulti
        ? CheckBoxOutlineBlankRounded
        : RadioButtonUncheckedRounded;

    const CheckedIcon = isMulti ? CheckBoxRounded : RadioButtonCheckedRounded;

    const Wrapper = isSwitch ? NonInteractableWrapper : InteractableWrapper;

    const additionalProps = {};

    if (!isSwitch) {
        additionalProps.onClick = () => onToggle(!isChecked);
    }

    const button = (
        <Wrapper light {...props} {...additionalProps}>
            <Title>
                {icon}
                {title}
            </Title>
            {description !== null && <Description>{description}</Description>}
            {isSwitch ? (
                <SwitchWrapper>
                    <Switch
                        checked={isChecked}
                        onChange={e => onToggle(e.target.checked)}
                        name="checked"
                    />
                </SwitchWrapper>
            ) : (
                <Icon>
                    {isLoading ? (
                        <Loader size={24} />
                    ) : isChecked ? (
                        <CheckedIcon />
                    ) : (
                        <UncheckedIcon />
                    )}
                </Icon>
            )}
        </Wrapper>
    );

    if (tooltip) {
        if (props.disabled) {
            return (
                <Tooltip title={tooltip}>
                    <DisabledButtonWrapper>{button}</DisabledButtonWrapper>
                </Tooltip>
            );
        }

        return <Tooltip title={tooltip}>{button}</Tooltip>;
    }

    return button;
};

ModalOption.defaultProps = {
    icon: undefined,
    isMulti: false,
    isSwitch: false,
    isLoading: false,
    description: null,
    tooltip: '',
};

ModalOption.propTypes = {
    icon: PropTypes.any,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    isChecked: PropTypes.bool.isRequired,
    isMulti: PropTypes.bool,
    isSwitch: PropTypes.bool,
    isLoading: PropTypes.bool,
    onToggle: PropTypes.func.isRequired,
    tooltip: PropTypes.string,
};

export default ModalOption;
