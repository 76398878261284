import { useRecoilValue } from 'recoil';
import isTemplateEmployeeSelectionStepCompletedSelector from 'ProjectManager/Template/Common/recoil/selectors/isTemplateEmployeeSelectionStepCompletedSelector';

const useIsTemplateEmployeeSelectionStepCompleted = () => {
    // noinspection UnnecessaryLocalVariableJS
    const isTemplateEmployeeSelectionStepCompleted = useRecoilValue(
        isTemplateEmployeeSelectionStepCompletedSelector,
    );

    return isTemplateEmployeeSelectionStepCompleted;
};

export default useIsTemplateEmployeeSelectionStepCompleted;
