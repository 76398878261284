import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import axios from 'axios';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import {
    ArrowBackRounded,
    CheckRounded,
    CloseRounded,
} from '@material-ui/icons';
import Title from 'Measure/components/vehicle/driver-license/Common/Title';
import Loader from 'Common/components/Loader';
import LicenseImage from 'Measure/components/vehicle/driver-license/create/LicenseImage';
import approveDriverLicenseDistanceFile from 'Measure/api/driver-license/distance-check/approveDriverLicenseDistanceFile';
import driverLicenseFileTypes from 'Measure/constants/driverLicenseFileTypes';
import rejectDriverLicenseDistanceFile from 'Measure/api/driver-license/distance-check/rejectDriverLicenseDistanceFile';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import getDistanceProcedureFileRejectReasons from 'Measure/api/driver-license/distance-check/getDistanceProcedureFileRejectReasons';
import RetryLoading from 'Common/components/RetryLoading';
import FormField from 'Common/components/Form/FormField';
import Checkbox from 'Common/components/Form/Fields/Checkbox';
import OuterBox from 'Common/components/Boxes/OuterBox';
import colors from 'Common/constants/colors';
import InnerBox from 'Common/components/Boxes/InnerBox';
import Button from 'Common/components/Button';
import OuterBoxButtons from 'Common/components/Boxes/OuterBoxButtons';
import parseDate from 'Common/utils/parseDate';

const SideBySideContent = styled.div`
    > div {
        margin-bottom: 10px;
        padding: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 940px) {
        display: flex;
        min-height: 320px;
        padding: 0;
        background: none;

        > div {
            flex: 1;
            width: calc(50% - 5px);
            margin-right: 10px;
            margin-bottom: 0;
            padding: 40px 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    @media screen and (min-width: 1180px) {
        > div {
            padding-left: 40px;
            padding-right: 40px;
        }
    }
`;

const Content = styled(OuterBox)`
    margin-bottom: 10px;

    @media screen and (min-width: 620px) {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 0;
    }
`;

const RejectReasons = styled.div``;

const RejectReasonsTitle = styled.div`
    margin-bottom: 20px;
    color: ${colors.DIRTY_WHITE};
    font-size: 18px;
    text-align: center;

    @media screen and (min-width: 940px) {
        margin-bottom: 30px;
    }
`;

const RejectReasonsFields = styled(InnerBox)`
    padding: 20px;

    > div {
        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 620px) {
        padding: 40px;
    }
`;

const DriverLicenseDistanceImageReview = ({
    driverLicenseControl,
    type,
    title,
    onApprove,
    onReject,
}) => {
    const [isConfirming, setIsConfirming] = useState(false);
    const [isInRejectMode, setIsInRejectMode] = useState(false);
    const [selectedRejectReasons, setSelectedRejectReasons] = useState([]);

    const {
        data: rejectReasons,
        loadData: loadRejectReasons,
        isLoading,
        hasError,
    } = useAxiosRequest(getDistanceProcedureFileRejectReasons, null);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    if (isLoading) {
        return <Loader />;
    }

    if (hasError) {
        return <RetryLoading onRetry={loadRejectReasons} />;
    }

    const handleApproveButtonClick = async () => {
        setIsConfirming(true);

        try {
            await approveDriverLicenseDistanceFile(
                driverLicenseControl.id,
                type,
                source.token,
            );

            await onApprove();

            setIsConfirming(false);
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsConfirming(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const handleConfirmRejectionButtonClick = async () => {
        setIsConfirming(true);

        try {
            await rejectDriverLicenseDistanceFile(
                driverLicenseControl.id,
                type,
                selectedRejectReasons,
                source.token,
            );

            await onReject();

            setIsConfirming(false);
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsConfirming(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const handleRejectButtonClick = async () => {
        setIsInRejectMode(true);
    };

    const handleBackButtonClick = () => {
        setIsInRejectMode(false);
    };

    const handleRejectReasonCheckboxChange = (reason, isChecked) => {
        setSelectedRejectReasons(prevRejectReasons => {
            const newRejectReasons = [...prevRejectReasons];

            const index = newRejectReasons.indexOf(reason);

            if (isChecked && index === -1) {
                newRejectReasons.push(reason);
            } else if (!isChecked && index !== -1) {
                newRejectReasons.splice(index, 1);
            }

            return newRejectReasons;
        });
    };

    const driverLicense = driverLicenseControl.driverLicense;

    const pendingFile = driverLicense.nextCheck.distanceProcedure.files.find(
        file => file.type === type,
    );

    const originalFile = driverLicense.files.find(file => file.type === type);

    const hint =
        type === driverLicenseFileTypes.FRONT_WITH_HOLOGRAM ? (
            <>
                Bitte führen Sie den Abgleich der beiden hinterlegten
                Führerscheinvorderseiten durch. Anschließend bestätigen Sie
                bitte den Abgleich. Sollte der Führerscheinabgleich zu keiner
                Übereinstimmung führen, bitten wir Sie den Vorgang abzuweisen.
            </>
        ) : (
            <>
                Bitte führen Sie den Abgleich der beiden hinterlegten
                Führerscheinrückseiten durch. Anschließend bestätigen Sie bitte
                den Abgleich. Sollte der Führerscheinabgleich zu keiner
                Übereinstimmung führen, bitten wir Sie den Vorgang abzuweisen.
            </>
        );

    return (
        <>
            <Title>{title}</Title>
            <SideBySideContent>
                <Content>
                    <LicenseImage
                        title="Bereitgestellte Fotoaufnahme des Mitarbeiters"
                        hint={hint}
                        src={pendingFile.file}
                        watermarkDate={parseDate(pendingFile.uploadedAt)}
                    />
                </Content>
                <Content>
                    {isInRejectMode ? (
                        <RejectReasons>
                            <RejectReasonsTitle>
                                Grund der Ablehnung
                            </RejectReasonsTitle>
                            <RejectReasonsFields>
                                {rejectReasons.map(rejectReason => (
                                    <FormField key={rejectReason.value}>
                                        <Checkbox
                                            id={`rejectReason_${rejectReason.value}`}
                                            label={rejectReason.name}
                                            checked={selectedRejectReasons.includes(
                                                rejectReason.value,
                                            )}
                                            onChange={e =>
                                                handleRejectReasonCheckboxChange(
                                                    rejectReason.value,
                                                    e.target.checked,
                                                )
                                            }
                                        />
                                    </FormField>
                                ))}
                            </RejectReasonsFields>
                        </RejectReasons>
                    ) : (
                        <LicenseImage
                            title="Originale Fotoaufnahme des Mitarbeiters"
                            src={originalFile.file}
                            watermarkDate={parseDate(originalFile.uploadedAt)}
                        />
                    )}
                </Content>
            </SideBySideContent>
            <OuterBoxButtons>
                {isInRejectMode ? (
                    <>
                        <Button
                            text
                            type="button"
                            icon={<ArrowBackRounded />}
                            onClick={handleBackButtonClick}
                        >
                            Zurück
                        </Button>
                        <Button
                            type="button"
                            isLoading={isConfirming}
                            disabled={isConfirming}
                            onClick={handleConfirmRejectionButtonClick}
                        >
                            Ablehnung bestätigen
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            outline
                            type="button"
                            icon={<CloseRounded />}
                            disabled={isConfirming}
                            onClick={handleRejectButtonClick}
                        >
                            Abweisen
                        </Button>
                        <Button
                            type="button"
                            icon={<CheckRounded />}
                            isLoading={isConfirming}
                            disabled={isConfirming}
                            onClick={handleApproveButtonClick}
                        >
                            Annehmen
                        </Button>
                    </>
                )}
            </OuterBoxButtons>
        </>
    );
};

DriverLicenseDistanceImageReview.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onApprove: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
};

export default DriverLicenseDistanceImageReview;
