const withoutGroup = groupId => prevGroups => {
    const index = prevGroups.findIndex(group => group.id === groupId);

    if (index !== -1) {
        const deletedGroup = prevGroups[index];

        const groups = [...prevGroups];

        groups.splice(index, 1);

        return groups.map(group => {
            if (group.position > deletedGroup.position) {
                return {
                    ...group,
                    position: group.position - 1,
                };
            }

            return group;
        });
    }

    return prevGroups;
};

export default withoutGroup;
