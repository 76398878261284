import React from 'react';
import styled from 'styled-components/macro';
import { InputLabel as MaterialInputLabel } from '@material-ui/core';
import colors from 'Common/constants/colors';

const InputLabel = styled(MaterialInputLabel)`
    &.MuiFormLabel-root {
        color: ${colors.GRAY};
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        line-height: 14px;

        &.Mui-focused {
            color: ${colors.GRAY};
            z-index: 1000;
        }

        &.Mui-error {
            color: ${colors.ERROR};
        }

        &.Mui-disabled {
            color: ${colors.SEARCH_FIELD_BORDER};
        }
    }

    &.MuiInputLabel-filled {
        &.MuiInputLabel-shrink {
            transform: translate(12px, 9px) scale(0.75);
        }

        &:not(.MuiInputLabel-shrink):not(.Mui-error) {
            .required-asterisk {
                pointer-events: all;
            }
        }
    }
`;

const StyledInputLabel = ({ ...props }) => (
    <InputLabel
        variant="filled"
        classes={{
            root: 'MuiInputLabel-formControl',
        }}
        {...props}
    />
);

export default StyledInputLabel;
