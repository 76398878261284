import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import colors from 'Common/constants/colors';
import formatDate from 'Common/utils/formatDate';

const IconCircle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: ${prop('background')};
    border-radius: 50%;
    color: ${colors.DIRTY_WHITE};
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.3);

    svg {
        font-size: 21px;
    }
`;

const Point = styled.div`
    width: 11px;
    height: 11px;
    background-color: ${prop('background')};
    border-radius: 50%;
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.3);
`;

const Text = styled.div`
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translate(100%, -50%);
    white-space: nowrap;
`;

const Title = styled.div`
    color: ${colors.GRAY};
    text-transform: uppercase;
`;

const SubTitle = styled.div`
    color: ${colors.GRAY};
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
`;

const Time = styled.div``;

const Wrapper = styled.div`
    position: relative;

    ${ifProp(
        'large',
        css`
            ${Title} {
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
            }

            ${Time} {
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
            }
        `,
        css`
            ${Title} {
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
            }

            ${Time} {
                font-size: 14px;
                line-height: 21px;
            }
        `,
    )};
`;

const TimelinePoint = ({
    title,
    subTitle,
    date,
    color,
    icon: Icon,
    ...props
}) => (
    <Wrapper {...props}>
        {props.large && Icon ? (
            <IconCircle background={color}>
                <Icon />
            </IconCircle>
        ) : (
            <Point background={color} />
        )}
        <Text>
            {subTitle && <SubTitle>{subTitle}</SubTitle>}
            <Title>{title}</Title>
            <Time>{date ? formatDate(date, 'dd MMM, yyyy') : 'Nicht festgelegt'}</Time>
        </Text>
    </Wrapper>
);

TimelinePoint.defaultProps = {
    subTitle: undefined,
    date: undefined,
    icon: undefined,
    large: false,
};

TimelinePoint.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    color: PropTypes.string.isRequired,
    icon: PropTypes.any,
    large: PropTypes.bool,
};

export default TimelinePoint;
