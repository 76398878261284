import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getEmployeeDriverLicenseControl from 'Measure/api/driver-license/getEmployeeDriverLicenseControl';
import { push, replace } from 'connected-react-router';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import modules from 'Common/constants/modules';
import { Redirect } from 'react-router';
import driverLicenseFileTypes from 'Measure/constants/driverLicenseFileTypes';
import DriverLicenseDistanceImageUpload from 'Measure/components/vehicle/driver-license/distance-check/DriverLicenseDistanceImageUpload';
import DriverLicensePasswordVerification from 'Measure/components/vehicle/driver-license/check/DriverLicensePasswordVerification';
import DriverLicenseSignatureVerification from 'Measure/components/vehicle/driver-license/check/DriverLicenseSignatureVerification';
import Title from 'Measure/components/vehicle/driver-license/Common/Title';
import signatureConfirmUploadedFilesForDistanceProcedure from 'Measure/api/driver-license/distance-check/signatureConfirmUploadedFilesForDistanceProcedure';
import passwordConfirmUploadedFilesForDistanceProcedure from 'Measure/api/driver-license/distance-check/passwordConfirmUploadedFilesForDistanceProcedure';
import confirmUploadedFilesForDistanceProcedure from 'Measure/api/driver-license/distance-check/confirmUploadedFilesForDistanceProcedure';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import RejectedFilesOverview from 'Measure/components/vehicle/driver-license/distance-check/RejectedFilesOverview';
import BackButton from 'Application/components/Header/BackButton/BackButton';
import createNewDate from 'Common/utils/createNewDate';
import formatDate from 'Common/utils/formatDate';

const steps = {
    REJECTED_OVERVIEW: 'REJECTED_OVERVIEW',
    FRONT_IMAGE: 'FRONT_IMAGE',
    BACK_IMAGE_WITH_LETTERS: 'BACK_IMAGE_WITH_LETTERS',
    BACK_IMAGE_WITH_SIGNS: 'BACK_IMAGE_WITH_SIGNS',
    PASSWORD_SIGNATURE_VERIFICATION: 'PASSWORD_SIGNATURE_VERIFICATION',
};

const DriverLicenseEmployeeDistanceCheck = () => {
    const dispatch = useDispatch();

    const userId = useSelector(state => state.user.id);

    const isAutomaticSigningAllowed = useSelector(
        state => state.user.isAutomaticSigningAllowed,
    );

    const isUsingPremiumDriverLicenseModule = useSelector(
        state => state.user.isUsingPremiumDriverLicenseModule,
    );

    const [step, setStep] = useState(null);
    const [stepNumbers, setStepNumbers] = useState(null);
    const [stepsCount, setStepsCount] = useState(null);

    const driverLicenseControlRequest = useCallback(
        cancelToken =>
            getEmployeeDriverLicenseControl(
                [
                    'examiners',
                    'employee',
                    'driverLicense',
                    'driverLicense.nextCheck',
                    'driverLicense.nextCheck.previousDistanceProcedure',
                    'driverLicense.nextCheck.previousDistanceProcedure.files',
                    'driverLicense.nextCheck.previousDistanceProcedure.examiner',
                    'driverLicense.nextCheck.distanceProcedure',
                    'driverLicense.nextCheck.distanceProcedure.files',
                    'driverLicense.nextCheck.approvedFilesForDistanceProcedure',
                ],
                cancelToken,
            ),
        [],
    );

    const {
        data: driverLicenseControl,
        loadData: loadDriverLicenseControl,
        isLoading,
        hasError,
        error,
    } = useAxiosRequest(driverLicenseControlRequest, null);

    useEffect(() => {
        const responseStatus = error?.response?.status;

        if (responseStatus === 404) {
            dispatch(replace('/404'));
        }
    }, [dispatch, error]);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // Set the initial step when the driver license control loads
    useEffect(() => {
        if (driverLicenseControl && step === null) {
            const nextCheck = driverLicenseControl?.driverLicense?.nextCheck;
            const currentDistanceProcedure = nextCheck?.distanceProcedure;
            const previousDistanceProcedure =
                nextCheck?.previousDistanceProcedure;

            if (currentDistanceProcedure) {
                if (
                    previousDistanceProcedure &&
                    previousDistanceProcedure.isRejected
                ) {
                    setStep(steps.REJECTED_OVERVIEW);
                } else {
                    const uploadedFileTypes = [
                        ...nextCheck.approvedFilesForDistanceProcedure.map(
                            file => file.type,
                        ),
                        ...currentDistanceProcedure.files.map(
                            file => file.type,
                        ),
                    ];

                    const hasFrontImage = uploadedFileTypes.includes(
                        driverLicenseFileTypes.FRONT_WITH_HOLOGRAM,
                    );

                    const hasBackImageWithLetters = uploadedFileTypes.includes(
                        driverLicenseFileTypes.BACK_WITH_HOLOGRAM_LETTERS,
                    );

                    const hasBackImageWithSigns = uploadedFileTypes.includes(
                        driverLicenseFileTypes.BACK_WITH_HOLOGRAM_SIGNS,
                    );

                    if (!hasFrontImage) {
                        setStep(steps.FRONT_IMAGE);
                    } else if (!hasBackImageWithLetters) {
                        setStep(steps.BACK_IMAGE_WITH_LETTERS);
                    } else if (!hasBackImageWithSigns) {
                        setStep(steps.BACK_IMAGE_WITH_SIGNS);
                    } else if (!nextCheck.isEmployeeSignaturePlaced) {
                        setStep(steps.PASSWORD_SIGNATURE_VERIFICATION);
                    }
                }

                const approvedFileTypes = nextCheck.approvedFilesForDistanceProcedure.map(
                    file => file.type,
                );

                const isFrontImageApproved = approvedFileTypes.includes(
                    driverLicenseFileTypes.FRONT_WITH_HOLOGRAM,
                );

                const isBackImageWithLettersApproved = approvedFileTypes.includes(
                    driverLicenseFileTypes.BACK_WITH_HOLOGRAM_LETTERS,
                );

                const isBackImageWithSignsApproved = approvedFileTypes.includes(
                    driverLicenseFileTypes.BACK_WITH_HOLOGRAM_SIGNS,
                );

                const count =
                    Number(!isFrontImageApproved) +
                    Number(!isBackImageWithLettersApproved) +
                    Number(!isBackImageWithSignsApproved) +
                    Number(!nextCheck.isEmployeeSignaturePlaced);

                setStepsCount(count);
                setStepNumbers({
                    [steps.FRONT_IMAGE]: 1,
                    [steps.BACK_IMAGE_WITH_LETTERS]:
                        2 - Number(isFrontImageApproved),
                    [steps.BACK_IMAGE_WITH_SIGNS]:
                        3 -
                        Number(isFrontImageApproved) -
                        Number(isBackImageWithLettersApproved),
                    [steps.PASSWORD_SIGNATURE_VERIFICATION]: count,
                });
            }
        }
    }, [driverLicenseControl, step]);

    if (isLoading) {
        return <Loader />;
    }

    if (hasError) {
        return <RetryLoading onRetry={loadDriverLicenseControl} />;
    }

    const driverLicense = driverLicenseControl?.driverLicense;
    const nextCheck = driverLicenseControl?.driverLicense?.nextCheck;
    const currentDistanceProcedure = nextCheck?.distanceProcedure;

    const approvedFileTypes = nextCheck.approvedFilesForDistanceProcedure.map(
        file => file.type,
    );

    const isFrontImageApproved = approvedFileTypes.includes(
        driverLicenseFileTypes.FRONT_WITH_HOLOGRAM,
    );

    const isBackImageWithLettersApproved = approvedFileTypes.includes(
        driverLicenseFileTypes.BACK_WITH_HOLOGRAM_LETTERS,
    );

    const isBackImageWithSignsApproved = approvedFileTypes.includes(
        driverLicenseFileTypes.BACK_WITH_HOLOGRAM_SIGNS,
    );

    const handleConfirmRejectedFiles = () => {
        if (!isFrontImageApproved) {
            setStep(steps.FRONT_IMAGE);
        } else if (!isBackImageWithLettersApproved) {
            setStep(steps.BACK_IMAGE_WITH_LETTERS);
        } else if (!isBackImageWithSignsApproved) {
            setStep(steps.BACK_IMAGE_WITH_SIGNS);
        }
    };

    const handleConfirmImage = async isAfterUpload => {
        const areAllOtherFilesUploaded =
            currentDistanceProcedure.files.length + approvedFileTypes.length ===
            2;

        if (areAllOtherFilesUploaded && isAfterUpload) {
            if (!nextCheck.isEmployeeSignaturePlaced) {
                setStep(steps.PASSWORD_SIGNATURE_VERIFICATION);
            } else {
                await confirmUploadedFilesForDistanceProcedure(
                    driverLicenseControl.id,
                    source.token,
                );

                notify(
                    'Führerscheinfotos wurden erfolgreich für die Kontrolle bereitgestellt',
                    {
                        type: toast.TYPE.SUCCESS,
                    },
                );

                dispatch(push(`/dashboard?module=${modules.MEASURES}`));
            }
        } else {
            await loadDriverLicenseControl();

            setStep(currentStep => {
                if (currentStep === steps.FRONT_IMAGE) {
                    if (!isBackImageWithLettersApproved) {
                        return steps.BACK_IMAGE_WITH_LETTERS;
                    } else if (!isBackImageWithSignsApproved) {
                        return steps.BACK_IMAGE_WITH_SIGNS;
                    } else if (!nextCheck.isEmployeeSignaturePlaced) {
                        return steps.PASSWORD_SIGNATURE_VERIFICATION;
                    }
                }

                if (currentStep === steps.BACK_IMAGE_WITH_LETTERS) {
                    if (!isBackImageWithSignsApproved) {
                        return steps.BACK_IMAGE_WITH_SIGNS;
                    } else if (!nextCheck.isEmployeeSignaturePlaced) {
                        return steps.PASSWORD_SIGNATURE_VERIFICATION;
                    }
                }

                if (
                    currentStep === steps.BACK_IMAGE_WITH_SIGNS &&
                    !nextCheck.isEmployeeSignaturePlaced
                ) {
                    return steps.PASSWORD_SIGNATURE_VERIFICATION;
                }

                return currentStep;
            });
        }
    };

    const handleBackButtonClick = () => {
        setStep(currentStep => {
            if (
                currentStep === steps.FRONT_IMAGE &&
                !!nextCheck.previousDistanceProcedure
            ) {
                return steps.REJECTED_OVERVIEW;
            }

            if (currentStep === steps.BACK_IMAGE_WITH_LETTERS) {
                if (!isFrontImageApproved) {
                    return steps.FRONT_IMAGE;
                }

                if (!!nextCheck.previousDistanceProcedure) {
                    return steps.REJECTED_OVERVIEW;
                }
            }

            if (currentStep === steps.BACK_IMAGE_WITH_SIGNS) {
                if (!isBackImageWithLettersApproved) {
                    return steps.BACK_IMAGE_WITH_LETTERS;
                }

                if (!isFrontImageApproved) {
                    return steps.FRONT_IMAGE;
                }

                if (!!nextCheck.previousDistanceProcedure) {
                    return steps.REJECTED_OVERVIEW;
                }
            }

            if (currentStep === steps.PASSWORD_SIGNATURE_VERIFICATION) {
                if (!isBackImageWithSignsApproved) {
                    return steps.BACK_IMAGE_WITH_SIGNS;
                }

                if (!isBackImageWithLettersApproved) {
                    return steps.BACK_IMAGE_WITH_LETTERS;
                }

                if (!isFrontImageApproved) {
                    return steps.FRONT_IMAGE;
                }

                if (!!nextCheck.previousDistanceProcedure) {
                    return steps.REJECTED_OVERVIEW;
                }
            }

            return currentStep;
        });
    };

    const handleConfirmPassword = async password => {
        await passwordConfirmUploadedFilesForDistanceProcedure(
            driverLicenseControl.id,
            password,
            source.token,
        );

        dispatch(push(`/dashboard?module=${modules.MEASURES}`));
    };

    const handleConfirmSignature = async blob => {
        const date = createNewDate();
        const file = new File(
            [blob],
            `DriverLicenseSignature_Employee_${userId}_${formatDate(
                date,
                'yyyy-MM-dd_H-mm-ss',
            )}.png`,
            {
                type: 'image/png',
                lastModified: date,
            },
        );

        await signatureConfirmUploadedFilesForDistanceProcedure(
            driverLicenseControl.id,
            file,
            source.token,
        );

        dispatch(push(`/dashboard?module=${modules.MEASURES}`));
    };

    if (
        driverLicenseControl &&
        driverLicenseControl.isActive &&
        driverLicense &&
        driverLicense.isUsingDistanceControlMethod &&
        !driverLicense.isInCreationProcess &&
        !driverLicense.isExpired &&
        !driverLicense.isLost &&
        driverLicense.nextCheck.isInVerificationPeriod &&
        driverLicense.nextCheck.distanceProcedure.isPendingEmployeeFileUpload
    ) {
        const hint = (
            <>
                Hinweis: Hiermit bestätigen Sie, dass Sie Ihren im Original
                vorliegenden Führerschein einer der Ihnen zugewiesenen Prüfer
                über den Amedic Manager (Fotos) zur Verfügung gestellt haben.
            </>
        );

        const stepsCounter = `${stepNumbers[step]}/${stepsCount} Führerscheinkontrolle`;

        return (
            <>
                <Helmet>
                    <title>Prüfung - Führerscheinkontrolle - Maßnahmen</title>
                </Helmet>
                <BackButton to="/measures/vehicles/driver-license/overview" />
                <Breadcrumb to={`/dashboard?module=${modules.MEASURES}`}>
                    Maßnahmen
                </Breadcrumb>
                <Breadcrumb to="/measures/vehicles/driver-license/overview">
                    Führerscheinkontrolle
                </Breadcrumb>
                <Breadcrumb isActive>Prüfung</Breadcrumb>
                {step === steps.REJECTED_OVERVIEW && (
                    <RejectedFilesOverview
                        driverLicenseControl={driverLicenseControl}
                        onConfirm={handleConfirmRejectedFiles}
                    />
                )}
                {step === steps.FRONT_IMAGE && (
                    <DriverLicenseDistanceImageUpload
                        type={driverLicenseFileTypes.FRONT_WITH_HOLOGRAM}
                        driverLicenseControl={driverLicenseControl}
                        title={stepsCounter}
                        onConfirm={handleConfirmImage}
                        onBackButtonClick={
                            !!nextCheck.previousDistanceProcedure
                                ? handleBackButtonClick
                                : undefined
                        }
                        shouldPreProcessImage={
                            isUsingPremiumDriverLicenseModule
                        }
                    />
                )}
                {step === steps.BACK_IMAGE_WITH_LETTERS && (
                    <DriverLicenseDistanceImageUpload
                        type={driverLicenseFileTypes.BACK_WITH_HOLOGRAM_LETTERS}
                        driverLicenseControl={driverLicenseControl}
                        title={stepsCounter}
                        onConfirm={handleConfirmImage}
                        onBackButtonClick={
                            !isFrontImageApproved ||
                            !!nextCheck.previousDistanceProcedure
                                ? handleBackButtonClick
                                : undefined
                        }
                        shouldPreProcessImage={
                            isUsingPremiumDriverLicenseModule
                        }
                    />
                )}
                {step === steps.BACK_IMAGE_WITH_SIGNS && (
                    <DriverLicenseDistanceImageUpload
                        type={driverLicenseFileTypes.BACK_WITH_HOLOGRAM_SIGNS}
                        driverLicenseControl={driverLicenseControl}
                        title={stepsCounter}
                        onConfirm={handleConfirmImage}
                        onBackButtonClick={
                            !isFrontImageApproved ||
                            !isBackImageWithLettersApproved ||
                            !!nextCheck.previousDistanceProcedure
                                ? handleBackButtonClick
                                : undefined
                        }
                        shouldPreProcessImage={
                            isUsingPremiumDriverLicenseModule
                        }
                    />
                )}
                {step === steps.PASSWORD_SIGNATURE_VERIFICATION && (
                    <>
                        <Title>{stepsCounter}</Title>
                        {isAutomaticSigningAllowed ? (
                            <DriverLicensePasswordVerification
                                confirmButtonText="Unterschrift übernehmen"
                                onConfirm={handleConfirmPassword}
                                onBackButtonClick={
                                    !isFrontImageApproved ||
                                    !isBackImageWithLettersApproved ||
                                    !isBackImageWithSignsApproved ||
                                    !!nextCheck.previousDistanceProcedure
                                        ? handleBackButtonClick
                                        : undefined
                                }
                                hint={hint}
                                successMessage="Führerscheinfotos wurden erfolgreich für die Kontrolle bereitgestellt"
                            />
                        ) : (
                            <DriverLicenseSignatureVerification
                                confirmButtonText="Unterschrift übernehmen"
                                onConfirm={handleConfirmSignature}
                                onBackButtonClick={
                                    !isFrontImageApproved ||
                                    !isBackImageWithLettersApproved ||
                                    !isBackImageWithSignsApproved ||
                                    !!nextCheck.previousDistanceProcedure
                                        ? handleBackButtonClick
                                        : undefined
                                }
                                hint={hint}
                                successMessage="Führerscheinfotos wurden erfolgreich für die Kontrolle bereitgestellt"
                            />
                        )}
                    </>
                )}
            </>
        );
    }

    return <Redirect to={`/dashboard?module=${modules.MEASURES}`} />;
};

export default DriverLicenseEmployeeDistanceCheck;
