import { createGlobalStyle } from 'styled-components';
import colors from 'Common/constants/colors';

const GlobalStyles = createGlobalStyle`
  html {
    padding-bottom: 30px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background: ${colors.PAGE_BACKGROUND};
    color: ${colors.DIRTY_WHITE};
    font-size: 16px;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-button:start:decrement,
  ::-webkit-scrollbar-button:end:increment {
    display: none;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: ${colors.PAGE_BACKGROUND};
    -webkit-border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.DARK_GRAY};
    -webkit-border-radius: 6px;
  }

  ::-ms-clear {
    display: none;
  }

  ::-ms-reveal {
    display: none;
  }

  button::-moz-focus-inner {
    border: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
  }

  b, strong {
    font-weight: 600;
  }

  * {
    font-family: 'Poppins', sans-serif;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fadeIn {
    animation-name: fadeIn;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  .fadeOut {
    animation-name: fadeOut;
  }

  .Toastify {
    .Toastify__toast-container {
      width: calc(100% - 40px);
      max-width: 400px;
      z-index: 99999;
      -webkit-transform: translate3d(0, 0, 99999px);

      @media only screen and (max-width: 480px) {
        &--top-right {
          top: 1em;
          right: auto;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .Toastify__toast {
      padding: 12px 50px 12px 62px;
      border-radius: 6px;
    }

    .Toastify__toast--error {
      background: ${colors.ERROR};
    }

    .Toastify__toast--warning {
      background: ${colors.WARNING};
    }

    .Toastify__toast--success {
      background: ${colors.GREEN};
    }

    .Toastify__toast--info {
      background: ${colors.BLUE};
    }

    .Toastify__toast-body > svg {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }

    .close-button {
      position: absolute;
      top: 0;
      right: 0;
      color: ${colors.DIRTY_WHITE};
      transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0.7;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  .MuiTooltip-popper {
    z-index: 99999 !important;

    .MuiTooltip-tooltip {
      background-color: rgba(94, 94, 94, 0.9);
      color: ${colors.DIRTY_WHITE};
      font-family: 'Poppins', sans-serif;
      font-size: 11px;
    }
  }

  .MuiPaper-root {
    color: inherit !important;
  }

  .MuiPopover-root {
    z-index: 9999 !important;
  }

  .ReactVirtualized__List {
    outline: 0 none;
  }

  [data-rmiz-btn-close] {
    &:focus {
      outline: 0 none;
    }
  }

  .react-datepicker-wrapper {
    display: block;
  }

  .react-datepicker-popper {
    z-index: 9999;
  }

  .react-datepicker {
    border: 0 none;
    border-radius: 6px;
    box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.4);
    background-color: ${colors.WHITE};
    color: ${colors.DARK_GRAY};
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 21px;

    &__triangle {
      display: none;
    }

    &__header {
      padding: 15px 5px 0;
      border-bottom: 1px solid ${colors.LIGHT_DIVIDER};
      border-radius: 6px 6px 0 0;
      background-color: ${colors.DIRTY_WHITE};
    }

    &__day-names {
      margin-top: 10px;
    }

    &__day-name,
    &__day {
      width: 40px;
      color: ${colors.DARK_GRAY};
    }

    &__day {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 40px;

      &:focus {
        outline: 0 none;
      }

      &--outside-month {
        color: ${colors.LIGHT_GRAY};
      }

      &:hover,
      &--keyboard-selected {
        border-radius: 6px;
        background-color: ${colors.DIRTY_WHITE};
      }

      &--in-range {
        border-radius: 6px;
        background-color: ${colors.PRIMARY_LIGHT};
        color: ${colors.DIRTY_WHITE};
      }

      &--selected,
      &--range-start,
      &--range-end {
        border-radius: 6px;
        background-color: ${colors.PRIMARY};
        color: ${colors.DIRTY_WHITE};
      }

      &--disabled {
        color: ${colors.LIGHT_DIVIDER};

        &:hover {
          background-color: transparent;
          color: ${colors.LIGHT_DIVIDER};
        }
      }
    }

    &__month {
      &--selecting-range {
        .react-datepicker__day {
          &--in-range {
            background-color: transparent;
            color: inherit;
          }

          &--in-selecting-range {
            border-radius: 6px;
            background-color: ${colors.PRIMARY_LIGHT};
            color: ${colors.DIRTY_WHITE};
          }

          &--selecting-range-start,
          &--selecting-range-end,
          &--selecting-range-start:hover,
          &--selecting-range-end:hover {
            border-radius: 6px;
            background-color: ${colors.PRIMARY};
            color: ${colors.DIRTY_WHITE};
          }
        }
      }
    }
  }
  
  #root-portal {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
  }
`;

export default GlobalStyles;
