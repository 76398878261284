import React from 'react';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import StatisticsNumber from 'ProjectManager/Project/Preview/Statistics/StatisticsNumber';
import SideBySideStatistics from 'ProjectManager/Project/Preview/Statistics/SideBySideStatistics';
import visibleQuestionFilesCountSelector from 'ProjectManager/Project/Common/recoil/project/selectors/visibleQuestionFilesCountSelector';

const Statistics = props => {
    const { files } = useRecoilValue(projectAtom);

    const generalProjectFilesCount = files.length;
    const questionFilesCount = useRecoilValue(visibleQuestionFilesCountSelector);

    return (
        <SideBySideStatistics {...props}>
            <StatisticsNumber
                number={generalProjectFilesCount}
                text="Projektdateien"
            />
            <StatisticsNumber
                number={questionFilesCount}
                text="Fragendateien"
            />
        </SideBySideStatistics>
    );
};

export default Statistics;
