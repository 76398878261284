import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import createProjectCustomQuestion from 'ProjectManager/Project/Common/api/dataManagement/questionnaire/question/createProjectCustomQuestion';
import withAdditionalCustomQuestion from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/withAdditionalCustomQuestion';

const useCreateProjectCustomQuestionFunction = subsectionId => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const createNewCustomQuestion = useCallback(
        async values => {
            const response = await createProjectCustomQuestion(
                projectId,
                subsectionId,
                values,
                source.token,
            );

            const newCustomQuestion = response.data;

            setProject(
                withAdditionalCustomQuestion(newCustomQuestion, subsectionId),
            );
        },
        [projectId, subsectionId, setProject, source.token],
    );

    return createNewCustomQuestion;
};

export default useCreateProjectCustomQuestionFunction;
