import api from 'Application/api/api';

const addContactPeopleToClient = (id, contactPeople, cancelToken = null) =>
    api.post(
        `project-manager/clients/${id}/contact-people/add`,
        contactPeople,
        { cancelToken },
    );

export default addContactPeopleToClient;
