import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import colors from 'Common/constants/colors';
import { ifProp } from 'styled-tools';
import { compact } from 'lodash';
import Loader from 'Common/components/Loader';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';

const CommonButton = styled.button.withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => {
        if (prop === 'dark') {
            return false;
        }

        return defaultValidatorFn(prop);
    },
})`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: 0 none;
    cursor: pointer;
    text-decoration: none;
    user-select: none;

    &[disabled] {
        opacity: 0.35;
        pointer-events: none;
    }
`;

const TextNextToIcon = styled.span`
    text-align: left;
`;

const BoxedButton = styled(CommonButton)`
    border-radius: 6px;
    transition: background 150ms cubic-bezier(0.4, 0, 0.2, 1),
        color 150ms cubic-bezier(0.4, 0, 0.2, 1),
        border-color 150ms cubic-bezier(0.4, 0, 0.2, 1);

    ${ifProp(
        'small',
        css`
            min-height: 36px;
            padding: 5px 20px;
            font-size: 16px;
            line-height: 25px;
        `,
        css`
            min-height: 50px;
            padding: 10px 15px;
            font-size: 17px;
            line-height: 26px;

            @media screen and (min-width: 375px) {
                padding-left: 25px;
                padding-right: 25px;
            }
        `,
    )};

    ${TextNextToIcon} {
        &:first-child {
            margin-right: 8px;
        }

        &:last-child {
            margin-left: 8px;
        }
    }
`;

const PrimaryButton = styled(BoxedButton)`
    background: ${colors.PRIMARY};
    border: 0 none;
    color: ${colors.DIRTY_WHITE};

    &:hover,
    &.is-active {
        background: ${colors.PRIMARY_LIGHT};
    }

    &[disabled] &:hover,
    &[disabled] &.is-active {
        background: ${colors.PRIMARY};
    }
`;

const PrimaryButtonAsReactRouterLink = styled(PrimaryButton).attrs({
    as: Link,
})``;

const PrimaryButtonAsHtmlAnchor = styled(PrimaryButton).attrs({
    as: 'a',
})``;

const OutlineButton = styled(BoxedButton)`
    background: none;
    border: 2px solid;

    ${ifProp(
        'dark',
        css`
            border-color: ${colors.DARK_GRAY};
            color: ${colors.DARK_GRAY};

            &:hover,
            &.is-active {
                background: ${colors.DARK_GRAY};
                color: ${colors.DIRTY_WHITE};
            }

            &[disabled] &:hover,
            &[disabled] &.is-active {
                background: none;
                color: ${colors.DARK_GRAY};
            }
        `,
        css`
            border-color: ${colors.DIRTY_WHITE};
            color: ${colors.DIRTY_WHITE};

            &:hover,
            &.is-active {
                background: ${colors.DIRTY_WHITE};
                color: ${colors.DARK_GRAY};
            }

            &[disabled] &:hover,
            &[disabled] &.is-active {
                background: none;
                color: ${colors.DIRTY_WHITE};
            }
        `,
    )};
`;

const OutlineButtonAsReactRouterLink = styled(OutlineButton).attrs({
    as: Link,
})``;

const OutlineButtonAsHtmlAnchor = styled(OutlineButton).attrs({
    as: 'a',
})``;

const BorderlessButton = styled(CommonButton)`
    background: none;
    border: 0 none;

    ${ifProp(
        'dark',
        css`
            color: ${colors.DARK_GRAY};

            &:hover,
            &.is-active {
                color: ${colors.DARK_DIVIDER};
            }

            &[disabled] &:hover,
            &[disabled] &.is-active {
                color: ${colors.DARK_GRAY};
            }
        `,
        css`
            color: ${colors.DIRTY_WHITE};

            &:hover,
            &.is-active {
                color: ${colors.WHITE};
            }

            &[disabled] &:hover,
            &[disabled] &.is-active {
                color: ${colors.WHITE};
            }
        `,
    )};
`;

const UnderlineButton = styled(BorderlessButton)`
    align-items: flex-start;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-decoration: underline;

    > svg {
        font-size: 19px !important;
    }

    ${TextNextToIcon} {
        &:first-child {
            margin-right: 5px;
        }

        &:last-child {
            margin-left: 5px;
        }
    }
`;

const UnderlineButtonAsReactRouterLink = styled(UnderlineButton).attrs({
    as: Link,
})``;

const UnderlineButtonAsHtmlAnchor = styled(UnderlineButton).attrs({
    as: 'a',
})``;

const TextButton = styled(BorderlessButton)`
    font-size: 16px;
    line-height: 25px;

    ${ifProp(
        'small',
        css`
            min-height: 36px;
            padding: 5px 20px;
        `,
        css`
            min-height: 50px;
            padding: 10px 15px;

            @media screen and (min-width: 375px) {
                padding-left: 25px;
                padding-right: 25px;
            }
        `,
    )};

    ${TextNextToIcon} {
        &:first-child {
            margin-right: 8px;
        }

        &:last-child {
            margin-left: 8px;
        }
    }
`;

const TextButtonAsReactRouterLink = styled(TextButton).attrs({
    as: Link,
})``;

const TextButtonAsHtmlAnchor = styled(TextButton).attrs({
    as: 'a',
})``;

const IconButton = styled(BorderlessButton)`
    padding: 8px;
`;

const IconButtonAsReactRouterLink = styled(IconButton).attrs({
    as: Link,
})``;

const IconButtonAsHtmlAnchor = styled(IconButton).attrs({
    as: 'a',
})``;

const DisabledButtonWrapper = styled.span`
    display: inline-flex;
`;

const Button = ({
    outline,
    underline,
    text,
    iconOnly,
    icon,
    iconPosition,
    isLoading,
    isActive,
    tooltip,
    loaderProps,
    children,
    innerRef,
    className,
    ...props
}) => {
    // The compact function will remove all the falsy values from the array of boolean style props.
    // Only one such prop is allowed to be true at the same time, so an error will be thrown otherwise.
    if (compact([outline, underline, text, iconOnly]).length > 1) {
        throw new Error(
            'Not clear which button style to use. Please use only one of the following: outline, underline, text, iconOnly',
        );
    }

    let isReactRouterLink = !!props.to;
    let isHtmlAnchor = !!props.href;

    let loaderSize = 24;
    let ButtonComponent;

    if (isReactRouterLink) {
        ButtonComponent = PrimaryButtonAsReactRouterLink;
    } else if (isHtmlAnchor) {
        ButtonComponent = PrimaryButtonAsHtmlAnchor;
    } else {
        ButtonComponent = PrimaryButton;
    }

    if (outline) {
        if (isReactRouterLink) {
            ButtonComponent = OutlineButtonAsReactRouterLink;
        } else if (isHtmlAnchor) {
            ButtonComponent = OutlineButtonAsHtmlAnchor;
        } else {
            ButtonComponent = OutlineButton;
        }
    }

    if (underline) {
        loaderSize = 19;

        if (isReactRouterLink) {
            ButtonComponent = UnderlineButtonAsReactRouterLink;
        } else if (isHtmlAnchor) {
            ButtonComponent = UnderlineButtonAsHtmlAnchor;
        } else {
            ButtonComponent = UnderlineButton;
        }
    }

    if (text) {
        if (isReactRouterLink) {
            ButtonComponent = TextButtonAsReactRouterLink;
        } else if (isHtmlAnchor) {
            ButtonComponent = TextButtonAsHtmlAnchor;
        } else {
            ButtonComponent = TextButton;
        }
    }

    if (iconOnly) {
        if (isReactRouterLink) {
            ButtonComponent = IconButtonAsReactRouterLink;
        } else if (isHtmlAnchor) {
            ButtonComponent = IconButtonAsHtmlAnchor;
        } else {
            ButtonComponent = IconButton;
        }
    }

    let renderedIcon = icon ?? null;

    if (isLoading) {
        renderedIcon = (
            <Loader
                size={loaderSize}
                color={props.dark ? colors.DARK_GRAY : colors.DIRTY_WHITE}
                {...loaderProps}
            />
        );
    }

    const hasIcon = !!renderedIcon;

    const classNames = className ? className.split(' ') : [];

    if (isActive) {
        classNames.push('is-active');
    }

    const renderedButton = (
        <ButtonComponent
            ref={innerRef}
            className={classNames.join(' ')}
            {...props}
        >
            {hasIcon ? (
                iconOnly ? (
                    renderedIcon
                ) : (
                    <>
                        {iconPosition === 'left' && renderedIcon}
                        <TextNextToIcon>{children}</TextNextToIcon>
                        {iconPosition === 'right' && renderedIcon}
                    </>
                )
            ) : (
                children
            )}
        </ButtonComponent>
    );

    if (tooltip) {
        const tooltipProps =
            typeof tooltip === 'string'
                ? {
                      title: tooltip,
                  }
                : tooltip;

        if (props.disabled) {
            // By default a disabled <button> will not trigger user interactions,
            // so a Tooltip will not activate on normal events like hover.
            // To accommodate the disabled button, it is wrapped in a <span>.
            return (
                <Tooltip {...tooltipProps}>
                    <DisabledButtonWrapper>
                        {renderedButton}
                    </DisabledButtonWrapper>
                </Tooltip>
            );
        }

        return <Tooltip {...tooltipProps}>{renderedButton}</Tooltip>;
    }

    return renderedButton;
};

Button.defaultProps = {
    outline: false,
    underline: false,
    text: false,
    iconOnly: false,
    icon: undefined,
    iconPosition: 'left',
    isLoading: false,
    isActive: false,
    dark: false,
    small: false,
    to: undefined,
    href: undefined,
    tooltip: undefined,
    loaderProps: {},
};

Button.propTypes = {
    outline: PropTypes.bool,
    underline: PropTypes.bool,
    text: PropTypes.bool,
    iconOnly: PropTypes.bool,
    icon: PropTypes.any,
    iconPosition: PropTypes.string,
    isLoading: PropTypes.bool,
    isActive: PropTypes.bool,
    dark: PropTypes.bool,
    small: PropTypes.bool,
    to: PropTypes.string,
    href: PropTypes.string,
    tooltip: PropTypes.any,
    loaderProps: PropTypes.object,
};

export default Button;
