import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import getDriverLicenseControl from 'Measure/api/driver-license/getDriverLicenseControl';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import { replace } from 'connected-react-router';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';
import isAuthorized from 'User/utils/isAuthorized';
import roles from 'User/constants/roles';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import modules from 'Common/constants/modules';
import axios from 'axios';
import passwordVerifyAuthenticityForDirectProcedure from 'Measure/api/driver-license/direct-check/passwordVerifyAuthenticityForDirectProcedure';
import signatureVerifyAuthenticityForDirectProcedure from 'Measure/api/driver-license/direct-check/signatureVerifyAuthenticityForDirectProcedure';
import LicenseImage from 'Measure/components/vehicle/driver-license/create/LicenseImage';
import styled from 'styled-components/macro';
import driverLicenseFileTypes from 'Measure/constants/driverLicenseFileTypes';
import DriverLicensePasswordVerification from 'Measure/components/vehicle/driver-license/check/DriverLicensePasswordVerification';
import DriverLicenseSignatureVerification from 'Measure/components/vehicle/driver-license/check/DriverLicenseSignatureVerification';
import Title from 'Measure/components/vehicle/driver-license/Common/Title';
import EmployeeConfirmationFromExaminerAccount from 'Measure/components/vehicle/driver-license/direct-check/EmployeeConfirmationFromExaminerAccount';
import OuterBox from 'Common/components/Boxes/OuterBox';
import BackButton from 'Application/components/Header/BackButton/BackButton';
import OuterBoxButtons from 'Common/components/Boxes/OuterBoxButtons';
import Button from 'Common/components/Button';
import createNewDate from 'Common/utils/createNewDate';
import formatDate from 'Common/utils/formatDate';
import parseDate from 'Common/utils/parseDate';

const SideBySideContent = styled.div`
    > div {
        margin-bottom: 10px;
        padding: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 940px) {
        display: flex;
        min-height: 320px;
        padding: 0;
        background: none;

        > div {
            flex: 1;
            width: calc(50% - 5px);
            margin-right: 10px;
            margin-bottom: 0;
            padding: 40px 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    @media screen and (min-width: 1180px) {
        > div {
            padding-left: 40px;
            padding-right: 40px;
        }
    }
`;

const Content = styled(OuterBox)`
    margin-bottom: 10px;

    @media screen and (min-width: 620px) {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 0;
    }
`;

const steps = {
    IMAGES_VERIFICATION: 'IMAGES_VERIFICATION',
    PASSWORD_SIGNATURE_VERIFICATION: 'PASSWORD_SIGNATURE_VERIFICATION',
};

const DriverLicenseExaminerDirectCheck = () => {
    const dispatch = useDispatch();

    const userId = useSelector(state => state.user.id);

    const isUsingOnlyNewModules = useSelector(
        state => state.user.isUsingOnlyNewModules,
    );

    const isAutomaticSigningAllowed = useSelector(
        state => state.user.isAutomaticSigningAllowed,
    );

    const { id: driverLicenseControlId } = useParams();

    const [step, setStep] = useState(steps.IMAGES_VERIFICATION);
    const [isConfirmed, setIsConfirmed] = useState(false);

    const driverLicenseControlRequest = useCallback(
        cancelToken =>
            getDriverLicenseControl(
                driverLicenseControlId,
                [
                    'examiners',
                    'employee',
                    'employee.user',
                    'driverLicense',
                    'driverLicense.files',
                    'driverLicense.nextCheck',
                    'driverLicense.nextCheck.directProcedure',
                ],
                cancelToken,
            ),
        [driverLicenseControlId],
    );

    const {
        data: driverLicenseControl,
        loadData: loadDriverLicenseControl,
        isLoading,
        hasError,
        error,
    } = useAxiosRequest(driverLicenseControlRequest, null);

    useEffect(() => {
        if (error?.response?.status === 404) {
            dispatch(replace('/404'));
        }
    }, [dispatch, error]);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    if (isLoading) {
        return <Loader />;
    }

    if (hasError) {
        return <RetryLoading onRetry={loadDriverLicenseControl} />;
    }

    const handleConfirmPassword = async password => {
        await passwordVerifyAuthenticityForDirectProcedure(
            driverLicenseControl.id,
            password,
            source.token,
        );

        setIsConfirmed(true);
    };

    const handleConfirmSignature = async blob => {
        const date = createNewDate();
        const file = new File(
            [blob],
            `DriverLicenseSignature_Examiner_${userId}_${formatDate(
                date,
                'yyyy-MM-dd_H-mm-ss',
            )}.png`,
            {
                type: 'image/png',
                lastModified: date,
            },
        );

        await signatureVerifyAuthenticityForDirectProcedure(
            driverLicenseControl.id,
            file,
            source.token,
        );

        setIsConfirmed(true);
    };

    const handleBackButtonClick = () => {
        setStep(steps.IMAGES_VERIFICATION);
    };

    const driverLicense = driverLicenseControl?.driverLicense;

    const isExaminerAuthorizedToExamine =
        isAuthorized([roles.MANAGER]) ||
        driverLicenseControl.examiners.findIndex(
            examiner => examiner.id === userId,
        ) !== -1;

    if (
        driverLicenseControl &&
        driverLicenseControl.isActive &&
        driverLicense &&
        driverLicense.isUsingDirectControlMethod &&
        !driverLicense.isInCreationProcess &&
        !driverLicense.isExpired &&
        !driverLicense.isLost &&
        driverLicense.nextCheck.isInVerificationPeriod &&
        driverLicense.nextCheck.directProcedure.isPendingExaminerVerification &&
        isExaminerAuthorizedToExamine
    ) {
        const breadcrumbs = isAuthorized([roles.MANAGER]) ? (
            isUsingOnlyNewModules ? (
                <>
                    <Helmet>
                        <title>
                            {`Prüfung - ${driverLicenseControl.employee.lastName}, ${driverLicenseControl.employee.firstName} - Führerscheinkontrolle`}
                        </title>
                    </Helmet>
                    <BackButton
                        to={`/measures/vehicles/driver-license/overview/${driverLicenseControl.id}`}
                    />
                    <Breadcrumb to="/measures/vehicles/driver-licenses">
                        Führerscheinkontrolle
                    </Breadcrumb>
                    <Breadcrumb
                        to={`/measures/vehicles/driver-license/overview/${driverLicenseControl.id}`}
                    >{`${driverLicenseControl.employee.lastName}, ${driverLicenseControl.employee.firstName}`}</Breadcrumb>
                    <Breadcrumb isActive>Prüfung</Breadcrumb>
                </>
            ) : (
                <>
                    <Helmet>
                        <title>
                            {`Prüfung - ${driverLicenseControl.employee.lastName}, ${driverLicenseControl.employee.firstName} - Führerscheinkontrolle - Fuhrpark - Maßnahmen`}
                        </title>
                    </Helmet>
                    <BackButton
                        to={`/measures/vehicles/driver-license/overview/${driverLicenseControl.id}`}
                    />
                    <Breadcrumb to="/measures">Maßnahmen</Breadcrumb>
                    <Breadcrumb to="/measures/vehicles">Fuhrpark</Breadcrumb>
                    <Breadcrumb to="/measures/vehicles/driver-licenses">
                        Führerscheinkontrolle
                    </Breadcrumb>
                    <Breadcrumb
                        to={`/measures/vehicles/driver-license/overview/${driverLicenseControl.id}`}
                    >{`${driverLicenseControl.employee.lastName}, ${driverLicenseControl.employee.firstName}`}</Breadcrumb>
                    <Breadcrumb isActive>Prüfung</Breadcrumb>
                </>
            )
        ) : (
            <>
                <Helmet>
                    <title>
                        {`Prüfung - ${driverLicenseControl.employee.lastName}, ${driverLicenseControl.employee.firstName} - Führerscheinkontrolle - Maßnahmen`}
                    </title>
                </Helmet>
                <BackButton
                    to={`/measures/vehicles/driver-license/overview/${driverLicenseControl.id}`}
                />
                <Breadcrumb to={`/dashboard?module=${modules.MEASURES}`}>
                    Maßnahmen
                </Breadcrumb>
                <Breadcrumb to="/measures/vehicles/driver-licenses">
                    Führerscheinkontrolle
                </Breadcrumb>
                <Breadcrumb
                    to={`/measures/vehicles/driver-license/overview/${driverLicenseControl.id}`}
                >{`${driverLicenseControl.employee.lastName}, ${driverLicenseControl.employee.firstName}`}</Breadcrumb>
                <Breadcrumb isActive>Prüfung</Breadcrumb>
            </>
        );

        const frontImageWithHologram = driverLicense.files.find(
            file => file.type === driverLicenseFileTypes.FRONT_WITH_HOLOGRAM,
        );

        const backImageWithHologram = driverLicense.files.find(
            file => file.type === driverLicenseFileTypes.BACK_WITH_HOLOGRAM,
        );

        const hint = (
            <>
                Hinweis: Hiermit bestätigen Sie den ordnungsgemäßen Abgleich des
                Führerscheins
            </>
        );

        const confirmButtonText = 'Unterschrift übernehmen';

        const title =
            step === steps.IMAGES_VERIFICATION
                ? '1/2 Führerscheinkontrolle'
                : '2/2 Führerscheinkontrolle';

        return (
            <>
                {breadcrumbs}
                <Title>{title}</Title>
                {step === steps.IMAGES_VERIFICATION && (
                    <>
                        <SideBySideContent>
                            <Content>
                                {!!frontImageWithHologram && (
                                    <LicenseImage
                                        title="Führerscheinvorderseite"
                                        src={frontImageWithHologram.file}
                                        watermarkDate={parseDate(
                                            frontImageWithHologram.uploadedAt,
                                        )}
                                    />
                                )}
                            </Content>
                            <Content>
                                {!!backImageWithHologram && (
                                    <LicenseImage
                                        title="Führerscheinrückseite"
                                        src={backImageWithHologram.file}
                                        watermarkDate={parseDate(
                                            backImageWithHologram.uploadedAt,
                                        )}
                                    />
                                )}
                            </Content>
                        </SideBySideContent>
                        <OuterBoxButtons>
                            <Button
                                type="button"
                                onClick={() =>
                                    setStep(
                                        steps.PASSWORD_SIGNATURE_VERIFICATION,
                                    )
                                }
                            >
                                Annehmen
                            </Button>
                        </OuterBoxButtons>
                    </>
                )}
                {isConfirmed ? (
                    <EmployeeConfirmationFromExaminerAccount
                        driverLicenseControl={driverLicenseControl}
                    />
                ) : (
                    step === steps.PASSWORD_SIGNATURE_VERIFICATION &&
                    (isAutomaticSigningAllowed ? (
                        <DriverLicensePasswordVerification
                            hint={hint}
                            confirmButtonText={confirmButtonText}
                            onConfirm={handleConfirmPassword}
                            onBackButtonClick={handleBackButtonClick}
                        />
                    ) : (
                        <DriverLicenseSignatureVerification
                            hint={hint}
                            confirmButtonText={confirmButtonText}
                            onConfirm={handleConfirmSignature}
                            onBackButtonClick={handleBackButtonClick}
                        />
                    ))
                )}
            </>
        );
    }

    return <Redirect to="/measures/vehicles/driver-licenses" />;
};

export default DriverLicenseExaminerDirectCheck;
