import { useRecoilValue } from 'recoil';
import areAllProjectStepsCompletedSelector from 'ProjectManager/Project/Common/recoil/project/selectors/areAllProjectStepsCompletedSelector';

const useAreAllProjectStepsCompleted = () => {
    // noinspection UnnecessaryLocalVariableJS
    const areAllStepsCompleted = useRecoilValue(areAllProjectStepsCompletedSelector);

    return areAllStepsCompleted;
};

export default useAreAllProjectStepsCompleted;
