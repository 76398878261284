import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Button from 'Common/components/Button';
import AddTopic from 'Common/components/Icons/AddTopic';
import CreateSubsectionForm from 'ProjectManager/DataManagement/Questionnaire/components/SubsectionCreation/components/CreateSubsectionForm';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';

const FormWrapper = styled.div`
    width: 100%;
    max-width: 420px;
`;

const CreateSubsectionButton = ({ section, onFormOpen }) => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);
    const isProject = dataManagementEntity === dataManagementEntities.PROJECT;

    const { status: projectStatus } = useRecoilValue(projectAtom);
    const isCreatingPossible = !isProject || projectStatus !== projectStatuses.ENDED;

    const [isFormOpened, setIsFormOpened] = useState(false);

    const handleCreateButtonClick = () => {
        setIsFormOpened(true);
        onFormOpen();
    };

    const closeForm = () => {
        setIsFormOpened(false);
    };

    if (isFormOpened) {
        return (
            <FormWrapper>
                <CreateSubsectionForm
                    section={section}
                    onCancel={closeForm}
                    onSave={closeForm}
                />
            </FormWrapper>
        );
    }

    return (
        <Button
            underline
            icon={<AddTopic />}
            type="button"
            onClick={handleCreateButtonClick}
            disabled={!isCreatingPossible}
            tooltip={
                !isCreatingPossible
                    ? 'Erstellung nicht erlaubt. Projekt ist im Status Beendet.'
                    : ''
            }
        >
            Unterbereich erstellen
        </Button>
    );
};

CreateSubsectionButton.propTypes = {
    section: PropTypes.object.isRequired,
    onFormOpen: PropTypes.func.isRequired,
};

export default CreateSubsectionButton;
