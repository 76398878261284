import api from 'Application/api/api';

const deselectProjectTopic = (projectId, topicId, cancelToken = null) =>
    api.post(
        `project-manager/projects/${projectId}/topics/${topicId}/deselect`,
        {},
        { cancelToken },
    );

export default deselectProjectTopic;
