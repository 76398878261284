import React from 'react';
import styled from 'styled-components/macro';
import Box from 'Application/components/Static/Box';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import { Helmet } from 'react-helmet-async';
import BackButton from 'Application/components/Header/BackButton/BackButton';

const Heading = styled.h1`
    margin-bottom: 0;
`;

const TermsAndConditions = () => (
    <Box>
        <Helmet>
            <title>AGB</title>
        </Helmet>
        <BackButton to="/dashboard" />
        <Breadcrumb isActive>AGB</Breadcrumb>
        <Heading>AGB's der Amedic GmbH & Co. KG</Heading>
        <i>Stand: 2018</i>
        <br />
        <br />

        <h2>1. Geltungsbereich</h2>
        <div>
            <h4>
                (1) Diese Allgemeinen Geschäftsbedingungen gelten für die Nutzung von Onlinearbeitsschutzunterweisungen und -Gefährdungsbeurteilungen - nachfolgend "Angebote" genannt der AMEDIC Arbeits- und Medizintechnische Dienste GmbH & Co. KG - nachfolgend "Amedic" genannt.
            </h4>
            <h4>
                (2) Es gelten ausschließlich diese Allgemeinen Geschäftsbedingungen. Andere Geschäftsbedingungen, auch solche des Vertragspartners, finden keine Anwendung und werden hiermit ausgeschlossen. Vertragsbedingungen des Vertragspartners werden auch dann nicht Vertragsinhalt, wenn ihnen der Anbieter nicht ausdrücklich widerspricht.
            </h4>
            <h4>
                (3) Mit der Bestellung der Angebote erklärt sich der Vertragspartner mit der Geltung dieser Allgemeinen Geschäftsbedingungen einverstanden.
            </h4>
        </div>
        <br />

        <h2>2. Vertragsschluss</h2>
        <div>
            <h4>
                (1) Die angeführten oder erwähnten Preise, Preisangebote, Aktionen und Beschreibungen stellen kein Angebot dar. Sie können von Amedic jederzeit vor der ausdrücklichen Annahme der Bestellung zurückgezogen oder geändert werden.
            </h4>
            <h4>
                (2) Amedic ist bestrebt, die Verfügbarkeit des Amedic-Managers sicherzustellen, kann aber nicht gewährleisten, dass zum Zeitpunkt der Bestellung, sowie innerhalb der gesamten Vertragslaufzeit sämtliche Inhalte des Angebotes verfügbar sind. Sollte Amedic nicht in der Lage sein, die Bestellung des Vertragspartners zu verarbeiten oder zu erfüllen, kann Amedic diese ohne weitere Haftung zurückweisen.
            </h4>
            <h4>
                (3) Eine vom Vertragspartner abgegebene Bestellung stellt ein an Amedic gerichtetes Angebot zur Teilnahme an dem vom Vertragspartner ausgewählten Angebot unter diesen Nutzungsbedingungen dar. Alle vom Vertragspartner aufgegebenen Bestellungen unterliegen der anschließenden Annahme durch Amedic GmbH & CO.KG.
            </h4>
            <h4>
                (4) Die Kosten der der jährlichen Lizenzgröße ist ein vertraglich festgesetzter Wert, der unabhängig von den  integrierten Nutzern (siehe Lizenz Größenbeschränkung des Vertrages) in Rechnung gestellt wird. Lizenzerweiterungen werden durch festgesetzte Lizenzgrößen durch den Auftragnehmer vorgegeben. Der Auftraggeber kann nach Anfrage zwischen Staffelungen verschiedener Lizenzgrößen (vorgegeben durch den Auftragnehmer) die Erweiterung hinzubuchen. Demnach sind Erweiterungen von Einzelnutzerlizenzen nicht möglich, sondern nur nach vorgegebenen Staffelungen durch die Amedic GmbH & CO.KG.
                <br /><br />
                Der Kunde zahlt alle in dem Vertrag angegebenen Gebühren. Sofern hierin oder in einem Vertrag nicht anders angegeben,
                <br />
                (i) basieren alle anfallenden Kosten auf den erworbenen Diensten und nicht auf der tatsächlichen Nutzung,
                <br />
                (ii) Zahlungsverpflichtungen sind nicht stornierbar und gezahlte Gebühren werden nicht zurückerstattet, und
                <br />
                (iii) die vertraglich festgelegten Dienste und Lizenzgrößen können während der jeweiligen Laufzeit nicht verringert werden.
            </h4>
        </div>
        <br />

        <h2>
            3. Zugang zu den Online-Angeboten und Pflichten des Vertragspartners
        </h2>
        <div>
            <h4>
                (1) Der Zugang zu den Angeboten erfolgt passwortgeschützt im Wege der Datenfernübertragung unter Verwendung der dem Vertragspartner zugeteilten Zugangsdaten.
            </h4>
            <h4>
                (2) Die Zugangsdaten sind nur für einen Nutzer/eine Firma gültig.
            </h4>
            <h4>
                (3) Der Vertragspartner ist verpflichtet, die Zugangsdaten und die Passwörter geheim zu halten sowie die unberechtigte Nutzung der Angebote durch Dritte zu verhindern.
            </h4>
            <h4>
                (4) Bei Missbrauch ist Amedic berechtigt, den Zugang zu sperren.
            </h4>
            <h4>
                (5) Der Vertragspartner haftet für einen von ihm zu vertretenden Missbrauch.
            </h4>
            <h4>
                (6) Der Vertragspartner ist dafür verantwortlich, bei ihm die technischen Voraussetzungen für den Zugang zu den Angeboten zu schaffen, insbesondere hinsichtlich der eingesetzten Hardware und Betriebssystemsoftware, der Verbindung zum Internet einschließlich der Sicherstellung der Verbindungsgeschwindigkeit, der aktuellen Browsersoftware und der Akzeptanz der vom Server der Amedic GmbH & CO.KG übermittelten Cookies und trägt insoweit sämtliche Kosten im Zusammenhang mit der Erfüllung dieser Voraussetzungen. Amedic wird den Vertragspartner auf Anfrage über den jeweils einzusetzenden Browser und technischen Mindestvoraussetzungen informieren. Der Auftraggeber verpflichtet sich, nach Aufforderung durch den Auftraggeber entsprechende Nutzerdaten, insbesondere valide E-Mail Adressen der jeweils zu integrierenden Nutzer bereitzustellen.  Erfolgt keine Bereitstellung valider E-Mail Adressen , nach zweimaliger Aufforderung , für die Integration der jeweiligen Nutzer durch den Auftraggeber und verhindert somit die Freischaltung und Nutzung des Amedic-Managers, zählt die vertragliche Leistung als erfüllt. Die Amedic GmbH & CO.KG ist somit berechtigt die vertraglich vereinbarten Gebühren in Rechnung zu stellen.  Der Auftraggeber kann gegenüber dem Auftragnehmer, Auskünfte sowie Anfragen jeglicher Art, ausschließlich als Administrator des  durchführen.
            </h4>
            <h4>
                (7) Im Falle der Weiterentwicklung der Softwareplattformen und sonstiger technischer Komponenten des Systems durch Amedic obliegt es dem Vertragspartner, nach Information durch Amedic die notwendigen Anpassungsmaßnahmen bei der vom Vertragspartner eingesetzten Soft- und Hardware eigenständig zu treffen.
            </h4>
        </div>
        <br />

        <h2>4. Leistungsumfang und Nutzungsbeschränkungen</h2>
        <div>
            <h4>
                (1) Die dem Vertragspartner im Rahmen eines Angebotes zugänglichen Inhalte werden in der Leistungsbeschreibung festgelegt, aus der sich auch die vereinbarte Beschaffenheit der Angebote ergibt.
            </h4>
            <h4>
                (2) Amedic ist berechtigt, die Inhalte der Angebote zu verändern, einzuschränken oder Inhalte auszutauschen sowie Module hinsichtlich ihrer Inhalte angemessen zu modifizieren, insbesondere diese angemessen zu reduzieren oder zu erweitern. Führt eine solche Veränderung zu einer wesentlichen Beschränkung eines für den Vertragspartner gemäß Nutzungsvertrag verfügbaren Angebotes, so ist er berechtigt, den Vertrag fristlos zu kündigen. Das Kündigungsrecht kann innerhalb einer Frist von zwei Wochen ab Eintritt der wesentlichen Beschränkung ausgeübt werden. Wartungsarbeiten und Angebotserweiterungen führen nicht zum Kündigungsrecht.
            </h4>
            <h4>
                (3) Aussagen und Erläuterungen zu den Angeboten in Werbematerialien sowie auf der Website der Amedic und in der Dokumentation verstehen sich ausschließlich als Beschreibung der Beschaffenheit und nicht als Garantie oder Zusicherung einer Eigenschaft.
            </h4>
            <h4>
                (4) Die jährliche Wirksamkeitskontrolle wird digital über die Amedic Anwendung ausgeführt.
            </h4>
            <h4>
                (5) Der Vertragspartner hat der Amedic auf Anforderung folgende Unterlagen zur Verfügung zu stellen wie.z.B Betriebsmittellisten und Mitarbeiterlisten und weitere, die zur Bereitstellung des Amedic -Manager  notwendig sind. Diese werden im einzelnen seitens des Auftragnehmers gegenüber dem Auftraggeber angefordert. Sollten die Unterlagen nach zweimaliger erfolgloser Aufforderung durch die Amedic nicht vorgelegt worden sein, gilt die Leistung als erbracht, die Amedic hat den vollen Zahlungsanspruch.

                Wird die Einweisung durch den Auftraggeber verhindert oder keine Terminzusage bestätigt, gilt diese als erbracht
            </h4>
        </div>
        <br />

        <h2>5. Nutzungsrechte</h2>
        <div>
            <h4>
                (1) Der Vertragspartner erhält im Rahmen des Nutzungsvertrages sowie der nachfolgenden Bestimmungen das auf die Dauer des Nutzungsvertrages befristete, auf Dritte nicht übertragbare Recht zur Teilnahme an dem bestellten Angebot.
            </h4>
            <h4>
                (2) Das Teilnahmerecht während der Vertragsdauer umfasst den Zugang zu vertragsgegenständlichen Angeboten sowie die Berechtigung zum Abrufen (online) von Lerninhalten auf einem, dem Vertragspartner oder einem Dritten gehörenden, datenverarbeitenden Gerät (Computer) zu eigenen Lernzwecken.
            </h4>
            <h4>
                (3) Die Teilnahme ist auf den Vertragspartner beschränkt. Die abgerufenen Dokumente dürfen von ihm nur für den eigenen Gebrauch während der Nutzungszeit verwendet werden.
            </h4>
            <h4>
                (4) Jede gewerbliche Weitergabe, insbesondere das Verkaufen, Vermieten, Verpachten oder Verleihen von Kursen, deren Inhalte oder Dokumente ist unzulässig.
            </h4>
            <h4>
                (5) Es ist nicht zulässig, zur Fremdnutzung durch unberechtigte Dritte Elemente der zu den Angeboten gehörige Computerprogramme zu sammeln, zu vervielfältigen, auf weitere Datenträger zu kopieren oder abzuspeichern.
            </h4>
            <h4>
                (6) Amedic ist berechtigt, technische Maßnahmen zu treffen, durch die eine Nutzung über den vertraglich zulässigen Umfang hinaus verhindert wird, insbesondere entsprechende Zugangssperren zu installieren.
            </h4>
            <h4>
                (7) Der Vertragspartner ist nicht berechtigt Vorrichtungen, Programme oder sonstige Mittel einzusetzen, die dazu dienen, die technischen Maßnahmen von Amedic zu umgehen oder zu überwinden. Bei einem Verstoß des Vertragspartners ist Amedic berechtigt, den Zugang zu Angeboten sofort zu sperren sowie den Vertrag außerordentlich fristlos zu kündigen. Weitere Rechte und Ansprüche der Amedic, insbesondere Ansprüche auf Schadensersatz, bleiben unberührt.
            </h4>
            <h4>
                (8) Vom Nutzungsvertrag nicht erfasst ist das gesetzliche Recht zur Anfertigung eines Vervielfältigungsstücks gemäß § 53 UrhG.
            </h4>
        </div>
        <br />

        <h2>6. Rechte der Amedic</h2>
        <div>
            <h4>
                (1) Die von Amedic bereitgestellten Inhalte sind
                urheberrechtlich geschützt.
            </h4>
            <h4>
                (2) Alle dadurch begründeten Rechte, insbesondere das des Nachdrucks, der Übersetzung, der Wiedergabe auf fotomechanischen oder ähnlichen Wegen, der Speicherung und Verarbeitung mit Hilfe der EDV oder ihrer Verbreitung in Computernetzen - auch auszugsweise - sind der Amedic, bzw. den Urhebern und Lizenzinhabern vorbehalten.
            </h4>
            <h4>
                (3) Der Vertragspartner erhält keinerlei Eigentums- oder Verwertungsrechte an den bereitgestellten Inhalten oder Programmen.
            </h4>
        </div>
        <br />

        <h2>7. Vergütung und Zahlungsbedingungen</h2>
        <div>
            <h4>
                (1) Die vom Vertragspartner für die Teilnahme an einem Angebot zu leistende Vergütung ist in der Leistungsbeschreibung festgelegt.
            </h4>
            <h4>
                (2) Die Vergütung für die Teilnahme an einem Angebot ist jeweils im Voraus zu leisten.
            </h4>
            <h4>
                (3) Bei Nichterteilung des SEPA-Mandates behält sich die Amedic das Recht vor, einen Aufschlag von 2,5%  auf den jeweiligen  Rechnungsbetrag zu erheben.
            </h4>
            <h4>
                (4) Die Vergütung ist in voller Höhe für das jeweilige Kalenderjahr zu leisten, unabhängig vom Vertragsbeginn.
            </h4>
            <h4>
                (5) Die Vergütung für die jährliche Nutzung des Amedic-Manager ist in voller Höhe im Januar des entsprechenden Kalenderjahres zu leisten.
            </h4>
            <h4>
                (6) Wenn ein in Rechnung gestellter Betrag nicht bis zum Fälligkeitsdatum bei der Amedic GmbH & Co.KG  eingeht, können ohne Einschränkung der Rechte oder Rechtsmittel von Amedic GmbH & CO.KG
            </h4>
            <h4>
                (a) Können auf diese Gebühren Verzugszinsen in Höhe von 1,5 % des ausstehenden Saldos pro Monat oder des gesetzlich zulässigen Höchstsatzes anfallen, je nachdem, welcher Satz niedriger ist. Verzugszinsen in Höhe von 1,5 % des ausstehenden Betrages pro Monat oder des gesetzlich zulässigen Höchstsatzes, je nachdem, welcher Satz niedriger ist, anfallen.
            </h4>
            <h4>
                (7) Die Amedic GmbH & Co KG ist berechtigt, die Jahresbeiträge aus folgenden Gründen anzupassen oder auch abzusenken. Preiserhöhungen können wegen steigender Kosten durchgesetzt (z. B. bei Investitionen, Neu- oder Weiterentwicklungen einzelner Komponenten des Systems sowie bei Preissteigerungen externer Anbieter) werden. Beide Vertragsparteien haben der Preisanpassungsklausel mit Vertragsbeginn zugestimmt.
            </h4>
        </div>
        <br />

        <h2>8. Haftung</h2>
        <div>
            <h4>
                (1) Ein Sachmangel liegt vor, wenn ein Angebot nicht die vertragliche Beschaffenheit im Sinne von Ziffer 4.1 aufweist und dadurch die Tauglichkeit zum vertragsgemäßen Gebrauch aufgehoben oder gemindert ist. Eine unerhebliche Einschränkung der Tauglichkeit bleibt außer Betracht.
            </h4>
            <h4>
                (2) Der Vertragspartner hat auftretende Mängel, Störungen oder Schäden der Amedic unverzüglich in Schriftform anzuzeigen.
            </h4>
            <h4>
                (3) Amedic behebt Mängel nach Erhalt einer nachvollziehbaren Mängelbeschreibung durch den Vertragspartner innerhalb angemessener Frist.
            </h4>
            <h4>
                (4) Ein Kündigungsrecht wegen Nichtgewährung des Gebrauchs nach § 543 Abs. 2 Nr. 1 BGB besteht erst dann, wenn die Beseitigung des Mangels nicht innerhalb angemessener Frist erfolgt oder als fehlgeschlagen anzusehen ist.
            </h4>
            <h4>
                (5) Es wird keine Gewähr dafür übernommen, dass die Benutzung der Angebote nicht in Schutzrechte oder Urheberrechte Dritter eingreift oder keine Schäden bei Dritten herbeiführt. Amedic sind bislang keine solchen Rechte bekannt.
            </h4>
            <h4>
                (6) Amedic übernimmt keine Haftung dafür, dass die Kurse für die Zwecke des Vertragspartners geeignet sind.
            </h4>
            <h4>
                (7) Das Recht des Vertragspartners, bei Vorliegen der gesetzlichen Voraussetzungen nach Maßgabe der Bestimmungen in nachstehender Ziffer 9 Schadensersatz zu verlangen, bleibt unberührt.
            </h4>
            <h4>
                (8) Für Ausfallzeiten von Servern von Drittanbietern übernimmt Amedic keine Haftung. Die Wartung des Systems durch den Serveranbieter stellt keinen Kündigungsgrund dar.
            </h4>
        </div>
        <br />

        <h2>9. Haftungsbeschränkung</h2>
        <div>
            <h4>
                (1) Die Haftung der Amedic auf Schadensersatz und Aufwendungsersatz ist unabhängig vom Haftungsgrund auf 5.000 Euro beschränkt.
            </h4>
            <h4>
                (2) Die Haftungsbeschränkung gemäß Ziffer 9.1 gilt nicht, soweit ein Schaden auf vorsätzlichem oder grob fahrlässigem Verhalten oder Arglist der Amedic oder deren Erfüllungsgehilfen beruht, sowie für Schäden, die auf der Verletzung von Verpflichtungen beruhen, für deren Erfüllung der Anbieter eine Garantie übernommen hat und für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit oder für Schäden, für die nach dem Produkthaftungsgesetz gehaftet wird.
            </h4>
            <h4>
                (3) Im Falle der Verletzung einer Kardinalpflicht haftet Amedic auch bei leichter Fahrlässigkeit. Kardinalpflichten in diesem Sinne sind wesentliche Vertragspflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner vertrauen darf. Ein Anspruch auf Schadensersatz ist im Falle der Verletzung einer Kardinalpflicht der Höhe nach auf den Schaden beschränkt, der im Zeitpunkt der Pflichtverletzung als mögliche Folge der Vertragsverletzung typisch und vorhersehbar war (typischerweise vorhersehbarer Schaden), soweit keiner der in Ziffer 9.2 genannten Fälle gegeben ist.
            </h4>
            <h4>
                (4) Die verschuldensunabhängige Haftung der Amedic für bereits bei Vertragsschluss vorhandene Mängel nach § 536a Abs. 1 Halbs. 1 BGB wird ausgeschlossen.
            </h4>
            <h4>
                (5) Die Verjährung von Schadensersatzansprüchen richtet sich nach den gesetzlichen Vorschriften.
            </h4>
            <h4>
                (6) Eine Änderung der Beweislast zum Nachteil des Vertragspartners ist mit den vorstehenden Regelungen nicht verbunden.
            </h4>
            <h4>
                (7) Die vorstehenden Bestimmungen gelten auch zugunsten der Mitarbeiter und Erfüllungsgehilfen der Amedic.
            </h4>
        </div>
        <br />

        <h2>10. Beendigung von Verträgen</h2>
        <div>
            <h4>
                (1) Die jährlichen Kosten für den Amedic-Manager  haben eine Laufzeit von 5 Kalenderjaheren. Die Laufzeit der 5 Kalenderjahre für den Amedic-Manager beginnt nach Ausgleich der Rechnung für die Erstellung-und Nutzungsgebühr inklusive Einführung im drauffolgenden Kalenderjahr. Das Vertragsverhältnis kann mit einer Frist von 6 Monaten zum Ende der Laufzeit gekündigt werden. Unterbleibt die Kündigung, verlängert sich der Vertrag jeweils um 12 Monate.
            </h4>
            <h4>
                (2) Eine vorzeitige ordentliche Kündigung ist nicht möglich.
            </h4>
            <h4>
                (3) Das Recht jeder Partei zur außerordentlichen Kündigung aus wichtigem Grund bleibt unberührt.
            </h4>
            <h4>
                (4) Bei Beendigung des Vertrags ist Amedic berechtigt, sofort den Zugang des Vertragspartners zu dem jeweiligen Kurs zu sperren.
            </h4>
            <h4>
                (5) Jede Kündigung hat schriftlich, im Falle der außerordentlichen Kündigung aus wichtigem Grund unter Angabe des Kündigungsgrundes, zu erfolgen. Das Fernbleiben von Angeboten gilt in keinem Falle als Kündigung.
            </h4>
            <h4>
                (6) Im Falle der Kündigung werden keine Zahlungen erstattet.
            </h4>
        </div>
        <br />

        <h2>11. Sonstiges</h2>
        <div>
            <h4>
                (1) Für Rechtsstreitigkeiten aus oder im Zusammenhang mit diesem Vertrag gilt ausschließlich deutsches Recht.
            </h4>
            <h4>
                (2) Für Kaufleute oder Personen ohne ständigen Wohnsitz in Deutschland ist der Gerichtsstand für Rechtsstreitigkeiten aus oder im Zusammenhang mit diesem Vertrag Berlin.
            </h4>
            <h4>(3) Mündliche Nebenabreden bestehen nicht.</h4>
            <h4>
                (4) Ergänzende oder abweichende Vereinbarungen bedürfen zu ihrer Wirksamkeit der Schriftform. Vom Schriftformerfordernis kann nur durch schriftliche Vereinbarung abgesehen werden.
            </h4>
        </div>
    </Box>
);

export default TermsAndConditions;
