import React, { useState } from 'react';
import { CloudDownloadRounded } from '@material-ui/icons';
import Button from 'Common/components/Button';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import DownloadAllFilesModal from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/components/DownloadAllFilesButton/components/DownloadAllFilesModal';
import PropTypes from 'prop-types';
import useRequestFilesDownloadFunction from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/components/DownloadAllFilesButton/hooks/requestFilesDownloadFunction/useRequestFilesDownloadFunction';

const DownloadAllFilesButton = ({ question, ...props }) => {
    const requestFilesDownload = useRequestFilesDownloadFunction(
        question.id,
        question.isCustom,
    );

    const [isLoading, setIsLoading] = useState(false);

    const [
        isDownloadAllFilesModalOpened,
        setDownloadAllFilesModalOpened,
    ] = useState(false);

    const closeDownloadAllFilesModal = () => {
        setDownloadAllFilesModalOpened(false);
    };

    const handleButtonClick = async () => {
        setIsLoading(true);

        try {
            await requestFilesDownload();

            setDownloadAllFilesModalOpened(true);
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }

        setIsLoading(false);
    };

    return (
        <>
            <Button
                icon={<CloudDownloadRounded />}
                isLoading={isLoading}
                disabled={isLoading}
                type="button"
                onClick={handleButtonClick}
                {...props}
            >
                Alle downloaden
            </Button>
            {isDownloadAllFilesModalOpened && (
                <DownloadAllFilesModal onClose={closeDownloadAllFilesModal} />
            )}
        </>
    );
};

DownloadAllFilesButton.propTypes = {
    question: PropTypes.object.isRequired,
};

export default DownloadAllFilesButton;
