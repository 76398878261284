import React from 'react';
import styled from 'styled-components/macro';
import { orderBy } from 'lodash';
import Participant from 'ProjectManager/Project/Introductions/Grid/components/Introduction/ViewParticipantsModal/Participant';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import PropTypes from 'prop-types';

const Participants = styled.div``;

const ViewParticipantsModal = ({ introduction, onClose }) => {
    const relevantParticipants = introduction.participants.filter(
        participant => participant.isRelevant,
    );

    return (
        <Modal
            isOpen
            onRequestClose={onClose}
            buttons={
                <>
                    <Button type="button" onClick={onClose}>
                        Ok
                    </Button>
                </>
            }
        >
            <ModalTitle>
                {introduction.isCanceled
                    ? 'Einbezogene Mitarbeiter'
                    : 'Bestätigung der Einweisung'}
            </ModalTitle>
            <Participants>
                {orderBy(
                    relevantParticipants,
                    [
                        'projectParticipant.employee.lastName',
                        'projectParticipant.employee.firstName',
                    ],
                    ['asc', 'asc'],
                ).map(participant => (
                    <Participant
                        key={participant.id}
                        participant={participant}
                        introduction={introduction}
                    />
                ))}
            </Participants>
        </Modal>
    );
};

ViewParticipantsModal.propTypes = {
    introduction: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ViewParticipantsModal;
