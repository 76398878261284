import { selector } from 'recoil';
import { sumBy } from 'lodash';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';

const visibleQuestionFilesCountSelector = selector({
    key: 'projectManagement_visibleProjectQuestionFilesCount',
    get: ({ get }) => {
        const { questionnaireSections } = get(projectAtom);

        return sumBy(questionnaireSections, section =>
            sumBy(section.subsections, subsection =>
                sumBy(
                    subsection.questions.filter(question => question.isVisible),
                    question => question.files.length,
                ),
            ),
        );
    },
});

export default visibleQuestionFilesCountSelector;
