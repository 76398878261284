import api from 'Application/api/api';

const markProjectAsCreated = (id, cancelToken = null) =>
    api.post(
        `project-manager/projects/${id}/mark-as-created`,
        {},
        { cancelToken },
    );

export default markProjectAsCreated;
