import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';

const EmployeeList = styled.ul`
    padding-left: 20px;
`;

const EmployeeWarningModal = ({ onClose, areaName, employees }) => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);
    const isProject = dataManagementEntity === dataManagementEntities.PROJECT;
    const isTemplate = dataManagementEntity === dataManagementEntities.TEMPLATE;

    return (
        <Modal
            isOpen
            onRequestClose={onClose}
            buttons={
                <>
                    <Button type="button" onClick={onClose}>
                        OK
                    </Button>
                </>
            }
        >
            <ModalTitle>{`Löschen von "${areaName}" nicht erlaubt`}</ModalTitle>
            {isProject && (
                <p>
                    Dieser Unterweisungsbereich ist aktuell folgenden
                    Mitarbeitern zugeordnet. Löschung nur nach Entfernung der
                    jeweiligen Mitarbeiter möglich.
                </p>
            )}
            {isTemplate && (
                <p>
                    Dieser Unterweisungsbereich ist aktuell folgenden
                    Mitarbeitern zugeordnet. Löschung nur nach Entfernung der
                    jeweiligen Mitarbeiter aus der Projektvorlage möglich.
                </p>
            )}
            <EmployeeList>
                {employees.map(employee => (
                    <li key={employee.id}>
                        {`${employee.lastName}, ${employee.firstName}`}
                        <br />
                    </li>
                ))}
            </EmployeeList>
        </Modal>
    );
};

EmployeeWarningModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    areaName: PropTypes.string.isRequired,
    employees: PropTypes.array.isRequired,
};

export default EmployeeWarningModal;
