import React from 'react';
import { useRecoilState } from 'recoil';
import Filters from 'Common/components/Filters/Filters';
import projectsFiltersAtom, {
    defaultProjectsFiltersState,
} from 'ProjectManager/Project/List/recoil/filters/projectsFiltersAtom';
import ProjectsThatRequireAction from 'ProjectManager/Project/List/components/Overview/ProjectsTable/Toolbar/ProjectFilters/Filters/ProjectsThatRequireAction';
import Statuses from 'ProjectManager/Project/List/components/Overview/ProjectsTable/Toolbar/ProjectFilters/Filters/Statuses/Statuses';
import Topics from 'ProjectManager/Project/List/components/Overview/ProjectsTable/Toolbar/ProjectFilters/Filters/Topics';
import EndingDate from 'ProjectManager/Project/List/components/Overview/ProjectsTable/Toolbar/ProjectFilters/Filters/EndingDate';

const ProjectFilters = () => {
    const [recoilFilters, setRecoilFilters] = useRecoilState(
        projectsFiltersAtom,
    );

    return (
        <Filters
            initialState={recoilFilters}
            setInitialState={setRecoilFilters}
            clearedState={defaultProjectsFiltersState}
            excludedFilterKeysFromCounter={['statuses']}
        >
            {(filters, setFilters) => (
                <>
                    <Statuses
                        value={filters.statuses}
                        setFilters={setFilters}
                    />
                    <ProjectsThatRequireAction
                        value={filters.onlyProjectsThatRequireAction}
                        setFilters={setFilters}
                    />
                    <EndingDate
                        from={filters.endingDateFrom}
                        to={filters.endingDateTo}
                        setFilters={setFilters}
                    />
                    <Topics value={filters.topicIds} setFilters={setFilters} />
                </>
            )}
        </Filters>
    );
};

export default ProjectFilters;
