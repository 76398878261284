import React from 'react';
import Statistics from 'ProjectManager/Project/Introductions/Common/components/Statistics';
import SupervisorsList from 'ProjectManager/Project/Introductions/Common/components/SupervisorsList';

const Sidebar = () => (
    <>
        <Statistics placement="top" />
        <SupervisorsList/>
    </>
);

export default Sidebar;
