import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';

const Title = styled.h1`
    display: block;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 0 10px;
    color: ${colors.DIRTY_WHITE};
    text-align: center;
    font-size: 20px;
`;

export default Title;
