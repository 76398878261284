import React from 'react';
import styled from 'styled-components/macro';
import Button from 'Common/components/Button';
import ModalOption from 'Common/components/Modals/ModalOption';

const Options = styled.div`
    > * {
        width: 100%;

        &:first-child {
            margin-bottom: 10px;
        }
    }

    @media screen and (min-width: 480px) {
        display: flex;

        > * {
            width: auto;
            flex: 1 1 50%;

            &:first-child {
                margin-bottom: 0;
                margin-right: 10px;
            }
        }
    }
`;

const actions = {
    DELETE_TEMPLATES: 'DELETE_TEMPLATES',
    MOVE_TEMPLATES: 'MOVE_TEMPLATES',
};

const useActionSelectionModal = ({
    value,
    onChange,
    onCancel,
    onConfirm,
    onNextStepButtonClick,
    isLoading,
}) => {
    let primaryButtonText;

    switch (value) {
        case actions.DELETE_TEMPLATES:
            primaryButtonText = 'Alle löschen';
            break;

        case actions.MOVE_TEMPLATES:
            primaryButtonText = 'Weiter';
            break;

        default:
            primaryButtonText = 'Löschen';
            break;
    }

    const handleClick = () => {
        if (value === actions.DELETE_TEMPLATES) {
            onConfirm();
        } else if (value === actions.MOVE_TEMPLATES) {
            onNextStepButtonClick();
        }
    };

    return {
        props: {
            buttons: (
                <>
                    <Button text dark type="button" onClick={onCancel}>
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        disabled={value === null || isLoading}
                        onClick={handleClick}
                        isLoading={isLoading}
                    >
                        {primaryButtonText}
                    </Button>
                </>
            ),
        },
        content: (
            <>
                <p>
                    Diese Projektkategorie beinhaltet Vorlagen. Bitte Entscheide,
                    was mit diesen Vorlagen geschehen soll.
                </p>
                <Options>
                    <ModalOption
                        title="Löschung der Vorlagen"
                        description="Alle Vorlagen aus dieser Kategorie werden im Zuge der Löschung unwiderruflich gelöscht"
                        isChecked={value === actions.DELETE_TEMPLATES}
                        onToggle={() => onChange(actions.DELETE_TEMPLATES)}
                    />
                    <ModalOption
                        title="Verschieben der Vorlagen"
                        description=" Alle Vorlagen aus die Kategorie sollen um Zuge der Löschung verschoben und erhalten bleiben"
                        isChecked={value === actions.MOVE_TEMPLATES}
                        onToggle={() => onChange(actions.MOVE_TEMPLATES)}
                    />
                </Options>
            </>
        ),
    };
};

export default useActionSelectionModal;
