import questionTypes from 'ProjectManager/DataManagement/Questionnaire/components/Question/Answers/constants/questionTypes';
import YesNo from 'ProjectManager/DataManagement/Questionnaire/components/Question/Answers/components/YesNo';
import Text from 'ProjectManager/DataManagement/Questionnaire/components/Question/Answers/components/Text';
import SingleChoice from 'ProjectManager/DataManagement/Questionnaire/components/Question/Answers/components/SingleChoice';
import MultipleChoice from 'ProjectManager/DataManagement/Questionnaire/components/Question/Answers/components/MultipleChoice';

const questionTypesAnswerComponentsMap = {
    [questionTypes.YES_NO]: YesNo,
    [questionTypes.TEXT]: Text,
    [questionTypes.SINGLE_CHOICE]: SingleChoice,
    [questionTypes.MULTIPLE_CHOICE]: MultipleChoice,
};

export default questionTypesAnswerComponentsMap;
