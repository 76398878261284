import React from 'react';
import PropTypes from 'prop-types';
import InnerBoxWithButtons from 'Common/components/Boxes/InnerBoxWithButtons';
import Button from 'Common/components/Button';
import styled from 'styled-components/macro';
import InnerBoxTitle from 'Common/components/Boxes/InnerBoxTitle';
import QuestionType from 'ProjectManager/DataManagement/Questionnaire/components/QuestionCreation/components/CreationWizard/Steps/QuestionType';
import questionTypesButtons from 'ProjectManager/DataManagement/Questionnaire/components/QuestionCreation/constants/questionTypesButtons';

const QuestionTypes = styled.div`
    > button {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 530px) {
        display: flex;
        flex-wrap: wrap;

        > button {
            width: calc(50% - 5px);
            margin: 0 10px 0 0;

            &:nth-child(2n) {
                margin-right: 0;
            }

            &:nth-child(n + 3) {
                margin-top: 10px;
            }
        }
    }
`;

const TypeSelection = ({ selectedType, onSelect, onCancel, onContinue }) => (
    <InnerBoxWithButtons
        buttons={
            <>
                <Button text type="button" onClick={onCancel}>
                    Abbrechen
                </Button>
                <Button
                    type="button"
                    disabled={selectedType === null}
                    onClick={onContinue}
                >
                    Weiter
                </Button>
            </>
        }
    >
        <InnerBoxTitle>Wähle einen Fragetyp</InnerBoxTitle>
        <QuestionTypes>
            {Object.keys(questionTypesButtons).map(type => (
                <QuestionType
                    key={type}
                    icon={questionTypesButtons[type].icon}
                    onClick={() => onSelect(type)}
                    isSelected={selectedType === type}
                >
                    {questionTypesButtons[type].text}
                </QuestionType>
            ))}
        </QuestionTypes>
    </InnerBoxWithButtons>
);

TypeSelection.defaultProps = {
    selectedType: null,
};

TypeSelection.propTypes = {
    selectedType: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
};

export default TypeSelection;
