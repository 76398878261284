import React from 'react';
import PropTypes from 'prop-types';
import DateRangeFilter from 'Common/components/Filters/Filter/DateRangeFilter';

const EndingDate = ({ from, to, setFilters }) => {
    const handleChangeFromDate = value => {
        setFilters(prevFilters => ({
            ...prevFilters,
            endingDateFrom: value,
        }));
    };

    const handleChangeToDate = value => {
        setFilters(prevFilters => ({
            ...prevFilters,
            endingDateTo: value,
        }));
    };

    return (
        <DateRangeFilter
            title="Enddatum"
            startingDate={from}
            endingDate={to}
            onStartingDateChange={handleChangeFromDate}
            onEndingDateChange={handleChangeToDate}
            startingDateProps={{
                name: 'endingDateFrom',
                label: 'Von',
            }}
            endingDateProps={{
                name: 'endingDateTo',
                label: 'Bis'
            }}
        />
    );
};

EndingDate.defaultProps = {
    from: null,
    to: null,
};

EndingDate.propTypes = {
    from: PropTypes.object,
    to: PropTypes.object,
    setFilters: PropTypes.func.isRequired,
};

export default EndingDate;
