import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import colors from 'Common/constants/colors';
import { ChevronLeftRounded } from '@material-ui/icons';
import { ifProp } from 'styled-tools';
import { Link } from 'react-router-dom';

const Button = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    background: none;
    border: 0 none;
    outline: 0 none;
    color: ${colors.DIRTY_WHITE};
    font-size: 18px;
    line-height: 27px;
    text-decoration: none;
    width: 50px;
    height: 50px;

    &:hover {
        color: ${colors.DIRTY_WHITE};
    }

    ${ifProp(
        '$isActive',
        css`
            cursor: pointer;
        `,
        css`
            opacity: 0.25;
            pointer-events: none;
        `,
    )};

    @media screen and (min-width: 768px) {
        width: auto;
        height: auto;
    }
`;

const BackIcon = styled(ChevronLeftRounded)`
    margin-right: 5px;
    font-size: 42px !important;

    @media screen and (min-width: 768px) {
        font-size: 24px !important;
    }
`;

const ButtonAsLink = styled(Button).attrs({
    as: Link,
})``;

const ButtonAsExternalLink = styled(Button).attrs({
    as: 'a',
})``;

const Text = styled.div`
    display: none;

    @media screen and (min-width: 768px) {
        display: block;
    }
`;

const BackButton = ({ to, href, isActive }) => {
    const [portalNode, setPortalNode] = useState();

    useEffect(() => {
        setPortalNode(document.getElementById('backButton'));
    }, []);

    const LinkComponent = !!to ? ButtonAsLink : ButtonAsExternalLink;
    const linkProp = !!to ? 'to' : 'href';

    return portalNode
        ? ReactDOM.createPortal(
              <LinkComponent
                  {...{
                      [linkProp]: to || href,
                  }}
                  $isActive={isActive}
              >
                  <BackIcon />
                  <Text>Back</Text>
              </LinkComponent>,
              portalNode,
              'backButton',
          )
        : null;
};

BackButton.defaultProps = {
    to: null,
    href: null,
    isActive: true,
};

BackButton.propTypes = {
    to: PropTypes.string,
    href: PropTypes.string,
    isActive: PropTypes.bool,
};

export default BackButton;
