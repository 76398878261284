import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import createTemplateCustomQuestion from 'ProjectManager/Template/Common/api/dataManagement/questionnaire/question/createTemplateCustomQuestion';
import withAdditionalCustomQuestion
    from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/withAdditionalCustomQuestion';

const useCreateTemplateCustomQuestionFunction = subsectionId => {
    const [{ id: templateId }, setTemplate] = useRecoilState(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const createNewCustomQuestion = useCallback(
        async values => {
            const response = await createTemplateCustomQuestion(
                templateId,
                subsectionId,
                values,
                source.token,
            );

            const newCustomQuestion = response.data;

            setTemplate(
                withAdditionalCustomQuestion(newCustomQuestion, subsectionId),
            );
        },
        [templateId, subsectionId, setTemplate, source.token],
    );

    return createNewCustomQuestion;
};

export default useCreateTemplateCustomQuestionFunction;
