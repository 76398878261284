import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';

const OuterBoxSubtitle = styled.div`
    font-size: 14px;
    line-height: 21px;
    color: ${colors.GRAY};
    margin-bottom: 20px;
`;

export default OuterBoxSubtitle;
