import api from 'Application/api/api';

const getInstructionArea = (id, load, cancelToken = null) =>
    api.get(`/education/instruction-areas/${id}`, {
        params: {
            load,
        },
        cancelToken,
    });

export default getInstructionArea;
