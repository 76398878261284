import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import colors from 'Common/constants/colors';
import { Tooltip } from '@material-ui/core';

const Wrapper = styled.span`
    display: block;
    text-align: center;
`;

const Bar = styled.span`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    overflow: hidden;

    i {
        z-index: 1;
        ${ifProp(
            'isActive',
            css`
                color: ${props =>
                    props.progress > 40
                        ? colors.DIRTY_WHITE
                        : colors.PRIMARY_LIGHT};
            `,
            css`
                color: ${colors.DIRTY_WHITE};
            `,
        )};
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: ${prop('progress')}%;
        height: 100%;
        ${ifProp(
            'isActive',
            css`
                background: ${colors.PRIMARY};
            `,
            css`
                background: ${colors.DARK_GRAY};
            `,
        )};
    }
`;

const Total = styled.span`
    position: relative;
    flex: 1;
    z-index: 1;
    font-size: 12px;
    color: ${colors.DIRTY_WHITE};
    text-align: left;
`;

const Open = styled(Total)`
    text-align: right;
`;

const ProgressBar = ({ title, icon: Icon, stats, isActive }) => {
    const progressPercentage =
        stats.all > 0 ? Math.floor((stats.completed / stats.all) * 100) : 0;

    return (
        <Tooltip title={title}>
            <Wrapper>
                <Bar
                    isActive={isActive}
                    progress={progressPercentage.toFixed(4)}
                >
                    <Total>{`${stats.all} Aufgaben`}</Total>
                    <Icon size="sm" />
                    <Open>{`${stats.pending} offen`}</Open>
                </Bar>
            </Wrapper>
        </Tooltip>
    );
};

ProgressBar.propTypes = {
    title: PropTypes.string.isRequired,
    stats: PropTypes.object.isRequired,
    isActive: PropTypes.bool.isRequired,
};

export default ProgressBar;
