import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Loader from 'Common/components/Loader';
import InnerBox from 'Common/components/Boxes/InnerBox';
import colors from 'Common/constants/colors';
import Switch from 'Common/components/Form/Switch';
import { Tooltip } from '@material-ui/core';

const Wrapper = styled(InnerBox)`
    position: relative;
    padding: 15px;
`;

const Title = styled.div`
    margin-bottom: 7px;
    padding-right: 80px;
    font-size: 14px;
    line-height: 21px;
    margin-right: 20px;
`;

const Controls = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    align-items: center;
`;

const StyledLoader = styled(Loader)`
    margin-right: 5px;
`;

const Description = styled.div`
    color: ${colors.LIGHTER_GRAY};
    font-size: 12px;
    line-height: 18px;
`;

const SwitchableTile = ({
    title,
    description,
    isSelected,
    onSelect,
    onDeselect,
    isDisabled,
    disabledTooltipText,
    children,
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = async e => {
        setIsLoading(true);

        if (e.target.checked) {
            await onSelect();
        } else {
            await onDeselect();
        }

        setIsLoading(false);
    };

    const renderedSwitch = (
        <div>
            <Switch
                checked={isSelected}
                onChange={handleChange}
                name="checked"
                disabled={isLoading || isDisabled}
            />
        </div>
    );

    return (
        <Wrapper>
            <Title>{title}</Title>
            <Description>{description}</Description>
            <Controls>
                {isLoading && <StyledLoader size={24} />}
                {isDisabled ? (
                    <Tooltip title={disabledTooltipText}>
                        {renderedSwitch}
                    </Tooltip>
                ) : (
                    renderedSwitch
                )}
            </Controls>
            {children}
        </Wrapper>
    );
};

SwitchableTile.defaultProps = {
    isDisabled: false,
    disabledTooltipText: '',
};

SwitchableTile.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
    onDeselect: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    disabledTooltipText: PropTypes.string,
};

export default SwitchableTile;
