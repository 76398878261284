import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { debounce } from 'lodash';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import hasUnsavedChangesState from 'Common/recoil/hasUnsavedChangesState';
import useGiveYesNoAnswerFunction from 'ProjectManager/DataManagement/Questionnaire/components/Question/Answers/hooks/giveYesNoAnswer/useGiveYesNoAnswerFunction';

const Wrapper = styled.div`
    display: flex;
    width: 191px;
    height: 41px;
    margin-bottom: 10px;
`;

const CommonButton = styled.button`
    flex: 1;
    background: none;
    border: 1px solid;
    outline: 0 none;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    cursor: pointer;

    ${ifProp(
        'isSelected',
        css`
            border-color: ${colors.DIRTY_WHITE};
            background: ${colors.DIRTY_WHITE};
            color: ${colors.DARK_GRAY};
            font-weight: 500;
        `,
        css`
            border-color: ${colors.SEARCH_FIELD_BORDER};
            color: ${colors.DIRTY_WHITE};
        `,
    )};
`;

const YesButton = styled(CommonButton)`
    border-radius: 6px 0 0 6px;
    border-right: 0;
`;

const NoButton = styled(CommonButton)`
    border-radius: 0 6px 6px 0;
    border-left: 0;
`;

const Divider = styled.div`
    flex: 0 0 1px;
    width: 1px;
    background: ${colors.SEARCH_FIELD_BORDER};
`;

const answers = {
    YES: 'YES',
    NO: 'NO',
};

const YesNo = ({ question }) => {
    const giveYesNoAnswer = useGiveYesNoAnswerFunction(
        question.id,
        question.isCustom,
    );

    const [, setHasUnsavedChanges] = useRecoilState(hasUnsavedChangesState);

    const [answer, setAnswer] = useState(question.yesNoAnswer);

    const debouncedSave = useCallback(
        debounce(
            async selectedAnswer => {
                try {
                    await giveYesNoAnswer(selectedAnswer);
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        notify(
                            'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                            {
                                type: toast.TYPE.ERROR,
                            },
                        );
                    }
                }

                setHasUnsavedChanges(false);
            },
            350,
            {
                leading: true,
                trailing: true,
            },
        ),
        [giveYesNoAnswer, setHasUnsavedChanges],
    );

    const handleYesButtonClick = () => {
        setHasUnsavedChanges(true);
        setAnswer(answers.YES);
        debouncedSave(answers.YES);
    };

    const handleNoButtonClick = () => {
        setHasUnsavedChanges(true);
        setAnswer(answers.NO);
        debouncedSave(answers.NO);
    };

    return (
        <Wrapper>
            <YesButton
                type="button"
                onClick={handleYesButtonClick}
                isSelected={answer === answers.YES}
            >
                Ja
            </YesButton>
            <Divider />
            <NoButton
                type="button"
                onClick={handleNoButtonClick}
                isSelected={answer === answers.NO}
            >
                Nein
            </NoButton>
        </Wrapper>
    );
};

YesNo.propTypes = {
    question: PropTypes.object.isRequired,
};

export default YesNo;
