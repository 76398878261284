import getUsersWithSupervisorPermissions from 'ProjectManager/Common/api/getUsersWithSupervisorPermissions';
import mapUsersToOptions from 'User/utils/mapUsersToOptions';

const loadOptionsOfUsersWithSupervisorPermissions = async query => {
    const response = await getUsersWithSupervisorPermissions();
    const supervisorUsers = response.data;

    if (!query) {
        return mapUsersToOptions(supervisorUsers);
    }

    const searchTerm = query.toLowerCase();

    return mapUsersToOptions(
        supervisorUsers.filter(
            supervisorUser =>
                supervisorUser.firstName.toLowerCase().includes(searchTerm) ||
                supervisorUser.lastName.toLowerCase().includes(searchTerm),
        ),
    );
};

export default loadOptionsOfUsersWithSupervisorPermissions;
