import api from 'Application/api/api';

const approveDriverLicenseDistanceFile = (
    driverLicenseControlId,
    type,
    cancelToken = null,
) =>
    api.post(
        `/measures/vehicles/driver-licenses/controls/${driverLicenseControlId}/driver-license/distance-check/approve-file`,
        {
            type,
        },
        {
            cancelToken,
        },
    );

export default approveDriverLicenseDistanceFile;
