import api from 'Application/api/api';

const removeProjectCoordinator = (
    projectId,
    projectCoordinatorId,
    cancelToken = null,
) =>
    api.delete(
        `project-manager/projects/${projectId}/project-coordinators/${projectCoordinatorId}`,
        { cancelToken },
    );

export default removeProjectCoordinator;
