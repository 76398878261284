import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import OuterBox from 'Common/components/Boxes/OuterBox';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';

const Wrapper = styled(OuterBox)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 20px;
    text-align: center;

    @media screen and (min-width: 940px) {
        padding: 50px;
    }
`;

const FailedMessage = styled.div`
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 27px;

    @media screen and (min-width: 940px) {
        margin-bottom: 30px;
        font-size: 22px;
        line-height: 33px;
    }
`;

const InformationText = styled.div`
    color: ${colors.GRAY};
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;

    @media screen and (min-width: 940px) {
        font-size: 18px;
        line-height: 27px;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    > a,
    > button {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const FailedExamScreen = ({
    attemptsLeft,
    onShowExamMistakes,
    onShowPresentation,
    onRetakeExam,
}) => {
    const [isRetaking, setIsRetaking] = useState(false);

    const handleRetakeExamButtonClick = async () => {
        setIsRetaking(true);

        await onRetakeExam();

        setIsRetaking(false);
    };

    let informationText;

    if (attemptsLeft === 0) {
        informationText = 'Sie haben keine Versuche mehr';
    } else {
        informationText =
            'Sie können Ihre Fehler überprüfen und erneut versuchen';

        if (attemptsLeft !== null) {
            informationText += `. Versuche übrig: ${attemptsLeft}`;
        }
    }

    return (
        <Wrapper>
            <FailedMessage>
                Leider haben Sie diesen Test nicht bestanden
                <br />
                <InformationText>{informationText}</InformationText>
            </FailedMessage>
            <ButtonWrapper>
                <Button
                    outline
                    type="button"
                    disabled={isRetaking}
                    onClick={onShowExamMistakes}
                >
                    Prüfungsfehler anzeigen
                </Button>
                <Button
                    outline
                    type="button"
                    disabled={isRetaking}
                    onClick={onShowPresentation}
                >
                    Präsentation erneut anzeigen
                </Button>
                {attemptsLeft !== 0 && (
                    <Button
                        type="button"
                        disabled={isRetaking}
                        isLoading={isRetaking}
                        onClick={handleRetakeExamButtonClick}
                    >
                        Prüfung wiederholen
                    </Button>
                )}
            </ButtonWrapper>
        </Wrapper>
    );
};

FailedExamScreen.defaultProps = {
    attemptsLeft: null,
};

FailedExamScreen.propTypes = {
    attemptsLeft: PropTypes.number,
    onShowExamMistakes: PropTypes.func.isRequired,
    onShowPresentation: PropTypes.func.isRequired,
    onRetakeExam: PropTypes.func.isRequired,
};

export default FailedExamScreen;
