import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import styled from 'styled-components';
import File
    from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/components/File/File';
import DownloadAllFilesButton
    from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/components/DownloadAllFilesButton/components/DownloadAllFilesButton';

const ListWrapper = styled.div`
  @media screen and (min-width: 580px) {
    margin-top: -30px;
    margin-left: -25px;
  }
`;

const StyledFile = styled(File)`
  width: calc(100% - 40px);
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 580px) {
    display: inline-block;
    width: 210px;
    margin-top: 30px;
    margin-left: 25px;
    margin-bottom: 0;
  }
`;

const AllFilesModal = ({ question, onClose }) => (
    <Modal
        isOpen
        onRequestClose={onClose}
        maxHeight={451}
        contentStyles={{
            paddingRight: 0,
        }}
        buttons={
            <>
                <DownloadAllFilesButton text dark question={question} />
                <Button type="button" onClick={onClose}>
                    Ok
                </Button>
            </>
        }
    >
        <ModalTitle>
            {`Dies sind alle Dateien (${question.files.length})`}
        </ModalTitle>
        <ListWrapper>
            {question.files.map(file => (
                <StyledFile
                    key={file.id}
                    question={question}
                    file={file}
                    dark
                />
            ))}
        </ListWrapper>
    </Modal>
);

AllFilesModal.propTypes = {
    question: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default AllFilesModal;
