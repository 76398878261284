import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import colors from 'Common/constants/colors';
import {
    ArchiveRounded,
    AssignmentTurnedInRounded,
    FastForwardRounded,
    UpdateRounded,
} from '@material-ui/icons';

const projectStatusesElements = {
    [projectStatuses.ON_STANDBY]: {
        color: colors.WARNING,
        icon: UpdateRounded,
        text: 'Bevorstehend',
    },
    [projectStatuses.ONGOING]: {
        color: colors.BLUE,
        icon: FastForwardRounded,
        text: 'Laufend',
    },
    [projectStatuses.ENDED]: {
        color: colors.GREEN,
        icon: AssignmentTurnedInRounded,
        text: 'Beendet',
    },
    [projectStatuses.ARCHIVED]: {
        color: colors.DARK_GRAY,
        icon: ArchiveRounded,
        text: 'Archiviert',
    },
};

export default projectStatusesElements;
