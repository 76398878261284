import api from 'Application/api/api';

const markDriverLicenseAsFound = (driverLicenseControlId, cancelToken = null) =>
    api.post(
        `/measures/vehicles/driver-licenses/controls/${driverLicenseControlId}/driver-license/mark-as-found`,
        {},
        { cancelToken },
    );

export default markDriverLicenseAsFound;
