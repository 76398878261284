import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import { useSelector } from 'react-redux';
import Button from 'Common/components/Button';

const SuccessModal = ({ onClose, ...props }) => {
    const currentUserEmail = useSelector(state => state.user.email);

    return (
        <Modal
            isOpen
            onRequestClose={onClose}
            buttons={
                <Button type="button" onClick={onClose}>
                    Weiter
                </Button>
            }
            {...props}
        >
            <ModalTitle>Angeforderte Projektanhänge</ModalTitle>
            <p>
                Die Projektanhänge für das Projekt werden vorbereitet. Du
                erhältst in kürze eine E-Mail ({`E-Mail: ${currentUserEmail}`}),
                in welcher du ein Link findest mit Hilfe dessen du die
                angeforderten Projektunterlagen herunterladen kannst. Bitte
                beachte das der Link 48 Stunden gültig ist.
            </p>
        </Modal>
    );
};

SuccessModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default SuccessModal;
