import api from 'Application/api/api';

const addTemplateQuestionComment = (templateId, questionId, isCustom, cancelToken = null) =>
    api.post(
        `project-manager/templates/${templateId}/questionnaire/questions/${questionId}/${
            isCustom ? 1 : 0
        }/comment/add`,
        {},
        { cancelToken },
    );

export default addTemplateQuestionComment;
