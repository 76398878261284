import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useAddProjectQuestionCommentData from 'ProjectManager/DataManagement/Questionnaire/components/Question/Comment/hooks/addQuestionComment/useAddProjectQuestionCommentData';
import useAddTemplateQuestionCommentData from 'ProjectManager/DataManagement/Questionnaire/components/Question/Comment/hooks/addQuestionComment/useAddTemplateQuestionCommentData';

const useAddQuestionCommentData = (questionId, isCustomQuestion, onAdd) => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useAddProjectQuestionCommentData(
        questionId,
        isCustomQuestion,
        onAdd,
    );

    const dataFromTemplate = useAddTemplateQuestionCommentData(
        questionId,
        isCustomQuestion,
        onAdd,
    );

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return {};
};

export default useAddQuestionCommentData;
