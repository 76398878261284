import setIn from 'Common/utils/setIn';
import findQuestionInQuestionnaireSections from 'ProjectManager/DataManagement/Questionnaire/helpers/findQuestionInQuestionnaireSections';
import withUpdatedCompletedStatusesInQuestionnaire from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/withUpdatedCompletedStatusesInQuestionnaire';

const withoutCustomQuestion = questionId => prevProjectOrTemplate => {
    const questionnaireSections = prevProjectOrTemplate.questionnaireSections;

    const {
        question,
        sectionIndex,
        subsectionIndex,
        questionIndex,
    } = findQuestionInQuestionnaireSections(
        questionnaireSections,
        questionId,
        true,
    );

    if (question === null) {
        return prevProjectOrTemplate;
    }

    const newQuestions = [
        ...questionnaireSections[sectionIndex].subsections[subsectionIndex]
            .questions,
    ];

    newQuestions.splice(questionIndex, 1);

    const newQuestionnaireSections = setIn(
        questionnaireSections,
        `${sectionIndex}.subsections.${subsectionIndex}.questions`,
        newQuestions,
    );

    return withUpdatedCompletedStatusesInQuestionnaire({
        ...prevProjectOrTemplate,
        questionnaireSections: newQuestionnaireSections,
    });
};

export default withoutCustomQuestion;
