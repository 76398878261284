import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';

const SuccessModal = ({ onClose, onConfirmButtonClick }) => (
    <Modal
        isOpen
        onRequestClose={onClose}
        noTransition
        buttons={
            <>
                <Button text dark type="button" onClick={onClose}>
                    Nein, danke
                </Button>
                <Button type="button" onClick={onConfirmButtonClick}>
                    Jetzt bestätigen
                </Button>
            </>
        }
    >
        <ModalTitle>Bestätigung durch die Mitarbeiter</ModalTitle>
        <p>
            Die Einweisung wurde erfolgreich beendet. Du hast die jetzt die
            Möglichkeit alle einbezogenen Mitarbeiter bestätigen zu lassen.
            Diese Bestätigung können die Mitarbeiter jederzeit aus ihren eigenen
            Accounts durchführen.
        </p>
    </Modal>
);

SuccessModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirmButtonClick: PropTypes.func.isRequired,
};

export default SuccessModal;
