import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import Button from 'Common/components/Button';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import getProject from 'ProjectManager/Project/Common/api/getProject';
import { last, pick } from 'lodash';
import endProject from 'ProjectManager/Project/Common/api/status/endProject';
import createNewDate from 'Common/utils/createNewDate';
import FormField from 'Common/components/Form/FormField';
import DatePicker from 'Common/components/Form/Fields/DatePicker';
import startOfDay from 'date-fns/startOfDay';
import parseDate from 'Common/utils/parseDate';
import formatDateAsISO from 'Common/utils/formatDateAsISO';

const ConfirmModal = ({ onClose }) => {
    const [
        { id, startingDate: rawStartingDate, introductions },
        setProject,
    ] = useRecoilState(projectAtom);

    const [isConfirming, setIsConfirming] = useState(false);

    const [endingDate, setEndingDate] = useState(() =>
        startOfDay(createNewDate()),
    );

    const handleEndingDateChange = date => {
        setEndingDate(prevEndingDate => date ?? prevEndingDate);
    };

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirm = async () => {
        setIsConfirming(true);

        try {
            await endProject(id, formatDateAsISO(endingDate), source.token);

            const response = await getProject(id, [], source.token);

            setProject(prevProject => ({
                ...prevProject,
                ...pick(response.data, [
                    'status',
                    'endingDate',
                    'isPrematurelyEnded',
                    'prematurelyEndedOn',
                ]),
            }));

            setIsConfirming(false);

            onClose();
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsConfirming(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const conductedIntroductions = introductions.filter(
        introduction => introduction.isConducted,
    );

    const lastConductedIntroduction = last(conductedIntroductions) ?? null;

    const startingDate = parseDate(rawStartingDate);

    let minEndingDate =
        lastConductedIntroduction === null
            ? startOfDay(startingDate)
            : startOfDay(parseDate(lastConductedIntroduction.conductedAt));

    return (
        <Modal
            isOpen
            onRequestClose={isConfirming ? undefined : onClose}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        disabled={isConfirming}
                        onClick={onClose}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isConfirming}
                        disabled={isConfirming}
                        onClick={handleConfirm}
                    >
                        Projekt beenden
                    </Button>
                </>
            }
        >
            <ModalTitle>Projektende vorverlegen</ModalTitle>
            <p>
                Du kannst das Projekt vorzeitig beenden in dem du diesen Schritt
                bestätigst. Bitte achte auf das unten stehende Enddatum und
                führer gegebenenfalls, falls gewünscht, Anpassungen durch.
            </p>
            <FormField>
                <DatePicker
                    id="endingDate"
                    name="endingDate"
                    label="Projektende am"
                    value={endingDate}
                    onChange={handleEndingDateChange}
                    minDate={minEndingDate}
                    maxDate={startOfDay(createNewDate())}
                    portalId="root-portal"
                    isClearable={false}
                />
            </FormField>
        </Modal>
    );
};

ConfirmModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default ConfirmModal;
