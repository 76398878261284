import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';

const Image = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    background-image: url('${prop('src')}');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    
    ${ifProp(
        'isFullscreen',
        css`
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        `,
    )};
`;

const ImageSlide = ({ slide, isFullscreen }) => (
    <Image src={slide.file} isFullscreen={isFullscreen} />
);

ImageSlide.defaultProps = {
    isFullscreen: false,
};

ImageSlide.propTypes = {
    slide: PropTypes.object.isRequired,
    isFullscreen: PropTypes.bool,
};

export default ImageSlide;
