const withAdditionalClientContactPeople = newClientContactPeople => prevProject => {
    const allClientContactPeople = [...prevProject.client.contactPeople];
    allClientContactPeople.push(...newClientContactPeople);

    const client = {
        ...prevProject.client,
        contactPeople: allClientContactPeople,
    };

    const clientContactPeopleInProject = [...prevProject.clientContactPeople];
    clientContactPeopleInProject.push(...newClientContactPeople);

    return {
        ...prevProject,
        client,
        clientContactPeople: clientContactPeopleInProject,
    };
};

export default withAdditionalClientContactPeople;
