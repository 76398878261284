import styled from 'styled-components/macro';
import OuterBox from 'Common/components/Boxes/OuterBox';

const ContentSection = styled(OuterBox)`
    margin-bottom: 10px;
    padding: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: 1180px) {
        margin-bottom: 75px;
        padding: 0;
        box-shadow: none;
        background: none;
    }
`;

export default ContentSection;
