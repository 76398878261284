import { selector } from 'recoil';
import isQuestionAnswered from 'ProjectManager/DataManagement/Questionnaire/helpers/isQuestionAnswered';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';

const isTemplateQuestionnaireCompletedSelector = selector({
    key: 'projectManagement_isTemplateQuestionnaireCompleted',
    get: ({ get }) => {
        const { id, questionnaireSections } = get(templateAtom);

        return (
            Boolean(id) &&
            questionnaireSections.length > 0 &&
            questionnaireSections.every(section =>
                section.subsections.every(subsection =>
                    subsection.questions.every(question =>
                        isQuestionAnswered(question),
                    ),
                ),
            )
        );
    },
});

export default isTemplateQuestionnaireCompletedSelector;
