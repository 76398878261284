import api from 'Application/api/api';

const resumeInstructionAssignmentExam = (id, cancelToken = null) =>
    api.post(
        `/education/instruction-assignments/${id}/exam/resume`,
        {},
        { cancelToken },
    );

export default resumeInstructionAssignmentExam;
