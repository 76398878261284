import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useProjectCustomTopicFunctions from 'ProjectManager/DataManagement/TopicManagement/components/CustomTopicManagement/hooks/customTopicFunctions/useProjectCustomTopicFunctions';
import useTemplateCustomTopicFunctions from 'ProjectManager/DataManagement/TopicManagement/components/CustomTopicManagement/hooks/customTopicFunctions/useTemplateCustomTopicFunctions';

const useCustomTopicFunctions = () => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useProjectCustomTopicFunctions();
    const dataFromTemplate = useTemplateCustomTopicFunctions();

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return {};
};

export default useCustomTopicFunctions;
