import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { AutorenewRounded } from '@material-ui/icons';
import InteractableInnerBox from 'Common/components/Boxes/InteractableInnerBox';
import colors from 'Common/constants/colors';

const Text = styled.div`
    color: ${colors.LIGHTER_GRAY};
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
`;

const RetryIcon = styled(AutorenewRounded)`
    margin-bottom: 10px;
    color: ${colors.LIGHTER_GRAY};
    font-size: 40px !important;
    will-change: transform;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
`;

const Wrapper = styled(InteractableInnerBox).attrs({
    as: 'button',
})`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 30px 20px;
    border: 0 none;
    outline: 0 none;
    cursor: pointer;

    &:hover {
        ${Text},
        ${RetryIcon} {
            color: ${colors.DIRTY_WHITE};
        }

        ${RetryIcon} {
            transform: rotate(360deg);
        }
    }
`;

const RetryLoading = ({ onRetry }) => (
    <Wrapper onClick={onRetry}>
        <RetryIcon />
        <Text>Ladefehler. Klicken Sie hier, um es erneut zu versuchen.</Text>
    </Wrapper>
);

RetryLoading.propTypes = {
    onRetry: PropTypes.func.isRequired,
};

export default RetryLoading;
