import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import InnerBox from 'Common/components/Boxes/InnerBox';
import colors from 'Common/constants/colors';

const Wrapper = styled(InnerBox)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 1;

    @media screen and (min-width: 580px) {
        width: 345px;
    }
`;

const Slide = styled.button`
    display: block;
    width: 100%;
    padding: 10px 20px;
    background: none;
    border: 0 none;
    color: ${colors.DARK_GRAY};
    text-align: left;
    cursor: default !important;

    &:hover,
    &:focus {
        outline: 0 none;
    }

    ${ifProp(
        'isAvailable',
        css`
            color: ${colors.GRAY};
            cursor: pointer !important;

            &:hover {
                background: ${colors.OUTER_BOX_BACKGROUND};
                color: ${colors.DIRTY_WHITE};
            }
        `,
    )}

    ${ifProp(
        'isActive',
        css`
            color: ${colors.WHITE};

            &:hover {
                color: ${colors.WHITE};
            }
        `,
    )}
`;

const SlidesSidebarMenu = ({
    elementRef,
    slides,
    slideNumber,
    maxAvailableSlideNumber,
    onSlideNumberChange,
}) => (
    <Wrapper ref={elementRef}>
        {slides.map((slide, index) => {
            const number = index + 1;
            const isActive = number === slideNumber;
            const isAvailable = number <= maxAvailableSlideNumber && !isActive;

            return (
                <Slide
                    key={slide.id}
                    isAvailable={isAvailable}
                    isActive={isActive}
                    onClick={
                        isAvailable
                            ? () => onSlideNumberChange(number)
                            : undefined
                    }
                >{`${number}. ${slide.title}`}</Slide>
            );
        })}
    </Wrapper>
);

SlidesSidebarMenu.propTypes = {
    slides: PropTypes.array.isRequired,
    slideNumber: PropTypes.number.isRequired,
    maxAvailableSlideNumber: PropTypes.number.isRequired,
    onSlideNumberChange: PropTypes.func.isRequired,
};

export default SlidesSidebarMenu;
