import api from 'Application/api/api';

const createTemplateCustomQuestion = (
    templateId,
    customSubsectionId,
    data,
    cancelToken = null,
) =>
    api.post(
        `project-manager/templates/${templateId}/questionnaire/custom-subsections/${customSubsectionId}/custom-questions`,
        data,
        { cancelToken },
    );

export default createTemplateCustomQuestion;
