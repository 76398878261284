import { useRecoilValue } from 'recoil';
import isGeneralTemplateInformationStepCompletedSelector from 'ProjectManager/Template/Common/recoil/selectors/isGeneralTemplateInformationStepCompletedSelector';

const useIsTemplateGeneralInformationStepCompleted = () => {
    // noinspection UnnecessaryLocalVariableJS
    const isTemplateGeneralInformationStepCompleted = useRecoilValue(
        isGeneralTemplateInformationStepCompletedSelector,
    );

    return isTemplateGeneralInformationStepCompleted;
};

export default useIsTemplateGeneralInformationStepCompleted;
