import React, { useEffect, useMemo, useState } from 'react';
import Button from 'Common/components/Button';
import { CloudUploadRounded } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import axios from 'axios';
import useUploadFilesFunction from 'ProjectManager/DataManagement/Files/hooks/uploadFilesFunction/useUploadFilesFunction';

const UploadButton = ({ children, ...props }) => {
    const uploadFiles = useUploadFilesFunction();

    const [isUploading, setIsUploading] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const { open, getInputProps } = useDropzone({
        accept: [
            'application/pdf',
            'application/zip',
            'application/x-zip-compressed',
            'image/jpg',
            'image/jpeg',
            'image/png',
            'image/gif',
            'video/mp4',
            'audio/mpeg',
            'audio/wav',
        ].join(', '),
        onDrop: async (acceptedFiles, fileRejections) => {
            setIsUploading(true);

            if (acceptedFiles && acceptedFiles.length > 0) {
                try {
                    await uploadFiles(acceptedFiles);

                    if (fileRejections.length) {
                        const acceptedFilesCount = acceptedFiles.length;
                        const allFilesCount =
                            acceptedFilesCount + fileRejections.length;

                        notify('Die Dateien wurden erfolgreich hochgeladen', {
                            type: toast.TYPE.SUCCESS,
                        });

                        notify(
                            `Nur ${acceptedFilesCount} von ${allFilesCount} Dateien wurden erfolgreich hochgeladen`,
                            {
                                type: toast.TYPE.WARNING,
                            },
                        );
                    } else {
                        notify('Alle Dateien wurden erfolgreich hochgeladen', {
                            type: toast.TYPE.SUCCESS,
                        });
                    }
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        notify('Die Dateien konnten nicht hochgeladen werden', {
                            type: toast.TYPE.ERROR,
                        });
                    }
                }
            } else {
                notify(
                    'Es wurden keine Dateien hochgeladen (ungültiges Dateiformat)',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }

            setIsUploading(false);
        },
    });

    return (
        <>
            <input {...getInputProps()} />
            <Button
                icon={<CloudUploadRounded />}
                type="button"
                isLoading={isUploading}
                disabled={isUploading}
                onClick={open}
                {...props}
            >
                {children ?? 'Dateien uploaden'}
            </Button>
        </>
    );
};

export default UploadButton;
