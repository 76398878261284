import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import ProjectDrawer from 'ProjectManager/Project/Preview/ProjectDrawer';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import Tile from 'ProjectManager/Project/Preview/Tile';
import { EditRounded } from '@material-ui/icons';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import Button from 'Common/components/Button';
import List from 'ProjectManager/Project/Preview/People/List';
import Supervisors from 'ProjectManager/DataManagement/Supervisors/components/Supervisors';
import ProjectCoordinators from 'ProjectManager/Project/DataManagement/GeneralInformation/ProjectCoordinators/ProjectCoordinators';
import ContactPeople from 'ProjectManager/Project/DataManagement/GeneralInformation/ContactPeople/components/ContactPeople';
import ContentSection from 'ProjectManager/DataManagement/Common/components/ContentSection';
import { orderBy } from 'lodash';
import roles from 'ProjectManager/Project/Preview/People/roles';
import { ifProp } from 'styled-tools';
import WarningMessage from 'Common/components/WarningMessage';
import { useWindowWidth } from '@react-hook/window-size';

const StyledTile = styled(Tile)`
    padding-right: 10px;
`;

const Wrapper = styled.div`
    padding-right: 10px;

    ${ifProp(
        'withPeople',
        css`
            overflow-y: auto;
            max-height: 475px;
            padding-bottom: 15px;
        `,
    )};
`;

const ButtonWrapper = styled.div`
    padding-right: 10px;
    margin-top: 30px;

    button {
        padding-left: 0;
    }
`;

const ContactPeopleWrapper = styled.div`
    @media screen and (min-width: 1180px) {
        width: calc(50% - 10px);
    }
`;

const People = () => {
    const {
        status,
        clientContactPeople,
        projectCoordinators,
        supervisors,
    } = useRecoilValue(projectAtom);
    const isManagingPossible = status !== projectStatuses.ARCHIVED;

    const [isDrawerOpened, setIsDrawerOpened] = useState(false);

    const openDrawer = () => {
        setIsDrawerOpened(true);
    };

    const closeDrawer = () => {
        setIsDrawerOpened(false);
    };

    const visibleSupervisors = supervisors.filter(
        supervisor => !supervisor.isUnAssigned,
    );

    const people = [
        ...orderBy(
            clientContactPeople.map(clientContactPerson => ({
                role: roles.CLIENT_CONTACT_PERSON,
                id: `${clientContactPerson.id}_CLIENT_CONTACT_PERSON`,
                name: clientContactPerson.name,
                email: clientContactPerson.email,
            })),
            ['name'],
            ['ASC'],
        ),
        ...orderBy(
            projectCoordinators.map(projectCoordinator => ({
                role: roles.PROJECT_COORDINATOR,
                id: `${projectCoordinator.id}_PROJECT_COORDINATOR`,
                name: projectCoordinator.name,
                email: projectCoordinator.email,
            })),
            ['name'],
            ['ASC'],
        ),
        ...orderBy(
            visibleSupervisors.map(supervisor => ({
                role: roles.SUPERVISOR,
                id: `${supervisor.id}_SUPERVISOR`,
                name: `${supervisor.user.lastName}, ${supervisor.user.firstName}`,
                email: supervisor.user.email,
            })),
            ['name'],
            ['ASC'],
        ),
    ];

    const hasMissingRequiredData =
        clientContactPeople.length === 0 || visibleSupervisors.length === 0;

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth <= 540;

    const fixedSelectProps = {};

    if (!isMobile) {
        fixedSelectProps.menuPosition = 'fixed';
        fixedSelectProps.menuPortalTarget = document.body;
    }

    return (
        <>
            <StyledTile>
                <OuterBoxTitle>Ansprechpartner</OuterBoxTitle>
                <Wrapper>
                    <List people={people} />
                </Wrapper>
                {isManagingPossible && (
                    <ButtonWrapper>
                        <Button
                            underline
                            icon={<EditRounded />}
                            onClick={openDrawer}
                        >
                            Manage
                        </Button>
                    </ButtonWrapper>
                )}
                {hasMissingRequiredData && isManagingPossible && (
                    <WarningMessage>
                        Fehlende erforderliche Daten
                    </WarningMessage>
                )}
            </StyledTile>
            <ProjectDrawer
                isOpened={isDrawerOpened}
                onOpen={openDrawer}
                onClose={closeDrawer}
            >
                <ContentSection>
                    <OuterBoxTitle>Kontaktperson</OuterBoxTitle>
                    <ContactPeopleWrapper>
                        <ContactPeople {...fixedSelectProps}/>
                    </ContactPeopleWrapper>
                </ContentSection>
                <Supervisors {...fixedSelectProps}/>
                <ProjectCoordinators />
            </ProjectDrawer>
        </>
    );
};

export default People;
