import { selector } from 'recoil';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';

const isGeneralTemplateInformationStepCompletedSelector = selector({
    key: 'projectManagement_isGeneralTemplateInformationStepCompleted',
    get: ({ get }) => {
        const { id, name, supervisorUsers, areas, workingDays } = get(
            templateAtom,
        );

        return (
            Boolean(id) &&
            name !== '' &&
            supervisorUsers.length > 0 &&
            areas.length > 0 &&
            workingDays.length > 0
        );
    },
});

export default isGeneralTemplateInformationStepCompletedSelector;
