import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useProjectWorkingHoursData from 'ProjectManager/DataManagement/WorkingHours/hooks/useProjectWorkingHoursData';
import useTemplateWorkingHoursData from 'ProjectManager/DataManagement/WorkingHours/hooks/useTemplateWorkingHoursData';

const useWorkingHoursData = () => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useProjectWorkingHoursData();
    const dataFromTemplate = useTemplateWorkingHoursData();

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return {};
};

export default useWorkingHoursData;
