import styled from 'styled-components/macro';

const ModalTitle = styled.div`
    margin-bottom: 30px;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
`;

export default ModalTitle;
