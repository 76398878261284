import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import DataManagementBottomButtons from 'ProjectManager/DataManagement/Common/components/DataManagementBottomButtons';
import Button from 'Common/components/Button';
import ContentSection from 'ProjectManager/DataManagement/Common/components/ContentSection';
import Areas from 'ProjectManager/DataManagement/Areas/components/Areas';
import Supervisors from 'ProjectManager/DataManagement/Supervisors/components/Supervisors';
import Files from 'ProjectManager/DataManagement/Files/components/Files';
import { useRecoilValue } from 'recoil';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import TemplateData from 'ProjectManager/Template/DataManagement/TemplateData';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import WorkingHoursAndDays from 'ProjectManager/DataManagement/WorkingHoursAndDays';

const Wrapper = styled.div``;

const TemplateGeneralInformation = ({ onNextButtonClick }) => {
    const { id: templateId } = useRecoilValue(templateAtom);

    return (
        <Wrapper>
            <TemplateData />
            {Boolean(templateId) && (
                <>
                    <Supervisors />
                    <Areas />
                    <ContentSection>
                        <OuterBoxTitle>Einsatzzeiten</OuterBoxTitle>
                        <WorkingHoursAndDays />
                    </ContentSection>
                    <ContentSection>
                        <Files title="Allgemeine Dateien" />
                    </ContentSection>
                </>
            )}
            <DataManagementBottomButtons>
                <Button type="button" onClick={onNextButtonClick}>
                    Weiter
                </Button>
            </DataManagementBottomButtons>
        </Wrapper>
    );
};

TemplateGeneralInformation.propTypes = {
    onNextButtonClick: PropTypes.func.isRequired,
};

export default TemplateGeneralInformation;
