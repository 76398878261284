import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import changeProjectSupervisors from 'ProjectManager/Project/Common/api/dataManagement/supervisor/changeProjectSupervisors';
import withSupervisors from 'ProjectManager/Project/Common/recoil/project/modifiers/withSupervisors';

const useProjectSupervisorsData = () => {
    const [{ id: projectId, supervisors }, setProject] = useRecoilState(
        projectAtom,
    );

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const updateSupervisors = useCallback(
        async userIds => {
            const response = await changeProjectSupervisors(
                projectId,
                userIds,
                source.token,
            );

            const newSupervisors = response.data;

            setProject(withSupervisors(newSupervisors));
        },
        [projectId, setProject, source.token],
    );

    return projectId
        ? {
              selectedSupervisorUsers: supervisors
                  .filter(supervisor => !supervisor.isUnAssigned)
                  .map(supervisor => supervisor.user),
              updateSupervisors,
              isRequired: true,
          }
        : {};
};

export default useProjectSupervisorsData;
