import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Button from 'Common/components/Button';
import { InfoRounded } from '@material-ui/icons';
import { Popover } from '@material-ui/core';
import colors from 'Common/constants/colors';

const Wrapper = styled.div``;

const InfoIconButton = styled(Button)`
    text-decoration: none;
`;

const SectionsWrapper = styled.div`
    min-width: 280px;
    max-width: 300px;
    padding: 20px;
    color: ${colors.DARK_GRAY};
    font-size: 14px;
    line-height: 21px;

    @media screen and (min-width: 400px) {
        max-width: 380px;
    }
`;

const Section = styled.div`
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
`;

const DocumentType = styled.div`
    margin-bottom: 2px;
`;

const FileTypes = styled.div`
    font-weight: 300;
`;

const AllowedFileTypes = () => {
    const [helpPopoverAnchorEl, setHelpPopoverAnchorEl] = useState(null);

    const handleButtonClick = e => {
        setHelpPopoverAnchorEl(e.currentTarget);
    };

    const handleCloseHelpPopover = () => {
        setHelpPopoverAnchorEl(null);
    };

    const isHelpPopoverOpened = Boolean(helpPopoverAnchorEl);

    return (
        <Wrapper>
            <InfoIconButton
                underline
                icon={<InfoRounded />}
                type="button"
                onClick={handleButtonClick}
            >
                Erlaubte Dateitypen
            </InfoIconButton>
            <Popover
                open={isHelpPopoverOpened}
                anchorEl={helpPopoverAnchorEl}
                onClose={handleCloseHelpPopover}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <SectionsWrapper>
                    <Section>
                        <DocumentType>Dokumente</DocumentType>
                        <FileTypes>pdf, zip</FileTypes>
                    </Section>
                    <Section>
                        <DocumentType>Bilder</DocumentType>
                        <FileTypes>jpg, jpeg, png, gif</FileTypes>
                    </Section>
                    <Section>
                        <DocumentType>Audiodateien</DocumentType>
                        <FileTypes>mp3, wav</FileTypes>
                    </Section>
                    <Section>
                        <DocumentType>Videodateien</DocumentType>
                        <FileTypes>mp4</FileTypes>
                    </Section>
                </SectionsWrapper>
            </Popover>
        </Wrapper>
    );
};

export default AllowedFileTypes;
