import React from 'react';
import styled from 'styled-components/macro';
import { useRecoilValue } from 'recoil';
import LabeledValue from 'ProjectManager/Project/Preview/LabeledValue';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import weekDays from 'ProjectManager/DataManagement/WorkingDays/constants/weekDays';
import parseDate from 'Common/utils/parseDate';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import formatInterval from 'Common/utils/formatInterval';
import createNewDate from 'Common/utils/createNewDate';
import addDays from 'date-fns/addDays';
import archivableAfterInDays from 'ProjectManager/Project/Common/constants/archivableAfterInDays';
import startOfDay from 'date-fns/startOfDay';

const Wrapper = styled.div``;

const formatTime = time =>
    `${time.hours
        .toString()
        .padStart(2, '0')}:${time.minutes.toString().padStart(2, '0')}`;

const Info = () => {
    const {
        status,
        isPrematurelyEnded,
        workingHours,
        workingDays,
        startingDate,
        endingDate,
        prematurelyEndedOn,
        archivedAt,
    } = useRecoilValue(projectAtom);

    return (
        <Wrapper>
            <LabeledValue label="Arbeitszeit">
                {`${formatTime(workingHours.from)} - ${formatTime(
                    workingHours.to,
                )} Uhr`}
            </LabeledValue>
            <LabeledValue label="Tage">
                {workingDays.length > 0
                    ? weekDays
                          .filter(weekDay =>
                              workingDays.includes(weekDay.value),
                          )
                          .map(weekDay => weekDay.label)
                          .join(', ')
                    : '-'}
            </LabeledValue>
            <LabeledValue label="Projektdauer">
                {endingDate === null
                    ? 'Nicht festgelegt'
                    : formatInterval(
                          {
                              start: startOfDay(parseDate(startingDate)),

                              // Add 1 day to the ending date, so that the interval is
                              // counted as inclusive. E.g. if the starting and ending
                              // date are on the same date, that will count as 1 day,
                              // and not as 0 days.
                              end: startOfDay(
                                  addDays(parseDate(endingDate), 1),
                              ),
                          },
                          {
                              format: ['years', 'months', 'weeks', 'days'],
                          },
                      ) ?? '0 Tage'}
            </LabeledValue>
            {status === projectStatuses.ON_STANDBY && (
                <LabeledValue label="Zeit bis zum Start">
                    {formatInterval(
                        {
                            start: startOfDay(createNewDate()),
                            end: startOfDay(parseDate(startingDate)),
                        },
                        {
                            format: ['years', 'months', 'weeks', 'days'],
                        },
                    ) ?? '0 Tage'}
                </LabeledValue>
            )}
            {status === projectStatuses.ONGOING && (
                <LabeledValue label="Zeit bis zum Ende">
                    {endingDate === null
                        ? 'Nicht festgelegt'
                        : formatInterval(
                              {
                                  start: startOfDay(createNewDate()),
                                  end: startOfDay(parseDate(endingDate)),
                              },
                              {
                                  format: ['years', 'months', 'weeks', 'days'],
                              },
                          ) ?? '0 Tage'}
                </LabeledValue>
            )}
            {status === projectStatuses.ENDED && (
                <LabeledValue label="Zeit bis zum Archiv">
                    {formatInterval(
                        {
                            start: startOfDay(createNewDate()),
                            end: startOfDay(
                                addDays(
                                    parseDate(
                                        isPrematurelyEnded
                                            ? prematurelyEndedOn
                                            : endingDate,
                                    ),
                                    archivableAfterInDays,
                                ),
                            ),
                        },
                        {
                            format: ['years', 'months', 'weeks', 'days'],
                        },
                    ) ?? '0 Tage'}
                </LabeledValue>
            )}
            {status === projectStatuses.ARCHIVED && (
                <LabeledValue label="Zeit seit dem Archiv">
                    {formatInterval(
                        {
                            start: startOfDay(parseDate(archivedAt)),
                            end: startOfDay(createNewDate()),
                        },
                        {
                            format: ['years', 'months', 'weeks', 'days'],
                        },
                    ) ?? '0 Tage'}
                </LabeledValue>
            )}
        </Wrapper>
    );
};

export default Info;
