import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import { useRecoilValue } from 'recoil';
import deleteProject from 'ProjectManager/Project/Common/api/deleteProject';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const DeleteModal = ({ onClose, ...props }) => {
    const dispatch = useDispatch();

    const { id: projectId } = useRecoilValue(projectAtom);

    const [isDeleting, setIsDeleting] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirm = async () => {
        setIsDeleting(true);

        try {
            await deleteProject(projectId, source.token);

            onClose();

            notify('Die Vorlage wurde erfolgreich gelöscht', {
                type: toast.TYPE.SUCCESS,
            });

            dispatch(push('/project-manager'));
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify('Die Vorlage konnte nicht gelöscht werden', {
                    type: toast.TYPE.ERROR,
                });
            }
        }

        setIsDeleting(false);
    };

    return (
        <Modal
            isOpen
            onRequestClose={isDeleting ? undefined : onClose}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onClose}
                        disabled={isDeleting}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isDeleting}
                        onClick={handleConfirm}
                        disabled={isDeleting}
                    >
                        Löschen
                    </Button>
                </>
            }
            {...props}
        >
            <ModalTitle>Unwiderrufliches Löschen des Projektes</ModalTitle>
            <p>
                In dem du bestätigst wird, das Projekt unwiderruflich gelöscht.
                Bitte beachte, dass alle in das Projekt eingefügten Unterlagen
                gelöscht werden.
            </p>
        </Modal>
    );
};

DeleteModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default DeleteModal;
