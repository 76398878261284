import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Question from 'Education/components/instruction/test-edit/Question';
import Button from 'Common/components/Button';
import { PostAddRounded } from '@material-ui/icons';

const ButtonWrapper = styled.div`
    text-align: left;
`;

const QuestionList = ({
    questions,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    onImageChange,
    onMoveQuestionBackwards,
    onMoveQuestionForwards,
    onQuestionAdd,
    onQuestionDelete,
    onAnswerAdd,
    onAnswerDelete,
}) => (
    <>
        <div>
            {questions.map((question, index) => {
                const id = question.id;

                return (
                    <Question
                        key={id}
                        questionValues={question}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        number={index + 1}
                        isFirst={index === 0}
                        isLast={index === questions.length - 1}
                        fieldPrefix={`questions[${index}]`}
                        onImageChange={image => onImageChange(id, image)}
                        onMoveBackwards={() => onMoveQuestionBackwards(id)}
                        onMoveForwards={() => onMoveQuestionForwards(id)}
                        onDelete={() => onQuestionDelete(id)}
                        onAnswerAdd={() => onAnswerAdd(id)}
                        onAnswerDelete={answerId =>
                            onAnswerDelete(id, answerId)
                        }
                    />
                );
            })}
        </div>
        <ButtonWrapper>
            <Button
                underline
                icon={<PostAddRounded />}
                type="button"
                onClick={onQuestionAdd}
            >
                Frage hinzufügen
            </Button>
        </ButtonWrapper>
    </>
);

QuestionList.propTypes = {
    questions: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    onImageChange: PropTypes.func.isRequired,
    onMoveQuestionBackwards: PropTypes.func.isRequired,
    onMoveQuestionForwards: PropTypes.func.isRequired,
    onQuestionAdd: PropTypes.func.isRequired,
    onQuestionDelete: PropTypes.func.isRequired,
    onAnswerAdd: PropTypes.func.isRequired,
    onAnswerDelete: PropTypes.func.isRequired,
};

export default QuestionList;
