import api from 'Application/api/api';

const getInstructionStats = (id, load, cancelToken = null) =>
    api.get(`/education/instructions/${id}/stats`, {
        params: {
            load,
        },
        cancelToken,
    });

export default getInstructionStats;
