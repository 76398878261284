const withIntroductionPPE = (introductionId, ppe) => prevProject => {
    const index = prevProject.introductions.findIndex(
        introduction => introduction.id === introductionId,
    );

    if (index === -1) {
        return prevProject;
    }

    if (prevProject.introductions[index].ppes.includes(ppe)) {
        return prevProject;
    }

    const newIntroductions = [...prevProject.introductions];

    newIntroductions[index] = {
        ...newIntroductions[index],
        ppes: [...newIntroductions[index].ppes, ppe],
    };

    return {
        ...prevProject,
        introductions: newIntroductions,
    };
};

export default withIntroductionPPE;
