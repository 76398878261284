import React from 'react';
import PropTypes from 'prop-types';
import modules from 'Common/constants/modules';
import roles from 'User/constants/roles';
import { useSelector } from 'react-redux';
import { StickyTabList, Tab, TabPanel, Tabs } from 'Common/components/tabs';
import AllEmployeeInstructionAssignments from 'Employee/components/View/Instruction/AllEmployeeInstructionAssignments';
import OpenEmployeeInstructionAssignments from 'Employee/components/View/Instruction/OpenEmployeeInstructionAssignments';
import EmployeeInstructionAssignmentsManagerView from 'Employee/components/View/Instruction/EmployeeInstructionAssignmentsManagerView';
import PendingEmployeeMeasureTasks from 'Employee/components/View/Measure/PendingEmployeeMeasureTasks';
import AllEmployeeMeasureTasks from 'Employee/components/View/Measure/AllEmployeeMeasureTasks';
import useHash from 'Common/hooks/useHash';

const EmployeeViewTabs = ({ selectedModule, employee }) => {
    const role = useSelector(state => state.user.role);

    const [tabIndex, setTabIndex] = useHash();

    return (
        <Tabs selectedIndex={tabIndex} onSelect={setTabIndex}>
            <StickyTabList>
                <Tab>Ausstehende Aufgaben</Tab>
                <Tab>Alle Aufgaben</Tab>
            </StickyTabList>
            <TabPanel>
                {selectedModule === modules.INSTRUCTIONS &&
                    (role === roles.MANAGER ? (
                        <EmployeeInstructionAssignmentsManagerView
                            employeeId={employee.id}
                            showOnlyOpened
                        />
                    ) : (
                        <OpenEmployeeInstructionAssignments />
                    ))}
                {selectedModule === modules.MEASURES &&
                    (role === roles.MANAGER ? (
                        <PendingEmployeeMeasureTasks employeeId={employee.id} />
                    ) : (
                        <PendingEmployeeMeasureTasks />
                    ))}
            </TabPanel>
            <TabPanel>
                {selectedModule === modules.INSTRUCTIONS &&
                    (role === roles.MANAGER ? (
                        <EmployeeInstructionAssignmentsManagerView
                            tabNumber={1}
                            employeeId={employee.id}
                        />
                    ) : (
                        <AllEmployeeInstructionAssignments />
                    ))}
                {selectedModule === modules.MEASURES &&
                    (role === roles.MANAGER ? (
                        <AllEmployeeMeasureTasks employeeId={employee.id} />
                    ) : (
                        <AllEmployeeMeasureTasks />
                    ))}
            </TabPanel>
        </Tabs>
    );
};

EmployeeViewTabs.propTypes = {
    selectedModule: PropTypes.string.isRequired,
    employee: PropTypes.object.isRequired,
};

export default EmployeeViewTabs;
