import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ContentSection from 'ProjectManager/DataManagement/Common/components/ContentSection';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import ProjectName from 'ProjectManager/Project/DataManagement/GeneralInformation/ProjectName';
import ContactPeople from 'ProjectManager/Project/DataManagement/GeneralInformation/ContactPeople/components/ContactPeople';
import Address from 'ProjectManager/Project/DataManagement/GeneralInformation/Address';
import Photo from 'ProjectManager/Project/DataManagement/GeneralInformation/Photo/Photo';
import { useWindowWidth } from '@react-hook/window-size';
import ClientForProjectData
    from 'ProjectManager/Project/DataManagement/GeneralInformation/Client/components/ClientForProjectData';

const Content = styled.div`
    @media screen and (min-width: 1180px) {
        display: flex;
        align-items: flex-start;
    }
`;

const Fields = styled.div`
    margin-bottom: 20px;

    @media screen and (min-width: 1180px) {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        order: 2;
        margin-left: 20px;
        margin-bottom: 0;
    }
`;

const Field = styled.div`
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: 1180px) {
        width: calc(50% - 10px);
        margin-right: 20px;

        &:nth-child(3) {
            margin-bottom: 0;
        }

        &:nth-child(2),
        &:nth-child(4) {
            margin-right: 0;
        }
    }
`;

const ProjectData = ({ isInsideDrawer }) => {
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth <= 540;

    const fixedSelectProps = {};

    if (isInsideDrawer && !isMobile) {
        fixedSelectProps.menuPosition = 'fixed';
        fixedSelectProps.menuPortalTarget = document.body;
    }

    return (
        <ContentSection>
            <OuterBoxTitle
                helpText={
                    <>
                        Kontaktperson: z. B. Baustellenverantwortliche(r),
                        Bauherr(in), Auftraggeber(in), Bauleiter(in),
                        Anlagenverantwortliche(r) oder Ansprechpartner(in) des
                        Fremdbetriebs
                    </>
                }
            >
                Projektdaten
            </OuterBoxTitle>
            <Content>
                <Fields>
                    <Field>
                        <ClientForProjectData {...fixedSelectProps} />
                    </Field>
                    <Field>
                        <ProjectName />
                    </Field>
                    <Field>
                        <ContactPeople {...fixedSelectProps} />
                    </Field>
                    <Field>
                        <Address />
                    </Field>
                </Fields>
                <Photo />
            </Content>
        </ContentSection>
    );
};

ProjectData.defaultProps = {
    isInsideDrawer: false,
};

ProjectData.propTypes = {
    isInsideDrawer: PropTypes.bool,
};

export default ProjectData;
