const dataTypes = {
    PDF_REPORT: 'PDF_REPORT',
    INSTRUCTION_CERTIFICATES: 'INSTRUCTION_CERTIFICATES',
    GENERAL_FILES: 'GENERAL_FILES',
    QUESTION_FILES: 'QUESTION_FILES',
};

export const dataTypesInfo = {
    [dataTypes.PDF_REPORT]: {
        title: 'Projektbericht',
        description:
            'Beinhaltet die Beantwortung aller Fragen, die Einweisungszertifikate und die  Unterweisungsstatistik',
    },
    [dataTypes.INSTRUCTION_CERTIFICATES]: {
        title: 'Unterweisungszertifikate',
        description:
            'Beinhaltet die Nachweise der aktuellen, jährlich absolvierten Unterweisungszertifikate aller einbezogenen Mitarbeiter in einer vordefinierten Ordnerstruktur',
    },
    [dataTypes.GENERAL_FILES]: {
        title: 'Allgemeine Projektdateien-Anhänge',
        description:
            'Alle Projektdateien, welche im Vorfeld hochgeladen wurden ind einer vordefinierten Ordnerstruktur',
    },
    [dataTypes.QUESTION_FILES]: {
        title: 'Fragedateien-Anhänge',
        description:
            'Beinhaltet alle hochgeladenen Dateien, welche den Fragen zugeordnet wurden, in einer vordefinierten Ordnerstruktur',
    },
};

export default dataTypes;
