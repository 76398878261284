import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
    flex: 1;
`;

const BackButtonPortal = () => <Wrapper id="backButton" />;

export default BackButtonPortal;
