import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import addTemplateCustomTopic from 'ProjectManager/Template/Common/api/dataManagement/customTopic/addTemplateCustomTopic';
import getTemplateQuestionnaireSections from 'ProjectManager/Template/Common/api/dataManagement/questionnaire/getTemplateQuestionnaireSections';
import withCustomTopic
    from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/customTopic/withCustomTopic';
import withQuestionnaireSections
    from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/withQuestionnaireSections';

const useCreateTemplateCustomTopicFunction = () => {
    const [{ id: templateId }, setTemplate] = useRecoilState(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const createCustomTopic = useCallback(
        async values => {
            const response = await addTemplateCustomTopic(
                templateId,
                values,
                source.token,
            );

            const questionnaireSections = await getTemplateQuestionnaireSections(
                templateId,
                source.token,
            );

            const newCustomTopic = response.data;

            setTemplate(withCustomTopic(newCustomTopic));
            setTemplate(withQuestionnaireSections(questionnaireSections));
        },
        [templateId, setTemplate, source.token],
    );

    return createCustomTopic;
};

export default useCreateTemplateCustomTopicFunction;
