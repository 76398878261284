import React, { useCallback } from 'react';
import NoTemplates from 'ProjectManager/Template/List/components/NoTemplates';
import Overview from 'ProjectManager/Template/List/components/Overview/Overview';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';
import { useRecoilState, useSetRecoilState } from 'recoil';
import templatesAtom from 'ProjectManager/Template/List/recoil/templates/templatesAtom';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import getTemplates from 'ProjectManager/Template/Common/api/getTemplates';
import groupsAtom from 'ProjectManager/Template/List/recoil/groups/groupsAtom';
import getTemplateGroups from 'ProjectManager/TemplateGroup/api/getTemplateGroups';

const Templates = () => {
    const setTemplates = useSetRecoilState(templatesAtom);
    const [groups, setGroups] = useRecoilState(groupsAtom);

    const templatesRequest = useCallback(
        cancelToken => getTemplates([], cancelToken),
        [],
    );

    const {
        loadData: loadTemplates,
        isLoading: isLoadingTemplates,
        hasError: hasErrorForTemplates,
    } = useAxiosRequest(templatesRequest, [], {
        customDataSetterFunction: setTemplates,
    });

    const {
        loadData: loadGroups,
        isLoading: isLoadingGroups,
        hasError: hasErrorForGroups,
    } = useAxiosRequest(getTemplateGroups, [], {
        customDataSetterFunction: setGroups,
    });

    const isLoading = isLoadingTemplates || isLoadingGroups;

    if (isLoading) {
        return <Loader />;
    }

    if (hasErrorForTemplates) {
        return <RetryLoading onRetry={loadTemplates} />;
    }

    if (hasErrorForGroups) {
        return <RetryLoading onRetry={loadGroups} />;
    }

    if (groups.length > 0) {
        return <Overview />;
    }

    return <NoTemplates />;
};

export default Templates;
