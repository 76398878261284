import api from 'Application/api/api';

const getLibraryInstruction = (id, load, cancelToken = null) =>
    api.get(`/education/library-instructions/${id}`, {
        params: {
            load,
        },
        cancelToken,
    });

export default getLibraryInstruction;
