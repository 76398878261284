import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import AvailableEmployees from 'ProjectManager/DataManagement/EmployeeSelection/components/AvailableEmployees';
import PersonAdd from 'Common/components/Icons/PersonAdd';
import Button from 'Common/components/Button';
import Modal from 'Common/components/Modals/Modal';
import ModalTitle from 'Common/components/Modals/ModalTitle';

const Wrapper = styled.div`
    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
        display: none;
    }
`;

const MobileAvailableEmployees = props => {
    const [isModalOpened, setIsModalOpened] = useState(false);

    const handleButtonClick = () => {
        setIsModalOpened(true);
    };

    const handleModalClose = () => {
        setIsModalOpened(false);
    };

    return (
        <Wrapper>
            <Button
                underline
                icon={<PersonAdd />}
                type="button"
                onClick={handleButtonClick}
            >
                Mitarbeiter hinzufügen
            </Button>
            {isModalOpened && (
                <Modal
                    isOpen
                    contentStyles={{
                        paddingRight: 0,
                    }}
                    onRequestClose={handleModalClose}
                    buttons={
                        <>
                            <Button type="button" onClick={handleModalClose}>
                                Fortsetzen
                            </Button>
                        </>
                    }
                >
                    <ModalTitle>Mitarbeiter hinzufügen</ModalTitle>
                    <AvailableEmployees isInsideModal {...props} />
                </Modal>
            )}
        </Wrapper>
    );
};

MobileAvailableEmployees.propTypes = {
    assignableEmployees: PropTypes.array.isRequired,
    participatingEmployees: PropTypes.array.isRequired,
    onAdd: PropTypes.func.isRequired,
    onAfterAdd: PropTypes.func.isRequired,
};

export default MobileAvailableEmployees;
