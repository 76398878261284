import React, { useState } from 'react';
import PropTypes from 'prop-types';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import axios from 'axios';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import useCustomTopicFunctions
    from 'ProjectManager/DataManagement/TopicManagement/components/CustomTopicManagement/hooks/customTopicFunctions/useCustomTopicFunctions';

const CustomTopicDeleteModal = ({ customTopic, onCancel, onDelete }) => {
    const { deleteCustomTopic } = useCustomTopicFunctions();

    const [isDeleting, setIsDeleting] = useState(false);

    const handleConfirm = async () => {
        setIsDeleting(true);

        try {
            await deleteCustomTopic(customTopic.id);
            await onDelete();

            notify('Das Projektfeld wurde erfolgreich gelöscht', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify('Das Projektfeld konnte nicht gelöscht werden', {
                    type: toast.TYPE.ERROR,
                });
            }
        }

        setIsDeleting(false);
    };

    return (
        <Modal
            isOpen
            onRequestClose={isDeleting ? undefined : onCancel}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onCancel}
                        disabled={isDeleting}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isDeleting}
                        onClick={handleConfirm}
                        disabled={isDeleting}
                    >
                        Löschen
                    </Button>
                </>
            }
        >
            <ModalTitle>
                Wollen Sie das Projektfeld {customTopic.name} wirklich löschen?
            </ModalTitle>
            <p>
                Solltest du dich entscheiden das ausgewählt Projektfeld
                abzuwählen, werden alle Projektfeld bezogenen Daten
                unwiderruflich gelöscht.
            </p>
        </Modal>
    );
};

CustomTopicDeleteModal.propTypes = {
    customTopic: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default CustomTopicDeleteModal;
