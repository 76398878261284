import React from 'react';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import Tile from 'ProjectManager/Project/Preview/Tile';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import LabeledValue from 'ProjectManager/Project/Preview/LabeledValue';
import { orderBy } from 'lodash';
import { EditRounded } from '@material-ui/icons';
import styled from 'styled-components/macro';
import Button from 'Common/components/Button';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import Statistics from 'ProjectManager/Project/Preview/Topics/Statistics';
import WarningMessage from 'Common/components/WarningMessage';

const Details = styled.div`
    margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
    padding-left: 0;
`;

const Topics = () => {
    const { id, topics, customTopics, status } = useRecoilValue(projectAtom);

    const isEditingPossible = status !== projectStatuses.ARCHIVED;

    const hasMissingRequiredData = topics.length + customTopics.length === 0;

    return (
        <>
            <Tile>
                <OuterBoxTitle>Projektfelder</OuterBoxTitle>
                <Details>
                    <LabeledValue label="Relevanter Projektfelder">
                        {topics.length > 0
                            ? orderBy(topics, ['name'], ['asc'])
                                  .map(topic => topic.name)
                                  .join(', ')
                            : '-'}
                    </LabeledValue>
                    <LabeledValue label="Eigene Projektfelder">
                        {customTopics.length > 0
                            ? orderBy(customTopics, ['name'], ['asc'])
                                  .map(customTopic => customTopic.name)
                                  .join(', ')
                            : '-'}
                    </LabeledValue>
                </Details>
                {isEditingPossible && (
                    <>
                        <StyledButton
                            underline
                            icon={<EditRounded />}
                            to={`/project-manager/project/${id}/edit`}
                        >
                            Auswahl Projektfelder
                        </StyledButton>
                    </>
                )}
                {hasMissingRequiredData && isEditingPossible && (
                    <WarningMessage>
                        Fehlende Projektfeld Auswahl
                    </WarningMessage>
                )}
                <Statistics />
            </Tile>
        </>
    );
};

export default Topics;
