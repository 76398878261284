import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import FormField from 'Common/components/Form/FormField';
import WorkingHours from 'ProjectManager/DataManagement/WorkingHours/components/WorkingHours';
import WorkingDays from 'ProjectManager/DataManagement/WorkingDays/components/WorkingDays';

const Wrapper = styled(FormField)`
    @media screen and (min-width: 790px) {
        display: flex;
        align-items: center;
    }
`;

const HoursWrapper = styled.div`
    max-width: 342px;
    margin-bottom: 20px;

    @media screen and (min-width: 790px) {
        flex: 0 0 342px;
        margin-right: 20px;
        margin-bottom: 0;
    }
`;

const WorkingHoursAndDays = ({ isInsideDrawer }) => (
    <Wrapper>
        <HoursWrapper>
            <WorkingHours isInsideDrawer={isInsideDrawer} />
        </HoursWrapper>
        <WorkingDays />
    </Wrapper>
);

WorkingHoursAndDays.defaultProps = {
    isInsideDrawer: false,
};

WorkingHoursAndDays.propTypes = {
    isInsideDrawer: PropTypes.bool,
};

export default WorkingHoursAndDays;
