import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';

const SuccessModal = ({ onClose }) => (
    <Modal
        isOpen
        onRequestClose={onClose}
        buttons={
            <Button type="button" onClick={onClose}>
                OK
            </Button>
        }
    >
        <ModalTitle>Einweisung erfolgreich erstellt</ModalTitle>
        <p>
            Die Einweisung wurde erfolgreich erstellt. Der
            Auftragsverantwortlich wurde in Form einer E-mail benachrichtigt.
            Bitte beachte, dass die Durchführung der Einweisung mit dem Datum
            des Projektstarts freigeschaltet wird.
        </p>
    </Modal>
);

SuccessModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default SuccessModal;
