const withoutGeneralFile = fileId => prevProjectOrTemplate => {
    const files = [...prevProjectOrTemplate.files];

    const index = files.findIndex(file => file.id === fileId);

    if (index !== -1) {
        files.splice(index, 1);
    }

    return {
        ...prevProjectOrTemplate,
        files,
    };
};

export default withoutGeneralFile;
