import api from 'Application/api/api';

const giveProjectQuestionSingleChoiceAnswer = (
    projectId,
    questionId,
    isCustom,
    answerId,
    cancelToken = null,
) =>
    api.post(
        `project-manager/projects/${projectId}/questionnaire/questions/${questionId}/${
            isCustom ? 1 : 0
        }/answers/single-choice`,
        {
            answerId,
        },
        { cancelToken },
    );

export default giveProjectQuestionSingleChoiceAnswer;
