import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import OuterBoxSubtitle from 'Common/components/Boxes/OuterBoxSubtitle';
import Comment from 'ProjectManager/Project/Introductions/Grid/components/Introduction/EditPointsDrawer/Comment';
import PointTile from 'ProjectManager/Project/Introductions/Grid/components/Introduction/EditPointsDrawer/PointTile';

const Points = styled.div`
    > div {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;

        > div {
            width: calc(50% - 10px);
            flex: 0 0 calc(50% - 10px);
            margin-right: 10px;
            margin-bottom: 0;

            &:nth-child(n + 3) {
                margin-top: 10px;
            }

            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }
`;

const PointsSelection = ({ points, ppes, introduction }) => (
    <>
        <OuterBoxTitle>Einweisungsthemen bestimmen</OuterBoxTitle>
        <OuterBoxSubtitle>
            Hier kannst du, falls gewollt, die Auswahl der Einweisungsthemen
            spezifizieren. Bitte beachte, dass du als Auftragsverantwortlicher
            verpflichtet bist die Einweisung gegebenenfalls zu wiederholen,
            sofern sich die projektbezogenen Gegebenheiten ändern.
        </OuterBoxSubtitle>
        <Points>
            {points.map(point => (
                <PointTile
                    key={point.value}
                    point={point}
                    ppes={ppes}
                    introduction={introduction}
                />
            ))}
        </Points>
        <Comment introduction={introduction} />
    </>
);

PointsSelection.propTypes = {
    points: PropTypes.array.isRequired,
    ppes: PropTypes.array.isRequired,
    introduction: PropTypes.object.isRequired,
};

export default PointsSelection;
