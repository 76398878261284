import React, { useCallback, useEffect, useState } from 'react';
import Loader from 'Common/components/Loader';
import { presentationStatuses } from 'Education/constants/presentationStatuses';
import SingleAssignmentStartScreen from 'Education/components/instruction-assignment/presentation/SingleAssignmentStartScreen';
import GroupAssignmentStartScreen from 'Education/components/instruction-assignment/presentation/GroupAssignmentStartScreen';
import resumeInstructionAssignmentPresentation from 'Education/api/instruction-assignment/presentation/resumeInstructionAssignmentPresentation';
import AssignmentPresentationInProgress from 'Education/components/instruction-assignment/presentation/AssignmentPresentationInProgress';
import { examStatuses } from 'Education/constants/examStatuses';
import CompletedPresentationScreen from 'Education/components/instruction-assignment/presentation/CompletedPresentationScreen';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import { Helmet } from 'react-helmet-async';
import { Redirect, useParams } from 'react-router';
import getInstructionAssignment from 'Education/api/instruction-assignment/getInstructionAssignment';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';
import BackButton from 'Application/components/Header/BackButton/BackButton';
import Button from 'Common/components/Button';
import OuterBoxButtons from 'Common/components/Boxes/OuterBoxButtons';

const AssignmentPresentation = () => {
    const [isInPreviewMode, setIsInPreviewMode] = useState(false);

    const { assignmentId } = useParams();

    const assignmentRequest = useCallback(
        cancelToken =>
            getInstructionAssignment(
                assignmentId,
                ['instruction', 'instruction.slides', 'groupAssignmentDetails'],
                cancelToken,
            ),
        [assignmentId],
    );

    const {
        data: assignment,
        loadData: loadAssignment,
        loadDataWithoutLoader: loadAssignmentWithoutLoader,
        isLoading,
        hasError,
    } = useAxiosRequest(assignmentRequest, {});

    const presentationStatus = assignment?.presentationStatus;

    useEffect(() => {
        if (presentationStatus === presentationStatuses.IN_PROGRESS) {
            // noinspection JSIgnoredPromiseFromCall
            resumeInstructionAssignmentPresentation(assignmentId);
        }
    }, [assignmentId, presentationStatus]);

    const handleViewPresentationAgain = () => {
        setIsInPreviewMode(true);
    };

    const handleNavigateBackFromPreviewMode = () => {
        setIsInPreviewMode(false);
    };

    const handleCompletePresentation = async () => {
        await loadAssignmentWithoutLoader();
        setIsInPreviewMode(false);
    };

    if (isLoading) {
        return <Loader />;
    }

    if (hasError) {
        return <RetryLoading onRetry={loadAssignment} />;
    }

    if (assignment !== null) {
        const breadcrumbs = (
            <>
                <Helmet>
                    <title>{`${assignment.name} - Aufgaben`}</title>
                </Helmet>
                <BackButton to="/dashboard" />
                <Breadcrumb to="/dashboard">Unterweisungen</Breadcrumb>
                <Breadcrumb isActive>
                    Präsentation - {assignment.name}
                </Breadcrumb>
            </>
        );

        if (
            assignment.presentationStatus === presentationStatuses.NOT_STARTED
        ) {
            if (assignment.isGroupAssignment) {
                return (
                    <>
                        {breadcrumbs}
                        <GroupAssignmentStartScreen
                            assignment={assignment}
                            onStart={loadAssignmentWithoutLoader}
                        />
                    </>
                );
            }

            return (
                <>
                    {breadcrumbs}
                    <SingleAssignmentStartScreen
                        assignment={assignment}
                        onStart={loadAssignmentWithoutLoader}
                    />
                </>
            );
        }

        if (
            isInPreviewMode ||
            assignment.presentationStatus === presentationStatuses.IN_PROGRESS
        ) {
            return (
                <>
                    {breadcrumbs}
                    <AssignmentPresentationInProgress
                        assignment={assignment}
                        initialSlideNumber={
                            isInPreviewMode
                                ? 1
                                : assignment.currentPresentationSlide
                        }
                        isInPreviewMode={isInPreviewMode}
                        onProgress={loadAssignmentWithoutLoader}
                        onComplete={handleCompletePresentation}
                    />
                    {isInPreviewMode && (
                        <OuterBoxButtons>
                            <Button
                                type="button"
                                onClick={handleNavigateBackFromPreviewMode}
                            >
                                Fortsetzen
                            </Button>
                        </OuterBoxButtons>
                    )}
                </>
            );
        }

        if (
            !isInPreviewMode &&
            assignment.presentationStatus === presentationStatuses.COMPLETED &&
            assignment.examStatus === examStatuses.NOT_STARTED
        ) {
            return (
                <>
                    {breadcrumbs}
                    <CompletedPresentationScreen
                        assignment={assignment}
                        onViewPresentationAgain={handleViewPresentationAgain}
                    />
                </>
            );
        }
    }

    return <Redirect to="/dashboard" />;
};

export default AssignmentPresentation;
