import api from 'Application/api/api';

const giveProjectQuestionMultipleChoiceAnswers = (
    projectId,
    questionId,
    isCustom,
    answerIds,
    cancelToken = null,
) =>
    api.post(
        `project-manager/projects/${projectId}/questionnaire/questions/${questionId}/${
            isCustom ? 1 : 0
        }/answers/multiple-choice`,
        {
            answerIds,
        },
        { cancelToken },
    );

export default giveProjectQuestionMultipleChoiceAnswers;
