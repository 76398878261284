import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { fill } from 'lodash';

const Wrapper = styled.div``;

const Dash = styled.div`
    width: 3px;
    height: 5px;
    margin-bottom: 2px;
    border-radius: 6px;
    background: ${colors.DIRTY_WHITE};

    &:last-child {
        margin-bottom: 0;
    }
`;

const Dashes = ({ count }) => (
    <Wrapper>
        {fill(Array(count), {}).map((element, index) => (
            <Dash key={index} />
        ))}
    </Wrapper>
);

Dashes.defaultProps = {
    count: 4,
};

Dashes.propTypes = {
    count: PropTypes.number,
};

export default Dashes;
