import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import EmployeeTile from 'ProjectManager/DataManagement/EmployeeSelection/components/EmployeeTile';
import Engineering from 'Common/components/Icons/Engineering';
import colors from 'Common/constants/colors';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';

const Heading = styled.div`
    display: flex;
    justify-content: space-between;
    // Add a little bit more space on the right (+20px), because of the space
    // which is on the right of the scrollbar of the list.
    padding: 10px 30px 10px 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
`;

const List = styled.div`
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-right: 10px;

    // This padding is needed, because otherwise the shadow of the last
    // item in the list will be cut-off, because of the overflow.
    padding-bottom: 15px;

    > div {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ${ifProp(
        'isInsideModal',
        css`
            max-height: calc(100vh - 390px);

            ::-webkit-scrollbar-track-piece {
                background-color: ${colors.DIRTY_WHITE};
            }

            ::-webkit-scrollbar-thumb {
                background-color: ${colors.LIGHT_GRAY};
            }
        `,
    )};
`;

const NoResults = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${colors.GRAY};
    min-height: 140px;

    svg {
        font-size: 48px;
    }
`;

const NoResultsText = styled.div`
    margin-top: 5px;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
`;

const EmployeeList = ({
    title,
    actionLabel,
    actionIcon,
    onActionButtonClick,
    isActionButtonDisabled,
    noResultsText,
    assignableEmployees,
    isInsideModal,
    animatedIds,
    animationText,
}) => (
    <>
        <Heading>
            <span>
                {title} ({assignableEmployees.length})
            </span>
            <span>{actionLabel}</span>
        </Heading>
        {assignableEmployees.length > 0 ? (
            <List isInsideModal={isInsideModal}>
                {assignableEmployees.map(assignableEmployee => {
                    const employee = assignableEmployee.employee;
                    const id = employee.id;

                    return (
                        <EmployeeTile
                            key={id}
                            employee={employee}
                            qualifications={assignableEmployee.qualifications}
                            actionLabel={actionLabel}
                            actionIcon={actionIcon}
                            onActionButtonClick={() => onActionButtonClick(id)}
                            isActionButtonDisabled={isActionButtonDisabled}
                            invertedColors={isInsideModal}
                            showAnimation={animatedIds.includes(id)}
                            animationText={animationText}
                        />
                    );
                })}
            </List>
        ) : (
            <NoResults>
                <Engineering />
                <NoResultsText>{noResultsText}</NoResultsText>
            </NoResults>
        )}
    </>
);

EmployeeList.defaultProps = {
    isActionButtonDisabled: false,
    isInsideModal: false,
    animatedAddedIds: [],
    animationText: '',
};

EmployeeList.propTypes = {
    title: PropTypes.string.isRequired,
    actionLabel: PropTypes.string.isRequired,
    actionIcon: PropTypes.any.isRequired,
    onActionButtonClick: PropTypes.func.isRequired,
    isActionButtonDisabled: PropTypes.bool,
    noResultsText: PropTypes.string.isRequired,
    assignableEmployees: PropTypes.array.isRequired,
    isInsideModal: PropTypes.bool,
    animatedIds: PropTypes.array,
    animationText: PropTypes.string,
};

export default EmployeeList;
