import api from 'Application/api/api';

const uploadProjectPhoto = (projectId, photoFile, cancelToken = null) => {
    const formData = new FormData();

    formData.append(`file`, photoFile);

    return api.post(`project-manager/projects/${projectId}/photo`, formData, {
        cancelToken,
        timeout: 30 * 1000,
    });
};

export default uploadProjectPhoto;
