import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import selectTemplateTopic from 'ProjectManager/Template/Common/api/dataManagement/topic/selectTemplateTopic';
import getTemplateQuestionnaireSections from 'ProjectManager/Template/Common/api/dataManagement/questionnaire/getTemplateQuestionnaireSections';
import deselectTemplateTopic from 'ProjectManager/Template/Common/api/dataManagement/topic/deselectTemplateTopic';
import withAdditionalTopic from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/topic/withAdditionalTopic';
import withoutTopic from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/topic/withoutTopic';
import withQuestionnaireSections
    from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/withQuestionnaireSections';

const useTemplateTopicFunctions = () => {
    const [{ id: templateId }, setTemplate] = useRecoilState(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const selectTopic = useCallback(
        async selectedTopic => {
            await selectTemplateTopic(
                templateId,
                selectedTopic.id,
                source.token,
            );

            const questionnaireSections = await getTemplateQuestionnaireSections(
                templateId,
                source.token,
            );

            setTemplate(withAdditionalTopic(selectedTopic));
            setTemplate(withQuestionnaireSections(questionnaireSections));
        },
        [templateId, setTemplate, source.token],
    );

    const deselectTopic = useCallback(
        async deselectedTopicId => {
            await deselectTemplateTopic(
                templateId,
                deselectedTopicId,
                source.token,
            );

            const questionnaireSections = await getTemplateQuestionnaireSections(
                templateId,
                source.token,
            );

            setTemplate(withoutTopic(deselectedTopicId));
            setTemplate(withQuestionnaireSections(questionnaireSections));
        },
        [templateId, setTemplate, source.token],
    );

    return { selectTopic, deselectTopic };
};

export default useTemplateTopicFunctions;
