import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { get } from 'lodash';
import loadInstructionAreaOptions from 'Education/utils/loadInstructionAreaOptions';
import Input from 'Common/components/Form/Fields/Input';
import Select from 'Common/components/Form/Fields/Select';
import AsyncSelect from 'Common/components/Form/Fields/AsyncSelect';
import Button from 'Common/components/Button';

const Wrapper = styled.div`
    padding: 15px;

    @media screen and (min-width: 880px) {
        padding-left: 10px;
        padding-right: 10px;
    }
`;

const Title = styled.div`
    margin-bottom: 20px;

    @media screen and (min-width: 880px) {
        margin-bottom: 15px;
    }
`;

const Content = styled.div``;

const Item = styled.div`
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }

    > * {
        margin-bottom: 10px;

        &:last-child {
            bottom: 0;
        }
    }

    @media screen and (min-width: 880px) {
        display: flex;
        flex-wrap: wrap;

        > * {
            width: 50%;
            flex: 0 0 50%;
            margin-bottom: 0;
            padding: 5px;
        }
    }
`;

const SlideButtonWrapper = styled.div`
    button {
        width: 100%;

        @media screen and (min-width: 880px) {
            height: 100%;
        }
    }
`;

const SelectedLibrariesForImportList = ({
    libraryInstructions,
    libraryInstructionValues,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    formFieldPrefix,
    onOpenSlidesButtonClick,
}) => (
    <Wrapper>
        <Title>Ausgewählte Unterweisungen</Title>
        <Content>
            {libraryInstructionValues.map((libraryInstructionValue, index) => {
                const prefix = `${formFieldPrefix}[${index}]`;

                const libraryInstruction = libraryInstructions.find(
                    libraryInstruction =>
                        libraryInstruction.id === libraryInstructionValue.id,
                );

                const allCount = libraryInstruction.slidesCount;

                const selectedCount =
                    libraryInstructionValue.slideIds?.length ?? allCount;

                return (
                    <Item key={libraryInstruction.id}>
                        <div>
                            <Input
                                label="Name"
                                id={`${prefix}.name`}
                                name={`${prefix}.name`}
                                error={get(errors, `${prefix}.name`)}
                                touched={get(touched, `${prefix}.name`, false)}
                                value={libraryInstructionValue.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </div>
                        <div>
                            <AsyncSelect
                                label="Bereiche"
                                id={`${prefix}.areas`}
                                name={`${prefix}.areas`}
                                error={get(errors, `${prefix}.areas`)}
                                touched={get(touched, `${prefix}.areas`, false)}
                                value={libraryInstructionValue.areas}
                                setFieldValue={setFieldValue}
                                onBlur={handleBlur}
                                loadOptions={loadInstructionAreaOptions}
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                isMulti
                                isSearchable
                                isClearable
                            />
                        </div>
                        <div>
                            <Select
                                id={`${prefix}.keepReference`}
                                name={`${prefix}.keepReference`}
                                label="Bibliothek Referenz"
                                error={get(errors, `${prefix}.keepReference`)}
                                touched={get(
                                    touched,
                                    `${prefix}.keepReference`,
                                    false,
                                )}
                                value={libraryInstructionValue.keepReference}
                                setFieldValue={setFieldValue}
                                onBlur={handleBlur}
                                options={[
                                    {
                                        value: true,
                                        label: 'Mit Referenz',
                                    },
                                    {
                                        value: true,
                                        label: 'Ohne Referenz',
                                    },
                                ]}
                                isSearchable={false}
                            />
                        </div>
                        <SlideButtonWrapper>
                            <Button
                                outline
                                type="button"
                                onClick={() =>
                                    onOpenSlidesButtonClick(
                                        libraryInstruction.id,
                                    )
                                }
                            >
                                {`Folienliste (${selectedCount} / ${allCount})`}
                            </Button>
                        </SlideButtonWrapper>
                    </Item>
                );
            })}
        </Content>
    </Wrapper>
);

SelectedLibrariesForImportList.propTypes = {
    libraryInstructions: PropTypes.array.isRequired,
    libraryInstructionValues: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    formFieldPrefix: PropTypes.string.isRequired,
    onOpenSlidesButtonClick: PropTypes.func.isRequired,
};

export default SelectedLibrariesForImportList;
