import styled from 'styled-components/macro';
import ModalWarningMessage from 'Common/components/Modals/WarningMessage';
import colors from 'Common/constants/colors';

const WarningMessage = styled(ModalWarningMessage)`
    margin-top: 5px;
    margin-bottom: 0;
    color: ${colors.WARNING};
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
`;

export default WarningMessage;
