import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';

const Wrapper = styled.label`
    display: block;
    margin-bottom: 8px;
    color: ${colors.LIGHT_GRAY};
    font-size: 14px;
    line-height: 21px;
`;

const Label = ({ label, hasError, ...props }) => (
    <Wrapper className={hasError ? 'has-error' : ''} {...props}>
        {label}
    </Wrapper>
);

Label.defaultProps = {
    hasError: false,
};

Label.propTypes = {
    label: PropTypes.string.isRequired,
    hasError: PropTypes.bool.isRequired,
};

export default Label;
