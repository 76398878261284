import { QuestionAnswerRounded } from '@material-ui/icons';
import { questionnaireSectionIcons } from 'ProjectManager/DataManagement/Navigation/constants/navigation';

const getQuestionnaireSectionIconComponent = section => {
    if (
        !section.isCustom &&
        questionnaireSectionIcons.hasOwnProperty(
            section.humanReadableIdentifier,
        )
    ) {
        return questionnaireSectionIcons[section.humanReadableIdentifier];
    }

    return QuestionAnswerRounded;
};

export default getQuestionnaireSectionIconComponent;
