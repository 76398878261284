import React from 'react';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import PropTypes from 'prop-types';

const NoTemplatesModal = ({
    group,
    isLoading,
    onConfirm,
    onCancel,
    ...props
}) => (
    <Modal
        isOpen
        onRequestClose={isLoading ? undefined : onCancel}
        buttons={
            <>
                <Button
                    text
                    dark
                    type="button"
                    onClick={onCancel}
                    disabled={isLoading}
                >
                    Abbrechen
                </Button>
                <Button
                    type="button"
                    isLoading={isLoading}
                    onClick={() => onConfirm()}
                    disabled={isLoading}
                >
                    Löschen
                </Button>
            </>
        }
        {...props}
    >
        <ModalTitle>Projektkategorie löschen</ModalTitle>
        <p>
            {`Bist du dir sicher, dass du die Kategorie "${group.name}" unwiderruflich löschen willst?`}
        </p>
    </Modal>
);

NoTemplatesModal.propTypes = {
    group: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default NoTemplatesModal;
