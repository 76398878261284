import api from 'Application/api/api';

const getInstructionAssignmentTodos = (load, cancelToken = null) =>
    api.get('/education/instruction-assignments/todos', {
        params: {
            load,
        },
        cancelToken,
    });

export default getInstructionAssignmentTodos;
