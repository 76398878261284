import api from 'Application/api/api';

const createProjectCustomQuestion = (
    projectId,
    customSubsectionId,
    data,
    cancelToken = null,
) =>
    api.post(
        `project-manager/projects/${projectId}/questionnaire/custom-subsections/${customSubsectionId}/custom-questions`,
        data,
        { cancelToken },
    );

export default createProjectCustomQuestion;
