import api from 'Application/api/api';

const requestFilesDownload = (templateId, cancelToken = null) =>
    api.post(
        `project-manager/templates/${templateId}/download-requests`,
        {},
        cancelToken,
    );

export default requestFilesDownload;
