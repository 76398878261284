import React, { useCallback } from 'react';
import Loader from 'Common/components/Loader';
import EmployeeView from 'Employee/components/EmployeeView';
import getCurrentEmployee from 'Employee/api/getCurrentEmployee';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';
import BackButton from 'Application/components/Header/BackButton/BackButton';

const EmployeeDashboard = () => {
    const { key } = useLocation();

    const employeeRequest = useCallback(
        cancelToken =>
            getCurrentEmployee(['completedInstructions'], cancelToken),
        [],
    );

    const {
        data: employee,
        loadData: loadEmployee,
        isLoading: isLoadingEmployee,
        hasError,
    } = useAxiosRequest(employeeRequest, null);

    const isLoading = isLoadingEmployee || employee === null;

    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <BackButton to="/dashboard" isActive={false} />
            {isLoading ? (
                <Loader />
            ) : hasError ? (
                <RetryLoading onRetry={loadEmployee} />
            ) : (
                <EmployeeView key={key} employee={employee} />
            )}
        </>
    );
};

export default EmployeeDashboard;
