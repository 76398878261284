import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import modules from 'Common/constants/modules';
import {
    ArchiveRounded,
    ExitToAppRounded,
    PeopleRounded,
    PersonRounded,
    SettingsRounded,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import logout from 'User/services/logout';
import axios from 'axios';
import OuterBox from 'Common/components/Boxes/OuterBox';
import BackButtonPortal from 'Application/components/Header/BackButton/BackButtonPortal';
import MoreActionsButton from 'Common/components/MoreActionsButton/MoreActionsButton';
import MenuItem from 'Common/components/MoreActionsButton/MenuItem';

const Wrapper = styled(OuterBox).attrs({
    as: 'header',
})`
    border-radius: 0;

    ${ifProp(
        'isReact',
        css`
            position: sticky;
            top: 0;
            z-index: 2000;
        `,
    )};
`;

const ConstrainedWidthContainer = styled.div`
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 10px;

    @media screen and (min-width: 500px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media screen and (min-width: 980px) {
        padding-left: 40px;
        padding-right: 40px;
    }
`;

const Inner = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 5px;

    @media screen and (min-width: 768px) {
        padding: 0 15px;
    }
`;

const Menu = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;

const MenuButton = styled(MoreActionsButton)`
    .MuiIconButton-root {
        padding: 0;
        outline: 0 none;
        font-size: 18px;
        line-height: 27px;
        width: 50px;
        height: 50px;
        border-radius: 0;

        @media screen and (min-width: 768px) {
            width: auto;
            height: auto;
        }

        &:hover {
            background-color: transparent;
        }
    }

    @media screen and (max-width: 767px) {
        svg {
            font-size: 30px !important;
        }
    }
`;

const MenuButtonText = styled.div`
    margin-right: 5px;
    display: none;

    @media screen and (min-width: 768px) {
        display: block;
    }
`;

const Logo = styled.div`
    position: relative;
    width: 140px;
    flex: 0 0 140px;

    @media screen and (min-width: 768px) {
        width: 163px;
        flex: 0 0 163px;
    }
`;

const LogoExternalLink = styled.a`
    display: block;
    width: 100%;
    text-decoration: none;
    outline: 0 none;
`;

const LogoReactLink = styled(LogoExternalLink).attrs({
    as: Link,
})``;

const LogoImage = styled.img`
    display: block;
    width: 100%;
`;

const moduleUnavailableMessage =
    'Freischaltung durch Produkterweiterung möglich, bitte kontaktieren Sie hierfür die Amedic GmbH & CO.KG';

const Header = ({ isManager, availableModules, isReact }) => {
    const [isLoggingOut, setIsLoggingOut] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleLogoutButtonClick = async () => {
        setIsLoggingOut(true);

        try {
            await logout(source.token);

            if (!isReact) {
                window.location.href = '/';
            }
        } catch (error) {
            if (!isReact) {
                window.location.href = '/';
                return;
            }

            if (!axios.isCancel(error)) {
                setIsLoggingOut(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const isModuleAvailable = useCallback(
        module => availableModules.includes(module),
        [availableModules],
    );

    const reactLinkProp = isReact ? 'to' : 'href';

    return (
        <Wrapper isReact={isReact}>
            <ConstrainedWidthContainer>
                <Inner>
                    <BackButtonPortal />

                    <Logo>
                        {isReact ? (
                            <LogoReactLink to="/dashboard">
                                <LogoImage
                                    src="/amedic-logo.svg"
                                    alt="Amedic-Manager"
                                />
                            </LogoReactLink>
                        ) : (
                            <LogoExternalLink href="/dashboard">
                                <LogoImage
                                    src="/amedic-logo.svg"
                                    alt="Amedic-Manager"
                                />
                            </LogoExternalLink>
                        )}
                    </Logo>

                    <Menu>
                        <MenuButton
                            icon={
                                <>
                                    <MenuButtonText>myAmedic</MenuButtonText>
                                    <PersonRounded />
                                </>
                            }
                            disableFocusRipple
                            disableRipple
                        >
                            <MenuItem
                                href="/backend/settings"
                                icon={<SettingsRounded />}
                            >
                                myEinstellungen
                            </MenuItem>
                            {isManager && (
                                <MenuItem
                                    {...{
                                        [reactLinkProp]: '/archive',
                                    }}
                                    icon={<ArchiveRounded />}
                                    isDisabled={
                                        !isModuleAvailable(modules.ARCHIVE)
                                    }
                                    tooltip={
                                        !isModuleAvailable(modules.ARCHIVE)
                                            ? moduleUnavailableMessage
                                            : ''
                                    }
                                >
                                    myArchiv
                                </MenuItem>
                            )}
                            {isManager ? (
                                <MenuItem
                                    type="button"
                                    icon={<PeopleRounded />}
                                    disabled
                                >
                                    myDSGVO
                                </MenuItem>
                            ) : (
                                <MenuItem
                                    href="/backend/dsgvo"
                                    icon={<PeopleRounded />}
                                >
                                    myDSGVO
                                </MenuItem>
                            )}
                            <MenuItem
                                type="button"
                                icon={<ExitToAppRounded />}
                                onClick={handleLogoutButtonClick}
                                isLoading={isLoggingOut}
                                disabled={isLoggingOut}
                            >
                                Abmelden
                            </MenuItem>
                        </MenuButton>
                    </Menu>
                </Inner>
            </ConstrainedWidthContainer>
        </Wrapper>
    );
};

Header.defaultProps = {
    isReact: true,
};

Header.propTypes = {
    isManager: PropTypes.bool.isRequired,
    availableModules: PropTypes.array.isRequired,
    isReact: PropTypes.bool,
};

export default Header;
