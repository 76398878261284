import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import deleteTemplate from 'ProjectManager/Template/Common/api/deleteTemplate';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import withoutTemplate from 'ProjectManager/Template/List/recoil/templates/modifiers/withoutTemplate';
import { useSetRecoilState } from 'recoil';
import templatesAtom from 'ProjectManager/Template/List/recoil/templates/templatesAtom';

const DeleteModal = ({ template, onClose, ...props }) => {
    const setTemplates = useSetRecoilState(templatesAtom);

    const [isDeleting, setIsDeleting] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirm = async () => {
        setIsDeleting(true);

        try {
            await deleteTemplate(template.id, source.token);

            setTemplates(withoutTemplate(template.id));

            onClose();

            notify('Die Vorlage wurde erfolgreich gelöscht', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify('Die Vorlage konnte nicht gelöscht werden', {
                    type: toast.TYPE.ERROR,
                });
            }
        }

        setIsDeleting(false);
    };

    return (
        <Modal
            isOpen
            onRequestClose={isDeleting ? undefined : onClose}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onClose}
                        disabled={isDeleting}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isDeleting}
                        onClick={handleConfirm}
                        disabled={isDeleting}
                    >
                        Löschen
                    </Button>
                </>
            }
            {...props}
        >
            <ModalTitle>Löschung der Vorlage</ModalTitle>
            <p>
                {`Du bist dabei die ausgewählte Vorlage "${template.name !== '' ? template.name : 'Kein Name'}" unwiderruflich zu löschen. Bist du dir sicher?`}
            </p>
        </Modal>
    );
};

DeleteModal.propTypes = {
    template: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default DeleteModal;
