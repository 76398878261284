import React from 'react';
import Box from 'Application/components/Static/Box';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import { Helmet } from 'react-helmet-async';
import BackButton from 'Application/components/Header/BackButton/BackButton';

const PrivacyPolicy = () => (
    <Box>
        <Helmet>
            <title>Datenschutzerklärung</title>
        </Helmet>
        <BackButton to="/dashboard" />
        <Breadcrumb isActive>Datenschutzerklärung</Breadcrumb>
        <h1>Datenschutzerklärung</h1>
        <br />

        <h2>Liebe Amedic Kunden</h2>
        <div>
            <p>
                Ab dem 25. Mai 2018 gilt auch in Deutschland die neue
                Datenschutzgrundverordnung (DSGVO) der Europäischen Union (EU).
                Das neue EU-Recht schafft einen einheitlichen gesetzlichen
                Rahmen für den Datenschutz in der EU. Kern der DS-GVO ist, den
                Menschen die Hoheit über ihre Daten zurückzugeben. Dieses
                Anliegen ist uns als Unternehmen sehr wichtig. Deshalb möchten
                wir Sie darüber informieren, wie die Amedic GmbH & Co.KG ab dem
                25. Mai 2018 Ihre Daten verarbeitet.
            </p>
            <em>Zuletzt aktualisiert: Mai 2018</em>
            <p>
                Wir respektieren Ihre Privatsphäre und Ihr Recht auf angemessene
                Kontrolle Ihrer personenbezogenen Daten. Unsere allgemeinen
                Richtlinien sind einfach. Wir legen klar und deutlich dar,
                welche Daten wir erfassen und warum wir das tun. Ihre Daten
                werden von uns besonders geschützt. Wir verkaufen Ihre Daten
                nicht an Dritte – weder aktuell noch in Zukunft.
            </p>
            <p>
                Hauptzweck der Datenerfassung ist es, unsere Leistung
                kontinuierlich zu verbessern.
            </p>
            <p>
                Diese Datenschutzerklärung (nachstehend als 'Erklärung'
                bezeichnet) gibt Aufschluss darüber, welche personenbezogenen
                Daten wir entweder direkt oder indirekt von Ihnen erfassen und
                wie wir diese nutzen. Personenbezogene Daten sind Informationen,
                anhand derer Sie identifiziert werden können, oder die wir mit
                Ihnen verknüpfen können. Diese Erklärung gibt auch an, welche
                Entscheidungen Sie über die von uns erhobenen Daten treffen
                können und wie Sie diese Entscheidungen steuern können. Sie gilt
                für alle Amedic Produkte (nachstehend als 'Amedic Produkt(e)'
                bezeichnet), darunter auch unsere App (nachstehend als 'Amedic
                App' oder 'App' bezeichnet). Darüber hinaus gilt sie für alle
                von Amedic kontrollierten Websites und sonstige Interaktionen
                außerhalb des Internets, wie beispielsweise die Teilnahme an
                Informationsveranstaltungen (nachstehend gemeinsam als
                'Dienstleistungen' bezeichnet). Bitte lesen Sie sich diese
                Erklärung sorgfältig durch, um zu erfahren, wie Ihre
                personenbezogenen Daten erfasst oder/und verwendet werden.
            </p>
        </div>
        <br />

        <h2>Die Arten der von uns erfassten personenbezogenen Daten</h2>
        <div>
            <h3>Daten, die Sie uns zur Verfügung stellen</h3>
            <p>
                Wir erfassen automatisch Daten über Sie, wie z. B.
                Funktionsdaten und Zusätzliche Nutzungsdaten über die Nutzung
                Ihrer Amedic Produkte oder Daten, die von Cookies und
                vergleichbaren Technologien erfasst werden, wenn Sie auf unsere
                Websites oder App zugreifen, diese verwenden oder mit uns über
                diese in Kontakt treten.
            </p>

            <h3>Daten, die wir automatisch erfassen</h3>
            <p>
                Wir erfassen automatisch Daten über Sie, wie z. B.
                Funktionsdaten und Zusätzliche Nutzungsdaten über die Nutzung
                Ihrer Amedic Produkte oder Daten, die von Cookies und
                vergleichbaren Technologien erfasst werden, wenn Sie auf unsere
                Websites oder App zugreifen, diese verwenden oder mit uns über
                diese in Kontakt treten.
            </p>
        </div>
        <br />

        <h2>Interaktionen der Kunden mit Amedic</h2>
        <div>
            <p>
                Um die Informationen in dieser Erklärung leicht verständlich zu
                machen, haben wir verschiedene Situationen hervorgehoben, in
                denen Amedic potentiell personenbezogene Daten von Kunden
                erfassen könnte. Die Interaktionen der Kunden mit Amedic sind in
                die folgenden Kategorien unterteilt:
            </p>

            <h3>Erster Kontakt mit Amedic</h3>
            <div>
                <p>
                    Wenn Sie weitere Informationen über Amedic einholen möchten,
                    besuchen Sie möglicherweise unsere Website. Dazu gehört
                    beispielsweise das Surfen auf unserer Website, ein
                    Telefongespräch mit unserem Team, der Besuch oder die
                    Teilnahme an einer Veranstaltung . Um mehr über Amedic
                    Produkte zu erfahren, stellen Sie uns in diesem Zuge unter
                    Umständen freiwillig personenbezogene Daten, wie
                    beispielsweise Ihren Namen, Firmendaten und Ihre
                    E-Mail-Adresse zur Verfügung.
                </p>
                <p>
                    <strong>Erfasste Daten:</strong> Name, E-Mail-Adresse,
                    Firmendaten.
                </p>
                <p>
                    <strong>Zweck der Erfassung:</strong> Wir verwenden diese
                    Daten, um Sie über bevorstehende Anpassungen und
                    Produktneuheiten von Amedic zu informieren. Darüber hinaus
                    helfen uns die IP-Adressen eine rechtssichere
                    Personenidentifikation/Personenverifikation bzgl. der
                    Benutzerzugänge nachzuweisen.
                </p>
            </div>

            <h3>Erwerb von Amedic Produkten</h3>
            <div>
                <p>
                    Wenn die Entscheidung für ein Amedic Produkt gefallen ist,
                    können Sie es entweder direkt über uns erwerben. Während des
                    Vorgangs werden Sie gebeten, uns Informationen
                    bereitzustellen, damit wir Ihren Erwerb abschließen können.
                </p>
                <p>
                    <strong>Erfasste Daten:</strong> Name, Telefonnummer
                    (optional), E-Mail-Adresse, Ihre Lieferadresse und
                    Zahlungsinformationen.
                </p>
                <p>
                    <strong>Zweck der Erfassung:</strong> Wir verwenden diese
                    Informationen, um Ihre Transaktion abzuschließen,
                    nachzufragen, ob Sie mit dem Erwerb zufrieden sind, Ihnen
                    bei Lieferproblemen zu helfen, und sonstige Probleme im
                    Hinblick auf Ihr erworbenes Amedic Produkt zu lösen. Die
                    Verarbeitung Ihrer personenbezogenen Daten für diesen Zweck
                    ist notwendig für die Erfüllung des mit Ihnen eingegangenen
                    Vertrages.
                </p>
            </div>

            <h3>Registrierung Ihres Amedic Produktes</h3>
            <div>
                <p>
                    Im Zuge der Ersteinrichtung werden Sie gebeten, die App zur
                    Steuerung von unserer Website oder der Website einer
                    Drittpartei, wie beispielsweise dem Google Play Store,
                    herunterzuladen. Mithilfe der Amedic App können Sie Ihr
                    Amedic System über ein Gerät Ihrer Wahl, wie z. B. Ihr Handy
                    oder Tablet, bedienen. Während der Einrichtung fordert Sie
                    die Amedic App auf, sich anzumelden.
                </p>
                <p>
                    Bei der Registrierung Ihres Amedic Produkts fordert Sie die
                    App dazu auf, Informationen, wie z. B. Ihren Benutzernamen,
                    Passwort und Firmendaten einzugeben. Diese Informationen
                    sind für die Registrierung Ihres Amedic Produktes
                    erforderlich. Ihr Amedic System weist Ihnen dann eine
                    eindeutige alphanumerische Identifikationsnummer zu, die wir
                    verwenden, um Ihr Amedic System ohne direkten Verweis auf
                    Ihre Person identifizieren zu können.
                </p>
                <p>
                    <strong>Erfasste Daten:</strong> Name, E-Mail-Adresse,
                    Firmenanschrift, Benutzername, Passwort, Telefonnummer,
                    IP-Adresse.
                </p>
                <p>
                    <strong>Zweck der Erfassung:</strong> Wir erfassen diese
                    Informationen, um eine sichere Funktionsweise Ihres Amedic
                    Produkts gewährleisten zu können. Die Verarbeitung ist
                    notwendig für die Erfüllung des mit Ihnen eingegangenen
                    Vertrages. Darüber hinaus verwenden wir Ihre E-Mail-Adresse,
                    um Ihnen Mitteilungen von Amedic, wie beispielsweise
                    Werbeankündigungen und Software-Updates, zukommen zu lassen,
                    Sie über Amedic Produkte und/oder Dienstleistungen zu
                    informieren und Ihnen von Neuigkeiten und Produktangeboten
                    von Amedic zu berichten.
                </p>
            </div>

            <h3>Verwendung von Amedic Produkten</h3>
            <div>
                <p>
                    Wir erfassen Informationen über Ihre Amedic Produkte
                    vorrangig aus den folgenden drei Gründen: (1) um Ihnen Ihre
                    dokumentationspflichtigen Nachweise zur Verfügung zu
                    stellen, (2) um Ihnen die Steuerung Ihres Amedic Systems zu
                    ermöglichen, was für die Erfüllung des mit Ihnen
                    eingegangenen Vertrages notwendig ist und (3) um Ihre Amedic
                    Produkte fortwährend zu verbessern, was unser berechtigtes
                    Interesse ist.
                </p>
                <p>
                    Amedic Produkte sind dafür vorgesehen,
                    arbeitsschutzrelevante Inhalte zur Verfügung zu stellen.
                </p>
            </div>

            <h3>Funktionsdaten</h3>
            <div>
                <p>
                    Diese Daten sind zwingend notwendig, um zu gewährleisten,
                    dass Ihr Amedic Produkt grundlegende Funktionen sicher
                    ausführt; Sie können der Erfassung, Weitergabe und/oder
                    Verarbeitung dieser Daten (nachstehend als „Funktionsdaten“
                    bezeichnet) nicht widersprechen, wenn Sie die Amedic
                    Produkte auch weiterhin verwenden möchten.
                </p>
                <p>
                    <strong>Erfasste Daten:</strong>
                    <ul>
                        <li>
                            <strong>Registrierungsdaten.</strong> Zu diesen
                            Daten zählen Ihre E-Mail-Adresse, Firmenanschrift,
                            IP-Adresse und Anmeldedaten für Ihren Amedic Account
                            (wie oben beschrieben).
                        </li>
                        <li>
                            <strong>Systemdaten.</strong> Zu diesen Daten zählt
                            das Betriebssystem des Endgerätes.
                        </li>
                    </ul>
                </p>
                <p>
                    <strong>Zweck der Erfassung von Funktionsdaten:</strong> Wir
                    erfassen diese Informationen, um die reibungslose
                    Funktionsweise Ihrer Amedic Produkte zu gewährleisten und
                    Ihnen Support-Leistungen bereitzustellen. Wir erfassen diese
                    Informationen außerdem, um Produktverbesserungen und
                    Entscheidungen beim Kunden-Support zu steuern, was unser
                    berechtigtes Interesse ist.
                </p>
            </div>

            <h3>Zusätzliche Nutzungsdaten</h3>
            <div>
                <p>
                    Um die bestmögliche Funktionsweise Ihres Amedic Produktes
                    gewährleisten zu können und sicherzustellen, dass unsere
                    Produkte und Dienstleistungen den Anforderungen und
                    Erwartungen unserer Kunden entsprechen, erfassen wir die
                    folgenden zusätzlichen Nutzungsdaten.
                </p>
                <p>
                    <strong>Erfasste Daten:</strong>
                    <ul>
                        <li>
                            <strong>Leistungs- und Aktivitätsdaten.</strong>{' '}
                            Dazu zählen unter anderem Ladezeiten, die mit Ihrem
                            Amedic System verbunden sind, Informationen darüber,
                            wie häufig Sie die Amedic App nutzen, Interaktionen
                            innerhalb der Amedic App, wenn die Amedic App in
                            Gebrauch ist, und wie lange das Amedic Produkt
                            eingesetzt wird.
                        </li>
                    </ul>
                </p>
                <p>
                    <strong>Zweck der Erfassung:</strong> Wir erfassen diese
                    Informationen, um die reibungslose Funktionsweise der Amedic
                    Produkte sicherzustellen, zu ermitteln, welche
                    Produktverbesserungen oder Funktionserweiterungen unseren
                    Kunden am besten gefallen würden und um potenzielle Probleme
                    mit den Amedic Produkten voraussehen zu können. Die
                    Erfassung dieser Daten ist unser berechtigtes Interesse, um
                    Ihnen bei Problemen zu helfen. Um der Weitergabe dieser
                    Daten zu widersprechen, kontaktieren Sie uns unter{' '}
                    <a href="mailto:info@amedic.de">info@amedic.de</a>. Sie
                    können der Erfassung, Weitergabe und/oder Verarbeitung
                    dieser Daten (nachstehend als „Funktionsdaten“ bezeichnet)
                    nicht widersprechen, wenn Sie die Amedic Produkte auch
                    weiterhin verwenden möchten.
                </p>
            </div>

            <h3>Unterstützung durch den Amedic Support</h3>
            <div>
                <p>
                    Es kann gelegentlich vorkommen, dass Sie Kontakt zu unserem
                    Kunden-Support aufnehmen, um Hilfe bei Ihrem Amedic
                    Produkten anzufordern. Um Ihnen den bestmöglichen Service
                    bieten zu können, erfassen wir ggf. Daten von Ihnen oder
                    Ihrem Amedic Produkt.
                </p>
                <p>
                    <strong>Erfasste Daten:</strong> Zu diesen Informationen
                    zählen unter anderem Kontaktdaten, wie beispielsweise Name,
                    E-Mail-Adresse, Telefonnummer, die Inhalte des Gesprächs und
                    sonstiger Kommunikationen mit der Amedic.
                </p>
                <p>
                    <strong>Zweck der Erfassung:</strong> Wir erfassen diese
                    Informationen, um Ihnen den besten und effizientesten
                    Support bieten zu können. Bei Kundenfragen und technischen
                    Fragen benutzen wir beispielsweise Kontaktdaten, um dem
                    Kunden über dessen bevorzugten Kommunikationskanal (Telefon,
                    E-Mail,) zu antworten. Bei Anpassungen des Produkts
                    verwenden wir Kontaktdaten, um zu gewährleisten, dass der
                    Empfang und/oder die Lieferung des Produkts erfolgreich
                    ausgeführt wird. Die Verwendung dieser Daten ist notwendig,
                    um den mit Ihnen eingegangenen Vertrag zu erfüllen.
                </p>
            </div>
        </div>
        <br />

        <h2>Wie wir personenbezogene Daten verwenden</h2>
        <div>
            <p>
                Amedic verwendet die erfassten Daten, um die angebotenen Amedic
                Produkte und Dienstleistungen bereitstellen zu können; das
                schließt die Nutzung von Daten zur Verbesserung und
                Personalisierung ein. Darüber hinaus verwenden wir diese Daten,
                um mit Ihnen in Kontakt zu treten, z. B. um Sie über Ihren
                Account über neue Amedic Produkte oder Services,
                Sicherheitsbelange und andere Neuigkeiten zu informieren.
            </p>

            <p>Amedic verwendet die Daten zu den folgenden Zwecken:</p>
            <ul>
                <li>Bereitstellung unserer Produkte</li>
                <li>Kunden-Support</li>
                <li>Produktverbesserungen</li>
                <li>Sicherheit und Streitbeilegung</li>
                <li>Rechnungslegung</li>
                <li>Kommunikation</li>
            </ul>

            <h3>Bereitstellung unserer Produkte</h3>
            <p>
                Wir verwenden Daten, um die von uns angebotenen Amedic Produkte
                und Dienstleistungen bereitzustellen, zu betreiben, zu
                unterstützen und zu verbessern. Diese Verarbeitung ist notwendig
                für die Erfüllung des mit Ihnen eingegangenen Vertrages.
            </p>

            <h3>Kunden-Support</h3>
            <p>
                Wir verwenden Daten, um Kundenfragen zu beantworten,
                Produktprobleme zu diagnostizieren, und sonstige Kundendienst-
                und Support-Leistungen bereitzustellen. Diese Verarbeitung ist
                für die Erfüllung des mit Ihnen eingegangenen Vertrages und auch
                um unserem berechtigten Interesse zu dienen, notwendig.
            </p>

            <h3>Produktverbesserungen</h3>
            <p>
                Wir verwenden kontinuierlich Daten um unsere Amedic Produkte und
                Dienstleistungen weiterzuentwickeln und zu verbessern. Darunter
                der Erhalt und die Verbesserung der Leistungsfähigkeit der
                Amedic Produkte und Dienstleistungen, die Entwicklung und
                Erweiterung um neue Funktionen oder Fähigkeiten, wie z. B. der
                Einsatz von Fehlermeldungen zur Steigerung des
                Sicherheitsniveaus, Diese Verarbeitung ist notwendig, um unserem
                berechtigten Interesse zu dienen.
            </p>

            <h3>Sicherheit und Streitbeilegung</h3>
            <p>
                Wir verwenden Daten, um die Sicherheit unserer Amedic Produkte
                und Kunden zu gewährleisten, Betrug aufzudecken und zu
                verhindern, Streitigkeiten beizulegen und unsere Vereinbarungen
                durchzusetzen. Diese Verarbeitung ist notwendig, um unserem
                berechtigten Interesse zu dienen.
            </p>

            <h3>Kommunikation</h3>
            <p>
                Wir verwenden die von uns erfassten Daten, um unsere
                Mitteilungen für Sie zu verfassen und zu personalisieren. So
                nehmen wir beispielsweise per E-Mail oder durch andere
                elektronische Kommunikationsmittel (einschließlich der Amedic
                App) Kontakt zu Ihnen auf, um Sie über neue Amedic Produkte oder
                Dienstleistungen sowie Sicherheits- oder Software-Updates zu
                informieren, Ihnen in Support-Angelegenheiten zu helfen. Diese
                Verarbeitung ist notwendig, um unserem berechtigten Interesse zu
                dienen.
            </p>
        </div>
        <br />

        <h2>
            Die rechtlichen Grundlagen für die Verwendung personenbezogener
            Daten
        </h2>
        <div>
            <p>
                Wir stützen uns bei der Nutzung Ihrer personenbezogenen Daten
                auf verschiedene rechtliche Grundlagen, und zwar:
            </p>

            <h3>Erfüllung eines Vertrages</h3>
            <p>
                Die Nutzung Ihrer personenbezogenen Daten ist ggf. notwendig, um
                den von Ihnen mit uns eingegangenen Vertrag zu erfüllen –
                beispielsweise, um den Erwerb Ihres Amedic Produktes
                abzuschließen, zu registrieren und Ihren Account zu verwalten,
                Ihr Amedic System zu registrieren, zu gewährleisten, dass Ihr
                Amedic Produkt grundlegende Funktionen sicher ausführt, oder
                Ihre Fragen zu beantworten.
            </p>
        </div>
        <br />

        <h2>
            Sicherheit, Speicherung und Aufbewahrung der durch uns erfassten
            personenbezogenen Daten
        </h2>
        <div>
            <h3>Sicherheit</h3>
            <p>
                Amedic hat sich dem Schutz Ihrer personenbezogenen Daten
                verpflichtet. Obwohl wir angemessene Schutzmaßnahmen ergreifen,
                um Ihre personenbezogenen Daten zu schützen, die wir erfassen,
                weisen wir darauf hin, dass kein Sicherheitssystem unfehlbar
                ist.
            </p>
            <p>
                Wir verwenden mehrere angemessene technische und
                organisatorische Maßnahmen und Branchenstandards, um Ihre
                personenbezogenen Daten zu schützen und somit Verlust,
                Diebstahl, Missbrauch, unbefugten Zugriff und unberechtigte
                Offenlegung, Veränderung und Vernichtung zu verhindern. Wir
                speichern z. B. die von Ihnen bereitgestellten personenbezogenen
                Daten auf Computersystemen mit beschränktem Zugriff, die sich in
                kontrollierten Bereichen befinden. So beauftragen wir lediglich
                nur Drittanbieter deren Rechenzentren angemessene
                Sicherheitsmaßnahmen durchführen. Darüber hinaus werden Ihre
                Daten während der Übermittlung im Internet durch
                Verschlüsselungstechnologien geschützt.
            </p>
            <p>
                Sie können ausschließlich anhand Ihres Amedic Benutzernamens und
                Passworts Zugriff auf Ihre Amedic Account-Daten und unsere
                Dienste erhalten. Um die Vertraulichkeit Ihrer personenbezogenen
                Daten zu wahren, sind Sie verpflichtet, Ihr Passwort geheim zu
                halten und nicht an Dritte weiterzugeben. Bitte teilen Sie uns
                unverzüglich mit, wenn Sie Grund zu der Annahme haben, dass Ihr
                Passwort missbräuchlich verwendet wurde. Achten Sie außerdem
                darauf, dass Sie sich immer abmelden und Ihren Browser zu
                schließen, wenn Ihre Sitzung beendet ist. Bitte beachten Sie,
                dass wir Sie niemals nach Ihrem Passwort fragen werden.
            </p>

            <p>
                Wenn Sie Fragen zur Sicherheit Ihrer personenbezogenen Daten
                haben, schreiben Sie uns eine E-Mail an{' '}
                <a href="mailto:info@amedic.de">info@amedic.de</a>.
            </p>

            <h3>Speicherung</h3>
            <p>
                Von Amedic erfasste personenbezogene Daten werden ausschließlich
                in Deutschland (in Strato Rechenzentren) gespeichert und
                verarbeitet.
            </p>

            <h3>Aufbewahrung</h3>
            <p>
                Amedic wird Ihre personenbezogenen Daten so lange aufbewahren,
                wie wir es für notwendig erachten, um Ihnen zu ermöglichen, die
                Website und Ihr Amedic Produkt zu verwenden, um Ihnen
                Dienstleistungen zu erbringen, um die geltenden Gesetze
                einzuhalten (einschließlich der Gesetze in Bezug auf die
                Aufbewahrung von Dokumenten) und für andere notwendige Zwecke,
                die es uns erlauben, unsere Geschäfte zu führen. Alle
                personenbezogenen Daten, die wir aufbewahren, unterliegen dieser
                Datenschutzerklärung und unseren internen
                Aufbewahrungsrichtlinien. Wenn Sie konkret wissen möchten, wie
                lange wir bestimmte von uns verarbeitete personenbezogene Daten
                von Ihnen aufbewahren, schreiben Sie uns eine E-Mail{' '}
                <a href="mailto:info@amedic.de">info@amedic.de</a>.
            </p>
        </div>
        <br />

        <h2>Ihre Rechte und Wahlmöglichkeiten</h2>
        <div>
            <h3>Ihre Rechte</h3>
            <p>
                Bei uns bestimmen Sie, wie wir Ihre personenbezogenen Daten
                nutzen. Vorbehaltlich örtlicher Gesetze können Sie das wie folgt
                tun:
            </p>
            <ul>
                <li>
                    Sie können uns bitten, eine Kopie der personenbezogenen
                    Daten, die wir über Sie erfasst haben, zukommen zu lassen.
                </li>
                <li>
                    Sie können uns davon in Kenntnis setzen, falls sich Ihre
                    personenbezogenen Daten geändert haben oder falls Sie
                    möchten, dass wir die von uns über Sie erfassten
                    personenbezogenen Daten ändern.
                </li>
                <li>
                    In bestimmten Situationen können Sie uns bitten, die von uns
                    über Sie erfassten personenbezogenen Daten zu löschen, zu
                    blockieren oder einzuschränken, oder bestimmte Formen
                    unserer Nutzung Ihrer personenbezogenen Daten ablehnen.
                </li>
                <li>
                    In bestimmten Situationen können Sie uns auch bitten, die
                    personenbezogenen Daten an eine Drittpartei zu senden.
                </li>
            </ul>
            <p>
                Sie haben das Recht darauf, Ihre Zustimmung zur Nutzung Ihrer
                personenbezogenen Daten jederzeit zurückzuziehen. Darüber hinaus
                haben Sie das Recht darauf, die Nutzung Ihrer personenbezogenen
                Daten jederzeit abzulehnen, wenn wir Ihre personenbezogenen
                Daten auf Grundlage eines berechtigten Interesses oder des
                Gemeinwohls verarbeiten.
            </p>
            <p>
                Wir verlassen uns darauf, dass Sie die Vollständigkeit,
                Richtigkeit und Aktualität Ihrer personenbezogenen Daten
                gewährleisten. Bitte setzen Sie uns unverzüglich über Änderungen
                oder Ungenauigkeiten Ihrer personenbezogenen Daten in Kenntnis,
                indem Sie eine E-Mail an{' '}
                <a href="mailto:info@amedic.de">info@amedic.de</a> senden.
            </p>

            <p>
                Wir kommen Ihrer Anfrage so schnell wie möglich, aber spätestens
                innerhalb von 30 Tagen nach.
            </p>

            <h3>Ihre Wahlmöglichkeiten</h3>
            <p>
                <strong>Amedic Account und Amedic App</strong>
            </p>
            <p>
                Wenn Sie auf Ihre Profilinformationen zugreifen bzw. diese
                bearbeiten oder entfernen möchten, Ihr Passwort ändern, Ihren
                Account schließen oder die Löschung Ihrer personenbezogenen
                Daten beantragen möchten, melden Sie sich einfach bei uns und
                senden uns eine E-Mail an:{' '}
                <a href="mailto:info@amedic.de">info@amedic.de</a>.
            </p>
        </div>
        <br />

        <h2>Fragen & Antworten</h2>
        <div>
            <p>
                Hier finden Sie Fragen & Antworten zu unserer
                Datenschutzerklärung. Wir empfehlen Ihnen, die vollständige
                Datenschutzerklärung durchzulesen.
            </p>

            <p>
                <strong>
                    Warum haben wir die Datenschutzerklärung von Amedic GmbH &
                    Co.KG aktualisiert?
                </strong>
                <br />
                Mit dieser Aktualisierung folgen wir den strengen
                Datenschutzbestimmungen, die in der
                EU-Datenschutz-Grundverordnung (DSGVO) festgelegt sind. Die
                aktualisierte Datenschutzerklärung ist außerdem klarer und
                einfacher formuliert.
            </p>

            <p>
                <strong>Was ist die DSGVO?</strong>
                <br />
                Die DSGVO ist eine Verordnung der Europäischen Union, mit der
                die Regeln zum Datenschutz EU-weit verbessert und
                vereinheitlicht werden. Und weil wir der Ansicht sind, dass für
                alle unsere Kunden derselbe Datenschutz gelten sollte,
                übernehmen wir die Bestimmungen der DSGVO weltweit. Die DSGVO
                schreibt vor, dass Unternehmen offen und transparent darlegen,
                wie personenbezogene Daten erfasst, gespeichert und verwendet
                werden. Für die Nutzung personenbezogener Daten gelten außerdem
                strengere Auflagen.
            </p>

            <p>
                <strong>
                    Wie oft wird die Datenschutzerklärung von Amedic
                    aktualisiert?
                </strong>
                <br />
                Immer dann, wenn es erforderlich ist, unsere Richtlinien auf
                internationale Vorschriften, unsere Produkte und
                Geschäftspraktiken abzustimmen.
            </p>

            <p>
                <strong>Welche Daten erfasst Amedic von mir?</strong>
                <br />
                Amedic erfasst Namen, Telefonnummer, E-Mail-Adresse,
                Lieferadresse, Zahlungsinformationen, Firmenanschrift und ggf.
                IP-Adresse.
            </p>

            <p>
                <strong>Warum braucht Amedic diese Daten?</strong>
                <br />
                Amedic verwendet die erfassten Daten, um Ihnen das beste
                Erlebnis mit unseren Produkten und Services zu bieten. Das
                beinhaltet die Nutzung von Daten zur Verbesserung und
                Personalisierung Ihres Systems. Außerdem verwenden wir die
                Daten, um mit Ihnen in Kontakt zu treten. z. B. um Sie über
                Ihren Account, neue Produkte oder Services, Sicherheitsbelange
                und andere Neuigkeiten zu informieren.
            </p>

            <p>
                <strong>Wie werden meine Daten verwendet?</strong>
                <br />
                Amedic verwendet die erfassten Daten zu den folgenden Zwecken:
            </p>
            <ul>
                <li>Produktverbesserung</li>
                <li>Zukünftige Produktentwicklung</li>
                <li>Kunden-Support</li>
                <li>Sicherheit und Streitbeilegung</li>
                <li>Rechnungslegung</li>
                <li>Kommunikation</li>
            </ul>

            <p>
                <strong>
                    Wie helfen meine Daten Amedic bei der Produktverbesserung?
                </strong>
                <br />
                Wir nutzen Ihre Daten, um dafür zu sorgen, dass Ihre Produkte
                einwandfrei funktionieren. Anhand der erfassten Fehlerberichte
                können wir Verbesserungen vornehmen und mögliche Fehler und
                Probleme beheben.
            </p>

            <p>
                <strong>
                    Muss ich meine Amedic Produkte registrieren, damit sie
                    richtig funktionieren?
                </strong>
                <br />
                Ja. Die Registrierung ist Voraussetzung für das sichere
                Funktionieren des Systems
            </p>

            <p>
                <strong>Verkauft Amedic Daten an Dritte?</strong>
                <br />
                Nein. Haben wir noch nie getan. Und werden wir auch in Zukunft
                nicht tun.
            </p>

            <p>
                <strong>
                    Wie lösche ich meine von Amedic erfassten personenbezogenen
                    Daten, und wie wirkt sich das aus?
                </strong>
                <br />
                Schicken Sie uns jederzeit eine E-Mail an{' '}
                <a href="mailto:info@amedic.de">info@amedic.de</a> oder wenden
                Sie sich an den Kunden-Support, um die Löschung Ihrer Daten zu
                verlangen. Beachten Sie jedoch, dass Ihre Amedic Produkte nach
                Löschung Ihrer personenbezogenen Daten nicht mehr funktionieren.
            </p>

            <p>
                <strong>Wie lange bewahrt Amedic meine Daten auf?</strong>
                <br />
                Die personenbezogenen Daten werden so lange aufbewahrt, wie es
                erforderlich ist – etwa zum Bereitstellen der angeforderten
                Dienste, zur Unterstützung Ihrer Produkte, zur Erfüllung einer
                von Ihnen angeforderten Transaktion oder aus anderen wichtigen
                Gründen, wie der Einhaltung rechtlicher Verpflichtungen, der
                Beilegung von Streitigkeiten oder der Durchsetzung von
                Vereinbarungen. Da die Anforderungen je nach Datentyp, Produkt
                oder Dienstleistung variieren können, unterscheiden sich die
                Aufbewahrungsfristen unter Umständen erheblich.
            </p>

            <p>
                <strong>
                    Wie werden die Daten auf den Servern von Amedic gespeichert
                    und geschützt?
                </strong>
                <br />
                Amedic hat sich dem Schutz Ihrer personenbezogenen Daten
                verpflichtet. Wir nutzen unterschiedliche
                Sicherheitstechnologien und -verfahren, um Ihre
                personenbezogenen Daten gegen unbefugten Zugriff, unberechtigte
                Nutzung oder nicht autorisierte Offenlegung zu schützen. Wir
                speichern z. B. die von Ihnen bereitgestellten personenbezogenen
                Daten auf Computersystemen mit beschränktem Zugriff, die sich in
                kontrollierten Bereichen befinden. Wenn wir Daten über das
                Internet übermitteln, schützen wir diese durch
                Verschlüsselungstechnologien.
            </p>

            <p>
                <strong>
                    Wie kann ich Amedic bei Fragen zur Datenschutzerklärung
                    kontaktieren?
                </strong>
                <br />
                Wenn Sie Fragen zur Nutzung Ihrer personenbezogenen Daten haben,
                schreiben Sie uns eine E-Mail an{' '}
                <a href="mailto:info@amedic.de">info@amedic.de</a>.
            </p>
        </div>
        <br />
    </Box>
);

export default PrivacyPolicy;
