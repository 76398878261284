import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import DriverLicensePasswordVerification from 'Measure/components/vehicle/driver-license/check/DriverLicensePasswordVerification';
import DriverLicenseSignatureVerification from 'Measure/components/vehicle/driver-license/check/DriverLicenseSignatureVerification';
import passwordConfirmSuccessfulCheckForDirectProcedure from 'Measure/api/driver-license/direct-check/passwordConfirmSuccessfulCheckForDirectProcedure';
import signatureConfirmSuccessfulCheckForDirectProcedure from 'Measure/api/driver-license/direct-check/signatureConfirmSuccessfulCheckForDirectProcedure';
import OuterBox from 'Common/components/Boxes/OuterBox';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';
import OuterBoxButtons from 'Common/components/Boxes/OuterBoxButtons';
import createNewDate from 'Common/utils/createNewDate';
import formatDate from 'Common/utils/formatDate';

const FullWidthContent = styled(OuterBox)`
    margin-bottom: 10px;
    padding: 30px 20px;
    text-align: center;

    @media screen and (min-width: 940px) {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 220px;
        margin-bottom: 0;
        padding: 40px;
    }
`;

const Inner = styled.div`
    width: 100%;
    max-width: 680px;
    margin: 0 auto;
    font-size: 18px;
    font-weight: 300;
    line-height: 27px;
    color: ${colors.DIRTY_WHITE};
`;

const EmployeeConfirmationFromExaminerAccount = ({ driverLicenseControl }) => {
    const dispatch = useDispatch();

    const [isInConfirmMode, setIsInConfirmMode] = useState(false);

    const proceedWithConfirmation = () => {
        setIsInConfirmMode(true);
    };

    const employee = driverLicenseControl.employee;
    const isAutomaticSigningAllowed = employee.isAutomaticSigningAllowed;

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const driverLicensesListLink = '/measures/vehicles/driver-licenses';

    const handleConfirmPassword = async password => {
        await passwordConfirmSuccessfulCheckForDirectProcedure(
            driverLicenseControl.id,
            password,
            source.token,
        );

        dispatch(push(driverLicensesListLink));
    };

    const handleConfirmSignature = async blob => {
        const date = createNewDate();
        const file = new File(
            [blob],
            `DriverLicenseSignature_Employee_${employee.user.id}_${formatDate(
                date,
                'yyyy-MM-dd_H-mm-ss',
            )}.png`,
            {
                type: 'image/png',
                lastModified: date,
            },
        );

        await signatureConfirmSuccessfulCheckForDirectProcedure(
            driverLicenseControl.id,
            file,
            source.token,
        );

        dispatch(push(driverLicensesListLink));
    };

    if (!isInConfirmMode) {
        return (
            <>
                <FullWidthContent>
                    <Inner>
                        Möchten Sie jetzt als Prüfer dem Inhaber des
                        Führerscheins die Möglichkeit geben, die Vorlage des
                        Führerscheins und somit die Kontrolle zu bestätigen?
                        Sollten Sie sich entscheiden, den Vorgang zu schließen,
                        muss die Führerscheinkontrolle seitens des Mitarbeiters
                        aus seinem persönlichen Account bestätigt werden.
                    </Inner>
                </FullWidthContent>
                <OuterBoxButtons>
                    <Button text to={driverLicensesListLink}>
                        Abbrechen
                    </Button>
                    <Button type="button" onClick={proceedWithConfirmation}>
                        Zur Bestätigung
                    </Button>
                </OuterBoxButtons>
            </>
        );
    }

    const hint = (
        <>
            Hinweis: Hiermit bestätigen Sie, dass Sie Ihren Führerschein einem
            der zugewiesenen Prüfer im Original vorgelegt haben.
        </>
    );

    const confirmButtonText = 'Kontrolle abschließen';

    return isAutomaticSigningAllowed ? (
        <DriverLicensePasswordVerification
            hint={hint}
            inputDescription={`${employee.lastName}, ${employee.firstName}`}
            confirmButtonText={confirmButtonText}
            onConfirm={handleConfirmPassword}
            showBackButton={false}
        />
    ) : (
        <DriverLicenseSignatureVerification
            hint={hint}
            signaturePadDescription={`${employee.lastName}, ${employee.firstName}`}
            confirmButtonText={confirmButtonText}
            onConfirm={handleConfirmSignature}
            showBackButton={false}
        />
    );
};

EmployeeConfirmationFromExaminerAccount.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
};

export default EmployeeConfirmationFromExaminerAccount;
