import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import PersonRemove from 'Common/components/Icons/PersonRemove';
import PersonAdd from 'Common/components/Icons/PersonAdd';
import ModalOption from 'Common/components/Modals/ModalOption';
import { orderBy } from 'lodash';

const Toolbar = styled.div`
    button {
        padding-left: 5px;
        padding-right: 5px;
    }

    @media screen and (min-width: 375px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;

const Participants = styled.div`
    margin-top: 10px;

    > * {
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const ParticipantsConfirmationModal = ({
    introduction,
    selectedProjectParticipantIds,
    onSelectParticipant,
    onDeselectParticipant,
    onSelectAllParticipants,
    onDeselectAllParticipants,
    onBackButtonClick,
    onNextButtonClick,
    onClose,
}) => {
    const relevantParticipants = introduction.participants.filter(
        participant => participant.isRelevant,
    );

    const projectParticipants = relevantParticipants.map(
        participant => participant.projectParticipant,
    );

    const isSelectAllDisabled =
        selectedProjectParticipantIds.length === relevantParticipants.length;

    const isDeselectAllDisabled = selectedProjectParticipantIds.length === 0;

    return (
        <Modal
            isOpen
            onRequestClose={onClose}
            noTransition
            buttons={
                <>
                    <Button text dark type="button" onClick={onBackButtonClick}>
                        Zurück
                    </Button>
                    <Button
                        type="button"
                        onClick={onNextButtonClick}
                        disabled={selectedProjectParticipantIds.length === 0}
                        tooltip={
                            selectedProjectParticipantIds.length === 0
                                ? 'Bitte mindestens einen Mitarbeiter auswählen um fortzufahren'
                                : ''
                        }
                    >
                        Bestätigen
                    </Button>
                </>
            }
        >
            <ModalTitle>Anwesenheitskontrolle</ModalTitle>
            <p>
                Bitte entferne die Markierung für die Mitarbeiter, die zur
                Einweisung nicht präsent sind.
            </p>
            <Toolbar>
                <Button
                    dark
                    underline
                    type="button"
                    icon={<PersonAdd />}
                    onClick={onSelectAllParticipants}
                    disabled={isSelectAllDisabled}
                    tooltip={
                        isSelectAllDisabled
                            ? 'Es wurden alle Mitarbeiter bereits einbezogen'
                            : ''
                    }
                >
                    Alle einbeziehen
                </Button>
                <Button
                    dark
                    underline
                    type="button"
                    icon={<PersonRemove />}
                    onClick={onDeselectAllParticipants}
                    disabled={isDeselectAllDisabled}
                    tooltip={
                        isDeselectAllDisabled
                            ? 'Alle Mitarbeiter sind bereits abgewählt'
                            : ''
                    }
                >
                    Alle abwählen
                </Button>
            </Toolbar>
            <Participants>
                {orderBy(
                    projectParticipants,
                    ['employee.lastName', 'employee.firstName'],
                    ['asc', 'asc'],
                ).map(participant => {
                    const employee = participant.employee;

                    const handleToggle = isChecked => {
                        if (isChecked) {
                            onSelectParticipant(participant.id);
                        } else {
                            onDeselectParticipant(participant.id);
                        }
                    };

                    return (
                        <ModalOption
                            key={participant.id}
                            title={`${employee.lastName}, ${employee.firstName}`}
                            isChecked={selectedProjectParticipantIds.includes(
                                participant.id,
                            )}
                            onToggle={handleToggle}
                            isMulti
                        />
                    );
                })}
            </Participants>
        </Modal>
    );
};

ParticipantsConfirmationModal.propTypes = {
    introduction: PropTypes.object.isRequired,
    selectedProjectParticipantIds: PropTypes.array.isRequired,
    onSelectParticipant: PropTypes.func.isRequired,
    onDeselectParticipant: PropTypes.func.isRequired,
    onSelectAllParticipants: PropTypes.func.isRequired,
    onDeselectAllParticipants: PropTypes.func.isRequired,
    onBackButtonClick: PropTypes.func.isRequired,
    onNextButtonClick: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ParticipantsConfirmationModal;
