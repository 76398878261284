import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import requestProjectQuestionFilesDownload from 'ProjectManager/Project/Common/api/dataManagement/questionnaire/question/downloadRequest/requestProjectQuestionFilesDownload';

const useRequestProjectQuestionFilesDownloadFunction = (
    questionId,
    isCustomQuestion,
) => {
    const { id: projectId } = useRecoilValue(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const requestDownload = useCallback(async () => {
        await requestProjectQuestionFilesDownload(
            projectId,
            questionId,
            isCustomQuestion,
            source.token,
        );
    }, [projectId, questionId, isCustomQuestion, source.token]);

    return requestDownload;
};

useRequestProjectQuestionFilesDownloadFunction.propTypes = {
    question: PropTypes.object.isRequired,
    isCustom: PropTypes.bool.isRequired,
};

export default useRequestProjectQuestionFilesDownloadFunction;
