import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddProjectCoordinator from 'ProjectManager/Project/DataManagement/GeneralInformation/ProjectCoordinators/AddProjectCoordinator';
import Button from 'Common/components/Button';
import { PersonAddRounded } from '@material-ui/icons';

const AddProjectCoordinatorButton = ({ onAdd, onFormOpen }) => {
    const [isAddFormOpened, setIsAddFormOpened] = useState(false);

    const handleAdd = () => {
        setIsAddFormOpened(false);
        onAdd();
    };

    const handleAddButtonClick = () => {
        setIsAddFormOpened(true);
        onFormOpen();
    };

    const handleCancelAdd = () => {
        setIsAddFormOpened(false);
    };

    if (isAddFormOpened) {
        return (
            <AddProjectCoordinator
                onAdd={handleAdd}
                onCancel={handleCancelAdd}
            />
        );
    }

    return (
        <Button
            underline
            icon={<PersonAddRounded />}
            onClick={handleAddButtonClick}
        >
            SiGeKo hinzufügen
        </Button>
    );
};

AddProjectCoordinatorButton.propTypes = {
    onAdd: PropTypes.func.isRequired,
    onFormOpen: PropTypes.func.isRequired,
};

export default AddProjectCoordinatorButton;
