import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import withoutGeneralFile from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/generalFile/withoutGeneralFile';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import deleteGeneralTemplateFile from 'ProjectManager/Template/Common/api/dataManagement/generalFile/deleteGeneralTemplateFile';

const useTemplateFileFunctions = () => {
    const [{ id: templateId }, setTemplate] = useRecoilState(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const generateDownloadLink = useCallback(
        fileId =>
            `/api/project-manager/templates/${templateId}/files/${fileId}`,
        [templateId],
    );

    const deleteFile = useCallback(
        async fileId => {
            await deleteGeneralTemplateFile(templateId, fileId, source.token);

            setTemplate(withoutGeneralFile(fileId));
        },
        [templateId, setTemplate, source.token],
    );

    return templateId ? { generateDownloadLink, deleteFile } : {};
};

export default useTemplateFileFunctions;
