import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import axios from 'axios';
import giveProjectQuestionYesNoAnswer from 'ProjectManager/Project/Common/api/dataManagement/questionnaire/question/answer/giveProjectQuestionYesNoAnswer';
import withYesNoAnswerForQuestion from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/answer/withYesNoAnswerForQuestion';

const useProjectGiveYesNoAnswerFunction = (questionId, isCustomQuestion) => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const giveYesNoAnswer = useCallback(
        async selectedAnswer => {
            await giveProjectQuestionYesNoAnswer(
                projectId,
                questionId,
                isCustomQuestion,
                selectedAnswer,
                source.token,
            );

            setProject(
                withYesNoAnswerForQuestion(
                    questionId,
                    isCustomQuestion,
                    selectedAnswer,
                ),
            );
        },
        [projectId, questionId, isCustomQuestion, setProject, source.token],
    );

    return giveYesNoAnswer;
};

export default useProjectGiveYesNoAnswerFunction;
