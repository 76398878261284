import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import ReactPlayer from 'react-player';

const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 10px;
    width: calc(100% - 20px);
    height: 0;
    padding-top: 35%;
    transform: translateY(-50%);

    ${ifProp(
        'isFullscreen',
        css`
            left: 0;
            width: 100%;
            height: 100%;
            padding-top: 0;
        `,
    )};
`;

const VideoPlayer = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
`;

const VideoSlide = ({
    slide,
    isSeekable,
    isPlaying,
    isFullscreen,
    onComplete,
    onDurationCalculated,
}) => {
    const player = useRef();
    const playedSeconds = useRef(0);

    const handleSeek = seconds => {
        if (playedSeconds.current < seconds && !isSeekable) {
            player.current.seekTo(playedSeconds.current, 'seconds');
        }
    };

    const handleProgress = ({ playedSeconds: seconds }) => {
        playedSeconds.current = seconds;
    };

    return (
        <Wrapper isFullscreen={isFullscreen}>
            <VideoPlayer
                ref={player}
                className="react-player"
                url={slide.file}
                playing={isPlaying}
                controls
                width="100%"
                height="100%"
                onSeek={handleSeek}
                onProgress={handleProgress}
                progressInterval={10000}
                onDuration={onDurationCalculated}
                onEnded={onComplete}
            />
        </Wrapper>
    );
};

VideoSlide.defaultProps = {
    isFullscreen: false,
};

VideoSlide.propTypes = {
    slide: PropTypes.object.isRequired,
    isSeekable: PropTypes.bool.isRequired,
    isPlaying: PropTypes.bool.isRequired,
    isFullscreen: PropTypes.bool,
    onComplete: PropTypes.func.isRequired,
    onDurationCalculated: PropTypes.func.isRequired,
};

export default VideoSlide;
