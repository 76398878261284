import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route as ReactRouterRoute } from 'react-router-dom';
import isAuthorized from 'User/utils/isAuthorized';
import areModulesAvailable from 'User/utils/areModulesAvailable';
import routeTypes from 'Application/constants/route-types';
import isAllowedToExamineMeasure from 'User/utils/isAllowedToExamineMeasure';

const Route = ({
    component: Component,
    type,
    authorizedRoles,
    authorizedModules,
    authorizedMeasure,
    ...props
}) => {
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);

    // Redirect authenticated and unauthenticated users to their corresponding
    // homepage, based on the current route's authentication policy.
    if (
        (type === routeTypes.AUTHENTICATED && !isAuthenticated) ||
        (type === routeTypes.NOT_AUTHENTICATED && isAuthenticated)
    ) {
        return <Redirect to="/" />;
    }

    // Redirect authenticated users to their homepage,
    // based on the current route's role-based authorization policy.
    if (isAuthenticated && authorizedRoles && !isAuthorized(authorizedRoles)) {
        return <Redirect to="/" />;
    }

    // Redirect authenticated users to their homepage,
    // based on the current route's module-based authorization policy.
    if (
        isAuthenticated &&
        authorizedModules &&
        !areModulesAvailable(authorizedModules)
    ) {
        return <Redirect to="/" />;
    }

    // Redirect authenticated users to their homepage,
    // based on the current route's measure-based authorization policy.
    if (
        isAuthenticated &&
        authorizedMeasure &&
        !isAllowedToExamineMeasure(authorizedMeasure)
    ) {
        return <Redirect to="/" />;
    }

    return (
        <ReactRouterRoute {...props}>
            <Component />
        </ReactRouterRoute>
    );
};

Route.defaultProps = {
    type: routeTypes.AUTHENTICATED,
    authorizedRoles: undefined,
    authorizedModules: undefined,
    authorizedMeasure: undefined,
};

Route.propTypes = {
    type: PropTypes.string,
    authorizedRoles: PropTypes.array,
    authorizedModules: PropTypes.array,
    authorizedMeasure: PropTypes.string,
};

export default Route;
