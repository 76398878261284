import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Filter from 'Common/components/Filters/Filter/Filter';
import {
    CheckBoxOutlineBlankRounded,
    CheckBoxRounded,
} from '@material-ui/icons';

const Heading = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Title = styled.div`
    margin-right: 10px;
`;

const SingleCheckboxFilter = ({ title, isChecked, onToggle, ...props }) => (
    <Filter
        {...props}
        title={
            <Heading>
                <Title>{title}</Title>
                {isChecked ? (
                    <CheckBoxRounded />
                ) : (
                    <CheckBoxOutlineBlankRounded />
                )}
            </Heading>
        }
        onClick={onToggle}
        isCollapsible={false}
        selectedCount={isChecked ? 1 : 0}
    />
);

SingleCheckboxFilter.propTypes = {
    title: PropTypes.any.isRequired,
    isChecked: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
};

export default SingleCheckboxFilter;
