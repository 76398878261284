import React, { useState } from 'react';
import styled from 'styled-components/macro';
import {
    CloudDownloadRounded,
    KeyboardArrowDownRounded,
    KeyboardArrowRightRounded,
} from '@material-ui/icons';
import colors from 'Common/constants/colors';

const Wrapper = styled.div`
    overflow: hidden;
`;

const DocumentBox = styled.div``;

const DocumentButton = styled.button`
    display: flex;
    width: 100%;
    padding: 20px 0;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    color: ${colors.DIRTY_WHITE};
    background: none;
    border: 0 none;
    outline: 0;
    cursor: pointer;

    svg {
        margin-left: 10px;
    }
`;

const DocumentWrapper = styled.div``;

const DocumentName = styled.div`
    color: ${colors.DIRTY_WHITE};
    font-size: 14px;
    line-height: 21px;
`;

const IconWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 28px;
    height: 28px;
    color: ${colors.DIRTY_WHITE};

    &::after {
        content: '';
        display: block;
        width: 1px;
        height: 20px;
        background: ${colors.DARK_DIVIDER};
        position: absolute;
        top: 27px;
    }
`;

const DocumentItem = styled.a`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    text-decoration: none;
    cursor: pointer;

    &:last-child {
        ${IconWrapper} {
            &::after {
                display: none;
            }
        }
    }

    &:hover {
        ${DocumentName} {
            color: ${colors.PRIMARY};
        }

        ${IconWrapper} {
            border-color: ${colors.PRIMARY};
        }
    }
`;

const DriverLicenseControlDocumentsWrapper = styled.div`
    padding: 5px 0;
`;

const DriverLicenseControlDocuments = () => {
    const [isDossierBoxOpened, setIsDossierBoxOpened] = useState(false);

    const handleDossierButtonClick = () => {
        setIsDossierBoxOpened(
            prevIsDossierBoxOpened => !prevIsDossierBoxOpened,
        );
    };

    return (
        <Wrapper>
            <DocumentBox>
                <DocumentButton
                    isActive={isDossierBoxOpened}
                    onClick={handleDossierButtonClick}
                >
                    Dossier Führerscheinkontrolle
                    {isDossierBoxOpened ? (
                        <KeyboardArrowDownRounded />
                    ) : (
                        <KeyboardArrowRightRounded />
                    )}
                </DocumentButton>
                {isDossierBoxOpened && (
                    <DriverLicenseControlDocumentsWrapper>
                        <DocumentWrapper>
                            <DocumentItem
                                href="/documents/driver-license/Rechtsbelehrung.pdf"
                                target="_blank"
                            >
                                <IconWrapper>
                                    <CloudDownloadRounded />
                                </IconWrapper>
                                <DocumentName>Rechtsbelehrung</DocumentName>
                            </DocumentItem>

                            <DocumentItem
                                href="/documents/driver-license/Führerscheinkontrolle.pdf"
                                target="_blank"
                            >
                                <IconWrapper>
                                    <CloudDownloadRounded />
                                </IconWrapper>
                                <DocumentName>Muster</DocumentName>
                            </DocumentItem>

                            {/*<DocumentItem*/}
                            {/*    href="/documents/driver-license/Rechtsbelehrung.pdf"*/}
                            {/*    target="_blank"*/}
                            {/*>*/}
                            {/*    <IconWrapper>*/}
                            {/*        <CloudDownloadRounded />*/}
                            {/*    </IconWrapper>*/}
                            {/*    <DocumentName>Hinweise</DocumentName>*/}
                            {/*</DocumentItem>*/}
                        </DocumentWrapper>
                    </DriverLicenseControlDocumentsWrapper>
                )}
            </DocumentBox>
        </Wrapper>
    );
};

export default DriverLicenseControlDocuments;
