const withUpdatedCoordinatorData = (
    projectCoordinatorId,
    updatedValues,
) => prevProject => {
    const projectCoordinators = [...prevProject.projectCoordinators];

    const index = projectCoordinators.findIndex(
        projectCoordinator => projectCoordinator.id === projectCoordinatorId,
    );

    if (index !== -1) {
        projectCoordinators[index] = {
            ...projectCoordinators[index],
            ...updatedValues,
        };
    }

    return {
        ...prevProject,
        projectCoordinators,
    };
};

export default withUpdatedCoordinatorData;
