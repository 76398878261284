import api from 'Application/api/api';

const changeProjectClient = (projectId, clientId, cancelToken = null) =>
    api.post(
        `project-manager/projects/${projectId}/change-client/${clientId}`,
        {},
        { cancelToken },
    );

export default changeProjectClient;
