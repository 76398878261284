import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import { useSelector } from 'react-redux';
import Input from 'Common/components/Form/Fields/Input';
import FormField from 'Common/components/Form/FormField';
import SignaturePad from 'Common/components/SignaturePad';
import useWindowSize from '@react-hook/window-size';
import { isMobile } from 'react-device-detect';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import dataUriToBlob from 'Common/utils/dataUriToBlob';
import axios from 'axios';
import TurnYourDeviceInLandscapeModeOverlay from 'Common/components/TurnYourDeviceInLandscapeModeOverlay';
import createNewDate from 'Common/utils/createNewDate';
import formatDate from 'Common/utils/formatDate';
import conductIntroduction from 'ProjectManager/Project/Introductions/Common/api/conductIntroduction';
import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import getProject from 'ProjectManager/Project/Common/api/getProject';
import { pick } from 'lodash';

const FixedWrapper = styled.div`
    > div {
        position: fixed;
        z-index: 10000;
    }
`;

const SignatureConfirmationModal = ({
    introduction,
    selectedProjectParticipantIds,
    onBackButtonClick,
    onConfirm,
    onClose,
}) => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const userId = useSelector(state => state.user.id);

    const [isConfirming, setIsConfirming] = useState(false);
    const [password, setPassword] = useState('');

    const handlePasswordChange = e => {
        setPassword(e.target.value);
    };

    const signaturePad = useRef();

    const isAutomaticSigningAllowed = useSelector(
        state => state.user.isAutomaticSigningAllowed,
    );

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirm = async () => {
        if (!isAutomaticSigningAllowed && signaturePad.current.isEmpty()) {
            notify('Das Unterschriftsfeld darf nicht leer sein', {
                type: toast.TYPE.ERROR,
            });
        } else {
            setIsConfirming(true);

            try {
                let signatureFile = null;

                if (!isAutomaticSigningAllowed) {
                    const blob = dataUriToBlob(
                        signaturePad.current.toDataURL(),
                    );
                    const date = createNewDate();

                    signatureFile = new File(
                        [blob],
                        `ConductSignature_Supervisor_${userId}_${formatDate(
                            date,
                            'yyyy-MM-dd_H-mm-ss',
                        )}.png`,
                        {
                            type: 'image/png',
                            lastModified: date,
                        },
                    );
                }

                await conductIntroduction(
                    projectId,
                    introduction.id,
                    selectedProjectParticipantIds,
                    password,
                    signatureFile,
                    source.token,
                );

                const projectResponse = await getProject(
                    projectId,
                    [
                        'statistics',
                        'introductions',
                        'introductions.supervisor',
                        'introductions.participants',
                        'introductions.participants.projectParticipant',
                        'introductions.participants.projectParticipant.employee',
                        'introductions.participants.projectParticipant.employee.user',
                    ],
                    source.token,
                );

                setProject(prevProject => ({
                    ...prevProject,
                    ...pick(projectResponse.data, ['statistics', 'introductions']),
                }));

                onConfirm();
            } catch (error) {
                if (!axios.isCancel(error)) {
                    if (error.response.status === 400) {
                        notify('Bitte Passworteingabe überprüfen', {
                            type: toast.TYPE.ERROR,
                        });
                    } else {
                        notify(
                            'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                            {
                                type: toast.TYPE.ERROR,
                            },
                        );
                    }
                }
            }

            setIsConfirming(false);
        }
    };

    const [width, height] = useWindowSize();
    const isPortrait = width < height;
    const isOverlayVisible =
        isMobile && isPortrait && !isAutomaticSigningAllowed;

    if (isOverlayVisible) {
        return (
            <FixedWrapper>
                <TurnYourDeviceInLandscapeModeOverlay />
            </FixedWrapper>
        );
    }

    return (
        <Modal
            isOpen
            onRequestClose={isConfirming ? undefined : onClose}
            noTransition
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onBackButtonClick}
                        disabled={isConfirming}
                    >
                        Zurück
                    </Button>
                    <Button
                        type="button"
                        onClick={handleConfirm}
                        isLoading={isConfirming}
                        disabled={isConfirming}
                    >
                        Bestätigen
                    </Button>
                </>
            }
        >
            <ModalTitle>Digitale Bestätigung</ModalTitle>
            <p>
                Als Einweiser musst du die Einweisung bestätigen, um diese
                erfolgreich zu beenden.
            </p>
            <FormField>
                <Input
                    type="password"
                    label="Passwort"
                    value={password}
                    onChange={handlePasswordChange}
                    autoComplete="off"
                />
            </FormField>
            {!isAutomaticSigningAllowed && (
                <SignaturePad ref={signaturePad} light />
            )}
        </Modal>
    );
};

SignatureConfirmationModal.propTypes = {
    introduction: PropTypes.object.isRequired,
    selectedProjectParticipantIds: PropTypes.array.isRequired,
    onBackButtonClick: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default SignatureConfirmationModal;
