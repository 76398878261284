import React from 'react';
import Statistics from 'ProjectManager/Project/Preview/MediaFiles/Statistics';
import QuickActions from 'ProjectManager/Project/ProjectMediaFiles/components/Sidebar/QuickActions/QuickActions';

const Sidebar = () => (
    <>
        <Statistics placement="top" />
        <QuickActions />
    </>
);

export default Sidebar;
