import api from 'Application/api/api';

const getProject = (id, load = [], cancelToken = null) =>
    api.get(`project-manager/projects/${id}`, {
        params: {
            load,
        },
        cancelToken,
    });

export default getProject;
