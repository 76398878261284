import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import Button from 'Common/components/Button';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import getProject from 'ProjectManager/Project/Common/api/getProject';
import { pick } from 'lodash';
import createNewDate from 'Common/utils/createNewDate';
import FormField from 'Common/components/Form/FormField';
import DatePicker from 'Common/components/Form/Fields/DatePicker';
import startOfDay from 'date-fns/startOfDay';
import addDays from 'date-fns/addDays';
import reactivateProject from 'ProjectManager/Project/Common/api/status/reactivateProject';
import formatDateAsISO from 'Common/utils/formatDateAsISO';

const ConfirmModal = ({ onClose }) => {
    const [{ id }, setProject] = useRecoilState(projectAtom);

    const [isConfirming, setIsConfirming] = useState(false);
    const [endingDate, setEndingDate] = useState(null);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirm = async () => {
        setIsConfirming(true);

        try {
            await reactivateProject(
                id,
                endingDate ? formatDateAsISO(endingDate) : null,
                source.token,
            );

            const response = await getProject(id, [], source.token);

            setProject(prevProject => ({
                ...prevProject,
                ...pick(response.data, [
                    'status',
                    'endingDate',
                    'isPrematurelyEnded',
                    'prematurelyEndedOn',
                ]),
            }));

            setIsConfirming(false);

            onClose();
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsConfirming(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    return (
        <Modal
            isOpen
            onRequestClose={isConfirming ? undefined : onClose}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        disabled={isConfirming}
                        onClick={onClose}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isConfirming}
                        disabled={isConfirming}
                        onClick={handleConfirm}
                    >
                        Reaktivieren
                    </Button>
                </>
            }
        >
            <ModalTitle>Projektreaktivierung</ModalTitle>
            <p>
                Du bist dabei das Projekt zu reaktivieren und somit den Status
                des Projektes auf LAUFEND zu setzten. Nach deiner Bestätigung
                wird das Projekt wieder aktiviert. Bitte bestimme das neue
                Enddatum des Projektes.
            </p>
            <FormField>
                <DatePicker
                    id="endingDate"
                    name="endingDate"
                    label="Neues Enddatum des Projektes"
                    value={endingDate}
                    onChange={setEndingDate}
                    minDate={startOfDay(addDays(createNewDate(), 1))}
                    portalId="root-portal"
                />
            </FormField>
        </Modal>
    );
};

ConfirmModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default ConfirmModal;
