import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import OuterBoxSubtitle from 'Common/components/Boxes/OuterBoxSubtitle';
import ReactDOM from 'react-dom';
import Button from 'Common/components/Button';
import { useSelector } from 'react-redux';
import roles from 'User/constants/roles';
import { useRecoilValue } from 'recoil';
import pointsAtom from 'ProjectManager/Project/Introductions/Create/recoil/points/pointsAtom';
import PointTile from 'ProjectManager/Project/Introductions/Create/components/Steps/PointsSelection/PointTile';
import Comment from 'ProjectManager/Project/Introductions/Create/components/Steps/PointsSelection/Comment';

const Points = styled.div`
    > div {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;

        > div {
            width: calc(50% - 10px);
            flex: 0 0 calc(50% - 10px);
            margin-right: 10px;
            margin-bottom: 0;

            &:nth-child(n + 3) {
                margin-top: 10px;
            }

            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }
`;

const PointsSelection = ({
    points,
    ppes,
    onNextButtonClick,
    onBackButtonClick,
    onCancelButtonClick,
}) => {
    const role = useSelector(state => state.user.role);

    const selectedPoints = useRecoilValue(pointsAtom);

    // Use a delayed-set ref because the bottom drawer buttons are not available
    // during the initial render of this component.
    const [bottomButtonsRef, setBottomButtonsRef] = useState(
        document.getElementById('drawer-bottom-buttons'),
    );

    useEffect(() => {
        setBottomButtonsRef(document.getElementById('drawer-bottom-buttons'));
    }, []);

    return (
        <>
            <OuterBoxTitle>Einweisungsthemen bestimmen</OuterBoxTitle>
            <OuterBoxSubtitle>
                Hier kannst du, falls gewollt, die Auswahl der Einweisungsthemen
                spezifizieren. Bitte beachte, dass du als
                Auftragsverantwortlicher verpflichtet bist die Einweisung
                gegebenenfalls zu wiederholen, sofern sich die projektbezogenen
                Gegebenheiten ändern.
            </OuterBoxSubtitle>
            <Points>
                {points.map(point => (
                    <PointTile
                        key={point.value}
                        point={point}
                        isSelected={selectedPoints.includes(point.value)}
                        ppes={ppes}
                    />
                ))}
            </Points>
            {Boolean(bottomButtonsRef) &&
                ReactDOM.createPortal(
                    <>
                        {role === roles.MANAGER ? (
                            <Button
                                text
                                type="button"
                                onClick={onBackButtonClick}
                            >
                                Zurück
                            </Button>
                        ) : (
                            <Button
                                text
                                type="button"
                                onClick={onCancelButtonClick}
                            >
                                Abbrechen
                            </Button>
                        )}
                        <Button type="button" onClick={onNextButtonClick}>
                            Weiter
                        </Button>
                    </>,
                    bottomButtonsRef,
                )}
            <Comment />
        </>
    );
};

PointsSelection.propTypes = {
    points: PropTypes.array.isRequired,
    ppes: PropTypes.array.isRequired,
    onNextButtonClick: PropTypes.func.isRequired,
    onBackButtonClick: PropTypes.func.isRequired,
    onCancelButtonClick: PropTypes.func.isRequired,
};

export default PointsSelection;
