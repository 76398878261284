import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useWindowWidth } from '@react-hook/window-size';
import { prop } from 'styled-tools';
import columnBreakpoints from 'ProjectManager/Project/Preview/Grid/constants/columnBreakpoints';
import GridItem from 'ProjectManager/Project/Preview/Grid/components/GridItem';

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
`;

const Column = styled.div`
    flex: ${prop('flex')};
    margin-right: 10px;
    min-width: 0;

    &:last-child {
        margin-right: 0;
    }

    > div {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const Grid = () => {
    const [columns, setColumns] = useState([]);

    const windowWidth = useWindowWidth();

    useEffect(() => {
        const breakpoint = columnBreakpoints.find(breakpoint => {
            const from = breakpoint.from ?? 0;
            const to = breakpoint.to ?? Infinity;

            return from <= windowWidth && windowWidth <= to;
        });

        setColumns(breakpoint.columns);
    }, [windowWidth]);

    return (
        <>
            <Wrapper>
                {columns.map((column, index) => (
                    <Column key={index} flex={column.flex}>
                        {column.items.map(item => (
                            <GridItem key={item} id={item} />
                        ))}
                    </Column>
                ))}
            </Wrapper>
        </>
    );
};

export default Grid;
