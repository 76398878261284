import { useRecoilValue } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import isTemplateCustomTopicNameUnique from 'ProjectManager/Template/Common/api/dataManagement/customTopic/isTemplateCustomTopicNameUnique';

const useUniqueNameValidationCallbackForTemplate = () => {
    const { id: templateId } = useRecoilValue(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const validationCallback = useCallback(
        async (value, editedCustomTopicId = null) => {
            if (!value) {
                return true;
            }

            try {
                const response = await isTemplateCustomTopicNameUnique(
                    value,
                    templateId,
                    editedCustomTopicId,
                    source.token,
                );

                return response.data.isUnique;
            } catch (error) {
                return axios.isCancel(error);
            }
        },
        [templateId, source.token],
    );

    return validationCallback;
};

export default useUniqueNameValidationCallbackForTemplate;
