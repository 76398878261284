import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import { pick } from 'lodash';
import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import getProject from 'ProjectManager/Project/Common/api/getProject';
import cancelIntroduction from 'ProjectManager/Project/Introductions/Common/api/cancelIntroduction';

const CancelModal = ({ introduction, onClose }) => {
    const [isCanceling, setIsCanceling] = useState(false);

    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleCancelButtonClick = async () => {
        setIsCanceling(true);

        try {
            await cancelIntroduction(projectId, introduction.id, source.token);

            const projectResponse = await getProject(
                projectId,
                [
                    'statistics',
                    'introductions',
                    'introductions.supervisor',
                    'introductions.participants',
                    'introductions.participants.projectParticipant',
                    'introductions.participants.projectParticipant.employee',
                    'introductions.participants.projectParticipant.employee.user',
                ],
                source.token,
            );

            setProject(prevProject => ({
                ...prevProject,
                ...pick(projectResponse.data, ['statistics', 'introductions']),
            }));

            setIsCanceling(false);

            onClose();

            notify('Einweisung wurde erfolgreich abgesagt', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsCanceling(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    return (
        <Modal
            isOpen
            onRequestClose={isCanceling ? undefined : onClose}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onClose}
                        disabled={isCanceling}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        onClick={handleCancelButtonClick}
                        isLoading={isCanceling}
                        disabled={isCanceling}
                    >
                        Einweisung absagen
                    </Button>
                </>
            }
        >
            <ModalTitle>Einweisung absagen</ModalTitle>
            <p>Bist du dir sicher, dass du die Einweisung absagen willst? Bitte bedenke, dass dieser Prozess unwiderruflich ist.</p>
        </Modal>
    );
};

CancelModal.propTypes = {
    introduction: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default CancelModal;
