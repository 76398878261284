import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import createTemplateCustomSubsection from 'ProjectManager/Template/Common/api/dataManagement/questionnaire/subsection/createTemplateCustomSubsection';
import withAdditionalCustomSubsection
    from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/subsection/withAdditionalCustomSubsection';

const useCreateTemplateCustomTopicFunction = (sectionId, isCustomSection) => {
    const [{ id: templateId }, setTemplate] = useRecoilState(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const createNewCustomSubsection = useCallback(
        async values => {
            const response = await createTemplateCustomSubsection(
                templateId,
                sectionId,
                isCustomSection,
                values,
                source.token,
            );

            const newCustomSubsection = response.data;

            setTemplate(
                withAdditionalCustomSubsection(
                    newCustomSubsection,
                    sectionId,
                    isCustomSection,
                ),
            );

            return newCustomSubsection;
        },
        [isCustomSection, templateId, sectionId, setTemplate, source.token],
    );

    return createNewCustomSubsection;
};

export default useCreateTemplateCustomTopicFunction;
