import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';

const useProjectCustomTopics = () => {
    const { customTopics } = useRecoilValue(projectAtom);

    return customTopics;
};

export default useProjectCustomTopics;
