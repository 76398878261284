import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useCreateTemplateCustomQuestionFunction from 'ProjectManager/DataManagement/Questionnaire/components/QuestionCreation/hooks/createCustomQuestionFunction/useCreateTemplateCustomQuestionFunction';
import useCreateProjectCustomQuestionFunction from 'ProjectManager/DataManagement/Questionnaire/components/QuestionCreation/hooks/createCustomQuestionFunction/useCreateProjectCustomQuestionFunction';

const useCreateCustomQuestionFunction = subsectionId => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useCreateProjectCustomQuestionFunction(
        subsectionId,
    );

    const dataFromTemplate = useCreateTemplateCustomQuestionFunction(
        subsectionId,
    );

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return () => {};
};

export default useCreateCustomQuestionFunction;
