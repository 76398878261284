import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import * as serviceWorker from './serviceWorker';
import { cssTransition, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notify from 'Common/utils/notify';
import { popToast } from 'Common/utils/toast';
import EmployeeDsgvoCertificates from 'Employee/components/EmployeeDsgvoCertificates';
import LaravelBreadcrumbsAndBackButton from 'Application/components/Header/LaravelBreadcrumbsAndBackButton';
import Breadcrumbs from 'Application/components/Header/Breadcrumbs';
import Root from 'Application/components/Root';
import LaravelHeader from 'Application/components/Header/LaravelHeader';
import { CloseRounded } from '@material-ui/icons';
import 'react-datepicker/dist/react-datepicker.css';
import Button from 'Common/components/Button';
import 'Common/utils/yup/debouncedCallbackValidator';
import 'Common/utils/yup/uniquePropertyValidator';
import 'Common/utils/yup/uniqueValidator';

const FadeInOut = cssTransition({
    enter: 'fadeIn',
    exit: 'fadeOut',
    duration: 350,
});

const CloseButton = styled(Button)``;

const ToastCloseButton = ({ closeToast }) => (
    <CloseButton
        iconOnly
        icon={<CloseRounded />}
        className="close-button"
        onClick={closeToast}
    />
);

toast.configure({
    autoClose: 3500,
    draggable: false,
    transition: FadeInOut,
    closeButton: <ToastCloseButton />,
});

(async () => {
    let toast = popToast();

    while (toast !== undefined) {
        if (toast.message) {
            notify(toast.message, toast.options ?? {});
        }

        toast = popToast();
    }
})();

if (document.getElementById('root')) {
    ReactDOM.render(<Root />, document.getElementById('root'));
}

if (document.getElementById('manager-header')) {
    ReactDOM.render(
        <LaravelHeader isManager />,
        document.getElementById('manager-header'),
    );
}

if (document.getElementById('employee-header')) {
    ReactDOM.render(
        <LaravelHeader isManager={false} />,
        document.getElementById('employee-header'),
    );
}

if (document.getElementById('react-breadcrumbs')) {
    ReactDOM.render(
        <Breadcrumbs isReact={false} />,
        document.getElementById('react-breadcrumbs'),
    );
}

if (document.getElementById('employee-dsgvo-certificates')) {
    ReactDOM.render(
        <EmployeeDsgvoCertificates />,
        document.getElementById('employee-dsgvo-certificates'),
    );
}

if (document.getElementById('laravel-breadcrumbs-and-back-button')) {
    ReactDOM.render(
        <LaravelBreadcrumbsAndBackButton />,
        document.getElementById('laravel-breadcrumbs-and-back-button'),
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
