import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useHasOtherPrimaryButtonForProject from 'ProjectManager/DataManagement/Common/hooks/useHasOtherPrimaryButton/useHasOtherPrimaryButtonForProject';
import useHasOtherPrimaryButtonForTemplate from 'ProjectManager/DataManagement/Common/hooks/useHasOtherPrimaryButton/useHasOtherPrimaryButtonForTemplate';

const useHasOtherPrimaryButton = () => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useHasOtherPrimaryButtonForProject();
    const dataFromTemplate = useHasOtherPrimaryButtonForTemplate();

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return false;
};

export default useHasOtherPrimaryButton;
