import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'Common/components/Button';
import { ImageRounded } from '@material-ui/icons';
import AllFilesModal from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/components/ShowAll/AllFilesModal';

const ShowAllFilesButton = ({ question }) => {
    const [showModal, setShowModal] = useState(false);

    const handleClick = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Button
                underline
                icon={<ImageRounded />}
                type="button"
                onClick={handleClick}
            >
                {`Alle anzeigen (${question.files.length})`}
            </Button>
            {showModal && (
                <AllFilesModal
                    question={question}
                    onClose={closeModal}
                />
            )}
        </>
    );
};

ShowAllFilesButton.propTypes = {
    question: PropTypes.object.isRequired,
};

export default ShowAllFilesButton;
