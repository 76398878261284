import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';

const Line = styled.div`
    width: 3px;
    height: 65px;
    border-radius: 6px;
    background: ${colors.DIRTY_WHITE};
`;

export default Line;
