import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Button from 'Common/components/Button';
import colors from 'Common/constants/colors';
import ConfirmModal from 'ProjectManager/Project/Preview/Timeline/PrimaryButtons/ReactivateButton/ConfirmModal';

const StyledButton = styled(Button)`
    background: ${colors.BLUE};

    &:hover,
    &.is-active {
        background: ${colors.BLUE_LIGHT};
    }

    &[disabled] &:hover,
    &[disabled] &.is-active {
        background: ${colors.BLUE};
    }
`;

const ReactivateButton = props => {
    const [isModalOpened, setIsModalOpened] = useState(false);

    const handleClick = () => {
        setIsModalOpened(true);
    };

    const closeModal = () => {
        setIsModalOpened(false);
    };

    return (
        <>
            <StyledButton small type="button" onClick={handleClick} {...props}>
                Reaktivieren
            </StyledButton>
            {isModalOpened && <ConfirmModal onClose={closeModal} />}
        </>
    );
};

export default ReactivateButton;
