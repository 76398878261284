import api from 'Application/api/api';

const assignEmployeesToProject = (projectId, employeeIds, cancelToken = null) =>
    api.post(
        `project-manager/projects/${projectId}/employees/assign`,
        {
            employeeIds,
        },
        {
            cancelToken,
        },
    );

export default assignEmployeesToProject;
