import api from 'Application/api/api';

const completeInstructionAssignmentPresentation = (id, cancelToken = null) =>
    api.post(
        `/education/instruction-assignments/${id}/presentation/complete`,
        {},
        { cancelToken },
    );

export default completeInstructionAssignmentPresentation;
