import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import MultiCheckboxFilter from 'Common/components/Filters/Filter/MultiCheckboxFilter';
import getTopics
    from 'ProjectManager/Common/api/getTopics';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';

const Topics = ({ value, setFilters }) => {
    const handleToggle = topicId => {
        setFilters(prevFilters => {
            const newTopicIds = [...prevFilters.topicIds];

            if (newTopicIds.includes(topicId)) {
                newTopicIds.splice(newTopicIds.indexOf(topicId), 1);
            } else {
                newTopicIds.push(topicId);
            }

            return {
                ...prevFilters,
                topicIds: newTopicIds,
            };
        });
    };

    const topicsRequest = useCallback(
        cancelToken => getTopics([], cancelToken),
        [],
    );

    const { data: topics, isLoading } = useAxiosRequest(topicsRequest, []);

    const topicOptions = topics.map(topic => ({
        value: topic.id,
        label: topic.name,
    }));

    return (
        <MultiCheckboxFilter
            title="Projektfelder"
            options={topicOptions}
            checkedValues={value}
            onToggle={handleToggle}
            isLoading={isLoading}
        />
    );
};

Topics.propTypes = {
    value: PropTypes.array.isRequired,
    setFilters: PropTypes.func.isRequired,
};

export default Topics;
