import api from 'Application/api/api';

const deleteProjectCustomQuestion = (
    projectId,
    customQuestionId,
    cancelToken = null,
) =>
    api.delete(
        `project-manager/projects/${projectId}/questionnaire/custom-questions/${customQuestionId}`,
        {
            cancelToken,
        },
    );

export default deleteProjectCustomQuestion;
