import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import deleteGeneralProjectFile
    from 'ProjectManager/Project/Common/api/dataManagement/generalFile/deleteGeneralProjectFile';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import withoutGeneralFile from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/generalFile/withoutGeneralFile';

const useProjectFileFunctions = () => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const generateDownloadLink = useCallback(
        fileId => `/api/project-manager/projects/${projectId}/files/${fileId}`,
        [projectId],
    );

    const deleteFile = useCallback(
        async fileId => {
            await deleteGeneralProjectFile(projectId, fileId, source.token);

            setProject(withoutGeneralFile(fileId));
        },
        [projectId, setProject, source.token],
    );

    return projectId ? { generateDownloadLink, deleteFile } : {};
};

export default useProjectFileFunctions;
