import api from 'Application/api/api';

const giveTemplateQuestionSingleChoiceAnswer = (
    templateId,
    questionId,
    isCustom,
    answerId,
    cancelToken = null,
) =>
    api.post(
        `project-manager/templates/${templateId}/questionnaire/questions/${questionId}/${
            isCustom ? 1 : 0
        }/answers/single-choice`,
        {
            answerId,
        },
        { cancelToken },
    );

export default giveTemplateQuestionSingleChoiceAnswer;
