import api from 'Application/api/api';

const getInstructionAssignment = (id, load, cancelToken = null) =>
    api.get(`/education/instruction-assignments/${id}`, {
        params: {
            load,
        },
        cancelToken,
    });

export default getInstructionAssignment;
