import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomTopicPreview from 'ProjectManager/DataManagement/TopicManagement/components/CustomTopicManagement/components/CustomTopicTile/CustomTopicPreview';
import CustomTopicEdit from 'ProjectManager/DataManagement/TopicManagement/components/CustomTopicManagement/components/CustomTopicTile/CustomTopicEdit';

const CustomTopicTile = ({ customTopic }) => {
    const [isInEditMode, setIsInEditMode] = useState(false);

    const handleEditButtonClick = () => {
        setIsInEditMode(true);
    };

    const cancelEditMode = () => {
        setIsInEditMode(false);
    };

    if (isInEditMode) {
        return (
            <CustomTopicEdit
                customTopic={customTopic}
                onCancel={cancelEditMode}
                onSave={cancelEditMode}
            />
        );
    }

    return (
        <CustomTopicPreview
            customTopic={customTopic}
            onEditButtonClick={handleEditButtonClick}
        />
    );
};

CustomTopicTile.propTypes = {
    customTopic: PropTypes.object.isRequired,
};

export default CustomTopicTile;
