import api from 'Application/api/api';

const getEmployeeDriverLicenseControl = (load, cancelToken = null) =>
    api.get(`/measures/vehicles/driver-licenses/controls/single-for-employee`, {
        params: {
            load,
        },
        cancelToken,
    });

export default getEmployeeDriverLicenseControl;
