import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Redirect } from 'react-router';
import isAuthorized from 'User/utils/isAuthorized';
import roles from 'User/constants/roles';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import modules from 'Common/constants/modules';
import Title from 'Measure/components/vehicle/driver-license/Common/Title';
import Examiners from 'Measure/components/vehicle/driver-license/overview/Examiners';
import { useSelector } from 'react-redux';
import measures from 'Measure/constants/measures';
import isAllowedToExamineMeasure from 'User/utils/isAllowedToExamineMeasure';
import MarkAsLost from 'Measure/components/vehicle/driver-license/overview/MarkAsLost';
import DriverLicenseControlOverviewMainButton from 'Measure/components/vehicle/driver-license/overview/DriverLicenseControlOverviewMainButton';
import DriverLicenseSentEmails from 'Measure/components/vehicle/driver-license/overview/DriverLicenseSentEmails';
import OuterBox from 'Common/components/Boxes/OuterBox';
import BackButton from 'Application/components/Header/BackButton/BackButton';
import OuterBoxButtons from 'Common/components/Boxes/OuterBoxButtons';
import parseDate from 'Common/utils/parseDate';
import formatDate from 'Common/utils/formatDate';

const SideBySideContent = styled.div`
    @media screen and (min-width: 1090px) {
        display: flex;
        min-height: 320px;
        padding: 0;
    }
`;

const Content = styled(OuterBox)`
    margin-bottom: 10px;
    padding: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: 1090px) {
        margin-right: 10px;
        margin-bottom: 0;

        &:last-child {
            margin-right: 0;
        }
    }
`;

const LeftContent = styled(Content)`
    @media screen and (min-width: 1090px) {
        flex: 1;
    }
`;

const RightContent = styled(Content)`
    @media screen and (min-width: 1090px) {
        flex: 0 0 310px;
        width: 310px;
    }
`;

const DriverLicenseControlOverview = ({ driverLicenseControl }) => {
    const userId = useSelector(state => state.user.id);

    const isUsingOnlyNewModules = useSelector(
        state => state.user.isUsingOnlyNewModules,
    );

    const [selectedCheckId, setSelectedCheckId] = useState(null);

    const driverLicense = driverLicenseControl.driverLicense;
    const checks = driverLicense.checks;

    const selectedCheck = selectedCheckId
        ? checks.find(check => check.id === selectedCheckId)
        : checks[checks.length - 1];

    if (
        driverLicenseControl &&
        driverLicense &&
        !driverLicense.isInCreationProcess
    ) {
        let breadcrumbs;

        const isExaminer =
            isAuthorized([roles.MANAGER]) ||
            (isAllowedToExamineMeasure(measures.DRIVER_LICENSE) &&
                driverLicenseControl.examiners.findIndex(
                    examiner => examiner.id === userId,
                ) !== -1);

        if (isAuthorized([roles.MANAGER])) {
            if (isUsingOnlyNewModules) {
                breadcrumbs = (
                    <>
                        <Helmet>
                            <title>{`${driverLicenseControl.employee.lastName}, ${driverLicenseControl.employee.firstName} - Führerscheinkontrolle`}</title>
                        </Helmet>
                        <BackButton to="/measures/vehicles/driver-licenses" />
                        <Breadcrumb to="/measures/vehicles/driver-licenses">
                            Führerscheinkontrolle
                        </Breadcrumb>
                        <Breadcrumb
                            isActive
                        >{`${driverLicenseControl.employee.lastName}, ${driverLicenseControl.employee.firstName}`}</Breadcrumb>
                    </>
                );
            } else {
                breadcrumbs = (
                    <>
                        <Helmet>
                            <title>{`${driverLicenseControl.employee.lastName}, ${driverLicenseControl.employee.firstName} - Führerscheinkontrolle - Fuhrpark - Maßnahmen`}</title>
                        </Helmet>
                        <BackButton to="/measures/vehicles/driver-licenses" />
                        <Breadcrumb to="/measures">Maßnahmen</Breadcrumb>
                        <Breadcrumb to="/measures/vehicles">
                            Fuhrpark
                        </Breadcrumb>
                        <Breadcrumb to="/measures/vehicles/driver-licenses">
                            Führerscheinkontrolle
                        </Breadcrumb>
                        <Breadcrumb
                            isActive
                        >{`${driverLicenseControl.employee.lastName}, ${driverLicenseControl.employee.firstName}`}</Breadcrumb>
                    </>
                );
            }
        } else if (isAuthorized(roles.EMPLOYEE)) {
            if (isExaminer) {
                breadcrumbs = (
                    <>
                        <Helmet>
                            <title>{`${driverLicenseControl.employee.lastName}, ${driverLicenseControl.employee.firstName} - Führerscheinkontrolle - Maßnahmen`}</title>
                        </Helmet>
                        <BackButton to="/measures/vehicles/driver-licenses" />
                        <Breadcrumb
                            to={`/dashboard?module=${modules.MEASURES}`}
                        >
                            Maßnahmen
                        </Breadcrumb>
                        <Breadcrumb to="/measures/vehicles/driver-licenses">
                            Führerscheinkontrolle
                        </Breadcrumb>
                        <Breadcrumb
                            isActive
                        >{`${driverLicenseControl.employee.lastName}, ${driverLicenseControl.employee.firstName}`}</Breadcrumb>
                    </>
                );
            } else {
                // Standard employee (viewing his own driver's license)
                breadcrumbs = (
                    <>
                        <Helmet>
                            <title>Führerscheinkontrolle - Maßnahmen</title>
                        </Helmet>
                        <BackButton
                            to={`/dashboard?module=${modules.MEASURES}`}
                        />
                        <Breadcrumb
                            to={`/dashboard?module=${modules.MEASURES}`}
                        >
                            Maßnahmen
                        </Breadcrumb>
                        <Breadcrumb isActive>Führerscheinkontrolle</Breadcrumb>
                    </>
                );
            }
        }

        let title = 'Aktuell keine anstehende Kontrolle';

        if (selectedCheckId !== null) {
            title = `Prüfprotokoll der Kontrolle vom ${formatDate(
                parseDate(selectedCheck.completedAt),
            )}`;
        } else if (driverLicense.nextCheck.isInVerificationPeriod) {
            title = `Prüfprotokoll der Kontrolle vom ${formatDate(
                parseDate(driverLicense.nextCheck.dueDate),
            )}`;
        }

        return (
            <>
                {breadcrumbs}
                <Title>{title}</Title>
                <SideBySideContent>
                    <LeftContent>
                        <DriverLicenseSentEmails
                            isExaminer={isExaminer}
                            driverLicenseControl={driverLicenseControl}
                            selectedCheckId={selectedCheckId}
                            onChangeSelectedCheckId={setSelectedCheckId}
                        />
                    </LeftContent>
                    <RightContent>
                        <Examiners
                            driverLicenseControl={driverLicenseControl}
                        />
                        <MarkAsLost
                            driverLicenseControl={driverLicenseControl}
                        />
                    </RightContent>
                </SideBySideContent>
                <OuterBoxButtons>
                    <DriverLicenseControlOverviewMainButton
                        driverLicenseControl={driverLicenseControl}
                    />
                </OuterBoxButtons>
            </>
        );
    }

    return <Redirect to="/measures/vehicles/driver-licenses" />;
};

DriverLicenseControlOverview.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
};

export default DriverLicenseControlOverview;
