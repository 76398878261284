import React from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import LoginForm from 'User/components/LoginForm';
import Box from 'Application/components/Static/Box';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    min-height: calc(100vh - 30px);
`;

const StyledBox = styled(Box)`
    flex: 1;
    max-width: 500px;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
    animation: fadeIn 1s ease-in;
`;

const Logo = styled.img`
    display: block;
    width: 100%;
    max-width: 190px;
    margin: 0 auto 30px;

    @media screen and (min-width: 425px) {
        max-width: 230px;
        margin-bottom: 40px;
    }
`;

const Login = () => (
    <>
        <Helmet>
            <title>Login</title>
        </Helmet>
        <Wrapper>
            <StyledBox>
                <Logo src="/amedic-logo.svg" alt="Amedic-Manager" />
                <LoginForm />
            </StyledBox>
        </Wrapper>
    </>
);

export default Login;
