const getToasts = () =>
    window.localStorage.getItem('toasts')
        ? JSON.parse(window.localStorage.getItem('toasts'))
        : [];

const saveToasts = toasts => {
    window.localStorage.setItem('toasts', JSON.stringify(toasts));
};

export const pushToast = toast => {
    if (typeof window.localStorage !== 'undefined') {
        const toasts = getToasts();

        toasts.push(toast);

        saveToasts(toasts);
    }
};

export const popToast = () => {
    if (typeof window.localStorage !== 'undefined') {
        const toasts = getToasts();

        const toast = toasts.pop();

        saveToasts(toasts);

        return toast;
    }

    return null;
};
