import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import axios from 'axios';
import hideProjectQuestion from 'ProjectManager/Project/Common/api/dataManagement/questionnaire/question/hideProjectQuestion';
import withQuestionVisibility from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/withQuestionVisibility';

const useHideProjectQuestionFunction = (questionId, isCustomQuestion) => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const hideQuestion = useCallback(async () => {
        await hideProjectQuestion(
            projectId,
            questionId,
            isCustomQuestion,
            source.token,
        );

        setProject(withQuestionVisibility(questionId, isCustomQuestion, false));
    }, [projectId, questionId, isCustomQuestion, setProject, source.token]);

    return hideQuestion;
};

export default useHideProjectQuestionFunction;
