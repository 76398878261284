import { useRecoilValue } from 'recoil';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';

const useSelectedTemplateTopics = () => {
    const { topics } = useRecoilValue(templateAtom);

    return topics;
};

export default useSelectedTemplateTopics;
