import api from 'Application/api/api';

const showTemplateQuestion = (
    templateId,
    questionId,
    isCustom,
    cancelToken = null,
) =>
    api.post(
        `project-manager/templates/${templateId}/questionnaire/questions/${questionId}/${
            isCustom ? 1 : 0
        }/show`,
        {},
        { cancelToken },
    );

export default showTemplateQuestion;
