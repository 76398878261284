import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import ActiveNavigationItem from 'ProjectManager/DataManagement/Navigation/components/ActiveNavigationItem';
import OuterBox from 'Common/components/Boxes/OuterBox';
import Navigation from 'ProjectManager/DataManagement/Navigation/components/Navigation';
import { navigationItems } from 'ProjectManager/DataManagement/Navigation/constants/navigation';
import getQuestionnaireSectionIdentifier from 'ProjectManager/DataManagement/Questionnaire/helpers/getQuestionnaireSectionIdentifier';
import Questionnaire from 'ProjectManager/DataManagement/Questionnaire/components/Questionnaire/components/Questionnaire';
import EmployeeSelection from 'ProjectManager/DataManagement/EmployeeSelection/components/EmployeeSelection';
import TopicManagement from 'ProjectManager/DataManagement/TopicManagement/components/TopicManagement';
import useQuestionnaireSections from 'ProjectManager/DataManagement/Questionnaire/hooks/questionnaireSections/useQuestionnaireSections';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';
import { useRecoilValue } from 'recoil';
import questionInGuidanceModeState from 'ProjectManager/DataManagement/Questionnaire/components/Questionnaire/recoil/questionInGuidanceModeState';
import useAreAllStepsCompleted from 'ProjectManager/DataManagement/Common/hooks/useAreAllStepsCompleted/useAreAllStepsCompleted';
import GeneralInformation from 'ProjectManager/DataManagement/GeneralInformation';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import ActionsMenu from 'ProjectManager/Project/CreationProcess/components/ActionsMenu/ActionsMenu';

const CustomOuterBoxButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-right: -10px;

    > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;

        > * {
            margin: 10px 10px 0 0;
        }
    }
`;

const Wrapper = styled.div`
    padding-bottom: 15px;
`;

const Header = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    @media screen and (min-width: 1180px) {
        justify-content: flex-end;
        min-height: 48px;
        margin-top: -48px;
        margin-bottom: 0;
        padding-right: 0;
        pointer-events: none;
    }
`;

const HeaderTitle = styled(OuterBoxTitle)`
    margin-bottom: 0;
    margin-right: 10px;

    @media screen and (min-width: 1180px) {
        display: none;
    }
`;

const ActiveNavigationItemWrapper = styled.div`
    position: sticky;
    top: 80px;
    padding: 10px 0;
    background: ${colors.PAGE_BACKGROUND};
    z-index: 9999;

    @media screen and (min-width: 1180px) {
        display: none;
    }

    ${ifProp(
        'isInQuestionGuidanceMode',
        css`
            position: static;
            z-index: 2;
        `,
    )};
`;

const SidebarLayout = styled.div`
    @media screen and (min-width: 1180px) {
        display: flex;
    }
`;

const Sidebar = styled(OuterBox)`
    display: none;

    @media screen and (min-width: 1180px) {
        display: block;
        width: 340px;
        flex: 0 0 340px;
        margin-right: 10px;
    }
`;

const Content = styled(OuterBox)`
    background: none;
    box-shadow: none;

    @media screen and (min-width: 1180px) {
        flex: 1;
        padding: 20px;
        background: ${colors.OUTER_BOX_BACKGROUND};
        box-shadow: 0 7px 10px rgba(0, 0, 0, 0.16);
    }
`;

const DataManagement = ({
    title,
    activeNavigationItem,
    onActiveNavigationItemChange,
    onComplete,
}) => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);
    const isProject = dataManagementEntity === dataManagementEntities.PROJECT;
    const { isCreated: isProjectCreated } = useRecoilValue(projectAtom);

    const questionnaireSections = useQuestionnaireSections();
    const areAllStepsCompleted = useAreAllStepsCompleted();

    const handleGeneralInformationNextButtonClick = () => {
        onActiveNavigationItemChange(navigationItems.EMPLOYEE_SELECTION);
    };

    const handleEmployeeSelectionNextButtonClick = () => {
        onActiveNavigationItemChange(navigationItems.TOPIC_MANAGEMENT);
    };

    const handleTopicManagementNextButtonClick = () => {
        if (questionnaireSections.length > 0) {
            onActiveNavigationItemChange(
                getQuestionnaireSectionIdentifier(questionnaireSections[0]),
            );
        }
    };

    const handleQuestionnaireSectionNextButtonClick = async ({
        completeCreationIfPossible = false,
    } = {}) => {
        const currentIndex = questionnaireSections.findIndex(
            section =>
                activeNavigationItem ===
                getQuestionnaireSectionIdentifier(section),
        );

        if (currentIndex !== -1) {
            if (
                areAllStepsCompleted &&
                completeCreationIfPossible &&
                onComplete
            ) {
                await onComplete();
            } else if (currentIndex < questionnaireSections.length - 1) {
                onActiveNavigationItemChange(
                    getQuestionnaireSectionIdentifier(
                        questionnaireSections[currentIndex + 1],
                    ),
                );
            }
        }
    };

    const isActiveNavigationItemFromQuestionnaire =
        activeNavigationItem.startsWith('SECTION_') ||
        activeNavigationItem.startsWith('CUSTOM_SECTION_');

    const questionnaireSection = isActiveNavigationItemFromQuestionnaire
        ? questionnaireSections.find(
              section =>
                  activeNavigationItem ===
                  getQuestionnaireSectionIdentifier(section),
          )
        : null;

    const questionInGuidanceMode = useRecoilValue(questionInGuidanceModeState);
    const isInQuestionGuidanceMode = questionInGuidanceMode !== null;

    return (
        <Wrapper>
            <Header>
                <HeaderTitle>{title}</HeaderTitle>
                {isProject && !isProjectCreated && <ActionsMenu />}
            </Header>
            <ActiveNavigationItemWrapper
                isInQuestionGuidanceMode={isInQuestionGuidanceMode}
            >
                <ActiveNavigationItem
                    title={title}
                    identifier={activeNavigationItem}
                    onChange={onActiveNavigationItemChange}
                />
            </ActiveNavigationItemWrapper>
            <SidebarLayout>
                <Sidebar>
                    <Navigation
                        title={title}
                        activeItem={activeNavigationItem}
                        onActiveItemChange={onActiveNavigationItemChange}
                    />
                </Sidebar>
                <Content>
                    {activeNavigationItem ===
                        navigationItems.GENERAL_INFORMATION && (
                        <GeneralInformation
                            onNextButtonClick={
                                handleGeneralInformationNextButtonClick
                            }
                        />
                    )}
                    {activeNavigationItem ===
                        navigationItems.EMPLOYEE_SELECTION && (
                        <EmployeeSelection
                            onNextButtonClick={
                                handleEmployeeSelectionNextButtonClick
                            }
                        />
                    )}
                    {activeNavigationItem ===
                        navigationItems.TOPIC_MANAGEMENT && (
                        <TopicManagement
                            onNextButtonClick={
                                handleTopicManagementNextButtonClick
                            }
                        />
                    )}
                    {isActiveNavigationItemFromQuestionnaire && (
                        <Questionnaire
                            section={questionnaireSection}
                            onNextButtonClick={
                                handleQuestionnaireSectionNextButtonClick
                            }
                        />
                    )}
                </Content>
            </SidebarLayout>
            <CustomOuterBoxButtons>
                <div id="dataManagementBottomButtonsBefore" />
                <div id="dataManagementBottomButtons" />
                <div id="dataManagementBottomButtonsAfter" />
            </CustomOuterBoxButtons>
        </Wrapper>
    );
};

DataManagement.defaultProps = {
    onActiveNavigationItemChange: () => {},
    onComplete: () => {},
};

DataManagement.propTypes = {
    title: PropTypes.string.isRequired,
    activeNavigationItem: PropTypes.string.isRequired,
    onActiveNavigationItemChange: PropTypes.func,
    onComplete: PropTypes.func,
};

export default DataManagement;
