import styled from 'styled-components/macro';
import {
    CheckBoxOutlineBlankRounded,
    CheckBoxRounded,
    CloseRounded,
    ExpandMoreRounded,
    UnfoldLessRounded,
    UnfoldMoreRounded,
} from '@material-ui/icons';
import { components as reactSelectComponents } from 'react-select';
import { Chip, Tooltip } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import colors from 'Common/constants/colors';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';
import Button from 'Common/components/Button';
import useSize from '@react-hook/size';
import Loader from 'Common/components/Loader';

const SelectedMultiValue = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const RemainingValues = styled.span`
    color: ${colors.LIGHT_GRAY};
`;

const ValueContainer = ({ children, ...props }) => {
    if (props.isMulti) {
        // Get the selected options and reverse them,
        // so that the newest options appear always on top.
        const selectedOptions = props.getValue() ?? [];
        const options = [...selectedOptions].reverse();

        return (
            <reactSelectComponents.ValueContainer {...props}>
                {options.length > 0 && (
                    <SelectedMultiValue className="react-select-selected-multi-value">
                        {options[0].label}
                        {options.length > 1 && (
                            <RemainingValues>
                                {` und ${options.length - 1} mehr...`}
                            </RemainingValues>
                        )}
                    </SelectedMultiValue>
                )}
                {children[1]}
            </reactSelectComponents.ValueContainer>
        );
    }

    return (
        <reactSelectComponents.ValueContainer {...props}>
            {children}
        </reactSelectComponents.ValueContainer>
    );
};

const ClearIcon = styled(CloseRounded)`
    font-size: 18px !important;
`;

const ClearIndicator = ({ children = <ClearIcon />, ...props }) => (
    <reactSelectComponents.ClearIndicator {...props}>
        <Tooltip title="entfernen">{children}</Tooltip>
    </reactSelectComponents.ClearIndicator>
);

const DropdownIndicator = ({ children = <ExpandMoreRounded />, ...props }) => (
    <reactSelectComponents.DropdownIndicator {...props}>
        {props.selectProps?.showSpinningLoader ? (
            <Loader size={24} color={colors.DARK_GRAY} />
        ) : (
            children
        )}
    </reactSelectComponents.DropdownIndicator>
);

const SelectedMultiValueOptions = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    padding: 10px 36px 5px 10px;
    border-bottom: 1px solid ${colors.LIGHT_DIVIDER};

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 36px;
        background: ${colors.WHITE};
        height: 100%;
        z-index: 1;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 36px;
        width: 20px;
        height: 100%;
        background: transparent;
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
        );
        z-index: 1;
    }

    ${ifProp(
        'isExpanded',
        css`
            flex-wrap: wrap;
        `,
        css`
            overflow: hidden;
        `,
    )};

    .MuiChip-root {
        margin-right: 5px;
        margin-bottom: 5px;
        background-color: ${colors.DIRTY_WHITE};
        color: ${colors.DARK_GRAY};
        font-family: 'Poppins', sans-serif;

        &:last-child {
            margin-right: 0;
        }
    }
`;

const ExpandCollapseButton = styled(Button)`
    position: absolute;
    top: 6px;
    right: 0;
    z-index: 2;
`;

const collapsedHeight = 53;

const Menu = ({ children, ...props }) => {
    const [areMultiValuesOpened, setAreMultiValuesOpened] = useState(false);
    const [hasOverflowChildren, setHasOverflowChildren] = useState(false);
    const multiValuesRef = useRef(null);

    // eslint-disable-next-line
    const [, multiValuesHeight] = useSize(multiValuesRef);

    const recalculateHasOverflow = () => {
        if (multiValuesRef && multiValuesRef.current) {
            if (areMultiValuesOpened) {
                const { offsetHeight } = multiValuesRef.current;
                const isVerticallyOverflowed = offsetHeight > collapsedHeight;

                setHasOverflowChildren(isVerticallyOverflowed);
            } else {
                const { offsetWidth, scrollWidth } = multiValuesRef.current;
                const isHorizontallyOverflowed = offsetWidth < scrollWidth;

                setHasOverflowChildren(isHorizontallyOverflowed);
            }
        } else {
            setHasOverflowChildren(false);
        }
    };

    useEffect(() => {
        recalculateHasOverflow();
    });

    if (props.isMulti) {
        // Get the selected options and reverse them,
        // so that the newest options appear always on top.
        const selectedOptions = props.getValue() ?? [];
        const options = [...selectedOptions].reverse();

        const handleDeleteOption = (e, deletedOption) => {
            const newOptions = [...selectedOptions];

            const index = newOptions.findIndex(
                option => option.value === deletedOption.value,
            );

            if (index !== -1) {
                newOptions.splice(index, 1);
                props.setValue(newOptions);
            }
        };

        const toggleMultiValueOptions = () => {
            setAreMultiValuesOpened(
                prevAreMultiValuesOpened => !prevAreMultiValuesOpened,
            );
        };

        const isOptionDisabled = option => {
            if (props.selectProps.isOptionDisabled) {
                return props.selectProps.isOptionDisabled(option);
            }

            return option.isDisabled ?? false;
        };

        return (
            <reactSelectComponents.Menu {...props}>
                {props.contentAboveMultiValueOptions}
                {options.length > 0 && (
                    <SelectedMultiValueOptions
                        ref={multiValuesRef}
                        isExpanded={areMultiValuesOpened}
                        onTouchStart={() => {
                            document.isInsideOfMenuTouched = true;
                        }}
                    >
                        {options.map(option => (
                            <Chip
                                key={option.value}
                                onDelete={
                                    !isOptionDisabled(option)
                                        ? e => handleDeleteOption(e, option)
                                        : undefined
                                }
                                label={option.label}
                            />
                        ))}
                        {hasOverflowChildren && (
                            <ExpandCollapseButton
                                dark
                                iconOnly
                                icon={
                                    areMultiValuesOpened ? (
                                        <UnfoldLessRounded />
                                    ) : (
                                        <UnfoldMoreRounded />
                                    )
                                }
                                type="button"
                                onClick={toggleMultiValueOptions}
                                tooltip={
                                    areMultiValuesOpened ? 'weniger' : 'mehr'
                                }
                            />
                        )}
                    </SelectedMultiValueOptions>
                )}
                {children}
            </reactSelectComponents.Menu>
        );
    }

    return (
        <reactSelectComponents.Menu {...props}>
            {children}
        </reactSelectComponents.Menu>
    );
};

const MultiValueOptionText = styled.div`
    padding-top: 2px;
    margin-left: 5px;
`;

const Option = ({ children, ...props }) => {
    if (props.isMulti) {
        return (
            <reactSelectComponents.Option {...props}>
                {props.isSelected ? (
                    <CheckBoxRounded />
                ) : (
                    <CheckBoxOutlineBlankRounded />
                )}
                <MultiValueOptionText>{children}</MultiValueOptionText>
            </reactSelectComponents.Option>
        );
    }

    return (
        <reactSelectComponents.Option {...props}>
            {children}
        </reactSelectComponents.Option>
    );
};

const Input = ({ onBlur, ...props }) => {
    const handleBlur = e => {
        if (document.isInsideOfMenuTouched) {
            document.isInsideOfMenuTouched = false;
        } else {
            onBlur(e);
        }
    };

    return <reactSelectComponents.Input onBlur={handleBlur} {...props} />;
};

const reactSelectCustomComponents = {
    ValueContainer,
    ClearIndicator,
    DropdownIndicator,
    Menu,
    Option,
    Input,
};

export default reactSelectCustomComponents;
