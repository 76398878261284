const withoutParticipant = participantId => prevParticipantIds => {
    const index = prevParticipantIds.indexOf(participantId);

    if (index === -1) {
        return prevParticipantIds;
    }

    const newParticipantIds = [...prevParticipantIds];

    newParticipantIds.splice(index, 1);

    return newParticipantIds;
};

export default withoutParticipant;
