import api from 'Application/api/api';

const endProject = (id, endingDate, cancelToken = null) =>
    api.post(
        `project-manager/projects/${id}/end`,
        { endingDate },
        { cancelToken },
    );

export default endProject;
