import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { debounce } from 'lodash';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import hasUnsavedChangesState from 'Common/recoil/hasUnsavedChangesState';
import Answer from 'ProjectManager/DataManagement/Questionnaire/components/Question/Answers/components/Answer';
import {
    RadioButtonCheckedRounded,
    RadioButtonUncheckedRounded,
} from '@material-ui/icons';
import colors from 'Common/constants/colors';
import useGiveSingleChoiceAnswerFunction from 'ProjectManager/DataManagement/Questionnaire/components/Question/Answers/hooks/giveSingleChoiceAnswer/useGiveSingleChoiceAnswerFunction';

const Wrapper = styled.div`
    max-width: 520px;
`;

const RadioButton = styled(RadioButtonUncheckedRounded)`
    color: ${colors.DARK_GRAY};
`;

const RadioButtonSelected = styled(RadioButtonCheckedRounded)`
    color: ${colors.SEARCH_FIELD_BORDER};
`;

const SingleChoice = ({ question }) => {
    const giveSingleChoiceAnswer = useGiveSingleChoiceAnswerFunction(
        question.id,
        question.isCustom,
    );

    const [, setHasUnsavedChanges] = useRecoilState(hasUnsavedChangesState);

    const [answerId, setAnswerId] = useState(
        () =>
            question.singleOrMultipleAnswers.find(answer => answer.isSelected)
                ?.id ?? null,
    );

    const debouncedSave = useCallback(
        debounce(
            async selectedAnswerId => {
                try {
                    await giveSingleChoiceAnswer(selectedAnswerId);
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        notify(
                            'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                            {
                                type: toast.TYPE.ERROR,
                            },
                        );
                    }
                }

                setHasUnsavedChanges(false);
            },
            350,
            {
                leading: true,
                trailing: true,
            },
        ),
        [giveSingleChoiceAnswer, setHasUnsavedChanges],
    );

    const handleAnswerClick = clickedAnswerId => {
        setHasUnsavedChanges(true);
        setAnswerId(clickedAnswerId);
        debouncedSave(clickedAnswerId);
    };

    return (
        <Wrapper>
            {question.singleOrMultipleAnswers.map(answer => (
                <Answer
                    key={answer.id}
                    text={answer.text}
                    isSelected={answer.id === answerId}
                    selectedIcon={<RadioButtonSelected />}
                    notSelectedIcon={<RadioButton />}
                    onClick={() => handleAnswerClick(answer.id)}
                />
            ))}
        </Wrapper>
    );
};

SingleChoice.propTypes = {
    question: PropTypes.object.isRequired,
};

export default SingleChoice;
