import api from 'Application/api/api';

const addProjectQuestionComment = (projectId, questionId, isCustom, cancelToken = null) =>
    api.post(
        `project-manager/projects/${projectId}/questionnaire/questions/${questionId}/${
            isCustom ? 1 : 0
        }/comment/add`,
        {},
        { cancelToken },
    );

export default addProjectQuestionComment;
