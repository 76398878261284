import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import selectProjectTopic from 'ProjectManager/Project/Common/api/dataManagement/topic/selectProjectTopic';
import deselectProjectTopic from 'ProjectManager/Project/Common/api/dataManagement/topic/deselectProjectTopic';
import getProjectQuestionnaireSections from 'ProjectManager/Project/Common/api/dataManagement/questionnaire/getProjectQuestionnaireSections';
import withAdditionalTopic from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/topic/withAdditionalTopic';
import withoutTopic from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/topic/withoutTopic';
import withQuestionnaireSections from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/withQuestionnaireSections';

const useProjectTopicFunctions = () => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const selectTopic = useCallback(
        async selectedTopic => {
            await selectProjectTopic(projectId, selectedTopic.id, source.token);

            const questionnaireSections = await getProjectQuestionnaireSections(
                projectId,
                source.token,
            );

            setProject(withAdditionalTopic(selectedTopic));
            setProject(withQuestionnaireSections(questionnaireSections));
        },
        [projectId, setProject, source.token],
    );

    const deselectTopic = useCallback(
        async deselectedTopicId => {
            await deselectProjectTopic(
                projectId,
                deselectedTopicId,
                source.token,
            );

            const questionnaireSections = await getProjectQuestionnaireSections(
                projectId,
                source.token,
            );

            setProject(withoutTopic(deselectedTopicId));
            setProject(withQuestionnaireSections(questionnaireSections));
        },
        [projectId, setProject, source.token],
    );

    return { selectTopic, deselectTopic };
};

export default useProjectTopicFunctions;
