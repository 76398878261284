import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import File from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/components/File/File';
import useSize from '@react-hook/size';
import { useWindowWidth } from '@react-hook/window-size';
import ShowAllFilesButton from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/components/ShowAll/ShowAllFilesButton';
import DownloadAllFilesButton from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/components/DownloadAllFilesButton/components/DownloadAllFilesButton';
import colors from 'Common/constants/colors';

const Wrapper = styled.div``;

const Title = styled.div`
    margin-bottom: 10px;
    color: ${colors.GRAY};
    font-size: 14px;
    line-height: 21px;
`;

const ListWrapper = styled.div`
    @media screen and (min-width: 580px) {
        margin-left: -25px;
    }
`;

const StyledFile = styled(File)`
    @media screen and (min-width: 580px) {
        display: inline-block;
        width: 210px;
        margin-left: 25px;
    }
`;

const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 5px;

    > div {
        margin-top: 10px;
        margin-right: 10px;

        > button,
        > a {
            padding-left: 0;
        }
    }
`;

const fileTileWidth = 235;

const Files = ({ question }) => {
    const wrapperRef = useRef(null);
    const [wrapperWidth] = useSize(wrapperRef);

    const windowWidth = useWindowWidth();
    const isOneTileOnly = windowWidth < 580;

    const visibleTilesCount = isOneTileOnly
        ? 1
        : Math.min(
              // How many tiles could fit into the wrapper
              Math.floor(wrapperWidth / fileTileWidth),

              // Prevent accessing undefined index if the files are less than the
              // amount of tiles that the wrapper can fit.
              question.files.length,
          );

    const isShowAllButtonVisible = question.files.length > visibleTilesCount;
    const isDownloadAllButtonVisible = question.files.length > 1;

    return (
        <Wrapper>
            <Title>Zuletzt hochgeladene Dateien:</Title>
            <ListWrapper ref={wrapperRef}>
                {question.files.slice(0, visibleTilesCount).map(file => (
                    <StyledFile key={file.id} question={question} file={file} />
                ))}
            </ListWrapper>
            {(isShowAllButtonVisible || isDownloadAllButtonVisible) && (
                <ButtonsWrapper>
                    {isShowAllButtonVisible && (
                        <div>
                            <ShowAllFilesButton question={question} />
                        </div>
                    )}
                    {isDownloadAllButtonVisible && (
                        <div>
                            <DownloadAllFilesButton
                                underline
                                question={question}
                            />
                        </div>
                    )}
                </ButtonsWrapper>
            )}
        </Wrapper>
    );
};

Files.propTypes = {
    question: PropTypes.object.isRequired,
};

export default Files;
