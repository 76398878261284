import React from 'react';
import Box from 'Application/components/Static/Box';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import { Helmet } from 'react-helmet-async';
import BackButton from 'Application/components/Header/BackButton/BackButton';

const Imprint = () => (
    <Box>
        <Helmet>
            <title>Impressum</title>
        </Helmet>
        <BackButton to="/dashboard" />
        <Breadcrumb isActive>Impressum</Breadcrumb>
        <h1>Impressum</h1>
        <p>
            Amedic GmbH & Co. KG
            <br />
            Witzlebenplatz 3
            <br />
            14057 Berlin
        </p>
        <br />
        <h2>Kontakt</h2>
        <p>
            Tel +49 30 32 30 16 25
            <br />
            <a href="mailto:info@amedic.de">info@amedic.de</a>
        </p>
        <p>
            AMEDIC Arbeits- und Medizintechnische Dienste GmbH & Co KG
            <br />
            HRA-Nummer der Gesellschaft lautet: HRA 54374
            <br />
            Amedic Geschäftsführungs GmbH
            <br />
            Eingetragen im Handelsregister, Amtsgericht Charlottenburg Berlin
            unter HRB 192793
            <br />
            ST-NR.: 27/140/50774
        </p>
        <br />
        <img src="/impressum-transparent.png" alt="Amedic GmbH" />
        <p>
            Entworfen von: Damir Letnin-Mekinic, Tomasz Kedzierski
        </p>
    </Box>
);

export default Imprint;
