/**
 * Allows you to use another column for sorting a table column.
 * An example usage would be when you have a "name" column, but it includes more advanced data.
 * In such cases probably you would want to still enable the sorting by this column, but use
 * some other data to sort by it. All you have to do is add you data in the "data" memoized constant.
 * After that, define a memoized custom sort type and pass it to the column you want to sort.
 *
 *   // Define the memoized custom sorting function
 *   const nameSortingFunction = useCallback(
 *       differentColumnSortType('rawName'), // <--- "rawName" here is the name of the property we wish to sort by
 *       [],
 *   );
 *
 *   // Define the memoized columns
 *   const columns = useMemo(
 *       () => [
 *           {
 *               Header: 'ID',
 *               accessor: 'id',
 *           },
 *           {
 *               Header: 'Name',
 *               accessor: 'name',
 *               sortType: nameSortingFunction, // <--- Add the custom sorting function to the column
 *           },
 *        ],
 *        [nameSortingFunction],
 *    );
 *
 *   // Define the memoized data
 *   const data = useMemo(
 *       () => [
 *           items.map(item => ({
 *               id: item.id,
 *
 *               // Here we see the complex column "name" which we can't sort by directly
 *               name: (
 *                   <>
 *                       {item.isActive ? <ActiveIcon /> : <InactiveIcon />}
 *                       {item.name}
 *                    </>
 *               ),
 *
 *               // So, to overcome this issue, we place the "rawName" property,
 *               // which will be used for sorting purposes only.
 *               // Since the "rawName" is not included as a column in the memoized "columns" constant,
 *               // it will not show up in the UI, but still will be accessible in the custom sorting function
 *               // which makes this whole functionality possible.
 *               rawName: item.name,
 *           }))
 *        ],
 *        [],
 *    );
 */
const differentColumnSortType = columnId => (rowA, rowB) => {
    const a = rowA.original[columnId];
    const b = rowB.original[columnId];

    return a === b ? 0 : a > b ? 1 : -1;
};

export default differentColumnSortType;
