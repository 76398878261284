import api from 'Application/api/api';

const resumeInstructionAssignmentPresentation = (id, cancelToken = null) =>
    api.post(
        `/education/instruction-assignments/${id}/presentation/resume`,
        {},
        { cancelToken },
    );

export default resumeInstructionAssignmentPresentation;
