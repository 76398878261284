import React from 'react';
import PropTypes from 'prop-types';
import LabeledValue from 'ProjectManager/Project/Preview/LabeledValue';

const Supervisor = ({ supervisor }) => {
    const supervisorUser = supervisor.user;

    return (
        <LabeledValue label="Auftragsverantwortlicher">
            {`${supervisorUser.lastName}, ${supervisorUser.firstName}`}
        </LabeledValue>
    );
};

Supervisor.propTypes = {
    supervisor: PropTypes.object.isRequired,
};

export default Supervisor;
