import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useProjectGiveYesNoAnswerFunction from 'ProjectManager/DataManagement/Questionnaire/components/Question/Answers/hooks/giveYesNoAnswer/useProjectGiveYesNoAnswerFunction';
import useTemplateGiveYesNoAnswerFunction from 'ProjectManager/DataManagement/Questionnaire/components/Question/Answers/hooks/giveYesNoAnswer/useTemplateGiveYesNoAnswerFunction';

const useGiveYesNoAnswerFunction = (questionId, isCustomQuestion) => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useProjectGiveYesNoAnswerFunction(
        questionId,
        isCustomQuestion,
    );

    const dataFromTemplate = useTemplateGiveYesNoAnswerFunction(
        questionId,
        isCustomQuestion,
    );

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return {};
};

export default useGiveYesNoAnswerFunction;
