import { useRecoilValue } from 'recoil';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';

const useTemplateQuestionnaireSections = () => {
    const { questionnaireSections } = useRecoilValue(templateAtom);

    return questionnaireSections;
};

export default useTemplateQuestionnaireSections;
