import * as Yup from 'yup';
import { get, isEmpty } from 'lodash';

const uniquePropertyTest = function (
    testedObject,
    propertyName,
    message,
    compareFunction = null,
) {
    if (
        this.parent
            // Exclude the same object (by reference)
            .filter(object => object !== testedObject)

            // Check for property match among some of the other objects
            .some(object => {
                const objectValue = get(object, propertyName);
                const testedObjectValue = get(testedObject, propertyName);

                return compareFunction
                    ? compareFunction(objectValue, testedObjectValue)
                    : objectValue === testedObjectValue;
            })
    ) {
        throw this.createError({
            path: `${this.path}.${propertyName}`,
            message,
        });
    }

    return true;
};

Yup.addMethod(
    Yup.object,
    'uniqueProperty',
    function (propertyName, message, compareFunction = null) {
        return this.test('unique', message, function (value) {
            return uniquePropertyTest.call(
                this,
                value,
                propertyName,
                message,
                compareFunction,
            );
        });
    },
);

Yup.addMethod(Yup.object, 'uniqueProperties', function (properties) {
    return this.test('unique', '', function (value) {
        const errors = properties
            .map(([propertyName, message, compareFunction = null]) => {
                try {
                    return uniquePropertyTest.call(
                        this,
                        value,
                        propertyName,
                        message,
                        compareFunction,
                    );
                } catch (error) {
                    return error;
                }
            })
            .filter(error => error instanceof Yup.ValidationError);

        if (!isEmpty(errors)) {
            throw new Yup.ValidationError(errors);
        }

        return true;
    });
});
