import de from 'date-fns/locale/de';
import formatDuration from 'date-fns/formatDuration';
import intervalToDuration from 'date-fns/intervalToDuration';

const formatInterval = (interval, options = {}) => {
    const formattedInterval = formatDuration(intervalToDuration(interval), {
        locale: de,
        ...options,
    }).replace(/ein/g, '1');

    return formattedInterval ? formattedInterval : null;
};

export default formatInterval;
