import { selector } from 'recoil';
import isGeneralProjectInformationStepCompletedSelector from 'ProjectManager/Project/Common/recoil/project/selectors/isGeneralProjectInformationStepCompletedSelector';
import isProjectEmployeeSelectionStepCompletedSelector from 'ProjectManager/Project/Common/recoil/project/selectors/isProjectEmployeeSelectionStepCompletedSelector';
import isProjectTopicManagementStepCompletedSelector from 'ProjectManager/Project/Common/recoil/project/selectors/isProjectTopicManagementStepCompletedSelector';
import isProjectQuestionnaireCompletedSelector from 'ProjectManager/Project/Common/recoil/project/selectors/isProjectQuestionnaireCompletedSelector';

const areAllProjectStepsCompletedSelector = selector({
    key: 'projectManagement_areAllProjectStepsCompleted',
    get: ({ get }) => {
        const isGeneralProjectInformationStepCompleted = get(
            isGeneralProjectInformationStepCompletedSelector,
        );

        const isEmployeeSelectionStepCompleted = get(
            isProjectEmployeeSelectionStepCompletedSelector,
        );

        const isTopicManagementStepCompleted = get(
            isProjectTopicManagementStepCompletedSelector,
        );

        const isQuestionnaireCompleted = get(
            isProjectQuestionnaireCompletedSelector,
        );

        return (
            isGeneralProjectInformationStepCompleted &&
            isTopicManagementStepCompleted &&
            isEmployeeSelectionStepCompleted &&
            isQuestionnaireCompleted
        );
    },
});

export default areAllProjectStepsCompletedSelector;
