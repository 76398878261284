import React, { useCallback, useEffect } from 'react';
import EmployeeView from 'Employee/components/EmployeeView';
import { useParams } from 'react-router';
import Loader from 'Common/components/Loader';
import { useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';
import getEmployee from 'Employee/api/getEmployee';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';
import BackButton from 'Application/components/Header/BackButton/BackButton';

const EmployeeManagement = () => {
    const dispatch = useDispatch();
    const { id: employeeId } = useParams();

    const employeeRequest = useCallback(
        cancelToken =>
            getEmployee(employeeId, ['completedInstructions'], cancelToken),
        [employeeId],
    );

    const {
        data: employee,
        loadData: loadEmployee,
        isLoading: isLoadingEmployee,
        hasError,
        error,
    } = useAxiosRequest(employeeRequest, null);

    useEffect(() => {
        if (error?.response?.status === 404) {
            dispatch(replace('/404'));
        }
    }, [dispatch, error]);

    const isLoading = isLoadingEmployee || employee === null;

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : hasError ? (
                <RetryLoading onRetry={loadEmployee} />
            ) : (
                <>
                    <Helmet>
                        <title>{`${employee.lastName}, ${employee.firstName} - Mitarbeiter`}</title>
                    </Helmet>
                    <BackButton to="/employees" />
                    <Breadcrumb to="/employees">Mitarbeiter</Breadcrumb>
                    <Breadcrumb
                        isActive
                    >{`${employee.lastName}, ${employee.firstName}`}</Breadcrumb>
                    <EmployeeView employee={employee} />
                </>
            )}
        </>
    );
};

export default EmployeeManagement;
