import api from 'Application/api/api';

const addProjectCustomTopic = (projectId, data, cancelToken = null) =>
    api.post(
        `project-manager/projects/${projectId}/custom-topics`,
        data,
        { cancelToken },
    );

export default addProjectCustomTopic;
