import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useProjectQuestionFileFunctions from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/hooks/questionFileFunctions/useProjectQuestionFileFunctions';
import useTemplateQuestionFileFunctions from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/hooks/questionFileFunctions/useTemplateQuestionFileFunctions';

const useQuestionFileFunctions = (questionId, isCustomQuestion) => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useProjectQuestionFileFunctions(
        questionId,
        isCustomQuestion,
    );

    const dataFromTemplate = useTemplateQuestionFileFunctions(
        questionId,
        isCustomQuestion,
    );

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return {};
};

export default useQuestionFileFunctions;
