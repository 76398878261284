import api from 'Application/api/api';

const getClient = (id, load, cancelToken = null) =>
    api.get(`project-manager/clients/${id}`, {
        params: {
            load,
        },
        cancelToken,
    });

export default getClient;
