import api from 'Application/api/api';

const rejectDriverLicenseDistanceFile = (
    driverLicenseControlId,
    type,
    reasons,
    cancelToken = null,
) =>
    api.post(
        `/measures/vehicles/driver-licenses/controls/${driverLicenseControlId}/driver-license/distance-check/reject-file`,
        {
            type,
            reasons,
        },
        {
            cancelToken,
        },
    );

export default rejectDriverLicenseDistanceFile;
