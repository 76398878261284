import api from 'Application/api/api';

const passwordConfirmUploadedFilesForDistanceProcedure = (
    driverLicenseControlId,
    password,
    cancelToken = null,
) =>
    api.post(
        `/measures/vehicles/driver-licenses/controls/${driverLicenseControlId}/driver-license/distance-check/password-confirm-uploaded-files`,
        {
            password,
        },
        {
            cancelToken,
        },
    );

export default passwordConfirmUploadedFilesForDistanceProcedure;
