import React, { useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { useRecoilState } from 'recoil';
import colors from 'Common/constants/colors';
import hasUnsavedChangesState from 'Common/recoil/hasUnsavedChangesState';
import axios from 'axios';
import { debounce } from 'lodash';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import ErrorMessage from 'Common/components/Form/ErrorMessage';
import useWorkingDaysData from 'ProjectManager/DataManagement/WorkingDays/hooks/useWorkingDaysData';
import weekDays from 'ProjectManager/DataManagement/WorkingDays/constants/weekDays';

const Wrapper = styled.div`
    max-width: 250px;

    @media screen and (min-width: 400px) {
        position: relative;
        max-width: initial;
    }
`;

const WeekDay = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 10px;
    border-radius: 50%;
    border: 0 none;
    outline: 0 none;
    background: ${colors.DIRTY_WHITE};
    color: ${colors.DARK_GRAY};
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }

    ${ifProp(
        'isSelected',
        css`
            background: ${colors.PRIMARY};
            color: ${colors.DIRTY_WHITE};
        `,
    )};
`;

const StyledErrorMessage = styled(ErrorMessage)`
    margin-bottom: 0;

    @media screen and (min-width: 790px) {
        position: absolute;
        bottom: -5px;
        left: 0;
        transform: translateY(100%);
    }
`;

const WorkingDays = () => {
    const {
        initialWorkingDays,
        updateWorkingDays,
        isRequired,
    } = useWorkingDaysData();

    const [, setHasUnsavedChanges] = useRecoilState(hasUnsavedChangesState);

    const debouncedSave = useCallback(
        debounce(
            async values => {
                try {
                    await updateWorkingDays(values);
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        notify(
                            'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                            {
                                type: toast.TYPE.ERROR,
                            },
                        );
                    }
                }

                setHasUnsavedChanges(false);
            },
            350,
            {
                leading: true,
                trailing: true,
            },
        ),
        [updateWorkingDays, setHasUnsavedChanges],
    );

    const [workingDays, setWorkingDays] = useState(initialWorkingDays);

    const handleWeekDayClick = weekDay => {
        setHasUnsavedChanges(true);

        setWorkingDays(prevWorkingDays => {
            const newWorkingDays = [...prevWorkingDays];

            const index = newWorkingDays.indexOf(weekDay);

            if (index === -1) {
                newWorkingDays.push(weekDay);
            } else {
                newWorkingDays.splice(index, 1);
            }

            debouncedSave(newWorkingDays);

            return newWorkingDays;
        });
    };

    return (
        <Wrapper>
            <div>
                {weekDays.map(weekDay => (
                    <WeekDay
                        key={weekDay.value}
                        isSelected={workingDays.includes(weekDay.value)}
                        onClick={() => handleWeekDayClick(weekDay.value)}
                    >
                        {weekDay.label}
                    </WeekDay>
                ))}
            </div>
            {isRequired && workingDays.length === 0 && (
                <StyledErrorMessage message="Bitte wählen Sie mindestens einen Tag aus." />
            )}
        </Wrapper>
    );
};

export default WorkingDays;
