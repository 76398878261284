import { store } from 'Application/store';

const areModulesAvailable = modules => {
    const availableModules = store.getState().user.availableModules;

    if (!availableModules) {
        return false;
    }

    const intersectingModules = modules.filter(module =>
        availableModules.includes(module),
    );

    return intersectingModules.length > 0;
};

export default areModulesAvailable;
