import api from 'Application/api/api';

const updateTemplateWorkingDays = (templateId, workingDays, cancelToken = null) =>
    api.post(
        `project-manager/templates/${templateId}/working-days`,
        {
            workingDays,
        },
        {
            cancelToken,
        },
    );

export default updateTemplateWorkingDays;
