import api from 'Application/api/api';

const getManagers = (load, filter, cancelToken = null) =>
    api.get('/managers', {
        params: {
            load,
            ...filter,
        },
        cancelToken,
    });

export default getManagers;
