const filterEmployeesWithoutOtherAreas = (
    employees,
    selectedAreaIds,
    areaId,
) => {
    const employeesWithClickedArea = employees.filter(
        employee => employee.areas.findIndex(area => area.id === areaId) !== -1,
    );

    const theRestProjectAreaIds = selectedAreaIds.filter(
        selectedAreaId => selectedAreaId !== areaId,
    );

    return employeesWithClickedArea.filter(employee => {
        const employeeAreaIds = employee.areas.map(area => area.id);

        return !employeeAreaIds.some(employeeAreaId =>
            theRestProjectAreaIds.includes(employeeAreaId),
        );
    });
};

export default filterEmployeesWithoutOtherAreas;
