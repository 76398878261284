import React from 'react';
import PropTypes from 'prop-types';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import Modal from 'Common/components/Modals/Modal';

const ClearAllModal = ({ onClose, onConfirm }) => (
    <Modal
        isOpen
        onRequestClose={onClose}
        buttons={
            <>
                <Button text dark type="button" onClick={onClose}>
                    Abbrechen
                </Button>
                <Button type="button" onClick={onConfirm}>
                    Zurücksetzen
                </Button>
            </>
        }
    >
        <ModalTitle>Zurücksetzen</ModalTitle>
        <p>
            Möchtest du die Filter wirklich auf die Standardeinstellungen
            zurücksetzen?
        </p>
    </Modal>
);

ClearAllModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default ClearAllModal;
