import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useIsProjectEmployeeSelectionStepCompleted from 'ProjectManager/DataManagement/Navigation/hooks/useIsEmployeeSelectionStepCompleted/useIsProjectEmployeeSelectionStepCompleted';
import useIsTemplateEmployeeSelectionStepCompleted from 'ProjectManager/DataManagement/Navigation/hooks/useIsEmployeeSelectionStepCompleted/useIsTemplateEmployeeSelectionStepCompleted';

const useIsEmployeeSelectionStepCompleted = () => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const projectData = useIsProjectEmployeeSelectionStepCompleted();
    const templateData = useIsTemplateEmployeeSelectionStepCompleted();

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return projectData;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return templateData;
    }

    return false;
};

export default useIsEmployeeSelectionStepCompleted;
