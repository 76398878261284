const withTemplatesMovedFromGroupToGroup = (
    fromGroupId,
    toGroup,
) => prevTemplates => {
    const otherTemplatesInTheGroup = prevTemplates.filter(
        prevTemplate => prevTemplate.group.id === toGroup.id,
    );

    let positionOffset = otherTemplatesInTheGroup.length;

    return prevTemplates.map(template => {
        if (template.group.id === fromGroupId) {
            return {
                ...template,
                position: positionOffset + template.position,
                group: toGroup,
            };
        }

        return template;
    });
};

export default withTemplatesMovedFromGroupToGroup;
