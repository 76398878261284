import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    @media screen and (min-width: 375px) {
        flex-direction: row;
    }
`;

const Content = styled.div`
    position: relative;

    svg {
        filter: drop-shadow(0px 7px 10px rgba(0, 0, 0, 0.25));
    }

    circle {
        fill: ${colors.OUTER_BOX_BACKGROUND};
        stroke: ${colors.PRIMARY};
        stroke-linecap: round;
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
    }
`;

const ProgressNumber = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${colors.DIRTY_WHITE};
    font-weight: 600;
    font-size: 19px;
`;

const Text = styled.div`
    margin-top: 20px;
    color: ${colors.DIRTY_WHITE};
    font-weight: 600;
    font-size: 18px;

    @media screen and (min-width: 375px) {
        margin-top: 0;
        margin-left: 20px;
    }

    @media screen and (min-width: 480px) {
        margin-left: 25px;
        font-size: 22px;
    }
`;

const CircleProgress = ({ className, percentage, size, strokeWidth, text }) => {
    const center = size / 2;
    const radius = (size - strokeWidth) / 2;
    const circumference = 2 * Math.PI * radius;

    return (
        <Wrapper className={className}>
            <Content
                style={{
                    width: size,
                    height: size,
                }}
            >
                <svg
                    style={{
                        width: size,
                        height: size,
                    }}
                >
                    <circle
                        cx={center}
                        cy={center}
                        r={radius}
                        style={{
                            strokeWidth,
                            strokeDasharray: circumference,
                            strokeDashoffset:
                                circumference -
                                (percentage / 100) * circumference,
                        }}
                    />
                </svg>
                <ProgressNumber>{percentage}%</ProgressNumber>
            </Content>
            <Text>{text}</Text>
        </Wrapper>
    );
};

CircleProgress.propTypes = {
    size: PropTypes.number.isRequired,
    strokeWidth: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
};

export default CircleProgress;
