import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Input from 'Common/components/Form/Fields/Input';
import { debounce } from 'lodash';
import changeProjectName from 'ProjectManager/Project/Common/api/dataManagement/changeProjectName';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import hasUnsavedChangesState from 'Common/recoil/hasUnsavedChangesState';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import withName from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/withName';

const ProjectName = () => {
    const [{ id: projectId, name: initialName }, setProject] = useRecoilState(
        projectAtom,
    );

    const [, setHasUnsavedChanges] = useRecoilState(hasUnsavedChangesState);

    const [isTouched, setIsTouched] = useState(false);
    const [name, setName] = useState(initialName ?? '');

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const debouncedSave = useCallback(
        debounce(
            async value => {
                try {
                    await changeProjectName(projectId, value, source.token);

                    setProject(withName(value));

                    setHasUnsavedChanges(false);
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        notify(
                            'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                            {
                                type: toast.TYPE.ERROR,
                            },
                        );
                    }
                }
            },
            350,
            {
                leading: true,
                trailing: true,
            },
        ),
        [projectId, setProject, setHasUnsavedChanges, source.token],
    );

    const handleChange = e => {
        setIsTouched(true);
        setHasUnsavedChanges(true);
        setName(e.target.value);

        if (projectId) {
            debouncedSave(e.target.value);
        }
    };

    return (
        <Input
            id="name"
            name="name"
            label="Projektbezeichnung"
            value={name}
            onChange={handleChange}
            disabled={!projectId}
            touched={isTouched}
            error={name === '' ? 'Pflichtangabe' : undefined}
            isRequired
        />
    );
};

export default ProjectName;
