export const presentationStatuses = {
    NOT_STARTED: 'NOT_STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
};

export const presentationStatusOptions = [
    {
        value: presentationStatuses.NOT_STARTED,
        label: 'Offen',
    },
    {
        value: presentationStatuses.IN_PROGRESS,
        label: 'In Bearbeitung',
    },
    {
        value: presentationStatuses.COMPLETED,
        label: 'Abgeschlossen',
    },
];
