import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { debounce } from 'lodash';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import hasUnsavedChangesState from 'Common/recoil/hasUnsavedChangesState';
import Input from 'Common/components/Form/Fields/Input';
import useGiveTextAnswerFunction from 'ProjectManager/DataManagement/Questionnaire/components/Question/Answers/hooks/giveTextAnswer/useGiveTextAnswerFunction';

const Wrapper = styled.div`
    width: 100%;
    max-width: 520px;
    margin-bottom: 10px;
`;

const Text = ({ question }) => {
    const giveTextAnswer = useGiveTextAnswerFunction(
        question.id,
        question.isCustom,
    );

    const [, setHasUnsavedChanges] = useRecoilState(hasUnsavedChangesState);

    const [answer, setAnswer] = useState(question.textAnswer);

    const debouncedSave = useCallback(
        debounce(
            async textAnswer => {
                try {
                    await giveTextAnswer(textAnswer);
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        notify(
                            'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                            {
                                type: toast.TYPE.ERROR,
                            },
                        );
                    }
                }

                setHasUnsavedChanges(false);
            },
            350,
            {
                leading: true,
                trailing: true,
            },
        ),
        [giveTextAnswer, setHasUnsavedChanges],
    );

    const handleChange = e => {
        setHasUnsavedChanges(true);
        setAnswer(e.target.value);
        debouncedSave(e.target.value);
    };

    return (
        <Wrapper>
            <Input
                placeholder="Bitte Antwort einfügen..."
                value={answer}
                onChange={handleChange}
            />
        </Wrapper>
    );
};

Text.propTypes = {
    question: PropTypes.object.isRequired,
};

export default Text;
