import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Filter from 'Common/components/Filters/Filter/Filter';
import {
    CheckBoxOutlineBlankRounded,
    CheckBoxRounded,
} from '@material-ui/icons';
import ContentLoader from 'react-content-loader';
import colors from 'Common/constants/colors';
import { fill, random } from 'lodash';

const Options = styled.div``;

const Option = styled.button`
    display: flex;
    margin-bottom: 10px;
    background: none;
    border: 0 none;
    outline: 0 none;
    text-align: left;
    cursor: pointer;

    &[disabled] {
        cursor: default;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

const Label = styled.div`
    position: relative;
    top: 3px;
    margin-left: 5px;
    font-size: 14px;
    line-height: 18px;
`;

const DataLoader = React.memo(() => (
    <ContentLoader
        width="100%"
        height={15}
        backgroundColor={colors.DIRTY_WHITE}
        foregroundColor={colors.LIGHT_DIVIDER}
    >
        <rect x="0" y="0" rx="6" ry="6" width="100%" height="15" />
    </ContentLoader>
));

const MultiCheckboxFilter = ({
    options,
    checkedValues,
    onToggle,
    isLoading,
    ...props
}) => (
    <Filter {...props} selectedCount={checkedValues.length}>
        <Options>
            {isLoading
                ? fill(Array(3), {}).map((entry, index) => (
                      <Option
                          key={index}
                          style={{ width: `${random(60, 100)}%` }}
                          disabled
                      >
                          <DataLoader />
                      </Option>
                  ))
                : options.map(option => (
                      <Option
                          key={option.value}
                          onClick={() => onToggle(option.value)}
                      >
                          {checkedValues.includes(option.value) ? (
                              <CheckBoxRounded />
                          ) : (
                              <CheckBoxOutlineBlankRounded />
                          )}
                          <Label>{option.label}</Label>
                      </Option>
                  ))}
        </Options>
    </Filter>
);

MultiCheckboxFilter.defaultProps = {
    isLoading: false,
};

MultiCheckboxFilter.propTypes = {
    title: PropTypes.any.isRequired,
    options: PropTypes.array.isRequired,
    checkedValues: PropTypes.array.isRequired,
    onToggle: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
};

export default MultiCheckboxFilter;
