import api from 'Application/api/api';

const changeTemplateSupervisors = (templateId, supervisorUserIds, cancelToken = null) =>
    api.post(
        `project-manager/templates/${templateId}/change-supervisors`,
        {
            supervisorUserIds,
        },
        { cancelToken },
    );

export default changeTemplateSupervisors;
