import api from 'Application/api/api';

const isProjectCustomTopicNameUnique = (
    name,
    projectId,
    editedCustomTopicId = null,
    cancelToken = null,
) =>
    api.get(
        `project-manager/projects/${projectId}/custom-topics/is-name-unique/${name}`,
        {
            params: {
                editedCustomTopicId,
            },
            cancelToken,
        },
    );

export default isProjectCustomTopicNameUnique;
