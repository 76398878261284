import { atom } from 'recoil';
import defaultSupervisorUserOption
    from 'ProjectManager/Project/Introductions/Create/recoil/supervisorUserOption/defaultSupervisorUserOption';

const supervisorUserOptionAtom = atom({
    key: 'projectManagement_introduction_create_supervisorUserOption',
    default: defaultSupervisorUserOption,
});

export default supervisorUserOptionAtom;
