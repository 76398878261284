import React from 'react';
import { useSelector } from 'react-redux';
import roles from 'User/constants/roles';
import ManagerDashboard from 'Dashboard/components/ManagerDashboard';
import EmployeeDashboard from 'Dashboard/components/EmployeeDashboard';

const Dashboard = () => {
    const role = useSelector(state => state.user.role);

    if (role === roles.MANAGER) {
        return <ManagerDashboard />;
    }

    if (role === roles.EMPLOYEE) {
        return <EmployeeDashboard />;
    }

    return null;
};

export default Dashboard;
