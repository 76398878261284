import api from 'Application/api/api';

const assignEmployeesToTemplate = (templateId, employeeIds, cancelToken = null) =>
    api.post(
        `project-manager/templates/${templateId}/employees/assign`,
        {
            employeeIds,
        },
        {
            cancelToken,
        },
    );

export default assignEmployeesToTemplate;
