import React from 'react';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { Link } from 'react-router-dom';
import createNewDate from 'Common/utils/createNewDate';
import getYear from 'date-fns/getYear';

const Wrapper = styled.footer`
    width: 100%;
    max-width: 1440px;
    margin: 30px auto 0;
    padding: 0 10px;
    text-align: center;
    z-index: 10;

    @media screen and (min-width: 500px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media screen and (min-width: 940px) {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        max-width: initial;
        margin: 0;
        padding: 0;
        background: ${colors.PAGE_BACKGROUND};
    }
`;

const Inner = styled.div`
    @media screen and (min-width: 940px) {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1440px;
        height: 30px;
        padding-left: 20px;
        padding-right: 20px;
    }

    @media screen and (min-width: 980px) {
        padding-left: 40px;
        padding-right: 40px;
    }
`;

const Copyright = styled.div`
    margin-bottom: 12px;
    color: ${colors.GRAY};
    font-size: 11px;

    @media screen and (min-width: 940px) {
        margin-bottom: 0;
    }
`;

const Links = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledLink = styled(Link)`
    color: ${colors.GRAY};
    font-size: 12px;
    text-decoration: none;

    &:hover {
        color: ${colors.PRIMARY};
    }

    @media screen and (min-width: 940px) {
        font-size: 11px;
    }
`;

const Separator = styled.span`
    margin-left: 8px;
    margin-right: 8px;
    color: ${colors.GRAY};
    font-size: 12px;

    @media screen and (min-width: 940px) {
        font-size: 11px;
    }
`;

const Footer = () => {
    const year = getYear(createNewDate());

    return (
        <Wrapper>
            <Inner>
                <Copyright>{`${year} © Amedic Gmbh & Co. KG. All Rights Reserved.`}</Copyright>
                <Links>
                    <StyledLink to="/imprint">Impressum</StyledLink>
                    <Separator>|</Separator>
                    <StyledLink to="/terms-and-conditions">AGB</StyledLink>
                    <Separator>|</Separator>
                    <StyledLink to="/privacy-policy">
                        Datenschutzerklärung
                    </StyledLink>
                </Links>
            </Inner>
        </Wrapper>
    );
};

export default Footer;
