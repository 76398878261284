import { sumBy } from 'lodash';

const percentages = {
    REQUIREMENTS: {
        percentage: 80,
        label: 'Anforderungserhebung',
    },
    DESIGN: {
        percentage: 85,
        label: 'UI/UX Design',
    },
    BUSINESS_LOGIC: {
        percentage: 100,
        label: 'Grundlegende Geschäftslogik',
    },
    BUSINESS_PROCESSES: {
        percentage: 80,
        label: 'Geschäftsprozesse',
    },
    UI: {
        percentage: 82,
        label: 'Benutzeroberfläche',
    },
    ESCALATION_MANAGEMENT: {
        percentage: 35,
        label: 'Eskalationsmanagement',
    },
};

const averagePercentage =
    sumBy(Object.values(percentages), 'percentage') /
    Object.keys(percentages).length;

export { percentages, averagePercentage };
