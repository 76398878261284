import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ProjectCoordinatorPreview
    from 'ProjectManager/Project/DataManagement/GeneralInformation/ProjectCoordinators/ProjectCoordinatorTile/ProjectCoordinatorPreview';
import ProjectCoordinatorEdit
    from 'ProjectManager/Project/DataManagement/GeneralInformation/ProjectCoordinators/ProjectCoordinatorTile/ProjectCoordinatorEdit';

const ProjectCoordinatorTile = ({ projectCoordinator }) => {
    const [isInEditMode, setIsInEditMode] = useState(false);

    const handleEditButtonClick = () => {
        setIsInEditMode(true);
    };

    const cancelEditMode = () => {
        setIsInEditMode(false);
    };

    if (isInEditMode) {
        return (
            <ProjectCoordinatorEdit
                projectCoordinator={projectCoordinator}
                onCancel={cancelEditMode}
                onSave={cancelEditMode}
            />
        );
    }

    return (
        <ProjectCoordinatorPreview
            projectCoordinator={projectCoordinator}
            onEditButtonClick={handleEditButtonClick}
        />
    );
};

ProjectCoordinatorTile.propTypes = {
    projectCoordinator: PropTypes.object.isRequired,
};

export default ProjectCoordinatorTile;
