import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'Common/components/Button';
import Modal from 'Common/components/Modals/Modal';
import ModalTitle from 'Common/components/Modals/ModalTitle';

const ConfirmGroupChangeModal = ({ onConfirm, onCancel, ...props }) => {
    const [isConfirming, setIsConfirming] = useState(false);

    const handleConfirm = async () => {
        setIsConfirming(true);

        await onConfirm();

        setIsConfirming(false);
    };

    return (
        <Modal
            onRequestClose={isConfirming ? undefined : onCancel}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onCancel}
                        disabled={isConfirming}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isConfirming}
                        onClick={handleConfirm}
                        disabled={isConfirming}
                    >
                        Wechseln
                    </Button>
                </>
            }
            {...props}
        >
            <ModalTitle>
                Möchtest du die Projektkategorie jetzt wirklich wechseln?
            </ModalTitle>
            <p>
                Bitte beachte, dass deine hinterlegten Einträge der
                Projektkategorie nicht gespeichert werden.
            </p>
        </Modal>
    );
};

ConfirmGroupChangeModal.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default ConfirmGroupChangeModal;
