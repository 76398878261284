import api from 'Application/api/api';

const deselectIntroductionPPE = (
    projectId,
    introductionId,
    ppe,
    cancelToken = null,
) =>
    api.post(
        `project-manager/projects/${projectId}/introductions/${introductionId}/deselect-ppe`,
        {
            ppe,
        },
        { cancelToken },
    );

export default deselectIntroductionPPE;
