import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';

const DownloadAllFilesModal = ({ onClose, ...props }) => {
    const currentUserEmail = useSelector(state => state.user.email);

    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);
    const isProject = dataManagementEntity === dataManagementEntities.PROJECT;

    return (
        <Modal
            isOpen
            onRequestClose={onClose}
            buttons={
                <Button type="button" onClick={onClose}>
                    Weiter
                </Button>
            }
            {...props}
        >
            <ModalTitle>Angeforderte Fragedateien-Anhänge</ModalTitle>
            <p>
                Die Fragedateien für
                {` ${isProject ? 'das Projekt' : 'die Projektvorlage'} `}werden
                vorbereitet. Du erhältst in kürze eine E-Mail (
                {`E-Mail: ${currentUserEmail}`}), in welcher du ein Link findest
                mit Hilfe dessen du die angeforderten Projektunterlagen
                herunterladen kannst. Bitte beachte das der Link 48 Stunden
                gültig ist.
            </p>
        </Modal>
    );
};

DownloadAllFilesModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default DownloadAllFilesModal;
