import React, { useCallback, useEffect, useState } from 'react';
import { presentationStatuses } from 'Education/constants/presentationStatuses';
import Loader from 'Common/components/Loader';
import { examStatuses } from 'Education/constants/examStatuses';
import startNewInstructionAssignmentExamAttempt from 'Education/api/instruction-assignment/exam/startNewInstructionAssignmentExamAttempt';
import resumeInstructionAssignmentExam from 'Education/api/instruction-assignment/exam/resumeInstructionAssignmentExam';
import AssignmentExamInProgress from 'Education/components/instruction-assignment/exam/AssignmentExamInProgress';
import FailedExamScreen from 'Education/components/instruction-assignment/exam/FailedExamScreen';
import CompletedExamScreen from 'Education/components/instruction-assignment/exam/CompletedExamScreen';
import GroupAssignmentExamApprovalScreen from 'Education/components/instruction-assignment/exam/GroupAssignmentExamApprovalScreen';
import AssignmentPresentationInProgress from 'Education/components/instruction-assignment/presentation/AssignmentPresentationInProgress';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import { Helmet } from 'react-helmet-async';
import { Redirect, useParams } from 'react-router';
import getInstructionAssignment from 'Education/api/instruction-assignment/getInstructionAssignment';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';
import withRestrictionCheckDuringArchivingProcess from 'Education/components/common/withRestrictionCheckDuringArchivingProcess';
import BackButton from 'Application/components/Header/BackButton/BackButton';
import Button from 'Common/components/Button';
import OuterBoxButtons from 'Common/components/Boxes/OuterBoxButtons';

const AssignmentExam = () => {
    const [isShowingMistakes, setIsShowingMistakes] = useState(false);
    const [isShowingPresentation, setIsShowingPresentation] = useState(false);

    const { id: assignmentId } = useParams();

    const assignmentRequest = useCallback(
        cancelToken =>
            getInstructionAssignment(
                assignmentId,
                [
                    'responsibleEmployee',
                    'instruction',
                    'instruction.slides',
                    'examQuestions',
                    'groupAssignmentDetails',
                ],
                cancelToken,
            ),
        [assignmentId],
    );

    const {
        data: assignment,
        loadData: loadAssignment,
        loadDataWithoutLoader: loadAssignmentWithoutLoader,
        isLoading: isLoadingAssignment,
        hasError,
    } = useAxiosRequest(assignmentRequest, {});

    const presentationStatus = assignment?.presentationStatus;
    const examStatus = assignment?.examStatus;

    useEffect(() => {
        (async () => {
            if (
                presentationStatus === presentationStatuses.COMPLETED &&
                examStatus === examStatuses.NOT_STARTED
            ) {
                await startNewInstructionAssignmentExamAttempt(assignmentId);
                await loadAssignmentWithoutLoader();
            } else if (examStatus === examStatuses.IN_PROGRESS) {
                await resumeInstructionAssignmentExam(assignmentId);
                await loadAssignmentWithoutLoader();
            }
        })();
    }, [
        assignmentId,
        presentationStatus,
        examStatus,
        loadAssignmentWithoutLoader,
    ]);

    const handleShowExamMistakes = () => {
        setIsShowingMistakes(true);
    };

    const handleNavigateBackFromShowingMistakes = () => {
        setIsShowingMistakes(false);
    };

    const handleShowPresentation = () => {
        setIsShowingPresentation(true);
    };

    const handleNavigateBackFromShowingPresentation = () => {
        setIsShowingPresentation(false);
    };

    const handleCompleteExam = async () => {
        await loadAssignmentWithoutLoader();
        setIsShowingMistakes(false);
    };

    const handleExamRetake = async () => {
        await startNewInstructionAssignmentExamAttempt(assignmentId);
        await loadAssignmentWithoutLoader();
    };

    const isLoading =
        isLoadingAssignment ||
        (presentationStatus === presentationStatuses.COMPLETED &&
            examStatus === examStatuses.NOT_STARTED);

    if (isLoading) {
        return <Loader />;
    }

    if (hasError) {
        return <RetryLoading onRetry={loadAssignment} />;
    }

    if (assignment !== null) {
        const breadcrumbs = (
            <>
                <Helmet>
                    <title>{`${assignment.name} - Aufgaben`}</title>
                </Helmet>
                <BackButton to="/dashboard" />
                <Breadcrumb to="/dashboard">Unterweisungen</Breadcrumb>
                <Breadcrumb isActive>Test - {assignment.name}</Breadcrumb>
            </>
        );

        if (
            assignment.examStatus === examStatuses.IN_PROGRESS ||
            isShowingMistakes
        ) {
            return (
                <>
                    {breadcrumbs}
                    <AssignmentExamInProgress
                        assignment={assignment}
                        isShowingMistakes={isShowingMistakes}
                        initialQuestionNumber={
                            isShowingMistakes
                                ? 1
                                : assignment.currentExamQuestion
                        }
                        initialSelectedAnswerIds={assignment.givenAnswerIds}
                        onProgress={loadAssignmentWithoutLoader}
                        onComplete={handleCompleteExam}
                        additionalButtons={
                            isShowingMistakes && (
                                <Button
                                    type="button"
                                    onClick={
                                        handleNavigateBackFromShowingMistakes
                                    }
                                >
                                    Fortsetzen
                                </Button>
                            )
                        }
                    />
                </>
            );
        }

        if (isShowingPresentation) {
            return (
                <>
                    {breadcrumbs}
                    <AssignmentPresentationInProgress
                        assignment={assignment}
                        initialSlideNumber={1}
                        isInPreviewMode={isShowingPresentation}
                        onProgress={loadAssignmentWithoutLoader}
                        onComplete={handleNavigateBackFromShowingPresentation}
                    />
                    <OuterBoxButtons>
                        <Button
                            type="button"
                            onClick={handleNavigateBackFromShowingPresentation}
                        >
                            Fortsetzen
                        </Button>
                    </OuterBoxButtons>
                </>
            );
        }

        if (
            assignment.examStatus === examStatuses.FAILED &&
            !isShowingMistakes &&
            !isShowingPresentation
        ) {
            return (
                <>
                    {breadcrumbs}
                    <FailedExamScreen
                        attemptsLeft={assignment.examAttemptsLeft}
                        onShowExamMistakes={handleShowExamMistakes}
                        onShowPresentation={handleShowPresentation}
                        onRetakeExam={handleExamRetake}
                    />
                </>
            );
        }

        if (assignment.examStatus === examStatuses.IN_APPROVAL) {
            return (
                <>
                    {breadcrumbs}
                    <GroupAssignmentExamApprovalScreen
                        assignment={assignment}
                        onComplete={loadAssignmentWithoutLoader}
                    />
                </>
            );
        }

        if (
            assignment.examStatus === examStatuses.COMPLETED &&
            !isShowingMistakes &&
            !isShowingPresentation
        ) {
            return (
                <>
                    {breadcrumbs}
                    <CompletedExamScreen
                        assignment={assignment}
                        onShowExamMistakes={handleShowExamMistakes}
                        onSignaturesUpdates={loadAssignmentWithoutLoader}
                    />
                </>
            );
        }
    }

    return <Redirect to="/dashboard" />;
};

export default withRestrictionCheckDuringArchivingProcess(AssignmentExam);
