import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useDropzone } from 'react-dropzone';
import Loader from 'Common/components/Loader';
import uploadInstructionPresentationSlideFiles from 'Education/api/instruction/uploadInstructionPresentationSlideFiles';
import axios from 'axios';
import InteractableInnerBox from 'Common/components/Boxes/InteractableInnerBox';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';
import { CloudUploadRounded } from '@material-ui/icons';

const Wrapper = styled(InteractableInnerBox)`
    padding: 17px 20px;
    color: ${colors.DIRTY_WHITE};
    word-break: break-word;

    p {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    button {
        margin-top: 10px;
    }
`;

const UploadingProcessWrapper = styled(Wrapper)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const UploadWrapper = styled(Wrapper)`
    cursor: pointer;
`;

const Note = styled.div`
    margin-top: 10px;
    color: ${colors.LIGHT_GRAY};
    font-size: 13px;
`;

const ImportSlides = ({ instructionId, onImport }) => {
    const [isImporting, setIsImporting] = useState(false);
    const [isImported, setIsImported] = useState(false);
    const [hasFailed, setHasFailed] = useState(false);

    const reset = () => {
        setIsImporting(false);
        setIsImported(false);
        setHasFailed(false);
    };

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleDropZoneDrop = useCallback(
        acceptedFiles => {
            (async () => {
                if (acceptedFiles && acceptedFiles.length > 0) {
                    setIsImporting(true);

                    try {
                        await uploadInstructionPresentationSlideFiles(
                            instructionId,
                            acceptedFiles,
                            source.token,
                        );

                        setIsImported(true);
                    } catch (error) {
                        setHasFailed(true);
                    }
                } else {
                    setHasFailed(true);
                }

                setIsImporting(false);
            })();
        },
        [instructionId, source.token],
    );

    const {
        acceptedFiles,
        rejectedFiles,
        getRootProps,
        getInputProps,
        isDragActive,
    } = useDropzone({
        onDrop: handleDropZoneDrop,
        accept:
            'application/zip, application/x-zip-compressed, image/jpg, image/jpeg, image/png, video/mp4',
    });

    useEffect(() => {
        if (isImported) {
            const acceptedFilesCount = acceptedFiles.length;
            const allFilesCount = rejectedFiles.length + acceptedFilesCount;

            const areAllFilesImported = acceptedFilesCount === allFilesCount;
            const areNoFilesImported = acceptedFilesCount === 0;

            let message;

            if (areAllFilesImported) {
                message = 'Alle Dateien wurden erfolgreich importiert';
            } else if (areNoFilesImported) {
                message =
                    'Es wurden keine Dateien importiert (ungültiges Dateiformat)';
            } else {
                message = `${acceptedFilesCount} von ${allFilesCount} Dateien wurden erfolgreich importiert`;
            }

            onImport(message);
        }
    }, [isImported, acceptedFiles.length, rejectedFiles.length, onImport]);

    if (isImporting) {
        return (
            <UploadingProcessWrapper>
                <Loader size={57} />
                <p>Dateien importieren</p>
                <p>Warten Sie mal...</p>
            </UploadingProcessWrapper>
        );
    }

    if (isImported) {
        return (
            <Wrapper>
                <p>Die Dateien wurden erfolgreich importiert</p>
                <Button
                    underline
                    type="button"
                    icon={<CloudUploadRounded />}
                    onClick={reset}
                >
                    Andere Dateien importieren
                </Button>
            </Wrapper>
        );
    }

    if (hasFailed) {
        return (
            <Wrapper>
                <p>Beim Import ist ein Fehler aufgetreten</p>
                <Button
                    underline
                    type="button"
                    icon={<CloudUploadRounded />}
                    onClick={reset}
                >
                    Versuch es noch einmal
                </Button>
            </Wrapper>
        );
    }

    return (
        <UploadWrapper {...getRootProps()}>
            <input {...getInputProps()} />

            {isDragActive ? (
                <p>Legen Sie die Dateien hier ab...</p>
            ) : (
                <>
                    <div style={{ width: '100%' }}>
                        Archive: <kbd>.zip</kbd>
                        <br />
                        Folien: <kbd>.jpg</kbd> <kbd>.jpeg</kbd> <kbd>.png</kbd>{' '}
                        <kbd>.mp4</kbd>
                        <br />
                        <Note>
                            Die Folien werden automatisch erstellt und die Seite
                            wird neu geladen (alle Änderungen gehen verloren).
                        </Note>
                    </div>
                    <Button
                        underline
                        type="button"
                        icon={<CloudUploadRounded />}
                    >
                        Durchsuche
                    </Button>
                </>
            )}
        </UploadWrapper>
    );
};

ImportSlides.propTypes = {
    instructionId: PropTypes.number.isRequired,
    onImport: PropTypes.func.isRequired,
};

export default ImportSlides;
