import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import createInstructionArea from 'Education/api/instruction-area/createInstructionArea';
import FormField from 'Common/components/Form/FormField';
import Input from 'Common/components/Form/Fields/Input';
import Textarea from 'Common/components/Form/Fields/Textarea';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Button from 'Common/components/Button';
import Modal from 'Common/components/Modals/Modal';
import ModalTitle from 'Common/components/Modals/ModalTitle';

const Form = styled.form``;

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Pflichtangabe'),
    description: Yup.string(),
});

const InstructionAreaCreateModal = ({ isOpen, onCancel, onSuccess }) => {
    const [isCreating, setIsCreating] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const {
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
    } = useFormik({
        initialValues: {
            name: '',
            description: '',
        },
        validationSchema,
        onSubmit: async values => {
            setIsCreating(true);

            try {
                const response = await createInstructionArea(
                    {
                        name: values.name,
                        description: values.description,
                    },
                    source.token,
                );

                await onSuccess(response.data);

                setIsCreating(false);

                notify('Der Bereich wurde erfolgreich erstellt', {
                    type: toast.TYPE.SUCCESS,
                });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setIsCreating(false);

                    notify('Der Bereich wurde nicht erfolgreich erstellt', {
                        type: toast.TYPE.ERROR,
                    });
                }
            }
        },
    });

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={isCreating ? undefined : onCancel}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onCancel}
                        disabled={isCreating}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isCreating}
                        onClick={handleSubmit}
                        disabled={isCreating || !isValid}
                    >
                        Erstellen
                    </Button>
                </>
            }
        >
            <ModalTitle>Bereich erstellen</ModalTitle>
            <Form onSubmit={handleSubmit}>
                <FormField>
                    <Input
                        label="Bereich"
                        id="name"
                        name="name"
                        error={errors.name}
                        touched={touched.name}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>
                <FormField>
                    <Textarea
                        label="Beschreibung"
                        id="description"
                        name="description"
                        error={errors.description}
                        touched={touched.description}
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>
            </Form>
        </Modal>
    );
};

InstructionAreaCreateModal.propTypes = {
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default InstructionAreaCreateModal;
