import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import qualificationStatuses from 'ProjectManager/DataManagement/EmployeeSelection/constants/qualificationStatuses';
import { CheckRounded, WarningRounded } from '@material-ui/icons';
import { ifProp } from 'styled-tools';

const Wrapper = styled.div`
    display: inline-flex;
    align-items: center;

    svg {
        font-size: 18px;
    }
`;

const QualificationLabel = styled.span`
    margin-left: 3px;
    color: ${ifProp('dark', colors.DARK_GRAY, colors.LIGHTER_GRAY)};
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
`;

const QualifiedIcon = styled(CheckRounded)`
    color: ${colors.GREEN};
`;

const PendingTasksIcon = styled(WarningRounded)`
    position: relative;
    top: -1px;
    color: ${colors.WARNING};
`;

const Qualification = ({ qualification, dark }) => (
    <Wrapper>
        {qualification.status === qualificationStatuses.QUALIFIED && (
            <QualifiedIcon />
        )}

        {qualification.status === qualificationStatuses.HAS_PENDING_TASKS && (
            <PendingTasksIcon />
        )}

        <QualificationLabel dark={dark}>
            {qualification.label}
        </QualificationLabel>
    </Wrapper>
);

Qualification.defaultProps = {
    dark: false,
};

Qualification.propTypes = {
    qualification: PropTypes.object.isRequired,
    dark: PropTypes.bool,
};

export default Qualification;
