import api from 'Application/api/api';

const createTemplateCustomSubsection = (
    templateId,
    sectionOrCustomTopicId,
    isCustomTopic,
    data,
    cancelToken = null,
) =>
    api.post(
        `project-manager/templates/${templateId}/questionnaire/sections/${sectionOrCustomTopicId}/${
            isCustomTopic ? 1 : 0
        }/custom-subsections`,
        data,
        { cancelToken },
    );

export default createTemplateCustomSubsection;
