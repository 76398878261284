import api from 'Application/api/api';

const getInstruction = (id, load, cancelToken = null) =>
    api.get(`/education/instructions/${id}`, {
        params: {
            load,
        },
        cancelToken,
    });

export default getInstruction;
