import React, { useEffect, useMemo } from 'react';
import {
    DefaultTableBody,
    DefaultTableHead,
    Table,
} from 'Common/components/table';
import PropTypes from 'prop-types';
import { useSortBy, useTable } from 'react-table';
import axios from 'axios';
import { useClickableTableRow } from 'Common/hooks/useClickableTableRow';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useCustomCellStyles } from 'Common/hooks/useCustomCellStyles';
import styled from 'styled-components/macro';
import { orderBy } from 'lodash';
import stableTableSort from 'Common/utils/stableTableSort';

const StyledTableHead = styled(DefaultTableHead)`
    th {
        top: 80px;
    }
`;

const EmployeesTable = ({ employees }) => {
    const dispatch = useDispatch();

    const columns = useMemo(
        () => [
            {
                Header: 'Mitarbeiter',
                accessor: 'name',
                customCellStyles: {
                    minWidth: 220,
                    paddingTop: 20,
                    paddingBottom: 20,
                },
            },
        ],
        [],
    );

    const initialSortBy = useMemo(
        () => [
            {
                id: 'name',
                desc: false,
            },
        ],
        [],
    );

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const data = useMemo(
        () =>
            orderBy(
                employees.map(employee => ({
                    name: `${employee.lastName}, ${employee.firstName}`,
                    targetLink: `/archive/employee/${employee.id}`,
                })),
                ['name'],
                ['asc'],
            ),
        [employees],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            onRowClick: row => {
                dispatch(push(row.original.targetLink));
            },
            disableSortRemove: true,
            disableMultiSort: true,
            initialState: {
                sortBy: initialSortBy,
            },
            orderByFn: stableTableSort,
        },
        useCustomCellStyles,
        useClickableTableRow,
        useSortBy,
    );

    return (
        <Table {...getTableProps()}>
            <StyledTableHead headerGroups={headerGroups} />
            <DefaultTableBody
                rows={rows}
                getTableBodyProps={getTableBodyProps}
                prepareRow={prepareRow}
            />
        </Table>
    );
};

EmployeesTable.propTypes = {
    employees: PropTypes.array.isRequired,
};

export default EmployeesTable;
