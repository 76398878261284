import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import MoreActionsButton from 'Common/components/MoreActionsButton/MoreActionsButton';
import MenuItem from 'Common/components/MoreActionsButton/MenuItem';
import {
    ArrowDownwardRounded,
    ArrowUpwardRounded,
    DeleteRounded,
    EditRounded,
} from '@material-ui/icons';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import moveTemplateGroupUp from 'ProjectManager/TemplateGroup/api/moveTemplateGroupUp';
import moveTemplateGroupDown from 'ProjectManager/TemplateGroup/api/moveTemplateGroupDown';
import EditModal from 'ProjectManager/Template/List/components/Overview/Group/EditModal';
import { useRecoilState } from 'recoil';
import withGroupMovedUp from 'ProjectManager/Template/List/recoil/groups/modifiers/withGroupMovedUp';
import withGroupMovedDown from 'ProjectManager/Template/List/recoil/groups/modifiers/withGroupMovedDown';
import PropTypes from 'prop-types';
import DeleteModal from 'ProjectManager/Template/List/components/Overview/Group/DeleteModal/components/DeleteModal';
import groupsAtom from 'ProjectManager/Template/List/recoil/groups/groupsAtom';
import Loader from 'Common/components/Loader';

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
`;

const Title = styled.div`
    margin-right: 7px;
    font-size: 18px;
    line-height: 27px;
    overflow-wrap: anywhere;
    word-break: break-word;
`;

const MenuWrapper = styled.div`
    position: relative;
    top: -11px;
`;

const StyledLoader = styled(Loader)`
    height: 48px;
    flex: initial;
    margin-left: 10px;
`;

const GroupHeading = ({ group }) => {
    const [allGroups, setGroups] = useRecoilState(groupsAtom);

    const [isEditModalOpened, setIsEditModalOpened] = useState(false);
    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const handleEditButtonClick = () => {
        setIsEditModalOpened(true);
    };

    const closeEditModal = () => {
        setIsEditModalOpened(false);
    };

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleMoveUpButtonClick = async () => {
        setIsLoading(true);

        try {
            await moveTemplateGroupUp(group.id, source.token);

            setGroups(withGroupMovedUp(group.id));
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }

        setIsLoading(false);
    };

    const handleMoveDownButtonClick = async () => {
        setIsLoading(true);

        try {
            await moveTemplateGroupDown(group.id, source.token);

            setGroups(withGroupMovedDown(group.id));
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }

        setIsLoading(false);
    };

    const handleDeleteButtonClick = () => {
        setIsDeleteModalOpened(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpened(false);
    };

    return (
        <Wrapper>
            <Title>{group.name}</Title>
            <MenuWrapper>
                {isLoading ? (
                    <StyledLoader size={24} />
                ) : (
                    <MoreActionsButton>
                        <MenuItem
                            icon={<EditRounded />}
                            type="button"
                            onClick={handleEditButtonClick}
                        >
                            Bearbeiten
                        </MenuItem>
                        <MenuItem
                            icon={<ArrowUpwardRounded />}
                            type="button"
                            onClick={handleMoveUpButtonClick}
                            disabled={group.position === 1}
                        >
                            Aufwärts verschieben
                        </MenuItem>
                        <MenuItem
                            icon={<ArrowDownwardRounded />}
                            type="button"
                            onClick={handleMoveDownButtonClick}
                            disabled={group.position === allGroups.length}
                        >
                            Abwärts verschieben
                        </MenuItem>
                        <MenuItem
                            icon={<DeleteRounded />}
                            type="button"
                            onClick={handleDeleteButtonClick}
                        >
                            Löschen
                        </MenuItem>
                    </MoreActionsButton>
                )}
            </MenuWrapper>
            {isEditModalOpened && (
                <EditModal group={group} onClose={closeEditModal} />
            )}
            {isDeleteModalOpened && (
                <DeleteModal group={group} onClose={closeDeleteModal} />
            )}
        </Wrapper>
    );
};

GroupHeading.propTypes = {
    group: PropTypes.object.isRequired,
};

export default GroupHeading;
