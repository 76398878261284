import React from 'react';
import styled from 'styled-components/macro';
import { FileCopyRounded } from '@material-ui/icons';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import Files from 'ProjectManager/DataManagement/Files/components/Files';
import projectMediaFilesSearchTermAtom from 'ProjectManager/Project/ProjectMediaFiles/recoil/projectMediaFilesSearchTerm/projectMediaFilesSearchTermAtom';
import { useRecoilValue } from 'recoil';
import Search from 'ProjectManager/Project/ProjectMediaFiles/components/Content/Search';
import Questionnaire from 'ProjectManager/Project/ProjectMediaFiles/components/Content/Questionnaire/Questionnaire';
import GeneralProjectFilesSearchResults from 'ProjectManager/Project/ProjectMediaFiles/components/GeneralProjectFilesSearchResults';

const Title = styled.div`
    display: flex;
    margin-bottom: 23px;
`;

const StyledFileCopyRounded = styled(FileCopyRounded)`
    font-size: 28px !important;
`;

const StyledOuterBoxTitle = styled(OuterBoxTitle)`
    margin-left: 10px;
`;

const SearchWrapper = styled.div`
    margin-bottom: 70px;
    max-width: 335px;
`;

const GeneralFilesWrapper = styled.div`
    margin-bottom: 70px;
`;

const Content = () => {
    const searchTerm = useRecoilValue(projectMediaFilesSearchTermAtom);

    return (
        <>
            <Title>
                <StyledFileCopyRounded />
                <StyledOuterBoxTitle>Media-Dateien</StyledOuterBoxTitle>
            </Title>
            <SearchWrapper>
                <Search />
            </SearchWrapper>
            <GeneralFilesWrapper>
                {searchTerm === '' ? (
                    <Files title="Allgemeine Projektdateien" />
                ) : (
                    <GeneralProjectFilesSearchResults />
                )}
            </GeneralFilesWrapper>
            <Questionnaire />
        </>
    );
};

export default Content;
