import React from 'react';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import ProgressBar from 'Common/components/ProgressBar';

const Wrapper = styled.div`
    padding-top: 20px;
    border-top: 1px solid ${colors.DARK_DIVIDER};
`;

const Title = styled.div`
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
`;

const Progress = () => {
    const { statistics } = useRecoilValue(projectAtom);

    const completedCount =
        statistics.participantsWithCompletedInstructions +
        statistics.participantsWithConfirmedIntroductions +
        statistics.completedSubsections;

    const pendingCount =
        statistics.participantsWithPendingInstructions +
        statistics.participantsWithoutConfirmedIntroductions +
        statistics.incompleteSubsections;

    const allCount = completedCount + pendingCount;

    const progress = allCount > 0 ? (completedCount / allCount) * 100 : 0;

    return (
        <Wrapper>
            <Title>Fortschritt</Title>
            <ProgressBar percentage={progress} color={colors.GREEN} />
        </Wrapper>
    );
};

export default Progress;
