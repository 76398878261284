import getProject from 'ProjectManager/Project/Common/api/getProject';

const getProjectQuestionnaireSections = async (projectId, cancelToken) => {
    try {
        const response = await getProject(
            projectId,
            [
                'questionnaireSections',
                'questionnaireSections.subsections',
                'questionnaireSections.subsections.questions',
                'questionnaireSections.subsections.questions.files',
            ],
            cancelToken,
        );

        return response.data.questionnaireSections;
    } catch (error) {
        return [];
    }
};

export default getProjectQuestionnaireSections;
