import { useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import hasUnsavedChangesState from 'Common/recoil/hasUnsavedChangesState';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import uploadProjectQuestionFiles from 'ProjectManager/Project/Common/api/dataManagement/questionnaire/question/files/uploadProjectQuestionFiles';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import getProjectQuestionFiles from 'ProjectManager/Project/Common/api/dataManagement/questionnaire/question/files/getProjectQuestionFiles';
import withQuestionFiles from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/withQuestionFiles';

const useUploadProjectQuestionFilesFunction = (
    questionId,
    isCustomQuestion,
    onUpload,
) => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const [isUploading, setIsUploading] = useState(false);
    const [, setHasUnsavedChanges] = useRecoilState(hasUnsavedChangesState);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const { open, getInputProps } = useDropzone({
        accept: [
            'application/pdf',
            'application/zip',
            'application/x-zip-compressed',
            'image/jpg',
            'image/jpeg',
            'image/png',
            'image/gif',
            'video/mp4',
            'audio/mpeg',
            'audio/wav',
        ].join(', '),
        onDrop: async (acceptedFiles, fileRejections) => {
            setHasUnsavedChanges(true);
            setIsUploading(true);

            if (acceptedFiles && acceptedFiles.length > 0) {
                try {
                    await uploadProjectQuestionFiles(
                        projectId,
                        questionId,
                        isCustomQuestion,
                        acceptedFiles,
                        source.token,
                    );

                    const filesResponse = await getProjectQuestionFiles(
                        projectId,
                        questionId,
                        isCustomQuestion,
                        source.token,
                    );

                    setProject(
                        withQuestionFiles(
                            questionId,
                            isCustomQuestion,
                            filesResponse.data,
                        ),
                    );

                    if (onUpload) {
                        await onUpload();
                    }

                    if (fileRejections.length) {
                        const acceptedFilesCount = acceptedFiles.length;
                        const allFilesCount =
                            acceptedFilesCount + fileRejections.length;

                        notify('Die Dateien wurden erfolgreich hochgeladen', {
                            type: toast.TYPE.SUCCESS,
                        });

                        notify(
                            `Nur ${acceptedFilesCount} von ${allFilesCount} Dateien wurden erfolgreich hochgeladen`,
                            {
                                type: toast.TYPE.WARNING,
                            },
                        );
                    } else {
                        notify('Alle Dateien wurden erfolgreich hochgeladen', {
                            type: toast.TYPE.SUCCESS,
                        });
                    }
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        notify('Die Dateien konnten nicht hochgeladen werden', {
                            type: toast.TYPE.ERROR,
                        });
                    }
                }
            } else {
                notify(
                    'Es wurden keine Dateien hochgeladen (ungültiges Dateiformat)',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }

            setIsUploading(false);
            setHasUnsavedChanges(false);
        },
    });

    return {
        isUploading,
        open,
        getInputProps,
    };
};

export default useUploadProjectQuestionFilesFunction;
