import api from 'Application/api/api';

const activateDriverLicenseControl = (id, cancelToken = null) =>
    api.post(
        `/measures/vehicles/driver-licenses/controls/${id}/activate`,
        {},
        { cancelToken }
    );

export default activateDriverLicenseControl;
