import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';

const WarningMessage = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    svg {
        position: relative;
        top: -3px;
        margin-right: 5px;
        color: ${colors.WARNING};
    }
`;

export default WarningMessage;
