import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
    DefaultTableBody,
    DefaultTableHead,
    Table,
} from 'Common/components/table';
import { useSortBy, useTable } from 'react-table';
import { useCustomCellStyles } from 'Common/hooks/useCustomCellStyles';
import { useResponsiveTable } from 'Common/hooks/useResponsiveTable';
import measures from 'Measure/constants/measures';
import {
    CheckRounded,
    DescriptionRounded,
    PeopleAltRounded,
    UpdateRounded,
    WarningRounded,
} from '@material-ui/icons';
import isAuthorized from 'User/utils/isAuthorized';
import roles from 'User/constants/roles';
import MenuItem from 'Common/components/MoreActionsButton/MenuItem';
import MoreActionsButton from 'Common/components/MoreActionsButton/MoreActionsButton';
import Button from 'Common/components/Button';
import { Tooltip } from '@material-ui/core';
import colors from 'Common/constants/colors';
import formatDate from 'Common/utils/formatDate';
import parseDate from 'Common/utils/parseDate';
import stableTableSort from 'Common/utils/stableTableSort';
import { orderBy } from 'lodash';

const ExaminerIcon = styled(PeopleAltRounded)`
    color: ${colors.LIGHTER_GRAY};
`;

const PendingNotInitiatedIcon = styled(UpdateRounded)`
    color: ${colors.LIGHTER_GRAY};
`;

const PendingInProgressIcon = styled(UpdateRounded)`
    color: ${colors.WARNING};
`;

const CompletedIcon = styled(CheckRounded)`
    color: ${colors.GREEN};
`;

const OverdueIcon = styled(WarningRounded)`
    color: ${colors.WARNING};
`;

const StyledTableHead = styled(DefaultTableHead)`
    th {
        top: 80px;

        @media screen and (min-width: 940px) {
            top: 134px;
        }
    }
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    > * {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
`;

const MeasureTasksTable = ({ measureTasks, employeeId }) => {
    const columns = useMemo(
        () => [
            {
                Header: 'Aufgabe',
                accessor: 'name',
            },
            {
                Header: 'Recht',
                accessor: 'examiner',
                disableSortBy: true,
            },
            {
                Header: 'Absolvieren bis',
                accessor: 'dueDate',
                customCellStyles: {
                    minWidth: 150,
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                disableSortBy: true,
            },
            {
                Header: 'Aktion',
                accessor: 'actions',
                disableSortBy: true,
                customCellStyles: {
                    textAlign: 'center',
                },
            },
        ],
        [],
    );

    const data = useMemo(
        () =>
            orderBy(
                measureTasks.map(task => {
                    let targetLink;
                    let externalTargetLink;

                    // eslint-disable-next-line default-case
                    switch (task.measure.value) {
                        case measures.DRIVER_LICENSE:
                            if (task.isExaminer) {
                                targetLink =
                                    '/measures/vehicles/driver-licenses';
                            } else {
                                if (employeeId !== null) {
                                    targetLink = `/measures/vehicles/driver-license/overview/${task.entity?.id}`;
                                } else {
                                    targetLink =
                                        '/measures/vehicles/driver-license/overview';
                                }
                            }
                            break;

                        case measures.VEHICLE_INSPECTION:
                        case measures.VEHICLE_MANAGEMENT:
                        case measures.UVV_TUV:
                            externalTargetLink =
                                '/backend/m/reminder/content?t=car';
                            break;

                        case measures.LADDER:
                        case measures.DGUV_REGULATION_3:
                        case measures.ELECTRIC_GATE:
                            externalTargetLink =
                                '/backend/m/reminder/content?t=ladder';
                            break;

                        case measures.PERSONAL_PROTECTIVE_EQUIPMENT:
                        case measures.PPE_FALL_EQUIPMENT:
                            externalTargetLink =
                                '/backend/m/reminder/content?t=psa';
                            break;

                        case measures.FIRST_AID:
                            externalTargetLink =
                                '/backend/m/reminder/content?t=aid';
                            break;

                        case measures.FIRE_EXTINGUISHER:
                        case measures.FIRE_PROTECTION_ASSISTANT:
                            externalTargetLink =
                                '/backend/m/reminder/content?t=fire';
                            break;

                        case measures.EC_SAFETY_DATA_SHEETS:
                            externalTargetLink =
                                '/backend/m/reminder/content?t=eg';
                            break;

                        case measures.REQUIREMENT_TO_CARRY_PERSONAL_ID:
                        case measures.TRANSMISSION_OF_ENTREPRENEURIAL_RESPONSIBILITIES:
                            externalTargetLink =
                                '/backend/m/reminder/content?t=other';
                            break;
                    }

                    let StatusIcon;
                    let statusTitle;

                    if (task.status === 'PENDING_NOT_INITIATED') {
                        StatusIcon = PendingNotInitiatedIcon;
                        statusTitle = 'Ausstehend (nicht initiiert)';
                    }
                    if (task.status === 'PENDING_IN_PROGRESS') {
                        StatusIcon = PendingInProgressIcon;
                        statusTitle = 'Ausstehend (in Bearbeitung)';
                    }

                    if (task.status === 'COMPLETED') {
                        StatusIcon = CompletedIcon;
                        statusTitle = 'Abgeschlossen';
                    }

                    if (task.status === 'OVERDUE') {
                        StatusIcon = OverdueIcon;
                        statusTitle = 'Überfällig';
                    }

                    const driverLicenseProofDocumentLink = isAuthorized([
                        roles.EMPLOYEE,
                    ])
                        ? '/api/measures/vehicles/driver-licenses/controls/download-proof-document'
                        : `/api/measures/vehicles/driver-licenses/controls/${task.entity?.id}/download-proof-document`;

                    const isActionsButtonEnabled =
                        task.measure.value === measures.DRIVER_LICENSE &&
                        !task.isExaminer &&
                        !task.entity.driverLicense?.isInCreationProcess;

                    return {
                        measure: task.measure.value,
                        name: task.measure.name,
                        examiner: task.isExaminer ? (
                            <Tooltip title="Prüfer">
                                <ExaminerIcon />
                            </Tooltip>
                        ) : (
                            ''
                        ),
                        dueDate: task.dueDate
                            ? formatDate(parseDate(task.dueDate))
                            : '-',
                        status: StatusIcon ? (
                            <Tooltip title={statusTitle}>
                                <StatusIcon />
                            </Tooltip>
                        ) : (
                            '-'
                        ),
                        actions: (
                            <Actions>
                                {targetLink && (
                                    <Button small to={targetLink}>
                                        Zur Aufgabe
                                    </Button>
                                )}
                                {externalTargetLink && (
                                    <Button small href={externalTargetLink}>
                                        Zur Aufgabe
                                    </Button>
                                )}
                                <MoreActionsButton
                                    disabled={!isActionsButtonEnabled}
                                >
                                    <MenuItem
                                        icon={<DescriptionRounded />}
                                        href={driverLicenseProofDocumentLink}
                                        target="_blank"
                                    >
                                        Dokument anzeigen
                                    </MenuItem>
                                </MoreActionsButton>
                            </Actions>
                        ),
                    };
                }),
                ['name'],
                ['asc'],
            ),
        [employeeId, measureTasks],
    );

    const initialSortBy = useMemo(
        () => [
            {
                id: 'name',
                desc: false,
            },
        ],
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { hiddenColumns },
    } = useTable(
        {
            columns,
            data,
            hideColumnsPriority: ['dueDate', 'examiner', 'status', 'actions'],
            disableSortRemove: true,
            disableMultiSort: true,
            initialState: {
                sortBy: initialSortBy,
            },
            orderByFn: stableTableSort,
        },
        useCustomCellStyles,
        useResponsiveTable,
        useSortBy,
    );

    return (
        <Table {...getTableProps()}>
            <StyledTableHead headerGroups={headerGroups} />
            <DefaultTableBody
                rows={rows}
                getTableBodyProps={getTableBodyProps}
                prepareRow={prepareRow}
                visibleColumns={visibleColumns}
                hiddenColumns={hiddenColumns}
            />
        </Table>
    );
};

MeasureTasksTable.defaultProps = {
    employeeId: null,
};

MeasureTasksTable.propTypes = {
    measureTasks: PropTypes.array.isRequired,
    employeeId: PropTypes.number,
};

export default MeasureTasksTable;
