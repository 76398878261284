import { useRecoilValue } from 'recoil';
import isProjectTopicManagementStepCompletedSelector from 'ProjectManager/Project/Common/recoil/project/selectors/isProjectTopicManagementStepCompletedSelector';

const useIsProjectTopicManagementStepCompleted = () => {
    // noinspection UnnecessaryLocalVariableJS
    const isProjectTopicManagementStepCompleted = useRecoilValue(
        isProjectTopicManagementStepCompletedSelector,
    );

    return isProjectTopicManagementStepCompleted;
};

export default useIsProjectTopicManagementStepCompleted;
