import React from 'react';
import PropTypes from 'prop-types';
import Button from 'Common/components/Button';
import { SpeakerNotesRounded } from '@material-ui/icons';
import useAddQuestionCommentData from 'ProjectManager/DataManagement/Questionnaire/components/Question/Comment/hooks/addQuestionComment/useAddQuestionCommentData';

const AddCommentButton = ({ question, disabled }) => {
    const { addComment, isLoading } = useAddQuestionCommentData(
        question.id,
        question.isCustom,
    );

    return (
        <Button
            underline
            icon={<SpeakerNotesRounded />}
            type="button"
            isLoading={isLoading}
            disabled={disabled || isLoading || question.comment !== null}
            onClick={addComment}
        >
            Kommentar
        </Button>
    );
};

AddCommentButton.defaultProps = {
    disabled: false,
};

AddCommentButton.propTypes = {
    question: PropTypes.object.isRequired,
};

export default AddCommentButton;
