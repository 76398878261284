import api from 'Application/api/api';

const deactivateDriverLicenseControl = (id, cancelToken = null) =>
    api.post(
        `/measures/vehicles/driver-licenses/controls/${id}/deactivate`,
        {},
        { cancelToken }
    );

export default deactivateDriverLicenseControl;
