import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import isProjectCustomSubsectionNameUnique from 'ProjectManager/Project/Common/api/dataManagement/questionnaire/subsection/isProjectCustomSubsectionNameUnique';

const useUniqueNameValidationCallbackForProject = (
    sectionId,
    isCustomSection,
) => {
    const { id: projectId } = useRecoilValue(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const validationCallback = useCallback(
        async (value, editedCustomSubsectionId = null) => {
            if (!value) {
                return true;
            }

            try {
                const response = await isProjectCustomSubsectionNameUnique(
                    value,
                    projectId,
                    sectionId,
                    isCustomSection,
                    editedCustomSubsectionId,
                    source.token,
                );

                return response.data.isUnique;
            } catch (error) {
                return axios.isCancel(error);
            }
        },
        [isCustomSection, projectId, sectionId, source.token],
    );

    return validationCallback;
};

export default useUniqueNameValidationCallbackForProject;
