import api from 'Application/api/api';

const getAssignableEmployeesForTemplate = (templateId, load = [], cancelToken = null) =>
    api.get(`project-manager/templates/${templateId}/employees/assignable`, {
        params: {
            load,
        },
        cancelToken,
    });

export default getAssignableEmployeesForTemplate;
