import { useRecoilValue } from 'recoil';
import isTemplateTopicManagementStepCompletedSelector from 'ProjectManager/Template/Common/recoil/selectors/isTemplateTopicManagementStepCompletedSelector';

const useIsTemplateTopicManagementStepCompleted = () => {
    // noinspection UnnecessaryLocalVariableJS
    const isTemplateTopicManagementStepCompleted = useRecoilValue(
        isTemplateTopicManagementStepCompletedSelector,
    );

    return isTemplateTopicManagementStepCompleted;
};

export default useIsTemplateTopicManagementStepCompleted;
