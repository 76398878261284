import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { prop } from 'styled-tools';
import colors from 'Common/constants/colors';
import { CheckBoxOutlineBlankRounded } from '@material-ui/icons';
import CheckBoxOutlinedRounded from 'Common/components/Icons/CheckBoxOutlinedRounded';

const Wrapper = styled.button`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 15px 0 5px;
    background: none;
    border: 0 none;
    outline: 0 none;
    cursor: pointer;

    &[disabled] {
        cursor: not-allowed;
    }
`;

const IconCircle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    margin-bottom: 8px;
    background-color: ${prop('background')};
    border-radius: 50%;
    color: ${colors.DIRTY_WHITE};
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.3);

    svg {
        font-size: 18px;
    }
`;

const Text = styled.div`
    color: ${colors.GRAY};
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
`;

const Check = styled.div`
    position: absolute;
    top: 0;
    left: calc(50% + 30px);
    transform: translateX(-50%);
    color: ${prop('color')};
`;

const Status = ({ icon, text, color, isSelected, isDisabled, onToggle }) => (
    <Wrapper disabled={isDisabled} onClick={onToggle}>
        <IconCircle background={color}>{icon}</IconCircle>
        <Text>{text}</Text>
        <Check color={color}>
            {isSelected ? (
                <CheckBoxOutlinedRounded />
            ) : (
                <CheckBoxOutlineBlankRounded />
            )}
        </Check>
    </Wrapper>
);

Status.defaultProps = {
    isSelected: false,
    isDisabled: false,
};

Status.propTypes = {
    icon: PropTypes.any.isRequired,
    text: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onToggle: PropTypes.func.isRequired,
};

export default Status;
