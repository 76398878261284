import React, { Children, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { MoreVertRounded } from '@material-ui/icons';
import {
    ClickAwayListener,
    Grow,
    IconButton as MaterialIconButton,
    MenuList,
    Paper as MaterialPaper,
    Popper,
} from '@material-ui/core';
import { ifProp } from 'styled-tools';

const Wrapper = styled.div`
    > div {
        z-index: 1;
    }
`;

const IconButton = styled(MaterialIconButton).withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => {
        if (prop === 'dark') {
            return false;
        }

        return defaultValidatorFn(prop);
    },
})`
    &.MuiIconButton-root {
        color: ${ifProp('dark', colors.DARK_GRAY, colors.DIRTY_WHITE)};

        &:hover {
            background-color: ${ifProp(
                'dark',
                'rgba(0, 0, 0, 0.04)',
                'rgba(255, 255, 255, 0.04)',
            )};
        }

        &.Mui-disabled {
            color: ${colors.SEARCH_FIELD_BORDER};
        }
    }
`;

const Paper = styled(MaterialPaper)`
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.4);
`;

const MoreActionsButton = ({
    className,
    children,
    icon,
    popperProps,
    ...props
}) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = e => {
        if (anchorRef.current && anchorRef.current.contains(e.target)) {
            return;
        }

        setOpen(false);
    };

    const handleListKeyDown = e => {
        if (e.key === 'Tab') {
            e.preventDefault();
            setOpen(false);
        }
    };

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <Wrapper className={className}>
            <IconButton
                ref={anchorRef}
                aria-label="more"
                aria-haspopup="true"
                onClick={handleToggle}
                {...props}
            >
                {icon ?? <MoreVertRounded />}
            </IconButton>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                placement="bottom-end"
                style={{
                    zIndex: 9999,
                }}
                {...popperProps}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-end'
                                    ? 'right top'
                                    : 'right bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    disablePadding
                                    autoFocusItem={open}
                                    onKeyDown={handleListKeyDown}
                                >
                                    {Children.map(children, child => {
                                        if (!child) {
                                            return null;
                                        }

                                        // Always use the onClick handler, even for links,
                                        // so that the user could see the menu closing.
                                        const handleClick = async e => {
                                            handleClose(e);

                                            if (child.props.onClick) {
                                                await child.props.onClick(e);
                                            }
                                        };

                                        return React.cloneElement(child, {
                                            onClick: handleClick,
                                        });
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Wrapper>
    );
};

export default MoreActionsButton;
