import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import MoreActionsButton from 'Common/components/MoreActionsButton/MoreActionsButton';
import MenuItem from 'Common/components/MoreActionsButton/MenuItem';
import formatDate from 'Common/utils/formatDate';
import colors from 'Common/constants/colors';
import { CloudDownloadRounded, DeleteRounded } from '@material-ui/icons';
import parseDate from 'Common/utils/parseDate';
import { Tooltip } from '@material-ui/core';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';
import MatchableText from 'ProjectManager/Project/ProjectMediaFiles/components/MatchableText';

const Info = styled.div``;

const NameAndExtension = styled.div`
    margin-right: 48px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 21px;
`;

const Extension = styled.span`
    margin-right: 5px;
    font-weight: 600;
    text-transform: uppercase;
`;

const UploadedDate = styled.div`
    margin-top: 4px;
    color: ${colors.LIGHT_GRAY};
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
`;

const ButtonWrapper = styled.div`
    position: absolute;
    top: -15px;
    right: 0;
`;

const Wrapper = styled.div`
    position: relative;

    ${ifProp(
        'dark',
        css`
            ${UploadedDate} {
                color: ${colors.GRAY};
            }
        `,
    )};
`;

const FileTile = ({
    file,
    downloadLink,
    onDeleteButtonClick,
    dark,
    searchTerm,
    className,
}) => {
    const urlParts = file.name.split('.');

    const extension = urlParts.pop();
    const name = urlParts.join('.');

    const uploadedAt = parseDate(file.uploadedAt);

    return (
        <Wrapper className={className} dark={dark}>
            <Info>
                <Tooltip
                    title={
                        <MatchableText text={name} searchTerm={searchTerm} />
                    }
                    interactive
                >
                    <NameAndExtension>
                        <Extension>
                            <MatchableText
                                text={extension}
                                searchTerm={searchTerm}
                            />
                        </Extension>
                        <MatchableText text={name} searchTerm={searchTerm} />
                    </NameAndExtension>
                </Tooltip>
                <UploadedDate>Upload vom {formatDate(uploadedAt)}</UploadedDate>
            </Info>
            <ButtonWrapper>
                <MoreActionsButton dark={dark}>
                    <MenuItem
                        icon={<CloudDownloadRounded />}
                        href={downloadLink}
                        target="_blank"
                    >
                        Download
                    </MenuItem>
                    {Boolean(onDeleteButtonClick) && (
                        <MenuItem
                            icon={<DeleteRounded />}
                            type="button"
                            onClick={onDeleteButtonClick}
                        >
                            Löschen
                        </MenuItem>
                    )}
                </MoreActionsButton>
            </ButtonWrapper>
        </Wrapper>
    );
};

FileTile.defaultProps = {
    dark: false,
    onDeleteButtonClick: undefined,
    searchTerm: undefined,
};

FileTile.propTypes = {
    file: PropTypes.object.isRequired,
    downloadLink: PropTypes.string.isRequired,
    dark: PropTypes.bool,
    onDeleteButtonClick: PropTypes.func,
    searchTerm: PropTypes.string,
};

export default FileTile;
