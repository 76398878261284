import api from 'Application/api/api';

const getPublicProjectDataDownloadRequest = (
    projectId,
    downloadRequestId,
    checksum,
    load,
    cancelToken = null,
) =>
    api.get(
        `public/project-manager/projects/${projectId}/download-requests/${downloadRequestId}`,
        {
            params: {
                load,
                checksum,
            },
            cancelToken,
        },
    );

export default getPublicProjectDataDownloadRequest;
