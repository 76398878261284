const colors = {
    // Base colors
    WHITE: '#fff',
    DIRTY_WHITE: '#f0f0f0',
    LIGHTER_GRAY: '#c4c4c4',
    LIGHT_GRAY: '#b6b6b6',
    GRAY: '#9b9b9b',
    DARK_GRAY: '#5e5e5e',

    // Dividers
    LIGHT_DIVIDER: '#dadada',
    DARK_DIVIDER: '#4c4c4c',

    // Colorful
    PRIMARY: '#e06852',
    WARNING: '#eb964e',
    GREEN: '#70d29e',
    ERROR: '#dc6565',
    BLUE: '#458bdb',

    // Colorful (light versions - please, use sparingly)
    PRIMARY_LIGHT: '#f98069',
    WARNING_LIGHT: '#f8b06d',
    GREEN_LIGHT: '#8ae5b6',
    BLUE_LIGHT: '#5e9fed',

    // Colorful (dark versions - please, use even more sparingly)
    DARK_GREEN: '#31b971',

    // Element-specific
    PAGE_BACKGROUND: '#2d2d2d',
    OUTER_BOX_BACKGROUND: '#3a3939',
    INNER_BOX_BACKGROUND: '#414141',
    SEARCH_FIELD_BORDER: '#707070',
};

export default colors;
