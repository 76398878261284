import api from 'Application/api/api';

const changeProjectSupervisors = (projectId, supervisorUserIds, cancelToken = null) =>
    api.post(
        `project-manager/projects/${projectId}/change-supervisors`,
        {
            supervisorUserIds,
        },
        { cancelToken },
    );

export default changeProjectSupervisors;
