import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'Common/components/Form/Fields/Input';
import styled from 'styled-components/macro';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import axios from 'axios';
import OuterBox from 'Common/components/Boxes/OuterBox';
import colors from 'Common/constants/colors';
import OuterBoxButtons from 'Common/components/Boxes/OuterBoxButtons';
import Button from 'Common/components/Button';
import { ArrowBackRounded } from '@material-ui/icons';

const FullWidthContent = styled(OuterBox)`
    margin-bottom: 10px;
    padding: 30px 20px;

    @media screen and (min-width: 940px) {
        display: flex;
        margin-bottom: 0;
        padding: 40px;
    }
`;

const PasswordField = styled.div`
    margin-bottom: 20px;

    @media screen and (min-width: 940px) {
        display: flex;
        align-items: center;
        flex: 0 0 350px;
        width: 350px;
        margin-right: 40px;
        margin-bottom: 0;
    }
`;

const PasswordInner = styled.div`
    width: 100%;
`;

const InputDescription = styled.div`
    margin: 0 0 20px 2px;
    font-size: 14px;
    font-weight: 600;
`;

const AdditionalInformation = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.5;

    @media screen and (min-width: 940px) {
        flex: 1;
        padding-top: 40px;
        padding-bottom: 40px;
    }
`;

const Hint = styled.div`
    max-width: 480px;
    font-size: 18px;
    font-weight: 300;
    line-height: 27px;
    color: ${colors.DIRTY_WHITE};
`;

const DriverLicensePasswordVerification = ({
    hint,
    inputDescription,
    confirmButtonText,
    onConfirm,
    onBackButtonClick,
    showBackButton,
    successMessage,
}) => {
    const [password, setPassword] = useState('');
    const [isConfirming, setIsConfirming] = useState(false);

    const handlePasswordChange = e => {
        setPassword(e.target.value);
    };

    const handleConfirm = async () => {
        setIsConfirming(true);

        try {
            await onConfirm(password);

            notify(successMessage, {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify('Dein Passwort ist falsch', {
                    type: toast.TYPE.ERROR,
                });
            }
        }

        setIsConfirming(false);
    };

    return (
        <>
            <FullWidthContent>
                <PasswordField>
                    <PasswordInner>
                        {!!inputDescription && (
                            <InputDescription>
                                {inputDescription}
                            </InputDescription>
                        )}
                        <Input
                            type="password"
                            label="Passwort"
                            value={password}
                            onChange={handlePasswordChange}
                            autoComplete="off"
                        />
                    </PasswordInner>
                </PasswordField>
                <AdditionalInformation>
                    <Hint>{hint}</Hint>
                </AdditionalInformation>
            </FullWidthContent>
            <OuterBoxButtons>
                {showBackButton && (
                    <Button
                        text
                        icon={<ArrowBackRounded />}
                        type="button"
                        disabled={!onBackButtonClick}
                        onClick={onBackButtonClick}
                    >
                        Zurück
                    </Button>
                )}
                <Button
                    type="button"
                    isLoading={isConfirming}
                    onClick={handleConfirm}
                    disabled={isConfirming}
                >
                    {confirmButtonText}
                </Button>
            </OuterBoxButtons>
        </>
    );
};

DriverLicensePasswordVerification.defaultProps = {
    inputDescription: undefined,
    onBackButtonClick: undefined,
    showBackButton: true,
    successMessage: 'Erfolgreiche Überprüfung',
};

DriverLicensePasswordVerification.propTypes = {
    hint: PropTypes.any.isRequired,
    inputDescription: PropTypes.string,
    confirmButtonText: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onBackButtonClick: PropTypes.func,
    showBackButton: PropTypes.bool,
    successMessage: PropTypes.string,
};

export default DriverLicensePasswordVerification;
