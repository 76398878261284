import api from 'Application/api/api';

const deselectIntroductionPoint = (
    projectId,
    introductionId,
    point,
    cancelToken = null,
) =>
    api.post(
        `project-manager/projects/${projectId}/introductions/${introductionId}/deselect-point`,
        {
            point,
        },
        { cancelToken },
    );

export default deselectIntroductionPoint;
