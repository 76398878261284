import api from 'Application/api/api';

const unassignEmployeesFromTemplate = (
    templateId,
    employeeIds,
    cancelToken = null,
) =>
    api.post(
        `project-manager/templates/${templateId}/employees/unassign`,
        {
            employeeIds,
        },
        {
            cancelToken,
        },
    );

export default unassignEmployeesFromTemplate;
