import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FileTile from 'ProjectManager/DataManagement/Common/components/FileTile';
import FileDeleteModal from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/components/File/FileDeleteModal';
import useQuestionFileFunctions from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/hooks/questionFileFunctions/useQuestionFileFunctions';

const File = ({ question, file, dark, searchTerm, className }) => {
    const { generateDownloadLink } = useQuestionFileFunctions(
        question.id,
        question.isCustom,
    );

    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

    const handleDeleteButtonClick = () => {
        setIsDeleteModalOpened(true);
    };

    const closeModal = () => {
        setIsDeleteModalOpened(false);
    };

    return (
        <>
            <FileTile
                file={file}
                downloadLink={generateDownloadLink(file.id)}
                onDeleteButtonClick={handleDeleteButtonClick}
                className={className}
                dark={dark}
                searchTerm={searchTerm}
            />

            {isDeleteModalOpened && (
                <FileDeleteModal
                    question={question}
                    fileId={file.id}
                    onCancel={closeModal}
                    onSuccess={closeModal}
                />
            )}
        </>
    );
};

File.defaultProps = {
    dark: false,
    searchTerm: undefined,
};

File.propTypes = {
    question: PropTypes.object.isRequired,
    file: PropTypes.object.isRequired,
    dark: PropTypes.bool,
    searchTerm: PropTypes.string,
};

export default File;
