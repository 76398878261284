import React from 'react';
import styled from 'styled-components';
import File from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/components/File/File';
import { useRecoilValue } from 'recoil';
import projectMediaFilesSearchTermAtom from 'ProjectManager/Project/ProjectMediaFiles/recoil/projectMediaFilesSearchTerm/projectMediaFilesSearchTermAtom';
import PropTypes from 'prop-types';

const ListWrapper = styled.div`
    @media screen and (min-width: 580px) {
        margin-top: -30px;
        margin-left: -25px;
    }
`;

const StyledFile = styled(File)`
    margin-bottom: 30px;

    @media screen and (min-width: 580px) {
        display: inline-block;
        width: 210px;
        margin-top: 30px;
        margin-left: 25px;
        margin-bottom: 0;
    }
`;

const QuestionFilesSearchResults = ({ question, files }) => {
    const searchTerm = useRecoilValue(projectMediaFilesSearchTermAtom);

    return (
        <ListWrapper>
            {files.map(file => (
                <StyledFile
                    key={file.id}
                    question={question}
                    file={file}
                    searchTerm={searchTerm}
                />
            ))}
        </ListWrapper>
    );
};

QuestionFilesSearchResults.propTypes = {
    question: PropTypes.object.isRequired,
    files: PropTypes.array.isRequired,
};

export default QuestionFilesSearchResults;
