import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import giveTemplateQuestionYesNoAnswer from 'ProjectManager/Template/Common/api/dataManagement/questionnaire/question/answer/giveTemplateQuestionYesNoAnswer';
import withYesNoAnswerForQuestion
    from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/answer/withYesNoAnswerForQuestion';

const useTemplateGiveYesNoAnswerFunction = (questionId, isCustomQuestion) => {
    const [{ id: templateId }, setTemplate] = useRecoilState(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const giveYesNoAnswer = useCallback(
        async selectedAnswer => {
            await giveTemplateQuestionYesNoAnswer(
                templateId,
                questionId,
                isCustomQuestion,
                selectedAnswer,
                source.token,
            );

            setTemplate(
                withYesNoAnswerForQuestion(
                    questionId,
                    isCustomQuestion,
                    selectedAnswer,
                ),
            );
        },
        [templateId, questionId, isCustomQuestion, setTemplate, source.token],
    );

    return giveYesNoAnswer;
};

export default useTemplateGiveYesNoAnswerFunction;
