import api from 'Application/api/api';

const getInstructionAreas = (load, cancelToken = null) =>
    api.get('/education/instruction-areas', {
        params: {
            load,
        },
        cancelToken,
    });

export default getInstructionAreas;
