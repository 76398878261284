import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components/macro';
import {
    DefaultTableHead,
    Table,
    VirtualizedTableBody,
} from 'Common/components/modernTable';
import axios from 'axios';
import formatDate from 'Common/utils/formatDate';
import parseDate from 'Common/utils/parseDate';
import GroupedPeopleAvatars from 'Common/components/GroupedPeopleAvatars/GroupedPeopleAvatars';
import Status from 'ProjectManager/Project/List/components/Overview/ProjectsTable/Status';
import GeneralData from 'ProjectManager/Project/List/components/Overview/ProjectsTable/GeneralData';
import { useFlexLayout, useSortBy, useTable } from 'react-table';
import { useCustomCellStyles } from 'Common/hooks/useCustomCellStyles';
import { useResponsiveFlexTable } from 'Common/hooks/useResponsiveFlexTable';
import Buttons from 'ProjectManager/Project/List/components/Overview/ProjectsTable/Buttons';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import projectsAtom from 'ProjectManager/Project/List/recoil/projects/projectsAtom';
import projectsSortByAtom from 'ProjectManager/Project/List/recoil/projectsSortByAtom';
import projectsTotalCountAtom from 'ProjectManager/Project/List/recoil/totalCount/projectsTotalCountAtom';
import projectsFiltersAtom from 'ProjectManager/Project/List/recoil/filters/projectsFiltersAtom';
import useLoadProjectsDataRequest from 'ProjectManager/Project/List/hooks/useLoadProjectsDataRequest';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import useProjectsTableRowHeight from 'ProjectManager/Project/List/hooks/useProjectsTableRowHeight';
import NoProjectsFound from 'ProjectManager/Project/List/components/Overview/ProjectsTable/NoProjectsFound';
import projectsLoadingState from 'ProjectManager/Project/List/recoil/loading/projectsLoadingState';
import projectsStatisticsAtom from 'ProjectManager/Project/List/recoil/statistics/projectsStatisticsAtom';
import projectsTotalCountWithoutFiltersAtom from 'ProjectManager/Project/List/recoil/totalCount/projectsTotalCountWithoutFiltersAtom';
import { usePrevious } from 'react-use';
import { debounce, fill } from 'lodash';
import Info from 'ProjectManager/Project/List/components/Overview/ProjectsTable/Info';
import Toolbar from 'ProjectManager/Project/List/components/Overview/ProjectsTable/Toolbar/Toolbar';
import projectsSearchTermAtom from 'ProjectManager/Project/List/recoil/projectsSearchTermAtom';

const MobileButtonsWrapper = styled.div`
    // Gutter on the left of the status icon
    width: 100%;

    @media screen and (min-width: 375px) {
        display: none;
    }
`;

const calculateProjectsHash = (filters, sortBy, searchTerm) =>
    JSON.stringify({
        filters,
        sortBy,
        searchTerm,
    });

const ProjectsTable = () => {
    const [isLoading, setIsLoading] = useRecoilState(projectsLoadingState);
    const [projects, setProjects] = useRecoilState(projectsAtom);
    const [totalCount, setTotalCount] = useRecoilState(projectsTotalCountAtom);

    const setStatistics = useSetRecoilState(projectsStatisticsAtom);
    const setTotalCountWithoutFilters = useSetRecoilState(
        projectsTotalCountWithoutFiltersAtom,
    );

    const filters = useRecoilValue(projectsFiltersAtom);
    const [sortBy, setSortBy] = useRecoilState(projectsSortByAtom);
    const searchTerm = useRecoilValue(projectsSearchTermAtom);

    const columns = useMemo(
        () => [
            {
                Header: 'Projekt ID',
                accessor: 'generalData',
                disableSortBy: true,
                width: 410,
                customCellStyles: {
                    flexWrap: 'wrap',
                },
                customBodyCellStyles: {
                    alignItems: 'flex-start',
                },
                Cell: ({ row: { original: project } }) => (
                    <>
                        <Status project={project} />
                        <GeneralData project={project} />
                        <MobileButtonsWrapper>
                            <Buttons project={project} />
                        </MobileButtonsWrapper>
                    </>
                ),
            },
            {
                Header: 'Startdatum',
                accessor: 'startingDate',
                width: 140,
                Cell: ({ row: { original: project } }) =>
                    formatDate(parseDate(project.startingDate)),
            },
            {
                Header: 'Enddatum',
                accessor: 'endingDate',
                width: 130,
                Cell: ({ row: { original: project } }) =>
                    project.endingDate
                        ? formatDate(parseDate(project.endingDate))
                        : '-',
            },
            {
                Header: 'Verantwortliche',
                accessor: 'supervisors',
                disableSortBy: true,
                width: 150,
                Cell: ({ row: { original: project } }) => {
                    const supervisors = project.supervisors.filter(
                        supervisor => !supervisor.isUnAssigned,
                    );

                    return supervisors.length > 0 ? (
                        <GroupedPeopleAvatars
                            people={supervisors.map(
                                supervisor => supervisor.user,
                            )}
                        />
                    ) : (
                        '-'
                    );
                },
            },
            {
                Header: 'Info',
                accessor: 'info',
                disableSortBy: true,
                width: 70,
                customCellStyles: {
                    justifyContent: 'center',
                },
                Cell: ({ row: { original: project } }) => (
                    <Info project={project} />
                ),
            },
        ],
        [],
    );

    const data = useMemo(() => projects, [projects]);

    const initialSortBy = useMemo(
        () => [
            {
                id: sortBy.field,
                desc: sortBy.direction === 'DESC',
            },
        ],
        // eslint-disable-next-line
        [],
    );

    const hideColumnsPriority = useMemo(
        () => ['supervisors', 'endingDate', 'startingDate', 'info'],
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { hiddenColumns, sortBy: tableSortBy },
    } = useTable(
        {
            columns,
            data,
            hideColumnsPriority,
            disableSortRemove: true,
            disableMultiSort: true,
            manualSortBy: true,
            initialState: {
                sortBy: initialSortBy,
            },
        },
        useFlexLayout,
        useCustomCellStyles,
        useResponsiveFlexTable,
        useSortBy,
    );

    const tableSortById = tableSortBy[0].id;
    const tableSortByDesc = tableSortBy[0].desc;

    useEffect(() => {
        setSortBy(prevSortBy => {
            const newField = tableSortById;
            const newDirection = tableSortByDesc ? 'DESC' : 'ASC';

            if (
                prevSortBy.field === newField &&
                prevSortBy.direction === newDirection
            ) {
                return prevSortBy;
            }

            return {
                field: tableSortById,
                direction: tableSortByDesc ? 'DESC' : 'ASC',
            };
        });
    }, [setSortBy, tableSortById, tableSortByDesc]);

    // Save the sorting, the filters and the search term in the local storage
    useEffect(() => {
        try {
            if (window.localStorage) {
                window.localStorage.setItem(
                    projectsSortByAtom.key,
                    JSON.stringify(sortBy),
                );

                window.localStorage.setItem(
                    projectsFiltersAtom.key,
                    JSON.stringify(filters),
                );

                window.localStorage.setItem(
                    projectsSearchTermAtom.key,
                    searchTerm,
                );
            }
        } catch (error) {
            // Silently ignore the error.
            // This try/catch is here only in cases of the browser
            // not supporting the local storage, e.g. Safari's private browsing mode.
        }
    }, [sortBy, filters, searchTerm]);

    const loadProjectsData = useLoadProjectsDataRequest();

    // The hash is used to identify whether the projects have changed.
    // That happens when the indices of the projects change,
    // e.g. by changing the filter, search term or by sorting the list by another property.
    const projectsHash = calculateProjectsHash(filters, sortBy, searchTerm);
    const prevProjectsHash = usePrevious(projectsHash);

    const isFirstRequestRef = useRef(true);

    // noinspection JSCheckFunctionSignatures
    const loadItems = useCallback(
        debounce(
            async ({
                filters,
                sortBy,
                searchTerm,
                startIndex,
                stopIndex,
                projectsHash,
                prevProjectsHash,
            }) => {
                const isLoadingNewProjects =
                    isFirstRequestRef.current ||
                    projectsHash !== prevProjectsHash;

                if (isLoadingNewProjects) {
                    setIsLoading(true);
                }

                try {
                    const response = await loadProjectsData(
                        startIndex,
                        stopIndex,
                        filters,
                        sortBy,
                        searchTerm,
                    );

                    if (isLoadingNewProjects) {
                        // Load projects anew
                        setProjects(
                            fill(
                                Array(response.data.totalCountWithFilters),
                                {},
                            ).map((emptyProject, index) => {
                                if (startIndex <= index && index <= stopIndex) {
                                    const indexInResponse = index - startIndex;

                                    return response.data.projects[
                                        indexInResponse
                                    ];
                                }

                                return emptyProject;
                            }),
                        );

                        setStatistics(response.data.statistics);
                        setTotalCount(response.data.totalCountWithFilters);
                        setTotalCountWithoutFilters(
                            response.data.totalCountWithoutFilters,
                        );
                    } else {
                        // Add projects to the current collection
                        setProjects(prevProjects =>
                            prevProjects.map((prevProject, index) => {
                                if (startIndex <= index && index <= stopIndex) {
                                    const indexInResponse = index - startIndex;

                                    return response.data.projects[
                                        indexInResponse
                                    ];
                                }

                                return prevProject;
                            }),
                        );
                    }

                    if (isLoadingNewProjects) {
                        setIsLoading(false);
                    }

                    isFirstRequestRef.current = false;
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        notify(
                            'Ein Fehler ist aufgetreten. Bitte versuchen Sie, die Seite zu aktualisieren.',
                            {
                                type: toast.TYPE.ERROR,
                            },
                        );
                    }
                }
            },
            350,
            {
                leading: true,
                trailing: true,
            },
        ),
        [
            setIsLoading,
            loadProjectsData,
            setProjects,
            setStatistics,
            setTotalCount,
            setTotalCountWithoutFilters,
        ],
    );

    const handleLoadItems = useCallback(
        debounce(
            async ({ startIndex, stopIndex }) =>
                loadItems({
                    filters,
                    sortBy,
                    searchTerm,
                    startIndex,
                    stopIndex,
                    projectsHash,
                    prevProjectsHash,
                }),
            350,
            {
                leading: true,
                trailing: true,
            },
        ),
        [
            loadItems,
            filters,
            sortBy,
            searchTerm,
            projectsHash,
            prevProjectsHash,
        ],
    );

    // Each time when the filters and/or the sorting change, reload the projects
    useEffect(() => {
        if (!isFirstRequestRef.current && projectsHash !== prevProjectsHash) {
            // noinspection JSIgnoredPromiseFromCall
            loadItems({
                filters,
                sortBy,
                searchTerm,
                startIndex: 0,
                stopIndex: 10,
                projectsHash,
                prevProjectsHash,
            });
        }
    }, [
        loadItems,
        projectsHash,
        prevProjectsHash,
        filters,
        sortBy,
        searchTerm,
    ]);

    // @TODO: Remove this hook when the npm package react-virtualized has been updated
    const rowHeight = useProjectsTableRowHeight();

    return (
        <Table getTableProps={getTableProps}>
            <DefaultTableHead
                before={<Toolbar />}
                headerGroups={headerGroups}
            />
            {totalCount > 0 ? (
                <VirtualizedTableBody
                    rows={rows}
                    hiddenColumns={hiddenColumns}
                    visibleColumns={visibleColumns}
                    prepareRow={prepareRow}
                    headerGroups={headerGroups}
                    getTableBodyProps={getTableBodyProps}
                    totalItemsCount={totalCount}
                    loadItems={handleLoadItems}
                    itemsHash={projectsHash}
                    rowDefaultHeight={rowHeight}
                    rowMinHeight={rowHeight}
                    isLoading={isLoading}
                />
            ) : (
                <NoProjectsFound />
            )}
        </Table>
    );
};

export default ProjectsTable;
