import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import Drawer from 'Common/components/Drawer';

const ProjectDrawer = ({ isOpened, onOpen, onClose, children, ...props }) => {
    const { name } = useRecoilValue(projectAtom);

    return (
        <Drawer
            isOpened={isOpened}
            onOpen={onOpen}
            onClose={onClose}
            backButtonProps={{
                title: 'Zurück zum Projekt',
                subtitle: name,
            }}
            {...props}
        >
            {children}
        </Drawer>
    );
};

ProjectDrawer.propTypes = {
    isOpened: PropTypes.bool.isRequired,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ProjectDrawer;
