import api from 'Application/api/api';

const deleteProjectQuestionComment = (projectId, questionId, isCustom, cancelToken = null) =>
    api.delete(
        `project-manager/projects/${projectId}/questionnaire/questions/${questionId}/${
            isCustom ? 1 : 0
        }/comment`,
        {
            cancelToken,
        },
    );

export default deleteProjectQuestionComment;
