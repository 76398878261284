import api from 'Application/api/api';

const deselectTemplateTopic = (templateId, topicId, cancelToken = null) =>
    api.post(
        `project-manager/templates/${templateId}/topics/${topicId}/deselect`,
        {},
        { cancelToken },
    );

export default deselectTemplateTopic;
