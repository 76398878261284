import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
    display: flex;
    width: 100%;
`;

const Sector = styled.div`
    flex: 1;
`;

const Number = styled.div`
    margin-bottom: 5px;
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
`;

const Text = styled.div``;

const Countdown = ({ hours, minutes, seconds }) => {
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return (
        <Wrapper>
            <Sector>
                <Number>{formattedHours}</Number>
                <Text>Stunden</Text>
            </Sector>
            <Sector>
                <Number>{formattedMinutes}</Number>
                <Text>Minuten</Text>
            </Sector>
            <Sector>
                <Number>{formattedSeconds}</Number>
                <Text>Sekunden</Text>
            </Sector>
        </Wrapper>
    );
};

Countdown.propTypes = {
    hours: PropTypes.number.isRequired,
    minutes: PropTypes.number.isRequired,
    seconds: PropTypes.number.isRequired,
};

export default Countdown;
