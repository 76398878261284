import React from 'react';
import PropTypes from 'prop-types';
import Input from 'Common/components/Form/Fields/Input';
import { DeleteRounded } from '@material-ui/icons';
import Button from 'Common/components/Button';
import styled from 'styled-components/macro';
import AddTask from 'Common/components/Icons/AddTask';

const Wrapper = styled.div`
    margin-bottom: 15px;
`;

const Answer = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
`;

const InlineField = styled.div`
    width: 100%;
    margin-right: 10px;

    &:last-of-type {
        margin-right: 0;
    }
`;

const DeleteButton = styled(Button)`
    position: relative;
    top: 7px;
`;

const Answers = ({
    answers,
    errors,
    touched,
    handleChange,
    handleBlur,
    onAdd,
    onRemove,
}) => (
    <>
        <Wrapper>
            {answers.map((answer, index) => {
                const fieldName = `answers[${index}]`;

                return (
                    <Answer key={index}>
                        <InlineField>
                            <Input
                                id={fieldName}
                                name={fieldName}
                                label={`Antwort ${index + 1}`}
                                value={answer}
                                error={errors[index]}
                                touched={touched[index]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isRequired
                            />
                        </InlineField>
                        {answers.length > 2 && (
                            <DeleteButton
                                iconOnly
                                icon={<DeleteRounded />}
                                type="button"
                                onClick={() => onRemove(index)}
                                tooltip="Löschen"
                            />
                        )}
                    </Answer>
                );
            })}
        </Wrapper>
        <Button underline icon={<AddTask />} type="button" onClick={onAdd}>
            Antwort hinzufügen
        </Button>
    </>
);

Answers.propTypes = {
    answers: PropTypes.array.isRequired,
    errors: PropTypes.array.isRequired,
    touched: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
};

export default Answers;
