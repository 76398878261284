import api from 'Application/api/api';

const updateProjectCustomTopic = (
    projectId,
    customTopicId,
    data,
    cancelToken = null,
) =>
    api.post(
        `project-manager/projects/${projectId}/custom-topics/${customTopicId}`,
        data,
        { cancelToken },
    );

export default updateProjectCustomTopic;
