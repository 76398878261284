import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import GroupAssignmentEmployeeChecklist from 'Education/components/instruction-assignment/GroupAssignmentEmployeeChecklist';
import completeGroupInstructionAssignmentExam from 'Education/api/instruction-assignment/exam/completeGroupInstructionAssignmentExam';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import OuterBox from 'Common/components/Boxes/OuterBox';
import colors from 'Common/constants/colors';

const Wrapper = styled(OuterBox)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 20px;
    text-align: center;

    @media screen and (min-width: 940px) {
        padding: 50px;
    }
`;

const Title = styled.div`
    font-size: 18px;
    line-height: 27px;

    @media screen and (min-width: 940px) {
        font-size: 22px;
        line-height: 33px;
    }
`;

const SubTitle = styled.div`
    margin-bottom: 40px;
    color: ${colors.GRAY};
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;

    @media screen and (min-width: 940px) {
        font-size: 18px;
        line-height: 27px;
    }
`;

const GroupAssignmentExamApprovalScreen = ({ assignment, onComplete }) => {
    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleEmployeeChecklistConfirm = async selectedIds => {
        try {
            await completeGroupInstructionAssignmentExam(
                assignment.id,
                selectedIds,
                source.token,
            );

            await onComplete();
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    return (
        <Wrapper>
            <Title>Herzlichen Glückwunsch</Title>
            <SubTitle>Die Gruppe hat den Test erfolgreich bestanden</SubTitle>
            <GroupAssignmentEmployeeChecklist
                employees={assignment.groupAssignmentDetails.assignees
                    .filter(
                        assignee =>
                            assignee.employee.id !==
                            assignment.groupAssignmentDetails.groupLeader
                                .employee.id,
                    )
                    .map(assignee => assignee.employee)}
                confirmButtonText="Bestätigen"
                onConfirm={handleEmployeeChecklistConfirm}
            />
        </Wrapper>
    );
};

GroupAssignmentExamApprovalScreen.propTypes = {
    assignment: PropTypes.object.isRequired,
    onComplete: PropTypes.func.isRequired,
};

export default GroupAssignmentExamApprovalScreen;
