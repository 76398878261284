import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import MoreActionsButton from 'Common/components/MoreActionsButton/MoreActionsButton';
import MenuItem from 'Common/components/MoreActionsButton/MenuItem';
import { AddPhotoAlternateRounded, DeleteRounded } from '@material-ui/icons';

const StyledMoreActionsButton = styled(MoreActionsButton)`
    .MuiIconButton-root {
        background-color: rgba(0, 0, 0, 0.5);

        &:hover {
            background-color: rgba(0, 0, 0, 0.65);
        }
    }
`;

const PhotoActionsMenu = ({ onUploadNewButtonClick, onDeleteButtonClick }) => (
    <StyledMoreActionsButton>
        <MenuItem
            icon={<AddPhotoAlternateRounded />}
            type="button"
            onClick={onUploadNewButtonClick}
        >
            Bild hochladen
        </MenuItem>
        <MenuItem
            icon={<DeleteRounded />}
            type="button"
            onClick={onDeleteButtonClick}
        >
            Bild löschen
        </MenuItem>
    </StyledMoreActionsButton>
);

PhotoActionsMenu.propTypes = {
    onUploadNewButtonClick: PropTypes.func.isRequired,
    onDeleteButtonClick: PropTypes.func.isRequired,
};

export default PhotoActionsMenu;
