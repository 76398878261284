import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import OuterBoxSubtitle from 'Common/components/Boxes/OuterBoxSubtitle';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import participantsAtom from 'ProjectManager/Project/Introductions/Create/recoil/participants/participantsAtom';
import ReactDOM from 'react-dom';
import Button from 'Common/components/Button';
import LabeledValue from 'ProjectManager/Project/Preview/LabeledValue';
import styled from 'styled-components/macro';
import RefineModal from 'ProjectManager/Project/Introductions/Create/components/Steps/EmployeeSelection/RefineModal';
import introducedProjectParticipantIdsSelector
    from 'ProjectManager/Project/Common/recoil/project/selectors/introducedProjectParticipantIdsSelector';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import roles from 'User/constants/roles';
import supervisorUserOptionAtom
    from 'ProjectManager/Project/Introductions/Create/recoil/supervisorUserOption/supervisorUserOptionAtom';
import axios from 'axios';
import SuccessModal from 'ProjectManager/Project/Introductions/Create/components/Steps/EmployeeSelection/SuccessModal';
import createIntroduction from 'ProjectManager/Project/Introductions/Common/api/createIntroduction';
import commentAtom from 'ProjectManager/Project/Introductions/Create/recoil/comment/commentAtom';
import pointsAtom from 'ProjectManager/Project/Introductions/Create/recoil/points/pointsAtom';
import ppesAtom from 'ProjectManager/Project/Introductions/Create/recoil/ppes/ppesAtom';

const RefineButton = styled(Button)`
  padding-left: 0;
`;

const StyledLabeledValue = styled(LabeledValue)`
  margin-bottom: 0;
`;

const EmployeesSelection = ({ onBackButtonClick, onCreate, onClose }) => {
    const currentUserId = useSelector(state => state.user.id);
    const role = useSelector(state => state.user.role);
    const selectedSupervisorUserOption = useRecoilValue(
        supervisorUserOptionAtom,
    );

    const { id: projectId, participants } = useRecoilValue(projectAtom);
    const selectedParticipants = useRecoilValue(participantsAtom);

    const assignedParticipants = useMemo(
        () => participants.filter(participant => participant.isAssigned),
        [participants],
    );

    const introducedProjectParticipantIds = useRecoilValue(
        introducedProjectParticipantIdsSelector,
    );

    // Use a delayed-set ref because the bottom drawer buttons are not available
    // during the initial render of this component.
    const [bottomButtonsRef, setBottomButtonsRef] = useState(
        document.getElementById('drawer-bottom-buttons'),
    );

    useEffect(() => {
        setBottomButtonsRef(document.getElementById('drawer-bottom-buttons'));
    }, []);

    const [isRefineModalOpened, setIsRefineModalOpened] = useState(false);

    const handleRefineButtonClick = () => {
        setIsRefineModalOpened(true);
    };

    const closeRefineModal = () => {
        setIsRefineModalOpened(false);
    };

    const [isSuccessModalOpened, setIsSuccessModalOpened] = useState(false);

    const handleCloseSuccessModal = () => {
        setIsSuccessModalOpened(false);
        onClose();
    };

    const [isCreating, setIsCreating] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const supervisorUserOption = useRecoilValue(supervisorUserOptionAtom);
    const selectedPoints = useRecoilValue(pointsAtom);
    const selectedPPEs = useRecoilValue(ppesAtom);
    const comment = useRecoilValue(commentAtom);

    const handleCreateIntroduction = async () => {
        setIsCreating(true);

        try {
            await createIntroduction(projectId, {
                supervisorUserId: supervisorUserOption ? supervisorUserOption.value : null,
                points: selectedPoints,
                ppes: selectedPPEs,
                projectParticipantIds: selectedParticipants,
                comment,
            }, source.token);

            await onCreate();
            setIsCreating(false);

            if (
                role === roles.MANAGER &&
                selectedSupervisorUserOption &&
                selectedSupervisorUserOption.value !== currentUserId
            ) {
                setIsSuccessModalOpened(true);
            } else {
                notify('Einweisung erfolgreich erstellt', {
                    type: toast.TYPE.SUCCESS,
                });

                onClose();
            }
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsCreating(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const isCreatingDisabled = selectedParticipants.length === 0;

    return (
        <>
            <OuterBoxTitle>Mitarbeiter einbeziehen</OuterBoxTitle>
            <OuterBoxSubtitle>
                Willst du alle Mitarbeiter in die Einweisung einbeziehen die
                noch nicht eingewiesen sind oder ausgewählte? Grundeinstellung
                ist, dass alle Mitarbeiter die noch nicht eingewiesen wurden
                automatisch einbezogen werden, so dass du direkt fortfahren
                kannst. Falls du nur bestimmte Mitarbeiter einweisen willst,
                kannst du das in dem du auf "Verfeinern klickst".
            </OuterBoxSubtitle>
            <div>
                <StyledLabeledValue label="Einbezogene Mitarbeiter">
                    {`${selectedParticipants.length} / ${assignedParticipants.length}`}
                </StyledLabeledValue>
                <RefineButton
                    type="button"
                    underline
                    onClick={handleRefineButtonClick}
                >
                    Verfeinern
                </RefineButton>
            </div>
            {Boolean(bottomButtonsRef) &&
            ReactDOM.createPortal(
                <>
                    <Button
                        text
                        type="button"
                        onClick={onBackButtonClick}
                        disabled={isCreating}
                    >
                        Zurück
                    </Button>
                    <Button
                        type="button"
                        dark
                        onClick={handleCreateIntroduction}
                        isLoading={isCreating}
                        disabled={isCreatingDisabled || isCreating}
                        tooltip={
                            isCreatingDisabled
                                ? 'Um fortzufahren musst du mindestens einen Mitarbeiter auswählen'
                                : ''
                        }
                    >
                        Erstellen
                    </Button>
                </>,
                bottomButtonsRef,
            )}
            {isRefineModalOpened && (
                <RefineModal
                    participants={assignedParticipants}
                    introducedParticipantIds={introducedProjectParticipantIds}
                    onClose={closeRefineModal}
                />
            )}
            {isSuccessModalOpened && (
                <SuccessModal onClose={handleCloseSuccessModal} />
            )}
        </>
    );
};

EmployeesSelection.propTypes = {
    onBackButtonClick: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default EmployeesSelection;
