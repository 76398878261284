import React from 'react';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import SideBySideStatistics from 'ProjectManager/Project/Preview/Statistics/SideBySideStatistics';
import StatisticsNumber from 'ProjectManager/Project/Preview/Statistics/StatisticsNumber';
import { sumBy } from 'lodash';

const Statistics = () => {
    const { topics, customTopics, questionnaireSections } = useRecoilValue(
        projectAtom,
    );

    const allTopicsCount = topics.length + customTopics.length;

    const subsectionsCount = sumBy(
        questionnaireSections,
        section => section.subsections.length,
    );

    return (
        <SideBySideStatistics>
            <StatisticsNumber number={allTopicsCount} text="Projektfelder" />
            <StatisticsNumber number={subsectionsCount} text="Untersektionen" />
        </SideBySideStatistics>
    );
};

export default Statistics;
