import api from 'Application/api/api';

const uploadGeneralTemplateFiles = (templateId, files, cancelToken = null) => {
    const formData = new FormData();

    files.forEach((file, index) => {
        formData.append(`file${index}`, file);
    });

    return api.post(`project-manager/templates/${templateId}/files`, formData, {
        cancelToken,
        timeout: 30 * 1000,
    });
};

export default uploadGeneralTemplateFiles;
