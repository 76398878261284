import api from 'Application/api/api';

const startNewInstructionAssignmentExamAttempt = (id, cancelToken = null) =>
    api.post(
        `/education/instruction-assignments/${id}/exam/start`,
        {},
        { cancelToken },
    );

export default startNewInstructionAssignmentExamAttempt;
