import api from 'Application/api/api';

const updateProjectCustomSubsection = (projectId, customSubsectionId, data, cancelToken = null) =>
    api.post(
        `project-manager/projects/${projectId}/questionnaire/custom-subsections/${customSubsectionId}`,
        data,
        {cancelToken}
    );

export default updateProjectCustomSubsection;
