import api from 'Application/api/api';

const updateInstructionAssignmentExamGivenAnswers = (
    id,
    answerIds,
    cancelToken = null,
) =>
    api.post(
        `/education/instruction-assignments/${id}/exam/update-given-answers`,
        {
            answerIds,
        },
        {
            cancelToken,
        },
    );

export default updateInstructionAssignmentExamGivenAnswers;
