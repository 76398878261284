import { createGlobalStyle } from 'styled-components';

const IconFont = createGlobalStyle`
    @font-face {
        font-family: 'Font-Amedic-01';
        src: local('Font-Amedic-01'), local('Font-Amedic-01'),
        url('/assets/fonts/Font-Amedic-01.woff') format('woff2'),
        url('/assets/fonts/Font-Amedic-01.woff2') format('woff'),
        url('/assets/fonts/Font-Amedic-01.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }
`;

export default IconFont;
