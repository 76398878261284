const withoutCoordinator = projectCoordinatorId => prevProject => {
    const projectCoordinators = [...prevProject.projectCoordinators];

    const index = projectCoordinators.findIndex(
        projectCoordinator => projectCoordinator.id === projectCoordinatorId,
    );

    if (index !== -1) {
        projectCoordinators.splice(index, 1);
    }

    return {
        ...prevProject,
        projectCoordinators,
    };
};

export default withoutCoordinator;
