import api from 'Application/api/api';

const isTemplateCustomSubsectionNameUnique = (
    name,
    templateId,
    sectionOrCustomTopicId,
    isCustomTopic,
    editedCustomSubsectionId = null,
    cancelToken = null,
) =>
    api.get(
        `project-manager/templates/${templateId}/questionnaire/sections/${sectionOrCustomTopicId}/${
            isCustomTopic ? 1 : 0
        }/custom-subsections/is-name-unique/${name}`,
        {
            params: {
                editedCustomSubsectionId,
            },
            cancelToken,
        },
    );

export default isTemplateCustomSubsectionNameUnique;
