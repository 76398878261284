import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import { HomeRounded } from '@material-ui/icons';

const Wrapper = styled.div`
    width: 100%;
    max-width: 1440px;
    padding: 0 10px;
    margin: 25px auto 15px;

    @media screen and (min-width: 500px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media screen and (min-width: 980px) {
        padding-left: 40px;
        padding-right: 40px;
    }
`;

const Inner = styled.div`
    padding: 0 15px;
`;

const BreadcrumbsPortal = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const HomeIcon = styled(HomeRounded)`
    margin-right: 5px;
    font-size: 20px !important;
`;

const Breadcrumbs = ({ isReact }) => (
    <Wrapper>
        <Inner>
            <BreadcrumbsPortal id="breadcrumbs" />
            {isReact ? (
                <Breadcrumb to="/dashboard">
                    <HomeIcon />
                    Home
                </Breadcrumb>
            ) : (
                <Breadcrumb href="/dashboard">
                    <HomeIcon />
                    Home
                </Breadcrumb>
            )}
        </Inner>
    </Wrapper>
);

Breadcrumbs.defaultProps = {
    isReact: true,
};

Breadcrumbs.propTypes = {
    isReact: PropTypes.bool,
};

export default Breadcrumbs;
