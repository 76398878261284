import { store } from 'Application/store';
import { push } from 'connected-react-router';
import { authenticateUser } from 'User/reduxUser';
import loginRequest from 'User/api/login';

const login = async (
    domain,
    userIdentifier,
    password,
    redirect = true,
    cancelToken = null,
) => {
    const response = await loginRequest(
        domain,
        userIdentifier,
        password,
        cancelToken,
    );

    store.dispatch(authenticateUser(response.data));

    if (redirect) {
        store.dispatch(push('/react-post-login'));

        // @TODO: Replace with a redirect to the dashboard, once we get rid of Laravel's evaluation.
        // store.dispatch(push('/dashboard'));
    }
};

export default login;
