import React, { Fragment } from 'react';
import ContentSection from 'ProjectManager/DataManagement/Common/components/ContentSection';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import { useWindowWidth } from '@react-hook/window-size';
import useWindowScroll from '@react-hook/window-scroll';
import styled from 'styled-components/macro';
import AddCustomTopicButton from 'ProjectManager/DataManagement/TopicManagement/components/CustomTopicManagement/components/AddCustomTopicButton';
import CustomTopicTile from 'ProjectManager/DataManagement/TopicManagement/components/CustomTopicManagement/components/CustomTopicTile/CustomTopicTile';
import useCustomTopics from 'ProjectManager/DataManagement/TopicManagement/components/CustomTopicManagement/hooks/customTopics/useCustomTopics';

const List = styled.div`
    margin-bottom: 20px;

    > div {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 940px) {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-bottom: 0;

        > div {
            flex: 0 0 420px;
            width: 420px;
            margin-right: 10px;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 10px;
            }
        }
    }
`;

const DesktopColumn = styled.div`
    > div {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const AddButtonWrapper = styled.div`
    margin-top: 20px;
`;

const approximateTileHeight = 280;
const approximateStickyElementsHeight = 170;

const CustomTopicManagement = () => {
    const customTopics = useCustomTopics();

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 940;

    const scrollY = useWindowScroll();

    const handleAdd = () => {
        if (isMobile) {
            const distanceToAddButton = document
                .getElementById('addCustomTopicButtonWrapper')
                .getBoundingClientRect().top;

            window.scroll({
                top:
                    scrollY +
                    distanceToAddButton -
                    approximateStickyElementsHeight -
                    approximateTileHeight,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    const handleFormOpen = () => {
        if (isMobile) {
            const distanceToAddButton = document
                .getElementById('addCustomTopicButtonWrapper')
                .getBoundingClientRect().top;

            // The timeout is to ensure that the state will be set
            // and the distance to the button will be calculated properly.
            setTimeout(() => {
                window.scroll({
                    top:
                        scrollY +
                        distanceToAddButton -
                        approximateStickyElementsHeight -
                        15, // Ensure to have some spacing at the top
                    left: 0,
                    behavior: 'smooth',
                });
            }, 5);
        }
    };

    const oddCustomTopics = customTopics.filter(
        (customTopic, index) => (index + 1) % 2 !== 0,
    );

    const evenCustomTopics = customTopics.filter(
        (customTopic, index) => (index + 1) % 2 === 0,
    );

    const customTopicColumns = isMobile
        ? [customTopics]
        : [oddCustomTopics, evenCustomTopics];

    const ColumnComponent = isMobile ? Fragment : DesktopColumn;

    return (
        <ContentSection>
            <OuterBoxTitle>Eigene Projektfelder</OuterBoxTitle>
            <List>
                {customTopicColumns.map((customTopics, index) => (
                    <ColumnComponent key={index}>
                        {customTopics.map(customTopic => (
                            <CustomTopicTile
                                key={customTopic.id}
                                customTopic={customTopic}
                            />
                        ))}
                        {index === 0 && (
                            <AddButtonWrapper id="addCustomTopicButtonWrapper">
                                <AddCustomTopicButton
                                    onAdd={handleAdd}
                                    onFormOpen={handleFormOpen}
                                />
                            </AddButtonWrapper>
                        )}
                    </ColumnComponent>
                ))}
            </List>
        </ContentSection>
    );
};

export default CustomTopicManagement;
