const withoutPPE = ppe => prevPPEs => {
    const index = prevPPEs.indexOf(ppe);

    if (index === -1) {
        return prevPPEs;
    }

    const newPPEs = [...prevPPEs];

    newPPEs.splice(index, 1);

    return newPPEs;
};

export default withoutPPE;
