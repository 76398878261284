import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import giveTemplateQuestionTextAnswer from 'ProjectManager/Template/Common/api/dataManagement/questionnaire/question/answer/giveTemplateQuestionTextAnswer';
import withTextAnswerForQuestion
    from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/answer/withTextAnswerForQuestion';

const useTemplateGiveTextAnswerFunction = (questionId, isCustomQuestion) => {
    const [{ id: templateId }, setTemplate] = useRecoilState(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const giveTextAnswer = useCallback(
        async textAnswer => {
            await giveTemplateQuestionTextAnswer(
                templateId,
                questionId,
                isCustomQuestion,
                textAnswer,
                source.token,
            );

            setTemplate(
                withTextAnswerForQuestion(
                    questionId,
                    isCustomQuestion,
                    textAnswer,
                ),
            );
        },
        [templateId, questionId, isCustomQuestion, setTemplate, source.token],
    );

    return giveTextAnswer;
};

export default useTemplateGiveTextAnswerFunction;
