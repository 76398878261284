import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import NoTemplatesModal from 'ProjectManager/Template/List/components/Overview/Group/DeleteModal/components/NoTemplatesModal';
import deleteTemplateGroup from 'ProjectManager/TemplateGroup/api/deleteTemplateGroup';
import { useRecoilState, useSetRecoilState } from 'recoil';
import LastTemplateGroupModal from 'ProjectManager/Template/List/components/Overview/Group/DeleteModal/components/LastTemplateGroupModal';
import PropTypes from 'prop-types';
import StandardDeleteModal from 'ProjectManager/Template/List/components/Overview/Group/DeleteModal/components/StandardDeleteModal';
import withoutGroup from 'ProjectManager/Template/List/recoil/groups/modifiers/withoutGroup';
import groupsAtom from 'ProjectManager/Template/List/recoil/groups/groupsAtom';
import templatesAtom from 'ProjectManager/Template/List/recoil/templates/templatesAtom';
import withTemplatesMovedFromGroupToGroup from 'ProjectManager/Template/List/recoil/templates/modifiers/withTemplatesMovedFromGroupToGroup';
import withoutTemplatesFromGroup from 'ProjectManager/Template/List/recoil/templates/modifiers/withoutTemplatesFromGroup';

const DeleteModal = ({ group, onClose }) => {
    const [allGroups, setGroups] = useRecoilState(groupsAtom);
    const setTemplates = useSetRecoilState(templatesAtom);

    const [isDeleting, setIsDeleting] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirm = async (
        templateGroupIdToMoveTheTemplatesTo = null,
    ) => {
        setIsDeleting(true);

        try {
            await deleteTemplateGroup(
                group.id,
                templateGroupIdToMoveTheTemplatesTo,
                source.token,
            );

            setGroups(withoutGroup(group.id));

            if (templateGroupIdToMoveTheTemplatesTo) {
                setTemplates(
                    withTemplatesMovedFromGroupToGroup(
                        group.id,
                        allGroups.find(
                            group =>
                                group.id ===
                                templateGroupIdToMoveTheTemplatesTo,
                        ),
                    ),
                );
            } else {
                setTemplates(withoutTemplatesFromGroup(group.id));
            }

            onClose();

            notify('Der Projektkategorie wurde erfolgreich gelöscht', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify('Der Projektkategorie konnte nicht gelöscht werden', {
                    type: toast.TYPE.ERROR,
                });
            }
        }

        setIsDeleting(false);
    };

    if (group.templates.length === 0) {
        return (
            <NoTemplatesModal
                group={group}
                isLoading={isDeleting}
                onConfirm={handleConfirm}
                onCancel={onClose}
            />
        );
    }

    if (allGroups.length === 1) {
        return (
            <LastTemplateGroupModal
                isLoading={isDeleting}
                onConfirm={handleConfirm}
                onCancel={onClose}
            />
        );
    }

    return (
        <StandardDeleteModal
            group={group}
            isLoading={isDeleting}
            onConfirm={handleConfirm}
            onCancel={onClose}
        />
    );
};

DeleteModal.propTypes = {
    group: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default DeleteModal;
