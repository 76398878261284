import { selector } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import isAfter from 'date-fns/isAfter';
import createNewDate from 'Common/utils/createNewDate';
import parseDate from 'Common/utils/parseDate';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';

const isGeneralProjectInformationStepCompletedSelector = selector({
    key: 'projectManagement_isGeneralProjectInformationStepCompleted',
    get: ({ get }) => {
        const {
            id,
            name,
            address,
            clientContactPeople,
            supervisors,
            areas,
            workingDays,
            endingDate,
        } = get(projectAtom);

        const isEndingDateValid =
            !Boolean(endingDate) ||
            isAfter(
                endOfDay(parseDate(endingDate)),
                startOfDay(createNewDate()),
            );

        return (
            Boolean(id) &&
            name !== '' &&
            address !== '' &&
            clientContactPeople.length > 0 &&
            supervisors.filter(
                supervisor => !supervisor.isUnAssigned,
            ).length > 0 &&
            areas.length > 0 &&
            workingDays.length > 0 &&
            isEndingDateValid
        );
    },
});

export default isGeneralProjectInformationStepCompletedSelector;
