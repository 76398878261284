import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useProjectWorkingDaysData from 'ProjectManager/DataManagement/WorkingDays/hooks/useProjectWorkingDaysData';
import useTemplateWorkingDaysData from 'ProjectManager/DataManagement/WorkingDays/hooks/useTemplateWorkingDaysData';

const useWorkingDaysData = () => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useProjectWorkingDaysData();
    const dataFromTemplate = useTemplateWorkingDaysData();

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return {};
};

export default useWorkingDaysData;
