import api from 'Application/api/api';

const isProjectCoordinatorEmailUnique = (
    email,
    projectId,
    projectCoordinatorId = null,
    cancelToken = null,
) =>
    api.get(
        `project-manager/projects/${projectId}/project-coordinators/is-email-unique/${email}`,
        {
            params: {
                projectCoordinatorId,
            },
            cancelToken,
        },
    );

export default isProjectCoordinatorEmailUnique;
