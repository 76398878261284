const withoutIntroductionPPE = (introductionId, ppe) => prevProject => {
    const index = prevProject.introductions.findIndex(
        introduction => introduction.id === introductionId,
    );

    if (index === -1) {
        return prevProject;
    }

    const ppeIndex = prevProject.introductions[index].ppes.indexOf(ppe);

    if (ppeIndex === -1) {
        return prevProject;
    }

    const newIntroductions = [...prevProject.introductions];
    const newPPEs = [...newIntroductions[index].ppes];

    newPPEs.splice(ppeIndex, 1);

    newIntroductions[index] = {
        ...newIntroductions[index],
        ppes: newPPEs,
    };

    return {
        ...prevProject,
        introductions: newIntroductions,
    };
};

export default withoutIntroductionPPE;
