import React, { useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { debounce } from 'lodash';
import Select from 'Common/components/Form/Fields/Select';
import { useRecoilState } from 'recoil';
import hasUnsavedChangesState from 'Common/recoil/hasUnsavedChangesState';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import colors from 'Common/constants/colors';
import useWorkingHoursData from 'ProjectManager/DataManagement/WorkingHours/hooks/useWorkingHoursData';
import convertAmountOfHoursToHumanReadableString from 'ProjectManager/DataManagement/WorkingHours/helpers/convertAmountOfHoursToHumanReadableString';
import hourOptions from 'ProjectManager/DataManagement/WorkingHours/constants/hourOptions';
import PropTypes from 'prop-types';
import { useWindowWidth } from '@react-hook/window-size';

const Wrapper = styled.div`
    display: flex;
    border-radius: 6px;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.16);

    > div {
        flex: 1;

        &:first-child {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                width: 1px;
                height: 60%;
                background: ${colors.LIGHT_GRAY};
                transform: translateY(-50%);
            }
        }
    }
`;

const WorkingHours = ({ isInsideDrawer }) => {
    const { from, to, updateWorkingHours } = useWorkingHoursData();

    const [, setHasUnsavedChanges] = useRecoilState(hasUnsavedChangesState);

    const debouncedSave = useCallback(
        debounce(
            async values => {
                try {
                    const fromValue = values.from.value;
                    const toValue = values.to.value;

                    await updateWorkingHours({
                        from: {
                            hours: Math.floor(fromValue),
                            minutes: (fromValue - Math.floor(fromValue)) * 60,
                            formatted: convertAmountOfHoursToHumanReadableString(
                                fromValue,
                            ),
                        },
                        to: {
                            hours: Math.floor(toValue),
                            minutes: (toValue - Math.floor(toValue)) * 60,
                            formatted: convertAmountOfHoursToHumanReadableString(
                                toValue,
                            ),
                        },
                    });
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        notify(
                            'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                            {
                                type: toast.TYPE.ERROR,
                            },
                        );
                    }
                }

                setHasUnsavedChanges(false);
            },
            350,
            {
                leading: true,
                trailing: true,
            },
        ),
        [updateWorkingHours, setHasUnsavedChanges],
    );

    const [workingHours, setWorkingHours] = useState(() => ({
        from: hourOptions.find(
            option => option.value === from.hours + from.minutes / 60,
        ),
        to: hourOptions.find(
            option => option.value === to.hours + to.minutes / 60,
        ),
    }));

    const handleFromChange = newFrom => {
        setHasUnsavedChanges(true);

        setWorkingHours(prevWorkingHours => {
            const newWorkingHours = {
                ...prevWorkingHours,
                from: newFrom,
            };

            debouncedSave(newWorkingHours);

            return newWorkingHours;
        });
    };

    const handleToChange = newTo => {
        setHasUnsavedChanges(true);

        setWorkingHours(prevWorkingHours => {
            const newWorkingHours = {
                ...prevWorkingHours,
                to: newTo,
            };

            debouncedSave(newWorkingHours);

            return newWorkingHours;
        });
    };

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth <= 540;

    const fixedSelectProps = {};

    if (isInsideDrawer && !isMobile) {
        fixedSelectProps.menuPosition = 'fixed';
        fixedSelectProps.menuPortalTarget = document.body;
    }

    return (
        <Wrapper>
            <Select
                id="from"
                name="from"
                label="Von"
                value={workingHours.from}
                onChange={handleFromChange}
                options={hourOptions.filter(
                    option =>
                        option.value < workingHours.to.value ||
                        // When the ending time is midnight, all options are allowed,
                        // because midnight of the end
                        workingHours.to.value === 0,
                )}
                additionalStyles={{
                    control: provided => ({
                        ...provided,
                        borderRadius: '6px 0 0 6px',
                        boxShadow: 'none',
                    }),
                }}
                isSearchable={false}
                {...fixedSelectProps}
            />
            <Select
                id="to"
                name="to"
                label="Bis"
                value={workingHours.to}
                onChange={handleToChange}
                options={[
                    ...hourOptions.filter(
                        option =>
                            option.value > workingHours.from.value &&
                            // Remove the 00:00 option, as it is in the beginning of the list,
                            // but should be shown at the end of the list, representing midnight of the next day.
                            option.value !== 0,
                    ),
                    // Add the 00:00 option at the end of the list,
                    // as it will represent the midnight of the next day.
                    {
                        value: 0,
                        label: '00:00',
                    },
                ]}
                additionalStyles={{
                    control: provided => ({
                        ...provided,
                        borderRadius: '0 6px 6px 0',
                        boxShadow: 'none',
                    }),
                }}
                isSearchable={false}
                {...fixedSelectProps}
            />
        </Wrapper>
    );
};

WorkingHours.defaultProps = {
    isInsideDrawer: false,
};

WorkingHours.propTypes = {
    isInsideDrawer: PropTypes.bool,
};

export default WorkingHours;
