import api from 'Application/api/api';

const addTemplateCustomTopic = (templateId, data, cancelToken = null) =>
    api.post(
        `project-manager/templates/${templateId}/custom-topics`,
        data,
        { cancelToken },
    );

export default addTemplateCustomTopic;
