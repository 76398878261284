import api from 'Application/api/api';

const changeIntroductionComment = (
    projectId,
    introductionId,
    comment,
    cancelToken = null,
) =>
    api.post(
        `project-manager/projects/${projectId}/introductions/${introductionId}/change-comment`,
        {
            comment,
        },
        { cancelToken },
    );

export default changeIntroductionComment;
