import api from 'Application/api/api';

const unassignEmployeesFromProject = (
    projectId,
    employeeIds,
    cancelToken = null,
) =>
    api.post(
        `project-manager/projects/${projectId}/employees/unassign`,
        {
            employeeIds,
        },
        {
            cancelToken,
        },
    );

export default unassignEmployeesFromProject;
