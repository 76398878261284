const convertAmountOfHoursToHumanReadableString = amountOfHours => {
    const hours = Math.floor(amountOfHours);
    const minutes = (amountOfHours - hours) * 60;

    return `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}`;
};

export default convertAmountOfHoursToHumanReadableString;
