import { useRecoilValue } from 'recoil';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';

const useTemplateCustomTopics = () => {
    const { customTopics } = useRecoilValue(templateAtom);

    return customTopics;
};

export default useTemplateCustomTopics;
