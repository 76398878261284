import React from 'react';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';

const Wrapper = styled.div`
    margin-bottom: 2px;
    color: ${colors.WARNING};
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
`;

const GroupAssignmentLabel = () => <Wrapper>Präsenzunterweisung</Wrapper>;

export default GroupAssignmentLabel;
