import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import DatePicker from 'Common/components/Form/Fields/DatePicker';
import colors from 'Common/constants/colors';

const Wrapper = styled.div`
    display: flex;
    max-width: 270px;
    border-radius: 6px;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.16);

    > div {
        flex: 1;

        .MuiTextField-root {
            .MuiFilledInput-root {
                box-shadow: none;
            }
        }

        &:first-child {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                width: 1px;
                height: 60%;
                background: ${colors.LIGHT_GRAY};
                transform: translateY(-50%);
            }

            .MuiTextField-root {
                .MuiFilledInput-root {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }

        &:last-child {
            .MuiTextField-root {
                .MuiFilledInput-root {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }
`;

const DateRangePicker = ({
    startingDateProps,
    endingDateProps,
    startingDate,
    endingDate,
    onStartingDateChange,
    onEndingDateChange,
}) => (
    <Wrapper>
        <DatePicker
            id="startingDate"
            name="startingDate"
            label="Startdatum"
            value={startingDate}
            onChange={onStartingDateChange}
            startDate={startingDate}
            endDate={endingDate}
            maxDate={endingDate}
            selectsStart
            {...startingDateProps}
        />
        <DatePicker
            id="endingDate"
            name="endingDate"
            label="Enddatum"
            value={endingDate}
            onChange={onEndingDateChange}
            startDate={startingDate}
            endDate={endingDate}
            minDate={startingDate}
            selectsEnd
            {...endingDateProps}
        />
    </Wrapper>
);

DateRangePicker.defaultProps = {
    startingDateProps: {},
    endingDateProps: {},
    startingDate: null,
    endingDate: null,
};

DateRangePicker.propTypes = {
    startingDateProps: PropTypes.object,
    endingDateProps: PropTypes.object,
    startingDate: PropTypes.object,
    endingDate: PropTypes.object,
    onStartingDateChange: PropTypes.func.isRequired,
    onEndingDateChange: PropTypes.func.isRequired,
};

export default DateRangePicker;
