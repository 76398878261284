import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { get } from 'lodash';
import {
    AddToPhotosRounded,
    ArrowDownwardRounded,
    ArrowUpwardRounded,
    DeleteRounded,
} from '@material-ui/icons';
import Textarea from 'Common/components/Form/Fields/Textarea';
import Answers from 'Education/components/instruction/test-edit/Answers';
import QuestionImage from 'Education/components/instruction/test-edit/QuestionImage';
import InnerBox from 'Common/components/Boxes/InnerBox';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';

const Wrapper = styled(InnerBox)`
    padding: 10px;
    margin-bottom: 30px;
`;

const QuestionTopBar = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const QuestionNumber = styled.div`
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    color: ${colors.DIRTY_WHITE};
`;

const QuestionInfo = styled.div`
    margin-bottom: 20px;

    @media screen and (min-width: 680px) {
        display: flex;
    }
`;

const QuestionTitle = styled.div`
    @media screen and (min-width: 680px) {
        flex: 1;
        order: 1;
        margin-right: 10px;
    }
`;

const QuestionTitleTextarea = styled(Textarea)`
    textarea {
        min-height: 120px;
    }
`;

const QuestionImageWrapper = styled.div`
    height: 160px;
    margin-bottom: 10px;

    @media screen and (min-width: 680px) {
        height: auto;
        margin-bottom: 0;
        flex: 0 0 200px;
        order: 2;
    }
`;

const ActionButton = styled.button`
    flex: 0 0 15px;
    margin-left: 7px;
    padding: 0 3px;
    background: none;
    border: 0 none;
    color: ${colors.GRAY};
    cursor: pointer;

    &:hover,
    &:focus {
        outline: 0 none;
        color: ${colors.WHITE};
    }
`;

const Question = ({
    questionValues,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    number,
    isFirst,
    isLast,
    fieldPrefix,
    onImageChange,
    onMoveBackwards,
    onMoveForwards,
    onDelete,
    onAnswerAdd,
    onAnswerDelete,
}) => (
    <Wrapper>
        <QuestionTopBar>
            <QuestionNumber>{`Frage ${number}`}</QuestionNumber>

            {!isFirst && (
                <ActionButton onClick={onMoveBackwards}>
                    <ArrowUpwardRounded />
                </ActionButton>
            )}
            {!isLast && (
                <ActionButton onClick={onMoveForwards}>
                    <ArrowDownwardRounded />
                </ActionButton>
            )}
            <ActionButton onClick={onDelete}>
                <DeleteRounded />
            </ActionButton>
        </QuestionTopBar>
        <div>
            <QuestionInfo>
                <QuestionImageWrapper>
                    <QuestionImage
                        defaultImage={questionValues.image}
                        onImageChange={onImageChange}
                    />
                </QuestionImageWrapper>
                <QuestionTitle>
                    <QuestionTitleTextarea
                        label="Frage"
                        id={`${fieldPrefix}.title`}
                        name={`${fieldPrefix}.title`}
                        error={get(errors, `${fieldPrefix}.title`)}
                        touched={get(touched, `${fieldPrefix}.title`, false)}
                        value={questionValues.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </QuestionTitle>
            </QuestionInfo>
            <Answers
                answers={questionValues.answers}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                fieldPrefix={`${fieldPrefix}.answers`}
                onAnswerDelete={onAnswerDelete}
            />
            <Button
                underline
                icon={<AddToPhotosRounded />}
                type="button"
                onClick={onAnswerAdd}
            >
                Antwort hinzufügen
            </Button>
        </div>
    </Wrapper>
);

Question.propTypes = {
    questionValues: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    number: PropTypes.number.isRequired,
    isFirst: PropTypes.bool.isRequired,
    isLast: PropTypes.bool.isRequired,
    fieldPrefix: PropTypes.string.isRequired,
    onImageChange: PropTypes.func.isRequired,
    onMoveBackwards: PropTypes.func.isRequired,
    onMoveForwards: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onAnswerAdd: PropTypes.func.isRequired,
    onAnswerDelete: PropTypes.func.isRequired,
};

export default Question;
