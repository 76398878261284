import React from 'react';
import PropTypes from 'prop-types';
import Button from 'Common/components/Button';
import { CloudUploadRounded } from '@material-ui/icons';
import useUploadQuestionFilesFunction
    from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/hooks/uploadQuestionFilesFunction/useUploadQuestionFilesFunction';

const UploadButton = ({ question, disabled }) => {
    const { isUploading, open, getInputProps } = useUploadQuestionFilesFunction(
        question.id,
        question.isCustom,
    );

    return (
        <>
            <input {...getInputProps()} />
            <Button
                underline
                icon={<CloudUploadRounded />}
                type="button"
                isLoading={isUploading}
                disabled={disabled || isUploading}
                onClick={open}
            >
                Upload
            </Button>
        </>
    );
};

UploadButton.defaultProps = {
    disabled: false,
};

UploadButton.propTypes = {
    question: PropTypes.object.isRequired,
};

export default UploadButton;
