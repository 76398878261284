import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import updateProjectQuestionComment from 'ProjectManager/Project/Common/api/dataManagement/questionnaire/question/comment/updateProjectQuestionComment';
import axios from 'axios';
import withQuestionComment from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/withQuestionComment';

const useUpdateProjectQuestionCommentFunction = (
    questionId,
    isCustomQuestion,
) => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const updateComment = useCallback(
        async newComment => {
            await updateProjectQuestionComment(
                projectId,
                questionId,
                isCustomQuestion,
                newComment,
                source.token,
            );

            setProject(
                withQuestionComment(questionId, isCustomQuestion, newComment),
            );
        },
        [projectId, questionId, isCustomQuestion, setProject, source.token],
    );

    return updateComment;
};

export default useUpdateProjectQuestionCommentFunction;
