import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Checkbox from 'Common/components/Form/Fields/Checkbox';
import InnerBox from 'Common/components/Boxes/InnerBox';
import colors from 'Common/constants/colors';
import { Tooltip } from '@material-ui/core';
import Input from 'Common/components/Form/Fields/Input';
import { Search } from '@material-ui/icons';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
`;

const SelectedInstructionsCounter = styled.div`
    margin-bottom: 20px;

    > span {
        display: inline-block;
        margin-right: 4px;

        &:first-of-type {
            color: ${colors.DIRTY_WHITE};
        }
    }
`;

const HighlightedCount = styled.span`
    color: ${colors.PRIMARY};
`;

const ItemsList = styled(InnerBox)`
    flex: 1;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 5px 15px;
`;

const LibraryInstructionItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const SearchWrapper = styled.div`
    margin-bottom: 10px;
`;

const LibraryInstructionsList = ({
    libraryInstructions,
    selectedIds,
    onCheckboxChange,
}) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchTermChange = e => {
        setSearchTerm(e.target.value);
    };

    let filteredLibraryInstructions = [...libraryInstructions];

    if (searchTerm) {
        filteredLibraryInstructions = filteredLibraryInstructions.filter(
            libraryInstruction =>
                libraryInstruction.name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()),
        );
    }

    return (
        <Wrapper>
            <SelectedInstructionsCounter>
                <span>Bibliothek</span>
                <HighlightedCount>
                    {`(${selectedIds.length} / ${libraryInstructions.length} ausgewählt)`}
                </HighlightedCount>
            </SelectedInstructionsCounter>
            <SearchWrapper>
                <Input
                    type="search"
                    label="Stichwortsuche"
                    icon={Search}
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                />
            </SearchWrapper>
            <ItemsList>
                {filteredLibraryInstructions.map(libraryInstruction => (
                    <Tooltip
                        key={libraryInstruction.id}
                        title={
                            libraryInstruction.numberOfInstructionReferences > 0
                                ? 'Diese Unterweisung wurde bereits importiert'
                                : ''
                        }
                    >
                        <LibraryInstructionItem>
                            <Checkbox
                                label={libraryInstruction.name}
                                id={`libraryInstruction_${libraryInstruction.id}`}
                                name="libraryInstruction"
                                disabled={
                                    libraryInstruction.numberOfInstructionReferences >
                                    0
                                }
                                checked={selectedIds.includes(
                                    libraryInstruction.id,
                                )}
                                onChange={e =>
                                    onCheckboxChange(
                                        libraryInstruction.id,
                                        e.target.checked,
                                    )
                                }
                            />
                        </LibraryInstructionItem>
                    </Tooltip>
                ))}
            </ItemsList>
        </Wrapper>
    );
};

LibraryInstructionsList.propTypes = {
    libraryInstructions: PropTypes.array.isRequired,
    selectedIds: PropTypes.array.isRequired,
    onCheckboxChange: PropTypes.func.isRequired,
};

export default LibraryInstructionsList;
