import api from 'Application/api/api';

const createSingleInstructionAssignmentByInstructionId = (
    instructionId,
    cancelToken = null,
) =>
    api.post(
        `/education/instruction-assignments/single/by-instruction-id/${instructionId}`,
        {},
        { cancelToken },
    );

export default createSingleInstructionAssignmentByInstructionId;
