import api from 'Application/api/api';

const getPendingDriverLicenseControls = (load, cancelToken = null) =>
    api.get('/measures/vehicles/driver-licenses/controls/pending', {
        params: {
            load,
        },
        cancelToken,
    });

export default getPendingDriverLicenseControls;
