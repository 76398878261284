import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import LabeledValue from 'ProjectManager/Project/Preview/LabeledValue';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';
import useConductButton from 'ProjectManager/Project/Introductions/Grid/hooks/useConductButton';
import useDownloadButton from 'ProjectManager/Project/Introductions/Grid/hooks/useDownloadButton';
import useReminderButton from 'ProjectManager/Project/Introductions/Grid/hooks/useReminderButton';
import useParticipantsViewButton from 'ProjectManager/Project/Introductions/Grid/hooks/useParticipantsViewButton';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';

const ConductedStatus = styled.div`
    color: ${colors.GREEN};
`;

const WarningStatus = styled.div`
    color: ${colors.WARNING};
`;

const CanceledStatus = styled.div`
    color: ${colors.ERROR};
`;

const StyledLabeledValue = styled(LabeledValue)`
    button,
    a {
        margin-top: 10px;
    }
`;

const Status = ({ introduction }) => {
    const { status: projectStatus } = useRecoilValue(projectAtom);

    const {
        handleClick: openParticipantsViewModal,
        renderModal: renderParticipantsViewModal,
    } = useParticipantsViewButton(introduction);

    const {
        isVisible: isConductButtonVisible,
        handleClick: handleConductButtonClick,
        renderModal: renderConductModal,
    } = useConductButton(introduction, openParticipantsViewModal);

    const {
        isVisible: isDownloadButtonVisible,
        handleClick: handleDownloadButtonClick,
    } = useDownloadButton(introduction);

    const {
        isVisible: isReminderButtonVisible,
        handleClick: handleReminderButtonClick,
        renderModal: renderReminderModal,
    } = useReminderButton(introduction);

    const haveAllRelevantParticipantsConfirmed =
        introduction.participants.filter(participant => participant.isRelevant)
            .length > 0 &&
        introduction.participants
            .filter(participant => participant.isRelevant)
            .every(participant => participant.isConfirmed);

    return (
        <StyledLabeledValue label="Status">
            {introduction.isConducted ? (
                <>
                    <ConductedStatus>Durchgeführt</ConductedStatus>
                    {!haveAllRelevantParticipantsConfirmed && (
                        <WarningStatus>Nicht bestätigt</WarningStatus>
                    )}
                </>
            ) : introduction.isCanceled ? (
                <CanceledStatus>Abgesagt</CanceledStatus>
            ) : projectStatus === projectStatuses.ON_STANDBY ? (
                <WarningStatus>Nicht durchführbar</WarningStatus>
            ) : (
                <WarningStatus>Nicht durchgeführt</WarningStatus>
            )}
            {isConductButtonVisible && (
                <Button small type="button" onClick={handleConductButtonClick}>
                    Durchführen
                </Button>
            )}
            {isDownloadButtonVisible && haveAllRelevantParticipantsConfirmed && (
                <Button small type="button" onClick={handleDownloadButtonClick}>
                    Einweisungsnachweis
                </Button>
            )}
            {isReminderButtonVisible && (
                <Button small type="button" onClick={handleReminderButtonClick}>
                    Benachrichtigen
                </Button>
            )}
            {renderConductModal()}
            {renderReminderModal()}
            {renderParticipantsViewModal()}
        </StyledLabeledValue>
    );
};

Status.propTypes = {
    introduction: PropTypes.object.isRequired,
};

export default Status;
