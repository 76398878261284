import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import colors from 'Common/constants/colors';

const NumberWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1px;

    > svg {
        margin-right: 5px;
    }
`;

const Number = styled.div`
    font-size: 26px;
    line-height: 39px;
    font-weight: 600;
`;

const Text = styled.div`
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    text-align: center;
`;

const Wrapper = styled.div`
    color: ${prop('color')};

    ${ifProp(
        'small',
        css`
            ${NumberWrapper} {
                > svg {
                    font-size: 22px;
                }
            }

            ${Number} {
                font-size: 18px;
                line-height: 27px;
            }
        `,
    )};
`;

const StatisticsNumber = ({ number, text, icon, ...props }) => (
    <Wrapper {...props}>
        <NumberWrapper>
            {icon}
            <Number>{number}</Number>
        </NumberWrapper>
        <Text>{text}</Text>
    </Wrapper>
);

StatisticsNumber.defaultProps = {
    icon: undefined,
    color: colors.DIRTY_WHITE,
    small: false,
};

StatisticsNumber.propTypes = {
    number: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.any,
    color: PropTypes.string,
    small: PropTypes.bool,
};

export default StatisticsNumber;
