import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import isProjectCustomTopicNameUnique from 'ProjectManager/Project/Common/api/dataManagement/customTopic/isProjectCustomTopicNameUnique';

const useUniqueNameValidationCallbackForProject = () => {
    const { id: projectId } = useRecoilValue(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const validationCallback = useCallback(
        async (value, editedCustomTopicId = null) => {
            if (!value) {
                return true;
            }

            try {
                const response = await isProjectCustomTopicNameUnique(
                    value,
                    projectId,
                    editedCustomTopicId,
                    source.token,
                );

                return response.data.isUnique;
            } catch (error) {
                return axios.isCancel(error);
            }
        },
        [projectId, source.token],
    );

    return validationCallback;
};

export default useUniqueNameValidationCallbackForProject;
