import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import React, { useCallback, useMemo, useState } from 'react';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import PointsConfirmationModal from 'ProjectManager/Project/Introductions/Grid/components/Introduction/ConductModal/PointsConfirmationModal';
import ParticipantsConfirmationModal from 'ProjectManager/Project/Introductions/Grid/components/Introduction/ConductModal/ParticipantsConfirmationModal';
import SignatureConfirmationModal from 'ProjectManager/Project/Introductions/Grid/components/Introduction/ConductModal/SignatureConfirmationModal';
import SuccessModal from 'ProjectManager/Project/Introductions/Grid/components/Introduction/ConductModal/SuccessModal';
import { useSelector } from 'react-redux';

const steps = {
    POINTS_CONFIRMATION: 'POINTS_CONFIRMATION',
    PARTICIPANTS_CONFIRMATION: 'PARTICIPANTS_CONFIRMATION',
    SIGNATURE_CONFIRMATION: 'SIGNATURE_CONFIRMATION',
    SUCCESS: 'SUCCESS',
};

const useConductButton = (introduction, openParticipantsViewModal) => {
    const currentUserId = useSelector(state => state.user.id);
    const supervisorUserId = introduction.supervisor.user.id;

    const { status: projectStatus } = useRecoilValue(projectAtom);

    const isVisible = useMemo(
        () =>
            projectStatus === projectStatuses.ONGOING &&
            !introduction.isConducted &&
            !introduction.isCanceled &&
            currentUserId === supervisorUserId,
        [
            projectStatus,
            introduction.isConducted,
            introduction.isCanceled,
            currentUserId,
            supervisorUserId,
        ],
    );

    const [step, setStep] = useState(steps.POINTS_CONFIRMATION);

    const [
        selectedProjectParticipantIds,
        setSelectedProjectParticipantIds,
    ] = useState([]);

    const [isModalOpened, setIsModalOpened] = useState(false);

    const handleClick = () => {
        setIsModalOpened(true);
        setSelectedProjectParticipantIds(
            introduction.participants
                .filter(participant => participant.isRelevant)
                .map(participant => participant.projectParticipant.id),
        );
    };

    const closeModal = useCallback(() => {
        setIsModalOpened(false);
        setStep(steps.POINTS_CONFIRMATION);
        setSelectedProjectParticipantIds([]);
    }, []);

    const renderModal = useCallback(() => {
        if (!isModalOpened) {
            return null;
        }

        const handleSelectParticipant = projectParticipantId => {
            setSelectedProjectParticipantIds(prevIds => {
                if (prevIds.includes(projectParticipantId)) {
                    return prevIds;
                }

                return [...prevIds, projectParticipantId];
            });
        };

        const handleDeselectParticipant = projectParticipantId => {
            setSelectedProjectParticipantIds(prevIds => {
                const index = prevIds.indexOf(projectParticipantId);

                if (index === -1) {
                    return prevIds;
                }

                const newIds = [...prevIds];

                newIds.splice(index, 1);

                return newIds;
            });
        };

        const handleSelectAllParticipants = () => {
            setSelectedProjectParticipantIds(
                introduction.participants
                    .filter(participant => participant.isRelevant)
                    .map(participant => participant.projectParticipant.id),
            );
        };

        const handleDeselectAllParticipants = () => {
            setSelectedProjectParticipantIds([]);
        };

        switch (step) {
            case steps.POINTS_CONFIRMATION:
                return (
                    <PointsConfirmationModal
                        introduction={introduction}
                        onNextButtonClick={() =>
                            setStep(steps.PARTICIPANTS_CONFIRMATION)
                        }
                        onClose={closeModal}
                    />
                );

            case steps.PARTICIPANTS_CONFIRMATION:
                return (
                    <ParticipantsConfirmationModal
                        introduction={introduction}
                        selectedProjectParticipantIds={
                            selectedProjectParticipantIds
                        }
                        onSelectParticipant={handleSelectParticipant}
                        onDeselectParticipant={handleDeselectParticipant}
                        onSelectAllParticipants={handleSelectAllParticipants}
                        onDeselectAllParticipants={
                            handleDeselectAllParticipants
                        }
                        onBackButtonClick={() =>
                            setStep(steps.POINTS_CONFIRMATION)
                        }
                        onNextButtonClick={() =>
                            setStep(steps.SIGNATURE_CONFIRMATION)
                        }
                        onClose={closeModal}
                    />
                );

            case steps.SIGNATURE_CONFIRMATION:
                return (
                    <SignatureConfirmationModal
                        introduction={introduction}
                        selectedProjectParticipantIds={
                            selectedProjectParticipantIds
                        }
                        onBackButtonClick={() =>
                            setStep(steps.PARTICIPANTS_CONFIRMATION)
                        }
                        onConfirm={() => setStep(steps.SUCCESS)}
                        onClose={closeModal}
                    />
                );

            case steps.SUCCESS:
                return (
                    <SuccessModal
                        onClose={closeModal}
                        onConfirmButtonClick={() => {
                            closeModal();
                            openParticipantsViewModal();
                        }}
                    />
                );

            default:
                return null;
        }
    }, [
        closeModal,
        introduction,
        isModalOpened,
        openParticipantsViewModal,
        selectedProjectParticipantIds,
        step,
    ]);

    return {
        isVisible,
        handleClick,
        renderModal,
    };
};

export default useConductButton;
