import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import OuterBoxSubtitle from 'Common/components/Boxes/OuterBoxSubtitle';
import ContentSection from 'ProjectManager/DataManagement/Common/components/ContentSection';
import CreateSubsectionButton from 'ProjectManager/DataManagement/Questionnaire/components/SubsectionCreation/components/CreateSubsectionButton';
import colors from 'Common/constants/colors';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';
import { useWindowWidth } from '@react-hook/window-size';
import useWindowScroll from '@react-hook/window-scroll';

const StyledContentSection = styled(ContentSection)`
    ${ifProp(
        'hasOtherSubsections',
        css`
            margin-top: 10px;

            @media screen and (min-width: 1180px) {
                margin-top: 50px;
                padding-top: 50px;
                border-top: 1px solid ${colors.DARK_DIVIDER};
                border-radius: 0;
            }
        `,
    )};
`;

const approximateStickyElementsHeight = 170;

const SubsectionCreation = ({ section }) => {
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 940;

    const scrollY = useWindowScroll();

    const handleFormOpen = () => {
        if (isMobile) {
            const distanceToAddButton = document
                .getElementById('addCustomSubsectionButtonWrapper')
                .getBoundingClientRect().top;

            // The timeout is to ensure that the state will be set
            // and the distance to the button will be calculated properly.
            setTimeout(() => {
                window.scroll({
                    top:
                        scrollY +
                        distanceToAddButton -
                        approximateStickyElementsHeight -
                        15, // Ensure to have some spacing at the top
                    left: 0,
                    behavior: 'smooth',
                });
            }, 5);
        }
    };

    return (
        <StyledContentSection
            hasOtherSubsections={section.subsections.length > 0}
        >
            <OuterBoxTitle>
                Benötigst du eine neue Untersektion? Dann kannst du diese hier
                erstellen.
            </OuterBoxTitle>
            <OuterBoxSubtitle>
                Mit der Erstellung einer Untersektion bist du im Stande eigene
                Fragen zum Projekt hinzuzufügen. Derzeit gibt es keine eigene
                Untersektion in diesem Bereich.
            </OuterBoxSubtitle>
            <div id="addCustomSubsectionButtonWrapper">
                <CreateSubsectionButton
                    section={section}
                    onFormOpen={handleFormOpen}
                />
            </div>
        </StyledContentSection>
    );
};

SubsectionCreation.propTypes = {
    section: PropTypes.object.isRequired,
};

export default SubsectionCreation;
