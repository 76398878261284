import React from 'react';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { WarningRounded } from '@material-ui/icons';

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    margin-top: 5px;
    color: ${colors.WARNING};
    font-size: 14px;
    line-height: 21px;

    svg {
        position: relative;
        margin-right: 5px;
        font-size: 19px !important;
    }
`;

const WarningMessage = ({ children, ...props }) => (
    <Wrapper {...props}>
        <WarningRounded />
        {children}
    </Wrapper>
);

export default WarningMessage;
