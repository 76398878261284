import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import axios from 'axios';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import removeProjectCoordinator from 'ProjectManager/Project/Common/api/dataManagement/coordinator/removeProjectCoordinator';
import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import withoutCoordinator from 'ProjectManager/Project/Common/recoil/project/modifiers/coordinator/withoutCoordinator';

const ProjectCoordinatorDeleteModal = ({
    projectCoordinator,
    onCancel,
    onSuccess,
}) => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const [isDeleting, setIsDeleting] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirm = async () => {
        try {
            setIsDeleting(true);

            await removeProjectCoordinator(
                projectId,
                projectCoordinator.id,
                source.token,
            );

            setProject(withoutCoordinator(projectCoordinator.id));

            onSuccess();

            setIsDeleting(false);

            notify('Der SiGeKo wurde erfolgreich gelöscht', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsDeleting(false);

                notify('Der SiGeKo konnte nicht gelöscht werden', {
                    type: toast.TYPE.ERROR,
                });
            }
        }
    };

    return (
        <Modal
            isOpen
            onRequestClose={isDeleting ? undefined : onCancel}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onCancel}
                        disabled={isDeleting}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isDeleting}
                        onClick={handleConfirm}
                        disabled={isDeleting}
                    >
                        Löschen
                    </Button>
                </>
            }
        >
            <ModalTitle>
                Wollen Sie den SiGeKo {projectCoordinator.name} wirklich
                löschen?
            </ModalTitle>
        </Modal>
    );
};

ProjectCoordinatorDeleteModal.propTypes = {
    projectCoordinator: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default ProjectCoordinatorDeleteModal;
