import React, { useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import AsyncSelect from 'Common/components/Form/Fields/AsyncSelect';
import loadOptionsOfUsersWithSupervisorPermissions from 'ProjectManager/Common/helpers/loadOptionsOfUsersWithSupervisorPermissions';
import mapUsersToOptions from 'User/utils/mapUsersToOptions';
import { debounce } from 'lodash';
import hasUnsavedChangesState from 'Common/recoil/hasUnsavedChangesState';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import { useSelector } from 'react-redux';
import roles from 'User/constants/roles';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import colors from 'Common/constants/colors';
import useSupervisorsData from 'ProjectManager/DataManagement/Supervisors/hooks/useSupervisorsData';

const SelectSecondaryLabel = styled.span`
    color: ${colors.LIGHT_GRAY};
`;

const SupervisorsField = props => {
    const {
        selectedSupervisorUsers,
        updateSupervisors,
        isRequired,
    } = useSupervisorsData();

    const [isTouched, setIsTouched] = useState(false);

    const currentUserId = useSelector(state => state.user.id);
    const currentUserRole = useSelector(state => state.user.role);
    const currentUserName = useSelector(
        state => `${state.user.lastName}, ${state.user.firstName}`,
    );

    const [selectedOptions, setSelectedOptions] = useState(() =>
        mapUsersToOptions(selectedSupervisorUsers),
    );

    const [, setHasUnsavedChanges] = useRecoilState(hasUnsavedChangesState);

    const debouncedSave = useCallback(
        debounce(
            async userIds => {
                try {
                    await updateSupervisors(userIds);
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        notify(
                            'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                            {
                                type: toast.TYPE.ERROR,
                            },
                        );
                    }
                }

                setHasUnsavedChanges(false);
            },
            350,
            {
                leading: true,
                trailing: true,
            },
        ),
        [updateSupervisors, setHasUnsavedChanges],
    );

    const handleChange = options => {
        setIsTouched(true);
        setHasUnsavedChanges(true);

        const newOptions = options ?? [];

        // When the current user is an employee, by clearing the values,
        // he should not be able to remove himself. So, we need to re-add him
        // after he has cleared the values from the field..
        if (newOptions.length === 0 && currentUserRole === roles.EMPLOYEE) {
            newOptions.push({
                value: currentUserId,
                label: `${currentUserName}`,
            });
        }

        setSelectedOptions(newOptions);

        const newUserIds = newOptions.map(option => option.value);

        debouncedSave(newUserIds);
    };

    return (
        <AsyncSelect
            id="supervisorUsers"
            name="supervisorUsers"
            label="Ausgewählte Personen"
            value={selectedOptions}
            onChange={handleChange}
            loadOptions={loadOptionsOfUsersWithSupervisorPermissions}
            isMulti
            isSearchable
            isRequired={isRequired}
            touched={isTouched}
            error={
                isRequired && selectedOptions.length === 0
                    ? 'Pflichtangabe'
                    : undefined
            }
            formatOptionLabel={({ label, isManager }) => (
                <>
                    {label}
                    {isManager && (
                        <>
                            {' '}
                            <SelectSecondaryLabel>
                                (Manager)
                            </SelectSecondaryLabel>
                        </>
                    )}
                </>
            )}
            isClearable={
                // The manager can always clear the options,
                // but an employee can only clear the options when there are more than 1
                // selected, because when it's only one option, then this is the supervisor
                // himself and he is not able to un-assign himself from the project.
                currentUserRole === roles.MANAGER || selectedOptions.length > 1
            }
            isOptionDisabled={
                // When the current user is an employee, he must not
                // be able to un-assign himself from the list of supervisors.
                option =>
                    currentUserRole === roles.EMPLOYEE &&
                    option.value === currentUserId
            }
            {...props}
        />
    );
};

export default SupervisorsField;
