import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Filter from 'Common/components/Filters/Filter/Filter';
import projectStatusesElements from 'ProjectManager/Project/Common/constants/projectStatusesElements';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import Status from 'ProjectManager/Project/List/components/Overview/ProjectsTable/Toolbar/ProjectFilters/Filters/Statuses/Status';
import colors from 'Common/constants/colors';

const List = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    > button {
        position: relative;
        flex: 1;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: calc(100% - 35px);
            background: ${colors.LIGHT_DIVIDER};
            transform: translateY(-50%);
        }

        @media screen and (max-width: 549px) {
            min-width: 50%;

            &:nth-child(2n) {
                &:after {
                    display: none;
                }
            }
        }

        @media screen and (min-width: 550px) {
            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
`;

const Statuses = ({ value, setFilters }) => {
    const handleToggle = status => {
        setFilters(prevFilters => {
            const newStatuses = [...prevFilters.statuses];

            if (newStatuses.includes(status)) {
                newStatuses.splice(newStatuses.indexOf(status), 1);
            } else {
                newStatuses.push(status);
            }

            return {
                ...prevFilters,
                statuses: newStatuses,
            };
        });
    };

    return (
        <Filter title="Status" isCollapsible={false}>
            <List>
                {Object.keys(projectStatuses).map(status => {
                    const statusElements = projectStatusesElements[status];
                    const Icon = statusElements.icon;

                    const isSelected = value.includes(status);

                    return (
                        <Status
                            key={status}
                            icon={<Icon />}
                            text={statusElements.text}
                            color={statusElements.color}
                            isSelected={isSelected}
                            isDisabled={isSelected && value.length === 1}
                            onToggle={() => handleToggle(status)}
                        />
                    );
                })}
            </List>
        </Filter>
    );
};

Statuses.propTypes = {
    value: PropTypes.array.isRequired,
    setFilters: PropTypes.func.isRequired,
};

export default Statuses;
