const isEmployeeMatched = (employee, searchTerm) => {
    if (!searchTerm) {
        return true;
    }

    const searchTerms = searchTerm
        .toLowerCase()
        .split(' ')
        .filter(term => Boolean(term));

    const firstName = employee.firstName.toLowerCase();
    const isFirstNameMatched =
        searchTerms.findIndex(term => firstName.startsWith(term)) !== -1;

    const lastName = employee.lastName.toLowerCase();
    const isLastNameMatched =
        searchTerms.findIndex(term => lastName.startsWith(term)) !== -1;

    return searchTerms.length > 1
        ? isFirstNameMatched && isLastNameMatched
        : isFirstNameMatched || isLastNameMatched;
};

export default isEmployeeMatched;
