import styled from 'styled-components/macro';

const OuterBoxButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-right: -10px;

    > * {
        margin: 10px 10px 0 0;
    }
`;

export default OuterBoxButtons;
