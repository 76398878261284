import isQuestionAnswered from 'ProjectManager/DataManagement/Questionnaire/helpers/isQuestionAnswered';

const withUpdatedCompletedStatusesInQuestionnaire = prevProjectOrTemplate => ({
    ...prevProjectOrTemplate,
    questionnaireSections: prevProjectOrTemplate.questionnaireSections.map(section => {
        const subsections = section.subsections.map(subsection => {
            const isSubsectionCompleted = subsection.questions.every(question =>
                isQuestionAnswered(question),
            );

            // Small optimization that will return the original subsection object
            // if there is no change in its "isCompleted" status.
            if (isSubsectionCompleted === subsection.isCompleted) {
                return subsection;
            }

            return {
                ...subsection,
                isCompleted: isSubsectionCompleted,
            };
        });

        return {
            ...section,
            subsections,
            isCompleted: subsections.every(
                subsection => subsection.isCompleted,
            ),
        };
    }),
});

export default withUpdatedCompletedStatusesInQuestionnaire;
