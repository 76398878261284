import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import { useRecoilState } from 'recoil';
import ppesAtom from 'ProjectManager/Project/Introductions/Create/recoil/ppes/ppesAtom';
import ModalOption from 'Common/components/Modals/ModalOption';
import styled from 'styled-components/macro';
import withPPE from 'ProjectManager/Project/Introductions/Create/recoil/ppes/modifiers/withPPE';
import withoutPPE from 'ProjectManager/Project/Introductions/Create/recoil/ppes/modifiers/withoutPPE';
import ppeIcons from 'ProjectManager/Project/Introductions/Create/constants/ppeIcons';

const Options = styled.div`
    > * {
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const PPERefineModal = ({ ppes, onClose }) => {
    const [selectedPPEs, setSelectedPPEs] = useRecoilState(ppesAtom);

    const handleToggle = (ppeValue, isChecked) => {
        if (isChecked) {
            setSelectedPPEs(withPPE(ppeValue));
        } else {
            setSelectedPPEs(withoutPPE(ppeValue));
        }
    };

    return (
        <Modal
            isOpen
            onRequestClose={onClose}
            buttons={
                <Button type="button" onClick={onClose}>
                    OK
                </Button>
            }
        >
            <ModalTitle>Auswahl der PSA</ModalTitle>
            <p>
                Die ausgehändigte PSA sollte vom Mitarbeiter vor Gebrauch einer
                Sichtkontrolle unterzogen werden
            </p>
            <Options>
                {ppes.map(ppe => {
                    const Icon = ppeIcons[ppe.value];

                    return (
                        <ModalOption
                            key={ppe.value}
                            icon={<Icon />}
                            title={ppe.label}
                            isChecked={selectedPPEs.includes(ppe.value)}
                            onToggle={isChecked =>
                                handleToggle(ppe.value, isChecked)
                            }
                            isMulti
                        />
                    );
                })}
            </Options>
        </Modal>
    );
};

PPERefineModal.propTypes = {
    ppes: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default PPERefineModal;
