import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { WarningRounded } from '@material-ui/icons';
import Select from 'Common/components/Form/Fields/Select';
import driverLicenseEmployeeExaminersLimit from 'Measure/constants/driverLicenseEmployeeExaminersLimit';
import Loader from 'Common/components/Loader';
import getEmployeeExaminersByMeasure from 'User/api/getEmployeeExaminersByMeasure';
import measures from 'Measure/constants/measures';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';
import colors from 'Common/constants/colors';
import { orderBy } from 'lodash';

const Wrapper = styled.div`
    margin-bottom: 20px;
`;

const SelectLoadingIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;

    > div {
        flex: 0 0 auto;
    }
`;

const SelectLoadingIcon = () => (
    <SelectLoadingIconWrapper>
        <Loader size={24} />
    </SelectLoadingIconWrapper>
);

const WarningMessage = styled.div`
    display: flex;
    align-items: center;
    margin-top: 5px;
    color: ${colors.WARNING};
    font-weight: 600;
    font-size: 13px;

    svg {
        font-size: 16px !important;
        margin-right: 5px;
    }
`;

const AddExaminer = ({
    driverLicenseControl,
    selectedExaminers,
    isUpdatingExaminers,
    onAdd,
}) => {
    const examinersRequest = useCallback(
        cancelToken =>
            getEmployeeExaminersByMeasure(measures.DRIVER_LICENSE, cancelToken),
        [],
    );

    const {
        data: examiners,
        loadData: loadExaminers,
        isLoading,
        hasError,
    } = useAxiosRequest(examinersRequest, []);

    if (isLoading) {
        return <Loader />;
    }

    if (hasError) {
        return <RetryLoading onRetry={loadExaminers} />;
    }

    const examinerOptions = orderBy(
        examiners
            .filter(examiner => {
                const isSameAsEmployee =
                    examiner.id === driverLicenseControl.employee.user.id;

                const isAlreadySelected =
                    selectedExaminers.findIndex(
                        option => option.value === examiner.id,
                    ) !== -1;

                return !isSameAsEmployee && !isAlreadySelected;
            })
            .map(examiner => ({
                value: examiner.id,
                label: `${examiner.lastName}, ${examiner.firstName}`,
            })),
        ['label'],
        ['asc'],
    );

    const selectComponents = isUpdatingExaminers
        ? {
              ValueContainer: SelectLoadingIcon,
          }
        : undefined;

    const isExaminersLimitReached =
        selectedExaminers.length === driverLicenseEmployeeExaminersLimit;

    return (
        <Wrapper>
            <Select
                label="Prüferauswahl"
                id="examiners"
                name="examiners"
                options={examinerOptions}
                value={null}
                onChange={onAdd}
                isDisabled={isUpdatingExaminers || isExaminersLimitReached}
                components={selectComponents}
                isSearchable
            />
            {isExaminersLimitReached && (
                <WarningMessage>
                    <WarningRounded />
                    {`Limit erreicht (${driverLicenseEmployeeExaminersLimit} Prüfer)`}
                </WarningMessage>
            )}
        </Wrapper>
    );
};

AddExaminer.defaultProps = {
    isUpdatingExaminers: false,
};

AddExaminer.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
    selectedExaminers: PropTypes.array.isRequired,
    isUpdatingExaminers: PropTypes.bool,
    onAdd: PropTypes.func.isRequired,
};

export default AddExaminer;
