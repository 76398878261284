import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'Common/components/Button';
import InnerBoxTitle from 'Common/components/Boxes/InnerBoxTitle';
import FormField from 'Common/components/Form/FormField';
import InnerBoxWithButtons from 'Common/components/Boxes/InnerBoxWithButtons';
import questionTypesButtons from 'ProjectManager/DataManagement/Questionnaire/components/QuestionCreation/constants/questionTypesButtons';
import questionTypes from 'ProjectManager/DataManagement/Questionnaire/components/Question/Answers/constants/questionTypes';
import { useFormik } from 'formik';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import axios from 'axios';
import * as Yup from 'yup';
import useCreateCustomQuestionFunction from 'ProjectManager/DataManagement/Questionnaire/components/QuestionCreation/hooks/createCustomQuestionFunction/useCreateCustomQuestionFunction';
import Answers from 'ProjectManager/DataManagement/Questionnaire/components/QuestionCreation/components/CreationWizard/Steps/Answers';
import Textarea from 'Common/components/Form/Fields/Textarea';
import styled from 'styled-components/macro';

const Type = styled.span`
    font-weight: 500;
`;

const StyledTextarea = styled(Textarea)`
    textarea {
        min-height: 60px;
    }
`;

const InformationForm = ({
    selectedType,
    subsection,
    onBackButtonClick,
    onCreate,
}) => {
    const hasAnswers = [
        questionTypes.SINGLE_CHOICE,
        questionTypes.MULTIPLE_CHOICE,
    ].includes(selectedType);

    const [isCreating, setIsCreating] = useState(false);

    const createCustomQuestion = useCreateCustomQuestionFunction(subsection.id);

    const initialValues = useMemo(() => {
        const initial = { text: '' };

        if (hasAnswers) {
            initial.answers = ['', ''];
        }

        return initial;
    }, [hasAnswers]);

    const validationSchema = useMemo(() => {
        const shape = {
            text: Yup.string().required('Pflichtangabe'),
        };

        if (hasAnswers) {
            shape.answers = Yup.array().of(
                Yup.string().unique(
                    'Antwort bereits in Verwendung',
                ).required('Pflichtangabe'),
            );
        }

        return Yup.object().shape(shape);
    }, [hasAnswers]);

    const {
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        setFieldValue,
        setFieldError,
        setFieldTouched,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async values => {
            setIsCreating(true);

            try {
                await createCustomQuestion({
                    type: selectedType,
                    ...values,
                });
                await onCreate();

                notify('Erstellung war erfolgreich', {
                    type: toast.TYPE.SUCCESS,
                });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }

            setIsCreating(false);
        },
    });

    const handleAnswerAdd = () => {
        setFieldValue('answers', [...values.answers, '']);
    };

    const handleAnswerRemove = index => {
        if (index < values.answers.length) {
            let newAnswers = [...values.answers];

            newAnswers.splice(index, 1);

            setFieldError('answers', []);
            setFieldTouched('answers', []);
            setFieldValue('answers', newAnswers, true);
        }
    };

    return (
        <InnerBoxWithButtons
            buttons={
                <>
                    <Button
                        text
                        type="button"
                        disabled={isCreating}
                        onClick={onBackButtonClick}
                    >
                        Zurück
                    </Button>
                    <Button
                        type="button"
                        disabled={isCreating || !isValid}
                        isLoading={isCreating}
                        onClick={handleSubmit}
                    >
                        Erstellen
                    </Button>
                </>
            }
        >
            <InnerBoxTitle>
                Neue Frage -&nbsp;
                <Type>{questionTypesButtons[selectedType].text}</Type>
            </InnerBoxTitle>
            <form onSubmit={handleSubmit}>
                <FormField>
                    <StyledTextarea
                        id="text"
                        name="text"
                        label="Frage"
                        value={values.text}
                        error={errors.text}
                        touched={touched.text}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isRequired
                    />
                </FormField>
                {hasAnswers && (
                    <Answers
                        answers={values.answers}
                        errors={errors.answers ?? []}
                        touched={touched.answers ?? []}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        onAdd={handleAnswerAdd}
                        onRemove={handleAnswerRemove}
                    />
                )}
            </form>
        </InnerBoxWithButtons>
    );
};

InformationForm.propTypes = {
    selectedType: PropTypes.string.isRequired,
    subsection: PropTypes.object.isRequired,
    onBackButtonClick: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
};

export default InformationForm;
