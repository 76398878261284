import api from 'Application/api/api';

const signInstructionAssignmentWithPassword = (
    assignmentId,
    password,
    cancelToken = null,
) =>
    api.post(
        `/education/instruction-assignments/${assignmentId}/sign/with-password`,
        {
            password,
        },
        {
            cancelToken,
        },
    );

export default signInstructionAssignmentWithPassword;
