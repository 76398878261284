const withoutCustomTopic = customTopicId => prevProjectOrTemplate => {
    const customTopics = [...prevProjectOrTemplate.customTopics];

    const index = customTopics.findIndex(
        customTopic => customTopic.id === customTopicId,
    );

    if (index !== -1) {
        customTopics.splice(index, 1);
    }

    return {
        ...prevProjectOrTemplate,
        customTopics,
    };
};

export default withoutCustomTopic;
