import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import Switch from 'Common/components/Form/Switch';
import useShowQuestionFunction from 'ProjectManager/DataManagement/Questionnaire/components/Question/ShowHideControl/hooks/showQuestionFunctions/useShowQuestionFunction';
import QuestionHidingModal from 'ProjectManager/DataManagement/Questionnaire/components/Question/ShowHideControl/components/QuestionHidingModal';

const Wrapper = styled.div``;

const ShowHideControl = ({
    question,
    disabled,
    onBeforeShow,
    onAfterShow,
    className,
}) => {
    const showQuestion = useShowQuestionFunction(
        question.id,
        question.isCustom,
    );

    const [isVisible, setIsVisible] = useState(question.isVisible);

    const [
        isQuestionHidingModalOpened,
        setIsQuestionHidingModalOpened,
    ] = useState(false);

    const closeModal = () => {
        setIsQuestionHidingModalOpened(false);
    };

    const handleHidingSuccess = () => {
        setIsVisible(false);
        closeModal();
    };

    const handleChange = async e => {
        if (e.target.checked) {
            onBeforeShow();

            setIsVisible(true);

            try {
                await showQuestion();
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setIsVisible(false);

                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }

            onAfterShow();
        } else {
            setIsQuestionHidingModalOpened(true);
        }
    };

    return (
        <>
            <Wrapper className={className}>
                <Switch
                    checked={isVisible}
                    onChange={handleChange}
                    name="checked"
                    disabled={disabled}
                />
            </Wrapper>
            {isQuestionHidingModalOpened && (
                <QuestionHidingModal
                    question={question}
                    onCancel={closeModal}
                    onSuccess={handleHidingSuccess}
                />
            )}
        </>
    );
};

ShowHideControl.defaultProps = {
    disabled: false,
};

ShowHideControl.propTypes = {
    question: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    onBeforeShow: PropTypes.func.isRequired,
    onAfterShow: PropTypes.func.isRequired,
};

export default ShowHideControl;
