import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import roles from 'User/constants/roles';
import OuterBox from 'Common/components/Boxes/OuterBox';
import { EditRounded } from '@material-ui/icons';
import Button from 'Common/components/Button';
import colors from 'Common/constants/colors';

const Wrapper = styled(OuterBox)`
    order: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 14px 20px;
`;

const EditButton = styled(Button)`
    position: absolute;
    right: 10px;

    &:hover {
        color: ${colors.PRIMARY};
    }
`;

const EmployeeTile = ({ employee }) => {
    const role = useSelector(state => state.user.role);

    return (
        <Wrapper>
            {`${employee.lastName}, ${employee.firstName}`}
            {role === roles.MANAGER && (
                <EditButton
                    iconOnly
                    icon={<EditRounded />}
                    href={`/backend/m/exec/call?emp=${employee.id}&openEdit=1`}
                />
            )}
        </Wrapper>
    );
};

EmployeeTile.propTypes = {
    employee: PropTypes.object.isRequired,
};

export default EmployeeTile;
