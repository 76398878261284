import React from 'react';
import styled from 'styled-components/macro';
import Dashes from 'ProjectManager/Project/Preview/Timeline/StatusTimeline/Dashes';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import TimelinePoint from 'ProjectManager/Project/Preview/Timeline/StatusTimeline/TimelinePoint';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import createNewDate from 'Common/utils/createNewDate';
import projectStatusesElements from 'ProjectManager/Project/Common/constants/projectStatusesElements';
import Line from 'ProjectManager/Project/Preview/Timeline/StatusTimeline/Line';
import parseDate from 'Common/utils/parseDate';
import startOfDay from 'date-fns/startOfDay';
import isSameDay from 'date-fns/isSameDay';
import addDays from 'date-fns/addDays';
import archivableAfterInDays from 'ProjectManager/Project/Common/constants/archivableAfterInDays';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;

    > div {
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const StyledTimelinePoint = styled(TimelinePoint)`
    // This is a hack to move the timeline point half the dashes/line width.
    // As the dashes/lines width is 3px, there is no other way to move the point 1.5px to the left.
    font-size: 1px;
    left: 1.5em;
    transform: translateX(-50%);
`;

const onStandby = projectStatusesElements[projectStatuses.ON_STANDBY];
const ongoing = projectStatusesElements[projectStatuses.ONGOING];
const ended = projectStatusesElements[projectStatuses.ENDED];
const archived = projectStatusesElements[projectStatuses.ARCHIVED];

const StatusTimeline = () => {
    const {
        status,
        isPrematurelyEnded,
        startingDate: rawStartingDate,
        endingDate: rawEndingDate,
        prematurelyEndedOn: rawPrematurelyEndedOn,
        archivedAt: rawArchivedAt,
    } = useRecoilValue(projectAtom);

    const today = startOfDay(createNewDate());

    const archivedAt = rawArchivedAt
        ? startOfDay(parseDate(rawArchivedAt))
        : null;
    const isArchivedDate = archivedAt !== null && isSameDay(today, archivedAt);

    const endingDate = rawEndingDate
        ? startOfDay(parseDate(rawEndingDate))
        : null;
    const isEndingDate =
        endingDate !== null && isSameDay(today, endingDate) && !isArchivedDate;

    const startingDate = startOfDay(parseDate(rawStartingDate));
    const isStartingDate = isSameDay(today, startingDate) && !isEndingDate && !isArchivedDate;

    const archivingDate = endingDate
        ? addDays(
              isPrematurelyEnded
                  ? startOfDay(parseDate(rawPrematurelyEndedOn))
                  : endingDate,
              archivableAfterInDays,
          )
        : null;

    const isArchivingDate =
        archivingDate !== null && isSameDay(today, archivingDate);

    return (
        <Wrapper>
            <Dashes />
            {status === projectStatuses.ON_STANDBY && (
                <>
                    <StyledTimelinePoint
                        title="Heute"
                        date={today}
                        color={onStandby.color}
                        icon={onStandby.icon}
                        large
                    />
                    <Line />
                </>
            )}
            <StyledTimelinePoint
                title={isStartingDate ? 'Heute' : 'Startdatum'}
                subTitle={isStartingDate ? 'Startdatum' : undefined}
                date={startingDate}
                color={ongoing.color}
                icon={ongoing.icon}
                large={isStartingDate}
            />
            <Line />
            {status === projectStatuses.ONGOING &&
                !isStartingDate &&
                !isEndingDate && (
                    <>
                        <StyledTimelinePoint
                            title="Heute"
                            date={today}
                            color={ongoing.color}
                            icon={ongoing.icon}
                            large
                        />
                        <Line />
                    </>
                )}
            <StyledTimelinePoint
                title={isEndingDate ? 'Heute' : 'Enddatum'}
                subTitle={isEndingDate ? 'Enddatum' : undefined}
                date={endingDate}
                color={ended.color}
                icon={ended.icon}
                large={isEndingDate}
            />
            {status === projectStatuses.ENDED && (
                <>
                    {!isEndingDate && !isArchivingDate && (
                        <>
                            <Line />
                            <StyledTimelinePoint
                                title="Heute"
                                date={today}
                                color={ended.color}
                                icon={ended.icon}
                                large
                            />
                        </>
                    )}
                    <Line />
                    <StyledTimelinePoint
                        title={isArchivingDate ? 'Heute' : 'Archivdatum'}
                        subTitle={isArchivingDate ? 'Archivdatum' : undefined}
                        date={archivingDate}
                        color={archived.color}
                        icon={archived.icon}
                        large={isArchivingDate}
                    />
                </>
            )}
            {status === projectStatuses.ARCHIVED && (
                <>
                    <Line />
                    <StyledTimelinePoint
                        title={isArchivedDate ? 'Heute' : 'Archivdatum'}
                        subTitle={isArchivedDate ? 'Archivdatum' : undefined}
                        date={archivedAt}
                        color={archived.color}
                        icon={archived.icon}
                        large={isArchivedDate}
                    />
                    {!isArchivedDate && (
                        <>
                            <Line />
                            <StyledTimelinePoint
                                title="Heute"
                                date={today}
                                color={archived.color}
                                icon={archived.icon}
                                large
                            />
                        </>
                    )}
                </>
            )}
            <Dashes />
        </Wrapper>
    );
};

export default StatusTimeline;
