import React from 'react';
import PropTypes from 'prop-types';
import FormField from 'Common/components/Form/FormField';
import Input from 'Common/components/Form/Fields/Input';

const preventDefault = e => {
    e.preventDefault();
};

const InstructionTestForm = ({
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
}) => (
    <form onSubmit={preventDefault}>
        <FormField>
            <Input
                type="number"
                label="Punktzahl übergeben (%)"
                id="passingScorePercentage"
                name="passingScorePercentage"
                error={errors.passingScorePercentage}
                touched={touched.passingScorePercentage}
                value={values.passingScorePercentage}
                onChange={handleChange}
                onBlur={handleBlur}
                numberProps={{
                    decimalScale: 0,
                }}
            />
        </FormField>
        <FormField>
            <Input
                type="number"
                label="Max Prüfungsversuche"
                id="maxExamAttempts"
                name="maxExamAttempts"
                error={errors.maxExamAttempts}
                touched={touched.maxExamAttempts}
                value={values.maxExamAttempts}
                onChange={handleChange}
                onBlur={handleBlur}
                numberProps={{
                    decimalScale: 0,
                }}
            />
        </FormField>
        <FormField>
            <Input
                type="number"
                label="Dauer (min)"
                id="recommendedTestDuration"
                name="recommendedTestDuration"
                error={errors.recommendedTestDuration}
                touched={touched.recommendedTestDuration}
                value={values.recommendedTestDuration}
                onChange={handleChange}
                onBlur={handleBlur}
                numberProps={{
                    decimalScale: 0,
                }}
            />
        </FormField>
    </form>
);

InstructionTestForm.propTypes = {
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
};

export default InstructionTestForm;
