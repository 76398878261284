import api from 'Application/api/api';

const isTemplateGroupNameUnique = (
    name,
    templateGroupId = null,
    cancelToken = null,
) =>
    api.get(`project-manager/template-groups/is-name-unique/${name}`, {
        params: {
            templateGroupId,
        },
        cancelToken,
    });

export default isTemplateGroupNameUnique;
