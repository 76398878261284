import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import axios from 'axios';
import deleteProjectQuestionComment
    from 'ProjectManager/Project/Common/api/dataManagement/questionnaire/question/comment/deleteProjectQuestionComment';
import withQuestionComment
    from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/withQuestionComment';

const useDeleteProjectQuestionCommentFunction = (
    questionId,
    isCustomQuestion,
) => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const deleteComment = useCallback(async () => {
        await deleteProjectQuestionComment(
            projectId,
            questionId,
            isCustomQuestion,
            source.token,
        );

        setProject(withQuestionComment(questionId, isCustomQuestion, null));
    }, [projectId, questionId, isCustomQuestion, setProject, source.token]);

    return deleteComment;
};

export default useDeleteProjectQuestionCommentFunction;
