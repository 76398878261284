import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import requestDataDownload from 'ProjectManager/Project/Common/api/downloadRequest/requestDataDownload';
import dataTypes from 'ProjectManager/Project/Preview/QuickActions/DownloadAction/constants/dataTypes';

const useRequestProjectFilesDownloadFunction = () => {
    const { id: projectId } = useRecoilValue(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const requestDownload = useCallback(async () => {
        await requestDataDownload(
            projectId,
            [dataTypes.GENERAL_FILES],
            {},
            true,
            source.token,
        );
    }, [projectId, source.token]);

    return requestDownload;
};

export default useRequestProjectFilesDownloadFunction;
