import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { ArrowForwardRounded } from '@material-ui/icons';
import Templates from 'ProjectManager/Template/List/components/Templates';
import InteractableOuterBox from 'Common/components/Boxes/InteractableOuterBox';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import Drawer from 'Common/components/Drawer';

const OpenDrawerButton = styled(InteractableOuterBox).attrs({
    as: 'button',
})`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 20px;
    border: 0 none;
    outline: 0 none;
    cursor: pointer;
`;

const StyledOuterBoxTitle = styled(OuterBoxTitle)`
    margin-bottom: 0;
`;

const MobileTemplates = () => {
    const [isOpened, setIsOpened] = useState(false);

    const handleOpen = () => {
        setIsOpened(true);
    };

    const handleClose = () => {
        setIsOpened(false);
    };

    return (
        <>
            <OpenDrawerButton type="button" onClick={handleOpen}>
                <StyledOuterBoxTitle>
                    Projektvorlage verwenden
                </StyledOuterBoxTitle>
                <ArrowForwardRounded />
            </OpenDrawerButton>
            <Drawer
                isOpened={isOpened}
                onOpen={handleOpen}
                onClose={handleClose}
                backButtonProps={{
                    title: 'Zurück zum',
                    subtitle: 'Projektübersicht',
                }}
            >
                <Templates />
            </Drawer>
        </>
    );
};

export default MobileTemplates;
