import React from 'react';
import PropTypes from 'prop-types';
import { SpeakerNotesRounded } from '@material-ui/icons';
import MenuItem from 'Common/components/MoreActionsButton/MenuItem';
import useAddQuestionCommentData from 'ProjectManager/DataManagement/Questionnaire/components/Question/Comment/hooks/addQuestionComment/useAddQuestionCommentData';

const AddCommentMenuItem = ({ question, onAdd, onClick: closeKebabMenu }) => {
    const { addComment, isLoading } = useAddQuestionCommentData(
        question.id,
        question.isCustom,
    );

    const handleClick = async e => {
        await addComment();
        await onAdd();
        await closeKebabMenu(e);
    };

    return (
        <MenuItem
            icon={<SpeakerNotesRounded />}
            isLoading={isLoading}
            disabled={isLoading || question.comment !== null}
            type="button"
            onClick={handleClick}
        >
            Kommentar
        </MenuItem>
    );
};

AddCommentMenuItem.propTypes = {
    question: PropTypes.object.isRequired,
    onAdd: PropTypes.func.isRequired,
};

export default AddCommentMenuItem;
