import React, { useEffect } from 'react';
import BackButton from 'Application/components/Header/BackButton/BackButton';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import { Helmet } from 'react-helmet-async';
import DataManagement from 'ProjectManager/DataManagement/Common/components/DataManagement';
import { navigationItems } from 'ProjectManager/DataManagement/Navigation/constants/navigation';
import { useSetRecoilState } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';

const CreateNewProject = () => {
    const setDataManagementEntity = useSetRecoilState(
        dataManagementEntityAtom,
    );

    useEffect(() => {
        setDataManagementEntity(dataManagementEntities.PROJECT);
    }, [setDataManagementEntity]);

    return (
        <>
            <Helmet>
                <title>Neues Projekt - Projektübersicht</title>
            </Helmet>
            <BackButton to="/project-manager" />
            <Breadcrumb to="/project-manager">Projektübersicht</Breadcrumb>
            <Breadcrumb isActive>Neues Projekt</Breadcrumb>
            <DataManagement
                title="Neues Projekt"
                activeNavigationItem={navigationItems.GENERAL_INFORMATION}
            />
        </>
    );
};

export default CreateNewProject;
