import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useProjectSupervisorsData from 'ProjectManager/DataManagement/Supervisors/hooks/useProjectSupervisorsData';
import useTemplateSupervisorsData from 'ProjectManager/DataManagement/Supervisors/hooks/useTemplateSupervisorsData';

const useSupervisorsData = () => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useProjectSupervisorsData();
    const dataFromTemplate = useTemplateSupervisorsData();

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return {};
};

export default useSupervisorsData;
