import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import updateTemplateCustomTopic from 'ProjectManager/Template/Common/api/dataManagement/customTopic/updateTemplateCustomTopic';
import getTemplateQuestionnaireSections from 'ProjectManager/Template/Common/api/dataManagement/questionnaire/getTemplateQuestionnaireSections';
import deleteTemplateCustomTopic from 'ProjectManager/Template/Common/api/dataManagement/customTopic/deleteTemplateCustomTopic';
import withoutCustomTopic
    from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/customTopic/withoutCustomTopic';
import withUpdatedCustomTopicData
    from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/customTopic/withUpdatedCustomTopicData';
import withQuestionnaireSections
    from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/withQuestionnaireSections';

const useTemplateCustomTopicFunctions = () => {
    const [{ id: templateId }, setTemplate] = useRecoilState(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const updateCustomTopic = useCallback(
        async (customTopicId, updatedValues) => {
            await updateTemplateCustomTopic(
                templateId,
                customTopicId,
                updatedValues,
                source.token,
            );

            const questionnaireSections = await getTemplateQuestionnaireSections(
                templateId,
                source.token,
            );

            setTemplate(
                withUpdatedCustomTopicData(customTopicId, updatedValues),
            );
            setTemplate(withQuestionnaireSections(questionnaireSections));
        },
        [templateId, setTemplate, source.token],
    );

    const deleteCustomTopic = useCallback(
        async deletedCustomTopicId => {
            await deleteTemplateCustomTopic(
                templateId,
                deletedCustomTopicId,
                source.token,
            );

            const questionnaireSections = await getTemplateQuestionnaireSections(
                templateId,
                source.token,
            );

            setTemplate(withoutCustomTopic(deletedCustomTopicId));
            setTemplate(withQuestionnaireSections(questionnaireSections));
        },
        [templateId, setTemplate, source.token],
    );

    return { updateCustomTopic, deleteCustomTopic };
};

export default useTemplateCustomTopicFunctions;
