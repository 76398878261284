import { useEffect, useState } from 'react';
import colors from 'Common/constants/colors';

const baseStyles = {
    // Containers
    container: provided => ({
        ...provided,
        width: '100%',
        color: colors.DARK_GRAY,
    }),
    control: (provided, state) => ({
        ...provided,
        minHeight: 54,
        paddingLeft: 10,
        outline: '0 none',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: 'transparent',
        borderRadius: 6,
        boxShadow: '0 3px 7px rgba(0, 0, 0, 0.16)',
        backgroundColor: state.isDisabled ? colors.LIGHTER_GRAY : colors.WHITE,
        fontSize: '15px',
        letterSpacing: '0.00938em',
        cursor:
            state.isFocused && state.selectProps.isSearchable
                ? 'text'
                : 'pointer',
        '.react-select-selected-multi-value': {
            display: state.menuIsOpen && state.hasValue ? 'none' : 'block',
        },
        ':hover': {
            borderColor: 'transparent',
        },
    }),

    // Field
    valueContainer: provided => ({
        ...provided,
        alignSelf: 'flex-end',
        flexWrap: 'nowrap',
        paddingLeft: 0,
        marginBottom: 4,
    }),
    singleValue: provided => ({
        ...provided,
        color: colors.DARK_GRAY,
        fontSize: '15px',
        lineHeight: '21px',
        letterSpacing: '0.00938em',
    }),
    input: provided => ({
        ...provided,
        padding: 0,
        color: colors.DARK_GRAY,
        '> div > input': {
            height: '18px',
            boxShadow: 'none !important',
            fontSize: '15px',
            letterSpacing: '0.00938em',
        },
        lineHeight: '15px',
    }),

    // Indicators
    dropdownIndicator: provided => ({
        ...provided,
        color: colors.DARK_GRAY,
        cursor: 'pointer',
        ':hover': {
            color: colors.DARK_GRAY,
        },
    }),
    clearIndicator: provided => ({
        ...provided,
        color: colors.DARK_GRAY,
        cursor: 'pointer',
        ':hover': {
            color: colors.DARK_GRAY,
        },
    }),

    // Drop-down
    menuPortal: provided => ({
        ...provided,
        zIndex: 99999,
    }),
    menu: provided => ({
        ...provided,
        color: colors.DARK_GRAY,
        zIndex: 99999,
        overflow: 'hidden',
        marginTop: 5,
        marginBottom: 5,
        backgroundColor: colors.WHITE,
        borderRadius: 6,
        boxShadow: '7px 7px 10px rgba(0,0,0,0.4)',
    }),
    menuList: (provided, state) => ({
        ...provided,
        // Subtract 45 pixels for the selected values of a multi select with values
        maxHeight:
            state.isMulti && state.hasValue
                ? provided.maxHeight - 45
                : provided.maxHeight,
        paddingTop: 0,
        paddingBottom: 0,
        '::-webkit-scrollbar-track-piece': {
            backgroundColor: colors.DIRTY_WHITE,
        },
        '::-webkit-scrollbar-thumb': {
            backgroundColor: colors.LIGHT_GRAY,
        },
    }),
    option: (provided, state) => {
        let backgroundColor = provided.backgroundColor;
        let color = provided.color;

        if (state.isSelected) {
            backgroundColor = state.isMulti
                ? 'transparent'
                : colors.LIGHT_DIVIDER;
            color = 'inherit';
        }

        if (state.isFocused) {
            backgroundColor = colors.DIRTY_WHITE;
        }

        const multiSelectProps = {};

        if (state.isMulti) {
            multiSelectProps.display = 'flex';
            multiSelectProps.alignItems = 'flex-start';
        }

        if (state.isDisabled) {
            multiSelectProps.opacity = 0.3;
        } else {
            multiSelectProps[':active'] = {
                backgroundColor: colors.LIGHTER_GRAY,
            };
        }

        return {
            ...provided,
            ...multiSelectProps,
            backgroundColor,
            color,
            fontSize: '14px',
            lineHeight: '21px',
            letterSpacing: '0.00938em',
            cursor: state.isDisabled ? 'default' : 'pointer',
            overflowWrap: 'break-word',
        };
    },

    // Messages
    noOptionsMessage: provided => ({
        ...provided,
        color: colors.GRAY,
    }),
    loadingMessage: provided => ({
        ...provided,
        color: colors.GRAY,
    }),

    // Hidden elements
    indicatorSeparator: provided => ({
        ...provided,
        display: 'none',
    }),
    placeholder: provided => ({
        ...provided,
        display: 'none',
    }),
};

const generateMergedStyles = additionalStyles => {
    const customStyles = { ...baseStyles };

    // Merge the base styles with the additional ones
    if (additionalStyles && Object.keys(additionalStyles).length > 0) {
        Object.keys(additionalStyles).forEach(key => {
            const customStyle = customStyles[key];
            const additionalStyle = additionalStyles[key];

            customStyles[key] = (provided, state) => {
                const evaluatedCustomStyle = customStyle
                    ? customStyle(provided, state)
                    : {};

                return additionalStyle(evaluatedCustomStyle, state);
            };
        });
    }

    return customStyles;
};

const useReactSelectCustomStyles = (hasError, additionalStyles) => {
    const [customStyles, setCustomStyles] = useState(() =>
        generateMergedStyles(additionalStyles),
    );

    // Add styles for the border when there is an error
    useEffect(() => {
        if (hasError) {
            setCustomStyles(prevCustomStyles => ({
                ...prevCustomStyles,
                control: (provided, state) => ({
                    ...prevCustomStyles.control(provided, state),
                    borderColor: colors.ERROR,
                    ':hover': {
                        borderColor: colors.ERROR,
                    },
                }),
            }));
        } else {
            setCustomStyles(generateMergedStyles(additionalStyles));
        }
    }, [additionalStyles, hasError]);

    return customStyles;
};

export default useReactSelectCustomStyles;
