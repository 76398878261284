import React from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import ReactModal from 'react-modal';
import colors from 'Common/constants/colors';
import Loader from 'Common/components/Loader';
import { CloseRounded } from '@material-ui/icons';
import Button from 'Common/components/Button';
import useWindowSize from '@react-hook/window-size';

if (document.getElementsByClassName('react-component').length > 0) {
    ReactModal.setAppElement('.react-component');
} else if (document.getElementById('root')) {
    ReactModal.setAppElement('#root');
}

const StyledLoader = styled(Loader)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ContentWithButtons = styled.div``;

const ContentWrapper = styled.div`
    padding: 20px;

    ${ifProp(
        'withCloseButton',
        css`
            padding-right: 48px;
        `,
    )};
`;

const ButtonsWrapper = styled.div`
    position: sticky;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: 5px 5px 15px 15px;
    background: ${colors.WHITE};
    box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.16);
    z-index: 1;

    > * {
        margin: 10px 10px 0 0;
    }
`;

const CloseButtonWrapper = styled.div`
    position: sticky;
    display: flex;
    justify-content: flex-end;
    top: 0;
`;

const CloseButton = styled(Button)`
    position: absolute;
    top: 0;
    right: 0;

    svg {
        font-size: 32px !important;
    }
`;

// Prevent the onMouseDown prop to be passed to the overlay,
// as internally it calls event.preventDefault. This makes any other possible
// elements inside the modal, like react-select, to not work properly.
// eslint-disable-next-line
const NonClickableOverlay = ({ onMouseDown, ...props }, contentEl) => (
    <div {...props}>{contentEl}</div>
);

const ReactModalAdapter = ({
    className,
    modalClassName,
    isLoading,
    withCloseButton = true,
    contentStyles = {},
    buttons,
    children,
    ...props
}) => {
    const shouldCloseOnOverlayClick = props.shouldCloseOnOverlayClick ?? false;

    const [, windowHeight] = useWindowSize();

    const classNames = [modalClassName];

    if (props.maxHeight > windowHeight - 20) {
        classNames.push('is-full-height');
    }

    return (
        <ReactModal
            className={classNames.join(' ')}
            portalClassName={className}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            overlayElement={
                shouldCloseOnOverlayClick ? undefined : NonClickableOverlay
            }
            {...props}
        >
            {isLoading ? (
                <StyledLoader />
            ) : (
                <>
                    {withCloseButton && (
                        <CloseButtonWrapper>
                            <CloseButton
                                iconOnly
                                dark
                                icon={<CloseRounded />}
                                type="button"
                                onClick={props.onRequestClose}
                            />
                        </CloseButtonWrapper>
                    )}
                    {buttons ? (
                        <ContentWithButtons>
                            <ContentWrapper
                                withCloseButton={withCloseButton}
                                style={contentStyles}
                            >
                                {children}
                            </ContentWrapper>
                            <ButtonsWrapper>{buttons}</ButtonsWrapper>
                        </ContentWithButtons>
                    ) : (
                        children
                    )}
                </>
            )}
        </ReactModal>
    );
};

const Modal = styled(ReactModalAdapter).attrs({
    modalClassName: 'Modal',
})`
    .ReactModal__Overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        z-index: 9999;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.55) !important;

        ${ifProp(
            'noTransition',
            css``,
            css`
                transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
            `,
        )};

        &--after-open {
            opacity: 1;
        }

        &--before-close {
            opacity: 0;
        }
    }

    .ReactModal__Content {
        position: relative;
        display: block;
        z-index: 10000;
        width: 100%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.4);
        border-radius: 6px;
        font-size: 14px;
        line-height: 21px;
        color: ${colors.DARK_GRAY};

        ::-webkit-scrollbar-track-piece {
            background-color: ${colors.DIRTY_WHITE};
        }

        ::-webkit-scrollbar-thumb {
            background-color: ${colors.LIGHT_GRAY};
        }

        p {
            margin: 0 0 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ${ifProp(
            'maxWidth',
            css`
                max-width: ${prop('maxWidth')}px;
            `,
            css`
                max-width: 500px;
            `,
        )};

        ${ifProp(
            'maxHeight',
            css`
                max-height: ${prop('maxHeight')}px;

                &.is-full-height {
                    max-height: 100%;
                }
            `,
            css`
                max-height: 100%;
            `,
        )};

        ${ifProp(
            'buttons',
            css`
                padding: 0;
            `,
            css`
                padding: 20px 40px 20px 20px;
            `,
        )};

        &:focus {
            outline: 0 none;
        }

        ${ifProp(
            'isLoading',
            css`
                height: 100%;
                background: none;
                border: 0 none;
                box-shadow: none;
            `,
            css`
                background: ${colors.DIRTY_WHITE};
            `,
        )}
    }

    @media screen and (min-width: 520px) {
        .ReactModal__Overlay {
            padding: 30px;
        }
    }
`;

export default Modal;
