import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import colors from 'Common/constants/colors';
import { SwipeableDrawer } from '@material-ui/core';
import ContentSection from 'ProjectManager/DataManagement/Common/components/ContentSection';
import { isMobile } from 'react-device-detect';
import { ifProp } from 'styled-tools';
import BackButton from 'Common/components/BackButton';
import InnerBoxButtons from 'Common/components/Boxes/InnerBoxButtons';

const Wrapper = styled(SwipeableDrawer)`
    &.MuiDrawer-root {
        z-index: 9999 !important;
    }

    .MuiPaper-root {
        display: block;
        top: 10px;
        width: calc(100% - 10px);
        max-width: 1010px;
        height: calc(100% - 20px);
        padding-bottom: 2px; // Add small padding because of difference between font's render and computed height
        border-radius: 6px 0 0 6px;
        box-shadow: 0 7px 10px rgba(0, 0, 0, 0.16);
        background: ${colors.OUTER_BOX_BACKGROUND};

        @media screen and (min-width: 541px) {
            height: auto;
            max-height: calc(100vh - 20px);
        }

        @media screen and (min-width: 768px) {
            top: 140px;
            max-height: calc(100vh - 150px);
        }
    }

    @media screen and (max-width: 1179px) {
        ${ContentSection} {
            padding: 0;
            box-shadow: none;
        }
    }
`;

const BottomButtons = styled(InnerBoxButtons)`
    &:empty {
        display: none;
    }
`;

const Content = styled.div`
    min-height: calc(100% - 168px);
    padding: 20px;

    @media screen and (min-width: 541px) {
        min-height: 320px;
    }

    // Fix for iOS devices' bottom bar
    ${ifProp(
        'isMobile',
        css`
            @media screen and (max-width: 540px) {
                padding-bottom: 90px;
            }
        `,
    )};
`;

const Drawer = ({
    isOpened,
    onOpen,
    onClose,
    backButtonProps,
    children,
    ...props
}) => (
    <Wrapper
        anchor="right"
        disableDiscovery
        disableSwipeToOpen
        disableEnforceFocus
        open={isOpened}
        onOpen={onOpen}
        onClose={onClose}
        {...props}
    >
        <BackButton
            type="button"
            onClick={onClose}
            isSticky
            {...backButtonProps}
        />
        <Content isMobile={isMobile}>{children}</Content>
        <BottomButtons id="drawer-bottom-buttons" />
    </Wrapper>
);

Drawer.propTypes = {
    backButtonProps: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        isSticky: PropTypes.bool,
    }).isRequired,
    isOpened: PropTypes.bool.isRequired,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Drawer;
