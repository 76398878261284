import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Textarea from 'Common/components/Form/Fields/Textarea';
import { useRecoilState } from 'recoil';
import hasUnsavedChangesState from 'Common/recoil/hasUnsavedChangesState';
import axios from 'axios';
import { debounce } from 'lodash';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import DeleteCommentButton from 'ProjectManager/DataManagement/Questionnaire/components/Question/Comment/components/DeleteCommentButton';
import styled from 'styled-components/macro';
import { useWindowWidth } from '@react-hook/window-size';
import { ifProp } from 'styled-tools';
import useUpdateQuestionCommentFunction from 'ProjectManager/DataManagement/Questionnaire/components/Question/Comment/hooks/updateQuestionCommentFunction/useUpdateQuestionCommentFunction';

const Wrapper = styled.div`
    max-width: 520px;
    margin: 20px 0;

    &:last-child {
        margin-bottom: 0;
    }
`;

const StyledTextarea = styled(Textarea)`
    textarea {
        min-height: initial;
        height: ${ifProp('$compact', 16, 48)}px !important;
        overflow: ${ifProp('$compact', 'hidden', 'auto')} !important;
        resize: none;
        transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1),
            height 200ms cubic-bezier(0, 0, 0.2, 1) !important;
    }
`;

const StyledDeleteCommentButton = styled(DeleteCommentButton)`
    padding-left: 0;
`;

const Comment = ({ question }) => {
    const updateComment = useUpdateQuestionCommentFunction(
        question.id,
        question.isCustom,
    );

    const [comment, setComment] = useState(question.comment);

    const [, setHasUnsavedChanges] = useRecoilState(hasUnsavedChangesState);

    const debouncedSave = useCallback(
        debounce(
            async newComment => {
                try {
                    await updateComment(newComment);
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        notify(
                            'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                            {
                                type: toast.TYPE.ERROR,
                            },
                        );
                    }
                }

                setHasUnsavedChanges(false);
            },
            350,
            {
                leading: true,
                trailing: true,
            },
        ),
        [updateComment, setHasUnsavedChanges],
    );

    const handleChange = e => {
        setHasUnsavedChanges(true);
        setComment(e.target.value);
        debouncedSave(e.target.value);
    };

    const [isMobileFiledCompact, setIsMobileFiledCompact] = useState(true);

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 940;

    const handleFocus = () => {
        if (isMobile) {
            setIsMobileFiledCompact(false);
        }
    };

    const handleBlur = () => {
        if (isMobile) {
            setIsMobileFiledCompact(true);
        }
    };

    const isFieldCompact = isMobile ? isMobileFiledCompact : false;

    return (
        <Wrapper>
            <StyledTextarea
                id="comment"
                name="comment"
                label="Kommentar"
                onChange={handleChange}
                value={comment}
                onFocus={handleFocus}
                onBlur={handleBlur}
                $compact={isFieldCompact}
            />
            <StyledDeleteCommentButton question={question} />
        </Wrapper>
    );
};

Comment.propTypes = {
    question: PropTypes.object.isRequired,
};

export default Comment;
