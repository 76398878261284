import React from 'react';
import { StickyTabList, Tab, TabPanel, Tabs } from 'Common/components/tabs';
import InstructionListTab from 'Education/components/instruction/InstructionListTab';
import InstructionAreaListTab from 'Education/components/instruction-area/InstructionAreaListTab';
import GroupInstructionAssignmentsListTab from 'Education/components/instruction-assignment/group/GroupInstructionAssignmentsListTab';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import { Helmet } from 'react-helmet-async';
import useHash from 'Common/hooks/useHash';
import withRestrictionCheckDuringArchivingProcess from 'Education/components/common/withRestrictionCheckDuringArchivingProcess';
import BackButton from 'Application/components/Header/BackButton/BackButton';

const ManagerInstructionsHomepage = () => {
    const [tabIndex, setTabIndex] = useHash();

    return (
        <>
            <Helmet>
                <title>Unterweisungen</title>
            </Helmet>
            <BackButton to="/dashboard" />
            <Breadcrumb isActive>Unterweisungen</Breadcrumb>
            <Tabs selectedIndex={tabIndex} onSelect={setTabIndex}>
                <StickyTabList>
                    <Tab>Unterweisungen</Tab>
                    <Tab>Präsenzunterweisung</Tab>
                    <Tab>Bereiche</Tab>
                </StickyTabList>

                <TabPanel>
                    <InstructionListTab />
                </TabPanel>
                <TabPanel>
                    <GroupInstructionAssignmentsListTab />
                </TabPanel>
                <TabPanel>
                    <InstructionAreaListTab />
                </TabPanel>
            </Tabs>
        </>
    );
};

export default withRestrictionCheckDuringArchivingProcess(
    ManagerInstructionsHomepage,
);
