import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';

const PeopleList = styled.ul`
    padding-left: 20px;
`;

const AllPeopleModal = ({ people, title, onClose }) => (
    <Modal
        isOpen
        onRequestClose={onClose}
        buttons={
            <>
                <Button type="button" onClick={onClose}>
                    OK
                </Button>
            </>
        }
    >
        <ModalTitle>{title}</ModalTitle>
        <PeopleList>
            {people.map(({ firstName, lastName }, index) => (
                <li key={index}>{`${lastName}, ${firstName}`}</li>
            ))}
        </PeopleList>
    </Modal>
);

AllPeopleModal.propTypes = {
    title: PropTypes.string.isRequired,
    people: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default AllPeopleModal;
