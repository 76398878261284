import { atom } from 'recoil';

const key = 'projectManagement_projectsListSearchTerm';

const getDefaultValue = () => {
    try {
        if (window.localStorage && window.localStorage.getItem(key)) {
            return window.localStorage.getItem(key);
        }
    } catch (error) {
        // Fail silently
    }

    return '';
};

const projectsSearchTermAtom = atom({
    key,
    default: getDefaultValue(),
});

export default projectsSearchTermAtom;
