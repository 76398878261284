import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const DataManagementBottomButtons = ({ children, prepend, append }) => {
    const [portalNode, setPortalNode] = useState();

    let id = 'dataManagementBottomButtons';

    if (prepend) {
        id = 'dataManagementBottomButtonsBefore';
    } else if (append) {
        id = 'dataManagementBottomButtonsAfter';
    }

    useEffect(() => {
        setPortalNode(document.getElementById(id));
    }, [id]);

    return portalNode ? ReactDOM.createPortal(children, portalNode, id) : null;
};

DataManagementBottomButtons.defaultProps = {
    prepend: false,
    append: false,
};

DataManagementBottomButtons.propTypes = {
    prepend: PropTypes.bool,
    append: PropTypes.bool,
};

export default DataManagementBottomButtons;
