import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import InteractableOuterBox from 'Common/components/Boxes/InteractableOuterBox';
import colors from 'Common/constants/colors';

const Tile = styled(InteractableOuterBox).withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => {
        if (prop === 'isDisabled') {
            return false;
        }

        return defaultValidatorFn(prop);
    },
})`
    display: flex;
    align-items: center;
    padding: 20px;
    font-size: 16px;
    text-decoration: none;
    border-radius: 4px;

    i,
    svg {
        width: 42px;
        height: 42px;
        font-size: 42px;
        margin-right: 10px;
        color: ${colors.PRIMARY_LIGHT};
    }

    ${ifProp(
        'isDisabled',
        css`
            cursor: not-allowed;
            user-select: none;
            opacity: 0.3;
        `,
    )};

    @media screen and (min-width: 425px) {
        font-size: 20px;

        i,
        svg {
            width: 52px;
            height: 52px;
            font-size: 52px;
            margin-right: 15px;
        }
    }

    @media screen and (min-width: 1180px) {
        i,
        svg {
            width: 72px;
            height: 72px;
            font-size: 72px;
        }
    }
`;

Tile.defaultProps = {
    isDisabled: false,
};

Tile.propTypes = {
    isDisabled: PropTypes.bool,
};

export default Tile;
