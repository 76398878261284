import api from 'Application/api/api';

const deleteProjectQuestionFile = (
    projectId,
    questionId,
    isCustom,
    fileId,
    cancelToken = null,
) =>
    api.delete(
        `project-manager/projects/${projectId}/questionnaire/questions/${questionId}/${
            isCustom ? 1 : 0
        }/files/${fileId}`,
        {
            cancelToken,
        },
    );

export default deleteProjectQuestionFile;
