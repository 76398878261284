import api from 'Application/api/api';

const requestDataDownload = (
    projectId,
    requestedData,
    receivers,
    useRequesterAsReceiver = true,
    cancelToken = null,
) =>
    api.post(
        `project-manager/projects/${projectId}/download-requests`,
        {
            requestedData,
            useRequesterAsReceiver,
            clientContactPeopleReceiverIds:
                receivers.clientContactPeopleIds ?? [],
            projectCoordinatorReceiverIds:
                receivers.projectCoordinatorIds ?? [],
            supervisorReceiverIds: receivers.supervisorIds ?? [],
        },
        cancelToken,
    );

export default requestDataDownload;
