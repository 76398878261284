import api from 'Application/api/api';

const isClientContactPersonEmailUnique = (
    email,
    clientId,
    contactPersonId = null,
    cancelToken = null,
) =>
    api.get(
        `project-manager/clients/${clientId}/contact-people/is-email-unique/${email}`,
        {
            params: {
                contactPersonId,
            },
            cancelToken,
        },
    );

export default isClientContactPersonEmailUnique;
