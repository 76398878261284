import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useShowProjectQuestionFunction from 'ProjectManager/DataManagement/Questionnaire/components/Question/ShowHideControl/hooks/showQuestionFunctions/useShowProjectQuestionFunction';
import useShowTemplateQuestionFunction from 'ProjectManager/DataManagement/Questionnaire/components/Question/ShowHideControl/hooks/showQuestionFunctions/useShowTemplateQuestionFunction';

const useShowQuestionFunction = (questionId, isCustomQuestion) => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useShowProjectQuestionFunction(
        questionId,
        isCustomQuestion,
    );

    const dataFromTemplate = useShowTemplateQuestionFunction(
        questionId,
        isCustomQuestion,
    );

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return () => {};
};

export default useShowQuestionFunction;
