import React from 'react';
import styled from 'styled-components/macro';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import Button from 'Common/components/Button';
import simpleDataTypes from 'ProjectManager/Project/Preview/QuickActions/DownloadAction/constants/simpleDataTypes';
import ModalOption from 'Common/components/Modals/ModalOption';

const Options = styled.div`
    > * {
        width: 100%;

        &:first-child {
            margin-bottom: 10px;
        }
    }

    @media screen and (min-width: 480px) {
        display: flex;

        > * {
            width: auto;
            flex: 1 1 50%;

            &:first-child {
                margin-bottom: 0;
                margin-right: 10px;
            }
        }
    }
`;

const useSimpleDataTypeSelectionModal = ({
    value,
    onChange,
    onClose,
    onNextStepButtonClick,
}) => {
    const {
        id: projectId,
        files,
        questionnaireSections,
        statistics: { participantsWithInstructionCertificates },
    } = useRecoilValue(projectAtom);

    const hasInstructionCertificates =
        participantsWithInstructionCertificates > 0;

    const hasGeneralFiles = files.length > 0;

    const hasQuestionFiles = questionnaireSections.some(section =>
        section.subsections.some(subsection =>
            subsection.questions.some(question => question.files.length > 0),
        ),
    );

    const isAllFilesOptionAvailable =
        hasInstructionCertificates || hasGeneralFiles || hasQuestionFiles;

    return {
        props: {
            buttons: (
                <>
                    <Button text dark type="button" onClick={onClose}>
                        Abbrechen
                    </Button>
                    {value === simpleDataTypes.PDF_REPORT ? (
                        <Button
                            href={`/api/project-manager/projects/${projectId}/pdf-report`}
                            target="_blank"
                            onClick={onClose}
                        >
                            Download
                        </Button>
                    ) : (
                        <Button
                            type="button"
                            disabled={value === null}
                            onClick={onNextStepButtonClick}
                        >
                            {value === simpleDataTypes.ALL_FILES
                                ? 'Weiter'
                                : 'Download'}
                        </Button>
                    )}
                </>
            ),
        },
        content: (
            <>
                <p>
                    Hier kannst du die Projektinhalte, welche du anfordern
                    willst, einschränken, wenn gewollt.
                </p>
                <Options>
                    <ModalOption
                        title="Nur die PDF Datei"
                        description="Die Datei mit der Projektbewertung ohne Anhänge"
                        isChecked={value === simpleDataTypes.PDF_REPORT}
                        onToggle={() => onChange(simpleDataTypes.PDF_REPORT)}
                    />
                    <ModalOption
                        title="Alle Projektdateien"
                        description="Alle projektbezogenen Dateien inklusive allen angehängten Dateien"
                        isChecked={value === simpleDataTypes.ALL_FILES}
                        onToggle={() => onChange(simpleDataTypes.ALL_FILES)}
                        disabled={!isAllFilesOptionAvailable}
                        tooltip={
                            !isAllFilesOptionAvailable
                                ? 'Es sind keine Daten vorhanden, welche du downloaden kannst'
                                : ''
                        }
                    />
                </Options>
            </>
        ),
    };
};

export default useSimpleDataTypeSelectionModal;
