import setIn from 'Common/utils/setIn';
import findQuestionInQuestionnaireSections from 'ProjectManager/DataManagement/Questionnaire/helpers/findQuestionInQuestionnaireSections';
import withUpdatedCompletedStatusesInQuestionnaire from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/withUpdatedCompletedStatusesInQuestionnaire';

const withYesNoAnswerForQuestion = (
    questionId,
    isCustomQuestion,
    yesNoAnswer,
) => prevProjectOrTemplate => {
    let questionnaireSections = [...prevProjectOrTemplate.questionnaireSections];

    const { question, questionPath } = findQuestionInQuestionnaireSections(
        questionnaireSections,
        questionId,
        isCustomQuestion,
    );

    if (!question) {
        return prevProjectOrTemplate;
    }

    const updatedQuestion = {
        ...question,
        yesNoAnswer,
    };

    questionnaireSections = setIn(
        questionnaireSections,
        questionPath,
        updatedQuestion,
    );

    return withUpdatedCompletedStatusesInQuestionnaire({
        ...prevProjectOrTemplate,
        questionnaireSections,
    });
};

export default withYesNoAnswerForQuestion;
