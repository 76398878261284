const stableTableSort = (array, functions, directions) =>
    [...array].sort((rowA, rowB) => {
        for (let i = 0; i < functions.length; i += 1) {
            const sortFunction = functions[i];

            const isDescending =
                directions[i] === false || directions[i] === 'desc';

            const sortInt = sortFunction(rowA, rowB);

            if (sortInt !== 0) {
                return isDescending ? -sortInt : sortInt;
            }
        }

        return 0;
    });

export default stableTableSort;
