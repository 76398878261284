import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useSelectedProjectTopics from 'ProjectManager/DataManagement/TopicManagement/components/TopicSelection/hooks/selectedTopics/useSelectedProjectTopics';
import useSelectedTemplateTopics from 'ProjectManager/DataManagement/TopicManagement/components/TopicSelection/hooks/selectedTopics/useSelectedTemplateTopics';

const useSelectedTopics = () => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useSelectedProjectTopics();
    const dataFromTemplate = useSelectedTemplateTopics();

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return [];
};

export default useSelectedTopics;
