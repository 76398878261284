import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import changeTemplateSupervisors from 'ProjectManager/Template/Common/api/dataManagement/supervisor/changeTemplateSupervisors';
import withSupervisorUsers from 'ProjectManager/Template/Common/recoil/modifiers/withSupervisorUsers';

const useTemplateSupervisorsData = () => {
    const [{ id: templateId, supervisorUsers }, setTemplate] = useRecoilState(
        templateAtom,
    );

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const updateSupervisors = useCallback(
        async userIds => {
            const response = await changeTemplateSupervisors(
                templateId,
                userIds,
                source.token,
            );

            const newSupervisorUsers = response.data;

            setTemplate(withSupervisorUsers(newSupervisorUsers));
        },
        [templateId, setTemplate, source.token],
    );

    return templateId
        ? {
              selectedSupervisorUsers: supervisorUsers,
              updateSupervisors,
              isRequired: false,
          }
        : {};
};

export default useTemplateSupervisorsData;
