import React from 'react';
import ContentSection from 'ProjectManager/DataManagement/Common/components/ContentSection';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import SupervisorsField from 'ProjectManager/DataManagement/Supervisors/components/SupervisorsField';
import styled from 'styled-components/macro';

const Content = styled.div`
    @media screen and (min-width: 1180px) {
        width: calc(50% - 10px);
    }
`;

const Supervisors = props => (
    <ContentSection>
        <OuterBoxTitle
            helpText={
                <>
                    "Arbeitsverantwortliche(r)": Diese Person/en stellt/en die
                    Aufsicht und leitet die Arbeiten vor Ort. Sie muss auch die
                    Ergebnisse der betrieblichen Gefährdungsbeurteilung (inkl.
                    Arbeitsanweisungen, Betriebsanweisungen etc.) kennen, um
                    beurteilen zu können, welche Gefährdungen dort bereits
                    berücksichtigt sind und welche Schutzmaßnahmen vom/der
                    Unternehmer(in) festgelegt wurden.
                </>
            }
        >
            Auftragsverantwortlicher
        </OuterBoxTitle>
        <Content>
            <SupervisorsField {...props} />
        </Content>
    </ContentSection>
);

export default Supervisors;
