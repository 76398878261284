import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import Title from 'Measure/components/vehicle/driver-license/Common/Title';
import controlMethods from 'Measure/constants/driverLicenseControlMethods';
import { ifProp } from 'styled-tools';
import createDriverLicense from 'Measure/api/driver-license/create/createDriverLicense';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import changeDriverLicenseControlMethod from 'Measure/api/driver-license/create/changeDriverLicenseControlMethod';
import reCreateDriverLicense from 'Measure/api/driver-license/create/reCreateDriverLicense';
import OuterBox from 'Common/components/Boxes/OuterBox';
import InteractableInnerBox from 'Common/components/Boxes/InteractableInnerBox';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';
import OuterBoxButtons from 'Common/components/Boxes/OuterBoxButtons';

const SideBySideContent = styled.div`
    padding: 20px;
    border-radius: 4px;

    > div {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 620px) {
        display: flex;
        min-height: 240px;
        padding: 0;
        background: none;

        > div {
            flex: 1;
            width: calc(50% - 5px);
            margin-right: 10px;
            margin-bottom: 0;
            padding: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    @media screen and (min-width: 940px) {
        min-height: 320px;

        > div {
            padding: 40px;
        }
    }
`;

const Content = styled(OuterBox)`
    @media screen and (min-width: 620px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const ControlMethod = styled(InteractableInnerBox).attrs({ as: 'button' })`
    width: 100%;
    min-height: 70px;
    padding: 20px;
    cursor: pointer;
    border: 0 none;
    outline: 0 none;

    &[disabled] {
        cursor: default;
    }

    ${ifProp(
        'isSelected',
        css`
            background: ${colors.DARK_DIVIDER};
        `,
    )};

    @media screen and (min-width: 620px) {
        height: 100%;
        max-width: 400px;
    }

    @media screen and (min-width: 940px) {
        max-height: 200px;
        padding: 40px;
    }
`;

const ControlMethodTitle = styled.div`
    font-weight: 600;
    font-size: 18px;
`;

const ControlMethodDescription = styled.div`
    margin-top: 20px;
    color: ${colors.LIGHTER_GRAY};
    font-size: 14px;
    line-height: 21px;
`;

const DriverLicenseControlMethodSelection = ({
    driverLicenseControl,
    onConfirm,
    shouldReCreate,
}) => {
    const [controlMethod, setControlMethod] = useState(() => {
        const driverLicense = driverLicenseControl.driverLicense;

        if (driverLicense && !shouldReCreate) {
            if (driverLicense.isUsingDirectControlMethod) {
                return 'DIRECT';
            }

            if (driverLicense.isUsingDistanceControlMethod) {
                return 'DISTANCE';
            }
        }

        return null;
    });
    const [isConfirming, setIsConfirming] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirmButtonClick = async () => {
        if (controlMethod) {
            setIsConfirming(true);

            try {
                const driverLicense = driverLicenseControl.driverLicense;

                if (driverLicense && !shouldReCreate) {
                    const hasMethodChanged =
                        (driverLicense.isUsingDirectControlMethod &&
                            controlMethod !== 'DIRECT') ||
                        (driverLicense.isUsingDistanceControlMethod &&
                            controlMethod !== 'DISTANCE');

                    if (hasMethodChanged) {
                        await changeDriverLicenseControlMethod(
                            driverLicenseControl.id,
                            controlMethod,
                            source.token,
                        );
                    }
                } else if (shouldReCreate) {
                    await reCreateDriverLicense(
                        driverLicenseControl.id,
                        controlMethod,
                        source.token,
                    );
                } else {
                    await createDriverLicense(
                        driverLicenseControl.id,
                        controlMethod,
                        source.token,
                    );
                }

                await onConfirm(controlMethod);

                setIsConfirming(false);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setIsConfirming(false);

                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }
        }
    };

    const handleControlMethodClick = method => {
        setControlMethod(method);
    };

    return (
        <>
            <Title>Bitte wählen Sie ein der Prüfmethoden</Title>
            <SideBySideContent>
                <Content>
                    <ControlMethod
                        disabled={controlMethod === controlMethods.DIRECT}
                        isSelected={controlMethod === controlMethods.DIRECT}
                        onClick={() =>
                            handleControlMethodClick(controlMethods.DIRECT)
                        }
                    >
                        <ControlMethodTitle>Direct</ControlMethodTitle>
                        {controlMethod === controlMethods.DIRECT && (
                            <ControlMethodDescription>
                                Hinweis: Die Prüfmethode Direct ist nicht
                                ausschließlich für EU-Kartenführerscheine aus
                                Deutschland geeignet!
                            </ControlMethodDescription>
                        )}
                    </ControlMethod>
                </Content>
                <Content>
                    <ControlMethod
                        disabled={controlMethod === controlMethods.DISTANCE}
                        isSelected={controlMethod === controlMethods.DISTANCE}
                        onClick={() =>
                            handleControlMethodClick(controlMethods.DISTANCE)
                        }
                    >
                        <ControlMethodTitle>Distance</ControlMethodTitle>
                        {controlMethod === controlMethods.DISTANCE && (
                            <ControlMethodDescription>
                                Hinweis: Die Prüfmethode Distance kann nur mobil
                                erstellte werden und setzt einen
                                EU-Kartenführerschein aus Deutschland voraus!
                            </ControlMethodDescription>
                        )}
                    </ControlMethod>
                </Content>
            </SideBySideContent>
            <OuterBoxButtons>
                <Button
                    isLoading={isConfirming}
                    type="button"
                    onClick={handleConfirmButtonClick}
                    disabled={!controlMethod || isConfirming}
                >
                    Auswahl übernehmen
                </Button>
            </OuterBoxButtons>
        </>
    );
};

DriverLicenseControlMethodSelection.defaultProps = {
    shouldReCreate: false,
};

DriverLicenseControlMethodSelection.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    shouldReCreate: PropTypes.bool,
};

export default DriverLicenseControlMethodSelection;
