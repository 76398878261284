import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import showTemplateQuestion from 'ProjectManager/Template/Common/api/dataManagement/questionnaire/question/showTemplateQuestion';
import withQuestionVisibility
    from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/withQuestionVisibility';

const useShowTemplateQuestionFunction = (questionId, isCustomQuestion) => {
    const [{ id: templateId }, setTemplate] = useRecoilState(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const showQuestion = useCallback(async () => {
        await showTemplateQuestion(
            templateId,
            questionId,
            isCustomQuestion,
            source.token,
        );

        setTemplate(withQuestionVisibility(questionId, isCustomQuestion, true));
    }, [templateId, questionId, isCustomQuestion, setTemplate, source.token]);

    return showQuestion;
};

export default useShowTemplateQuestionFunction;
