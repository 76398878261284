import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo, useState } from 'react';
import hasUnsavedChangesState from 'Common/recoil/hasUnsavedChangesState';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import addTemplateQuestionComment from 'ProjectManager/Template/Common/api/dataManagement/questionnaire/question/comment/addTemplateQuestionComment';
import withQuestionComment
    from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/withQuestionComment';

const useAddTemplateQuestionCommentData = (
    questionId,
    isCustomQuestion,
    onAdd,
) => {
    const [{ id: templateId }, setTemplate] = useRecoilState(templateAtom);

    const [isLoading, setIsLoading] = useState(false);
    const [, setHasUnsavedChanges] = useRecoilState(hasUnsavedChangesState);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const addComment = useCallback(async () => {
        setHasUnsavedChanges(true);
        setIsLoading(true);

        try {
            await addTemplateQuestionComment(
                templateId,
                questionId,
                isCustomQuestion,
                source.token,
            );

            setTemplate(withQuestionComment(questionId, isCustomQuestion, ''));

            if (onAdd) {
                await onAdd();
            }
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }

        setIsLoading(false);
        setHasUnsavedChanges(false);
    }, [
        onAdd,
        templateId,
        questionId,
        isCustomQuestion,
        setHasUnsavedChanges,
        setTemplate,
        source.token,
    ]);

    return {
        isLoading,
        addComment,
    };
};

export default useAddTemplateQuestionCommentData;
