import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import InnerBox from 'Common/components/Boxes/InnerBox';
import colors from 'Common/constants/colors';
import MoreActionsButton from 'Common/components/MoreActionsButton/MoreActionsButton';
import MenuItem from 'Common/components/MoreActionsButton/MenuItem';
import { DeleteRounded, EditRounded } from '@material-ui/icons';
import ProjectCoordinatorDeleteModal from 'ProjectManager/Project/DataManagement/GeneralInformation/ProjectCoordinators/ProjectCoordinatorTile/ProjectCoordinatorDeleteModal';

const Wrapper = styled(InnerBox)`
    display: flex;
    align-items: center;
    padding: 15px 10px 15px 15px;
    font-size: 14px;
    line-height: 21px;
`;

const Info = styled.div`
    flex: 1;
    margin-right: 10px;
    word-break: break-word;
`;

const Name = styled.div`
    margin-bottom: 5px;
`;

const Email = styled.div`
    font-weight: 300;
    color: ${colors.GRAY};
`;

const ProjectCoordinatorPreview = ({ projectCoordinator, onEditButtonClick }) => {
    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

    const handleDeleteButtonClick = () => {
        setIsDeleteModalOpened(true);
    };

    const closeModal = () => {
        setIsDeleteModalOpened(false);
    };

    return (
        <>
            <Wrapper>
                <Info>
                    <Name>{projectCoordinator.name}</Name>
                    <Email>{projectCoordinator.email}</Email>
                </Info>
                <MoreActionsButton>
                    <MenuItem
                        icon={<EditRounded />}
                        type="button"
                        onClick={onEditButtonClick}
                    >
                        Bearbeiten
                    </MenuItem>
                    <MenuItem
                        icon={<DeleteRounded />}
                        type="button"
                        onClick={handleDeleteButtonClick}
                    >
                        Löschen
                    </MenuItem>
                </MoreActionsButton>
            </Wrapper>
            {isDeleteModalOpened && (
                <ProjectCoordinatorDeleteModal
                    projectCoordinator={projectCoordinator}
                    onCancel={closeModal}
                    onSuccess={closeModal}
                />
            )}
        </>
    );
};

ProjectCoordinatorPreview.propTypes = {
    projectCoordinator: PropTypes.object.isRequired,
    onEditButtonClick: PropTypes.func.isRequired,
};

export default ProjectCoordinatorPreview;
