import api from 'Application/api/api';

const markInstructionAssignmentExamForApproval = (id, cancelToken = null) =>
    api.post(
        `/education/instruction-assignments/${id}/exam/mark-for-approval`,
        {},
        {
            cancelToken,
        },
    );

export default markInstructionAssignmentExamForApproval;
