import styled from 'styled-components/macro';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';

const InnerBoxTitle = styled(OuterBoxTitle)`
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 25px;
`;

export default InnerBoxTitle;
