import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';
import styled from 'styled-components/macro';
import SwitchableTile from 'Common/components/SwitchableTile';
import Button from 'Common/components/Button';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import selectIntroductionPoint from 'ProjectManager/Project/Introductions/Common/api/selectIntroductionPoint';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { useRecoilState } from 'recoil';
import deselectIntroductionPoint from 'ProjectManager/Project/Introductions/Common/api/deselectIntroductionPoint';
import withIntroductionPoint from 'ProjectManager/Project/Common/recoil/project/modifiers/introduction/withIntroductionPoint';
import withoutIntroductionPoint from 'ProjectManager/Project/Common/recoil/project/modifiers/introduction/withoutIntroductionPoint';
import PPERefineModal
    from 'ProjectManager/Project/Introductions/Grid/components/Introduction/EditPointsDrawer/PPERefineModal';

const RefineWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`;

const RefineButton = styled(Button)`
    padding-left: 0;
`;

const RefineCounter = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    ${ifProp(
        'isDisabled',
        css`
            opacity: 0.35;
        `,
    )};
`;

const PointTile = ({ point, ppes, introduction }) => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleSelect = async () => {
        try {
            await selectIntroductionPoint(
                projectId,
                introduction.id,
                point.value,
                source.token,
            );

            setProject(withIntroductionPoint(introduction.id, point.value));
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const handleDeselect = async () => {
        try {
            await deselectIntroductionPoint(
                projectId,
                introduction.id,
                point.value,
                source.token,
            );

            setProject(withoutIntroductionPoint(introduction.id, point.value));
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const [isRefineModalOpened, setIsRefineModalOpened] = useState(false);

    const handleRefineButtonClick = () => {
        setIsRefineModalOpened(true);
    };

    const closeRefineModal = () => {
        setIsRefineModalOpened(false);
    };

    const isSelected = introduction.points.includes(point.value);

    return (
        <>
            <SwitchableTile
                title={point.label}
                description={point.description}
                isSelected={isSelected}
                isDisabled={isSelected && introduction.points.length === 1}
                disabledTooltipText="Mind. eine Einweisungspunkt muss ausgewählt werden"
                onSelect={handleSelect}
                onDeselect={handleDeselect}
            >
                {point.value === 'PERSONAL_PROTECTIVE_EQUIPMENT' && (
                    <RefineWrapper>
                        <RefineButton
                            type="button"
                            underline
                            onClick={handleRefineButtonClick}
                            disabled={!isSelected}
                        >
                            Verfeinern
                        </RefineButton>
                        <RefineCounter isDisabled={!isSelected}>
                            {`${introduction.ppes.length}/${ppes.length}`}
                        </RefineCounter>
                    </RefineWrapper>
                )}
            </SwitchableTile>
            {isRefineModalOpened && (
                <PPERefineModal ppes={ppes} introduction={introduction} onClose={closeRefineModal} />
            )}
        </>
    );
};

PointTile.propTypes = {
    point: PropTypes.object.isRequired,
    ppes: PropTypes.array.isRequired,
    introduction: PropTypes.object.isRequired,
};

export default PointTile;
