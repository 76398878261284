import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import InnerBoxWithButtons from 'Common/components/Boxes/InnerBoxWithButtons';
import Button from 'Common/components/Button';
import InnerBoxTitle from 'Common/components/Boxes/InnerBoxTitle';
import FormField from 'Common/components/Form/FormField';
import Input from 'Common/components/Form/Fields/Input';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { pick } from 'lodash';
import Textarea from 'Common/components/Form/Fields/Textarea';
import useUniqueNameValidationCallback from 'ProjectManager/DataManagement/TopicManagement/components/CustomTopicManagement/hooks/uniqueNameValidationCallback/useUniqueNameValidationCallback';
import useCustomTopicFunctions from 'ProjectManager/DataManagement/TopicManagement/components/CustomTopicManagement/hooks/customTopicFunctions/useCustomTopicFunctions';

const CustomTopicEdit = ({ customTopic, onCancel, onSave }) => {
    const [isSaving, setIsSaving] = useState(false);

    const initialValues = useMemo(
        () => pick(customTopic, ['name', 'explanationText']),
        [customTopic],
    );

    const { updateCustomTopic } = useCustomTopicFunctions();
    const uniqueNameValidationCallback = useUniqueNameValidationCallback();

    const customizedUniqueNameValidationCallback = useCallback(
        value => uniqueNameValidationCallback(value, customTopic.id),
        [customTopic.id, uniqueNameValidationCallback],
    );

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                name: Yup.string()
                    .debouncedCallback(
                        'Der Name wird bereits verwendet',
                        customizedUniqueNameValidationCallback,
                    )
                    .required('Pflichtangabe'),
            }),
        [customizedUniqueNameValidationCallback],
    );

    const {
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        isSubmitting,
        isValidating,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async values => {
            setIsSaving(true);

            try {
                await updateCustomTopic(customTopic.id, values);
                await onSave();

                notify('Anpassung war erfolgreich', {
                    type: toast.TYPE.SUCCESS,
                });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }

            setIsSaving(false);
        },
    });

    const isSubmittingAndValidating = isSubmitting && isValidating;
    const isLoading = isSaving || isSubmittingAndValidating;

    return (
        <InnerBoxWithButtons
            buttons={
                <>
                    <Button
                        text
                        type="button"
                        disabled={isLoading}
                        onClick={onCancel}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        disabled={isLoading || !isValid}
                        isLoading={isLoading}
                        onClick={handleSubmit}
                    >
                        Speichern
                    </Button>
                </>
            }
        >
            <InnerBoxTitle>Eigenes Projektfeld bearbeiten</InnerBoxTitle>
            <form onSubmit={handleSubmit}>
                <FormField>
                    <Input
                        id="name"
                        name="name"
                        label="Projektfeld Bezeichnung"
                        value={values.name}
                        error={errors.name}
                        touched={touched.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isRequired
                    />
                </FormField>
                <FormField>
                    <Textarea
                        id="explanationText"
                        name="explanationText"
                        label="Beschreibung des Projektfeldes"
                        value={values.explanationText}
                        error={errors.explanationText}
                        touched={touched.explanationText}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>
            </form>
        </InnerBoxWithButtons>
    );
};

CustomTopicEdit.propTypes = {
    customTopic: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default CustomTopicEdit;
