import api from 'Application/api/api';

const updateProjectAreas = (projectId, areaIds, cancelToken = null) =>
    api.post(
        `project-manager/projects/${projectId}/areas`,
        {
            areaIds,
        },
        { cancelToken },
    );

export default updateProjectAreas;
