import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import FormField from 'Common/components/Form/FormField';
import Select from 'Common/components/Form/Fields/Select';
import Input from 'Common/components/Form/Fields/Input';
import AsyncSelect from 'Common/components/Form/Fields/AsyncSelect';
import loadInstructionAreaOptions from 'Education/utils/loadInstructionAreaOptions';

const preventDefault = e => {
    e.preventDefault();
};

const keepReferenceOptions = [
    {
        value: true,
        label: 'Mit Referenz',
    },
    {
        value: false,
        label: 'Ohne Referenz',
    },
];

const FormRow = styled.div`
    @media screen and (min-width: 680px) {
        display: flex;
        align-items: flex-end;

        > div {
            align-self: flex-start;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
`;

const InstructionPresentationForm = ({
    errors,
    touched,
    values,
    setFieldValue,
    handleChange,
    handleBlur,
}) => (
    <form onSubmit={preventDefault}>
        <FormRow>
            {values.keepReference !== null && (
                <FormField>
                    <Select
                        label="Bibliothek Referenz"
                        id="keepReference"
                        name="keepReference"
                        error={errors.keepReference}
                        touched={touched.keepReference}
                        value={values.keepReference}
                        setFieldValue={setFieldValue}
                        onBlur={handleBlur}
                        options={keepReferenceOptions}
                        isSearchable={false}
                    />
                </FormField>
            )}
            <FormField>
                <Input
                    label="Name"
                    id="name"
                    name="name"
                    error={errors.name}
                    touched={touched.name}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </FormField>
            <FormField size={150}>
                <Input
                    type="number"
                    label="Dauer (min)"
                    id="recommendedPresentationDuration"
                    name="recommendedPresentationDuration"
                    error={errors.recommendedPresentationDuration}
                    touched={touched.recommendedPresentationDuration}
                    value={values.recommendedPresentationDuration}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    numberProps={{
                        decimalScale: 0,
                    }}
                />
            </FormField>
        </FormRow>
        <FormField>
            <AsyncSelect
                label="Bereiche"
                id="areas"
                name="areas"
                error={errors.areas}
                touched={touched.areas}
                value={values.areas}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                loadOptions={loadInstructionAreaOptions}
                isMulti
                isClerable
                isSearchable
            />
        </FormField>
    </form>
);

InstructionPresentationForm.propTypes = {
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
};

export default InstructionPresentationForm;
