import { useRecoilValue } from 'recoil';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';

const useTemplateFiles = () => {
    const { files } = useRecoilValue(templateAtom);

    return files;
};

export default useTemplateFiles;
