import React from 'react';
import { useSelector } from 'react-redux';
import Header from 'Application/components/Header/Header';
import roles from 'User/constants/roles';
import Breadcrumbs from 'Application/components/Header/Breadcrumbs';

const ReactHeader = () => {
    const role = useSelector(state => state.user.role);
    const availableModules = useSelector(state => state.user.availableModules);

    return (
        <>
            <Header
                isManager={role === roles.MANAGER}
                availableModules={availableModules}
            />
            <Breadcrumbs />
        </>
    );
};

export default ReactHeader;
