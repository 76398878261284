import questionTypes from 'ProjectManager/DataManagement/Questionnaire/components/Question/Answers/constants/questionTypes';
import Rule from 'Common/components/Icons/Rule';
import {
    LibraryAddCheckRounded,
    RadioButtonCheckedRounded,
    TextFieldsRounded,
} from '@material-ui/icons';

const questionTypesButtons = {
    [questionTypes.YES_NO]: {
        icon: Rule,
        text: 'Ja / Nein',
    },
    [questionTypes.TEXT]: {
        icon: TextFieldsRounded,
        text: 'Mit Textantwort',
    },
    [questionTypes.SINGLE_CHOICE]: {
        icon: RadioButtonCheckedRounded,
        text: 'Einzelauswahl',
    },
    [questionTypes.MULTIPLE_CHOICE]: {
        icon: LibraryAddCheckRounded,
        text: 'Mehrfachauswahl',
    },
};

export default questionTypesButtons;
