import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useCreateProjectCustomTopicFunction from 'ProjectManager/DataManagement/TopicManagement/components/CustomTopicManagement/hooks/createCustomTopicFunction/useCreateProjectCustomTopicFunction';
import useCreateTemplateCustomTopicFunction from 'ProjectManager/DataManagement/TopicManagement/components/CustomTopicManagement/hooks/createCustomTopicFunction/useCreateTemplateCustomTopicFunction';

const useCreateCustomTopicFunction = () => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useCreateProjectCustomTopicFunction();
    const dataFromTemplate = useCreateTemplateCustomTopicFunction();

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return () => {};
};

export default useCreateCustomTopicFunction;
