import { createAction, createReducer } from 'redux-act';

export const restrictAccessToInstructions = createAction(
    'Restricts the access to the instructions',
);

export const clearInstructionsRestriction = createAction(
    'Clears the external access restriction to the instructions',
);

const initialEducationData = {
    isAccessRestricted: false,
};

export const educationReducer = createReducer(
    {
        [restrictAccessToInstructions]: state => ({
            ...state,
            isAccessRestricted: true,
        }),
        [clearInstructionsRestriction]: state => ({
            ...state,
            isAccessRestricted: false,
        }),
    },
    {
        ...initialEducationData,
    },
);
