import api from 'Application/api/api';

const updateDriverLicenseFrontData = (
    driverLicenseControlId,
    data,
    cancelToken = null,
) =>
    api.post(
        `/measures/vehicles/driver-licenses/controls/${driverLicenseControlId}/driver-license/update-front-data`,
        data,
        {
            cancelToken,
        },
    );

export default updateDriverLicenseFrontData;
