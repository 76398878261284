import React, { useEffect, useMemo, useState } from 'react';
import QuickAction from 'ProjectManager/Project/Preview/QuickActions/QuickAction';
import { CloudDownloadRounded } from '@material-ui/icons';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import axios from 'axios';
import requestDataDownload from 'ProjectManager/Project/Common/api/downloadRequest/requestDataDownload';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import dataTypes from 'ProjectManager/Project/Preview/QuickActions/DownloadAction/constants/dataTypes';
import SuccessModal from 'ProjectManager/Project/ProjectMediaFiles/components/Sidebar/QuickActions/DownloadGeneralFiles/SuccessModal';

const DownloadGeneralFiles = () => {
    const { id: projectId, files } = useRecoilValue(projectAtom);

    const [isSuccessModalOpened, setIsSuccessModalOpened] = useState(false);

    const closeSuccessModal = () => {
        setIsSuccessModalOpened(false);
    };

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const [isLoading, setIsLoading] = useState(false);

    const handleButtonClick = async () => {
        setIsLoading(true);

        try {
            await requestDataDownload(
                projectId,
                [dataTypes.GENERAL_FILES],
                {},
                true,
                source.token,
            );

            setIsLoading(false);
            setIsSuccessModalOpened(true);
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsLoading(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const generalProjectFilesCount = files.length;

    const canDownloadGeneralFiles = generalProjectFilesCount > 0;

    return (
        <>
            <QuickAction
                icon={<CloudDownloadRounded />}
                title="Projektdateien"
                description="Alle allgemeinen Projektdateien"
                type="button"
                onClick={handleButtonClick}
                isLoading={isLoading}
                disabled={isLoading || !canDownloadGeneralFiles}
                tooltip={
                    !canDownloadGeneralFiles ? 'Keine Anhänge verfügbar' : ''
                }
            />
            {isSuccessModalOpened && (
                <SuccessModal onClose={closeSuccessModal} />
            )}
        </>
    );
};

export default DownloadGeneralFiles;
