import React, { useEffect, useState } from 'react';
import scrollToTop from 'Common/utils/scrollToTop';
import { navigationItems } from 'ProjectManager/DataManagement/Navigation/constants/navigation';
import { useRecoilValue } from 'recoil';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import DataManagement from 'ProjectManager/DataManagement/Common/components/DataManagement';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';

const Template = () => {
    const { name } = useRecoilValue(templateAtom);

    const [activeNavigationItem, setActiveNavigationItem] = useState(
        navigationItems.GENERAL_INFORMATION,
    );

    // Each time the step is changed, scroll to the top of the page,
    // as if the page has changed.
    useEffect(() => {
        scrollToTop();
    }, [activeNavigationItem]);

    const title = name ? name : 'Kein Name';

    return (
        <>
            <Helmet>
                <title>{title} - Projektübersicht</title>
            </Helmet>
            <Breadcrumb to="/project-manager">Projektübersicht</Breadcrumb>
            <Breadcrumb isActive>{title}</Breadcrumb>
            <DataManagement
                title={title}
                activeNavigationItem={activeNavigationItem}
                onActiveNavigationItemChange={setActiveNavigationItem}
            />
        </>
    );
};

export default Template;
