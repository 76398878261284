import React from 'react';
import PropTypes from 'prop-types';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import Modal from 'Common/components/Modals/Modal';

const ConfirmModal = ({ onClose, onConfirm }) => (
    <Modal
        isOpen
        onRequestClose={onClose}
        buttons={
            <>
                <Button text dark type="button" onClick={onClose}>
                    Abbrechen
                </Button>
                <Button type="button" onClick={onConfirm}>
                    Schließen
                </Button>
            </>
        }
    >
        <ModalTitle>Schließen</ModalTitle>
        <p>
            Du hast Filter, die nicht angewendet wurden. Möchten Sie die Filter
            wirklich schließen?
        </p>
    </Modal>
);

ConfirmModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default ConfirmModal;
