import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import InnerBox from 'Common/components/Boxes/InnerBox';
import Statistics from 'ProjectManager/Project/Introductions/Grid/components/Introduction/Statistics';
import Status from 'ProjectManager/Project/Introductions/Grid/components/Introduction/Status';
import RelevantDate from 'ProjectManager/Project/Introductions/Grid/components/Introduction/RelevantDate';
import Points from 'ProjectManager/Project/Introductions/Grid/components/Introduction/Points';
import Participants from 'ProjectManager/Project/Introductions/Grid/components/Introduction/Participants';
import Supervisor from 'ProjectManager/Project/Introductions/Grid/components/Introduction/Supervisor';
import MoreActionsButton from 'Common/components/MoreActionsButton/MoreActionsButton';
import MenuItem from 'Common/components/MoreActionsButton/MenuItem';
import {
    AssignmentRounded,
    BlockRounded,
    CloudDownloadRounded,
    EditRounded,
    NotificationsNoneRounded,
} from '@material-ui/icons';
import Engineering from 'Common/components/Icons/Engineering';
import useConductButton from 'ProjectManager/Project/Introductions/Grid/hooks/useConductButton';
import useEditPointsButton from 'ProjectManager/Project/Introductions/Grid/hooks/useEditPointsButton';
import useParticipantsManageButton from 'ProjectManager/Project/Introductions/Grid/hooks/useParticipantsManageButton';
import useParticipantsViewButton from 'ProjectManager/Project/Introductions/Grid/hooks/useParticipantsViewButton';
import useDownloadButton from 'ProjectManager/Project/Introductions/Grid/hooks/useDownloadButton';
import useReminderButton from 'ProjectManager/Project/Introductions/Grid/hooks/useReminderButton';
import useCancelButton from 'ProjectManager/Project/Introductions/Grid/hooks/useCancelButton';

const Wrapper = styled(InnerBox)`
    position: relative;
    padding: 20px;

    @media screen and (min-width: 520px) {
        display: flex;
        padding-right: 50px;
    }
`;

const Content = styled.div`
    @media screen and (min-width: 520px) {
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 auto;
        padding-left: 20px;

        > div {
            flex: 0 0 230px;
            width: 230px;
        }
    }
`;

const Actions = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
`;

const Introduction = ({ introduction }) => {
    const {
        isVisible: isEditPointsButtonVisible,
        handleClick: handleEditPointsButtonVisible,
        renderDrawer: renderEditPointsDrawer,
    } = useEditPointsButton(introduction);

    const {
        isVisible: isParticipantsManageButtonVisible,
        handleClick: handleParticipantsManageButtonClick,
        renderModal: renderParticipantsManageModal,
    } = useParticipantsManageButton(introduction);

    const {
        isVisible: isParticipantsViewButtonVisible,
        handleClick: handleParticipantsViewButtonClick,
        renderModal: renderParticipantsViewModal,
    } = useParticipantsViewButton(introduction);

    const {
        isVisible: isConductButtonVisible,
        handleClick: handleConductButtonClick,
        renderModal: renderConductModal,
    } = useConductButton(introduction, handleParticipantsViewButtonClick);

    const {
        isVisible: isDownloadButtonVisible,
        handleClick: handleDownloadButtonClick,
    } = useDownloadButton(introduction);

    const {
        isVisible: isReminderButtonVisible,
        handleClick: handleReminderButtonClick,
        renderModal: renderReminderModal,
    } = useReminderButton(introduction);

    const {
        isVisible: isCancelButtonVisible,
        handleClick: handleCancelButtonClick,
        renderModal: renderCancelModal,
    } = useCancelButton(introduction);

    return (
        <Wrapper>
            <Statistics introduction={introduction} />
            <Content>
                <Status introduction={introduction} />
                <RelevantDate introduction={introduction} />
                <Points introduction={introduction} />
                <Participants introduction={introduction} />
                <Supervisor supervisor={introduction.supervisor} />
            </Content>
            <Actions>
                <MoreActionsButton>
                    {isConductButtonVisible && (
                        <MenuItem
                            type="button"
                            icon={<AssignmentRounded />}
                            onClick={handleConductButtonClick}
                        >
                            Durchführen
                        </MenuItem>
                    )}
                    {isEditPointsButtonVisible && (
                        <MenuItem
                            type="button"
                            icon={<EditRounded />}
                            onClick={handleEditPointsButtonVisible}
                        >
                            Einweisungspunkte Bearbeiten
                        </MenuItem>
                    )}
                    {isParticipantsManageButtonVisible && (
                        <MenuItem
                            type="button"
                            icon={<Engineering />}
                            onClick={handleParticipantsManageButtonClick}
                        >
                            Teilnehmer managen
                        </MenuItem>
                    )}
                    {isParticipantsViewButtonVisible && (
                        <MenuItem
                            type="button"
                            icon={<Engineering />}
                            onClick={handleParticipantsViewButtonClick}
                        >
                            Teilnehmer anzeigen
                        </MenuItem>
                    )}
                    {isDownloadButtonVisible && (
                        <MenuItem
                            type="button"
                            icon={<CloudDownloadRounded />}
                            onClick={handleDownloadButtonClick}
                        >
                            Nachweis downloaden
                        </MenuItem>
                    )}
                    {isReminderButtonVisible && (
                        <MenuItem
                            type="button"
                            icon={<NotificationsNoneRounded />}
                            onClick={handleReminderButtonClick}
                        >
                            Erinnerungen versenden
                        </MenuItem>
                    )}
                    {isCancelButtonVisible && (
                        <MenuItem
                            type="button"
                            icon={<BlockRounded />}
                            onClick={handleCancelButtonClick}
                        >
                            Einweisung Absagen
                        </MenuItem>
                    )}
                </MoreActionsButton>
                {renderConductModal()}
                {renderParticipantsManageModal()}
                {renderEditPointsDrawer()}
                {renderReminderModal()}
                {renderCancelModal()}
                {renderParticipantsViewModal()}
            </Actions>
        </Wrapper>
    );
};

Introduction.propTypes = {
    introduction: PropTypes.object.isRequired,
};

export default Introduction;
