import questionTypes from 'ProjectManager/DataManagement/Questionnaire/components/Question/Answers/constants/questionTypes';

const hasAnswer = question => {
    switch (question.type) {
        case questionTypes.YES_NO:
            return Boolean(question.yesNoAnswer);

        case questionTypes.TEXT:
            return Boolean(question.textAnswer);

        case questionTypes.SINGLE_CHOICE:
        case questionTypes.MULTIPLE_CHOICE:
            return question.singleOrMultipleAnswers.some(
                answer => answer.isSelected,
            );

        default:
            return false;
    }
};

const isQuestionAnswered = question =>
    !question.isVisible || hasAnswer(question);

export default isQuestionAnswered;
