const withIntroductionPoint = (introductionId, point) => prevProject => {
    const index = prevProject.introductions.findIndex(
        introduction => introduction.id === introductionId,
    );

    if (index === -1) {
        return prevProject;
    }

    if (prevProject.introductions[index].points.includes(point)) {
        return prevProject;
    }

    const newIntroductions = [...prevProject.introductions];

    newIntroductions[index] = {
        ...newIntroductions[index],
        points: [...newIntroductions[index].points, point],
    };

    return {
        ...prevProject,
        introductions: newIntroductions,
    };
};

export default withIntroductionPoint;
