import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';
import colors from 'Common/constants/colors';
import { KeyboardArrowDownRounded } from '@material-ui/icons';
import InnerBox from 'Common/components/Boxes/InnerBox';

const Wrapper = styled(InnerBox)`
    position: relative;
`;

const Counter = styled.div`
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 21px;
    height: 21px;
    border-radius: 21px;
    background: ${colors.DARK_GRAY};
    color: ${colors.DIRTY_WHITE};
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    pointer-events: none;
`;

const Heading = styled.button`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    background: none;
    border: 0 none;
    outline: 0 none;
    padding: 12px 10px;
    text-align: left;
    cursor: pointer;
`;

const Title = styled.div`
    font-size: 14px;
    line-height: 21px;

    ${ifProp(
        'isCollapsible',
        css`
            position: relative;
            top: 2px;
            margin-right: 10px;
        `,
        css`
            flex: 1;
        `,
    )};
`;

const Content = styled.div`
    padding: 0 10px 12px;
`;

const CollapseIndicator = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);

    ${ifProp(
        'isCollapsed',
        css`
            transform: rotate(0deg);
        `,
        css`
            transform: rotate(180deg);
        `,
    )};
`;

const Filter = ({
    title,
    selectedCount,
    isCollapsible,
    onClick,
    children,
    ...props
}) => {
    const [isCollapsed, setIsCollapsed] = useState(isCollapsible);

    const toggle = () => {
        if (isCollapsible) {
            setIsCollapsed(prevIsCollapsed => !prevIsCollapsed);
        }
    };

    return (
        <Wrapper light {...props}>
            {selectedCount > 0 && <Counter>{selectedCount}</Counter>}
            <Heading type="button" onClick={onClick ?? toggle}>
                <Title isCollapsible={isCollapsible}>{title}</Title>
                {isCollapsible && (
                    <CollapseIndicator isCollapsed={isCollapsed}>
                        <KeyboardArrowDownRounded />
                    </CollapseIndicator>
                )}
            </Heading>
            {!isCollapsed && Boolean(children) && <Content>{children}</Content>}
        </Wrapper>
    );
};

Filter.defaultProps = {
    selectedCount: 0,
    isCollapsible: true,
    onClick: undefined,
};

Filter.propTypes = {
    title: PropTypes.any.isRequired,
    selectedCount: PropTypes.number,
    isCollapsible: PropTypes.bool,
    onClick: PropTypes.func,
};

export default Filter;
