import { useCallback, useEffect, useMemo } from 'react';
import getProjects from 'ProjectManager/Project/Common/api/getProjects';
import axios from 'axios';

const useLoadProjectsDataRequest = () => {
    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const loadProjectsData = useCallback(
        (startIndex, stopIndex, filters, sortBy, searchTerm) =>
            getProjects(
                startIndex,
                stopIndex - startIndex + 1,
                filters,
                sortBy,
                searchTerm,
                ['client', 'supervisors', 'topics', 'customTopics'],
                source.token,
            ),
        [source.token],
    );

    return loadProjectsData;
};

export default useLoadProjectsDataRequest;
