import React, { useCallback, useMemo } from 'react';
import getEmployeeInstructionAssignments from 'Education/api/instruction-assignment/getEmployeeInstructionAssignments';
import getCurrentEmployee from 'Employee/api/getCurrentEmployee';
import { useTable } from 'react-table';
import { useCustomCellStyles } from 'Common/hooks/useCustomCellStyles';
import { examStatuses } from 'Education/constants/examStatuses';
import { DefaultTable, TableRowActions } from 'Common/components/table';
import Loader from 'Common/components/Loader';
import styled from 'styled-components/macro';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';
import { CloudDownloadRounded } from '@material-ui/icons';
import colors from 'Common/constants/colors';
import Button from 'Common/components/Button';

const Wrapper = styled.div`
    background-color: ${colors.OUTER_BOX_BACKGROUND};
`;

const LoaderWrapper = styled.div`
    padding: 20px;
`;

const CertificateTableRowActions = styled(TableRowActions)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    a {
        color: ${colors.DIRTY_WHITE};

        &:first-child {
            margin-bottom: 5px;
        }

        i {
            margin-right: 5px;
        }
    }
`;

const CertificateTable = styled(DefaultTable)`
    thead {
        color: ${colors.DIRTY_WHITE};
        font-size: 18px;
        padding: 12px;
    }

    tbody {
        td {
            padding: 12px;
            color: ${colors.DIRTY_WHITE};
        }
    }
`;

const Title = styled.div`
    padding: 10px 10px 5px 10px;
    font-size: 20px;
    background-color: ${colors.OUTER_BOX_BACKGROUND};
    border-bottom: 1px solid ${colors.DARK_DIVIDER};
`;

const EmployeeDsgvoCertificates = () => {
    const request = useCallback(async cancelToken => {
        const assignmentsPromise = getEmployeeInstructionAssignments(
            null,
            ['instruction', 'instruction.areas', 'groupAssignmentDetails'],
            cancelToken,
        );

        const currentEmployeePromise = getCurrentEmployee(
            ['areas'],
            cancelToken,
        );

        const [
            assignmentsResponse,
            currentEmployeeResponse,
        ] = await Promise.all([assignmentsPromise, currentEmployeePromise]);

        return {
            instructionAssignments: assignmentsResponse.data,
            currentEmployee: currentEmployeeResponse.data,
        };
    }, []);

    const {
        data: { instructionAssignments, currentEmployee },
        loadData,
        isLoading,
        hasError,
    } = useAxiosRequest(request, {
        instructionAssignments: [],
        currentEmployee: null,
    });

    const columns = useMemo(
        () => [
            {
                Header: 'Unterweisungsname',
                accessor: 'name',
            },

            {
                Header: 'Unterweisungskategorie',
                accessor: 'areas',
            },
            {
                Header: 'Aktion',
                accessor: 'actions',
                customCellStyles: {
                    maxWidth: 170,
                    textAlign: 'left',
                },
            },
        ],
        [],
    );

    const data = useMemo(() => {
        if (currentEmployee === null) {
            return [];
        }

        let filteredInstructionAssignments = [...instructionAssignments];

        return filteredInstructionAssignments
            .filter(assignment => {
                if (assignment.examStatus === examStatuses.COMPLETED) {
                    if (assignment.isSingleAssignment) {
                        return true;
                    }

                    if (assignment.isGroupAssignment) {
                        const employeeIds =
                            assignment.groupAssignmentDetails.assignees
                                ?.filter(assignee => assignee.isSignaturePlaced)
                                ?.map(assignee => assignee.employee.id) ?? [];

                        const groupLeader =
                            assignment.groupAssignmentDetails.groupLeader;

                        if (groupLeader.isSignaturePlaced) {
                            employeeIds.push(groupLeader.employee.id);
                        }

                        return employeeIds.includes(currentEmployee.id);
                    }
                }

                return false;
            })
            .map(assignment => {
                const areas = currentEmployee.areas.filter(area =>
                    assignment.instruction.areas
                        .map(instructionArea => instructionArea.id)
                        .includes(area.id),
                );

                const isAssignee =
                    assignment.isSingleAssignment ||
                    (assignment.isGroupAssignment &&
                        assignment.groupAssignmentDetails?.assignees.findIndex(
                            assignee =>
                                assignee.employee.id === currentEmployee.id,
                        ) !== -1);

                return {
                    name: assignment.name,
                    areas: areas.map(area => area.name).join(', '),
                    actions: (
                        <CertificateTableRowActions>
                            {isAssignee && (
                                <Button
                                    text
                                    icon={<CloudDownloadRounded />}
                                    href={`/api/education/instruction-assignments/${assignment.id}/certificates/single/download`}
                                    target="_blank"
                                >
                                    Zertifikat
                                </Button>
                            )}
                            {assignment.isGroupAssignment && (
                                <Button
                                    text
                                    icon={<CloudDownloadRounded />}
                                    href={`/api/education/instruction-assignments/${assignment.id}/certificates/group/download`}
                                    target="_blank"
                                >
                                    Gruppenzertifikat
                                </Button>
                            )}
                        </CertificateTableRowActions>
                    ),
                };
            });
    }, [instructionAssignments, currentEmployee]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useCustomCellStyles,
    );

    return (
        <Wrapper>
            <Title>Zertifikate</Title>
            {isLoading ? (
                <LoaderWrapper>
                    <Loader />
                </LoaderWrapper>
            ) : hasError ? (
                <LoaderWrapper>
                    <RetryLoading onRetry={loadData} />
                </LoaderWrapper>
            ) : (
                <CertificateTable
                    headerGroups={headerGroups}
                    rows={rows}
                    getTableProps={getTableProps}
                    getTableBodyProps={getTableBodyProps}
                    prepareRow={prepareRow}
                />
            )}
        </Wrapper>
    );
};

export default EmployeeDsgvoCertificates;
