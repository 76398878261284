import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Input from 'Common/components/Form/Fields/Input';
import { debounce } from 'lodash';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import hasUnsavedChangesState from 'Common/recoil/hasUnsavedChangesState';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import changeTemplateName from 'ProjectManager/Template/Common/api/dataManagement/changeTemplateName';
import withName from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/withName';

const TemplateName = () => {
    const [{ id: templateId, name: initialName }, setTemplate] = useRecoilState(
        templateAtom,
    );

    const [, setHasUnsavedChanges] = useRecoilState(hasUnsavedChangesState);

    const [isTouched, setIsTouched] = useState(false);
    const [name, setName] = useState(initialName ?? '');

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const debouncedSave = useCallback(
        debounce(
            async value => {
                try {
                    await changeTemplateName(templateId, value, source.token);

                    setTemplate(withName(value));

                    setHasUnsavedChanges(false);
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        notify(
                            'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                            {
                                type: toast.TYPE.ERROR,
                            },
                        );
                    }
                }
            },
            350,
            {
                leading: true,
                trailing: true,
            },
        ),
        [templateId, setTemplate, setHasUnsavedChanges, source.token],
    );

    const handleChange = e => {
        const newName = e.target.value;

        if (newName.length <= 255) {
            setIsTouched(true);
            setHasUnsavedChanges(true);
            setName(e.target.value);

            if (templateId) {
                debouncedSave(e.target.value);
            }
        }
    };

    return (
        <Input
            id="name"
            name="name"
            label="Vorlagenbezeichnung"
            value={name}
            onChange={handleChange}
            disabled={!templateId}
            touched={isTouched}
            error={name === '' ? 'Pflichtangabe' : undefined}
            isRequired
        />
    );
};

export default TemplateName;
