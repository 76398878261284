import React from 'react';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { Tooltip } from '@material-ui/core';

const Wrapper = styled.span`
    position: relative;
    top: -2px;
    left: -1px;
    color: ${colors.SEARCH_FIELD_BORDER};
    font-weight: 600;
    font-size: 14px;
`;

const RequiredAsterisk = () => (
    <Tooltip title="Pflichtangabe">
        <Wrapper className="required-asterisk">*</Wrapper>
    </Tooltip>
);

export default RequiredAsterisk;
