import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';

const useProjectQuestionnaireSections = () => {
    const { questionnaireSections } = useRecoilValue(projectAtom);

    return questionnaireSections;
};

export default useProjectQuestionnaireSections;
