import {
    InfoRounded,
    LocalHospitalRounded,
    PeopleOutlineRounded,
} from '@material-ui/icons';
import Topics from 'Common/components/Icons/Topics';

const navigationItems = {
    GENERAL_INFORMATION: 'GENERAL_INFORMATION',
    EMPLOYEE_SELECTION: 'EMPLOYEE_SELECTION',
    TOPIC_MANAGEMENT: 'TOPIC_MANAGEMENT',
};

const navigationItemsData = {
    [navigationItems.GENERAL_INFORMATION]: {
        icon: InfoRounded,
        name: 'Allgemeine Informationen',
    },
    [navigationItems.EMPLOYEE_SELECTION]: {
        icon: PeopleOutlineRounded,
        name: 'Personaleinsatzplanung',
    },
    [navigationItems.TOPIC_MANAGEMENT]: {
        icon: Topics,
        name: 'Auswahl Projektfelder',
    },
};

// @TODO: Compete the list once Amedic are done with the topics, sections, etc.
const questionnaireSectionIcons = {
    GENERAL_ORGANIZATION_SAFETY: LocalHospitalRounded,
};

export { navigationItems, navigationItemsData, questionnaireSectionIcons };
