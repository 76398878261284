import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import modules from 'Common/constants/modules';
import { useSelector } from 'react-redux';
import roles from 'User/constants/roles';
import colors from 'Common/constants/colors';

const Wrapper = styled.div`
    order: 3;
    display: flex;
    flex-wrap: wrap;
    margin-top: -1px;
    margin-left: -1px;
`;

const Tile = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
    width: calc(50% - 1px);
    margin-top: 1px;
    margin-left: 1px;
    padding: 20px;
    border: 0 none;
    outline: 0 none;
    font-size: 14px;

    ${ifProp(
        'isSelected',
        css`
            background: ${colors.WHITE};
            color: ${colors.DARK_GRAY};
            cursor: default;
        `,
        css`
            background: ${colors.INNER_BOX_BACKGROUND};
            color: ${colors.DIRTY_WHITE};
            cursor: pointer;

            &:hover {
                background: ${colors.DARK_DIVIDER};
            }
        `,
    )};

    &:first-child {
        border-radius: 6px 0 0 0;
    }

    &:nth-child(2) {
        border-radius: 0 6px 0 0;
    }

    &:nth-child(3) {
        border-radius: 0 0 0 6px;
    }

    &:last-child {
        border-radius: 0 0 6px 0;
    }

    @media screen and (min-width: 680px) and (max-width: 939px) {
        &:first-child {
            border-radius: 6px 0 0 6px;
        }

        &:nth-child(2),
        &:nth-child(3) {
            border-radius: 0;
        }

        &:last-child {
            border-radius: 0 6px 6px 0;
        }
    }

    @media screen and (min-width: 680px) {
        width: calc(25% - 1px);
    }

    @media screen and (min-width: 940px) {
        width: calc(50% - 1px);
    }
`;

const TileLink = styled(Tile).attrs({
    as: 'a',
})`
    text-decoration: none;
`;

const ModuleTiles = ({ selectedModule, onModuleTileClick, employee }) => {
    const role = useSelector(state => state.user.role);

    return (
        <Wrapper>
            <Tile
                isSelected={selectedModule === modules.INSTRUCTIONS}
                disabled={selectedModule === modules.INSTRUCTIONS}
                onClick={() => onModuleTileClick(modules.INSTRUCTIONS)}
            >
                Unterweisungen
            </Tile>
            <Tile
                isSelected={selectedModule === modules.MEASURES}
                disabled={selectedModule === modules.MEASURES}
                onClick={() => onModuleTileClick(modules.MEASURES)}
            >
                Maßnahmen
            </Tile>
            <TileLink
                href={
                    role === roles.MANAGER
                        ? `/backend/m/exec/call?emp=${employee.id}`
                        : '/backend/m/exec/call'
                }
            >
                Ergänzende GF
            </TileLink>
            <TileLink href="/backend/m/docs/call">Dokumente</TileLink>
        </Wrapper>
    );
};

ModuleTiles.propTypes = {
    selectedModule: PropTypes.string.isRequired,
    onModuleTileClick: PropTypes.func.isRequired,
    employee: PropTypes.object.isRequired,
};

export default ModuleTiles;
