import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import getQuestionnaireSubsectionIdentifier from 'ProjectManager/DataManagement/Questionnaire/helpers/getQuestionnaireSubsectionIdentifier';
import DataManagementBottomButtons from 'ProjectManager/DataManagement/Common/components/DataManagementBottomButtons';
import Subsection from 'ProjectManager/DataManagement/Questionnaire/components/Subsection/components/Subsection';
import SubsectionCreation from 'ProjectManager/DataManagement/Questionnaire/components/SubsectionCreation/components/SubsectionCreation';
import NextButton from 'ProjectManager/DataManagement/Questionnaire/components/Questionnaire/components/NextButton';

const Wrapper = styled.div``;

const Subsections = styled.div``;

const Questionnaire = ({ section, onNextButtonClick }) => (
    <Wrapper>
        <Subsections>
            {section.subsections.map(subsection => (
                <Subsection
                    key={getQuestionnaireSubsectionIdentifier(subsection)}
                    subsection={subsection}
                    section={section}
                />
            ))}
        </Subsections>
        <SubsectionCreation section={section} />
        <DataManagementBottomButtons>
            <NextButton section={section} onClick={onNextButtonClick} />
        </DataManagementBottomButtons>
    </Wrapper>
);

Questionnaire.propTypes = {
    section: PropTypes.object.isRequired,
    onNextButtonClick: PropTypes.func.isRequired,
};

export default Questionnaire;
