import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';

const useProjectFiles = () => {
    const { files } = useRecoilValue(projectAtom);

    return files;
};

export default useProjectFiles;
