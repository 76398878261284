import api from 'Application/api/api';

const startSingleInstructionAssignmentPresentation = (id, cancelToken = null) =>
    api.post(
        `/education/instruction-assignments/${id}/presentation/start/single`,
        {},
        { cancelToken },
    );

export default startSingleInstructionAssignmentPresentation;
