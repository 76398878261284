import React, { useCallback, useEffect, useState } from 'react';
import MaterialAccordion from '@material-ui/core/Accordion';
import MaterialAccordionSummary from '@material-ui/core/AccordionSummary';
import MaterialAccordionDetails from '@material-ui/core/AccordionDetails';
import { ExpandMoreRounded } from '@material-ui/icons';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import getArchivedEmployeeDocuments from 'Archive/api/getArchivedEmployeeDocuments';
import styled from 'styled-components/macro';
import { useParams } from 'react-router';
import { orderBy } from 'lodash';
import DocumentsTable from 'Archive/components/employee/DocumentsTable';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import getEmployee from 'Employee/api/getEmployee';
import { replace } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import colors from 'Common/constants/colors';
import OuterBox from 'Common/components/Boxes/OuterBox';
import BackButton from 'Application/components/Header/BackButton/BackButton';
import parseDate from 'Common/utils/parseDate';
import formatDate from 'Common/utils/formatDate';
import getYear from 'date-fns/getYear';
import createNewDate from 'Common/utils/createNewDate';

const Wrapper = styled.div`
    max-width: 980px;
    margin: 15px auto 0;
`;

const Accordion = styled(MaterialAccordion)`
    margin-bottom: 30px;
    background: ${colors.OUTER_BOX_BACKGROUND} !important;
    color: ${colors.DIRTY_WHITE} !important;
    box-shadow: 0 7px 10px rgba(0, 0, 0, 0.16) !important;

    &:last-child {
        margin-bottom: 0;
    }

    &.MuiAccordion-root {
        &:before {
            display: none;
        }

        &.MuiAccordion-rounded {
            border-radius: 6px;
        }

        &.MuiPaper-elevation1 {
            box-shadow: none;
        }
    }
`;

const AccordionSummary = styled(MaterialAccordionSummary)`
    &.MuiAccordionSummary-root {
        .MuiIconButton-root {
            color: ${colors.DIRTY_WHITE};
        }

        .MuiAccordionSummary-content {
            margin: 25px 0;
        }
    }
`;

const AccordionDetails = styled(MaterialAccordionDetails)`
    display: block !important;
`;

const EmptyWrapper = styled(OuterBox)`
    padding: 55px 20px;
    text-align: center;
    color: ${colors.GRAY};
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;

    @media screen and (min-width: 940px) {
        padding-top: 125px;
        padding-bottom: 125px;
    }
`;

const EmployeeArchive = () => {
    const [expandedMonth, setExpandedMonth] = useState(null);

    const handleToggleMonth = (month, isExpanded) => {
        setExpandedMonth(isExpanded ? month : null);
    };

    const dispatch = useDispatch();
    const { id } = useParams();

    const employeeRequest = useCallback(
        cancelToken => getEmployee(id, [], cancelToken),
        [id],
    );

    const isUsingOnlyNewModules = useSelector(
        state => state.user.isUsingOnlyNewModules,
    );

    const {
        data: employee,
        loadData: loadEmployee,
        isLoading: isLoadingEmployee,
        hasError: hasEmployeeError,
        error: employeeError,
    } = useAxiosRequest(employeeRequest, null);

    useEffect(() => {
        if (employeeError?.response?.status === 404) {
            dispatch(replace('/404'));
        }
    }, [dispatch, employeeError]);

    const archivedDocumentsRequest = useCallback(
        cancelToken => getArchivedEmployeeDocuments(id, cancelToken),
        [id],
    );

    const {
        data: archivedDocuments,
        loadData: loadArchivedDocuments,
        isLoading: isLoadingDocuments,
        hasError: hasDocumentsError,
    } = useAxiosRequest(archivedDocumentsRequest, []);

    const isLoading = isLoadingEmployee || isLoadingDocuments;
    const hasError = hasEmployeeError || hasDocumentsError;

    if (isLoading) {
        return <Loader />;
    }

    if (hasError) {
        return (
            <RetryLoading
                onRetry={() => {
                    if (hasEmployeeError) {
                        loadEmployee();
                    }

                    if (hasDocumentsError) {
                        loadArchivedDocuments();
                    }
                }}
            />
        );
    }

    let documentsByMonth = [];

    archivedDocuments.forEach(document => {
        let month = formatDate(parseDate(document.archivedAt), 'yyyy-MM');
        let index = documentsByMonth.findIndex(item => item.month === month);

        if (index > -1) {
            documentsByMonth[index].documents.push(document);
        } else {
            documentsByMonth.push({ month, documents: [document] });
        }
    });

    documentsByMonth.forEach(item => {
        item.documents = orderBy(item.documents, ['archivedAt'], ['desc']);
    });

    documentsByMonth = orderBy(documentsByMonth, ['month'], ['desc']);

    const year = getYear(createNewDate());

    return (
        <>
            <BackButton to="/archive/employees" />
            {isUsingOnlyNewModules ? (
                <>
                    <Helmet>
                        <title>{`${employee.lastName}, ${employee.firstName} - Archive`}</title>
                    </Helmet>
                    <Breadcrumb to="/archive/employees">Archiv</Breadcrumb>
                </>
            ) : (
                <>
                    <Helmet>
                        <title>{`${employee.lastName}, ${employee.firstName} - Mitarbeiter (2020 - ${year}) - Archive`}</title>
                    </Helmet>
                    <Breadcrumb to="/archive">Archiv</Breadcrumb>
                    <Breadcrumb to="/archive/employees">
                        {`Mitarbeiter (2020 - ${year})`}
                    </Breadcrumb>
                </>
            )}
            <Breadcrumb
                isActive
            >{`${employee.lastName}, ${employee.firstName}`}</Breadcrumb>
            <Wrapper>
                {documentsByMonth.length > 0 ? (
                    documentsByMonth.map(item => {
                        const date = parseDate(item.documents[0].archivedAt);

                        return (
                            <Accordion
                                key={item.month}
                                expanded={expandedMonth === item.month}
                                onChange={(e, isExpanded) =>
                                    handleToggleMonth(item.month, isExpanded)
                                }
                            >
                                <AccordionSummary
                                    id={`documents-${item.month}-header`}
                                    aria-controls={`documents-${item.month}-content`}
                                    expandIcon={<ExpandMoreRounded />}
                                >
                                    {formatDate(date, 'MMMM, yyyy')}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <DocumentsTable
                                        documents={item.documents}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        );
                    })
                ) : (
                    <EmptyWrapper>
                        Es liegen derzeit keine archivierten Dokumente vor.
                    </EmptyWrapper>
                )}
            </Wrapper>
        </>
    );
};

export default EmployeeArchive;
