import api from 'Application/api/api';

const getClients = (load, cancelToken = null) =>
    api.get('project-manager/clients', {
        params: {
            load,
        },
        cancelToken,
    });

export default getClients;
