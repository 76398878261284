import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import axios from 'axios';
import Input from 'Common/components/Form/Fields/Input';
import { SearchRounded } from '@material-ui/icons';
import EmployeeList from 'ProjectManager/DataManagement/EmployeeSelection/components/EmployeeList';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import PersonAdd from 'Common/components/Icons/PersonAdd';
import { orderBy } from 'lodash';
import isEmployeeMatched from 'ProjectManager/DataManagement/EmployeeSelection/helpers/isEmployeeMatched';
import Button from 'Common/components/Button';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Inner = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px 10px 0 0;
    overflow: hidden;
`;

const SearchBar = styled.div`
    width: calc(100% - 10px);
    margin-bottom: 10px;

    @media screen and (min-width: 430px) {
        max-width: 220px;
    }
`;

const BottomButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    padding-right: 20px;
`;

const AvailableEmployees = ({
    assignableEmployees,
    participatingEmployees,
    onAdd,
    onAfterAdd,
    isInsideModal,
}) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchTermChange = e => {
        setSearchTerm(e.target.value);
    };

    const selectedEmployeeIds = participatingEmployees.map(
        employee => employee.id,
    );

    // Exclude the selected ones
    const availableEmployees = orderBy(
        assignableEmployees.filter(
            assignableEmployee =>
                !selectedEmployeeIds.includes(assignableEmployee.employee.id) &&
                isEmployeeMatched(assignableEmployee.employee, searchTerm),
        ),
        ['employee.lastName', 'employee.firstName'],
        ['asc', 'asc'],
    );

    const [animatedAddedIds, setAnimatedAddedIds] = useState([]);

    const handleActionButtonClick = async employeeId => {
        try {
            const newValue = await onAdd([employeeId]);

            if (isInsideModal) {
                setAnimatedAddedIds([employeeId]);
            }

            setTimeout(
                () => {
                    // Clear the filter if this employee was the last one from the search
                    if (availableEmployees.length === 1 && searchTerm !== '') {
                        setSearchTerm('');
                    }

                    setAnimatedAddedIds([]);

                    onAfterAdd([employeeId], newValue);
                },
                isInsideModal ? 700 : 0,
            );
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    const [isAddingAll, setIsAddingAll] = useState(false);

    const handleAddAllButtonClick = async () => {
        setIsAddingAll(true);

        try {
            const employeeIds = availableEmployees.map(
                assignableEmployee => assignableEmployee.employee.id,
            );

            const newValue = await onAdd(employeeIds);

            if (isInsideModal) {
                setAnimatedAddedIds(employeeIds);
            }

            setTimeout(
                () => {
                    // Clear the filter if all employees were added and no one is left from the search
                    if (searchTerm !== '') {
                        setSearchTerm('');
                    }

                    setAnimatedAddedIds([]);

                    // Reverse the employee ids, because the list of selected ones
                    // will show them in reverse order.
                    onAfterAdd(
                        [...employeeIds].reverse(),
                        newValue,
                    );
                },
                isInsideModal ? 700 : 0,
            );
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }

        setIsAddingAll(false);
    };

    return (
        <Wrapper>
            <Inner>
                <SearchBar>
                    <Input
                        id="availableEmployeesSearchTerm"
                        name="availableEmployeesSearchTerm"
                        label="Suche"
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                        icon={SearchRounded}
                        disabled={isAddingAll}
                    />
                </SearchBar>
                <EmployeeList
                    title="Verfügbare Mitarbeiter"
                    actionLabel="Hinzufügen"
                    actionIcon={<PersonAdd />}
                    onActionButtonClick={handleActionButtonClick}
                    isActionButtonDisabled={isAddingAll}
                    noResultsText="Keine verfügbare Mitarbeiter"
                    assignableEmployees={availableEmployees}
                    isInsideModal={isInsideModal}
                    animatedIds={animatedAddedIds}
                    animationText="Hinzugefügt"
                />
            </Inner>
            {availableEmployees.length > 1 && (
                <BottomButtonWrapper>
                    <Button
                        dark={isInsideModal}
                        underline
                        icon={<PersonAdd />}
                        type="button"
                        onClick={handleAddAllButtonClick}
                        isLoading={isAddingAll}
                        disabled={isAddingAll}
                    >
                        Alle hinzufügen
                    </Button>
                </BottomButtonWrapper>
            )}
        </Wrapper>
    );
};

AvailableEmployees.defaultProps = {
    isInsideModal: false,
};

AvailableEmployees.propTypes = {
    assignableEmployees: PropTypes.array.isRequired,
    participatingEmployees: PropTypes.array.isRequired,
    onAdd: PropTypes.func.isRequired,
    onAfterAdd: PropTypes.func.isRequired,
    isInsideModal: PropTypes.bool,
};

export default AvailableEmployees;
