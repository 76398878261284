import React, { useEffect, useMemo } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import getProject from 'ProjectManager/Project/Common/api/getProject';
import Loader from 'Common/components/Loader';
import RetryLoading from 'Common/components/RetryLoading';
import Grid from 'ProjectManager/Project/Preview/Grid/components/Grid';
import { Helmet } from 'react-helmet-async';
import BackButton from 'Application/components/Header/BackButton/BackButton';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import { usePrevious } from 'react-use';
import axios from 'axios';
import useProjectForDataManagement from 'ProjectManager/Project/Common/hooks/useProjectForDataManagement';

const load = ['statistics'];

const ProjectPreview = () => {
    const { id } = useParams();

    const {
        project,
        setProject,
        loadProject,
        isLoading,
        hasError,
        error,
    } = useProjectForDataManagement(id, load);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const { participants, areas } = project;
    const prevParticipants = usePrevious(participants);
    const prevAreas = usePrevious(areas);

    // Reload the statistics when the participating employees change
    useEffect(() => {
        const isFirstRender = prevParticipants === undefined;

        const areChangesDetected =
            participants !== prevParticipants || areas !== prevAreas;

        if (!isFirstRender && areChangesDetected) {
            (async () => {
                const response = await getProject(
                    id,
                    ['statistics', 'introductions'],
                    source.token,
                );

                setProject(prevProject => ({
                    ...prevProject,
                    statistics: response.data.statistics,
                }));
            })();
        }
    }, [
        participants,
        prevParticipants,
        areas,
        prevAreas,
        id,
        setProject,
        source.token,
    ]);

    if (
        hasError &&
        (error?.response?.status === 401 || error?.response?.status === 404)
    ) {
        return <Redirect to="/project-manager" />;
    }

    if (project.id && !project.isCreated) {
        return <Redirect to={`/project-manager/project/${id}/create`} />;
    }

    if (isLoading) {
        return <Loader />;
    }

    if (hasError) {
        return <RetryLoading onRetry={loadProject} />;
    }

    return (
        <>
            <Helmet>
                <title>{`${project.name} - Projektübersicht`}</title>
            </Helmet>
            <BackButton to="/project-manager" />
            <Breadcrumb to="/project-manager">Projektübersicht</Breadcrumb>
            <Breadcrumb isActive>{project.name}</Breadcrumb>
            <Grid />
        </>
    );
};

export default ProjectPreview;
