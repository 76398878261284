import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'Common/components/Button';
import AddTopic from 'Common/components/Icons/AddTopic';
import AddCustomTopic from 'ProjectManager/DataManagement/TopicManagement/components/CustomTopicManagement/components/AddCustomTopic';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';

const AddCustomTopicButton = ({ onAdd, onFormOpen }) => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);
    const isProject = dataManagementEntity === dataManagementEntities.PROJECT;

    const { status: projectStatus } = useRecoilValue(projectAtom);
    const isCreatingPossible = !isProject || projectStatus !== projectStatuses.ENDED;

    const [isAddFormOpened, setIsAddFormOpened] = useState(false);

    const handleAddButtonClick = () => {
        setIsAddFormOpened(true);
        onFormOpen();
    };

    const handleCancelAdd = () => {
        setIsAddFormOpened(false);
    };

    const handleAdd = async () => {
        await onAdd();

        setIsAddFormOpened(false);
    };

    if (isAddFormOpened) {
        return <AddCustomTopic onAdd={handleAdd} onCancel={handleCancelAdd} />;
    }

    return (
        <Button
            underline
            icon={<AddTopic />}
            onClick={handleAddButtonClick}
            disabled={!isCreatingPossible}
            tooltip={
                !isCreatingPossible
                    ? 'Erstellung nicht erlaubt. Projekt ist im Status Beendet.'
                    : ''
            }
        >
            Eigenes Projektfeld erstellen
        </Button>
    );
};

AddCustomTopicButton.propTypes = {
    onAdd: PropTypes.func.isRequired,
    onFormOpen: PropTypes.func.isRequired,
};

export default AddCustomTopicButton;
