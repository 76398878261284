import { useEffect, useState } from 'react';

const processDevice = videoDevice =>
    new Promise(async resolve => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: { deviceId: { exact: videoDevice.deviceId } },
            });

            let video = document.createElement('video');
            video.autoplay = true;
            video.muted = false;

            video.addEventListener('loadedmetadata', () => {
                // The timeout is needed, because the capabilities are not present from
                // the very beginning.
                setTimeout(() => {
                    const track = stream.getVideoTracks()[0];
                    const capabilities = track.getCapabilities();

                    stream.getTracks().forEach(track => track.stop());

                    video.srcObject = null;

                    if (video.src) {
                        window.URL.revokeObjectURL(video.src);
                    }

                    video.remove();

                    resolve({
                        device: videoDevice,
                        stream,
                        track,
                        capabilities,
                        isAvailable: !!stream,
                    });
                }, 500);
            });

            try {
                video.srcObject = stream;
            } catch (error) {
                video.src = window.URL.createObjectURL(stream);
            }
        } catch (error) {
            resolve({
                device: videoDevice,
                stream: null,
                track: null,
                capabilities: {},
                isAvailable: false,
                error,
            });
        }
    });

export const useVideoInputDevices = () => {
    const [devices, setDevices] = useState(
        window.mediaDevicesConfiguration ?? null,
    );

    useEffect(() => {
        if (!window.mediaDevicesConfiguration) {
            (async () => {
                // getUserMedia to ensure devices are available for enumeration in Safari
                await navigator.mediaDevices.getUserMedia({
                    audio: false,
                    video: true,
                });

                const allDevices = await navigator.mediaDevices.enumerateDevices();
                const videoDevices = allDevices.filter(
                    device => device.kind === 'videoinput',
                );

                const devicesConfiguration = [];

                for (let i in videoDevices) {
                    const videoDevice = videoDevices[i];
                    const deviceConfiguration = await processDevice(
                        videoDevice,
                    );

                    devicesConfiguration.push(deviceConfiguration);
                }

                window.mediaDevicesConfiguration = devicesConfiguration;

                setDevices(devicesConfiguration);
            })();
        }
    }, []);

    return devices;
};
