import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import Modal from 'Common/components/Modals/Modal';
import useHideQuestionFunction from 'ProjectManager/DataManagement/Questionnaire/components/Question/ShowHideControl/hooks/hideQuestionFunctions/useHideQuestionFunction';
import { useSetRecoilState } from 'recoil';
import questionWithOpenedImagePreviewAtom from 'ProjectManager/DataManagement/Common/recoil/questionWithOpenedImagePreview/questionWithOpenedImagePreviewAtom';

const QuestionHidingModal = ({ question, onCancel, onSuccess }) => {
    const hideQuestion = useHideQuestionFunction(
        question.id,
        question.isCustom,
    );

    const setQuestionWithOpenedImagePreview = useSetRecoilState(
        questionWithOpenedImagePreviewAtom,
    );

    const [isHiding, setIsHiding] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirm = async () => {
        setIsHiding(true);

        try {
            await hideQuestion();
            await onSuccess();

            setQuestionWithOpenedImagePreview(null);

            notify('Die Frage wurde erfolgreich ausgeblendet', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify('Die Frage konnte nicht versteckt werden', {
                    type: toast.TYPE.ERROR,
                });
            }
        }

        setIsHiding(false);
    };

    return (
        <Modal
            isOpen
            onRequestClose={setIsHiding ? undefined : onCancel}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onCancel}
                        disabled={isHiding}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isHiding}
                        disabled={isHiding}
                        onClick={handleConfirm}
                    >
                        Ausblenden
                    </Button>
                </>
            }
        >
            <ModalTitle>Wollen Sie die Frage wirklich ausblenden?</ModalTitle>
        </Modal>
    );
};

QuestionHidingModal.propTypes = {
    question: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default QuestionHidingModal;
