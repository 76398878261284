import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ReactSignaturePad from 'react-signature-pad-wrapper';
import colors from 'Common/constants/colors';
import InnerBox from 'Common/components/Boxes/InnerBox';
import { DeleteRounded } from '@material-ui/icons';
import Signature from 'Common/components/Icons/Signature';

const Wrapper = styled(InnerBox)`
    position: relative;
    cursor: crosshair;
`;

const SignatureText = styled.div`
    max-width: 250px;
    text-align: left;
    color: ${colors.GRAY};
    font-size: 14px;
`;

const ClearButton = styled.div`
    position: absolute;
    bottom: 30px;
    right: 30px;
    color: ${colors.GRAY};
    cursor: pointer;
`;

const InformationWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    top: 20px;
    left: 20px;
    pointer-events: none;
    user-select: none;

    > svg {
        font-size: 42px;
        margin-right: 10px;
        color: ${colors.GRAY};
    }
`;

const SignaturePad = ({ light, innerRef, className, ...props }) => {
    const handleClearSignature = () => {
        innerRef.current.clear();
    };

    return (
        <Wrapper light={light} className={className}>
            <InformationWrapper>
                <Signature />
                <SignatureText>
                    Unterschreiben sie in der dafür vorgesehenden Fläche
                </SignatureText>
            </InformationWrapper>
            <ReactSignaturePad ref={innerRef} {...props} />
            <ClearButton onClick={handleClearSignature}>
                <DeleteRounded />
            </ClearButton>
        </Wrapper>
    );
};

SignaturePad.defaultProps = {
    light: false,
};

SignaturePad.propTypes = {
    light: PropTypes.bool,
};

export default forwardRef((props, ref) => (
    <SignaturePad innerRef={ref} {...props} />
));
