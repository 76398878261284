import { useWindowWidth } from '@react-hook/window-size';

const useProjectsTableRowHeight = () => {
    const windowWidth = useWindowWidth();

    if (windowWidth < 375) {
        return 448;
    }

    if (windowWidth < 390) {
        return 431;
    }

    if (windowWidth < 560) {
        return 289;
    }

    if (windowWidth < 940) {
        return 225;
    }

    if (windowWidth < 1180) {
        return 121;
    }

    if (1180 <= windowWidth && windowWidth < 1340) {
        return 225;
    }

    return 121;
};

export default useProjectsTableRowHeight;
