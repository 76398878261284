import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import colors from 'Common/constants/colors';
import CommonBox from 'Common/components/Boxes/CommonBox';

const InnerBox = styled(CommonBox)`
    ${ifProp(
        'light',
        css`
            background: ${colors.WHITE};
            color: ${colors.DARK_GRAY};
        `,
        css`
            background: ${colors.INNER_BOX_BACKGROUND};
        `,
    )};
`;

export default InnerBox;
