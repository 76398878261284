import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import ErrorMessage from 'Common/components/Form/ErrorMessage';
import StyledTextField from 'Common/components/Form/Fields/StyledTextField';
import RequiredAsterisk from 'Common/components/Form/RequiredAsterisk';

const StyledTextFieldArea = styled(StyledTextField)`
    textarea {
        min-height: 150px;
        resize: vertical;

        ${ifProp(
            '$resize',
            css`
                resize: ${prop('$resize')};
            `,
        )};
    }
`;

const Textarea = memo(
    ({ label, error, touched, readOnly, isRequired, resize, ...props }) => {
        const hasError = !!error && touched;
        const hasLabel = label !== null;

        const renderedLabel =
            hasLabel && isRequired ? (
                <>
                    {label} <RequiredAsterisk />
                </>
            ) : (
                label
            );

        return (
            <>
                <StyledTextFieldArea
                    variant="filled"
                    error={hasError}
                    label={hasLabel ? renderedLabel : undefined}
                    multiline
                    $resize={resize}
                    InputProps={{
                        readOnly,
                    }}
                    {...props}
                />

                {hasError && <ErrorMessage message={error} />}
            </>
        );
    },
);

Textarea.defaultProps = {
    label: null,
    error: null,
    touched: false,
    readOnly: false,
    isRequired: false,
    resize: null,
};

Textarea.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.bool,
    readOnly: PropTypes.bool,
    isRequired: PropTypes.bool,
    resize: PropTypes.string,
};

export default Textarea;
