import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import axios from 'axios';
import markDriverLicenseAsFound from 'Measure/api/driver-license/update/markDriverLicenseAsFound';
import Button from 'Common/components/Button';
import Modal from 'Common/components/Modals/Modal';
import ModalTitle from 'Common/components/Modals/ModalTitle';

const MarkButton = styled(Button)`
    width: 100%;
`;

const MarkAsFoundButton = ({ driverLicenseControl }) => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpened, setIsModalOpened] = useState(false);

    const openModal = () => {
        setIsModalOpened(true);
    };

    const closeModal = () => {
        setIsModalOpened(false);
    };

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirmMarkAsFound = async () => {
        setIsLoading(true);

        try {
            await markDriverLicenseAsFound(
                driverLicenseControl.id,
                source.token,
            );

            notify(
                'Der Führerschein wurde als gefunden markiert und aktiviert',
                {
                    type: toast.TYPE.SUCCESS,
                },
            );

            dispatch(push('/measures/vehicles/driver-licenses'));
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }

        setIsLoading(false);
    };

    return (
        <>
            <MarkButton
                outline
                type="button"
                isLoading={isLoading}
                disabled={isLoading}
                onClick={openModal}
            >
                Führerschein gefunden
            </MarkButton>
            {isModalOpened && (
                <Modal
                    isOpen={isModalOpened}
                    onRequestClose={isLoading ? undefined : closeModal}
                    buttons={
                        <>
                            <Button
                                text
                                dark
                                type="button"
                                onClick={closeModal}
                                disabled={isLoading}
                            >
                                Abbrechen
                            </Button>
                            <Button
                                type="button"
                                isLoading={isLoading}
                                onClick={handleConfirmMarkAsFound}
                                disabled={isLoading}
                            >
                                Bestätigen
                            </Button>
                        </>
                    }
                >
                    <ModalTitle>
                        Bestätigen Sie, dass Sie den Führerschein gefunden haben
                    </ModalTitle>
                </Modal>
            )}
        </>
    );
};

MarkAsFoundButton.propTypes = {
    driverLicenseControl: PropTypes.object.isRequired,
};

export default MarkAsFoundButton;
