import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Loader from 'Common/components/Loader';
import InstructionAssignmentsTableManagerView from 'Employee/components/View/Instruction/InstructionAssignmentsTableManagerView';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';
import getEmployeeInstructionAssignments from 'Education/api/instruction-assignment/getEmployeeInstructionAssignments';
import styled from 'styled-components/macro';
import withRestrictionCheckDuringArchivingProcess from 'Education/components/common/withRestrictionCheckDuringArchivingProcess';
import OuterBox from 'Common/components/Boxes/OuterBox';

const Content = styled(OuterBox)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    padding: 8px 0;
`;

const EmployeeInstructionAssignmentsManagerView = ({
    tabNumber,
    employeeId,
    showOnlyOpened,
}) => {
    const instructionAssignmentsRequest = useCallback(
        cancelToken =>
            getEmployeeInstructionAssignments(
                employeeId,
                ['groupAssignmentDetails'],
                cancelToken,
            ),
        [employeeId],
    );

    const {
        data: instructionAssignments,
        loadData: loadInstructionAssignments,
        isLoading,
        hasError,
    } = useAxiosRequest(instructionAssignmentsRequest, []);

    return (
        <Content>
            {isLoading ? (
                <Loader />
            ) : hasError ? (
                <RetryLoading onRetry={loadInstructionAssignments} />
            ) : (
                <InstructionAssignmentsTableManagerView
                    instructionAssignments={instructionAssignments}
                    employeeId={employeeId}
                    showOnlyOpened={showOnlyOpened}
                    tabNumber={tabNumber}
                />
            )}
        </Content>
    );
};

EmployeeInstructionAssignmentsManagerView.defaultProps = {
    showOnlyOpened: false,
    tabNumber: null,
};

EmployeeInstructionAssignmentsManagerView.propTypes = {
    tabNumber: PropTypes.number,
    employeeId: PropTypes.number.isRequired,
    showOnlyOpened: PropTypes.bool,
};

export default withRestrictionCheckDuringArchivingProcess(
    EmployeeInstructionAssignmentsManagerView,
);
