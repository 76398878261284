const withoutIntroductionPoint = (introductionId, point) => prevProject => {
    const index = prevProject.introductions.findIndex(
        introduction => introduction.id === introductionId,
    );

    if (index === -1) {
        return prevProject;
    }

    const pointIndex = prevProject.introductions[index].points.indexOf(point);

    if (pointIndex === -1) {
        return prevProject;
    }

    const newIntroductions = [...prevProject.introductions];
    const newPoints = [...newIntroductions[index].points];

    newPoints.splice(pointIndex, 1);

    newIntroductions[index] = {
        ...newIntroductions[index],
        points: newPoints,
    };

    return {
        ...prevProject,
        introductions: newIntroductions,
    };
};

export default withoutIntroductionPoint;
