import api from 'Application/api/api';

const getAssignableEmployeesForProject = (projectId, load = [], cancelToken = null) =>
    api.get(`project-manager/projects/${projectId}/employees/assignable`, {
        params: {
            load,
        },
        cancelToken,
    });

export default getAssignableEmployeesForProject;
