import { store } from 'Application/store';
import roles from 'User/constants/roles';

const isAllowedToExamineMeasure = measure => {
    const role = store.getState().user.role;

    if (role === roles.MANAGER) {
        return true;
    }

    const measuresForExamination = store.getState().user.measuresForExamination;

    if (!measuresForExamination) {
        return false;
    }

    const measureValues = measuresForExamination.map(measure => measure.value);

    return measureValues.includes(measure);
};

export default isAllowedToExamineMeasure;
