import api from 'Application/api/api';

const moveTemplateToGroup = (templateId, groupId, cancelToken = null) =>
    api.post(
        `project-manager/templates/${templateId}/move-to-group/${groupId}`,
        {},
        { cancelToken },
    );

export default moveTemplateToGroup;
