import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useDeleteProjectQuestionCommentFunction from 'ProjectManager/DataManagement/Questionnaire/components/Question/Comment/hooks/deleteQuestionCommentFunction/useDeleteProjectQuestionCommentFunction';
import useDeleteTemplateQuestionCommentFunction from 'ProjectManager/DataManagement/Questionnaire/components/Question/Comment/hooks/deleteQuestionCommentFunction/useDeleteTemplateQuestionCommentFunction';

const useDeleteQuestionCommentFunction = (questionId, isCustomQuestion) => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useDeleteProjectQuestionCommentFunction(
        questionId,
        isCustomQuestion,
    );

    const dataFromTemplate = useDeleteTemplateQuestionCommentFunction(
        questionId,
        isCustomQuestion,
    );

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return {};
};

export default useDeleteQuestionCommentFunction;
