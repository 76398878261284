import React from 'react';
import styled from 'styled-components/macro';
import useRestrictionCheckDuringArchivingProcess from 'Education/hooks/useRestrictionCheckDuringArchivingProcess';
import Countdown from 'Education/components/common/Countdown';
import OuterBox from 'Common/components/Boxes/OuterBox';
import colors from 'Common/constants/colors';

const Wrapper = styled(OuterBox)`
    width: 100%;
    padding: 55px 20px;
    text-align: center;

    @media screen and (min-width: 940px) {
        padding-top: 125px;
        padding-bottom: 125px;
    }
`;

const Text = styled.div`
    max-width: 940px;
    margin: 0 auto 40px;
    color: ${colors.GRAY};
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
`;

const Timer = styled.div`
    max-width: 380px;
    margin: 0 auto;
`;

const withRestrictionCheckDuringArchivingProcess = Component => props => {
    const {
        isRestricted,
        timeLeftUntilAccessible,
    } = useRestrictionCheckDuringArchivingProcess();

    if (isRestricted && timeLeftUntilAccessible) {
        return (
            <Wrapper>
                <Text>
                    Lieber Nutzer des Amedic-Manager, aktuell findet die
                    Archivierung der Unterweisungsnachweise für die
                    entsprechenden Nutzer statt. Nach erfolgter Archivierung
                    steht dir das Unterweisungsmodul wie gewohnt zur Verfügung.
                </Text>
                <Timer>
                    <Countdown {...timeLeftUntilAccessible} />
                </Timer>
            </Wrapper>
        );
    }

    return <Component {...props} />;
};

export default withRestrictionCheckDuringArchivingProcess;
