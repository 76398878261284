import React from 'react';
import PropTypes from 'prop-types';
import { WarningRounded } from '@material-ui/icons';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import WarningMessage from 'Common/components/Modals/WarningMessage';

const InstructionSaveModal = ({
    isOpen,
    isConfirmLoading,
    onConfirm,
    onCancel,
    stats,
    keepReference,
}) => (
    <Modal
        isOpen={isOpen}
        onRequestClose={isConfirmLoading ? undefined : onCancel}
        buttons={
            <>
                <Button
                    text
                    dark
                    type="button"
                    onClick={onCancel}
                    disabled={isConfirmLoading}
                >
                    Abbrechen
                </Button>
                <Button
                    type="button"
                    isLoading={isConfirmLoading}
                    onClick={onConfirm}
                    disabled={isConfirmLoading}
                >
                    Speichern
                </Button>
            </>
        }
    >
        <ModalTitle>
            Alle an der Präsentation vorgenommenen Änderungen speichern?
        </ModalTitle>
        {!!stats && stats.presentationsInProgressCount > 0 && (
            <>
                <p>
                    Hinweis: Der Fortschritt des Mitarbeiters wird zurückgesetzt
                </p>
                <WarningMessage>
                    {`Es gibt ${stats.presentationsInProgressCount} Mitarbeiter, die eine Präsentation halten`}
                </WarningMessage>
            </>
        )}
        {keepReference && (
            <WarningMessage>
                <WarningRounded />
                Sie haben eine Unterweisung mit einer Referenz auf die
                Bibliothek geändert. Alle Änderungen gehen verloren, wenn Amedic
                die Bibliothek synchronisiert. Stellen Sie sicher, dass Sie Ihre
                Unterweisung von der Bibliothek trennen, um überschriebene Daten
                zu vermeiden. Sie können zurückgehen und "Ohne Referenz" aus der
                Dropdown-Liste "Bibliothek Referenz" auswählen.
            </WarningMessage>
        )}
    </Modal>
);

InstructionSaveModal.defaultProps = {
    stats: null,
    keepReference: null,
};

InstructionSaveModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isConfirmLoading: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    stats: PropTypes.object,
    keepReference: PropTypes.bool,
};

export default InstructionSaveModal;
