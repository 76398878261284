import AsyncSelect from 'Common/components/Form/Fields/AsyncSelect';
import loadClientOptions from 'ProjectManager/Project/DataManagement/GeneralInformation/Client/helpers/loadClientOptions';
import reactSelectCustomComponents from 'Common/components/Form/Fields/reactSelectCustomComponents';
import CreateClientModal from 'ProjectManager/Project/DataManagement/GeneralInformation/Client/components/CreateClientModal';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Button from 'Common/components/Button';
import { components as reactSelectComponents } from 'react-select';
import { AddRounded } from '@material-ui/icons';

const CreateButton = styled(Button)`
    width: 100%;
    justify-content: flex-start;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;

    svg {
        font-size: 18px !important;
    }
`;

const MenuWithCreateButton = ({ children, ...props }) => (
    <reactSelectComponents.Menu {...props}>
        <CreateButton
            text
            dark
            icon={<AddRounded />}
            type="button"
            onClick={props.selectProps.onCreateButtonClick}
            onTouchStart={() => {
                document.isInsideOfMenuTouched = true;
            }}
        >
            Kundenerstellung
        </CreateButton>
        {children}
    </reactSelectComponents.Menu>
);

const useClientField = ({
    onCreateButtonClick,
    onClientCreate,
    selectProps = {},
}) => {
    // Keep track of how many clients were created in order to update the options
    // in the drop-down field for the clients.
    const [newClientsCreatedCount, setNewClientsCreatedCount] = useState(0);

    const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);

    const openCreateModal = () => {
        setIsCreateModalOpened(true);
    };

    const closeCreateModal = () => {
        setIsCreateModalOpened(false);
    };

    const handleCreateClientSuccess = async newClient => {
        await onClientCreate(newClient);
        setNewClientsCreatedCount(prevCount => prevCount + 1);
        setIsCreateModalOpened(false);
    };

    return {
        openCreateModal,
        closeCreateModal,
        field: (
            <>
                <AsyncSelect
                    key={newClientsCreatedCount}
                    id="client"
                    name="client"
                    label="Kunde"
                    loadOptions={loadClientOptions}
                    isSearchable
                    components={{
                        ...reactSelectCustomComponents,
                        Menu: MenuWithCreateButton,
                    }}
                    onCreateButtonClick={onCreateButtonClick ?? openCreateModal}
                    {...selectProps}
                />
                {isCreateModalOpened && (
                    <CreateClientModal
                        isOpen
                        onSuccess={handleCreateClientSuccess}
                        onCancel={closeCreateModal}
                        shouldReturnFocusAfterClose={false}
                    />
                )}
            </>
        ),
    };
};

export default useClientField;
