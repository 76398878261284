import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import isTemplateGroupNameUnique from 'ProjectManager/TemplateGroup/api/isTemplateGroupNameUnique';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import updateTemplateGroup from 'ProjectManager/TemplateGroup/api/updateTemplateGroup';
import { useSetRecoilState } from 'recoil';
import withUpdatedGroupData from 'ProjectManager/Template/List/recoil/groups/modifiers/withUpdatedGroupData';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import Input from 'Common/components/Form/Fields/Input';
import PropTypes from 'prop-types';
import groupsAtom from 'ProjectManager/Template/List/recoil/groups/groupsAtom';
import { pick } from 'lodash';

const fallbackValues = {
    name: '',
};

const EditModal = ({ group, onClose, ...props }) => {
    const [isUpdating, setIsUpdating] = useState(false);

    const setGroups = useSetRecoilState(groupsAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const uniqueNameValidationCallback = useCallback(
        async value => {
            if (!value) {
                return true;
            }

            try {
                const response = await isTemplateGroupNameUnique(
                    value,
                    group.id,
                    source.token,
                );

                return response.data.isUnique;
            } catch (error) {
                return axios.isCancel(error);
            }
        },
        [group.id, source.token],
    );

    const initialValues = useMemo(
        () => ({
            ...fallbackValues,
            ...pick(group, ['name']),
        }),
        [group],
    );

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                name: Yup.string()
                    .debouncedCallback(
                        'Der Name wird bereits verwendet',
                        uniqueNameValidationCallback,
                    )
                    .required('Pflichtangabe'),
            }),
        [uniqueNameValidationCallback],
    );

    const {
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async values => {
            setIsUpdating(true);

            try {
                await updateTemplateGroup(group.id, values, source.token);

                setGroups(withUpdatedGroupData(group.id, values));

                onClose();
            } catch (error) {
                if (!axios.isCancel(error)) {
                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }

            setIsUpdating(false);
        },
    });

    return (
        <Modal
            isOpen
            onRequestClose={isUpdating ? undefined : onClose}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onClose}
                        disabled={isUpdating}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isUpdating}
                        onClick={handleSubmit}
                        disabled={isUpdating || !isValid}
                    >
                        Speichern
                    </Button>
                </>
            }
            {...props}
        >
            <form onSubmit={handleSubmit}>
                <ModalTitle>Projektkategorie bearbeiten</ModalTitle>
                <Input
                    id="name"
                    name="name"
                    label="Projektkategorie"
                    value={values.name}
                    error={errors.name}
                    touched={touched.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isRequired
                />
            </form>
        </Modal>
    );
};

EditModal.propTypes = {
    group: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default EditModal;
