import api from 'Application/api/api';

const getTemplates = (load = [], cancelToken = null) =>
    api.get('project-manager/templates', {
        params: {
            load,
        },
        cancelToken,
    });

export default getTemplates;
