import api from 'Application/api/api';

const changeDriverLicenseControlMethod = (
    driverLicenseControlId,
    controlMethod,
    cancelToken = null,
) =>
    api.post(
        `/measures/vehicles/driver-licenses/controls/${driverLicenseControlId}/driver-license/change-control-method`,
        { controlMethod },
        {
            cancelToken,
        },
    );

export default changeDriverLicenseControlMethod;
