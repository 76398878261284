import React from 'react';
import styled from 'styled-components/macro';
import employeeStatusFilters from 'Employee/constants/employeeStatusFilters';
import PropTypes from 'prop-types';
import EmployeesTable from 'Archive/components/employee-list/EmployeesTable';
import OuterBox from 'Common/components/Boxes/OuterBox';

const Content = styled(OuterBox)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    padding: 8px 0;
`;

const List = ({ selectedStatus, employees }) => {
    let filteredEmployees = [...employees];

    if (selectedStatus === employeeStatusFilters.ACTIVE) {
        filteredEmployees = filteredEmployees.filter(
            employee => employee.isEnabled,
        );
    } else {
        filteredEmployees = filteredEmployees.filter(
            employee => !employee.isEnabled,
        );
    }

    return (
        <Content>
            <EmployeesTable employees={filteredEmployees} />
        </Content>
    );
};

List.propTypes = {
    selectedStatus: PropTypes.string.isRequired,
    employees: PropTypes.array.isRequired,
};

export default List;
