import api from 'Application/api/api';

const changeProjectName = (projectId, name, cancelToken = null) =>
    api.post(
        `project-manager/projects/${projectId}/change-name`,
        {
            name,
        },
        { cancelToken },
    );

export default changeProjectName;
