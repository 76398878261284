import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import getAssignableEmployeesForTemplate from 'ProjectManager/Template/Common/api/dataManagement/employee/getAssignableEmployeesForTemplate';
import assignEmployeesToTemplate from 'ProjectManager/Template/Common/api/dataManagement/employee/assignEmployeesToTemplate';
import getTemplate from 'ProjectManager/Template/Common/api/getTemplate';
import unassignEmployeesFromTemplate from 'ProjectManager/Template/Common/api/dataManagement/employee/unassignEmployeesFromTemplate';
import withParticipatingEmployees from 'ProjectManager/Template/Common/recoil/modifiers/withParticipatingEmployees';

const useTemplateEmployeeSelectionData = () => {
    const [
        { id: templateId, participatingEmployees, areas },
        setTemplate,
    ] = useRecoilState(templateAtom);

    const assignableEmployeesRequest = useCallback(
        cancelToken =>
            getAssignableEmployeesForTemplate(
                templateId,
                ['employee.user'],
                cancelToken,
            ),
        [templateId],
    );

    const {
        data: assignableEmployees,
        loadData: loadAssignableEmployees,
        isLoading,
        hasError,
    } = useAxiosRequest(assignableEmployeesRequest, [], {
        isManual: true,
    });

    // Whenever the areas change, reload the assignable employees,
    // as they are directly dependent
    useEffect(() => {
        if (templateId) {
            loadAssignableEmployees();
        }
    }, [templateId, areas, loadAssignableEmployees]);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const assignEmployees = async employeeIds => {
        await assignEmployeesToTemplate(templateId, employeeIds, source.token);

        const templateResponse = await getTemplate(
            templateId,
            [
                'participatingEmployees',
                'participatingEmployees.user',
                'participatingEmployees.areas',
            ],
            source.token,
        );

        return templateResponse.data.participatingEmployees;
    };

    const unassignEmployees = useCallback(
        async employeeIds => {
            await unassignEmployeesFromTemplate(
                templateId,
                employeeIds,
                source.token,
            );

            const templateResponse = await getTemplate(
                templateId,
                [
                    'participatingEmployees',
                    'participatingEmployees.user',
                    'participatingEmployees.areas',
                ],
                source.token,
            );

            return templateResponse.data.participatingEmployees;
        },
        [templateId, source.token],
    );

    const updateRecoilState = useCallback(
        newParticipatingEmployees => {
            setTemplate(withParticipatingEmployees(newParticipatingEmployees));
        },
        [setTemplate],
    );

    return templateId
        ? {
              isLoading,
              hasError,
              retryLoading: loadAssignableEmployees,
              assignableEmployees,
              participatingEmployees,
              assignEmployees,
              unassignEmployees,
              updateRecoilState,
              showWarningModalWhenNoEmployeesAreSelected: false,
          }
        : {};
};

export default useTemplateEmployeeSelectionData;
