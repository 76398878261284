import React, { useState } from 'react';
import PropTypes from 'prop-types';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import axios from 'axios';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import useDeleteCustomSubsectionFunction from 'ProjectManager/DataManagement/Questionnaire/components/Subsection/hooks/deleteCustomSubsectionFunction/useDeleteCustomSubsectionFunction';

const CustomSubsectionDeleteModal = ({ subsection, onCancel, onDelete }) => {
    const [isDeleting, setIsDeleting] = useState(false);

    const deleteCustomSubsection = useDeleteCustomSubsectionFunction();

    const handleConfirm = async () => {
        setIsDeleting(true);

        try {
            await deleteCustomSubsection(subsection.id);
            await onDelete();

            notify('Der Untersektion wurde erfolgreich gelöscht', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify('Der Untersektion konnte nicht gelöscht werden', {
                    type: toast.TYPE.ERROR,
                });
            }
        }

        setIsDeleting(false);
    };

    return (
        <Modal
            isOpen
            onRequestClose={isDeleting ? undefined : onCancel}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onCancel}
                        disabled={isDeleting}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isDeleting}
                        onClick={handleConfirm}
                        disabled={isDeleting}
                    >
                        Löschen
                    </Button>
                </>
            }
        >
            <ModalTitle>
                Wollen Sie den Untersektion {subsection.name} wirklich löschen?
            </ModalTitle>
            <p>
                Solltest du dich entscheiden die Untersektion zu löschen, werden
                alle Untersektion bezogenen Daten unwiderruflich gelöscht.
            </p>
        </Modal>
    );
};

CustomSubsectionDeleteModal.propTypes = {
    subsection: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default CustomSubsectionDeleteModal;
