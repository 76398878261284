import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import { useRecoilState } from 'recoil';
import ModalOption from 'Common/components/Modals/ModalOption';
import styled from 'styled-components/macro';
import ppeIcons from 'ProjectManager/Project/Introductions/Create/constants/ppeIcons';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import selectIntroductionPPE from 'ProjectManager/Project/Introductions/Common/api/selectIntroductionPPE';
import deselectIntroductionPPE from 'ProjectManager/Project/Introductions/Common/api/deselectIntroductionPPE';
import withIntroductionPPE from 'ProjectManager/Project/Common/recoil/project/modifiers/introduction/withIntroductionPPE';
import withoutIntroductionPPE from 'ProjectManager/Project/Common/recoil/project/modifiers/introduction/withoutIntroductionPPE';

const Options = styled.div`
    > * {
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const StyledModalOption = styled(ModalOption)`
    &[disabled] {
        opacity: 1;
        cursor: default !important;
        pointer-events: none;
    }
`;

const PPERefineModal = ({ ppes, introduction, onClose }) => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);
    const [loadingPPEValues, setLoadingPPEValues] = useState([]);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const startLoadingPPEValue = ppeValue => {
        setLoadingPPEValues(prevPPEValues => [...prevPPEValues, ppeValue]);
    };

    const stopLoadingPPEValue = ppeValue => {
        setLoadingPPEValues(prevPPEValues => {
            const index = prevPPEValues.indexOf(ppeValue);

            if (index === -1) {
                return prevPPEValues;
            }

            const newPPEValues = [...prevPPEValues];

            newPPEValues.splice(index, 1);

            return newPPEValues;
        });
    };

    const handleToggle = async (ppeValue, isChecked) => {
        startLoadingPPEValue(ppeValue);

        try {
            if (isChecked) {
                await selectIntroductionPPE(
                    projectId,
                    introduction.id,
                    ppeValue,
                    source.token,
                );

                setProject(withIntroductionPPE(introduction.id, ppeValue));
            } else {
                await deselectIntroductionPPE(
                    projectId,
                    introduction.id,
                    ppeValue,
                    source.token,
                );

                setProject(withoutIntroductionPPE(introduction.id, ppeValue));
            }
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }

        stopLoadingPPEValue(ppeValue);
    };

    return (
        <Modal
            isOpen
            onRequestClose={onClose}
            buttons={
                <Button type="button" onClick={onClose}>
                    OK
                </Button>
            }
        >
            <ModalTitle>Auswahl der PSA</ModalTitle>
            <p>
                Die ausgehändigte PSA sollte vom Mitarbeiter vor Gebrauch einer
                Sichtkontrolle unterzogen werden
            </p>
            <Options>
                {ppes.map(ppe => {
                    const Icon = ppeIcons[ppe.value];
                    const isLoading = loadingPPEValues.includes(ppe.value);

                    return (
                        <StyledModalOption
                            key={ppe.value}
                            icon={<Icon />}
                            title={ppe.label}
                            isChecked={introduction.ppes.includes(ppe.value)}
                            onToggle={isChecked =>
                                handleToggle(ppe.value, isChecked)
                            }
                            isLoading={isLoading}
                            disabled={isLoading}
                            isMulti
                        />
                    );
                })}
            </Options>
        </Modal>
    );
};

PPERefineModal.propTypes = {
    ppes: PropTypes.array.isRequired,
    introduction: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default PPERefineModal;
