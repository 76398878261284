import React from 'react';
import Button from 'Common/components/Button';
import styled from 'styled-components/macro';
import { useRecoilValue } from 'recoil';
import templateGroupsSelector from 'ProjectManager/Template/List/recoil/templateGroupsSelector';
import Group from 'ProjectManager/Template/List/components/Overview/Group/Group';

const Title = styled.div`
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 27px;

    @media screen and (min-width: 1180px) {
        margin-bottom: 20px;
        font-size: 22px;
        line-height: 33px;
    }
`;

const Groups = styled.div`
    margin-top: 40px;

    > div {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const Overview = () => {
    const templateGroups = useRecoilValue(templateGroupsSelector);

    return (
        <>
            <Title>Projektvorlagen</Title>
            <Button outline to="/project-manager/template/create">
                Projektvorlage erstellen
            </Button>
            <Groups>
                {templateGroups.map(group => (
                    <Group key={group.id} group={group} />
                ))}
            </Groups>
        </>
    );
};

export default Overview;
