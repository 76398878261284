import React from 'react';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import PropTypes from 'prop-types';

const LastTemplateGroupModal = ({
    isLoading,
    onConfirm,
    onCancel,
    ...props
}) => (
    <Modal
        isOpen
        onRequestClose={isLoading ? undefined : onCancel}
        buttons={
            <>
                <Button
                    text
                    dark
                    type="button"
                    onClick={onCancel}
                    disabled={isLoading}
                >
                    Abbrechen
                </Button>
                <Button
                    type="button"
                    isLoading={isLoading}
                    onClick={() => onConfirm()}
                    disabled={isLoading}
                >
                    Alle Löschen
                </Button>
            </>
        }
        {...props}
    >
        <ModalTitle>Projektkategorie löschen</ModalTitle>
        <p>
            Dies Kategorie besitzt Projektvorlagen, falls du mit der Löschung
            Fortfährst werden diese gleichfalls unwiderruflich gelöscht.
        </p>
    </Modal>
);

LastTemplateGroupModal.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default LastTemplateGroupModal;
