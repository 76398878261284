import React from 'react';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import ProjectFilters from 'ProjectManager/Project/List/components/Overview/ProjectsTable/Toolbar/ProjectFilters/ProjectFilters';
import Search from 'ProjectManager/Project/List/components/Overview/ProjectsTable/Toolbar/Search/Search';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid ${colors.DARK_DIVIDER};
`;

const Toolbar = () => (
    <Wrapper>
        <ProjectFilters />
        <Search />
    </Wrapper>
);

export default Toolbar;
