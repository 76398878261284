import { useRecoilValue } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import requestTemplateQuestionFilesDownload from 'ProjectManager/Template/Common/api/dataManagement/questionnaire/question/downloadRequest/requestTemplateQuestionFilesDownload';

const useRequestTemplateQuestionFilesDownloadFunction = (
    questionId,
    isCustomQuestion,
) => {
    const { id: templateId } = useRecoilValue(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const requestDownload = useCallback(async () => {
        await requestTemplateQuestionFilesDownload(
            templateId,
            questionId,
            isCustomQuestion,
            source.token,
        );
    }, [templateId, questionId, isCustomQuestion, source.token]);

    return requestDownload;
};

export default useRequestTemplateQuestionFilesDownloadFunction;
