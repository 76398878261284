import React, { useCallback } from 'react';
import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import { AddRounded } from '@material-ui/icons';
import getCompany from 'Company/api/getCompany';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import OuterBox from 'Common/components/Boxes/OuterBox';
import colors from 'Common/constants/colors';
import { Tooltip } from '@material-ui/core';

const CreateButton = styled(OuterBox).attrs({
    as: 'a',
})`
    display: flex;
    width: 100%;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    border: 0 none;
    outline: 0;
    text-decoration: none;

    &:hover {
        color: ${colors.DIRTY_WHITE};
    }

    ${ifProp(
        'isDisabled',
        css`
            opacity: 0.35;
            pointer-events: none;
        `,
    )};
`;

const EmployeeCreateButton = () => {
    const companyRequest = useCallback(
        cancelToken => getCompany(['usedEmployeeLicenses'], cancelToken),
        [],
    );

    const { data: company, isLoading, hasError } = useAxiosRequest(
        companyRequest,
        {},
    );

    let isLimitReached = false;

    if (company) {
        isLimitReached =
            company.usedEmployeeLicenses >= company.employeeLicensesLimit;
    }

    return (
        <Tooltip title={isLimitReached ? 'Limit erreicht' : ''}>
            <div>
                <CreateButton
                    isDisabled={isLoading || hasError || isLimitReached}
                    href="/backend/employees"
                >
                    Mitarbeiter
                    <AddRounded />
                </CreateButton>
            </div>
        </Tooltip>
    );
};

export default EmployeeCreateButton;
