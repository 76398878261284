import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';

const Wrapper = styled.div`
    margin-top: 5px;
    margin-bottom: -5px;
    padding-left: 5px;
    color: ${colors.ERROR};
    font-weight: 600;
    font-size: 13px;
    line-height: 21px;
    text-align: left;
`;

const ErrorMessage = ({ message, className }) => <Wrapper className={className}>{message}</Wrapper>;

ErrorMessage.propTypes = {
    message: PropTypes.string.isRequired,
};

export default ErrorMessage;
