import { selector } from 'recoil';
import isGeneralTemplateInformationStepCompletedSelector from 'ProjectManager/Template/Common/recoil/selectors/isGeneralTemplateInformationStepCompletedSelector';
import isTemplateEmployeeSelectionStepCompletedSelector from 'ProjectManager/Template/Common/recoil/selectors/isTemplateEmployeeSelectionStepCompletedSelector';
import isTemplateTopicManagementStepCompletedSelector from 'ProjectManager/Template/Common/recoil/selectors/isTemplateTopicManagementStepCompletedSelector';
import isTemplateQuestionnaireCompletedSelector from 'ProjectManager/Template/Common/recoil/selectors/isTemplateQuestionnaireCompletedSelector';

const areAllTemplateStepsCompletedSelector = selector({
    key: 'projectManagement_areAllTemplateStepsCompleted',
    get: ({ get }) => {
        const isGeneralTemplateInformationStepCompleted = get(
            isGeneralTemplateInformationStepCompletedSelector,
        );

        const isEmployeeSelectionStepCompleted = get(
            isTemplateEmployeeSelectionStepCompletedSelector,
        );

        const isTopicManagementStepCompleted = get(
            isTemplateTopicManagementStepCompletedSelector,
        );

        const isQuestionnaireCompleted = get(
            isTemplateQuestionnaireCompletedSelector,
        );

        return (
            isGeneralTemplateInformationStepCompleted &&
            isTopicManagementStepCompleted &&
            isEmployeeSelectionStepCompleted &&
            isQuestionnaireCompleted
        );
    },
});

export default areAllTemplateStepsCompletedSelector;
