import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useRequestProjectFilesDownloadFunction from 'ProjectManager/DataManagement/Files/hooks/requestFilesDownloadFunction/useRequestProjectFilesDownloadFunction';
import useRequestTemplateFilesDownloadFunction from 'ProjectManager/DataManagement/Files/hooks/requestFilesDownloadFunction/useRequestTemplateFilesDownloadFunction';

const useRequestFilesDownloadFunction = () => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useRequestProjectFilesDownloadFunction();
    const dataFromTemplate = useRequestTemplateFilesDownloadFunction();

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return () => {};
};

export default useRequestFilesDownloadFunction;
