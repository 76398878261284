import api from 'Application/api/api';

const updateInstructionPresentation = (
    instructionId,
    data,
    cancelToken = null,
) =>
    api.put(`/education/instructions/${instructionId}/presentation`, data, {
        cancelToken,
    });

export default updateInstructionPresentation;
