import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import InnerBox from 'Common/components/Boxes/InnerBox';
import InnerBoxTitle from 'Common/components/Boxes/InnerBoxTitle';
import colors from 'Common/constants/colors';
import File from 'ProjectManager/DataManagement/Files/components/File/File';

const Wrapper = styled(InnerBox)`
    max-height: 238px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 20px;

    ${InnerBoxTitle} {
        margin-bottom: 0;
        color: ${colors.GRAY};
    }
`;

const FilesWrapper = styled.div`
    @media screen and (min-width: 580px) {
        margin-top: -30px;
        margin-left: -25px;
    }
`;

const StyledFile = styled(File)`
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: 580px) {
        display: inline-block;
        width: 210px;
        margin-top: 30px;
        margin-left: 25px;
        margin-bottom: 0;
    }
`;

const Files = ({ files, noFilesText, searchTerm }) => (
    <Wrapper>
        {files.length > 0 ? (
            <FilesWrapper>
                {files.map(file => (
                    <StyledFile
                        key={file.id}
                        file={file}
                        searchTerm={searchTerm}
                    />
                ))}
            </FilesWrapper>
        ) : (
            <InnerBoxTitle>{noFilesText}</InnerBoxTitle>
        )}
    </Wrapper>
);

Files.defaultProps = {
    noFilesText: 'Keine Projektdateien vorhanden',
    searchTerm: undefined,
};

Files.propTypes = {
    files: PropTypes.array.isRequired,
    noFilesText: PropTypes.string,
    searchTerm: PropTypes.string,
};

export default Files;
