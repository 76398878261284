import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import Input from 'Common/components/Form/Fields/Input';
import isTemplateGroupNameUnique from 'ProjectManager/TemplateGroup/api/isTemplateGroupNameUnique';
import createTemplateGroup from 'ProjectManager/TemplateGroup/api/createTemplateGroup';

const initialValues = {
    name: '',
};

const CreateGroupModal = ({ onSuccess, onCancel, ...props }) => {
    const [isCreating, setIsCreating] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const uniqueNameValidationCallback = useCallback(
        async value => {
            if (!value) {
                return true;
            }

            try {
                const response = await isTemplateGroupNameUnique(
                    value,
                    null,
                    source.token,
                );

                return response.data.isUnique;
            } catch (error) {
                return axios.isCancel(error);
            }
        },
        [source.token],
    );

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                name: Yup.string()
                    .debouncedCallback(
                        'Der Name wird bereits verwendet',
                        uniqueNameValidationCallback,
                    )
                    .required('Pflichtangabe'),
            }),
        [uniqueNameValidationCallback],
    );

    const {
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async values => {
            setIsCreating(true);

            try {
                const response = await createTemplateGroup(
                    values,
                    source.token,
                );

                const newGroup = response.data;

                // Let the parent component know about the change that was made
                // and which group exactly is the new one.
                await onSuccess(newGroup);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }

            setIsCreating(false);
        },
    });

    return (
        <Modal
            onRequestClose={isCreating ? undefined : onCancel}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onCancel}
                        disabled={isCreating}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isCreating}
                        onClick={handleSubmit}
                        disabled={isCreating || !isValid}
                    >
                        Erstellen
                    </Button>
                </>
            }
            {...props}
        >
            <form onSubmit={handleSubmit}>
                <ModalTitle>Erstelle eine neue Projektkategorie</ModalTitle>
                <Input
                    id="name"
                    name="name"
                    label="Name der Projektkategorie"
                    value={values.name}
                    error={errors.name}
                    touched={touched.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isRequired
                />
            </form>
        </Modal>
    );
};

CreateGroupModal.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default CreateGroupModal;
