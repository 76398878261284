import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import InnerBox from 'Common/components/Boxes/InnerBox';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';

const InteractableInnerBox = styled(InnerBox)`
    transition: background 150ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
        ${ifProp(
            'light',
            css``,
            css`
                background: ${colors.DARK_DIVIDER};
            `,
        )};
    }
`;

export default InteractableInnerBox;
