import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import axios from 'axios';
import SignaturePad from 'Common/components/SignaturePad';
import dataUriToBlob from 'Common/utils/dataUriToBlob';
import OuterBox from 'Common/components/Boxes/OuterBox';
import colors from 'Common/constants/colors';
import OuterBoxButtons from 'Common/components/Boxes/OuterBoxButtons';
import Button from 'Common/components/Button';
import { ArrowBackRounded } from '@material-ui/icons';
import useWindowSize from '@react-hook/window-size';
import { isMobile } from 'react-device-detect';
import TurnYourDeviceInLandscapeModeOverlay from 'Common/components/TurnYourDeviceInLandscapeModeOverlay';

const FullWidthContent = styled(OuterBox)`
    margin-bottom: 10px;
    padding: 30px 20px;

    @media screen and (min-width: 940px) {
        display: flex;
        margin-bottom: 0;
        padding: 40px;
    }
`;

const SignaturePadWrapper = styled.div`
    flex: 1;
    margin-right: 40px;
`;

const SignaturePadDescription = styled.div`
    margin: 0 0 20px 2px;
    font-size: 14px;
    font-weight: 600;
`;

const AdditionalInformation = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.5;
    margin-top: 20px;

    @media screen and (min-width: 940px) {
        flex: 1;
        padding-top: 40px;
        padding-bottom: 40px;
        margin-top: 0;
    }
`;

const Hint = styled.div`
    max-width: 480px;
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
    color: ${colors.DIRTY_WHITE};
`;

const DriverLicenseSignatureVerification = ({
    hint,
    signaturePadDescription,
    confirmButtonText,
    onConfirm,
    onBackButtonClick,
    showBackButton,
    successMessage,
}) => {
    const signaturePad = useRef();
    const [isConfirming, setIsConfirming] = useState(false);
    const [width, height] = useWindowSize();
    const isPortrait = width < height;
    const isOverlayVisible = isMobile && isPortrait;

    const handleConfirm = async () => {
        if (signaturePad.current.isEmpty()) {
            notify('Das Unterschriftsfeld darf nicht leer sein', {
                type: toast.TYPE.ERROR,
            });
        } else {
            setIsConfirming(true);

            try {
                const blob = dataUriToBlob(signaturePad.current.toDataURL());

                await onConfirm(blob);

                notify(successMessage, {
                    type: toast.TYPE.SUCCESS,
                });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }

            setIsConfirming(false);
        }
    };

    if (isOverlayVisible) {
        return <TurnYourDeviceInLandscapeModeOverlay />;
    }

    return (
        <>
            <FullWidthContent>
                <SignaturePadWrapper>
                    {!!signaturePadDescription && (
                        <SignaturePadDescription>
                            {signaturePadDescription}
                        </SignaturePadDescription>
                    )}
                    <SignaturePad ref={signaturePad} />
                </SignaturePadWrapper>
                <AdditionalInformation>
                    <Hint>{hint}</Hint>
                </AdditionalInformation>
            </FullWidthContent>
            <OuterBoxButtons>
                {showBackButton && (
                    <Button
                        text
                        icon={<ArrowBackRounded />}
                        type="button"
                        disabled={!onBackButtonClick}
                        onClick={onBackButtonClick}
                    >
                        Zurück
                    </Button>
                )}
                <Button
                    type="button"
                    isLoading={isConfirming}
                    onClick={handleConfirm}
                    disabled={isConfirming}
                >
                    {confirmButtonText}
                </Button>
            </OuterBoxButtons>
        </>
    );
};

DriverLicenseSignatureVerification.defaultProps = {
    signaturePadDescription: undefined,
    onBackButtonClick: undefined,
    showBackButton: true,
    successMessage: 'Erfolgreiche Überprüfung',
};

DriverLicenseSignatureVerification.propTypes = {
    hint: PropTypes.any.isRequired,
    signaturePadDescription: PropTypes.string,
    confirmButtonText: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onBackButtonClick: PropTypes.func,
    showBackButton: PropTypes.bool,
    successMessage: PropTypes.string,
};

export default DriverLicenseSignatureVerification;
