import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import updateProjectCustomSubsection from 'ProjectManager/Project/Common/api/dataManagement/questionnaire/subsection/updateProjectCustomSubsection';
import withUpdatedCustomSubsectionData from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/subsection/withUpdatedCustomSubsectionData';

const useUpdateProjectCustomSubsectionFunction = () => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const updateCustomSubsection = useCallback(
        async (customSubsectionId, updatedValues) => {
            await updateProjectCustomSubsection(
                projectId,
                customSubsectionId,
                updatedValues,
                source.token,
            );

            setProject(
                withUpdatedCustomSubsectionData(
                    customSubsectionId,
                    updatedValues,
                ),
            );
        },
        [projectId, setProject, source.token],
    );
    return updateCustomSubsection;
};

export default useUpdateProjectCustomSubsectionFunction;
