import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import LibraryInstructionsList from 'Education/components/library/LibraryInstructionsList';
import getLibraryInstructions from 'Education/api/library/getLibraryInstructions';
import Loader from 'Common/components/Loader';
import SelectedLibrariesForImport from 'Education/components/library/SelectedLibrariesForImport';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import importInstructionsFromLibrary from 'Education/api/library/importInstructionFromLibrary';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import Breadcrumb from 'Application/components/Header/Breadcrumb';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import withRestrictionCheckDuringArchivingProcess from 'Education/components/common/withRestrictionCheckDuringArchivingProcess';
import OuterBox from 'Common/components/Boxes/OuterBox';
import BackButton from 'Application/components/Header/BackButton/BackButton';
import Button from 'Common/components/Button';
import Modal from 'Common/components/Modals/Modal';
import OuterBoxButtons from 'Common/components/Boxes/OuterBoxButtons';

const Wrapper = styled(OuterBox)`
    position: relative;

    @media screen and (min-width: 880px) {
        display: flex;
        height: calc(100vh - 250px);
    }
`;

const SelectionList = styled.div`
    margin-bottom: 10px;

    > div {
        height: calc(100vh - 365px);
        min-height: 365px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    @media screen and (min-width: 880px) {
        flex: 0 0 350px;
        margin-bottom: 0;

        > div {
            height: 100%;
            min-height: initial;
            overflow-y: initial;
        }
    }
`;

const SelectedList = styled.div`
    padding-bottom: 30px;

    @media screen and (min-width: 880px) {
        flex: 1;
        padding-bottom: 0;

        > div {
            height: 100%;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }
    }
`;

const validationSchema = Yup.object().shape({
    libraryInstructions: Yup.array().of(
        Yup.object().shape({
            id: Yup.mixed(),
            name: Yup.string().required('Pflichtangabe'),
            keepReference: Yup.object(),
            areas: Yup.array()
                .of(Yup.object())
                .required('Pflichtangabe')
                .nullable(),
            slideIds: Yup.array()
                .of(Yup.number())
                .nullable(),
        }),
    ),
});

const ImportFromLibrary = () => {
    const dispatch = useDispatch();

    const [libraryInstructions, setLibraryInstructions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);

        (async () => {
            const response = await getLibraryInstructions([
                'numberOfInstructionReferences',
                'slidesCount',
            ]);

            setLibraryInstructions(response.data);
            setIsLoading(false);
        })();
    }, []);

    const [isImporting, setIsImporting] = useState(false);
    const [isImportModalOpened, setIsImportModalOpened] = useState(false);

    const handleImportButtonClick = () => {
        setIsImportModalOpened(true);
    };

    const handleImportCancel = () => {
        setIsImportModalOpened(false);
    };

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const {
        errors,
        touched,
        values,
        setFieldValue,
        setTouched,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
    } = useFormik({
        initialValues: {
            libraryInstructions: [],
        },
        validationSchema,
        onSubmit: async values => {
            try {
                setIsImporting(true);

                await importInstructionsFromLibrary(
                    values.libraryInstructions.map(libraryInstruction => ({
                        id: Number(libraryInstruction.id),
                        name: libraryInstruction.name,
                        keepReference: libraryInstruction.keepReference.value,
                        areaIds: libraryInstruction.areas.map(
                            option => option.value,
                        ),
                        slideIds: libraryInstruction.slideIds ?? null,
                    })),
                    source.token,
                );

                notify('Die Bibliotheken werden erfolgreich importiert!', {
                    type: toast.TYPE.SUCCESS,
                });

                dispatch(push('/education'));

                setIsImporting(false);
                setIsImportModalOpened(false);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setIsImporting(false);

                    notify('Der Import ist fehlgeschlagen', {
                        type: toast.TYPE.ERROR,
                    });
                }
            }
        },
    });

    const handleCheckboxChange = (id, isChecked) => {
        const libraryInstruction = libraryInstructions.find(
            libraryInstruction => libraryInstruction.id === id,
        );

        const newLibraryInstructions = [...values.libraryInstructions];

        const index = newLibraryInstructions.findIndex(
            libraryInstruction => libraryInstruction.id === id,
        );

        if (isChecked && index === -1) {
            newLibraryInstructions.push({
                id: libraryInstruction.id,
                name: libraryInstruction.name,
                areas: [],
                keepReference: {
                    value: true,
                    label: 'Mit Referenz',
                },
                slideIds: null,
            });
        } else if (!isChecked && index !== -1) {
            const newLibraryInstructionsTouched = touched.libraryInstructions
                ? [...touched.libraryInstructions]
                : [];

            newLibraryInstructionsTouched.splice(index, 1);

            setTouched({
                ...touched,
                libraryInstructions: newLibraryInstructionsTouched,
            });

            newLibraryInstructions.splice(index, 1);
        }

        setFieldValue('libraryInstructions', newLibraryInstructions);
    };

    const selectedLibraryInstructionIds = values.libraryInstructions.map(
        libraryInstruction => libraryInstruction.id,
    );

    return (
        <>
            <Wrapper>
                <Helmet>
                    <title>Bibliothek - Unterweisungen</title>
                </Helmet>
                <BackButton to="/education" />
                <Breadcrumb to="/education">Unterweisungen</Breadcrumb>
                <Breadcrumb isActive>Bibliothek</Breadcrumb>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <SelectionList>
                            <LibraryInstructionsList
                                libraryInstructions={libraryInstructions}
                                selectedIds={selectedLibraryInstructionIds}
                                onCheckboxChange={handleCheckboxChange}
                            />
                        </SelectionList>
                        <SelectedList>
                            <SelectedLibrariesForImport
                                libraryInstructions={libraryInstructions}
                                libraryInstructionValues={
                                    values.libraryInstructions
                                }
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                handleSubmit={handleSubmit}
                            />
                        </SelectedList>
                    </>
                )}
            </Wrapper>
            <OuterBoxButtons>
                <Button
                    type="button"
                    onClick={handleImportButtonClick}
                    disabled={
                        !isValid || values.libraryInstructions.length === 0
                    }
                    tooltip={!isValid ? 'Füllen Sie alle Felder aus' : ''}
                >
                    Importieren
                </Button>
            </OuterBoxButtons>
            <Modal
                isOpen={isImportModalOpened}
                onRequestClose={isImporting ? undefined : handleImportCancel}
                buttons={
                    <>
                        <Button
                            text
                            dark
                            type="button"
                            onClick={handleImportCancel}
                            disabled={isImporting}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            type="button"
                            isLoading={isImporting}
                            onClick={handleSubmit}
                            disabled={isImporting}
                        >
                            Importieren
                        </Button>
                    </>
                }
            >
                Möchten Sie die Unterweisung/en importieren?
            </Modal>
        </>
    );
};

export default withRestrictionCheckDuringArchivingProcess(ImportFromLibrary);
