import React from 'react';
import Button from 'Common/components/Button';
import styled from 'styled-components/macro';
import Template from 'Common/components/Icons/Template';
import NoItemsText from 'ProjectManager/Overview/NoItemsText';
import colors from 'Common/constants/colors';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${colors.GRAY};

    ${NoItemsText} {
        max-width: 200px;
    }

    > svg {
        font-size: 55px;
    }

    @media screen and (min-width: 1180px) {
        height: 100%;

        > svg {
            font-size: 100px;
        }
    }
`;

const NoTemplates = () => (
    <Wrapper>
        <Template />
        <NoItemsText>Derzeit gibt es keine Vorlagen</NoItemsText>
        <Button outline to="/project-manager/template/create">
            Projektvorlage erstellen
        </Button>
    </Wrapper>
);

export default NoTemplates;
