import React, { useCallback, useMemo, useState } from 'react';
import ViewParticipantsModal
    from 'ProjectManager/Project/Introductions/Grid/components/Introduction/ViewParticipantsModal/ViewParticipantsModal';

const useParticipantsViewButton = introduction => {
    const isVisible = useMemo(
        () => introduction.isConducted || introduction.isCanceled,
        [introduction.isCanceled, introduction.isConducted],
    );

    const [isModalOpened, setIsModalOpened] = useState(false);

    const handleClick = () => {
        setIsModalOpened(true);
    };

    const closeModal = useCallback(() => {
        setIsModalOpened(false);
    }, []);

    const renderModal = useCallback(() => {
        if (!isModalOpened) {
            return null;
        }

        return (
            <ViewParticipantsModal
                introduction={introduction}
                onClose={closeModal}
            />
        );
    }, [closeModal, introduction, isModalOpened]);

    return {
        isVisible,
        handleClick,
        renderModal,
    };
};

export default useParticipantsViewButton;
