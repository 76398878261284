import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import getAssignableEmployeesForProject from 'ProjectManager/Project/Common/api/dataManagement/employee/getAssignableEmployeesForProject';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import assignEmployeesToProject from 'ProjectManager/Project/Common/api/dataManagement/employee/assignEmployeesToProject';
import getProject from 'ProjectManager/Project/Common/api/getProject';
import unassignEmployeesFromProject from 'ProjectManager/Project/Common/api/dataManagement/employee/unassignEmployeesFromProject';
import withParticipants from 'ProjectManager/Project/Common/recoil/project/modifiers/withParticipants';

const useProjectEmployeeSelectionData = () => {
    const [{ id: projectId, participants, areas }, setProject] = useRecoilState(
        projectAtom,
    );

    const assignableEmployeesRequest = useCallback(
        cancelToken =>
            getAssignableEmployeesForProject(
                projectId,
                ['employee.user'],
                cancelToken,
            ),
        [projectId],
    );

    const {
        data: assignableEmployees,
        loadData: loadAssignableEmployees,
        isLoading,
        hasError,
    } = useAxiosRequest(assignableEmployeesRequest, [], {
        isManual: true,
    });

    // Whenever the areas change, reload the assignable employees,
    // as they are directly dependent
    useEffect(() => {
        if (projectId) {
            loadAssignableEmployees();
        }
    }, [projectId, areas, loadAssignableEmployees]);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const assignEmployees = async employeeIds => {
        await assignEmployeesToProject(projectId, employeeIds, source.token);

        const projectResponse = await getProject(
            projectId,
            [
                'participants',
                'participants.employee.user',
                'participants.employee.areas',
            ],
            source.token,
        );

        return projectResponse.data.participants;
    };

    const unassignEmployees = useCallback(
        async employeeIds => {
            await unassignEmployeesFromProject(
                projectId,
                employeeIds,
                source.token,
            );

            const projectResponse = await getProject(
                projectId,
                [
                    'participants',
                    'participants.employee.user',
                    'participants.employee.areas',
                ],
                source.token,
            );

            return projectResponse.data.participants;
        },
        [projectId, source.token],
    );

    const updateRecoilState = useCallback(
        newParticipants => {
            setProject(withParticipants(newParticipants));
        },
        [setProject],
    );

    return projectId
        ? {
              isLoading,
              hasError,
              retryLoading: loadAssignableEmployees,
              assignableEmployees,
              participatingEmployees: participants
                  .filter(participant => participant.isAssigned)
                  .map(participant => participant.employee),
              assignEmployees,
              unassignEmployees,
              updateRecoilState,
              showWarningModalWhenNoEmployeesAreSelected: true,
          }
        : {};
};

export default useProjectEmployeeSelectionData;
