import api from 'Application/api/api';

const getPublicTemplateFilesDownloadRequest = (
    templateId,
    downloadRequestId,
    checksum,
    load,
    cancelToken = null,
) =>
    api.get(
        `public/project-manager/templates/${templateId}/download-requests/${downloadRequestId}`,
        {
            params: {
                load,
                checksum,
            },
            cancelToken,
        },
    );

export default getPublicTemplateFilesDownloadRequest;
