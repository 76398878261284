import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSortBy, useTable } from 'react-table';
import { useCustomCellStyles } from 'Common/hooks/useCustomCellStyles';
import { DefaultTable } from 'Common/components/table';
import { useResponsiveTable } from 'Common/hooks/useResponsiveTable';
import { CloudDownloadRounded } from '@material-ui/icons';
import Button from 'Common/components/Button';
import parseDate from 'Common/utils/parseDate';
import formatDate from 'Common/utils/formatDate';
import { orderBy } from 'lodash';
import stableTableSort from 'Common/utils/stableTableSort';

const DocumentsTable = ({ documents }) => {
    const columns = useMemo(
        () => [
            {
                Header: 'Dokumentname',
                accessor: 'name',
                customCellStyles: {
                    minWidth: 150,
                    wordBreak: 'break-all',
                },
            },
            {
                Header: 'Archiviert am',
                accessor: 'archivedAt',
            },
            {
                Header: 'Download',
                accessor: 'download',
                disableSortBy: true,
                customCellStyles: {
                    textAlign: 'center',
                },
            },
        ],
        [],
    );

    const initialSortBy = useMemo(
        () => [
            {
                id: 'archivedAt',
                desc: true,
            },
        ],
        [],
    );

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const data = useMemo(
        () =>
            orderBy(
                documents.map(document => ({
                    name: document.name,
                    archivedAt: formatDate(parseDate(document.archivedAt)),
                    download: (
                        <Button
                            iconOnly
                            icon={<CloudDownloadRounded />}
                            href={document.url}
                        />
                    ),
                })),
                ['archivedAt'],
                ['desc'],
            ),
        [documents],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { hiddenColumns },
    } = useTable(
        {
            columns,
            data,
            disableSortRemove: true,
            disableMultiSort: true,
            hideColumnsPriority: ['archivedAt', 'actions'],
            initialState: {
                sortBy: initialSortBy,
            },
            orderByFn: stableTableSort,
        },
        useCustomCellStyles,
        useResponsiveTable,
        useSortBy,
    );

    return (
        <DefaultTable
            getTableProps={getTableProps}
            rows={rows}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            hiddenColumns={hiddenColumns}
            visibleColumns={visibleColumns}
        />
    );
};

DocumentsTable.propTypes = {
    documents: PropTypes.array.isRequired,
};

export default DocumentsTable;
