import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { WarningRounded } from '@material-ui/icons';
import Button from 'Common/components/Button';
import WarningMessage from 'Common/components/ActionBar/WarningMessage';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;

    ${WarningMessage} {
        margin-left: 10px;
    }
`;

const Buttons = ({ project }) => (
    <Wrapper>
        {project.isCreated ? (
            <>
                <Button
                    small
                    to={`/project-manager/project/${project.id}/preview`}
                >
                    Ansicht
                </Button>
                {project.doesRequireAction && (
                    <WarningMessage>
                        <WarningRounded />
                        Aufgabe
                    </WarningMessage>
                )}
            </>
        ) : (
            <Button small to={`/project-manager/project/${project.id}/create`}>
                Erstellung fortsetzen
            </Button>
        )}
    </Wrapper>
);

Buttons.propTypes = {
    project: PropTypes.object.isRequired,
};

export default Buttons;
