import React from 'react';
import styled from 'styled-components/macro';

import Loader from 'Common/components/Loader';
import getVehiclesTasksProgress from 'Measure/api/getVehiclesTasksProgress';
import useAxiosRequest from 'Common/hooks/useAxiosRequest';
import RetryLoading from 'Common/components/RetryLoading';
import PieChart from 'Common/components/PieChart';
import OuterBox from 'Common/components/Boxes/OuterBox';
import colors from 'Common/constants/colors';

const Wrapper = styled(OuterBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 20px;
    border-radius: 4px;

    @media screen and (min-width: 860px) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
`;

const Title = styled.h2`
    margin: 0 0 40px;
    color: ${colors.DIRTY_WHITE};
    font-size: 23px;
`;

const VehicleMeasuresProgress = () => {
    const {
        data: stats,
        loadData: loadStats,
        isLoading,
        hasError,
    } = useAxiosRequest(getVehiclesTasksProgress, {
        pending: 0,
        open: 0,
        completed: 0,
    });

    const percentage =
        stats.all > 0 ? Math.floor((stats.completed / stats.all) * 100) : 0;

    return (
        <Wrapper>
            <Title>Fuhrpark</Title>
            {isLoading ? (
                <Loader />
            ) : hasError ? (
                <RetryLoading onRetry={loadStats} />
            ) : (
                <PieChart
                    data={[
                        {
                            id: 'completed',
                            label: 'Abgeschlossen',
                            value: stats.completed,
                            backgroundColor: colors.PRIMARY,
                        },
                        {
                            id: 'pending',
                            label: 'Ausstehend',
                            value: stats.pending,
                            backgroundColor: colors.SEARCH_FIELD_BORDER,
                        },
                        {
                            id: 'others',
                            label: 'Andere',
                            value: stats.all - stats.completed - stats.pending,
                            backgroundColor: colors.SEARCH_FIELD_BORDER,
                        },
                    ]}
                    percentage={percentage}
                    text="Maßnahmen erledigt"
                    size={170}
                />
            )}
        </Wrapper>
    );
};

export default VehicleMeasuresProgress;
