import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import modules from 'Common/constants/modules';
import {
    ConstructionSiteIcon,
    DriverLicenseControlIcon,
    InstructionIcon,
    MeasuresIcon,
    PdfDocumentIcon,
} from 'Common/components/icons';
import Loader from 'Common/components/Loader';
import { useSelector } from 'react-redux';
import ProgressBar from 'Common/components/ProgressBar';
import { averagePercentage } from 'Dashboard/constants/newRiskAssessment';
import Tile from 'Common/components/Tile';
import { Link } from 'react-router-dom';
import InteractableInnerBox from 'Common/components/Boxes/InteractableInnerBox';
import { Tooltip } from '@material-ui/core';

const ModuleTile = styled(Tile)`
    margin-top: 10px;

    &:first-child {
        margin-top: 0;
    }

    @media screen and (min-width: 1180px) {
        width: calc(50% - 5px);
        margin-right: 10px;

        &:nth-child(2) {
            margin-top: 0;
        }

        &:nth-child(2n) {
            margin-right: 0;
        }

        &:nth-child(2n + 1):last-child {
            width: 100%;
            margin-right: 0;
        }
    }
`;

const ContentModuleLink = styled(ModuleTile).attrs({
    as: Link,
})``;

const ContentModuleExternalLink = styled(ModuleTile).attrs({
    as: 'a',
})``;

const RiskAssessmentTile = styled(ModuleTile)`
    position: relative;
    align-items: stretch;
    padding: 0;

    ${ContentModuleLink} {
        width: 100%;
        box-shadow: none;
    }

    ${ContentModuleExternalLink} {
        width: 100%;
        margin-right: 0;
        padding-bottom: 110px;

        @media screen and (min-width: 461px) {
            padding-bottom: 90px;
        }

        @media screen and (min-width: 1180px) {
            padding-top: 0;
            padding-bottom: 70px;
            font-size: 17px;

            i,
            svg {
                width: 52px;
                height: 52px;
                font-size: 52px;
            }
        }
    }
`;

const ModuleName = styled.span`
    @media screen and (min-width: 1180px) {
        flex: 1;
        padding-right: 87px;
        text-align: center;
    }
`;

const ModuleProgress = styled(InteractableInnerBox).attrs({
    as: Link,
})`
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: calc(100% - 20px);
    padding: 10px;
    text-decoration: none;
`;

const Title = styled.div`
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 7px;
    line-height: 17px;

    span {
        display: inline-block;
        text-decoration: underline;
    }
`;

const moduleUnavailableMessage =
    'Freischaltung durch Produkterweiterung möglich, bitte kontaktieren Sie hierfür die Amedic GmbH & CO.KG';

const AvailableModules = () => {
    const isUsingOnlyNewModules = useSelector(
        state => state.user.isUsingOnlyNewModules,
    );

    const availableModules = useSelector(state => state.user.availableModules);

    const isAvailable = useCallback(
        module => availableModules.includes(module),
        [availableModules],
    );

    const isLoading = availableModules.length === 0;

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <Tooltip
                title={
                    !isAvailable(modules.INSTRUCTIONS)
                        ? moduleUnavailableMessage
                        : ''
                }
            >
                <ContentModuleLink
                    to={isAvailable(modules.INSTRUCTIONS) ? '/education' : null}
                    isDisabled={!isAvailable(modules.INSTRUCTIONS)}
                >
                    <InstructionIcon />
                    <ModuleName>Unterweisungen</ModuleName>
                </ContentModuleLink>
            </Tooltip>
            <Tooltip
                title={
                    !isAvailable(modules.MEASURES)
                        ? moduleUnavailableMessage
                        : ''
                }
            >
                {isUsingOnlyNewModules ? (
                    <ContentModuleLink
                        to={
                            isAvailable(modules.MEASURES)
                                ? '/measures/vehicles/driver-licenses'
                                : null
                        }
                        isDisabled={!isAvailable(modules.MEASURES)}
                    >
                        <DriverLicenseControlIcon size={null} />
                        <ModuleName>Führerscheinkontrolle</ModuleName>
                    </ContentModuleLink>
                ) : (
                    <ContentModuleLink
                        to={isAvailable(modules.MEASURES) ? '/measures' : null}
                        isDisabled={!isAvailable(modules.MEASURES)}
                    >
                        <MeasuresIcon />
                        <ModuleName>Maßnahmen</ModuleName>
                    </ContentModuleLink>
                )}
            </Tooltip>
            <Tooltip
                title={
                    !isAvailable(modules.CONSTRUCTION_SITES)
                        ? moduleUnavailableMessage
                        : ''
                }
            >
                {
                    // @TODO: Change the tile once the risk assessment has been reworked
                }
                <RiskAssessmentTile>
                    {isAvailable(modules.NEW_RISK_ASSESSMENT) ? (
                        <ContentModuleLink to="/project-manager">
                            <ConstructionSiteIcon />
                            <ModuleName>Projekt Manager</ModuleName>
                        </ContentModuleLink>
                    ) : (
                        <>
                            <ContentModuleExternalLink
                                href={
                                    isAvailable(modules.CONSTRUCTION_SITES)
                                        ? '/backend/m/csite/call'
                                        : null
                                }
                                isDisabled={
                                    !isAvailable(modules.CONSTRUCTION_SITES)
                                }
                            >
                                <ConstructionSiteIcon />
                                <ModuleName>
                                    Ergänzende Gefährdungsbeurteilung
                                </ModuleName>
                            </ContentModuleExternalLink>
                            <ModuleProgress to="/risk-assessment/preview">
                                <Title>
                                    Fortschritte bei der Erweiterung.{' '}
                                    <span>Klick hier um mehr zu erfahren.</span>
                                </Title>
                                <ProgressBar
                                    percentage={averagePercentage}
                                />
                            </ModuleProgress>
                        </>
                    )}
                </RiskAssessmentTile>
            </Tooltip>
            <ContentModuleExternalLink href="/backend/m/docs/call">
                <PdfDocumentIcon />
                <ModuleName>Dokumente</ModuleName>
            </ContentModuleExternalLink>
        </>
    );
};

export default AvailableModules;
