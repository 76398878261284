import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Avatar, Tooltip } from '@material-ui/core';
import colors from 'Common/constants/colors';

const getFirstLetter = name => name?.charAt(0)?.toUpperCase() ?? '';

const getInitials = (firstName, lastName) =>
    `${getFirstLetter(lastName)}${getFirstLetter(firstName)}`;

const StyledAvatar = styled(Avatar)`
    &.MuiAvatar-root {
      width: 45px;
      height: 45px;
        color: ${colors.DIRTY_WHITE};
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
    }
`;

const PersonAvatar = ({ firstName, lastName, ...props }) => {
    const fullName = `${lastName}, ${firstName}`;

    return (
        <Tooltip title={fullName}>
            <StyledAvatar alt={fullName} {...props}>
                {getInitials(firstName, lastName)}
            </StyledAvatar>
        </Tooltip>
    );
};

PersonAvatar.defaultProps = {
    firstName: '',
    lastName: '',
};

PersonAvatar.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
};

export default PersonAvatar;
