import React from 'react';
import { toast } from 'react-toastify';
import {
    CheckCircleRounded,
    ErrorRounded,
    InfoRounded,
} from '@material-ui/icons';

const notify = (content, options) => {
    let Icon;

    if (options && options.type) {
        switch (options.type) {
            case toast.TYPE.ERROR:
            case toast.TYPE.WARNING:
                Icon = ErrorRounded;
                break;

            case toast.TYPE.SUCCESS:
                Icon = CheckCircleRounded;
                break;

            case toast.TYPE.INFO:
            case toast.TYPE.DEFAULT:
            default:
                Icon = InfoRounded;
                break;
        }
    }

    const hasIcon = !!Icon;

    return toast(
        <>
            {hasIcon && <Icon />}
            {content}
        </>,
        options,
    );
};

export default notify;
