import api from 'Application/api/api';

const updateProjectQuestionComment = (
    projectId,
    questionId,
    isCustom,
    comment,
    cancelToken = null,
) =>
    api.post(
        `project-manager/projects/${projectId}/questionnaire/questions/${questionId}/${
            isCustom ? 1 : 0
        }/comment/update`,
        { comment },
        { cancelToken },
    );

export default updateProjectQuestionComment;
