import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import updateTemplateAreas from 'ProjectManager/Template/Common/api/dataManagement/updateTemplateAreas';
import withAreas from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/withAreas';

const useTemplateAreasData = () => {
    const [
        { id: templateId, areas, participatingEmployees },
        setTemplate,
    ] = useRecoilState(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const update = useCallback(
        async areaIds => {
            const response = await updateTemplateAreas(
                templateId,
                areaIds,
                source.token,
            );

            const newAreas = response.data;

            setTemplate(withAreas(newAreas));
        },
        [templateId, setTemplate, source.token],
    );

    return templateId
        ? {
              selectedAreas: areas,
              participatingEmployees,
              updateAreas: update,
              isRequired: false,
          }
        : {};
};

export default useTemplateAreasData;
