import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';
import history from 'Application/store/history';
import createRootReducer from 'Application/reducers';

// noinspection JSUnresolvedVariable
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user'],
};

const persistedReducer = persistReducer(
    persistConfig,
    createRootReducer(history),
);

const configureStore = () => {
    const store = createStore(
        persistedReducer,
        storeEnhancers(applyMiddleware(routerMiddleware(history))),
    );

    const persistor = persistStore(store);

    return {
        store,
        persistor,
        history,
    };
};

export default configureStore;
