import mapTemplateGroupsToOptions from 'ProjectManager/Template/DataManagement/Group/helpers/mapTemplateGroupsToOptions';
import getTemplateGroups from 'ProjectManager/TemplateGroup/api/getTemplateGroups';

const loadTemplateGroupOptions = async query => {
    const response = await getTemplateGroups();
    const groups = response.data;

    if (!query) {
        return mapTemplateGroupsToOptions(groups);
    }

    const searchTerm = query.toLowerCase();

    return mapTemplateGroupsToOptions(
        groups.filter(group => group.name.toLowerCase().includes(searchTerm)),
    );
};

export default loadTemplateGroupOptions;
