import React from 'react';
import Button from 'Common/components/Button';
import { useSelector } from 'react-redux';
import { dataTypesInfo } from 'ProjectManager/Project/Preview/QuickActions/DownloadAction/constants/dataTypes';

const useSuccessModal = ({ requestedData, onClose }) => {
    const currentUserEmail = useSelector(state => state.user.email);

    return {
        props: {
            buttons: (
                <>
                    <Button type="button" onClick={onClose}>
                        Weiter
                    </Button>
                </>
            ),
        },
        content: (
            <>
                <p>
                    Die Projektunterlagen für das Projekt werden vorbereitet. Du
                    erhältst in kürze eine E-Mail (
                    {`E-Mail: ${currentUserEmail}`}), in welcher du ein Link
                    findest mit Hilfe dessen du die angeforderten
                    Projektunterlagen herunterladen kannst. Bitte beachte das
                    der Link 48 Stunden gültig ist.
                </p>
                <p>
                    <ul>
                        {requestedData.map(requestedDatum => (
                            <li key={requestedDatum}>
                                {dataTypesInfo[requestedDatum].title}
                            </li>
                        ))}
                    </ul>
                </p>
            </>
        ),
    };
};

export default useSuccessModal;
