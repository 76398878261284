import React, { useState } from 'react';
import { CloudDownloadRounded } from '@material-ui/icons';
import Button from 'Common/components/Button';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import useRequestFilesDownloadFunction from 'ProjectManager/DataManagement/Files/hooks/requestFilesDownloadFunction/useRequestFilesDownloadFunction';
import DownloadAllProjectFilesModal from 'ProjectManager/DataManagement/Files/components/DownloadAllProjectFilesModal';

const DownloadAllFilesButton = () => {
    const requestFilesDownload = useRequestFilesDownloadFunction();

    const [isLoading, setIsLoading] = useState(false);

    const [
        isDownloadAllProjectFilesModalOpened,
        setIsDownloadAllProjectFilesModalOpened,
    ] = useState(false);

    const closeDownloadAllProjectFilesModal = () => {
        setIsDownloadAllProjectFilesModalOpened(false);
    };

    const handleButtonClick = async () => {
        setIsLoading(true);

        try {
            await requestFilesDownload();

            setIsDownloadAllProjectFilesModalOpened(true);
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }

        setIsLoading(false);
    };

    return (
        <>
            <Button
                underline
                icon={<CloudDownloadRounded />}
                isLoading={isLoading}
                disabled={isLoading}
                type="button"
                onClick={handleButtonClick}
            >
                Alle downloaden
            </Button>
            {isDownloadAllProjectFilesModalOpened && (
                <DownloadAllProjectFilesModal
                    onClose={closeDownloadAllProjectFilesModal}
                />
            )}
        </>
    );
};

export default DownloadAllFilesButton;
