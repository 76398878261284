import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import updateTemplateQuestionComment from 'ProjectManager/Template/Common/api/dataManagement/questionnaire/question/comment/updateTemplateQuestionComment';
import withQuestionComment
    from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/withQuestionComment';

const useUpdateTemplateQuestionCommentFunction = (
    questionId,
    isCustomQuestion,
) => {
    const [{ id: templateId }, setTemplate] = useRecoilState(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const updateComment = useCallback(
        async newComment => {
            await updateTemplateQuestionComment(
                templateId,
                questionId,
                isCustomQuestion,
                newComment,
                source.token,
            );

            setTemplate(
                withQuestionComment(questionId, isCustomQuestion, newComment),
            );
        },
        [templateId, questionId, isCustomQuestion, setTemplate, source.token],
    );

    return updateComment;
};

export default useUpdateTemplateQuestionCommentFunction;
