import React from 'react';
import PropTypes from 'prop-types';
import { WarningRounded } from '@material-ui/icons';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';
import WarningMessage from 'Common/components/Modals/WarningMessage';

const InstructionTestSaveModal = ({
    isOpen,
    isConfirmLoading,
    onConfirm,
    onCancel,
    stats,
    keepReference,
}) => (
    <Modal
        isOpen={isOpen}
        onRequestClose={isConfirmLoading ? undefined : onCancel}
        buttons={
            <>
                <Button
                    text
                    dark
                    type="button"
                    onClick={onCancel}
                    disabled={isConfirmLoading}
                >
                    Abbrechen
                </Button>
                <Button
                    type="button"
                    isLoading={isConfirmLoading}
                    onClick={onConfirm}
                    disabled={isConfirmLoading}
                >
                    Speichern
                </Button>
            </>
        }
    >
        <ModalTitle>
            Alle am Test vorgenommenen Änderungen speichern?
        </ModalTitle>
        {!!stats && stats.examsInProgressCount > 0 && (
            <>
                <p>
                    Hinweis: Der Fortschritt des Mitarbeiters wird zurückgesetzt
                </p>
                <WarningMessage>
                    <WarningRounded />
                    {`Es gibt ${stats.examsInProgressCount} Mitarbeiter, die eine Testprüfung machen`}
                </WarningMessage>
            </>
        )}
        {keepReference && (
            <WarningMessage>
                <WarningRounded />
                Sie haben eine Unterweisung mit einer Referenz auf die
                Bibliothek geändert. Alle Änderungen gehen verloren, wenn Amedic
                die Bibliothek synchronisiert. Stellen Sie sicher, dass Sie Ihre
                Unterweisung von der Bibliothek trennen, um überschriebene Daten
                zu vermeiden.
            </WarningMessage>
        )}
    </Modal>
);

InstructionTestSaveModal.defaultProps = {
    stats: null,
    keepReference: null,
};

InstructionTestSaveModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isConfirmLoading: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    stats: PropTypes.object,
    keepReference: PropTypes.bool,
};

export default InstructionTestSaveModal;
