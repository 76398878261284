import api from 'Application/api/api';

const updateTemplateCustomSubsection = (templateId, customSubsectionId, data, cancelToken = null) =>
    api.post(
        `project-manager/templates/${templateId}/questionnaire/custom-subsections/${customSubsectionId}`,
        data,
        {cancelToken}
    );

export default updateTemplateCustomSubsection;
