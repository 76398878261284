import styled from 'styled-components/macro';
import { TextField as MaterialTextField } from '@material-ui/core';
import colors from 'Common/constants/colors';

const StyledTextField = styled(MaterialTextField)`
    &.MuiTextField-root {
        width: 100%;

        .MuiFilledInput-root {
            background-color: ${colors.WHITE};
            border: 2px solid transparent;
            border-radius: 6px;
            box-shadow: 0 3px 7px rgba(0, 0, 0, 0.16);
            transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1),
                border-color 200ms cubic-bezier(0, 0, 0.2, 1);

            &:hover {
                background-color: ${colors.WHITE};
            }

            &.Mui-focused {
                background-color: ${colors.WHITE};
            }

            &.Mui-error {
                border-color: ${colors.ERROR};
            }
        }

        .MuiInputBase-input {
            display: block;
            width: 100%;
            height: 18px;
            color: ${colors.DARK_GRAY};
            font-size: 15px;
            line-height: 15px;
            transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
            text-overflow: ellipsis;

            &:-webkit-autofill {
                box-shadow: 0 0 0 1000px ${colors.WHITE} inset;
                -webkit-text-fill-color: ${colors.DARK_GRAY};
                caret-color: ${colors.DARK_GRAY};
            }

            &:hover {
                &:-webkit-autofill {
                    box-shadow: 0 0 0 1000px ${colors.WHITE} inset;
                }
            }
        }

        .MuiFilledInput-input {
            padding-top: 16px;
            padding-bottom: 16px;

            &:-webkit-autofill {
                border-radius: inherit;
            }

            &::placeholder {
                color: ${colors.GRAY};
                opacity: 1;
            }
        }

        .MuiFormLabel-root + .MuiFilledInput-root > .MuiFilledInput-input {
            padding-top: 24px;
            padding-bottom: 8px;
        }

        .MuiFilledInput-inputMultiline {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        .MuiInputAdornment-positionEnd {
            button,
            a {
                margin-right: -12px;
                margin-left: -8px;
                padding: 15px;
            }
        }
    }

    .MuiFormLabel-root {
        color: ${colors.GRAY};
        font-family: 'Poppins', sans-serif;

        &.Mui-focused {
            color: ${colors.GRAY};
        }

        &.Mui-error {
            color: ${colors.ERROR};
        }

        &.Mui-disabled {
            color: ${colors.SEARCH_FIELD_BORDER};
        }
    }

    .MuiInputLabel-filled {
        &.MuiInputLabel-shrink {
            transform: translate(12px, 9px) scale(0.75);
        }

        &:not(.MuiInputLabel-shrink):not(.Mui-error) {
            .required-asterisk {
                pointer-events: all;
            }
        }
    }

    .MuiFilledInput-underline {
        &:before,
        &:after {
            display: none;
        }
    }

    .MuiInputBase-root,
    .MuiFormLabel-root {
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        line-height: 14px;
    }

    .MuiInputBase-root.Mui-disabled {
        background-color: ${colors.LIGHTER_GRAY};
        color: ${colors.DARK_GRAY};

        &:hover {
            background-color: ${colors.LIGHTER_GRAY};
        }
    }

    .MuiSvgIcon-root {
        color: ${colors.GRAY};
    }
`;

export default StyledTextField;
