import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import DataManagementBottomButtons from 'ProjectManager/DataManagement/Common/components/DataManagementBottomButtons';
import Button from 'Common/components/Button';
import ContentSection from 'ProjectManager/DataManagement/Common/components/ContentSection';
import Areas from 'ProjectManager/DataManagement/Areas/components/Areas';
import Supervisors from 'ProjectManager/DataManagement/Supervisors/components/Supervisors';
import Files from 'ProjectManager/DataManagement/Files/components/Files';
import { useRecoilValue } from 'recoil';
import isGeneralProjectInformationStepCompletedSelector from 'ProjectManager/Project/Common/recoil/project/selectors/isGeneralProjectInformationStepCompletedSelector';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import ProjectData from 'ProjectManager/Project/DataManagement/GeneralInformation/ProjectData';
import { ArrowForwardRounded } from '@material-ui/icons';
import useHasOtherPrimaryButtonForProject from 'ProjectManager/DataManagement/Common/hooks/useHasOtherPrimaryButton/useHasOtherPrimaryButtonForProject';
import TimelineAndWorkingHours from 'ProjectManager/Project/DataManagement/GeneralInformation/TimelineAndWorkingHours';
import ProjectCoordinators from 'ProjectManager/Project/DataManagement/GeneralInformation/ProjectCoordinators/ProjectCoordinators';

const Wrapper = styled.div``;

const ProjectGeneralInformation = ({ onNextButtonClick }) => {
    const { id: projectId } = useRecoilValue(projectAtom);

    const isGeneralProjectInformationStepCompleted = useRecoilValue(
        isGeneralProjectInformationStepCompletedSelector,
    );

    const hasOtherPrimaryButtonForProject = useHasOtherPrimaryButtonForProject();

    return (
        <Wrapper>
            <ProjectData />
            {Boolean(projectId) && (
                <>
                    <Supervisors />
                    <ProjectCoordinators />
                    <Areas />
                    <TimelineAndWorkingHours />
                    <ContentSection>
                        <Files title="Allgemeine Projektdateien" />
                    </ContentSection>
                </>
            )}
            <DataManagementBottomButtons>
                <Button
                    text={hasOtherPrimaryButtonForProject}
                    icon={
                        hasOtherPrimaryButtonForProject ? (
                            <ArrowForwardRounded />
                        ) : undefined
                    }
                    iconPosition="right"
                    type="button"
                    onClick={onNextButtonClick}
                    disabled={!isGeneralProjectInformationStepCompleted}
                    tooltip={
                        !isGeneralProjectInformationStepCompleted
                            ? 'Bitte geben Sie alle erforderlichen Daten ein'
                            : ''
                    }
                >
                    Weiter
                </Button>
            </DataManagementBottomButtons>
        </Wrapper>
    );
};

ProjectGeneralInformation.propTypes = {
    onNextButtonClick: PropTypes.func.isRequired,
};

export default ProjectGeneralInformation;
