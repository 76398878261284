const withAdditionalCoordinator = projectCoordinator => prevProject => {
    const projectCoordinators = [...prevProject.projectCoordinators];

    projectCoordinators.push(projectCoordinator);

    return {
        ...prevProject,
        projectCoordinators,
    };
};

export default withAdditionalCoordinator;
