import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';

const NumberIndicator = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 26px;
    height: 26px;
    border-radius: 26px;
    background: ${colors.PRIMARY};
    color: ${colors.DIRTY_WHITE};
    font-size: 14px;
    line-height: 21px;
`;

export default NumberIndicator;
