import getInstructionAreas from 'Education/api/instruction-area/getInstructionAreas';
import mapInstructionAreasToOptions from 'Education/utils/mapInstructionAreasToOptions';

const loadInstructionAreaOptions = async query => {
    const response = await getInstructionAreas();
    const instructionAreas = response.data;

    if (!query) {
        return mapInstructionAreasToOptions(instructionAreas);
    }

    const searchTerm = query.toLowerCase();

    return mapInstructionAreasToOptions(
        instructionAreas.filter(instructionArea =>
            instructionArea.name.toLowerCase().includes(searchTerm),
        ),
    );
};

export default loadInstructionAreaOptions;
