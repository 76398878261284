import React from 'react';
import styled from 'styled-components/macro';
import ContentSection from 'ProjectManager/DataManagement/Common/components/ContentSection';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import Group from 'ProjectManager/Template/DataManagement/Group/components/Group';
import TemplateName from 'ProjectManager/Template/DataManagement/TemplateName';

const Content = styled.div`
    @media screen and (min-width: 1180px) {
        display: flex;
        align-items: flex-start;
    }
`;

const Fields = styled.div`
    margin-bottom: 20px;

    @media screen and (min-width: 1180px) {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        order: 2;
        margin-bottom: 0;
    }
`;

const Field = styled.div`
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: 1180px) {
        width: calc(50% - 10px);
        margin-right: 20px;

        &:nth-child(3) {
            margin-bottom: 0;
        }

        &:nth-child(2),
        &:nth-child(4) {
            margin-right: 0;
        }
    }
`;

const TemplateData = () => (
    <ContentSection>
        <OuterBoxTitle>Vorlagendetails</OuterBoxTitle>
        <Content>
            <Fields>
                <Field>
                    <Group />
                </Field>
                <Field>
                    <TemplateName />
                </Field>
            </Fields>
        </Content>
    </ContentSection>
);

export default TemplateData;
