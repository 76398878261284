import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'Common/components/Button';
import { DeleteRounded } from '@material-ui/icons';
import DeleteCustomQuestionModal from 'ProjectManager/DataManagement/Questionnaire/components/Question/DeleteCustomQuestion/components/DeleteCustomQuestionModal';

const DeleteCustomQuestionButton = ({ question, ...props }) => {
    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

    const handleClick = () => {
        setIsDeleteModalOpened(true);
    };

    const closeModal = () => {
        setIsDeleteModalOpened(false);
    };

    return (
        <>
            <Button
                underline
                icon={<DeleteRounded />}
                type="button"
                onClick={handleClick}
                {...props}
            >
                Löschen
            </Button>
            {isDeleteModalOpened && (
                <DeleteCustomQuestionModal
                    question={question}
                    onCancel={closeModal}
                    onDelete={closeModal}
                />
            )}
        </>
    );
};

DeleteCustomQuestionButton.propTypes = {
    question: PropTypes.object.isRequired,
};

export default DeleteCustomQuestionButton;
