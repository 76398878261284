import api from 'Application/api/api';

const cancelIntroduction = (projectId, introductionId, cancelToken = null) =>
    api.post(
        `project-manager/projects/${projectId}/introductions/${introductionId}/cancel`,
        {},
        { cancelToken },
    );

export default cancelIntroduction;
