const driverLicenseCreateSteps = {
    CONTROL_METHOD_SELECTION: 'CONTROL_METHOD_SELECTION',
    DIRECT_FRONT_IMAGE: 'DIRECT_FRONT_IMAGE',
    DIRECT_BACK_IMAGE: 'DIRECT_BACK_IMAGE',
    DIRECT_FRONT_DATA: 'DIRECT_FRONT_DATA',
    DIRECT_BACK_DATA: 'DIRECT_BACK_DATA',
    DISTANCE_FRONT_IMAGE: 'DISTANCE_FRONT_IMAGE',
    DISTANCE_BACK_IMAGE_WITH_LETTERS: 'DISTANCE_BACK_IMAGE_WITH_LETTERS',
    DISTANCE_BACK_IMAGE_WITH_SIGNS: 'DISTANCE_BACK_IMAGE_WITH_SIGNS',
    DISTANCE_FRONT_DATA: 'DISTANCE_FRONT_DATA',
    DISTANCE_BACK_DATA: 'DISTANCE_BACK_DATA',
};

export default driverLicenseCreateSteps;
