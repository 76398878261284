import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import OuterBoxTitle from 'Common/components/Boxes/OuterBoxTitle';
import OuterBoxSubtitle from 'Common/components/Boxes/OuterBoxSubtitle';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import mapUsersToOptions from 'User/utils/mapUsersToOptions';
import { useRecoilState, useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import Select from 'Common/components/Form/Fields/Select';
import supervisorUserOptionAtom from 'ProjectManager/Project/Introductions/Create/recoil/supervisorUserOption/supervisorUserOptionAtom';
import Button from 'Common/components/Button';

const SelectWrapper = styled.div`
    max-width: 480px;
`;

const SelectSecondaryLabel = styled.span`
    color: ${colors.LIGHT_GRAY};
`;

const SupervisorSelection = ({ onCancelButtonClick, onNextButtonClick }) => {
    const [isTouched, setIsTouched] = useState(false);

    const [supervisorUserOption, setSupervisorUserOption] = useRecoilState(
        supervisorUserOptionAtom,
    );

    const handleChange = newOption => {
        setIsTouched(true);
        setSupervisorUserOption(newOption);
    };

    const { supervisors } = useRecoilValue(projectAtom);

    const userOptions = useMemo(
        () =>
            mapUsersToOptions(
                supervisors
                    .filter(supervisor => !supervisor.isUnAssigned)
                    .map(supervisor => supervisor.user),
            ),
        [supervisors],
    );

    // Use a delayed-set ref because the bottom drawer buttons are not available
    // during the initial render of this component.
    const [bottomButtonsRef, setBottomButtonsRef] = useState(
        document.getElementById('drawer-bottom-buttons'),
    );

    useEffect(() => {
        setBottomButtonsRef(document.getElementById('drawer-bottom-buttons'));
    }, []);

    return (
        <>
            <OuterBoxTitle>Einweiser benennen</OuterBoxTitle>
            <OuterBoxSubtitle>
                Als Geschäftsführer kannst du die Durchführung einer Einweisung
                an einen Auftragsverantwortlichen delegieren. Voraussetzung
                hierfür ist, dass du weiter Auftragsverantwortliche in das
                Projekt einbindest.
            </OuterBoxSubtitle>
            <SelectWrapper>
                <Select
                    id="supervisorUser"
                    name="supervisorUser"
                    label="Arbeitsverantwortlicher"
                    value={supervisorUserOption}
                    onChange={handleChange}
                    options={userOptions}
                    isSearchable
                    isRequired
                    isClearable
                    formatOptionLabel={({ label, isManager }) => (
                        <>
                            {label}
                            {isManager && (
                                <>
                                    {' '}
                                    <SelectSecondaryLabel>
                                        (Manager)
                                    </SelectSecondaryLabel>
                                </>
                            )}
                        </>
                    )}
                    touched={isTouched}
                    error={!supervisorUserOption ? 'Pflichtangabe' : undefined}
                />
            </SelectWrapper>
            {Boolean(bottomButtonsRef) &&
                ReactDOM.createPortal(
                    <>
                        <Button
                            text
                            type="button"
                            onClick={onCancelButtonClick}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            type="button"
                            onClick={onNextButtonClick}
                            disabled={supervisorUserOption === null}
                        >
                            Weiter
                        </Button>
                    </>,
                    bottomButtonsRef,
                )}
        </>
    );
};

SupervisorSelection.propTypes = {
    onCancelButtonClick: PropTypes.func.isRequired,
    onNextButtonClick: PropTypes.func.isRequired,
};

export default SupervisorSelection;
