import React from 'react';
import styled from 'styled-components/macro';
import { EmployeesIcon } from 'Common/components/icons';
import TasksProgress from 'Dashboard/components/manager/TasksProgress';
import AvailableModules from 'Dashboard/components/manager/AvailableModules';
import { Helmet } from 'react-helmet-async';
import Tile from 'Common/components/Tile';
import { Link } from 'react-router-dom';
import OuterBox from 'Common/components/Boxes/OuterBox';
import BackButton from 'Application/components/Header/BackButton/BackButton';

const Layout = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 860px) {
        flex-direction: row;
    }
`;

const Sidebar = styled.div`
    > * {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 860px) {
        order: 1;
        width: 300px;
        margin-right: 10px;
    }
`;

const Content = styled.div`
    margin-bottom: 10px;

    @media screen and (min-width: 860px) {
        order: 2;
        flex: 1;
        margin-bottom: 0;
    }

    @media screen and (min-width: 1180px) {
        display: flex;
        flex-wrap: wrap;
    }
`;

const EmployeesModuleLink = styled(Tile).attrs({
    as: Link,
})`
    i {
        font-size: 32px;
    }

    @media screen and (min-width: 425px) {
        i {
            font-size: 42px;
        }
    }

    @media screen and (min-width: 860px) {
        justify-content: center;
        padding-top: 15px;
        padding-bottom: 15px;

        i,
        svg {
            width: 32px;
            height: 32px;
            font-size: 32px;
        }
    }
`;

const ManagerDashboard = () => (
    <Layout>
        <Helmet>
            <title>Dashboard</title>
        </Helmet>
        <BackButton to="/dashboard" isActive={false} />
        <Content>
            <AvailableModules />
        </Content>
        <Sidebar>
            <EmployeesModuleLink to="/employees">
                <EmployeesIcon />
                Mitarbeiter
            </EmployeesModuleLink>
            <OuterBox>
                <TasksProgress title="Gesamtfortschritt" />
            </OuterBox>
        </Sidebar>
    </Layout>
);

export default ManagerDashboard;
