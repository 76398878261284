import api from 'Application/api/api';

const deleteTemplateCustomQuestion = (
    templateId,
    customQuestionId,
    cancelToken = null,
) =>
    api.delete(
        `project-manager/templates/${templateId}/questionnaire/custom-questions/${customQuestionId}`,
        {
            cancelToken,
        },
    );

export default deleteTemplateCustomQuestion;
