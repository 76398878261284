import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import archiveInstruction from 'Education/api/instruction/archiveInstruction';
import Modal from 'Common/components/Modals/Modal';
import Button from 'Common/components/Button';
import ModalTitle from 'Common/components/Modals/ModalTitle';

const InstructionArchiveModal = ({ instruction, onCancel, onSuccess }) => {
    const [isLoading, setIsLoading] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const handleConfirm = async () => {
        try {
            setIsLoading(true);

            await archiveInstruction(instruction.id, source.token);

            onSuccess();

            setIsLoading(false);

            notify('Die Unterweisung wurde erfolgreich gelöscht', {
                type: toast.TYPE.SUCCESS,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsLoading(false);

                notify('Die Unterweisung konnte nicht gelöscht werden', {
                    type: toast.TYPE.ERROR,
                });
            }
        }
    };

    return (
        <Modal
            isOpen={instruction !== null}
            onRequestClose={isLoading ? undefined : onCancel}
            buttons={
                <>
                    <Button
                        text
                        dark
                        type="button"
                        onClick={onCancel}
                        disabled={isLoading}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        isLoading={isLoading}
                        onClick={handleConfirm}
                        disabled={isLoading}
                    >
                        Löschen
                    </Button>
                </>
            }
        >
            <ModalTitle>
                Wollen Sie den Unterweisung {instruction?.name} wirklich
                Löschen?
            </ModalTitle>
            <p>
                Bitte beachten: Es werden nur bestandene Unterweisungen
                archiviert. Unterweisungen, welche nicht zu 100 % absolviert
                wurden, werden nicht archiviert und die Fortschritte werden
                unwiderruflich gelöscht.
            </p>
        </Modal>
    );
};

InstructionArchiveModal.defaultProps = {
    instruction: null,
};

InstructionArchiveModal.propTypes = {
    instruction: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default InstructionArchiveModal;
