import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Common/constants/colors';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';

const Wrapper = styled.button`
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 10px 15px;
    margin: 0 10px 10px 0;
    text-align: left;
    outline: 0 none;
    border: 1px solid ${colors.SEARCH_FIELD_BORDER};
    border-radius: 6px;
    cursor: pointer;

    ${ifProp(
        'isSelected',
        css`
            background: ${colors.DIRTY_WHITE};
            color: ${colors.DARK_GRAY};
        `,
        css`
            background: transparent;
            color: ${colors.DIRTY_WHITE};
        `,
    )};
`;

const Text = styled.div`
    position: relative;
    top: 2px;
    margin-left: 7px;
    font-size: 14px;
    line-height: 21px;
`;

const Answer = ({
    text,
    isSelected,
    selectedIcon,
    notSelectedIcon,
    onClick,
}) => (
    <Wrapper type="button" isSelected={isSelected} onClick={onClick}>
        {isSelected ? selectedIcon : notSelectedIcon}
        <Text>{text}</Text>
    </Wrapper>
);

Answer.propTypes = {
    text: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    selectedIcon: PropTypes.any.isRequired,
    notSelectedIcon: PropTypes.any.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default Answer;
