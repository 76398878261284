import api from 'Application/api/api';

const updateTemplateAreas = (templateId, areaIds, cancelToken = null) =>
    api.post(
        `project-manager/templates/${templateId}/areas`,
        {
            areaIds,
        },
        { cancelToken },
    );

export default updateTemplateAreas;
