import { useRecoilValue } from 'recoil';
import dataManagementEntityAtom from 'ProjectManager/DataManagement/Common/recoil/dataManagementEntity/dataManagementEntityAtom';
import dataManagementEntities from 'ProjectManager/DataManagement/Common/constants/dataManagementEntities';
import useRequestProjectQuestionFilesDownloadFunction from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/components/DownloadAllFilesButton/hooks/requestFilesDownloadFunction/useRequestProjectQuestionFilesDownloadFunction';
import useRequestTemplateQuestionFilesDownloadFunction from 'ProjectManager/DataManagement/Questionnaire/components/Question/Files/components/DownloadAllFilesButton/hooks/requestFilesDownloadFunction/useRequestTemplateQuestionFilesDownloadFunction';

const useRequestFilesDownloadFunction = (questionId, isCustomQuestion) => {
    const dataManagementEntity = useRecoilValue(dataManagementEntityAtom);

    const dataFromProject = useRequestProjectQuestionFilesDownloadFunction(
        questionId,
        isCustomQuestion,
    );

    const dataFromTemplate = useRequestTemplateQuestionFilesDownloadFunction(
        questionId,
        isCustomQuestion,
    );

    if (dataManagementEntity === dataManagementEntities.PROJECT) {
        return dataFromProject;
    }

    if (dataManagementEntity === dataManagementEntities.TEMPLATE) {
        return dataFromTemplate;
    }

    return () => {};
};

export default useRequestFilesDownloadFunction;
