import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import {
    Checkbox as MaterialCheckbox,
    FormControlLabel,
} from '@material-ui/core';
import ErrorMessage from 'Common/components/Form/ErrorMessage';
import colors from 'Common/constants/colors';

const Wrapper = styled.div`
    .MuiFormControlLabel-root {
        display: flex;
        align-items: flex-start;
    }

    .MuiFormControlLabel-label {
        padding-top: 11px;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        line-height: 21px;
    }

    ${ifProp(
        'dark',
        css`
            .MuiCheckbox-root {
                color: ${colors.DARK_GRAY};

                &.Mui-disabled {
                    color: ${colors.GRAY};
                }
            }

            .MuiFormControlLabel-label {
                color: ${colors.DARK_GRAY};

                &.Mui-disabled {
                    color: ${colors.GRAY};
                }
            }
        `,
        css`
            .MuiCheckbox-root {
                color: ${colors.DIRTY_WHITE};

                &:hover {
                    background-color: rgba(255, 255, 255, 0.04);
                }

                &.Mui-disabled {
                    color: ${colors.SEARCH_FIELD_BORDER};
                }
            }

            .MuiFormControlLabel-label {
                color: ${colors.DIRTY_WHITE};

                &.Mui-disabled {
                    color: ${colors.SEARCH_FIELD_BORDER};
                }
            }
        `,
    )}
`;

const Checkbox = ({ label, error, touched, className, dark, ...props }) => {
    const hasError = !!error && touched;
    const hasLabel = label !== null;

    const renderedCheckbox = <MaterialCheckbox color="default" {...props} />;

    return (
        <Wrapper className={className} dark={dark}>
            {hasLabel ? (
                <FormControlLabel control={renderedCheckbox} label={label} />
            ) : (
                renderedCheckbox
            )}

            {hasError && <ErrorMessage message={error} />}
        </Wrapper>
    );
};

Checkbox.defaultProps = {
    label: null,
    error: null,
    touched: false,
    dark: false,
};

Checkbox.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.bool,
    dark: PropTypes.bool,
};

export default Checkbox;
