import api from 'Application/api/api';

const showProjectQuestion = (projectId, questionId, isCustom, cancelToken = null) =>
    api.post(
        `project-manager/projects/${projectId}/questionnaire/questions/${questionId}/${
            isCustom ? 1 : 0
        }/show`,
        {},
        { cancelToken },
    );

export default showProjectQuestion;
