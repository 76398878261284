import React, { useState } from 'react';
import colors from 'Common/constants/colors';
import styled from 'styled-components/macro';
import formatDate from 'Common/utils/formatDate';
import parseDate from 'Common/utils/parseDate';
import Button from 'Common/components/Button';
import { CheckCircleRounded, WarningRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';
import InnerBox from 'Common/components/Boxes/InnerBox';
import ConfirmationModal from 'ProjectManager/Project/Introductions/Grid/components/Introduction/ViewParticipantsModal/ConfirmationModal';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';

const Wrapper = styled(InnerBox)`
    margin-bottom: 10px;
    padding: 12px;
`;

const Title = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 21px;
`;

const Status = styled.div`
    font-size: 12px;
    line-height: 21px;
    color: ${colors.GRAY};

    > div {
        color: ${colors.WARNING};
    }

    > button {
        padding-left: 0;
    }
`;

const StyledCheckCircleRounded = styled(CheckCircleRounded)`
    color: ${colors.GREEN};
    margin-left: 10px;
`;

const StyledWarningRounded = styled(WarningRounded)`
    color: ${colors.WARNING};
    margin-left: 10px;
`;

const Participant = ({ participant, introduction }) => {
    const { status: projectStatus } = useRecoilValue(projectAtom);

    const [isModalOpened, setIsModalOpened] = useState(false);

    const employee = participant.projectParticipant.employee;

    const handleConfirmButtonClick = () => {
        setIsModalOpened(true);
    };

    const handleCloseModal = () => {
        setIsModalOpened(false);
    };

    return (
        <Wrapper light>
            <Title>
                {`${employee.lastName}, ${employee.firstName}`}
                {!introduction.isCanceled && (
                    <>
                        {participant.isConfirmed ? (
                            <StyledCheckCircleRounded />
                        ) : (
                            <StyledWarningRounded />
                        )}
                    </>
                )}
            </Title>
            {!introduction.isCanceled && (
                <Status>
                    {participant.isConfirmed ? (
                        `Bestätigt am ${formatDate(
                            parseDate(participant.confirmedAt),
                        )}`
                    ) : (
                        <>
                            <div>Ausstehende Bestätigung</div>
                            {projectStatus !== projectStatuses.ARCHIVED && (
                                <Button
                                    dark
                                    type="button"
                                    underline
                                    onClick={handleConfirmButtonClick}
                                >
                                    Bestätigen
                                </Button>
                            )}
                        </>
                    )}
                </Status>
            )}
            {isModalOpened && (
                <ConfirmationModal
                    employee={employee}
                    introduction={introduction}
                    onClose={handleCloseModal}
                />
            )}
        </Wrapper>
    );
};

Participant.propTypes = {
    participant: PropTypes.object.isRequired,
    introduction: PropTypes.object.isRequired,
};

export default Participant;
