import gridItems from 'ProjectManager/Project/Preview/Grid/constants/gridItems';

const columnBreakpoints = [
    {
        // Mobile
        to: 869,
        columns: [
            {
                flex: 1,
                items: [
                    gridItems.PROJECT_DETAILS,
                    gridItems.TASKS,
                    gridItems.TIMELINE,
                    gridItems.WORKFORCE_PLANNING,
                    gridItems.PEOPLE,
                    gridItems.MEDIA_FILES,
                    gridItems.TOPICS,
                    gridItems.QUICK_ACTIONS,
                ],
            },
        ],
    },
    {
        // Tablet
        from: 870,
        to: 1009,
        columns: [
            {
                flex: 55,
                items: [
                    gridItems.PROJECT_DETAILS,
                    gridItems.TIMELINE,
                    gridItems.QUICK_ACTIONS,
                    gridItems.MEDIA_FILES,
                ],
            },
            {
                flex: 45,
                items: [
                    gridItems.TASKS,
                    gridItems.WORKFORCE_PLANNING,
                    gridItems.PEOPLE,
                    gridItems.TOPICS,
                ],
            },
        ],
    },
    {
        // Small desktop
        from: 1010,
        to: 1289,
        columns: [
            {
                flex: 60,
                items: [
                    gridItems.PROJECT_DETAILS,
                    gridItems.TIMELINE,
                    gridItems.QUICK_ACTIONS,
                    gridItems.MEDIA_FILES,
                ],
            },
            {
                flex: 40,
                items: [
                    gridItems.TASKS,
                    gridItems.WORKFORCE_PLANNING,
                    gridItems.PEOPLE,
                    gridItems.TOPICS,
                ],
            },
        ],
    },
    {
        // Large desktop
        from: 1290,
        columns: [
            {
                flex: 38,
                items: [
                    gridItems.PROJECT_DETAILS,
                    gridItems.TIMELINE,
                    gridItems.QUICK_ACTIONS,
                ],
            },
            {
                flex: 31,
                items: [gridItems.TASKS, gridItems.PEOPLE],
            },
            {
                flex: 31,
                items: [
                    gridItems.WORKFORCE_PLANNING,
                    gridItems.MEDIA_FILES,
                    gridItems.TOPICS,
                ],
            },
        ],
    },
];

export default columnBreakpoints;
