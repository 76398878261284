import styled from 'styled-components/macro';
import MaterialAccordion from '@material-ui/core/Accordion';
import MaterialAccordionSummary from '@material-ui/core/AccordionSummary';
import MaterialAccordionDetails from '@material-ui/core/AccordionDetails';
import colors from 'Common/constants/colors';

export const SidebarAccordionPanel = styled(MaterialAccordion)`
    &.MuiAccordion-root {
        padding: 0;
        background: ${colors.INNER_BOX_BACKGROUND};
        box-shadow: 0 7px 10px rgba(0, 0, 0, 0.16);
        border-radius: 6px;

        &:before {
            display: none;
        }

        &.Mui-expanded {
            margin: 0;
        }
    }
`;

export const SidebarAccordionTitle = styled(MaterialAccordionSummary)`
    &.MuiAccordionSummary-root {
        padding: 0 20px;
        flex-direction: row-reverse;

        .MuiAccordionSummary-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 20px 0;
            color: ${colors.DIRTY_WHITE};

            &.Mui-expanded {
                margin: 20px 0;
            }
        }

        .MuiIconButton-root {
            color: ${colors.DIRTY_WHITE};
        }

        .MuiIconButton-edgeEnd {
            margin-right: 0;
            margin-left: -12px;
        }
    }
`;

export const SidebarAccordionContent = styled(MaterialAccordionDetails)`
    &.MuiAccordionDetails-root {
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
        background: ${colors.INNER_BOX_BACKGROUND};
        color: ${colors.DIRTY_WHITE};

        > div {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;
