import React, { useEffect, useMemo, useState } from 'react';
import { CloudDownloadRounded } from '@material-ui/icons';
import Button from 'Common/components/Button';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import requestDataDownload from 'ProjectManager/Project/Common/api/downloadRequest/requestDataDownload';
import dataTypes from 'ProjectManager/Project/Preview/QuickActions/DownloadAction/constants/dataTypes';
import SuccessModal
    from 'ProjectManager/Project/ProjectMediaFiles/components/Content/Questionnaire/DownloadAllFilesButton/SuccessModal';

const DownloadAllFilesButton = () => {
    const { id: projectId } = useRecoilValue(projectAtom);

    const [isSuccessModalOpened, setIsSuccessModalOpened] = useState(false);

    const closeSuccessModal = () => {
        setIsSuccessModalOpened(false);
    };

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const [isLoading, setIsLoading] = useState(false);

    const handleButtonClick = async () => {
        setIsLoading(true);

        try {
            await requestDataDownload(
                projectId,
                [dataTypes.QUESTION_FILES],
                {},
                true,
                source.token,
            );

            setIsLoading(false);
            setIsSuccessModalOpened(true);
        } catch (error) {
            if (!axios.isCancel(error)) {
                setIsLoading(false);

                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }
    };

    return (
        <>
            <Button
                underline
                icon={<CloudDownloadRounded />}
                isLoading={isLoading}
                disabled={isLoading}
                type="button"
                onClick={handleButtonClick}
            >
                Alle downloaden
            </Button>
            {isSuccessModalOpened && (
                <SuccessModal onClose={closeSuccessModal} />
            )}
        </>
    );
};

export default DownloadAllFilesButton;
