import React from 'react';
import styled from 'styled-components/macro';
import { useRecoilValue } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import projectStatuses from 'ProjectManager/Project/Common/constants/projectStatuses';
import StartButton from 'ProjectManager/Project/Preview/Timeline/PrimaryButtons/StartButton/StartButton';
import EndButton from 'ProjectManager/Project/Preview/Timeline/PrimaryButtons/EndButton/EndButton';
import ReactivateButton from 'ProjectManager/Project/Preview/Timeline/PrimaryButtons/ReactivateButton/ReactivateButton';
import ArchiveButton from 'ProjectManager/Project/Preview/Timeline/PrimaryButtons/ArchiveButton/ArchiveButton';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    > button {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const PrimaryButtons = () => {
    const { status } = useRecoilValue(projectAtom);

    return (
        <Wrapper>
            {status === projectStatuses.ON_STANDBY && <StartButton />}
            {status === projectStatuses.ONGOING && <EndButton />}
            {status === projectStatuses.ENDED && (
                <>
                    <ArchiveButton />
                    <ReactivateButton />
                </>
            )}
        </Wrapper>
    );
};

export default PrimaryButtons;
