import api from 'Application/api/api';

const createProject = (clientId, cancelToken = null) =>
    api.post(
        `project-manager/clients/${clientId}/project`,
        {},
        { cancelToken },
    );

export default createProject;
