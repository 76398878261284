import { useRecoilValue } from 'recoil';
import isGeneralProjectInformationStepCompletedSelector from 'ProjectManager/Project/Common/recoil/project/selectors/isGeneralProjectInformationStepCompletedSelector';

const useIsProjectGeneralInformationStepCompleted = () => {
    // noinspection UnnecessaryLocalVariableJS
    const isProjectGeneralInformationStepCompleted = useRecoilValue(
        isGeneralProjectInformationStepCompletedSelector,
    );

    return isProjectGeneralInformationStepCompleted;
};

export default useIsProjectGeneralInformationStepCompleted;
