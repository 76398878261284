import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import hideTemplateQuestion from 'ProjectManager/Template/Common/api/dataManagement/questionnaire/question/hideTemplateQuestion';
import withQuestionVisibility
    from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/withQuestionVisibility';

const useHideTemplateQuestionFunction = (questionId, isCustomQuestion) => {
    const [{ id: templateId }, setTemplate] = useRecoilState(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const hideQuestion = useCallback(async () => {
        await hideTemplateQuestion(
            templateId,
            questionId,
            isCustomQuestion,
            source.token,
        );

        setTemplate(
            withQuestionVisibility(questionId, isCustomQuestion, false),
        );
    }, [templateId, questionId, isCustomQuestion, setTemplate, source.token]);

    return hideQuestion;
};

export default useHideTemplateQuestionFunction;
