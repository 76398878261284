import { useRecoilValue } from 'recoil';
import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import templateAtom from 'ProjectManager/Template/Common/recoil/templateAtom';
import isTemplateCustomSubsectionNameUnique from 'ProjectManager/Template/Common/api/dataManagement/questionnaire/subsection/isTemplateCustomSubsectionNameUnique';

const useUniqueNameValidationCallbackForTemplate = (
    sectionId,
    isCustomSection,
) => {
    const { id: templateId } = useRecoilValue(templateAtom);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const validationCallback = useCallback(
        async (value, editedCustomSubsectionId = null) => {
            if (!value) {
                return true;
            }

            try {
                const response = await isTemplateCustomSubsectionNameUnique(
                    value,
                    templateId,
                    sectionId,
                    isCustomSection,
                    editedCustomSubsectionId,
                    source.token,
                );

                return response.data.isUnique;
            } catch (error) {
                return axios.isCancel(error);
            }
        },
        [isCustomSection, templateId, sectionId, source.token],
    );

    return validationCallback;
};

export default useUniqueNameValidationCallbackForTemplate;
