import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import InnerBoxWithButtons from 'Common/components/Boxes/InnerBoxWithButtons';
import Button from 'Common/components/Button';
import * as Yup from 'yup';
import axios from 'axios';
import isProjectCoordinatorEmailUnique from 'ProjectManager/Project/Common/api/dataManagement/coordinator/isProjectCoordinatorEmailUnique';
import { useFormik } from 'formik';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import addProjectCoordinator from 'ProjectManager/Project/Common/api/dataManagement/coordinator/addProjectCoordinator';
import Input from 'Common/components/Form/Fields/Input';
import FormField from 'Common/components/Form/FormField';
import InnerBoxTitle from 'Common/components/Boxes/InnerBoxTitle';
import { useRecoilState } from 'recoil';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import withAdditionalCoordinator from 'ProjectManager/Project/Common/recoil/project/modifiers/coordinator/withAdditionalCoordinator';

const initialValues = {
    name: '',
    email: '',
};

const AddProjectCoordinator = ({ onCancel, onAdd }) => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const [isAdding, setIsAdding] = useState(false);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    const uniqueEmailValidationCallback = useCallback(
        async value => {
            if (!value) {
                return true;
            }

            try {
                const response = await isProjectCoordinatorEmailUnique(
                    value,
                    projectId,
                    null,
                    source.token,
                );

                return response.data.isUnique;
            } catch (error) {
                return axios.isCancel(error);
            }
        },
        [projectId, source.token],
    );

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                name: Yup.string().required('Pflichtangabe'),
                email: Yup.string()
                    .email('Ungültige E-Mail')
                    .debouncedCallback(
                        'E-Mail bereits in Verwendung',
                        uniqueEmailValidationCallback,
                    )
                    .required('Pflichtangabe'),
            }),
        [uniqueEmailValidationCallback],
    );

    const {
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        isSubmitting,
        isValidating,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async values => {
            setIsAdding(true);

            try {
                const response = await addProjectCoordinator(
                    projectId,
                    values,
                    source.token,
                );

                const projectCoordinator = response.data;

                setProject(withAdditionalCoordinator(projectCoordinator));

                onAdd();

                notify('Erstellung war erfolgreich', {
                    type: toast.TYPE.SUCCESS,
                });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    notify(
                        'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                        {
                            type: toast.TYPE.ERROR,
                        },
                    );
                }
            }

            setIsAdding(false);
        },
    });

    const handleEmailKeyDown = e => {
        if (e.key === ' ') {
            e.preventDefault();
        }
    };

    const handleEmailInput = e => {
        e.target.value = e.target.value.replace(/\s/g, '');
    };

    const isSubmittingAndValidating = isSubmitting && isValidating;
    const isLoading = isAdding || isSubmittingAndValidating;

    return (
        <InnerBoxWithButtons
            buttons={
                <>
                    <Button
                        text
                        type="button"
                        disabled={isLoading}
                        onClick={onCancel}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        type="button"
                        disabled={isLoading || !isValid}
                        isLoading={isLoading}
                        onClick={handleSubmit}
                    >
                        Hinzufügen
                    </Button>
                </>
            }
        >
            <InnerBoxTitle>SiGeKo hinzufügen</InnerBoxTitle>
            <form onSubmit={handleSubmit}>
                <FormField>
                    <Input
                        id="name"
                        name="name"
                        label="Name"
                        value={values.name}
                        error={errors.name}
                        touched={touched.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isRequired
                    />
                </FormField>
                <FormField>
                    <Input
                        id="email"
                        name="email"
                        label="E-Mail"
                        value={values.email}
                        error={errors.email}
                        touched={touched.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={handleEmailKeyDown}
                        onInput={handleEmailInput}
                        isRequired
                    />
                </FormField>
            </form>
        </InnerBoxWithButtons>
    );
};

AddProjectCoordinator.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
};

export default AddProjectCoordinator;
