import { useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import hasUnsavedChangesState from 'Common/recoil/hasUnsavedChangesState';
import projectAtom from 'ProjectManager/Project/Common/recoil/project/projectAtom';
import deleteProjectCustomQuestion from 'ProjectManager/Project/Common/api/dataManagement/questionnaire/question/deleteProjectCustomQuestion';
import withoutCustomQuestion from 'ProjectManager/DataManagement/Common/recoil/projectOrTemplate/modifiers/questionnaire/question/withoutCustomQuestion';

const useDeleteProjectCustomQuestionFunction = questionId => {
    const [{ id: projectId }, setProject] = useRecoilState(projectAtom);

    const [, setHasUnsavedChanges] = useRecoilState(hasUnsavedChangesState);

    const source = useMemo(() => axios.CancelToken.source(), []);

    useEffect(
        () => () => {
            source.cancel();
        },
        [source],
    );

    // noinspection UnnecessaryLocalVariableJS
    const deleteQuestion = useCallback(async () => {
        setHasUnsavedChanges(true);

        try {
            await deleteProjectCustomQuestion(
                projectId,
                questionId,
                source.token,
            );

            setProject(withoutCustomQuestion(questionId));
        } catch (error) {
            if (!axios.isCancel(error)) {
                notify(
                    'Ein Fehler ist aufgetreten. Bitte versuche es erneut.',
                    {
                        type: toast.TYPE.ERROR,
                    },
                );
            }
        }

        setHasUnsavedChanges(false);
    }, [projectId, questionId, setHasUnsavedChanges, setProject, source.token]);

    return deleteQuestion;
};

export default useDeleteProjectCustomQuestionFunction;
