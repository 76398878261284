import React, { useState } from 'react';
import Textarea from 'Common/components/Form/Fields/Textarea';
import { useRecoilState } from 'recoil';
import styled from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import commentAtom from 'ProjectManager/Project/Introductions/Create/recoil/comment/commentAtom';

const Wrapper = styled.div`
    width: calc(100% - 10px);
    margin-top: 20px;

    @media screen and (min-width: 820px) {
        width: calc(50% - 10px);
    }
`;

const StyledTextarea = styled(Textarea)`
    textarea {
        min-height: initial;
        height: ${ifProp('$compact', 16, 48)}px !important;
        overflow: ${ifProp('$compact', 'hidden', 'auto')} !important;
        resize: none;
        transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1),
            height 200ms cubic-bezier(0, 0, 0.2, 1) !important;
    }
`;

const Comment = () => {
    const [comment, setComment] = useRecoilState(commentAtom);

    const handleChange = e => {
        setComment(e.target.value);
    };

    const [isCompact, setIsCompact] = useState(true);

    const handleFocus = () => {
        setIsCompact(false);
    };

    const handleBlur = () => {
        setIsCompact(true);
    };

    return (
        <Wrapper>
            <StyledTextarea
                id="comment"
                name="comment"
                label="Kommentar"
                value={comment}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                $compact={isCompact}
            />
        </Wrapper>
    );
};

export default Comment;
