import api from 'Application/api/api';

const signatureConfirmUploadedFilesVerificationForDistanceProcedure = (
    driverLicenseControlId,
    signature,
    cancelToken = null,
) => {
    const formData = new FormData();

    formData.append('signature', signature);

    return api.post(
        `/measures/vehicles/driver-licenses/controls/${driverLicenseControlId}/driver-license/distance-check/signature-confirm-uploaded-files-verification`,
        formData,
        { cancelToken },
    );
};

export default signatureConfirmUploadedFilesVerificationForDistanceProcedure;
