import React from 'react';
import rolesElements from 'ProjectManager/Project/Preview/People/rolesElements';
import InnerBox from 'Common/components/Boxes/InnerBox';
import styled from 'styled-components/macro';
import { prop } from 'styled-tools';
import colors from 'Common/constants/colors';

const Person = styled(InnerBox)`
    margin-bottom: 10px;
    padding: 12px;

    &:last-child {
        margin-bottom: 0;
    }
`;

const Role = styled.div`
    margin-bottom: 2px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
    color: ${prop('color')};
`;

const Name = styled.div`
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 21px;
`;

const Email = styled.div`
    font-size: 12px;
    line-height: 18px;
    color: ${colors.LIGHTER_GRAY};
`;

const List = ({ people }) =>
    people.map(person => (
        <Person key={person.id}>
            <Role color={rolesElements[person.role].color}>
                {rolesElements[person.role].text}
            </Role>
            <Name>{person.name}</Name>
            <Email>{person.email}</Email>
        </Person>
    ));

export default List;
